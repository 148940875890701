import * as React from "react";
import {
  CategoryInsightDTO,
  SummaryInsightDTO,
} from "../../../../../generated-api-v3";
import { Insight } from "../Insight";
import bell from "../Bell.svg";
import { Typography } from "@progress/kendo-react-common";
import s from "./InsightsColumn.module.scss";
import { RoundButton } from "../../RoundButton/RoundButton";
import { useNavigate, useParams } from "react-router";
interface IProps {
  insights?: CategoryInsightDTO[] | SummaryInsightDTO[];
  height?: number;
  loading?: boolean;
  onMorePress?: () => void;
  categoryLabel?: string;
  instrumentName?: string;
}

export const InsightsColumn = ({
  insights,
  height,
  loading,
  onMorePress,
  categoryLabel,
  instrumentName,
}: IProps) => {
  let content: JSX.Element | JSX.Element[];
  const { portfolioId, positionId } = useParams<{
    portfolioId: string;
    positionId: string;
  }>();
  const navigate = useNavigate();
  const onInsightClick = (insight: SummaryInsightDTO) => {
    navigate(
      `/portfolio/${portfolioId}/instrumentInsightDetail/${positionId}/${insight?.id}/`
    );
  };
  if (loading) {
    content = (
      <div className={"shine"} style={{ height: 80, borderRadius: "6px" }} />
    );
  } else {
    content =
      (insights || []).length === 0 ? (
        <Typography.p>
          Sorry we don’t have any insights on this position
        </Typography.p>
      ) : (
        (insights || []).map((item, index) => (
          <Insight
            onClick={onInsightClick}
            key={`insight${index}`}
            insight={item}
          />
        ))
      );
  }
  return (
    <div style={{ marginTop: 18 }}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
        <img src={bell} alt={"insight"} />
        <Typography.p
          style={{
            marginBottom: 0,
            marginLeft: 6,
            fontWeight: "500",
            fontSize: "1.2rem",
          }}
        >
          Insights {instrumentName}
        </Typography.p>
      </div>
      <div className={s.column} style={{ height }}>
        {content}
        {onMorePress && (
          <div className={s.showMore}>
            <p>Would you like to know more about your {categoryLabel}?</p>
            <RoundButton onClick={onMorePress} fillMode={"outline"}>
              Show me more insights
            </RoundButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightsColumn;
