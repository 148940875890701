import React, { FC, PropsWithChildren } from "react";
import cn from "classnames";
import s from "./Blur.module.scss";

interface IOwnProps {
  colors?: [string, string, string];
  backgroundColor?: string;
}

export const Blur: FC<PropsWithChildren<IOwnProps>> = ({
  children,
  backgroundColor,
  colors = ["#EDF8F7", "#FDFDFD", "#CAD8D9"],
}) => {
  return (
    <div className={cn(s.backing)}>
      <div className={cn(s.content)}>{children}</div>
    </div>
  );
};
