import React from "react";
import { useSelectedAccountStatus } from "@iliotech/data-wire";
import { Loader } from "@iliotech/component-library/src/components/Loader/Loader";
import { PlatformContactInfo } from "./PlatformContactInfo";

interface IOwnProps {}

export const AccountManagement = (props: IOwnProps) => {
  const accountStatus = useSelectedAccountStatus();

  const loading = accountStatus.isLoading;
  const accountSource = accountStatus.data?.data?.accountSource;

  if (loading) return <Loader fullScreen={false} />;

  switch (accountSource) {
    case "Enterprise":
      return <div>Please contact your enterprise account manager</div>;
    case "Direct":
      return (
        <div>
          Please send an email to{" "}
          <u>
            <a href={"mailto:support@illio.com"}>support@illio.com</a>
          </u>{" "}
          if you'd like to close your account
        </div>
      );

    case "Platform":
      return (
        <PlatformContactInfo
          sourceName={accountStatus.data?.data?.sourceName}
        />
      );
  }

  return null;
};
