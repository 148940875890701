import { Typography } from "@progress/kendo-react-common";
import { TransactionTableCellProps } from "./PriceCell";
import { observer } from "mobx-react-lite";
import { usePositionUploadV2 } from "@iliotech/data-wire/src/stores/PositionsUpload";

interface Props extends TransactionTableCellProps {}

const TotalLocalCell = ({ index }: Props) => {
  const store = usePositionUploadV2();

  // need to get the currency from the selected instrument
  // TODO - please fix these types
  const currency = store.position.getField("currency", index + "");
  const quantity = store.position.getField("quantity", index + "") as number;
  const price = store.position.getField("price", index + "") as number;

  const dataToLog = {
    currency,
    quantity,
    price,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {quantity && (
        <Typography.p>
          {(currency || "") + " "}
          {!currency
            ? ""
            : (quantity * (price || 1)).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
        </Typography.p>
      )}
    </div>
  );
};

export default observer(TotalLocalCell);
