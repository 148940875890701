import * as React from "react";
import { WorkgroupV3Dto } from "@iliotech/generated-api-v3";
import { IActionColumn, IColumn } from "../../../../IllioTable/types";
import { IllioTable } from "../../../../IllioTable/IllioTable";
import { useAuth, useWorkgroupList } from "@iliotech/data-wire";
import { Link } from "react-router-dom";

interface IProps {
  workgroups: WorkgroupV3Dto[] | undefined;
  loading: boolean;
}

const columns: IColumn<WorkgroupV3Dto>[] = [
  {
    field: "name",
    title: "NAME",
    isDefault: true,
    renderer: ({ dataItem, style }) => {
      return (
        <td style={{ ...style }}>
          <Link
            style={{ textDecoration: "underline" }}
            to={"/workgroup/" + dataItem.name}
          >
            {dataItem.name}
          </Link>
        </td>
      );
    },
  },

  {
    field: "clientUser",
    title: "STATUS",
    isDefault: true,

    renderer: ({ dataItem, style }) => {
      return (
        <td style={{ ...style }}>
          {dataItem.clientUser ? "Assigned to client" : "Unassigned"}
        </td>
      );
    },
  },
];

const WorkgroupTable = ({ workgroups, loading }: IProps) => {
  const store = useWorkgroupList();
  const { isCustomer, isOwner } = useAuth();

  const actionColumns: IActionColumn[] = isCustomer
    ? []
    : [
        {
          text: "Assign to client",
          action: (dataItem) => {
            store.setModalState({
              visible: true,
              id: dataItem.id,
              type: "assign",
              name: dataItem.name,
              loading: false,
            });
          },
          disabled: (dataItem: WorkgroupV3Dto) => {
            return !!dataItem.clientUser;
          },
        },
        ...(isOwner
          ? [
              {
                text: "Manager sharing",
                action: (dataItem: WorkgroupV3Dto) => {
                  store.setModalState({
                    visible: true,
                    id: dataItem.id,
                    name: dataItem.name,
                    type: "managerShare",
                    loading: false,
                  });
                },
              },
            ]
          : []),
        {
          text: "Rename",
          action: (dataItem) => {
            store.setModalState({
              visible: true,
              id: dataItem.id,
              name: dataItem.name,
              type: "rename",
              loading: false,
            });
          },
        },
        {
          text: "Delete",
          action: (dataItem) => {
            store.setModalState({
              visible: true,
              id: dataItem.id,
              name: dataItem.name,
              type: "delete",
              loading: false,
            });
          },
          disabled: (dataItem: WorkgroupV3Dto) => {
            // @ts-ignore
            return !!dataItem.clientUser || dataItem.withPortfolios;
          },
        },
      ];

  return (
    <IllioTable
      loading={loading}
      columns={columns}
      isPageable={true}
      pageSize={20}
      actionColumns={actionColumns}
      data={workgroups || []}
      useColumnChooser={false}
    />
  );
};

export default WorkgroupTable;
