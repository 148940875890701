import React, { SyntheticEvent } from "react";
import cn from "classnames";
import s from "./IBAccountOnboarding.module.scss";
import { Blur } from "../Blur/Blur";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import { IBDetails, IBPreparePortfolio } from "./Steps";
import { IBConnectAccount } from "./Steps/IBConnectAccount";
import { IBConnectAccountIntro } from "./Steps/IBConnectAccountIntro/IBConnectAccountIntro";
import { IBPreparingPortfolio } from "./Steps/IBPreparingPortfolio";
import {
  createBrokerIntegration,
  useAuth,
  useReferenceData,
  useSelectedAccountStatus,
} from "@iliotech/data-wire";
import {
  CreateIntegrationRequestCountryCodeEnum,
  EnumDisplay,
  PortfolioCreateDTO,
} from "@iliotech/generated-api-v3";
import { generatePath, useNavigate } from "react-router-dom";

interface IProps {
  popupModal: boolean;
  setPopupModal(v: boolean): void;
  createPortfolio(payload: PortfolioCreateDTO): Promise<any>;
  onLogout(): void;
}

export const IBAccountOnboarding = (props: IProps) => {
  const { raw, isLoading } = useReferenceData();
  const currencies = raw?.baseCurrencies.map(({ code }) => code) ?? [];
  const countries = raw?.residentialCountries ?? [];

  const { illioSelectedAccount } = useAuth();

  return (
    <IBAccountOnboardingInner
      loading={isLoading}
      {...{ countries, currencies }}
      {...props}
      externalAccountId={illioSelectedAccount?.externalAccountId}
    />
  );
};

const IB_MACHINE_CONFIG = {
  id: "IB_ONBOARDING_STATE",
  initial: "welcome",
  // initial: "preparing",
  predictableActionArguments: true,
  states: {
    welcome: {
      meta: {
        title: "Welcome to illio!",
      },
      on: {
        START: "createPortfolio",
      },
    },
    createPortfolio: {
      meta: {
        title: "Create portfolio",
      },
      on: {
        BACK: "welcome",
        CONNECT_ACCOUNT: "connectAccountIntro",
      },
    },
    connectAccountIntro: {
      meta: {
        title: "Connect to Interactive Brokers",
      },
      on: {
        CANCEL: "createPortfolio",
        INITIATE_AUTH: "accountConnecting",
      },
    },
    accountConnecting: {
      meta: {
        title: "Connect to Interactive Brokers",
      },
      on: {
        CANCEL: "connectAccountIntro",
        FINALISE: "preparing",
      },
    },
    preparing: {
      meta: {
        title: "Portfolios",
      },
    },
  },
};

const ibMachine = createMachine(IB_MACHINE_CONFIG);

interface InnerProps extends IProps {
  currencies: string[];
  countries: EnumDisplay[];
  loading?: boolean;
  externalAccountId?: string;
}

export const IBAccountOnboardingInner = ({
  currencies,
  countries,
  externalAccountId,
  loading,
  createPortfolio,
  popupModal,
  setPopupModal,
  onLogout,
}: InnerProps) => {
  const { refetch } = useSelectedAccountStatus();
  const navigate = useNavigate();
  const [countryOfResidence, setCountryOfResidence] = React.useState<
    EnumDisplay | undefined
  >(undefined);
  const [portfolioName, setPortfolioName] = React.useState("");
  const [portfolioCurrency, setPortfolioCurrency] = React.useState<string>();
  const ibState = useMachine(ibMachine);
  const [{ machine, value }, send] = ibState;

  const title = machine?.states[String(value)]?.meta?.title;

  React.useEffect(() => {
    const interval = setInterval(() => {
      refetch?.();
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const logout = (e: SyntheticEvent) => {
    e.preventDefault?.();
    onLogout();
  };

  const submitCountryOfResidence = (countryCode: EnumDisplay) => {
    setCountryOfResidence(countryCode);
    send("START");
  };

  const submitPortfolio = (name: string, currencyCode: string) => {
    if (!!name && !!currencyCode) {
      setPortfolioName(name);
      setPortfolioCurrency(currencyCode);
      send("CONNECT_ACCOUNT");
    }
  };

  const openPopup = (url: string) => {
    var newWin = window.open(
      url,
      "_blank",
      "width=500,height=500,toolbar=1,resizable=0"
    );
    if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
      console.log("BLOCKED");
      setPopupModal(true);
    }
  };

  const initiateIBConnection = async (brokerInstituteId: number) => {
    //TODO call broker endpoint and get IB id (brokerName === IB)
    createBrokerIntegration(externalAccountId!, {
      brokerInstituteId: brokerInstituteId,
      portfolioName,
      currencyCode: portfolioCurrency?.toUpperCase() as any,
      countryCode:
        countryOfResidence?.code as CreateIntegrationRequestCountryCodeEnum,
    })
      .then((result) => {
        const authorizeUrl = (result.data as any)?.authorizeUrl;
        refetch();
        if (authorizeUrl) {
          openPopup(authorizeUrl);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createPortfolioManually = async () => {
    try {
      const result = await createPortfolio({
        name: portfolioName,
        currency: portfolioCurrency?.toUpperCase() as any,
      } as any);
      navigate(
        generatePath("/portfolio/:portfolioId/add-investments/", {
          portfolioId: (result as any).id,
        })
      );
      return Promise.resolve();
    } catch (e) {
      return Promise.resolve();
    }
  };
  // const simulateIBConnection = () => {
  //   const authorizeUrl = "http://localhost:4000/broker/ib/connect/1234";
  //   window.open(authorizeUrl, "_blank", 'width=500,height=500,toolbar=1,resizable=0');
  //   window.addEventListener("message", (e) => {
  //     if (e.data === "ibAuthenticationComplete") {
  //       send("FINALISE")
  //       console.log("FINALISE SENT")
  //     }
  //   })
  // }

  return (
    <Blur>
      <div className={cn(s.surround)}>
        <div className={cn(s.content)}>
          <div className={cn(s.logoutSurround)}>
            <a href={"#"} onClick={logout}>
              Log out
            </a>
          </div>
          {title && <h1>{title}</h1>}
          {value === "welcome" && (
            <IBDetails
              countries={countries}
              selectedCountry={countryOfResidence!}
              submit={submitCountryOfResidence}
            />
          )}
          {value === "createPortfolio" && (
            <IBPreparePortfolio
              portfolioName={portfolioName}
              portfolioCurrency={portfolioCurrency}
              submit={submitPortfolio}
              onBack={() => send("BACK")}
              currencies={currencies}
            />
          )}
          {value === "connectAccountIntro" && (
            <IBConnectAccountIntro
              popupModal={popupModal}
              closeModal={() => setPopupModal(false)}
              onBack={() => {
                send("CANCEL");
              }}
              onStart={async () => {
                // simulateIBConnection();
                await initiateIBConnection();
                // send("INITIATE_AUTH");
              }}
              onSkip={createPortfolioManually}
            />
          )}
          {value === "accountConnecting" && (
            <IBConnectAccount
              // onFailure={() => send("CONNECTION_FAILED")}
              onCancel={() => send("CANCEL")}
            />
          )}
          {value === "preparing" && <IBPreparingPortfolio />}
          {/*<pre style={{fontSize: 12}}>{JSON.stringify({value, meta}, null, 2)}</pre>*/}
        </div>
      </div>
    </Blur>
  );
};
