import * as React from "react";
import { useWindowSize } from "react-use";

const BREAKPOINTS: Record<string, number> = {
  XS: 500,
  SMALL: 769,
};

export const useSmallScreen = (size = BREAKPOINTS.SMALL) => {
  const [smallScreen, setSmallScreen] = React.useState(false);

  const measurements = useWindowSize();

  React.useEffect(() => {
    setSmallScreen(measurements.width < size);
  }, [measurements?.width]);

  return smallScreen;
};

export default useSmallScreen;
