import * as React from "react";
import s from "./ToggableButtons.module.scss";
import ToggableButton from "./components/ToggableButton/ToggableButton";
import { observer, useLocalObservable } from "mobx-react-lite";
import cn from "classnames";
import { SearchBox } from "../SearchBox/SearchBox";

export interface IToggableItem {
  value: string;
  label: string;
}
interface IProps {
  toggableItems: IToggableItem[];
  initialItems?: IToggableItem[];
  onChange: (items: string[]) => void;
  singleSelect?: boolean;
  searchPlaceholder?: string;
  size?: "small" | "large" | "auto";
}

const ToggableButtons = observer(
  ({
    toggableItems,
    onChange,
    singleSelect,
    initialItems,
    searchPlaceholder = "Search Client",
    size = "auto",
  }: IProps) => {
    const actualSize =
      size === "auto" ? (toggableItems.length > 7 ? "small" : "large") : size;
    const store = useLocalObservable(() => ({
      search: "",
      setSearch(search: string) {
        this.search = search;
      },
      selected: (initialItems || []).reduce(
        (acc, item) => ({ ...acc, [item.value]: true }),
        {}
      ),
      selectedItemsList() {
        const list: string[] = [];
        Object.entries(this.selected).forEach(
          ([key, value]) => value && list.push(key)
        );
        return list;
      },
      toggleItem(id: string) {
        if (singleSelect) {
          this.selected = { [id]: true };
          onChange?.([id]);
          return;
        }
        //@ts-ignore
        if (this.selected[id]) {
          //@ts-ignore
          this.selected[id] = false;
          onChange?.(this.selectedItemsList());
        } else {
          //@ts-ignore
          this.selected[id] = true;
          onChange?.(this.selectedItemsList());
        }
      },
    }));

    return (
      <div className={s.container}>
        <SearchBox
          style={{ width: "100%", marginBottom: "0.5rem" }}
          value={store.search}
          onChange={(s: string) => store.setSearch(s)}
          placeholder={searchPlaceholder}
          className={cn(s.searchSurround)}
        />
        {toggableItems
          .filter((item) =>
            item.label.toLowerCase().includes(store.search.toLowerCase())
          )
          .map((item) => (
            <ToggableButton
              size={actualSize}
              onClick={() => store.toggleItem(item.value)}
              label={item.label}
              toggled={
                //@ts-ignore
                store.selected[item.value] || false
              }
            />
          ))}
      </div>
    );
  }
);

export default ToggableButtons;
