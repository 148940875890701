import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { Input } from "@progress/kendo-react-inputs";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";

const NotesCell = ({ index }: { index: number }) => {
  const store = useTransactionUploadV2();
  const notes = store.equities.getField("notes", index + "") as number;

  const onChange = (event: InputChangeEvent) => {
    store.equities.updateTableRowByIndex("notes", event.value, index + "");
  };

  return <Input name={"tradeCosts"} value={notes} onChange={onChange} />;
};

export default observer(NotesCell);
