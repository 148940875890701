import React from "react";
import {
  STRUCTURE_OPTIONS,
  getBackgroundColor,
  useGlobalCurrency,
} from "@iliotech/data-wire";
import { useStructureGrouping } from "../context";
import { PieTableEntry } from "@iliotech/types";
import { PortfolioSnapshotDtoV3 } from "@iliotech/generated-api-v3";
import { addPositions } from "../../processing/positions";

type Breakdown = {
  labelField: keyof PortfolioSnapshotDtoV3;
  field: keyof PortfolioSnapshotDtoV3;
  id?: string;
};

const DRILL_DOWNS: Record<string, Breakdown> = {
  assetClassCode: {
    field: "assetSubClassCode",
    labelField: "assetSubClassName",
  },
  riskAssetClassCode: {
    field: "investmentVehicleCode",
    labelField: "investmentVehicleName",
  },
  regionCode: {
    field: "countryCode",
    labelField: "countryName",
  },
};

const SELECTABLE_FIELDS: Record<string, boolean> = {
  assetClassCode: true,
  riskAssetClassCode: true,
  regionCode: true,
  sectorCode: true,
  currencyCode: true,
  currencyLocalName: true,
  sector: true,
  custodian: true,
  custodianCode: true,
  custodianName: true,
};

export const useNetWorthBreakdown = (
  snapshots?: PortfolioSnapshotDtoV3[],
  isWhatIf?: boolean
) => {
  const structureGrouping = useStructureGrouping();
  const whatIfStructureGrouping = {
    grouping: structureGrouping.structureOptions?.[0],
    selectedGroup: undefined,
    setSelectedGroup: () => {},
  };
  const { grouping, selectedGroup, setSelectedGroup } = isWhatIf
    ? whatIfStructureGrouping
    : structureGrouping;

  const { currencyFormatter } = useGlobalCurrency();
  return React.useMemo(() => {
    const groupedData: { [grouping: string]: PieTableEntry } = {};

    let absoluteTotal = 0;
    let total = 0;

    const filterField = grouping.field; // filter.field as keyof PortfolioSnapshotDtoV3;
    const filterLabel = grouping.labelField; // filter.field as keyof PortfolioSnapshotDtoV3;
    const filterId = selectedGroup?.code;
    const aggregateField = (
      !filterId
        ? filterField
        : DRILL_DOWNS[grouping.field]?.field || filterField
    ) as keyof PortfolioSnapshotDtoV3;

    const aggregateLabelField = (
      !filterId
        ? filterLabel
        : DRILL_DOWNS[grouping.field]?.labelField || filterLabel
    ) as keyof PortfolioSnapshotDtoV3;

    const openPositions = snapshots;
    const filteredSnapshots =
      (filterId
        ? openPositions?.filter(
            (s) => s[filterField as keyof PortfolioSnapshotDtoV3] === filterId
          )
        : openPositions) || [];

    const snapshotsAggregatedByInstrumentsMap: {
      [instrumentId: string]: PortfolioSnapshotDtoV3;
    } = {};

    filteredSnapshots?.forEach((snapshot) => {
      groupedData[snapshot[aggregateField] as string] = groupedData[
        snapshot[aggregateField] as string
      ] || {
        label: snapshot[aggregateLabelField],
        percentShare: 0,
        absoluteShare: 0,
        value: 0,
        pnlValue: 0,
        incomeValue: 0,
        color:
          grouping?.getColor?.((snapshot[aggregateField] as string) || "") ||
          getBackgroundColor((snapshot[aggregateField] as string) || "null"),
        id: `${snapshot[aggregateField]}`,
        onClick: () => {
          if (SELECTABLE_FIELDS[aggregateField] && !filterId) {
            setSelectedGroup?.({
              code: snapshot[aggregateField] as string,
              label: snapshot[aggregateLabelField] as string,
            });
          } else {
            // reset
            setSelectedGroup?.(undefined);
          }
        },
      };

      groupedData[snapshot[aggregateField] as string].value +=
        snapshot.marketValueBase;

      groupedData[snapshot[aggregateField] as string].pnlValue +=
        snapshot.pnlBase;

      groupedData[snapshot[aggregateField] as string].incomeValue +=
        snapshot.incomeHistoricBase;

      if (!snapshotsAggregatedByInstrumentsMap[snapshot.instrumentId]) {
        snapshotsAggregatedByInstrumentsMap[snapshot.instrumentId] = {
          ...snapshot,
        };
      } else {
        snapshotsAggregatedByInstrumentsMap[snapshot.instrumentId] =
          addPositions(
            snapshotsAggregatedByInstrumentsMap[snapshot.instrumentId],
            snapshot
          );
      }
    });

    Object.entries(groupedData).forEach(([, entry]) => {
      absoluteTotal += Math.abs(entry.value);
      total += entry.value;
    });

    const groupedDataTotal = {
      percentShare: 1,
      absoluteShare: 1,
      value: 0,
      pnlValue: 0,
      incomeValue: 0,
      label: "Total",
      id: "Total",
      isTotal: true,
    };
    Object.entries(groupedData).forEach(([, entry]) => {
      entry.percentShare = entry.value / (total === 0 ? 1 : total);
      entry.absoluteShare =
        Math.abs(entry.value) / (absoluteTotal === 0 ? 1 : absoluteTotal);
      groupedDataTotal.value += entry.value;
      groupedDataTotal.pnlValue += entry.pnlValue;
      groupedDataTotal.incomeValue += entry.incomeValue;
    });

    groupedData.total = groupedDataTotal;
    const pieTableData = Object.values(groupedData).sort((a, b) =>
      a.isTotal ? -Infinity : b.percentShare - a.percentShare
    );

    const pnlData = Object.values(groupedData).sort((a, b) =>
      a.isTotal ? -Infinity : Math.abs(b.pnlValue) - Math.abs(a.pnlValue)
    );

    const incomeData = Object.values(groupedData).sort((a, b) =>
      a.isTotal ? -Infinity : b.incomeValue - a.incomeValue
    );

    const breakdownTotals = Object.values(pieTableData).map((item) => ({
      ...item,
      value: currencyFormatter(item.value),
      onClick: () => {
        if (SELECTABLE_FIELDS[aggregateField] && item.id !== "Total") {
          setSelectedGroup?.({
            code: item.id,
            label: item.label,
          });
        } else {
          // reset
          setSelectedGroup?.(undefined);
        }
      },
    }));

    const snapshotsAggregatedByInstruments = Object.values(
      snapshotsAggregatedByInstrumentsMap
    ); //.filter((s) => s.marketValueBase !== 0);

    return {
      incomeData,
      pnlData,
      pieTableData,
      filteredSnapshots,
      breakdownTotals,
      snapshotsAggregatedByInstruments,
    };
  }, [snapshots, grouping, selectedGroup, setSelectedGroup, currencyFormatter]);
};
