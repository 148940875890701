export enum TabType {
  Summary = "summary",
  Examples = "examples",
  Insights = "insight",
  Positions = "position",
  Transactions = "transaction",
  Monthly = "monthly",
  Portfolios = "portfolio",
}

const BaseViews = [
  { name: "Summary", id: "summary" },
  { name: "Insights", id: "insight" },
];

const MonthlyView = { name: "Monthly", id: "monthly" };

export const IncomeViews = [...BaseViews];
export const NetWorthViews = [...BaseViews];
export const PnlViews = [BaseViews[0], MonthlyView, BaseViews[1]];
export const PerformanceViews = [BaseViews[0], MonthlyView, BaseViews[1]];
export const RiskViews = [...BaseViews];
export const EsgViews = [...BaseViews];

export const PositionViews = [
  { name: "Positions", id: TabType.Positions },
  { name: "Transactions", id: TabType.Transactions },
];

export const PortfolioGroupViews = [
  { name: "Clients", id: TabType.Summary },

  { name: "Portfolios", id: TabType.Portfolios },
  { name: "Example Portfolios", id: TabType.Examples },
];
