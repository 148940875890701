import * as React from "react";
import s from "./SearchableWorkgroupList.module.scss";
import { WorkgroupV3Dto } from "@iliotech/generated-api-v3";
import WorkgroupTable from "../WorkgroupTable/WorkgroupTable";
import { SearchBox } from "../../../../SearchBox/SearchBox";
import cn from "classnames";
import { useWorkgroupList } from "@iliotech/data-wire";
import { observer } from "mobx-react-lite";

interface IProps {
  workgroups: WorkgroupV3Dto[];
  loading: boolean;
}

export const SearchableWorkgroupList = observer(
  ({ loading, workgroups }: IProps) => {
    const store = useWorkgroupList();

    const filterWorkgroup = (e: WorkgroupV3Dto) => {
      if (!store.search.trim) {
        return true;
      }

      return e.name.toLowerCase().includes(store.search.toLowerCase());
    };
    const filteredData = workgroups?.filter(filterWorkgroup);

    return (
      <div className={s.surround}>
        <SearchBox
          value={store.search}
          onChange={(e) => store.setSearch(e)}
          dataCy={"search-workgroup"}
          placeholder={"Search Groups"}
          className={cn(s.searchSurround)}
        />

        <h6 className={"element-title"}>Portfolio Groups</h6>
        <p>This is a list of your clients and portfolio groups.</p>
        <WorkgroupTable loading={loading} workgroups={filteredData || []} />
      </div>
    );
  }
);

export default SearchableWorkgroupList;
