import { IColumn } from "../types";
import { TradeDtoV3 } from "@iliotech/generated-api-v3";
import { DateCell } from "../Cells/DateCell";
import { DecimalCell } from "../Cells/DecimalCell";
import React from "react";

export const TradesBlotter: IColumn<TradeDtoV3>[] = [
  {
    field: "instrument",
    isDefault: true,
    order: -1,
    renderer: ({ dataItem, width }) => {
      const icon = dataItem?.instrument?.logoUrl || dataItem?.icon;
      const displayName = dataItem?.displayName || dataItem?.operationName;
      const iconUrl = `https://cdn.illio.com/${icon}`;
      const isDefaultIcon = icon?.startsWith("images/DEFAULT-ICON");
      return (
        <td>
          <div
            style={{
              display: "flex",
              pointerEvents: "none",
              alignItems: "center",
            }}
          >
            {icon ? (
              <img
                alt={dataItem?.displayName}
                src={iconUrl}
                height={15}
                width={20}
                style={{
                  marginRight: 5,
                  filter: isDefaultIcon ? "invert(60%)" : undefined,
                }}
              />
            ) : (
              <div style={{ width: 15, marginRight: 5 }} />
            )}
            <div style={{ width }}>{displayName}</div>
          </div>
        </td>
      );
    },
  },
  {
    field: "tradeTime",
    title: "Trade Date",
    renderer: DateCell(),
    isDefault: true,
  },
  { field: "custodianName", title: "Account", isDefault: true },
  { field: "operationName", title: "Type", isDefault: false },
  { field: "tradeType", title: "Type", isDefault: false },
  { field: "quantity", isDefault: true },
  {
    field: "price",
    title: "Cost Price",
    isDefault: false,
    renderer: DecimalCell({}),
  },
  { field: "operation" },
  { field: "amount" },
  { field: "currencyCode" },
  { field: "fxRate" },
  { field: "commission" },
  { field: "tradeCosts" },
  { field: "notes" },
  { field: "netAmountBase" },
  { field: "netAmountLocal" },
  { field: "multiplier" },
  { field: "settlementDate" },
  { field: "accruedInterestLocal" },
  { field: "accruedInterestBase" },
  { field: "accruedInterestIsCalculated" },
  { field: "settlementOption" },
  { field: "assetSubClassCode" },
  { field: "tradeSource" },
  { field: "otherInstrumentName" },
  { field: "otherQuantity" },
  { field: "otherCurrency" },
  { field: "displayBaseFx" },
  { field: "historic" },
  { field: "historicPrice" },
  { field: "historicPnl" },
];
