import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { TransactionTableCellProps } from "./DateCell";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";

interface Props extends TransactionTableCellProps {
  baseCurrency: string;
}

const SettlementCurrencyCell = ({ index, baseCurrency }: Props) => {
  const store = useTransactionUploadV2();
  const settlementCurrency = store.equities.getField(
    "settlementCurrency",
    index + ""
  ) as string;
  const instrumentCurrency = store.equities.getField("currency", index + "");
  const sameCurrency = instrumentCurrency === baseCurrency;
  const currencyList =
    sameCurrency || !instrumentCurrency
      ? [baseCurrency]
      : [instrumentCurrency, baseCurrency];
  const onChange = (event: ComboBoxChangeEvent) => {
    store.equities.updateTableRowByIndex(
      "settlementCurrency",
      event.value,
      index + ""
    );
  };

  return (
    <ComboBox
      popupSettings={{ popupClass: "illio-combo" }}
      size={"medium"}
      disabled={sameCurrency}
      rounded={"large"}
      clearButton={false}
      onChange={onChange}
      data={currencyList}
      defaultValue={settlementCurrency}
      filterable={true}
    />
  );
};

export default observer(SettlementCurrencyCell);
