import * as React from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { useState } from "react";
import s from "./Toggle.module.scss";

interface IProps {
  left: string;
  right: string;
  onClick?: (what: 0 | 1) => void;
}

export const Toggle = ({ left, right, onClick }: IProps) => {
  const [value, setValue] = useState<boolean>(false);

  const onChange = (v: boolean) => {
    onClick?.(v ? 1 : 0);
    setValue(v);
  };

  return (
    <div className={s.container} style={{ flexDirection: "row" }}>
      <span
        style={{ fontWeight: !value ? "500" : "400" }}
        className={s.text}
        onClick={() => onChange(false)}
      >
        {left}
      </span>
      <Switch
        className={s.toggleContainer}
        checked={value}
        onLabel={""}
        offLabel={""}
        size={"small"}
        onChange={(e) => onChange(e.target.value)}
      />
      <span
        style={{ fontWeight: !value ? "400" : "500" }}
        onClick={() => onChange(true)}
        className={s.text}
      >
        {right}
      </span>
    </div>
  );
};

export default Toggle;
