import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { notEmptyDropDown } from "@iliotech/data-wire";
import * as React from "react";
import { AutocompleteProps } from "../Autocomplete/Autocomplete";
import DropDownForForm from "../../DropDownForForm/DropDownForForm";
import { DropDownListProps } from "@progress/kendo-react-dropdowns";

interface IOwnProps extends Omit<DropDownListProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  list?: any[];
  popupClass?: string;
}

export const SingleSelect = ({
  errors,
  name = "expiryYear",
  label = "Expiry Year",
  list,
  ...rest
}: IOwnProps) => {
  return (
    <Field
      component={DropDownForForm}
      label={label}
      name={name}
      error={errors?.[name]}
      validator={notEmptyDropDown}
      data={list}
      {...rest}
    />
  );
};
