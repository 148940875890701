import { PortfolioApi, PortfolioCustodian } from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

export const createCustodian = (
  portfolioId: string,
  selectedCustodian: PortfolioCustodian | null
) => {
  const api = new PortfolioApi(undefined, `${API_BASE}/api`);
  if (selectedCustodian && !!portfolioId) {
    return api.updatePortfolio(
      {
        custodians: [selectedCustodian] as any as Set<PortfolioCustodian>,
        // @ts-ignore
        valid: undefined,
      },
      portfolioId
    );
  }

  return Promise.reject("No custodian passed to create custodian");
};
