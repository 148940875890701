/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { AssignDto } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { PageDTOPortfolioDataV3Dto } from '../../aggregator';
// @ts-ignore
import { PageDTOPortfolioSummaryDto } from '../../aggregator';
// @ts-ignore
import { PageDTOWorkgroupMinimalDataV3Dto } from '../../aggregator';
// @ts-ignore
import { PageDTOWorkgroupWithDataV3Dto } from '../../aggregator';
// @ts-ignore
import { PortfolioCreateV3Dto } from '../../aggregator';
// @ts-ignore
import { PortfolioSummaryDto } from '../../aggregator';
// @ts-ignore
import { PortfolioV3DTO } from '../../aggregator';
// @ts-ignore
import { UpdateWorkgroup } from '../../aggregator';
// @ts-ignore
import { WorkgroupCreateDto } from '../../aggregator';
// @ts-ignore
import { WorkgroupV3Dto } from '../../aggregator';
// @ts-ignore
import { WorkgroupWithPortfolioDataV3Dto } from '../../aggregator';
/**
 * PortfolioAndResourceGroupsWorkgroupsApi - axios parameter creator
 * @export
 */
export const PortfolioAndResourceGroupsWorkgroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * You must have access been assigned to this workgroup to add a portfolio
         * @summary Add a portfolio[to the default workgroup]
         * @param {PortfolioCreateV3Dto} portfolioCreateV3Dto 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioToDefaultWorkgroup: async (portfolioCreateV3Dto: PortfolioCreateV3Dto, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioCreateV3Dto' is not null or undefined
            assertParamExists('addPortfolioToDefaultWorkgroup', 'portfolioCreateV3Dto', portfolioCreateV3Dto)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addPortfolioToDefaultWorkgroup', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/portfolio`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioCreateV3Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * You must have access been assigned to this workgroup to add a portfolio
         * @summary Add a portfolio to an existing workgroup
         * @param {PortfolioCreateV3Dto} portfolioCreateV3Dto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioToWorkgroup: async (portfolioCreateV3Dto: PortfolioCreateV3Dto, externalAccountId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioCreateV3Dto' is not null or undefined
            assertParamExists('addPortfolioToWorkgroup', 'portfolioCreateV3Dto', portfolioCreateV3Dto)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addPortfolioToWorkgroup', 'externalAccountId', externalAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addPortfolioToWorkgroup', 'name', name)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup/{name}/portfolio`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioCreateV3Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign a Manager or Client to your workgroup
         * @param {AssignDto} assignDto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignWorkgroup: async (assignDto: AssignDto, externalAccountId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignDto' is not null or undefined
            assertParamExists('assignWorkgroup', 'assignDto', assignDto)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('assignWorkgroup', 'externalAccountId', externalAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('assignWorkgroup', 'name', name)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup/{name}/assign`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Client, Model or Internal Workgroup
         * @summary Create a workgroup
         * @param {WorkgroupCreateDto} workgroupCreateDto 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkgroup: async (workgroupCreateDto: WorkgroupCreateDto, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workgroupCreateDto' is not null or undefined
            assertParamExists('createWorkgroup', 'workgroupCreateDto', workgroupCreateDto)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('createWorkgroup', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workgroupCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks the portfolio as ready to be deleted
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolio: async (externalAccountId: string, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('deletePortfolio', 'externalAccountId', externalAccountId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('deletePortfolio', 'portfolioId', portfolioId)
            const localVarPath = `/v3/account/{externalAccountId}/portfolio/{portfolioId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete workgroup
         * @param {string} externalAccountId 
         * @param {number} workgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkgroup: async (externalAccountId: string, workgroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('deleteWorkgroup', 'externalAccountId', externalAccountId)
            // verify required parameter 'workgroupId' is not null or undefined
            assertParamExists('deleteWorkgroup', 'workgroupId', workgroupId)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup/{workgroupId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"workgroupId"}}`, encodeURIComponent(String(workgroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for a workgroup
         * @param {string} externalAccountId 
         * @param {string} query 
         * @param {boolean} [includeEmpty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorkgroup: async (externalAccountId: string, query: string, includeEmpty?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('findWorkgroup', 'externalAccountId', externalAccountId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('findWorkgroup', 'query', query)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup/search`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (includeEmpty !== undefined) {
                localVarQueryParameter['includeEmpty'] = includeEmpty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Shows the workgroup, its assigned users and portfolios
         * @summary Fetch a workgroup
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkgroupByName: async (externalAccountId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getWorkgroupByName', 'externalAccountId', externalAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getWorkgroupByName', 'name', name)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup/{name}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Shows the workgroup portfolios
         * @summary Fetch workgroups portfolios
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [portfolioName] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkgroupDataByName: async (externalAccountId: string, name: string, page?: number, size?: number, portfolioName?: string, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getWorkgroupDataByName', 'externalAccountId', externalAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getWorkgroupDataByName', 'name', name)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup/{name}/data`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (portfolioName !== undefined) {
                localVarQueryParameter['portfolioName'] = portfolioName;
            }

            if (displayCurrency !== undefined) {
                localVarQueryParameter['displayCurrency'] = displayCurrency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all workgroups
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {string} [workgroupName] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkgroupsData: async (externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', workgroupName?: string, page?: number, size?: number, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getWorkgroupsData', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/workgroups/data`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workgroupType !== undefined) {
                localVarQueryParameter['workgroupType'] = workgroupType;
            }

            if (workgroupName !== undefined) {
                localVarQueryParameter['workgroupName'] = workgroupName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (displayCurrency !== undefined) {
                localVarQueryParameter['displayCurrency'] = displayCurrency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all portfolios in your workgroups
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPortfolios: async (externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('listPortfolios', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/portfolios`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workgroupType !== undefined) {
                localVarQueryParameter['workgroupType'] = workgroupType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all portfolios with data
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {string} [portfolioName] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPortfoliosWithData: async (externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', portfolioName?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('listPortfoliosWithData', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/portfolios/data`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workgroupType !== undefined) {
                localVarQueryParameter['workgroupType'] = workgroupType;
            }

            if (displayCurrency !== undefined) {
                localVarQueryParameter['displayCurrency'] = displayCurrency;
            }

            if (portfolioName !== undefined) {
                localVarQueryParameter['portfolioName'] = portfolioName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all workgroups
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkgroups: async (externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('listWorkgroups', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/workgroups`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workgroupType !== undefined) {
                localVarQueryParameter['workgroupType'] = workgroupType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for a portfolio in your workgroups
         * @param {string} externalAccountId 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPortfolio: async (externalAccountId: string, query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('searchPortfolio', 'externalAccountId', externalAccountId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchPortfolio', 'query', query)
            const localVarPath = `/v3/account/{externalAccountId}/portfolio/search`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share workgroup to Manager or Client
         * @param {AssignDto} assignDto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareWorkgroup: async (assignDto: AssignDto, externalAccountId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignDto' is not null or undefined
            assertParamExists('shareWorkgroup', 'assignDto', assignDto)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('shareWorkgroup', 'externalAccountId', externalAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('shareWorkgroup', 'name', name)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup/{name}/share`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Un-share a workgroup
         * @param {AssignDto} assignDto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareWorkgroup: async (assignDto: AssignDto, externalAccountId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignDto' is not null or undefined
            assertParamExists('unshareWorkgroup', 'assignDto', assignDto)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('unshareWorkgroup', 'externalAccountId', externalAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('unshareWorkgroup', 'name', name)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup/{name}/unshare`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update workgroup
         * @param {UpdateWorkgroup} updateWorkgroup 
         * @param {string} externalAccountId 
         * @param {number} workgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkgroup: async (updateWorkgroup: UpdateWorkgroup, externalAccountId: string, workgroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateWorkgroup' is not null or undefined
            assertParamExists('updateWorkgroup', 'updateWorkgroup', updateWorkgroup)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('updateWorkgroup', 'externalAccountId', externalAccountId)
            // verify required parameter 'workgroupId' is not null or undefined
            assertParamExists('updateWorkgroup', 'workgroupId', workgroupId)
            const localVarPath = `/v3/account/{externalAccountId}/workgroup/{workgroupId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"workgroupId"}}`, encodeURIComponent(String(workgroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkgroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioAndResourceGroupsWorkgroupsApi - functional programming interface
 * @export
 */
export const PortfolioAndResourceGroupsWorkgroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioAndResourceGroupsWorkgroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * You must have access been assigned to this workgroup to add a portfolio
         * @summary Add a portfolio[to the default workgroup]
         * @param {PortfolioCreateV3Dto} portfolioCreateV3Dto 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioToDefaultWorkgroup(portfolioCreateV3Dto: PortfolioCreateV3Dto, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioV3DTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioToDefaultWorkgroup(portfolioCreateV3Dto, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * You must have access been assigned to this workgroup to add a portfolio
         * @summary Add a portfolio to an existing workgroup
         * @param {PortfolioCreateV3Dto} portfolioCreateV3Dto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioToWorkgroup(portfolioCreateV3Dto: PortfolioCreateV3Dto, externalAccountId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioV3DTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioToWorkgroup(portfolioCreateV3Dto, externalAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign a Manager or Client to your workgroup
         * @param {AssignDto} assignDto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignWorkgroup(assignDto: AssignDto, externalAccountId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignWorkgroup(assignDto, externalAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Client, Model or Internal Workgroup
         * @summary Create a workgroup
         * @param {WorkgroupCreateDto} workgroupCreateDto 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkgroup(workgroupCreateDto: WorkgroupCreateDto, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkgroupV3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkgroup(workgroupCreateDto, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marks the portfolio as ready to be deleted
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePortfolio(externalAccountId: string, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePortfolio(externalAccountId, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete workgroup
         * @param {string} externalAccountId 
         * @param {number} workgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkgroup(externalAccountId: string, workgroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkgroup(externalAccountId, workgroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for a workgroup
         * @param {string} externalAccountId 
         * @param {string} query 
         * @param {boolean} [includeEmpty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findWorkgroup(externalAccountId: string, query: string, includeEmpty?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkgroupV3Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findWorkgroup(externalAccountId, query, includeEmpty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Shows the workgroup, its assigned users and portfolios
         * @summary Fetch a workgroup
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkgroupByName(externalAccountId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkgroupV3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkgroupByName(externalAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Shows the workgroup portfolios
         * @summary Fetch workgroups portfolios
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [portfolioName] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkgroupDataByName(externalAccountId: string, name: string, page?: number, size?: number, portfolioName?: string, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkgroupWithPortfolioDataV3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkgroupDataByName(externalAccountId, name, page, size, portfolioName, displayCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all workgroups
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {string} [workgroupName] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkgroupsData(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', workgroupName?: string, page?: number, size?: number, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDTOWorkgroupWithDataV3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkgroupsData(externalAccountId, workgroupType, workgroupName, page, size, displayCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all portfolios in your workgroups
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPortfolios(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDTOPortfolioSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPortfolios(externalAccountId, workgroupType, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all portfolios with data
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {string} [portfolioName] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPortfoliosWithData(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', portfolioName?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDTOPortfolioDataV3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPortfoliosWithData(externalAccountId, workgroupType, displayCurrency, portfolioName, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all workgroups
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkgroups(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDTOWorkgroupMinimalDataV3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkgroups(externalAccountId, workgroupType, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for a portfolio in your workgroups
         * @param {string} externalAccountId 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPortfolio(externalAccountId: string, query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortfolioSummaryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPortfolio(externalAccountId, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share workgroup to Manager or Client
         * @param {AssignDto} assignDto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareWorkgroup(assignDto: AssignDto, externalAccountId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareWorkgroup(assignDto, externalAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Un-share a workgroup
         * @param {AssignDto} assignDto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unshareWorkgroup(assignDto: AssignDto, externalAccountId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unshareWorkgroup(assignDto, externalAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update workgroup
         * @param {UpdateWorkgroup} updateWorkgroup 
         * @param {string} externalAccountId 
         * @param {number} workgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkgroup(updateWorkgroup: UpdateWorkgroup, externalAccountId: string, workgroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkgroupV3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkgroup(updateWorkgroup, externalAccountId, workgroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioAndResourceGroupsWorkgroupsApi - factory interface
 * @export
 */
export const PortfolioAndResourceGroupsWorkgroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioAndResourceGroupsWorkgroupsApiFp(configuration)
    return {
        /**
         * You must have access been assigned to this workgroup to add a portfolio
         * @summary Add a portfolio[to the default workgroup]
         * @param {PortfolioCreateV3Dto} portfolioCreateV3Dto 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioToDefaultWorkgroup(portfolioCreateV3Dto: PortfolioCreateV3Dto, externalAccountId: string, options?: any): AxiosPromise<PortfolioV3DTO> {
            return localVarFp.addPortfolioToDefaultWorkgroup(portfolioCreateV3Dto, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * You must have access been assigned to this workgroup to add a portfolio
         * @summary Add a portfolio to an existing workgroup
         * @param {PortfolioCreateV3Dto} portfolioCreateV3Dto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioToWorkgroup(portfolioCreateV3Dto: PortfolioCreateV3Dto, externalAccountId: string, name: string, options?: any): AxiosPromise<PortfolioV3DTO> {
            return localVarFp.addPortfolioToWorkgroup(portfolioCreateV3Dto, externalAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign a Manager or Client to your workgroup
         * @param {AssignDto} assignDto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignWorkgroup(assignDto: AssignDto, externalAccountId: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.assignWorkgroup(assignDto, externalAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Client, Model or Internal Workgroup
         * @summary Create a workgroup
         * @param {WorkgroupCreateDto} workgroupCreateDto 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkgroup(workgroupCreateDto: WorkgroupCreateDto, externalAccountId: string, options?: any): AxiosPromise<WorkgroupV3Dto> {
            return localVarFp.createWorkgroup(workgroupCreateDto, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks the portfolio as ready to be deleted
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolio(externalAccountId: string, portfolioId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePortfolio(externalAccountId, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete workgroup
         * @param {string} externalAccountId 
         * @param {number} workgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkgroup(externalAccountId: string, workgroupId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkgroup(externalAccountId, workgroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for a workgroup
         * @param {string} externalAccountId 
         * @param {string} query 
         * @param {boolean} [includeEmpty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorkgroup(externalAccountId: string, query: string, includeEmpty?: boolean, options?: any): AxiosPromise<Array<WorkgroupV3Dto>> {
            return localVarFp.findWorkgroup(externalAccountId, query, includeEmpty, options).then((request) => request(axios, basePath));
        },
        /**
         * Shows the workgroup, its assigned users and portfolios
         * @summary Fetch a workgroup
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkgroupByName(externalAccountId: string, name: string, options?: any): AxiosPromise<WorkgroupV3Dto> {
            return localVarFp.getWorkgroupByName(externalAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Shows the workgroup portfolios
         * @summary Fetch workgroups portfolios
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [portfolioName] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkgroupDataByName(externalAccountId: string, name: string, page?: number, size?: number, portfolioName?: string, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: any): AxiosPromise<WorkgroupWithPortfolioDataV3Dto> {
            return localVarFp.getWorkgroupDataByName(externalAccountId, name, page, size, portfolioName, displayCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all workgroups
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {string} [workgroupName] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkgroupsData(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', workgroupName?: string, page?: number, size?: number, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: any): AxiosPromise<PageDTOWorkgroupWithDataV3Dto> {
            return localVarFp.getWorkgroupsData(externalAccountId, workgroupType, workgroupName, page, size, displayCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all portfolios in your workgroups
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPortfolios(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options?: any): AxiosPromise<PageDTOPortfolioSummaryDto> {
            return localVarFp.listPortfolios(externalAccountId, workgroupType, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all portfolios with data
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {string} [portfolioName] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPortfoliosWithData(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', portfolioName?: string, page?: number, size?: number, options?: any): AxiosPromise<PageDTOPortfolioDataV3Dto> {
            return localVarFp.listPortfoliosWithData(externalAccountId, workgroupType, displayCurrency, portfolioName, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all workgroups
         * @param {string} externalAccountId 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkgroups(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options?: any): AxiosPromise<PageDTOWorkgroupMinimalDataV3Dto> {
            return localVarFp.listWorkgroups(externalAccountId, workgroupType, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for a portfolio in your workgroups
         * @param {string} externalAccountId 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPortfolio(externalAccountId: string, query: string, options?: any): AxiosPromise<Array<PortfolioSummaryDto>> {
            return localVarFp.searchPortfolio(externalAccountId, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share workgroup to Manager or Client
         * @param {AssignDto} assignDto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareWorkgroup(assignDto: AssignDto, externalAccountId: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.shareWorkgroup(assignDto, externalAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Un-share a workgroup
         * @param {AssignDto} assignDto 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareWorkgroup(assignDto: AssignDto, externalAccountId: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.unshareWorkgroup(assignDto, externalAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update workgroup
         * @param {UpdateWorkgroup} updateWorkgroup 
         * @param {string} externalAccountId 
         * @param {number} workgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkgroup(updateWorkgroup: UpdateWorkgroup, externalAccountId: string, workgroupId: number, options?: any): AxiosPromise<WorkgroupV3Dto> {
            return localVarFp.updateWorkgroup(updateWorkgroup, externalAccountId, workgroupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioAndResourceGroupsWorkgroupsApi - object-oriented interface
 * @export
 * @class PortfolioAndResourceGroupsWorkgroupsApi
 * @extends {BaseAPI}
 */
export class PortfolioAndResourceGroupsWorkgroupsApi extends BaseAPI {
    /**
     * You must have access been assigned to this workgroup to add a portfolio
     * @summary Add a portfolio[to the default workgroup]
     * @param {PortfolioCreateV3Dto} portfolioCreateV3Dto 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public addPortfolioToDefaultWorkgroup(portfolioCreateV3Dto: PortfolioCreateV3Dto, externalAccountId: string, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).addPortfolioToDefaultWorkgroup(portfolioCreateV3Dto, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * You must have access been assigned to this workgroup to add a portfolio
     * @summary Add a portfolio to an existing workgroup
     * @param {PortfolioCreateV3Dto} portfolioCreateV3Dto 
     * @param {string} externalAccountId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public addPortfolioToWorkgroup(portfolioCreateV3Dto: PortfolioCreateV3Dto, externalAccountId: string, name: string, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).addPortfolioToWorkgroup(portfolioCreateV3Dto, externalAccountId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign a Manager or Client to your workgroup
     * @param {AssignDto} assignDto 
     * @param {string} externalAccountId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public assignWorkgroup(assignDto: AssignDto, externalAccountId: string, name: string, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).assignWorkgroup(assignDto, externalAccountId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Client, Model or Internal Workgroup
     * @summary Create a workgroup
     * @param {WorkgroupCreateDto} workgroupCreateDto 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public createWorkgroup(workgroupCreateDto: WorkgroupCreateDto, externalAccountId: string, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).createWorkgroup(workgroupCreateDto, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks the portfolio as ready to be deleted
     * @param {string} externalAccountId 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public deletePortfolio(externalAccountId: string, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).deletePortfolio(externalAccountId, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete workgroup
     * @param {string} externalAccountId 
     * @param {number} workgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public deleteWorkgroup(externalAccountId: string, workgroupId: number, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).deleteWorkgroup(externalAccountId, workgroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for a workgroup
     * @param {string} externalAccountId 
     * @param {string} query 
     * @param {boolean} [includeEmpty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public findWorkgroup(externalAccountId: string, query: string, includeEmpty?: boolean, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).findWorkgroup(externalAccountId, query, includeEmpty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Shows the workgroup, its assigned users and portfolios
     * @summary Fetch a workgroup
     * @param {string} externalAccountId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public getWorkgroupByName(externalAccountId: string, name: string, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).getWorkgroupByName(externalAccountId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Shows the workgroup portfolios
     * @summary Fetch workgroups portfolios
     * @param {string} externalAccountId 
     * @param {string} name 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [portfolioName] 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public getWorkgroupDataByName(externalAccountId: string, name: string, page?: number, size?: number, portfolioName?: string, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).getWorkgroupDataByName(externalAccountId, name, page, size, portfolioName, displayCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all workgroups
     * @param {string} externalAccountId 
     * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
     * @param {string} [workgroupName] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public getWorkgroupsData(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', workgroupName?: string, page?: number, size?: number, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).getWorkgroupsData(externalAccountId, workgroupType, workgroupName, page, size, displayCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all portfolios in your workgroups
     * @param {string} externalAccountId 
     * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public listPortfolios(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).listPortfolios(externalAccountId, workgroupType, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all portfolios with data
     * @param {string} externalAccountId 
     * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
     * @param {string} [portfolioName] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public listPortfoliosWithData(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', portfolioName?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).listPortfoliosWithData(externalAccountId, workgroupType, displayCurrency, portfolioName, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all workgroups
     * @param {string} externalAccountId 
     * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public listWorkgroups(externalAccountId: string, workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).listWorkgroups(externalAccountId, workgroupType, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for a portfolio in your workgroups
     * @param {string} externalAccountId 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public searchPortfolio(externalAccountId: string, query: string, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).searchPortfolio(externalAccountId, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share workgroup to Manager or Client
     * @param {AssignDto} assignDto 
     * @param {string} externalAccountId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public shareWorkgroup(assignDto: AssignDto, externalAccountId: string, name: string, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).shareWorkgroup(assignDto, externalAccountId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Un-share a workgroup
     * @param {AssignDto} assignDto 
     * @param {string} externalAccountId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public unshareWorkgroup(assignDto: AssignDto, externalAccountId: string, name: string, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).unshareWorkgroup(assignDto, externalAccountId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update workgroup
     * @param {UpdateWorkgroup} updateWorkgroup 
     * @param {string} externalAccountId 
     * @param {number} workgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAndResourceGroupsWorkgroupsApi
     */
    public updateWorkgroup(updateWorkgroup: UpdateWorkgroup, externalAccountId: string, workgroupId: number, options?: AxiosRequestConfig) {
        return PortfolioAndResourceGroupsWorkgroupsApiFp(this.configuration).updateWorkgroup(updateWorkgroup, externalAccountId, workgroupId, options).then((request) => request(this.axios, this.basePath));
    }
}
