import { BundlesEnum, useAuth } from "@iliotech/data-wire";
import { PropsWithChildren } from "react";
import { Navigate, useParams } from "react-router";

interface IProps {
  bundle: BundlesEnum;
  redirect?: boolean;
}

export const WithBundle = ({
  children,
  bundle,
  redirect,
}: PropsWithChildren<IProps>) => {
  const { hasBundle } = useAuth();

  const { portfolioId } = useParams();
  const redirectPath = portfolioId ? `/portfolio/${portfolioId}` : "/";
  if (!hasBundle(bundle)) {
    if (redirect) {
      return <Navigate to={redirectPath} />;
    }
    return null;
  }
  return <>{children}</>;
};
