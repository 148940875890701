import {
  CreateComparisonScenario,
  WhatIfComparisonApi,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

export const saveWhatIfScenario = (
  createComparisonScenario: CreateComparisonScenario,
  externalAccountId: string
) => {
  if (!createComparisonScenario || !externalAccountId) {
    return;
  }
  const api = new WhatIfComparisonApi(undefined, `${API_BASE}/api`);
  return api.saveComparisonScenario(
    createComparisonScenario,
    externalAccountId
  );
};
