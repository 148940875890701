import styled from '@emotion/styled';
import {ColorStylingProps, IFactStylingProps} from "./types";

export const StyledFactSquare = styled.div<Partial<ColorStylingProps> & IFactStylingProps>`
  display: inline-block;
  background-color: ${(props) => `${props.secondaryBackground}`};
  color:  ${(props) => `${props.primaryBright}`};
  border-radius: 0.5rem;
  padding: 0.5rem;
  min-width: ${(props) => props.minWidth || "12rem"};
  `;


export const StyledFactValue = styled.div`
  margin: 1rem 0 0 0;
  color: green;
  font-size: 1.5rem;
`

export const StyledFactSubtext = styled.label`
  color: lightgrey;
  font-size: 0.75rem;
  margin-top: -0.2rem;
  padding: 0;
  `
