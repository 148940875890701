import * as React from "react";
import {
  CheckboxProps,
  Checkbox as KendoCheckbox,
} from "@progress/kendo-react-inputs";

interface IProps extends CheckboxProps {}

export const Checkbox = ({ ...rest }: IProps) => {
  return (
    <div className={"illio-checkbox"}>
      <KendoCheckbox {...rest} />
    </div>
  );
};

export default Checkbox;
