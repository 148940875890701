import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { Input } from "@progress/kendo-react-inputs";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { TransactionTableCellProps } from "./DateCell";
import { observer } from "mobx-react-lite";

const FxRateCell = ({ index }: TransactionTableCellProps) => {
  const store = useTransactionUploadV2();
  const fxRate = store.equities.getField("fxRate", index + "");
  const onChange = (event: InputChangeEvent) => {
    store.equities.updateTableRowByIndex("fxRate", event.value, index + "");
  };

  return (
    <Input
      name={"fxRate"}
      type={"number"}
      value={fxRate as number}
      onChange={onChange}
    />
  );
};

export default observer(FxRateCell);
