import React from "react";
import { AUTH_MODE } from "./constants";
// import { Registration_DEPR } from "@iliotech/component-library/src/components/Registration/Registration";
import { SubscriptionChooser } from "./SubscriptionChooser";
import { Loader, KendoLoader } from "@iliotech/component-library";
import SelectAccount from "../../pages/SelectAccount/SelectAccount";

export const StatusHandler = ({
  children,
  signOut,
  user,
  illioSelectedAccount,
  mode,
}: any) => {
  // Disabled until direct customer onboarding flow is established and implementation completed
  // return children;

  if (
    [AUTH_MODE.LOADING_ACCOUNTS, AUTH_MODE.CHECKING_ACCOUNT_STATUS].includes(
      mode
    )
  ) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );
  }

  if (AUTH_MODE.SELECT_ACCOUNT === mode) {
    return <SelectAccount />;
  }
  return <>{children}</>;
};
