import { GenericIndexComparison } from "../../Menu/types";
import { DynamicCell } from "../Cells/DynamicCell";

import { IColumn } from "../types";

export const PerformanceStats = (loading: boolean) => [
  {
    field: "name",
    isDefault: true,
  },
  {
    field: "value",
    isDefault: true,
    rightAligned: true,
    // width: 70,
    renderer: DynamicCell({ loading }),
  },
  {
    field: "indexValue",
    isDefault: true,
    rightAligned: true,
    // width: 70,
    loading,
    renderer: DynamicCell({ loading }),
  },
];
