import { ITableRow } from "../../stores/TransactionsTableStore";
import {
  PortfolioCustodian,
  PortfolioDTOWithDetailsV3,
  TradeImportWithSearchResult,
} from "@iliotech/generated-api-v3";
import { fetchInstrumentPrice, searchInstruments } from "../../requests";
import { searchRealAssetOtcInstruments } from "../../requests/searchRealAsetOtcInstruments";

const FIELD_DICT = {
  A: "tradeType",
  B: "date",
  C: "quantity",
  D: "ticker",
  E: "currency",
  F: "assetClass",
  G: "price",
  H: "custodian",
  I: "commission",
  J: "tradeCosts",
  K: "totalAmountLocal",
  L: "totalAmountBase",
  M: "settlementCurrency",
  N: "notes",
};
const findCustodian = (
  custodianCode: string,
  portfolioCustodians: PortfolioCustodian[]
) => {
  return (portfolioCustodians || []).find((item) => {
    const code = item?.code || "";
    return code.toLowerCase() === custodianCode?.toLowerCase();
  });
};

export function parseLocaleNumber(stringNumber: string, locale: string) {
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
}

function isValidISIN(isin: string) {
  const isinRegex = /^[A-Z]{2}[A-Z0-9]{9}[0-9]$/;
  return isinRegex.test(isin);
}

export const autoResolveInstrument = async (
  instrumentType: string,
  ticker: string,
  currencyText: string,
  externalAccountId: any,
  workgroup: string
) => {
  if (!instrumentType) {
    throw new Error("Instrument type is required");
  }
  try {
    const searchText = (ticker || "")
      .split(".")[0]
      .split(":")[0]
      .split(",")[0]
      .split(" ")[0]
      .split("-")[0]
      .split("=")[0];

    let searchResults;
    const trimmedType = () => {
      if (instrumentType === "Shares") return "Equity";
      if (instrumentType === "ETF") return "Fund";
      return instrumentType?.replace(/\s/g, "") as any;
    };

    if (instrumentType === "Real Assets" || instrumentType === "Real Estate") {
      searchResults = await searchRealAssetOtcInstruments(
        externalAccountId!,
        workgroup,
        searchText,
        trimmedType()
      );
    } else {
      searchResults = await searchInstruments(
        searchText,
        externalAccountId,
        [trimmedType()],
        currencyText
      );
    }

    const isIsin = isValidISIN(searchText);
    if (isIsin) {
      return searchResults?.data;
    }

    return searchResults?.data?.filter((item) => {
      const sourceId = (item.instrumentSourceId?.sourceId || "")
        .split(".")[0]
        .split(":")[0]
        .split(",")[0]
        .split(" ")[0]
        .split("=")[0];

      return searchText === sourceId && currencyText === item.currencyCode;
    });
  } catch (e) {
    throw new Error("Error with instrument resolutions");
  }
};

export const validateRows = (rawData: {
  [key: string]: { [key: string]: string };
}) => {
  const validRows = Object.keys(rawData).reduce<{
    [key: string]: { [key: string]: string };
  }>((acc, key) => {
    if (rawData[key].S === "TRUE") {
      acc[key] = rawData[key];
    }
    return acc;
  }, {});

  const invalidRows = Object.keys(rawData).reduce<{
    [key: string]: { [key: string]: string };
  }>((acc, key) => {
    if (rawData[key].S === "FALSE") {
      acc[key] = rawData[key];
    }
    return acc;
  }, {});
  return { validRows, invalidRows };
};
export const processRawDataForTransaction: (
  rawData: any,
  portfolioInfo: PortfolioDTOWithDetailsV3,
  externalAccountId: string
) => Promise<{
  [id: string]: ITableRow;
}> = async (rawData, portfolioInfo, externalAccountId) => {
  const portfolioCustodians = portfolioInfo?.custodians || [];
  const baseCurrency = portfolioInfo?.currencyCode;
  const workgroup = portfolioInfo?.workgroup;
  const fieldDict = FIELD_DICT;

  // convert to array and remove all invalid rows
  const rawDataArray = Object.values(rawData).filter(
    (row: any) => row.S === "TRUE"
  );
  // filter out empty rows
  const invalidRows = Object.values(rawData).filter(
    (row: any) => row.S === "FALSE"
  );

  const stripTrailingDot = (value: string) => {
    if (!value) return "";
    if (value.charAt(value.length - 1) === ".") {
      return value.substr(0, value.length - 1);
    }
    return value;
  };

  const finalData = rawDataArray.map((row: any) => {
    const transaction: any = {};
    Object.entries(row).forEach(([key, value]: any) => {
      // @ts-ignore
      const field = fieldDict?.[key];
      if (field) {
        transaction[field] = value;
      }
    });
    return transaction;
  });

  return { rawDataArray: finalData, invalidRows };
  const map = {};
  await Promise.all(
    finalData.map(async (item, index) => {
      const custodianFound = findCustodian(item.custodian, portfolioCustodians);
      const currencyText = item.currency.trim();
      const settlementCurrency =
        item.settlementCurrency === "Instrument Currency"
          ? currencyText
          : baseCurrency;
      // @ts-ignore
      const assetClass = item.assetClass;
      let searchResultsFiltered: any[] | undefined = [];
      try {
        searchResultsFiltered = await autoResolveInstrument(
          assetClass,
          item.ticker,
          currencyText,
          externalAccountId,
          workgroup
        );
      } catch (e) {
        console.log(e);
      }

      let initialPrice: number = 0;
      if (searchResultsFiltered?.length === 1 && !item.price) {
        try {
          const result = await fetchInstrumentPrice(
            searchResultsFiltered[0]?.instrumentSourceId!,
            item.date
          );
          initialPrice = result.data;
        } catch (e) {
          console.log({ e });
          return;
        }
      }
      // @ts-ignore
      map[index] = {
        ...item,
        commission: parseLocaleNumber(
          stripTrailingDot(item.commission + ""),
          "en-US"
        ),
        tradeCosts:
          parseLocaleNumber(stripTrailingDot(item.tradeCosts + ""), "en-US") ||
          0,

        instrumentType: item.assetClass,
        currency: currencyText,
        settlementCurrency: settlementCurrency,
        date: item.date ? new Date(item.date) : undefined,
        searchResults: searchResultsFiltered,
        id: index,
        quantity: Math.abs(
          parseLocaleNumber(stripTrailingDot(item.quantity + ""), "en-US")
        ),
        notes: item.notes,
        price:
          parseLocaleNumber(stripTrailingDot(item.price + ""), "en-US") ||
          initialPrice,
        custodian: custodianFound ? custodianFound.name : item.custodian,
        custodianResolved: !!custodianFound,
        overridePrice: !!item.price,
        overrideFxRate: !!item.totalAmountBase,
        totalBase: parseLocaleNumber(
          stripTrailingDot(item.totalAmountBase + ""),
          "en-US"
        ),
        selectedInstrument:
          searchResultsFiltered?.length === 1
            ? searchResultsFiltered?.[0]
            : undefined,
        fxRate: 1,
      };

      return {};
    })
  );
  return { rawData: map, invalidRows };
};
