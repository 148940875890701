import { Period, PortfolioRiskApi } from "@iliotech/generated-api-v3";
import moment from "moment";
import { useQuery } from "react-query";
import { useGlobalPeriod } from "../context";
import { API_BASE } from "../../constants/constants";

export const useHistoricVolatility = (
  portfolioId: string,
  overridePeriod?: Period
) => {
  const { period } = useGlobalPeriod();
  const from = !!period?.from
    ? moment(period.from).format("YYYY-MM-DD")
    : undefined;
  const to = !!period?.to ? moment(period.to).format("YYYY-MM-DD") : undefined;
  const api = new PortfolioRiskApi(undefined, `${API_BASE}/api`);

  const finalPeriod = overridePeriod || { from, to };
  return useQuery(
    [
      `portfolio-historic-volatility`,
      { portfolioId, from: finalPeriod.from, to: finalPeriod.to },
    ],
    () =>
      api.getHistoricBacktest(
        portfolioId,
        "Daily",
        finalPeriod.from,
        finalPeriod.to
      ),
    {
      enabled: (!!from && !!to) || !!overridePeriod,
    }
  );
};
