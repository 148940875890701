import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import {
  IllioDatePicker,
  IllioDatePickerProps,
} from "../IllioDatePicker/IllioDatePicker";
import {
  CONSUMER_EPOCH,
  dateValidator,
  futureDateValidator,
  useGlobalPeriod,
} from "@iliotech/data-wire";
import * as React from "react";

interface IOwnProps extends Omit<IllioDatePickerProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  futureDates?: boolean;
  onChange?: (e: any) => void;
  optional?: boolean;
}

export const DateField = ({
  errors,
  name = "date",
  label = "Date",
  futureDates,
  onChange,
  optional,
  ...rest
}: IOwnProps) => {
  const { epochDate } = useGlobalPeriod();
  const minDate = rest.min ?? epochDate ?? CONSUMER_EPOCH;

  const validatorCallBack = React.useCallback(
    (value: Date) =>
      futureDates
        ? futureDateValidator(value, minDate, optional)
        : dateValidator(value, minDate, optional),
    [minDate, futureDates, optional]
  );
  return (
    <Field
      name={name}
      min={minDate}
      max={futureDates ? undefined : new Date()}
      error={errors?.[name]}
      component={IllioDatePicker}
      label={label ?? undefined}
      onChange={onChange}
      validator={validatorCallBack}
      {...rest}
    />
  );
};
