import React, { FC } from "react";
import cn from "classnames";
import s from "../ReportCreator.module.scss";
import { Button } from "rsuite";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DropDown } from "src/main";
import { POSITION_GROUPING_OPTIONS } from "../constants";

interface IOwnProps {
  settings: Record<string, any>;
  updateSettings(newSettings: Record<string, any>): void;
}

const AllocationSettings: FC<IOwnProps> = ({ settings, updateSettings }) => {
  const isAllSelected =
    !!settings.structure &&
    !!settings.openPositions &&
    !!settings.transactionHistory;

  const toggleAll = () => {
    updateSettings({
      structure: !isAllSelected,
      openPositions: !isAllSelected,
      transactionHistory: !isAllSelected,
    });
  };

  const AllocationToggle = ({ field }: { field: string }) => {
    return (
      <Checkbox
        checked={settings[field]}
        onChange={({ value }) => updateSettings({ [field]: value })}
      />
    );
  };

  return (
    <div className={cn(s.advancedSettingsSection)}>
      <span className={s.sectionTitle}>Allocation</span>
      <br />
      <div className={cn(s.settingsRow)}>
        <Checkbox
          checked={isAllSelected}
          onChange={({ value }) => toggleAll()}
        />
        <span>Select All</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <AllocationToggle field={"structure"} />
        <span>Portfolio structure</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <AllocationToggle field={"openPositions"} />
        <span>Open positions by asset class</span>
      </div>
      {!!settings.openPositions && (
        <div className={cn(s.settingsRow)}>
          <span>Group by</span>
          <DropDown
            disabled={!settings.openPositions}
            data={POSITION_GROUPING_OPTIONS}
            textField={"name"}
            value={settings.positionGrouping}
            dataItemKey="value"
            onChange={({ value }) =>
              updateSettings({ positionGrouping: value })
            }
          />
        </div>
      )}
      <div className={cn(s.settingsRow)}>
        <AllocationToggle field={"transactionHistory"} />
        <span>Transaction History</span>
      </div>
    </div>
  );
};

export default AllocationSettings;
