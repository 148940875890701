import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import {
  Input,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { TransactionTableCellProps } from "./DateCell";
import { observer } from "mobx-react-lite";

const PriceCell = ({ index }: TransactionTableCellProps) => {
  const store = useTransactionUploadV2();

  const price = store.equities.getField("price", index + "");
  const onChange = (event: NumericTextBoxChangeEvent) => {
    store.equities.updateTableRowByIndex("price", event.value, index + "");
  };

  return (
    <NumericTextBox
      name={"price"}
      min={0.00001}
      format={{
        maximumFractionDigits: 5,
      }}
      value={price as number}
      onChange={onChange}
      spinners={false}
    />
  );
};

export default observer(PriceCell);
