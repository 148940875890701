import {
  ExchangeRateControllerApi,
  ExchangeRateGetResponse,
} from "@iliotech/generated-api-v3";
import { AxiosResponse } from "axios";
import { API_BASE } from "../main";

const fxRateCacheMap: Map<
  string,
  Promise<AxiosResponse<ExchangeRateGetResponse>>
> = new Map();

const getCachedFxRateFactory = (
  date: string,
  from: string,
  to: string
): Promise<AxiosResponse<ExchangeRateGetResponse>> => {
  const api = new ExchangeRateControllerApi(undefined, `${API_BASE}/api`);
  const cacheKey = JSON.stringify({ date, from, to });
  if (!fxRateCacheMap.has(cacheKey)) {
    fxRateCacheMap.set(cacheKey, api.getFxRate(from as any, to as any, date));
  }
  return fxRateCacheMap.get(cacheKey)!;
};

export const getCachedFxRate = async (
  date: string,
  from: string,
  to: string
) => {
  if (from === to) {
    return 1;
  }
  const result = await getCachedFxRateFactory(date, from, to);

  return result?.data?.fromToFxRate || 1;
};
