import React, { PropsWithChildren } from "react";
import {
  IInsightStory,
  StoryContextProps,
} from "@widgets/components/__Insights/InsightStoryList/types/InsightStoryTypes";
import { track } from "@vercel/analytics";
import { saEvent } from "@iliotech/watchlist-api";

export const StoryContext =
  React.createContext<ReturnType<typeof useStoryContextValue> | null>(null);

export const StoryProvider = <Type, Settings>({
  children,
  story,
  settings,
  loader,
}: PropsWithChildren<StoryContextProps<Type, Settings>>) => {
  const value = useStoryContextValue(story, settings as any, loader);

  return (
    <StoryContext.Provider value={value}>{children}</StoryContext.Provider>
  );
};

const useStoryContextValue = <Type, Settings, ResponseType>(
  story: IInsightStory<Type>,
  settings: Settings,
  loader: (
    insightId: string,
    settings: Settings
  ) => Promise<{ data: ResponseType }>
) => {
  const [currentInsightId, setCurrentInsightId] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [currentInsight, setCurrentInsight] =
    React.useState<{ Component: any; data: ResponseType }>();
  const inFlightRequest = React.useRef("");
  const [loadedInsights, setLoadedInsights] = React.useState<{
    [insightId: string]: { Component: any; data: ResponseType } | undefined;
  }>({});

  React.useEffect(() => {
    if (!story.insights.length) {
      return;
    }
    const firstInsight = story.insights[0];
    loadInsight(firstInsight.id);
  }, [story, settings]);

  const loadInsight = (insightId: string) => {
    const insight = story.insights.find((i) => i.id === insightId);
    if (!insight) {
      // throw new Error(`Insight with id ${insightId} not found`);
      return;
    }
    setCurrentInsightId(insightId);
    saEvent("insight-loaded", {
      insightId,
      type: story.type,
    });
    if (loadedInsights[insightId]) {
      setCurrentInsight(loadedInsights[insightId]);
      setLoading(false);
      return;
    }

    if (inFlightRequest.current) {
      // TODO: cancel the in flight request
    }

    /** *
     * Cancel any in flight requests
     * Check if insight has already been loaded ✅ (if so, set the currentInsight and return)
     * Set loading = true ✅
     * Set the currentInsightId ✅
     * Load the data for the insight ✅
     * Add the newly loaded insight to the loadedInsights ✅
     * Set the currentInsight ✅
     * Set loading = false ✅
     */

    setLoading(true);
    loader(insightId, settings)
      .then(({ data }) => {
        const nextInsight = { data, Component: insight.Component };
        setLoadedInsights((prev) => ({ ...prev, [insightId]: nextInsight }));
        setCurrentInsight(nextInsight);
      })
      .catch(() => {
        console.error("Error loading insight");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const moveNext = () => {
    const index = story.insights.findIndex((i) => i.id === currentInsightId);
    let nextIndex = 0;
    if (index === -1 || index >= story.insights.length - 1) {
      nextIndex = 0;
    } else {
      nextIndex = index + 1;
    }
    const nextId = story.insights[nextIndex].id;
    loadInsight(nextId);
  };

  const movePrevious = () => {
    const index = story.insights.findIndex((i) => i.id === currentInsightId);
    let nextIndex = 0;
    if (index === -1 || index === 0) {
      nextIndex = story.insights.length - 1;
    } else {
      nextIndex = index - 1;
    }
    loadInsight(story.insights[nextIndex].id);
  };

  const selectCategory = (categoryId: string) => {
    if (!categoryId) return;
    if (currentInsightId) {
      const current = story.insights.find((i) => i.id === currentInsightId);
      if (current?.categoryId === categoryId) {
        moveNext();
        return;
      }
    }

    const insight = story.insights.find((i) => i.categoryId === categoryId);
    if (!insight) return;
    loadInsight(insight.id);
  };

  return {
    moveNext,
    movePrevious,
    selectCategory,
    currentInsightId,
    currentInsight,
    loadInsight,
    loading,
    story,
  };
};

export const useStoryContext = () => {
  const context = React.useContext(StoryContext);
  if (context === null) {
    throw new Error("useStoryContext must be used within a StoryProvider");
  }
  return context;
};
