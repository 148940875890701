export const ROUTES = {
  portfolio: {
    options: "/portfolio/:portfolioId/options",
    bonds: "/portfolio/:portfolioId/bonds",
    insights: "/portfolio/:portfolioId/insights",
  },
  profile: "/profile",
  unlistedAssets: {
    list: `/portfolio/:portfolioId/unlisted-assets`,
    edit: `/portfolio/:portfolioId/unlisted-assets/edit/:code`,
  },
};
