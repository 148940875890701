import moment, { MomentInput } from "moment";
import { PriceControllerV3Api, SourceId } from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

export type PricingSource =
  | "MarketBondService"
  | "MarketEodService"
  | "MarketRefinitivService"
  | "OTCService"
  | "OptionsService"
  | "RealAssetService"
  | "SystemInstrumentService"
  | "UserInstrumentService";
export const fetchInstrumentPrice = (
  instrumentSourceId: SourceId,
  date: MomentInput
) => {
  const api = new PriceControllerV3Api(undefined, `${API_BASE}/api`);
  return api.get1(
    instrumentSourceId.sourceId,
    instrumentSourceId.sourceData,
    moment(date).format("YYYY-MM-DD")
  );
};
