import s from "./IllioAlertBox.module.scss";
import {
  AiFillCloseCircle,
  AiFillInfoCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { Typography } from "@progress/kendo-react-common";
import { useState } from "react";
interface IProps {
  text: string;
}

export function IllioAlertBox({ text }: IProps) {
  const [visible, setVisible] = useState(true);

  if (!visible) return null;
  return (
    <div className={s.container}>
      <AiFillInfoCircle size={40} color="var(--dark)" className={s.icon} />
      <Typography.p>{text}</Typography.p>
      <AiOutlineCloseCircle
        onClick={() => setVisible(false)}
        size={24}
        color="var(--dark)"
        className={s.close}
      />
    </div>
  );
}

export default IllioAlertBox;
