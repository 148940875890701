import * as React from "react";
import s from "./ConnectDetailsScreen.module.scss";
import { Typography } from "@progress/kendo-react-common";
import RoundButton from "../../../RoundButton/RoundButton";
import { BsShieldFillCheck } from "react-icons/all";
import { BrokerProvider } from "../../../../../../generated-api-v3";
import { useEffect, useState } from "react";
import { getIconUrl } from "../../../../../../data-wire";
import plaid from "./Plaid.png";
import IllioDialog from "../../../IllioDialog/IllioDialog";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import ConnectDetailsScreen from "./ConnectDetailsScreen";
interface IProps {
  broker: BrokerProvider;
  onConnect: (broker: BrokerProvider) => Promise<void>;
  goBack: () => void;
  createPortfolioManually?: () => Promise<void>;
  popupModal?: boolean;
  handleClose?: () => void;
}

export const ConnectIbDetailsScreen = ({
  popupModal,
  handleClose,
  ...props
}: IProps) => {
  const popupDialog = React.useMemo(() => {
    return !popupModal ? null : (
      <IllioDialog title={"Action needed"} onClose={handleClose}>
        <div style={{ margin: "2rem 0" }}>
          You have blocked pop-ups on your browser. Please enable them in order
          to connect to broker.
        </div>
        <DialogActionsBar layout={"center"}>
          <RoundButton style={{ maxWidth: 160 }} onClick={handleClose}>
            I Understand
          </RoundButton>
        </DialogActionsBar>
      </IllioDialog>
    );
  }, [popupModal]);

  const ibContent = React.useMemo(() => {
    return (
      <>
        <Typography.h6
          style={{ fontSize: "1.2rem" }}
          className={"element-title"}
        >
          What you need to know
        </Typography.h6>
        <Typography.p style={{ marginTop: 10 }}>
          Starting the secure connection will take you to your Interactive
          Brokers sign-in page.
        </Typography.p>
        <Typography.p style={{ marginTop: 10 }}>
          Once you’ve successfully logged into IB, they will share your
          positions and trades with illio.
        </Typography.p>
        <Typography.p style={{ marginTop: 10 }}>
          Please note, we will only be able to see your positions and trades, we
          won’t be able to perform any activity on your behalf.
        </Typography.p>
        <Typography.h6
          style={{ fontSize: "1.2rem", marginTop: 25 }}
          className={"element-title"}
        >
          What we will receive
        </Typography.h6>
        <Typography.p style={{ marginTop: 10 }}>
          To give you our professional grade insights we receive :
        </Typography.p>
        <ul>
          <li>Today: Your historic balance and trades</li>
          <li>Next business day: Your ongoing trades , income and fees</li>
        </ul>
        <b>
          Please note, if you have a popup blocker, you’ll need to pause it on
          this page to connect.
        </b>
      </>
    );
  }, []);

  return (
    <>
      {popupDialog}
      <ConnectDetailsScreen {...props} overrideContent={ibContent} />
    </>
  );
};

export default ConnectIbDetailsScreen;
