import { useGlobalPeriod } from "../context";
import moment, { MomentInput } from "moment";
import { PortfolioApi } from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";

export const useSnapshot = (
  portfolioId: string | undefined,
  periodOverride?: { from?: MomentInput; to?: MomentInput }
) => {
  const { period: globalPeriod } = useGlobalPeriod();
  const period = periodOverride || globalPeriod;
  const from = !!period?.from
    ? moment(period.from).format("YYYY-MM-DD")
    : undefined;
  const to = !!period?.to ? moment(period.to).format("YYYY-MM-DD") : undefined;
  const api = new PortfolioApi(undefined, `${API_BASE}/api`);
  return useQuery(
    [`portfolio-snapshot`, { portfolioId, from, to }],
    () => api.getSnapshot(portfolioId!, from, to),
    { enabled: !!portfolioId }
  );
};
