import React, { useState } from "react";

export const WhatIfResultsRowContext =
  React.createContext<
    ReturnType<typeof useWhatIfResultsRowContextHook> | undefined
  >(undefined);

const useWhatIfResultsRowContextHook = () => {
  const [direction, setDirection] = useState<"Rows" | "Columns">("Columns");

  return { direction, setDirection };
};

export const WhatIfResultsRowContextProvider = ({
  children,
}: React.PropsWithChildren<any>) => {
  const { direction, setDirection } = useWhatIfResultsRowContextHook();
  return (
    <WhatIfResultsRowContext.Provider value={{ direction, setDirection }}>
      {children}
    </WhatIfResultsRowContext.Provider>
  );
};

export const useWhatIfResultsRowContext = () => {
  const context = React.useContext(WhatIfResultsRowContext);
  if (typeof context === "undefined") {
    throw new Error(
      "What If results row context can only be accessed from within it's provider"
    );
  }
  return context;
};
