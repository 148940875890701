import {
  assignToWorkgroup,
  deleteWorkgroup,
  unAssignToWorkgroup,
  useAuth,
  useWorkgroupInfoV3,
  useWorkgroupList,
} from "@iliotech/data-wire";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useUsers } from "@iliotech/data-wire/src/reactHooks/api/useUsers";
import ManagerShareModal from "@iliotech/component-library/src/components/__pageComponents/WorkgroupListPage/components/ManagerShareModal/ManagerShareModal";
import { AssignDtoAccountRoleEnum } from "@iliotech/generated-api-v3";
import { FormModal, RoundButton } from "@iliotech/component-library";

export const DeleteWorkgroupModalWrapper = observer(
  ({ onComplete }: { onComplete: () => void }) => {
    const { illioSelectedAccount } = useAuth();
    const store = useWorkgroupList();
    const visible = store.modalState.type === "delete";

    const workgroup = useWorkgroupInfoV3(
      illioSelectedAccount?.externalAccountId!,
      store.modalState.name
    );

    const onSuccess = () => {
      workgroup.refetch();
      onComplete();
    };

    const onDelete = () => {
      return new Promise((resolve) => {
        deleteWorkgroup(
          illioSelectedAccount?.externalAccountId!,
          store.modalState.id
        ).then(() => {
          resolve(onSuccess());
        });
      }).then(() => {
        store.resetModalState();
      });
    };

    const handleClose = () => {
      store.resetModalState();
    };

    return (
      <FormModal
        key={"delete workgroup"}
        title={"Delete workgroup"}
        visible={visible}
        handleClose={handleClose}
        footer={
          <FormModal.Footer
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <RoundButton fillMode={"outline"} onClick={handleClose}>
              Close
            </RoundButton>
            <RoundButton loading={store.modalState.loading} onClick={onDelete}>
              Delete
            </RoundButton>
          </FormModal.Footer>
        }
      >
        <div style={{ paddingBottom: 20 }}>
          <span>Are you sure?</span> <br />
        </div>
        <div></div>
      </FormModal>
    );
  }
);
