// REQUESTS :update original price
import { API_BASE } from "../constants/constants";
import { PriceControllerApi } from "@iliotech/generated-api-v3";
export const getPrice = (
  sourceId: string,
  sourceData: string,
  tradeDate: string
) => {
  const api = new PriceControllerApi(undefined, `${API_BASE}/api`);

  return api.getPriceForDate(sourceId, sourceData as any, tradeDate);
};
