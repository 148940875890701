import * as React from "react";
import { ROUTES } from "@iliotech/consumer/src/navigation/Routes/ROUTES";
import { BundlesEnum, useAuth } from "../context";
import { generatePath, useParams } from "react-router";
import { useAccountIntegrations, usePortfolio } from "../api";
import { usePortfolioUnlistedInstruments } from "../api/usePortfolioUnlistedInstruments";
import { has } from "lodash";

export const useAccountMenuItems = () => {
  const { isCustomer, logOut, illioSelectedAccount, hasBundle } = useAuth();
  const { portfolioId } = useParams();
  const portfolio = usePortfolio(portfolioId!);
  const { data: unlistedInstruments } = usePortfolioUnlistedInstruments(
    portfolioId!
  );

  const { data: accountIntegrations } = useAccountIntegrations(
    illioSelectedAccount?.externalAccountId || ""
  );

  const isModel =
    portfolio.data?.data.workgroupType === "GLOBAL" ||
    portfolio.data?.data.workgroupType === "MODEL";

  const makeHref = (href: string) => generatePath(href, { portfolioId });

  return React.useMemo(() => {
    let items: any = [{ label: "My Profile", href: "/profile" }];

    const logout = {
      label: "Log out",
      onClick: logOut,
    };

    if (isCustomer || isModel) {
      return [...items, logout];
    }

    if (
      (unlistedInstruments?.data?.length || 0) > 0 &&
      hasBundle(BundlesEnum.Add_Unlisted_Assets)
    ) {
      items.push({
        label: "Unlisted Assets",
        href: makeHref(ROUTES.unlistedAssets.list),
      });
    }
    if (
      (accountIntegrations?.data?.accountIntegrations?.length || 0) > 0 &&
      hasBundle(BundlesEnum.Broker_Connections)
    ) {
      items.push({
        label: "Connections",
        href: makeHref(`/portfolio/:portfolioId/connections`),
      });
    }

    return [...items, logout];
  }, [isCustomer, isModel, unlistedInstruments, accountIntegrations]);
};

export default useAccountMenuItems;
