import React from "react";
import validator from "validator";
import { useFormContext } from "react-hook-form";
import { BiCheck, BiX } from "react-icons/all";

interface IOwnProps {}

interface StrongPasswordOptions {
  minLength?: number | undefined;
  minLowercase?: number | undefined;
  minUppercase?: number | undefined;
  minNumbers?: number | undefined;
  minSymbols?: number | undefined;
  returnScore?: boolean | undefined;
  pointsPerUnique?: number | undefined;
  pointsPerRepeat?: number | undefined;
  pointsForContainingLower?: number | undefined;
  pointsForContainingUpper?: number | undefined;
  pointsForContainingNumber?: number | undefined;
  pointsForContainingSymbol?: number | undefined;
}

type Rule = {
  regex: RegExp;
  label: string;
};

const rules: Rule[] = [
  { regex: /(?=.{8,64})/g, label: "8+ Characters" },
  { regex: /(?=.*[A-Z])/g, label: "Uppercase" },
  { regex: /(?=.*[a-z])/g, label: "Lowercase" },
  { regex: /(?=.*[0-9])/g, label: "Number" },
  {
    regex: /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/g,
    label: "Special Characters",
  },
];

const validate = (str: string, regex: RegExp) => {
  return regex.test(str);
};
// const validate = (str: string) => {
//   console.log(str);
//   return validator.isStrongPassword(str ?? "", {
//     // [rule.id]: rule.value
//     pointsPerUnique: 1000,
//     minLength: 8,
//     minLowercase: 1,
//     minUppercase: 1,
//     minNumbers: 1,
//     minSymbols: 0,
//     returnScore: true,
//   })
// }

export const PasswordValidator = (props: IOwnProps) => {
  const {
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const password = watch("password");

  if (!password) return null;

  return (
    <div>
      {/*For security, your password must contain:*/}
      <div
        data-cy={"password-validator"}
        style={{
          display: "grid",
          marginTop: "0.5rem",
          gridTemplateColumns: "repeat(2,1fr)",
          backgroundColor: "#f5f5f5",
          padding: "0.5rem",
          borderRadius: "0.25rem",
          // color: "black"
        }}
      >
        {rules.map((rule) => (
          <div
            key={rule.label}
            style={{ display: "flex", alignItems: "center" }}
          >
            {validate(password, rule.regex) ? (
              <BiCheck color={"green"} />
            ) : (
              <BiX />
            )}
            <div style={{ marginLeft: "0.25rem" }}>{rule.label}</div>
          </div>
        ))}
      </div>
      {/*<pre style={{fontSize: 12}}>{JSON.stringify({minLength}, null, 2)}</pre>*/}
    </div>
  );
};
