import * as React from "react";
import cn from "classnames";
import s from "./Loader.module.scss";

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  fullScreen?: boolean;
}
export const Loader = ({ fullScreen, ...rest }: IProps) => {
  return (
    <div className={cn(s.container, fullScreen && s.fullScreen)} {...rest}>
      <span className={s.loader}></span>
    </div>
  );
};

export default Loader;
