import { PortfolioTradesApi } from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";

export const useTradesLight = (portfolioId: string, options?: any) => {
  const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);

  return useQuery(
    [`trades-light-api`, { portfolioId }],
    () => api.getPortfolioTradeInfo(portfolioId),
    options
  );
};
