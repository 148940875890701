import React from "react";
import {
  Chart,
  ChartArea,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisLabels,
  ChartValueAxis,
  ChartValueAxisItem, // custom axis
  ChartLegend,
  ChartPanes,
  ChartPane, // multi-pane chart
  ChartTooltip,
} from "@progress/kendo-react-charts";
import moment from "moment";
import "hammerjs";
import { IKendoFinancialDrawdownChartProps } from "./KendoFinancialDrawdownChart.types";

export const KendoFinancialDrawdownChart = ({
  controls,
  name,
  modifiers,
  currencyFormatter,
  thickness,
  valuesInPercent = false,
  nameHidden = false,
  height = 200,
  drawdownData,
  truncateDates = true,
  t = (d) => d,
  ...props
}: IKendoFinancialDrawdownChartProps) => {
  if (!drawdownData?.length) return <p>Loading...</p>;
  const categories = drawdownData?.map((d) => d?.date);
  const colors = modifiers?.colors;

  const maxChartValue =
    Math.max(
      ...(drawdownData ?? [])?.map(
        (d: { perfIndex: number }) => d?.perfIndex ?? 160
      )
    ) + 5;
  const minChartValue =
    Math.min(
      ...(drawdownData ?? [])?.map(
        (d: { perfIndex: number }) => d?.perfIndex ?? 95
      )
    ) - 5;

  return (
    <>
      <Chart
        {...props}
        style={{ fontSize: "44px", background: "transparent" }}
        transitions={false}
        data-cy="KendoFinancialDrawdownChart"
      >
        <ChartArea background={"transparent"} />
        <ChartTooltip
          padding={20}
          opacity={0.9}
          background={"var(--dark)"}
          border={{
            width: 0,
            color: "transparent",
          }}
          render={(props) => {
            return (
              <>
                {/* @ts-ignore */}
                <div style={{ color: "white" }}>{props?.categoryText}</div>
                {/* @ts-ignore */}
                {props.points?.map((point) => {
                  if (point.dataItem.hasOwnProperty("perfIndex")) {
                    return (
                      <div style={{ color: "white" }}>
                        <span>
                          <svg width="20" height="15">
                            <circle
                              fill="var(--portfolio-color)"
                              r={6}
                              cx={6}
                              cy={6}
                            />
                          </svg>
                        </span>
                        Portfolio: {point.value}
                      </div>
                    );
                  }

                  if (point.dataItem.hasOwnProperty("maxPerf")) {
                    return (
                      <div style={{ color: "white" }}>
                        <span>
                          <svg width="20" height="15">
                            <circle
                              fill="transparent"
                              stroke="var(--illio-chart-watermark-color)"
                              stroke-dasharray="2,2"
                              r={6}
                              cx={6}
                              cy={6}
                            />
                          </svg>
                        </span>
                        Watermark: {point.value}
                      </div>
                    );
                  }

                  if (point.dataItem.hasOwnProperty("drawdown")) {
                    return (
                      <div style={{ color: "white" }}>
                        <span>
                          <svg width="20" height="15">
                            <circle
                              fill="var(--illio-chart-drawdown-color)"
                              r={6}
                              cx={6}
                              cy={6}
                            />
                          </svg>
                        </span>
                        Drawdown: {point.value}
                      </div>
                    );
                  }

                  return null;
                })}
              </>
            );
          }}
          shared={modifiers?.tooltip?.shared || true}
        />
        <ChartPanes>
          <ChartPane
            name="top"
            background={colors?.topPanelBg ?? "transparent"}
          />
          <ChartPane
            name="drawdown"
            background={colors?.bottomPanelBg ?? "transparent"}
            height={90}
          />
          <ChartPane name="bottom" height={controls?.expanded ? 75 : 55} />
        </ChartPanes>
        <ChartArea height={height - 40} background={"transparent"} />
        <ChartLegend visible={false} position={"top"} />
        {!!name && !nameHidden && (
          <ChartTitle
            text={name}
            color={colors?.titleColor ?? "var(--illio-chart-title-text, #888)"}
          />
        )}
        <ChartSeries>
          <ChartSeriesItem
            name={t("performance index")}
            type="line"
            markers={modifiers?.markers?.indexMarker ?? { visible: false }}
            width={thickness ?? modifiers?.lines?.indexLineThickness ?? 1}
            color={
              colors?.indexPerformanceColor ??
              "var(--illio-chart-primary-line-color, #3FAAA4)"
            }
            maxSize={1}
            field={"perfIndex"}
            categoryField={"date"}
            data={drawdownData}
            key={`series-performance`}
          />
          <ChartSeriesItem
            name={t("watermark")}
            type="line"
            markers={
              modifiers?.markers?.watermarkMarker ?? {
                visible: false,
                size: 0.3,
              }
            }
            dashType={"longDash"}
            width={thickness ?? modifiers?.lines?.watermarkLineThickness ?? 1}
            color={
              colors?.watermarkColor ??
              "var(--illio-chart-watermark-color, #EA7250)"
            }
            maxSize={0.2}
            field={"maxPerf"}
            categoryField={"date"}
            data={(drawdownData ?? []).filter((d, i) => true)}
            key={`series-watermark`}
          />

          <ChartSeriesItem
            type="bar"
            data={(drawdownData ?? [])?.map((val) => 0)}
            axis="bottom"
            key={`series-ghost`}
          />

          <ChartSeriesItem
            type="area"
            missingValues="interpolate"
            key={`drawdown-series`}
            name="drawdown"
            field={"drawdown"}
            opacity={1}
            color={
              colors?.drawdownColor ??
              "var(--illio-chart-drawdown-color, #E5422B)"
            }
            data={drawdownData}
            axis="drawdown"
          />
        </ChartSeries>
        <ChartValueAxis>
          <ChartValueAxisItem
            name="top"
            pane="top"
            max={maxChartValue}
            min={minChartValue}
            majorGridLines={{
              color: colors?.axisColor ?? "var(--illio-chart-axis-color, #EEE)",
              width: 0.4,
            }}
          >
            <ChartCategoryAxisLabels
              font={`${controls?.expanded ? "15" : "12"}px 'Nunito'`}
              color={"var(--color-active)"}
              content={(e) => e.value}
            />
          </ChartValueAxisItem>
          <ChartValueAxisItem
            visible={true}
            name="drawdown"
            pane="drawdown"
            color={
              colors?.axisTextColor ??
              "var(--illio-chart-axis-text-color, #666)"
            }
            majorGridLines={{
              color: colors?.axisColor ?? "var(--light-gray-for-axis)",
              width: 0.4,
            }}
          >
            <ChartCategoryAxisLabels
              color={"var(--color-active)"}
              content={(e) => `${e.value * 1}`}
            />
          </ChartValueAxisItem>
          <ChartValueAxisItem
            name="bottom"
            pane="bottom"
            color="transparent"
            majorGridLines={{
              color: "transparent",
            }}
          >
            <ChartCategoryAxisLabels
              color={"transparent"}
              content={(e) => ""}
            />
          </ChartValueAxisItem>
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            type={"date"}
            baseUnit={"days"}
            pane={"top"}
            color={"transparent"}
            crosshair={{
              visible: false,
              color:
                colors?.axisTextColor ??
                "var(--illio-chart-axis-text-color, #666)",
            }}
            maxDivisions={10}
            labels={{
              color: "var(--color-active)",
              rotation: "auto",
              content: (e) => "",
            }}
            categories={categories}
          ></ChartCategoryAxisItem>
          <ChartCategoryAxisItem
            type={"date"}
            baseUnit={"days"}
            pane={"bottom"}
            color={"transparent"}
            maxDivisions={10}
            labels={{
              color: "var(--color-active)",
              rotation: "auto",
              font: `${controls?.expanded ? "15" : "12"}px 'Nunito'`,
              content: (e) => moment(e.value).format(`DD MMM 'YY`),
            }}
            categories={categories}
          />
        </ChartCategoryAxis>
      </Chart>
    </>
  );
};
