import React from "react";
import { API_BASE, useAuth } from "@iliotech/data-wire";
import { SubscriptionControllerApi } from "@iliotech/generated-api-v3";

interface IOwnProps {
  label?: string;
}

const api = new SubscriptionControllerApi(undefined, `${API_BASE}/api`);

export const StripeAccountLink = ({ label = "Manage Payments" }: IOwnProps) => {
  const { illioSelectedAccount } = useAuth();

  const handleClick = async () => {
    try {
      const response = await api.openBillingPortal(
        illioSelectedAccount?.externalAccountId!
      );

      if (response.data.url) {
        window.open(response.data.url, "_blank", "rel=noreferrer");
      }
    } catch (error) {
      console.log(
        "An error ocurred whilst trying to open the billing portal",
        error
      );
    }
  };

  if (!illioSelectedAccount) return null;

  return <button onClick={handleClick}>{label}</button>;
};
