import React from "react";
import {
  AllocationDTO,
  PortfolioAllocationResponseData,
  PortfolioSnapshotDtoV3,
} from "@iliotech/generated-api-v3";
import cn from "classnames";
import s from "./AllocationFilter.module.scss";
import { AllocationFilterField } from "./AllocationFilterField";

export interface AllocationFilterProps {
  allocations?: PortfolioAllocationResponseData["allocations"];
  grouping?: GroupingOption;
  filters?: FilterMap;
  setFilters?: React.Dispatch<React.SetStateAction<FilterMap>>;
  setGrouping?: React.Dispatch<
    React.SetStateAction<GroupingOption | undefined>
  >;
  positionStatuses?: PositionStatus;
  positions?: PortfolioSnapshotDtoV3[];
  // positionStatuses: PositionStatus;
  setPositionStatuses: React.Dispatch<React.SetStateAction<PositionStatus>>;
  setLongShortStatuses: React.Dispatch<React.SetStateAction<LongShortStatus>>;
}

export type PositionStatus = "all" | "open" | "closed";
export type LongShortStatus = "all" | "long" | "short";

export type FilterMap<T = PortfolioSnapshotDtoV3> = Partial<{
  [Property in keyof T]: AllocationDTO;
}>;

type PortfolioAggregateField = keyof PortfolioSnapshotDtoV3;

export type GroupingOption = {
  field: PortfolioAggregateField;
  labelField: keyof PortfolioSnapshotDtoV3;
  displayLabel: string;
};

export const GROUPING_OPTIONS: GroupingOption[] = [
  {
    field: "riskAssetClassCode",
    labelField: "riskAssetClassName",
    displayLabel: "Asset Class",
  },
  // {
  //   field: "assetClassCode",
  //   labelField: "assetClassName",
  //   displayLabel: "Asset Class",
  // },
  {
    field: "custodianName",
    labelField: "custodianName",
    displayLabel: "Account",
  },
  { field: "regionCode", labelField: "regionName", displayLabel: "Region" },
  { field: "countryName", labelField: "countryName", displayLabel: "Country" },
  {
    field: "currencyLocalName",
    labelField: "currencyLocalName",
    displayLabel: "Currency",
  },
  {
    field: "underlying",
    labelField: "underlying",
    displayLabel: "Underlying",
  },
];
export const POSITION_TYPE_OPTIONS: GroupingOption[] = [
  { field: "all" as any, labelField: "name", displayLabel: "All" },
  { field: "open" as any, labelField: "name", displayLabel: "Open" },
  { field: "closed" as any, labelField: "name", displayLabel: "Closed" },
];

export const LONG_SHORT_OPTIONS: GroupingOption[] = [
  { field: "all" as any, labelField: "name", displayLabel: "All" },
  { field: "long" as any, labelField: "name", displayLabel: "Long" },
  { field: "short" as any, labelField: "name", displayLabel: "Short" },
];

export const AllocationFilter = ({
  allocations,
  setGrouping,
  setFilters,
  // filters,
  positionStatuses,
  positions,
  setPositionStatuses,
  setLongShortStatuses,
}: AllocationFilterProps) => {
  const fields = React.useMemo(() => {
    return Object.fromEntries(
      Object.entries(DEFAULT_FIELDS)
      // .filter(([, value]) => !!allocations?.[value?.allocationEnum || ""]?.length)
    );
  }, [allocations]);

  if (!allocations) {
    return <div>Loading</div>;
  }

  const setFilter = (field: keyof FilterMap, value: AllocationDTO) => {
    setFilters?.((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div
      className={cn("card", "tight")}
      style={{ padding: "1rem", marginTop: "0.5rem" }}
    >
      <div className={cn(s.surround)}>
        <label>Filter by:</label>
        {Object.entries(fields).map(([field, value]) => {
          const { label, allocationEnum, labelField } = value!;
          if (!allocationEnum) {
            return (
              <AllocationFilterField
                key={field}
                data={getFilteredData(
                  positions ?? [],
                  field as any,
                  labelField!,
                  positionStatuses
                )}
                textField={"name"}
                dataItemKey={"id"}
                label={label}
                onSelect={(event: any) => {
                  setFilter(field as any, event.value);
                }}
              />
            );
          }

          return (
            <AllocationFilterField
              key={field}
              data={
                positionStatuses === "open"
                  ? allocations[allocationEnum].filter(
                      (item) => (item?.percentage || 0) > 0
                    )
                  : positionStatuses === "closed"
                  ? allocations[allocationEnum].filter(
                      (item) => item.percentage === 0
                    )
                  : allocations[allocationEnum]
              }
              textField={"name"}
              dataItemKey={"id"}
              label={label}
              onSelect={(event: any) => {
                setFilter(field as any, event.value);
              }}
            />
          );
        })}

        <AllocationFilterField
          data={POSITION_TYPE_OPTIONS}
          textField={"displayLabel"}
          dataItemKey={"field"}
          label={"Position Status"}
          onSelect={(event: any) => {
            setPositionStatuses?.(event.value?.field);
          }}
        />

        <AllocationFilterField
          data={LONG_SHORT_OPTIONS}
          textField={"displayLabel"}
          dataItemKey={"field"}
          label={"Long or Short"}
          onSelect={(event: any) => {
            setLongShortStatuses?.(event.value?.field);
          }}
        />

        <div className={cn(s.pipe)} />
        <AllocationFilterField
          data={GROUPING_OPTIONS}
          textField={"displayLabel"}
          dataItemKey={"field"}
          label={"Group By"}
          onSelect={(event: any) => {
            setGrouping?.(event.value);
          }}
        />
      </div>
    </div>
  );
};

const getFilteredData = <
  T extends keyof PortfolioSnapshotDtoV3,
  U extends keyof PortfolioSnapshotDtoV3
>(
  positions: PortfolioSnapshotDtoV3[],
  field: T,
  labelField: U,
  posistionStatus?: string
) => {
  const map: { [fieldName: string]: string } = {};
  const positionsToUse =
    posistionStatus === "open"
      ? positions?.filter((item) => item.marketValueBase > 0)
      : posistionStatus === "closed"
      ? positions?.filter((item) => item.marketValueBase === 0)
      : positions;

  positionsToUse.forEach((position) => {
    map[`${position[field]}`] = `${position[labelField]}`;
  });

  const data = Object.entries(map).map(([id, name]) => ({ id, name }));

  return data;
};

type AllocationFilterEntry = {
  label: string;
  labelField?: keyof PortfolioSnapshotDtoV3;
  allocationEnum?: string;
  field?: keyof PortfolioSnapshotDtoV3;
};

const DEFAULT_FIELDS: Partial<{
  [Property in keyof PortfolioSnapshotDtoV3]: AllocationFilterEntry;
}> = {
  riskAssetClassCode: {
    label: "Asset Class",
    labelField: "riskAssetClassName",
    allocationEnum: "RiskAssetClass",
  },
  investmentVehicleCode: {
    label: "Instrument Type",
    labelField: "investmentVehicleName",
  },
  currencyLocalName: {
    label: "Currency",
    labelField: "currencyLocalName",
    allocationEnum: "Currency",
  },
  countryCode: {
    // Country not available in the breakdown
    label: "Country",
    labelField: "countryName",
    allocationEnum: "Country",
  },
  regionCode: {
    label: "Region",
    labelField: "countryName",
    allocationEnum: "Region",
  },
  sectorCode: {
    label: "Sector",
    labelField: "sectorName",
    allocationEnum: "IndustrySector",
  },
  custodianName: {
    label: "Account",
    labelField: "custodianName",
    allocationEnum: "Custodian",
  },
};
