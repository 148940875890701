import React, { FC } from "react";
import { IDecimalCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import moment from "moment";

export const DateCell = (): FC<IDecimalCellProps> => {
  return React.memo(({ width, ...props }) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const field = props?.field || "";
    const value = props?.dataItem?.[field];
    let displayText = !value ? "" : moment(value).format("DD MMM 'YY");
    if (displayText === "Invalid date") {
      displayText = "";
    }

    return (
      <td
        style={{
          // TODO: replace colors with appropriate css-vars
          color: "var(--color)",
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div style={{ display: "flex", width }}>
          <div style={{ width, textAlign: "right", fontWeight: 600 }}>
            {displayText}
          </div>
        </div>
      </td>
    );
  });
};
