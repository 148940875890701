import { GridColumnProps } from "@progress/kendo-react-grid";
import { CellWrapper } from "../__common/CellWrapper";

import {
  PortfolioPercentageCell,
  CurrentPriceCell,
  QuantityCell,
  NameCell,
  DeleteCell,
  TotalValueCell,
} from "./cells";
import OriginalQuantityCell from "./cells/OriginalQuantityCell";

export const whatIfPositionsColumns: (
  baseCurrencySymbol: string
) => GridColumnProps[] = (baseCurrencySymbol: string) => [
  {
    field: "name",
    title: "TICKER/NAME",
    removeBorderLeft: true,
    cell: (props) => <CellWrapper gridCellProps={props} cell={NameCell} />,
  },
  {
    field: "quantity",
    title: "QUANTITY",
    removeBorderLeft: true,
    cell: (props) => <CellWrapper gridCellProps={props} cell={QuantityCell} />,
  },
  {
    field: "oldQuantity",
    title: "ORIGINAL QUANTITY",
    rightAligned: true,
    removeBorderLeft: true,
    cell: (props) => (
      <CellWrapper
        gridCellProps={props}
        cell={OriginalQuantityCell}
        rightAligned
      />
    ),
  },
  {
    field: "portfolioPercentage",
    title: "PORTFOLIO %",
    rightAligned: true,
    removeBorderLeft: true,
    cell: (props) => (
      <CellWrapper
        gridCellProps={props}
        cell={PortfolioPercentageCell}
        rightAligned
      />
    ),
  },
  {
    field: "price",
    filterable: true,
    rightAligned: true,
    removeBorderLeft: true,
    title: `PRICE 1 YR AGO`,
    cell: (props) => (
      <CellWrapper gridCellProps={props} cell={CurrentPriceCell} rightAligned />
    ),
  },
  {
    field: "totalValue",
    rightAligned: true,
    removeBorderLeft: true,
    title: `TOTAL VALUE (${baseCurrencySymbol})`,
    cell: (props) => (
      <CellWrapper gridCellProps={props} cell={TotalValueCell} rightAligned />
    ),
  },
  {
    field: "",
    width: 50,
    rightAligned: true,
    removeBorderLeft: true,
    title: ``,
    cell: (props) => <CellWrapper gridCellProps={props} cell={DeleteCell} />,
  },
];
