import React from "react";
import { Step } from "../../Step";
import {
  BrokerInstituteDto,
  BrokerInstituteDtoConnectionStatusEnum,
  BrokerInstituteDtoDataStatusEnum,
} from "@iliotech/generated-api-v3";
import ConnectIbDetailsScreen from "../../../__pageComponents/ConnectBrokerPage/ConnectDetailsScreen/ConnectIbDetailsScreen";

interface IOwnProps {
  onStart(): Promise<void>;
  onBack(): void;
  onSkip(): Promise<void>;
  closeModal(): void;
  popupModal: boolean;
}

export const IBConnectAccountIntro = ({
  onStart,
  onBack,
  onSkip,
  closeModal,
  popupModal,
}: IOwnProps) => {
  const provider: BrokerInstituteDto = {
    brokerProviderId: "IB",
    brokerName: "IB",
    connectionStatus: BrokerInstituteDtoConnectionStatusEnum.Amber,
    dataStatus: BrokerInstituteDtoDataStatusEnum.Green,
    countryCode: "Global",
    name: "Interactive Brokers",
    icon: "images/IB-InteractiveBrokers.svg",
    popular: true,
  };

  return (
    <Step currentStep={3} onBack={onBack}>
      <ConnectIbDetailsScreen
        createPortfolioManually={onSkip}
        broker={provider}
        onConnect={onStart}
        goBack={onBack}
        popupModal={popupModal}
        handleClose={closeModal}
      />
    </Step>
  );
};
