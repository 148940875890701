import React from "react";
import { Button, FormModal } from "@iliotech/component-library";
import {
  SUPPORT_EMAIL_ADDRESS,
  useAccountStatus,
  useAuth,
} from "@iliotech/data-wire";
import { useLogout } from "../../hooks/useLogout";
import { StripeAccountLink } from "./MyProfile/components/StripeAccountLink";

interface IOwnProps {}

const STRIPE_ACCOUNT_BUTTON_TEXT = "Reactivate my account";

export const PaymentResolutionPrompt = (props: IOwnProps) => {
  const { illioSelectedAccount } = useAuth();
  const accountStatus = useAccountStatus(
    illioSelectedAccount?.externalAccountId
  );

  const logout = useLogout();

  // const loading = accountStatus.isLoading;
  const accountSource = accountStatus.data?.data?.accountSource;

  let content: {
    button: React.ReactNode;
    title: string;
    instructions: string | React.ReactNode;
  } = {
    button: <Button>Loading</Button>,
    title: "No subscription",
    instructions: (
      <div>
        <p>
          Your account access has been temporarily restricted due to payment
          issues. Please contact your account holder to resolve.
        </p>
        <ContactHelp />
      </div>
    ),
  };

  switch (accountSource) {
    case "Enterprise":
      content.button = <div />;
      content.instructions = (
        <div>
          <p>
            Your account access has been temporarily restricted due to payment
            issues. Please contact your account holder to resolve.
          </p>
          <ContactHelp />
        </div>
      );
      break;
    case "Direct":
      content = {
        button: <StripeAccountLink label={STRIPE_ACCOUNT_BUTTON_TEXT} />,
        title: "Subscription issue",
        instructions: (
          <div>
            <p>
              Your account access has been temporarily restricted due to payment
              issues. In order to regain access to your account, please click on
              the "{STRIPE_ACCOUNT_BUTTON_TEXT}" button and resolve the payment
              issues
            </p>
            <ContactHelp />
          </div>
        ),
      };
      break;
    case "Platform":
      content.button = <div />;
      content.instructions = (
        <div>
          <p>
            Your trial has ended. In order to regain access to your account
            please resubscribe via the platform you signed up with
          </p>
          <ContactHelp />
        </div>
      );
      break;
  }

  const footer = (
    <FormModal.Footer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          style={{
            backgroundColor: "var(--contrast-inverse)",
            color: "var(--contrast-color)",
            border: "solid",
            borderColor: " var(--contrast-color)",
            borderWidth: 1,
          }}
          onClick={logout}
        >
          Log Off
        </Button>
        {content.button}
      </div>
    </FormModal.Footer>
  );

  return (
    <FormModal
      visible={true}
      handleClose={() => {}}
      hideClose
      title={content.title}
      footer={footer}
    >
      {content.instructions}
    </FormModal>
  );
};

const ContactHelp = () => {
  return (
    <p>
      If you have any questions about this, please fee free to get in touch at{" "}
      <a
        href={`mailto:${SUPPORT_EMAIL_ADDRESS}?subject=Account%20Restriction%20Query`}
      >
        {SUPPORT_EMAIL_ADDRESS}
      </a>
    </p>
  );
};
