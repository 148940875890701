import * as React from "react";
import { AccountSummaryDTO } from "@iliotech/generated-api-v3";
import s from "./SelectAccountPage.module.scss";
import { Blur } from "../../Blur/Blur";
import { BasicHeader } from "../../BasicHeader/BasicHeader";
import { Link } from "react-router-dom";
import AccountList from "./components/AccountList/AccountList";
import { useAuth } from "@iliotech/data-wire";

interface IProps {
  accountList?: AccountSummaryDTO[];
  onSelectAccount: (account: AccountSummaryDTO | null) => void;
}

export const SelectAccountPage = ({ accountList, onSelectAccount }: IProps) => {
  const { logOut } = useAuth();

  return (
    <Blur>
      <div className={s.container}>
        <BasicHeader
          title={"Select Account"}
          hideProfile={true}
          logOut={logOut}
        />
        <div className={s.wrapper}>
          <Link to={"#"}>+ Create account</Link>
          <div className={s.inner}>
            <AccountList onClick={onSelectAccount} accountList={accountList!} />
          </div>
        </div>
      </div>
    </Blur>
  );
};

export default SelectAccountPage;
