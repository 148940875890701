import axios, { AxiosRequestConfig } from "axios";
import { useQuery, UseQueryOptions } from "react-query";

import { Auth } from "aws-amplify";
import { API_BASE } from "@iliotech/data-wire";
import { LOCAL_STORAGE_KEYS } from "../constants/constants";
// import { errorStatusHandler } from '../utils/HTTPErrors/errorStatusHandler';

/*
 * sendRequest
 * Can be used for requests that shouldn't be included in the cache (e.g. non-idempotent / unsafe calls)
 * Sets appropriate headers for illio services
 * */
export const sendRequest = <T = any>(
  endpoint: string,
  options?: AxiosRequestConfig,
  data?: any
): Promise<T> =>
  Auth.currentSession()
    .then(async (session) => {
      console.log({ session });
      axios.defaults.timeout = 20000;
      axios.defaults.headers.common.Authorization = `Bearer ${session
        .getAccessToken()
        .getJwtToken()}`;
    })
    .then(() => {
      return axios({
        url: endpoint,
        method: options?.method || "get",
        responseType: "json",
        baseURL: API_BASE as string,
        data,
        withCredentials: true,
        ...options,
        headers: {
          ...options?.headers,
          "illio-selected-account": 1,
          "illio-selected-workgroup": 12,
          // illio-selected-account is handled in App.tsx
        },
      }).then((result) => result.data);
    })
    .catch((error) => {
      console.log("Send request error", { endpoint, options, data }, error);
    });

const queryFn =
  <T = any>(endpoint: string, options?: AxiosRequestConfig, data?: any) =>
  () =>
    sendRequest<T>(endpoint, options, data);
// .catch(errorStatusHandler);

/*
 * useAPI
 * Sets appropriate headers for illio services
 * Sets some defaults for React Query
 * Uses url as default for cache key (can be overridden in queryConfig)
 * */
export const useApi = <T = any>(
  endpoint: string,
  options?: AxiosRequestConfig,
  queryConfig?: UseQueryOptions<T>
) => {
  return useQuery<T>(
    [
      endpoint,
      JSON.stringify({
        data: options?.data,
        headers: options?.headers,
        params: options?.params,
      }),
    ],
    queryFn<T>(endpoint, options),
    {
      retry: 1,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...queryConfig,
    }
  );
};
export default useApi;
