import {
  InstrumentSearchResultV3,
  InstrumentsApi,
} from "@iliotech/generated-api-v3";
import { ComboBoxProps } from "@progress/kendo-react-dropdowns";
import { AutocompleteAsync } from "../__inputs/AutocompleteAsync/AutocompleteAsync";
import { API_BASE, REQUEST_DATE_FORMAT, useAuth } from "@iliotech/data-wire";
import moment from "moment";

type searchTypes = Array<
  | "Fx"
  | "Subscription"
  | "Crypto"
  | "Equity"
  | "Fund"
  | "Index"
  | "FixedIncome"
  | "Commodity"
  | "Option"
  | "OtcOption"
  | "Future"
  | "StructuredProduct"
>;
interface IOwnProps extends ComboBoxProps {
  tradeTime?: string;
  types?: searchTypes;
  getLabel?: (item: InstrumentSearchResultV3) => string;
  placeholder?: string;
  emptyMessage?: string;
}

const DEFAULT_TYPES: searchTypes = [
  "Fx",
  "Subscription",
  "Crypto",
  "Equity",
  "Fund",
  "FixedIncome",
  "Commodity",
  "Option",
  "Future",
  "StructuredProduct",
];

const defaultGetLabel = (item: InstrumentSearchResultV3) =>
  item?.name + " " + item?.label;

export function ResolveInstrumentAutoComplete({
  tradeTime = moment().format(REQUEST_DATE_FORMAT),
  types = DEFAULT_TYPES,
  getLabel = defaultGetLabel,
  placeholder = "Enter ticker or name",
  ...rest
}: IOwnProps) {
  const { illioSelectedAccount } = useAuth();

  const onInstrumentSearch = async (text: string) => {
    const api = new InstrumentsApi(undefined, `${API_BASE}/api`);
    const data = await api.search(
      illioSelectedAccount?.externalAccountId!,
      text,
      types,
      undefined
    );
    return (
      data?.data.map((item) => ({
        ...item,
        label: getLabel(item),
      })) || []
    );
  };

  return (
    <AutocompleteAsync
      allowCustom={true}
      dataItemKey={"code"}
      textField={"label"}
      filterData={onInstrumentSearch}
      placeholder={placeholder}
      {...rest}
    />
  );
}
