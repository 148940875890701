import { useWhatIfPositions } from "@iliotech/data-wire/src/stores";
import { WhatIfPositionsTableCellProps } from "../WhatIfPositionsTable";
import { observer } from "mobx-react-lite";

const CurrentPriceCell = ({ index }: WhatIfPositionsTableCellProps) => {
  const store = useWhatIfPositions();
  const currentPrice = store.getField<number>("price", index);

  const formattedPrice = currentPrice
    ? currentPrice.toLocaleString(undefined, {
        maximumFractionDigits: 6,
      })
    : "";

  return <div>{formattedPrice}</div>;
};

export default observer(CurrentPriceCell);
