import React from "react";
import cn from "classnames";
import s from "./AllocationFilter.module.scss";
import { FaChevronDown } from "react-icons/fa";
import { DropDownMenu } from "../DropDownMenu/DropDownMenu";

interface IOwnProps<T> {
  data: T[];
  textField: keyof T;
  dataItemKey: keyof T;
  label: string;
  filterActive?: boolean;
  onSelect?(item: { value: T }): void;
}

export const AllocationFilterField = <T,>({
  data,
  textField,
  dataItemKey,
  label,
  onSelect,
  filterActive,
}: IOwnProps<T>) => {
  const sortedData = (data || []).sort((a, b) =>
    `${a[textField]}`.localeCompare(`${b[textField]}`)
  );

  return (
    <div className={s.container}>
      <DropDownMenu
        data={sortedData}
        textField={textField}
        dataItemKey={dataItemKey}
        onSelect={onSelect}
        button={
          <div className={cn(s.button, filterActive && s.active)}>
            {label}
            <FaChevronDown className={cn(s.chevron)} size={8} />
          </div>
        }
      />
    </div>
  );
};
