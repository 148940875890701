import { API_BASE } from "../../../constants/constants";
import { useQuery } from "react-query";
import { useAuth } from "../../context";
import { SubscriptionControllerApi } from "@iliotech/generated-api-v3";

const api = new SubscriptionControllerApi(undefined, `${API_BASE}/api`);
export const useAccountUpgrades = () => {
  const { illioSelectedAccount } = useAuth();
  const externalAccountId = illioSelectedAccount?.externalAccountId;

  const query = useQuery(
    ["account-upgrades", { externalAccountId }],
    () => api.getUpgrades1(externalAccountId!),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  return {
    ...query,
  };
};
