import {
  AccountCapabilityKeyEnum,
  AccountSummaryDTO,
  AccountUserCapabilitiesDTO,
  UserLimits,
} from "@iliotech/generated-api-v3";
import React from "react";

export interface IAuthContext {
  illioSelectedAccount?: AccountSummaryDTO;
  availableAccounts?: AccountSummaryDTO[];
  selectIllioAccount(account: AccountSummaryDTO | null): void;
  logOut(): void;
  isCustomer: boolean;
  isOwner: boolean;
  isManager: boolean;
  capabilities?: AccountUserCapabilitiesDTO;
  userLimits?: UserLimits;
  hasBundle(bundle: BundlesEnum): boolean;
}
export type ISSUE_CATEGORY =
  | "ACCOUNT_INACTIVE"
  | "BILLING"
  | "GENERAL_ISSUE"
  | "NONE"
  | "SUBSCRIPTION_REQUIRED"
  | "USAGE_LIMIT_REACHED";

export enum BundlesEnum {
  Dashboard = "Dashboard",
  Insights_all = "Insights_all",
  Manager_Functions = "Manager_Functions",
  Add_Common_Instruments = "Add_Common_instruments",
  Advanced_View = "Advanced_View",
  Basic_View = "Basic_View",
  Broker_Connections = "Broker_Connections",
  Advanced_View_b = "Advanced_View_b",
  Add_Unlisted_Assets = "Add_Unlisted_Assets",
}

export const AuthContext = React.createContext<IAuthContext>({
  selectIllioAccount: () =>
    console.log("Tried to set selected account - Auth context not ready"),
  illioSelectedAccount: undefined,
  availableAccounts: [],
  logOut: () =>
    console.log("Tried to logout selected account - Auth context not ready"),
  isCustomer: false,
  isOwner: false,
  isManager: false,
  hasBundle: (bundle: BundlesEnum) => false,
});

export const useAuth = () => React.useContext(AuthContext);
