import * as React from "react";
import s from "./IllioDialogStructured.module.scss";
import IllioDialog from "../IllioDialog/IllioDialog";
import { DialogActionsBar, DialogProps } from "@progress/kendo-react-dialogs";

interface IProps extends DialogProps {
  children: React.ReactNode | React.ReactNode[];
  header: React.ReactNode;
  footer?: React.ReactNode | React.ReactNode[];
}

const IllioDialogStructured = ({
  children,
  header,
  footer,
  ...rest
}: IProps) => {
  return (
    <IllioDialog {...rest} closeIcon={true}>
      <div className={s.header}>{header}</div>
      {children}
      <DialogActionsBar>
        <div className={s.footer}>{footer}</div>
      </DialogActionsBar>
    </IllioDialog>
  );
};

export default IllioDialogStructured;
