import s from "./container.module.scss";

export const createContainer = () => {
  const portalId = "notifyContainer";
  let element = document.getElementById(portalId);
  
  if (element) {
    return element;
  }
  
  element = document.createElement("div");
  element.setAttribute("id", portalId);
  element.className = s.container;
  document.body.appendChild(element);
  return element;
}
