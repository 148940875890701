import React from "react";
import { sendTracking, useGlobalPeriod } from "@iliotech/data-wire";
import { PERIOD_IDS, PeriodChooser } from "../PeriodChooser/PeriodChooser";
import { MomentInput } from "moment";
import { ITimePeriodV2 } from "@iliotech/types";

interface IOwnProps {
  onPeriodSelectionChanged?(period?: ITimePeriodV2): void;
  inceptionDateOverride?: MomentInput;
  excludedPeriods?: PERIOD_IDS[];
}

export const GlobalPeriodChooser = ({
  onPeriodSelectionChanged,
  inceptionDateOverride,
  excludedPeriods = [],
}: IOwnProps) => {
  const { period, setPeriod, inceptionDate, epochDate } = useGlobalPeriod();

  const handlePeriodChange = (p?: ITimePeriodV2) => {
    setPeriod(p);
    sendTracking(`period_changed_to_${p?.label}`, "period_select");
    onPeriodSelectionChanged?.(p);
  };

  return (
    <PeriodChooser
      epochDate={epochDate}
      excludedPeriods={excludedPeriods}
      inceptionDate={inceptionDateOverride ?? inceptionDate}
      onPeriodSelected={handlePeriodChange}
      selectedPeriod={period}
    />
  );
};
