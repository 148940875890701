import * as React from "react";
import { FormModal } from "../FormModal/FormModal";
import { Typography } from "@progress/kendo-react-common";

import s from "./AddCustodianModal.module.scss";
import { CgSearch } from "react-icons/cg";
import { useState } from "react";
// import { custodiansList } from "./custodiansData";
import { useUserCustodians } from "@iliotech/data-wire";
import { Checkbox } from "../CheckBox/Checkbox";
import { RoundButton } from "../RoundButton/RoundButton";
import { Input } from "../FormInputs/Input/Input";
import Button from "../Button/Button";

import cn from "classnames";
import { PortfolioCustodian } from "@iliotech/generated-api-v3";
import { ConnectedInformationButton } from "../InformationButton";
import { SeamlessToggle } from "../SeamlessToggle/SeamlessToggle";

interface IProps {
  visible: boolean;
  handleClose: () => void;
  wrapperId: string;
  portfolioCustodians: PortfolioCustodian[];
  onSelectCustodian?: (
    selectedCustodian: PortfolioCustodian | null,
    isDefault?: boolean
  ) => void;
}

export const AddCustodianModal = ({
  visible,
  handleClose,
  wrapperId,
  onSelectCustodian,
  portfolioCustodians,
}: IProps) => {
  const [search, setSearch] = useState("");
  const [isDefault, setDefault] = useState<boolean>(false);
  const allCustodians = useUserCustodians();
  const custodiansList = allCustodians?.data?.data;

  const [corporateAction, setCorporateAction] = useState<string>("Manual");
  const addableCustodinas = React.useMemo(() => {
    if (!custodiansList?.length) {
      return [];
    }
    const portfolioCustodiansCodes = portfolioCustodians.map((i) => i.code);
    return custodiansList.filter((custodian) => {
      return !portfolioCustodiansCodes.includes(custodian.code);
    });
  }, [custodiansList, portfolioCustodians]);
  const [selectedCustodian, setSelectedCustodian] =
    useState<PortfolioCustodian | null>(null);

  const onCancel = () => {
    setSearch("");
  };

  const onSelect = () => {
    onSelectCustodian?.(
      {
        ...selectedCustodian,
        default: isDefault,
        onlyManualDividends: corporateAction === "Manual",
        code: selectedCustodian?.code || selectedCustodian?.name || "",
      },
      isDefault
    );
  };

  const custodians = React.useMemo(
    () =>
      addableCustodinas
        ?.filter((c) => c?.name?.toLowerCase().includes(search.toLowerCase()))
        .sort((a, b) => {
          if (a.default) return -10;
          if (b.default) return 10;
          return a.name?.localeCompare(b.name || "z") ?? 1;
        }),
    [search, addableCustodinas]
  );

  const custodianItem = (custodian: PortfolioCustodian) => {
    return (
      <div
        key={custodian.id}
        onClick={() => setSelectedCustodian(custodian)}
        className={cn(
          s.custodianRow,
          custodian.id === selectedCustodian?.id && s.selectedCustodianRow
        )}
      >
        <span className={s.custodianItem}>{custodian.name}</span>
      </div>
    );
  };

  return (
    <FormModal
      handleClose={handleClose}
      visible={visible}
      wrapperId={wrapperId}
      footer={
        <div className={s.footer}>
          <div>
            <div
              style={{
                display: "flex",
                gap: 20,
                marginLeft: -6,
                marginRight: 2,
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <ConnectedInformationButton
                code={"onlyManualDividends"}
                position={"bottom"}
                invert={true}
              >
                <p style={{ marginBottom: 0, marginLeft: 1.2 }}>
                  Corporate Actions:
                </p>
              </ConnectedInformationButton>
              <SeamlessToggle
                disabled={!selectedCustodian}
                values={["Manual", "Auto"]}
                onChange={setCorporateAction}
              />
            </div>
            <div style={{ height: 20 }} />
            <Checkbox
              label={"Make my default account"}
              checked={isDefault}
              onChange={(event) => setDefault(event.value)}
              disabled={!selectedCustodian}
            />
          </div>
          <RoundButton disabled={!selectedCustodian} onClick={onSelect}>
            Add account
          </RoundButton>
        </div>
      }
    >
      <Typography.h3>Add new account</Typography.h3>
      <div className={s.row}>
        <div className={s.inputWrapper}>
          <CgSearch color={"var(--border-color)"} size={24} />
          <Input
            onChange={(e) => setSearch(e.value)}
            value={search}
            className={s.searchInput}
            name={"search"}
          />
        </div>
        <Button onClick={onCancel} className={"illio-button"}>
          Cancel
        </Button>
      </div>
      <div className={s.resultsWrapper}>
        {(custodians ?? [])?.length > 0
          ? custodians?.map((c) => custodianItem(c))
          : custodianItem({
              code: search,
              name: search,
              default: false,
              id: "1",
            })}
      </div>
    </FormModal>
  );
};

export default AddCustodianModal;
