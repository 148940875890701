import * as React from "react";
import { FormRenderProps } from "@progress/kendo-react-form";
import { FormInputs } from "../../FormInputs";
import { EnumDisplay } from "@iliotech/generated-api-v3";
import {
  getFlagEmoji,
  portfolioNameValidator,
  useAuth,
} from "@iliotech/data-wire";
import { useParams } from "react-router";
import { searchWorkgroups } from "@iliotech/data-wire/src/requests/searchWorkgroups";

interface IProps {
  formRenderProps: FormRenderProps;
  allPositions: Record<string, EnumDisplay> | undefined;
  allCurrencies: string[] | undefined;
}

export const PortfolioForm = ({ formRenderProps, allCurrencies }: IProps) => {
  const { workgroup = "" } = useParams();
  const { portfolioId = "" } = useParams();

  const workgroupPassed = workgroup && workgroup !== "select";
  const { illioSelectedAccount } = useAuth();

  const onWorkgroupSearch = async (text: string) => {
    const data = await searchWorkgroups(
      text,
      illioSelectedAccount?.externalAccountId!,
      true
    );
    // workgroup created from input
    const newWorkgroup = [
      {
        code: text,
        label: text,
      },
    ];
    // if no results then return only the new workgroup
    if (!data?.data?.length) return newWorkgroup;

    const workgroupsData = (data?.data || []).map((workgroup) => ({
      code: workgroup?.id,
      label: workgroup?.name,
    }));
    // if workgroupsData contains the workgroup query, then just return workgroupsData

    if (
      workgroupsData.some(
        (workgroup) => workgroup.label.toLowerCase() === text.toLowerCase()
      )
    )
      return workgroupsData;
    // else return the new workgroup and the workgroupsData
    return [...newWorkgroup, ...workgroupsData];
  };

  return (
    <div style={{ width: "100%" }}>
      <FormInputs.Common.NameField
        label="Portfolio Name"
        name="name"
        placeholder="e.g. 'My Portfolio'"
        errors={formRenderProps?.errors}
        validator={portfolioNameValidator}
      />
      {workgroup === "select" && (
        <FormInputs.Common.WorkgroupSearchField
          name={"workgroupName"}
          label={"Portfolio group"}
          placeholder={"Select a group"}
          errors={formRenderProps?.errors}
          textField={"label"}
          isAsync={true}
          filterData={onWorkgroupSearch}
        />
      )}
      <FormInputs.Common.CurrencySearchField
        allCurrencies={allCurrencies}
        disabled={!!portfolioId}
        errors={formRenderProps?.errors}
      />
      {!!portfolioId && (
        <div style={{ marginTop: 10 }}>
          This portfolio has already been saved to your account so you can’t
          change the portfolio currency. If you need to change the portfolio
          currency, please create a new portfolio.
        </div>
      )}
      {workgroupPassed && (
        <div style={{ marginTop: 10 }}>
          NB: This portfolio will be added to {workgroup}
        </div>
      )}
    </div>
  );
};

export default PortfolioForm;
