import React, {PropsWithChildren} from 'react';
import {usePusherContext} from "@iliotech/data-wire";
import cn from "classnames";
import s from "./PNLLoading.module.scss"
import {Loader} from "@progress/kendo-react-indicators";
import {Tooltip} from "@progress/kendo-react-tooltip";


interface IOwnProps {
  loading?: boolean;
}

export const PNLLoading = ({children, loading}: PropsWithChildren<IOwnProps>) => {
  
  const {pnlCalcState} = usePusherContext();
  
  const isCalculating = pnlCalcState !== "green";
  const show = (pnlCalcState !== "green") || loading;
  const tooltipText = isCalculating ? "Calculating your P&L" : "Loading your data"
  
  return (
    <div className={cn(s.surround)}>
      <div className={cn(s.surround, (show || loading) && s.fade)} style={{position: "relative", height: "100%"}}>
        {children}
      </div>
      {
        show && (
          <div className={cn(s.loader)}>
            <Tooltip anchorElement="target" position={"left"}>
              <div title={tooltipText}>
                <Loader type={"infinite-spinner"} themeColor={"light"}
                        style={{color: "var(--switch-background-color)"}}/>
              
              </div>
            </Tooltip>
          </div>
        )
      }
    
    </div>
  
  );
};
