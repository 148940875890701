import * as React from "react";
import s from "./DropDown.module.scss";
import {
  DropDownList,
  DropDownListProps,
} from "@progress/kendo-react-dropdowns";
import cn from "classnames";
import InputWrapper from "../InputWrapper/InputWrapper";

interface IProps extends DropDownListProps {
  illioInputProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  popupClass?: string;
  name?: string;
  label?: string;
  error?: string;
  visited?: boolean;
}

export const DropDown = ({
  className,
  illioInputProps,
  error,
  visited,
  name,
  label,
  popupClass,
  ...rest
}: IProps) => {
  const isError = error && visited;

  return (
    <div className={"illio-input"} {...illioInputProps}>
      <InputWrapper error={isError ? error : ""} label={label} name={name!}>
        <DropDownList
          popupSettings={{ popupClass: cn("illio-combo", popupClass) }}
          className={cn(s.dropDown, className)}
          {...rest}
        />
      </InputWrapper>
    </div>
  );
};

export default DropDown;
