import React from "react";
import cn from "classnames";
import s from "./BasicHeader.module.scss";
import { Link } from "react-router-dom";
import { DEFAULT_LOGO_SOURCE } from "../__pageComponents";
import { Typography } from "@progress/kendo-react-common";
import { useSmallScreen } from "@iliotech/data-wire";
import logout from "./assets/logout.svg";
import profile from "./assets/profile.svg";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AiOutlineInfoCircle } from "react-icons/all";
import ModalBackButton from "../ModalBackButton/ModalBackButton";
import { ThemeSwitcher } from "../ThemeSwitcher/ThemeSwitcher";
const { h6: H6 } = Typography;

interface IOwnProps {
  title: string;
  hideLogOff?: boolean;
  hideProfile?: boolean;
  hideLogo?: boolean;
  showHelp?: boolean;
  onSelectAccount?: () => void;
  logOut?: () => void;
  marginTop?: number;
  showThemeSwitcher?: boolean;
}

export const BasicHeader = ({
  title,
  hideProfile,
  hideLogOff,
  hideLogo,
  showHelp,
  onSelectAccount,
  logOut,
  marginTop = 30,
  showThemeSwitcher,
}: IOwnProps) => {
  const small = useSmallScreen();

  const renderProfile = () => {
    if (hideProfile) return null;
    if (small) {
      return (
        <Link to={"/profile"}>
          <Tooltip openDelay={0} position="bottom" anchorElement={"target"}>
            <img
              src={profile}
              alt={"profile"}
              title={"open profile"}
              className={s.icon}
            />
          </Tooltip>
        </Link>
      );
    }
    return <Link to={"/profile"}>My profile</Link>;
  };

  const renderLogOff = () => {
    if (hideLogOff) return null;
    if (small) {
      return (
        <Link onClick={logOut} to={"/"}>
          <Tooltip openDelay={0} position="bottom" anchorElement={"target"}>
            <img
              src={logout}
              alt={"logout"}
              title="log out"
              className={s.icon}
            />
          </Tooltip>
        </Link>
      );
    }
    return (
      <Link to={"/"} onClick={logOut}>
        Log off
      </Link>
    );
  };

  const renderHelp = () => {
    if (!showHelp) return null;
    if (small) {
      return (
        <a href={"https://support.illio.com/"} target={"_blank"}>
          <Tooltip openDelay={0} position="bottom" anchorElement={"target"}>
            <AiOutlineInfoCircle className={s.icon} />
          </Tooltip>
        </a>
      );
    }
    return (
      <a href={"https://support.illio.com/"} target={"_blank"}>
        Help center
      </a>
    );
  };

  const renderLogo = () => {
    if (hideLogo)
      return <div className={cn(s.logoSurround)} style={{ flex: 1 }} />;
    return (
      <Link className={cn(s.logoSurround)} style={{ flex: 1 }} to={"/"}>
        <img alt={"logo"} src={DEFAULT_LOGO_SOURCE} className={cn(s.logo)} />
      </Link>
    );
  };

  const renderThemeSwitcher = () => {
    if (showThemeSwitcher) {
      return <ThemeSwitcher />;
    }
  };

  const renderBack = () => {
    if (!hideLogo || !onSelectAccount) return null;
    return (
      <ModalBackButton
        style={{ top: marginTop }}
        onPress={onSelectAccount}
        text={"Select account"}
      />
    );
  };

  return (
    <div className={cn(s.topRow)}>
      {renderLogo()}
      {renderBack()}
      <H6 className={cn("element-title", s.title)}>{title}</H6>
      <div className={cn(s.logout, hideLogOff && s.hideLogOff)}>
        {renderHelp()}
        {renderProfile()}
        {renderLogOff()}
        {renderThemeSwitcher()}
      </div>
    </div>
  );
};
