/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { ExchangeRateGetResponse } from '../../aggregator';
/**
 * ExchangeRateControllerApi - axios parameter creator
 * @export
 */
export const ExchangeRateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} from 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} to 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFxRate: async (from: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', to: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getFxRate', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getFxRate', 'to', to)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getFxRate', 'date', date)
            const localVarPath = `/v1/fx-rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeRateControllerApi - functional programming interface
 * @export
 */
export const ExchangeRateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeRateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} from 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} to 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFxRate(from: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', to: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeRateGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFxRate(from, to, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExchangeRateControllerApi - factory interface
 * @export
 */
export const ExchangeRateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeRateControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} from 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} to 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFxRate(from: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', to: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', date: string, options?: any): AxiosPromise<ExchangeRateGetResponse> {
            return localVarFp.getFxRate(from, to, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangeRateControllerApi - object-oriented interface
 * @export
 * @class ExchangeRateControllerApi
 * @extends {BaseAPI}
 */
export class ExchangeRateControllerApi extends BaseAPI {
    /**
     * 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} from 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} to 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRateControllerApi
     */
    public getFxRate(from: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', to: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', date: string, options?: AxiosRequestConfig) {
        return ExchangeRateControllerApiFp(this.configuration).getFxRate(from, to, date, options).then((request) => request(this.axios, this.basePath));
    }
}
