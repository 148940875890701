import {
  OTCInstrumentsApi,
  PricedInstrumentCreateRequestEquitySystemInstrumentRequest,
  PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest,
} from "../../../generated-api-v3";
import { API_BASE } from "../constants/constants";

const api = new OTCInstrumentsApi(undefined, `${API_BASE}/api`);

export const createOptionInstrumentOtc = (
  instrument: PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest,
  externalAccountId: string
) => {
  return api.addOptionWarrantInstrument(instrument, externalAccountId);
};
