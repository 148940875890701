import React, { useCallback, useEffect } from "react";
import { PortfolioGrid } from "./PortfolioGrid";
import Separator from "../Separator/Separator";
import { SearchBox } from "../SearchBox/SearchBox";
import cn from "classnames";
import s from "./SearchablePortfolioList.module.scss";
import { StatusModal } from "../__modals/StatusModal/StatusModal";
import AwaitingDialog from "./awaitingDialog";
import { usePortfolioListSharedLogic } from "@iliotech/data-wire";

export const SearchablePortfolioList = () => {
  const {
    portfolios,
    searchText,
    setSearchText,
    filteredPortfolios,
    loading,
    modal,
    setModal,
    filteredSamplePortfolios,
    samplePortfolios,
  } = usePortfolioListSharedLogic();

  return (
    <div className={cn(s.surround)}>
      <AwaitingDialog visible={modal} closeModal={() => setModal(false)} />
      <StatusModal loading={loading} text={"Update in progress"} />
      <SearchBox
        value={searchText}
        onChange={setSearchText}
        placeholder={"Search Portfolios"}
        className={cn(s.searchSurround)}
      />

      <h6 className={"element-title"}>Your portfolios</h6>
      <p>
        These are portfolios that you have set up with your own investments.
      </p>

      <PortfolioGrid
        portfolios={filteredPortfolios}
        loading={portfolios.isLoading}
      />
      <Separator />

      <h6 className={"element-title"}>Example portfolios</h6>
      <p>These are example portfolios created for you to explore illio.</p>
      <PortfolioGrid
        portfolios={filteredSamplePortfolios}
        loading={samplePortfolios.isLoading}
      />
    </div>
  );
};
