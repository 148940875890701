import {
  CreateOptionTrade,
  CreateStandardTrade,
  CreateSwapTrade,
  EditTradeRequest,
  PortfolioTradesApi,
  CreateMortgageTrade,
  CreateReduceMortgageTrade,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);
export const addStandardTrade = (
    portfolioId: string,
    trade: CreateStandardTrade
) => {
  return api.addPortfolioStandardTrade(trade, portfolioId);
};

export const addCryptoSwap = (portfolioId: string, trade: CreateSwapTrade) => {
  return api.addPortfolioCryptoSwapTrade(trade, portfolioId);
};

export const editStandardTrade = (
    portfolioId: string,
    trade: EditTradeRequest,
    tradeKey: number | string
) => {
  return api.editPortfolioTrade(trade, portfolioId, parseInt(`${tradeKey}`));
};

export const addOption = (portfolioId: string, option: CreateOptionTrade) => {
  return api.addPortfolioOptionTrade(option, portfolioId);
};

export const addMortgage = (
    portfolioId: string,
    trade: CreateMortgageTrade
) => {
  return api.addPortfolioMortgageTrade(trade, portfolioId);
};
export const reduceMortgage = (
    portfolioId: string,
    trade: CreateReduceMortgageTrade
) => {
  return api.addPortfolioMortgageReductionTrade(trade, portfolioId);
};
