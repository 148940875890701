import * as React from "react";
import { useCallback, useEffect } from "react";
import { usePortfolioList, useWorkgroupInfoV3 } from "../../api";
import { useAuth } from "../../context";
import { useParams } from "react-router-dom";
import { filterPortfolios } from "@iliotech/component-library/src/components/PortfolioList/utils";
import { useWorkgroupPortfolioList } from "../useWorkgroupPortfolioList";

export const usePortfolioListSharedLogic = () => {
  const { illioSelectedAccount, isCustomer } = useAuth();
  const { portfolios, workgroupName, workgroupRequest } =
    useWorkgroupPortfolioList();

  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const samplePortfolios = usePortfolioList(
    illioSelectedAccount?.externalAccountId!,
    "GLOBAL",
    workgroupName !== "Default" && !isCustomer
  );
  const [searchText, setSearchText] = React.useState("");

  const { filteredPortfolios, filteredSamplePortfolios } = React.useMemo(() => {
    const filteredPortfolios = filterPortfolios(searchText, portfolios!);
    const filteredSamplePortfolios = filterPortfolios(
      searchText,
      samplePortfolios?.data?.data!
    );
    return { filteredPortfolios, filteredSamplePortfolios };
  }, [searchText, portfolios, samplePortfolios]);

  const portfoliosAwaitingIntegration = React.useMemo(() => {
    if (portfolios?.length) {
      return false;
    }
    return portfolios?.find((item) => {
      const integrations: { [key: string]: string } = item.integrations || {};
      const keys = Object.keys(integrations).filter(
        (s) => integrations[s] === "AWAITING_FILES"
      );

      return keys.length > 0;
    });
  }, [workgroupRequest]);

  const reload = useCallback(
    (event: MessageEvent<any>) => {
      if (event.data === "ibAuthenticationComplete") {
        setLoading(true);
        setTimeout(() => window.location.reload(), 10000);
      }
    },
    [setLoading]
  );

  useEffect(() => {
    window.addEventListener("message", reload);

    return () => window.removeEventListener("message", reload);
  }, []);

  useEffect(() => {
    if (portfoliosAwaitingIntegration) {
      setModal(true);
    }
  }, [portfoliosAwaitingIntegration]);

  return {
    loading,
    modal,
    portfolios: workgroupRequest,
    illioSelectedAccount,
    setModal,
    searchText,
    setSearchText,
    filteredPortfolios,
    filteredSamplePortfolios,
    samplePortfolios,
  };
};

export default usePortfolioListSharedLogic;
