import {useQuery} from "react-query";
import {PortfolioSensitivityApi} from "@iliotech/generated-api-v3";
import { API_BASE } from "../../constants/constants";

export const useScenariosCoefficients = (portfolioId: string) => {
  const api = new PortfolioSensitivityApi(undefined, `${API_BASE}/api`)
  return useQuery([`portfolio-scenario-coefficients`, {portfolioId}], () => api.getCoefficients(portfolioId), {
    // enabled: false,
  });
}
