import React from "react";
import cn from "classnames";
// import s from "../StructureHeader/StructureHeader.module.scss";
import s from "./IconButton.module.scss";

interface IOwnProps {
  onClick?(): void;
  name: string;
  subtitle?: string;
  color?: string;
  enableSelection?: boolean;
  selected?: boolean;
  icon?: string | React.ReactNode;
  iconColor?: string;
}

export const IconButton = ({
  onClick,
  color,
  enableSelection,
  selected,
  icon,
  name,
  subtitle,
}: IOwnProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        s.iconButton,
        enableSelection && s.hoverable,
        selected && s.selected
      )}
      style={{
        backgroundColor: selected ? color : "var(--card-light)",
      }}
    >
      {/*{!!icon && (*/}
      <div className={cn(s.iconSurround)} style={{ backgroundColor: color }}>
        {icon}
      </div>
      {/*// )}*/}
      <div className={s.textColumn}>
        <div className={cn(s.name)}>{name}</div>
        <div className={cn(s.subtitle)}>{subtitle}</div>
      </div>
    </div>
  );
};
