import { Field } from "@progress/kendo-react-form";
import type { KeyValue, FieldValidatorType } from "@progress/kendo-react-form";
import {
  currencyCodeValidator,
  enumDisplayValidator,
  workgroupEnumValidator,
} from "@iliotech/data-wire";
import * as React from "react";
import { Autocomplete, AutocompleteProps } from "../Autocomplete/Autocomplete";
import { EnumDisplay } from "../../../../../generated-api-v3";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useState } from "react";

interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  validator?: FieldValidatorType | FieldValidatorType[] | undefined;
}

export const WorkgroupSearchField = observer(
  ({
    errors,
    name = "workgroup",
    label = "Client ID",
    defaultValue,
    validator,
    ...rest
  }: IOwnProps) => {
    const [open, setOpen] = React.useState(false);
    const component = React.useMemo(
      () => (props: AutocompleteProps) =>
        (
          <Autocomplete
            {...props}
            opened={open}
            onOpen={() => setOpen(true)}
            onClose={() => setTimeout(() => setOpen(false), 100)}
            onFocus={() => setOpen(true)}
          />
        ),
      [open, setOpen]
    );

    return (
      <Field
        component={component}
        label={label}
        name={name}
        error={errors?.[name]}
        // defaultValue={defaultValue}
        placeholder={"Enter currency"}
        validator={workgroupEnumValidator}
        allowCustom
        {...rest}
      />
    );
  }
);
