import * as React from "react";
import s from "./Separator.module.scss";
interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

const Separator = ({ ...rest }: IProps) => {
  return <div className={s.separator} {...rest} />;
};

export default Separator;
