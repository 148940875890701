import { PortfolioAndResourceGroupsWorkgroupsApi } from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";

export const useWorkgroupInfoV3 = (externalAccountId: string, name: string) => {
  const api = new PortfolioAndResourceGroupsWorkgroupsApi(
    undefined,
    `${API_BASE}/api`
  );

  return useQuery(
    [`workgroup-detail`, { externalAccountId, name }],
    () => api.getWorkgroupByName(externalAccountId!, name),
    {
      enabled: !!externalAccountId && !!name,
    }
  );
};
