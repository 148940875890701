import { useWhatIfPositions } from "@iliotech/data-wire";
import { observer } from "mobx-react-lite";
import { FadeLoading, SimpleTable } from "src/main";
import { whatIfPositionsColumns } from "./WhatIfPositionsColumns";

export interface WhatIfPositionsTableCellProps {
  index: number;
  baseCurrency?: string;
}

interface IProps {}

const RowRenderer = observer(({ row, props }: any) => {
  return row;
});

export const WhatIfPositionsTable = observer(({}: IProps) => {
  const store = useWhatIfPositions();
  const tableData = store.tableDataArray();

  return (
    <SimpleTable
      data={tableData}
      rowRender={(row, props) => <RowRenderer row={row} girProps={props} />}
      columns={whatIfPositionsColumns(store.currencySymbol)}
    />
  );
});
