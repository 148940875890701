import React, { FunctionComponent } from 'react';
// import { Dimensions, StyleSheet, View } from "react-native";
// import { defaultBorderRadius, gutter, smallGap } from "@constants/Dimensions";
// import Svg, { Defs, LinearGradient, Path, Stop } from "react-native-svg";
// import { fontSizes, headingFontFamily } from "@constants/FontStyles";
import cn from "classnames";
import s from "./TopPortfolioBetas.module.css"


const BETA_COLORS = [
  '#a50009',
  '#e13800',
  '#e13800',
  '#f27800',
  '#f27800',
  '#fb9900',
  '#fb9900',
  '#0077b2',
  '#0077b2',
  '#6397ff',
]

// TODO: Move to appropriate package
interface IHexConversion {
  (hex: string, opacity?: number): string
}

export const hexToRGBA: IHexConversion = (hex, opacity = 1) => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);
  
  return `rgba(${r},${g},${b},${opacity})`;
};

const getRgbIntFromHex = (hex?: string) =>  parseInt(hex ?? "00", 16) ?? 0;


export const hexToRgbObject = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: getRgbIntFromHex(result?.[1]),
    g: getRgbIntFromHex(result?.[2]),
    b: getRgbIntFromHex(result?.[3]),
  }
}

const adjust = (val: number, ratio: number) => Math.min(255, Math.max(0, Math.round(val * ratio)));
export const brighten = (color: string, ratio: number = 1) => {
  if(color.indexOf("rgb(") == 0){
    const nums = color.substring(4, color.length - 1).split(",");
    // console.log(nums[0], nums[1], nums[2])
    const _adjust = (index: number) => adjust(parseInt(nums[index]), ratio);
    // console.log(`rgb(${_adjust(0)},${_adjust(1)},${_adjust(2)})`)
    return `rgb(${_adjust(0)},${_adjust(1)},${_adjust(2)})`
  }
  
  if(color.indexOf("#") == 0){
    const {r, g, b} = hexToRgbObject(color);
    const _adjust = (val: number) => adjust(val, ratio);
    return `rgb(${_adjust(r)},${_adjust(g)},${_adjust(b)})`
  }
  
  console.log(`Error interpreting color ${color}`);
  
  return color;
}

export const transparentise: IHexConversion = (hex: string, count: number = 5, increment: number = 0.1, max: number = 0.8) =>
  hexToRGBA(hex, 1 - Math.min(count * increment, max));

// TODO: Replace with autogenerated server type +1

export interface IPortfolioVolatilityData {
  portfolio: {
    assets: {
      id: string;
      isCash: boolean;
      isShort: boolean;
      name: string;
      riskBand: number;
      riskBandPercentage: number;
      annualReturn: number;
      totalMarketValueBase: number | string;
      volatility: number;
      weight: number;
    }[];
    baseCurrencySymbol: string;
    beta: number;
    dataFrequency: string;
    dataQuality: string;
    insufficientDataExposurePercentage: number;
    riskBand: number;
    riskBandPercentage: number;
    annualReturn: number;
    topInstruments: {
      beta: number;
      name: string;
      ticker: string;
      totalMarketValueBase: string | number;
      positions: {
        custodian: string;
        marketValueBase: string | number;
        marketValueBasePercentage: number;
      }[]
    }[],
    volatility: number,
  },
  benchmark: {
    indexName: string;
    riskBand: number;
    riskBandPercentage: number;
    simulatedAnnualReturn: number;
    volatility: number;
  },
  volatilityRatio: number;
}


type Coords = {x: number, y: number};
const makeLinePath = (start: Coords, finish: Coords) => {
  
  const midX = (start.x + finish.x) / 2;
  const midY = (start.y + finish.y) / 2;
  
  return `M${start.x} ${start.y} Q${start.x + (midX - start.x) / 3},${start.y} ${midX},${midY} T${finish.x},${finish.y} `;
  
  // return path;
}


interface IProps {
  volatility: IPortfolioVolatilityData;
}



const BETA_RGBS = BETA_COLORS.map(color => hexToRgbObject(color));

const LABEL_HEIGHT = 53;
const LABEL_GAP = 2;
const LABEL_WIDTH = 110;
const LINE_WIDTH = 60;



const LABELS = [": Higher correlation", "", ": Lower correlation"]

const getLineColor = (beta: number, range: {min: number, max: number, range: number}) => {
  const steps = BETA_RGBS.length;
  const position = Math.abs((range.max - beta) * steps / range.range)
  // console.log({position})
  
  if(isNaN(position)) {
    return "white";
  }
  
  const colorA = BETA_RGBS[Math.floor(Math.max(position, 0))]
  const colorB = BETA_RGBS[Math.min(Math.ceil(position), BETA_RGBS.length - 1)];
  
  if(!colorA || !colorB) {
    // console.log({position, colorA, colorB, pos: Math.floor(Math.max(position, 0))})
    return "white";
  }
  
  const amountB = position - Math.floor(position);
  const amountA = 1 - amountB;
  
  return `rgb(${Math.floor(colorA.r * amountA + colorB.r * amountB)},${Math.floor(colorA.g * amountA + colorB.g * amountB)},${Math.floor(colorA.b * amountA + colorB.b * amountB)})`
  
  
}

const getBetaRange = (portfolio: IPortfolioVolatilityData["portfolio"]) => {
  let betaRange;
  
  const { topInstruments: instruments } = portfolio
  
  const spread = instruments[0].beta - instruments[instruments.length - 1].beta;
  
  
  if(instruments.length === 1){
    betaRange =  {
      min: instruments[0].beta * 0.5,
      max: instruments[0].beta * 1.5,
      range: 0
    }
  } else {
    betaRange = {
      min: instruments[instruments.length - 1].beta - spread * 0.01,
      max: instruments[0].beta + spread * 0.01,
      range: 0
    }
  }
  
  
  betaRange.max = Math.max(betaRange.max, portfolio.beta)
  betaRange.min = Math.min(betaRange.min, portfolio.beta)
  betaRange.range = (betaRange.max - betaRange.min) * 1.02;
  
  return betaRange;
}

export const TopPortfolioBetas: FunctionComponent<IProps> = ({ volatility }) => {
  
  
  // if (!volatility?.portfolio || !volatility.portfolio?.topInstruments) return null;
  const instruments = volatility?.portfolio?.topInstruments?.sort((a, b) => b.beta - a.beta) ?? [];
  if (!volatility?.portfolio || !volatility.portfolio?.topInstruments || instruments.length < 1) {
    return <div>Not enough instruments to display</div>
  }
  // return (
  //   <div>Got some data</div>
  // )
  
  const portfolio = volatility.portfolio;
  
  
  const screenWidth = 400; // Dimensions.get("window").width - 2 * gutter;
  const chartWidth = screenWidth;
  
  // const height = 400;
  // const height = Math.max((LABEL_HEIGHT + LABEL_GAP) * instruments.length);
  const height = Math.max((LABEL_HEIGHT + LABEL_GAP) * instruments.length, 250);
  
  const axisPath = `M${LABEL_WIDTH + LINE_WIDTH},0 L${LABEL_WIDTH + LINE_WIDTH},${height}`;
  
  
  const betaRange = getBetaRange(portfolio)
  
  
  
  const portfolioY = Math.abs(betaRange.max - portfolio.beta) / betaRange.range * height + (portfolio.beta >= (betaRange.max - betaRange.range / 2) ? 0.5 * LABEL_HEIGHT : -1 * LABEL_HEIGHT);
  
  return (
    <div style={{  width: screenWidth, paddingTop: 20, position: "relative" }}>
      
      <svg height={height} width={chartWidth}>
        <defs>
          <linearGradient id="axisGrad"
                          x1="0%" y1="0%" x2="0%" y2="100%"
          >
            {
              BETA_COLORS.map((color, index) => (
                <stop
                  key={`${color}${index}`}
                  offset={`${( index / (BETA_COLORS.length - 1)) * 100  }%`}
                  stopColor={color}
                  // stop-color={"blue"}
                  stopOpacity={"100%"}
                />
              ))
            }
          </linearGradient>
        </defs>
        {
          instruments.map((instrument, i) => {
            const lineColor = getLineColor(instrument.beta, betaRange);
            return (
              <React.Fragment key={`${instrument.ticker}${i}`}>
                {/*<div style={{*/}
                {/*  position: "absolute",*/}
                {/*  justifyContent: "center",*/}
                {/*  backgroundColor: "rgba(0,0,0,0.2)",*/}
                {/*  paddingLeft: 4,*/}
                {/*  height: LABEL_HEIGHT,*/}
                {/*  width: LABEL_WIDTH,*/}
                {/*  top: (LABEL_HEIGHT + LABEL_GAP) * i,*/}
                {/*  left: 0,*/}
                {/*  borderRadius: 6,*/}
                {/*}}>*/}
                {/*  <div style={{*/}
                {/*    color: "black", // "var(--color)",*/}
                {/*    fontSize: "0.5rem",*/}
                {/*  }}>{instrument.ticker}</div>*/}
                {/*  <div style={{color: lineColor, fontSize: "0.5rem"}}>β {instrument.beta}</div>*/}
                {/*  <div className={cn(s.ticker)}>{instrument.positions.reduce((acc, curr) => acc + curr.marketValueBasePercentage, 0)}%</div>*/}
                
                {/*</div>*/}
  
                <rect
                  x={0}
                  y={(LABEL_HEIGHT + LABEL_GAP) * i + 5}
                  height={40}
                  width={LABEL_WIDTH}
                  fill={"var(--color)"}
                  rx={4}
                  ry={4}
                />
                <text
                  x={0}
                  y={(LABEL_HEIGHT + LABEL_GAP) * i + 20}
                  height={30}
                  fontSize={12}
                  fill={"var(--background-color)"}
                >
                  <tspan x={"4"}>
                    {instrument.ticker}
                  </tspan>
                  <tspan x={"4"} y={(LABEL_HEIGHT + LABEL_GAP) * i + 20 + 14}>
                    β {instrument.beta}
                  </tspan>
                  <tspan textAnchor={"end"} width={LABEL_WIDTH} fontSize={8} x={LABEL_WIDTH - 4} y={(LABEL_HEIGHT + LABEL_GAP) * i + 20 + 14}>
                    {instrument.positions.reduce((acc, curr) => acc + curr.marketValueBasePercentage, 0)}%
                  </tspan>
                </text>
                
  
                
                <path d={
                  makeLinePath(
                    { x: LABEL_WIDTH, y: (i + 0.5) * (LABEL_HEIGHT + LABEL_GAP) },
                    {
                      x: LABEL_WIDTH + LINE_WIDTH,
                      // y: instrument.beta / 3 * height,
                      y: Math.abs(betaRange.max - instrument.beta) / betaRange.range * height,
                      
                    })
                }
                      stroke={lineColor}
                      strokeWidth={2}
                      fill={"transparent"}
                      fillRule={"nonzero"}/>
              </React.Fragment>
            
            )
          })
        }
        <rect
          x={LABEL_WIDTH + LINE_WIDTH}
          y={0}
          width={10}
          height={height}
          // stroke={"red"}
          // stroke={"blue"}
          // stroke={"url(#axisGrad)"}
          fill={"url(#axisGrad)"}
          strokeWidth={1}
        />
        {/*<path*/}
        {/*  d={axisPath}*/}
        {/*  // stroke={"red"}*/}
        {/*  stroke={"blue"}*/}
        {/*  // stroke={"url(#axisGrad)"}*/}
        {/*  fill={"url(#axisGrad)"}*/}
        {/*  strokeWidth={1}*/}
        {/*/>*/}
        
        {/*Render the portfolio Beta*/}
        <React.Fragment>
          <div  style={{
            position: "absolute",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.2)",
            padding: "0.25rem",
            height: LABEL_HEIGHT,
            // width: 70,
            top: portfolioY ,
            left: LABEL_WIDTH + 2 * LINE_WIDTH + 10,
            borderRadius: 12,
          }}>
            <div
              className={cn(s.ticker)}
              style={{
              color: "var(--color)",
            }}>Portfolio</div>
            <div
              className={cn(s.ticker)}
              style={{color: getLineColor(portfolio.beta, betaRange)}}>β {portfolio.beta}</div>
          
          </div>
          <path d={
            makeLinePath(
              { x: LABEL_WIDTH + LINE_WIDTH + 5, y: Math.abs(betaRange.max - portfolio.beta) / betaRange.range * height },
              {
                x: LABEL_WIDTH + 2 * LINE_WIDTH + 10,
                // y: instrument.beta / 3 * height,
                y: portfolioY + LABEL_HEIGHT / 2,
                
              })
          } stroke={getLineColor(portfolio.beta, betaRange)} strokeWidth={2} fill={"transparent"}/>
        </React.Fragment>
      
      </svg>
      
      {[betaRange.max, betaRange.min + betaRange.range / 2, betaRange.min].map((val, i) => (
        <div
          key={`${i}${val}`}
          style={{
            position: "absolute",
            left: LABEL_WIDTH + LINE_WIDTH + 10,
            top: height * i / 2 + 10,
            fontSize: "0.5rem",
            color: "var(--color)",
            // fontFamily: headingFontFamily,
          }}>
          {val.toFixed(3)}{LABELS[i]}
        </div>
      ))}
      
      {/*<BrandText fontSize={12}>{JSON.stringify(betaRange, null, 2)}</BrandText>*/}
      {/*<BrandText fontSize={12}>{JSON.stringify(BETA_RGBS, null, 2)}</BrandText>*/}
      {/*<BrandText fontSize={12}>{JSON.stringify(volatility, null, 2)}</BrandText>*/}
    </div>
  );
};

// const styles = StyleSheet.create({
//   ticker: {
//     fontSize: fontSizes.xtiny
//   }
// })

