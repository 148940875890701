import {
  WhatIfResultsRowContext,
  WhatIfResultsRowContextProvider,
  useGlobalCurrency,
  useHistoricVolatility,
  useWhatIfResultsRowContext,
} from "@iliotech/data-wire";
import { useParams } from "react-router";
import { ComparatorWrapper } from "./ComparatorWrapper";
import { KendoFinancialDrawdownChart } from "src/main";
import s from "./shared.module.scss";
import { Period } from "@iliotech/generated-api";
import { useTranslation } from "react-i18next";
import { DrawdownChartLegend } from "../../RiskPage/DrawdownChartLegend";
import classNames from "classnames";
import { ResultsRowWrapper } from "./ResultsRowWrapper/ResultsRowWrapper";
import { RiskDtoV3 } from "@iliotech/generated-api-v3";

interface IProps {
  period: Period;
  virtualId: string;
  basePortfolioId: string;
}

interface IInnerProps {
  baseVol: RiskDtoV3;
  virtualVol: RiskDtoV3;
  currencyFormatter: (v: number) => string;
}

const RiskComparatorInner = ({
  baseVol,
  virtualVol,
  currencyFormatter,
}: IInnerProps) => {
  const { direction } = useWhatIfResultsRowContext();
  const { t } = useTranslation();
  return (
    <article
      className={classNames(s.card)}
      style={{ flexDirection: direction === "Columns" ? "column" : "row" }}
    >
      <div className={s.half}>
        <div style={{ height: 30 }} />
        <div style={{ padding: 20 }}>
          <DrawdownChartLegend t={t} />
        </div>
        <div className={classNames(s.titleWrap, s.borderTop)}>
          <b>Your Portfolio</b>
        </div>
        <div style={{ padding: 20 }}>
          <KendoFinancialDrawdownChart
            controls={{ expanded: false }}
            modifiers={{
              colors: { indexPerformanceColor: "var(--portfolio-color)" },
              lines: { indexLineThickness: 2, watermarkLineThickness: 2 },
            }}
            drawdownData={baseVol?.drawdown!}
            t={t}
            nameHidden={true}
            height={400}
            truncateDates={false}
            currencyFormatter={currencyFormatter}
            name={t("Daily Performance Index and Drawdown")}
          />
        </div>
      </div>
      <div className={s.half}>
        <div style={{ height: 30 }} />
        <div style={{ padding: 20 }}>
          <DrawdownChartLegend t={t} />
        </div>
        <div className={classNames(s.titleWrap, s.borderTop)}>
          <b>What-if scenario</b>
        </div>
        <div style={{ padding: 20 }}>
          <KendoFinancialDrawdownChart
            controls={{ expanded: false }}
            modifiers={{
              colors: { indexPerformanceColor: "var(--portfolio-color)" },
              lines: { indexLineThickness: 2, watermarkLineThickness: 2 },
            }}
            drawdownData={virtualVol?.drawdown!}
            t={t}
            nameHidden={true}
            height={400}
            truncateDates={false}
            currencyFormatter={currencyFormatter}
            name={t("Daily Performance Index and Drawdown")}
          />
        </div>
      </div>
    </article>
  );
};

export const RiskComparator = ({
  period,
  virtualId,
  basePortfolioId,
}: IProps) => {
  const { data: baseVol, isLoading: bLoading } = useHistoricVolatility(
    basePortfolioId!,
    period
  );
  const { data: virtualVol, isLoading: vLoading } = useHistoricVolatility(
    virtualId!,
    period
  );

  const { t } = useTranslation();
  const { currencyFormatter } = useGlobalCurrency();

  return (
    <WhatIfResultsRowContextProvider>
      <ComparatorWrapper
        loading={bLoading || vLoading}
        noData={!baseVol || !virtualVol}
      >
        <ResultsRowWrapper title="Biggest Drawdown">
          <RiskComparatorInner
            baseVol={baseVol?.data!}
            virtualVol={virtualVol?.data!}
            currencyFormatter={currencyFormatter}
          />
        </ResultsRowWrapper>
      </ComparatorWrapper>
    </WhatIfResultsRowContextProvider>
  );
};
