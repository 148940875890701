import { API_BASE } from "../../constants/constants";
import { useQuery } from "react-query";
import axios, { AxiosResponse } from "axios";

const getResidentialCountries: Promise<AxiosResponse<any>> = axios.get(
  `${API_BASE}/api/public/residentialCountries`
);

export const useResidentialCountries = () => {
  return useQuery(["residential-countries"], () => getResidentialCountries, {
    enabled: true,
  });
};
