"use client";

import React, { FC, PropsWithChildren, useState } from "react";
import { useToken } from "./AuthContext";
import { useAppLoadingContext } from "./AppLoadingContext";
import { DisplayItem } from "../../src/types";

import { useTogglesLogic } from "../hooks/useTogglesLogic";
import { useAllMarketCategoryRanking } from "../hooks/useAllMarketCategoryRanking";
import { useAllSortedItems } from "../hooks/useAllSortedItems";
import { useSortContext } from "./SortContext";
import { getNumberFromString } from "../utils";

interface IOwnProps {
  onBackPress?: () => void;
  onInstrumentClick(displayItem: DisplayItem): void;
}

interface IFilters {
  [key: string]: {
    min: number;
    max: number;
    checked: boolean;
  };
}
const DiscoveryContext =
  React.createContext<
    | (ReturnType<typeof useDiscoveryContextValue> &
        Pick<IOwnProps, "onBackPress" | "onInstrumentClick">)
    | null
  >(null);

export const DiscoveryContextProvider: FC<PropsWithChildren<IOwnProps>> = ({
  children,
  onBackPress,
  onInstrumentClick,
}) => {
  const value = useDiscoveryContextValue();
  return (
    <DiscoveryContext.Provider
      value={{
        ...value,
        onBackPress,
        onInstrumentClick,
      }}
    >
      {children}
    </DiscoveryContext.Provider>
  );
};

const useDiscoveryContextValue = () => {
  const { environment } = useToken();
  const { compare, toggles, ...rest } = useTogglesLogic();
  const { watchlistId } = useSortContext();
  const { data: sortedItems } = useAllSortedItems({ environment, watchlistId });
  const [filters, setFilters] = useState<IFilters>({});
  const { data, isLoading, findCategoryIdByField } =
    useAllMarketCategoryRanking({
      watchlistId,
      environment,
    });

  const getFilteredDataString = (filter: (item: DisplayItem) => boolean) => {
    return `${
      [...(data || [])]?.filter(filter).length
    } items match your filters`;
  };
  const resetFilters = () => {
    setFilters({});
  };

  const getMinMaxString = (field: string) => {
    const max = data?.sort(
      (a, b) => getNumberFromString(b[field]) - getNumberFromString(a[field])
    )?.[0]?.[field];
    const min = data?.sort(
      (a, b) => getNumberFromString(a[field]) - getNumberFromString(b[field])
    )?.[0]?.[field];
    return `Existing values between ${min} and ${max}`;
  };
  const { loading } = useAppLoadingContext();

  const getFilteredData = () => {
    const fromFiltersToBooleans = Object.entries(filters).map(
      ([key, value]) => {
        if (
          !value?.checked ||
          (!value.max && value.max !== 0) ||
          (!value.min && value.min !== 0)
        )
          return () => true;
        return (item: DisplayItem) => {
          return (
            // @ts-ignore
            getNumberFromString(item[key]) >= value.min * 100 &&
            // @ts-ignore
            getNumberFromString(item[key]) <= value.max * 100
          );
        };
      }
    );
    return (item: DisplayItem) => fromFiltersToBooleans.every((f) => f(item));
  };

  const filterComparedData = (data: any[]) => {
    if (compare) {
      return data?.filter((item) => toggles.has(item.instrumentId));
    }
    return data?.filter(getFilteredData());
  };

  return {
    loading: loading || isLoading,
    data,
    sortedItems,
    watchlistId,
    filterComparedData,
    togglesArray: Array.from(toggles),
    compare,
    findCategoryIdByField,
    filters,
    setFilters,
    resetFilters,
    getFilteredDataString,
    getMinMaxString,
    ...rest,
  };
};

export const useDiscoveryContext = () => {
  const value = React.useContext(DiscoveryContext);
  if (!value) {
    throw new Error("DiscoveryContext is not initialized");
  }
  return value;
};
