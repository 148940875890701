import {
  DatedValueUpdateRequest,
  RealAssetInstrumentCreateRequest,
  RealAssetsApi,
} from "../../../generated-api-v3";
import { API_BASE } from "../constants/constants";

const api = new RealAssetsApi(undefined, `${API_BASE}/api`);

export const createRealAssetInstrumentOtc = (
  instrument: RealAssetInstrumentCreateRequest,
  externalAccountId: string,
  workgroupName: string
) => {
  return api.addRealAsset(instrument, externalAccountId, workgroupName);
};

export const createRealAssetOtcPrices = (
  prices: DatedValueUpdateRequest,
  externalAccountId: string,
  instrumentId: string
) => {
  return api.updatePrices(prices, externalAccountId, instrumentId);
};
