import React from "react";
import { useTranslation } from "react-i18next";
import { ArcGauge } from "../../shapes/ArcGauge/ArcGauge";
import { EsgScoreWithRAG } from "@iliotech/generated-api-v3";
import { Empty } from "@iliotech/widgets";

interface IOwnProps {
  scores?: EsgScoreWithRAG;
  loading?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const labelMapper = new Map();

labelMapper.set("A", "Fair");
labelMapper.set("R", "Weak");
labelMapper.set("G", "Strong");

export const ESGTombstone = ({ scores, style, className }: IOwnProps) => {
  const { t } = useTranslation();

  if (typeof scores === "undefined" || !scores.total) {
    return <Empty style={{ height: 200 }} text={"No Esg data"} />;
  }

  const { total, e, s, g, eRank, sRank, gRank, totalRank } = scores;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: 400,
        ...style,
      }}
      className={className}
    >
      <ArcGauge
        values={{ min: 0, max: 100, actual: total }}
        color={getColor(totalRank)}
        title={t("ESG Score")}
        valueLabel={labelMapper.get(totalRank)}
        valueLabelColor={"var(--color)"}
      />
      <ArcGauge
        mini
        values={{ min: 0, max: 100, actual: e }}
        color={getColor(eRank)}
        title={t("Environmental")}
        valueLabel={labelMapper.get(eRank)}
        valueLabelColor={"var(--color)"}
      />
      <ArcGauge
        mini
        values={{ min: 0, max: 100, actual: s }}
        color={getColor(sRank)}
        title={t("Social")}
        valueLabel={labelMapper.get(sRank)}
        valueLabelColor={"var(--color)"}
      />
      <ArcGauge
        mini
        values={{ min: 0, max: 100, actual: g }}
        color={getColor(gRank)}
        title={t("Governance")}
        valueLabel={labelMapper.get(gRank)}
        valueLabelColor={"var(--color)"}
      />
    </div>
  );
};

const getColor = (s?: string) => {
  switch (s?.toUpperCase()) {
    case "A":
      return "var(--amber)";
    case "R":
      return "var(--red)";
    case "G":
      return "var(--green)";
  }
  return undefined;
};
