/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { CategoryInsightDTO } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { ExplainerResponse } from '../../aggregator';
// @ts-ignore
import { InsightPortfolioIncome } from '../../aggregator';
// @ts-ignore
import { SummaryInsightDTO } from '../../aggregator';
/**
 * PortfolioInsightsAndExplainersApi - axios parameter creator
 * @export
 */
export const PortfolioInsightsAndExplainersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve aggregated explainers for a specific position within a portfolio. 
         * @summary Retrieve aggregated explainers for position
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregateExplainers: async (portfolioId: string, positionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getAggregateExplainers', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('getAggregateExplainers', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/explainers/aggregate/position/{positionId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve position explainers for a specific instrument within a portfolio. 
         * @summary Retrieve position explainers for instrument
         * @param {string} portfolioId 
         * @param {string} instrumentId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentExplainers: async (portfolioId: string, instrumentId: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getInstrumentExplainers', 'portfolioId', portfolioId)
            // verify required parameter 'instrumentId' is not null or undefined
            assertParamExists('getInstrumentExplainers', 'instrumentId', instrumentId)
            const localVarPath = `/v3/portfolio/{portfolioId}/explainers/instrument/{instrumentId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"instrumentId"}}`, encodeURIComponent(String(instrumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve insights summary for a portfolio. 
         * @summary Retrieve portfolio insights summary
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCategoryInsights: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioCategoryInsights', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/insight/summary`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve insights for specific category eg - income,pnl,risk, esg etc. 
         * @summary Retrieve portfolio insights for a specific category of data
         * @param {string} portfolioId 
         * @param {'INCOME' | 'PNL' | 'RISK' | 'ESG' | 'STRUCTURE' | 'PERFORMANCE'} category 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCategoryInsights1: async (portfolioId: string, category: 'INCOME' | 'PNL' | 'RISK' | 'ESG' | 'STRUCTURE' | 'PERFORMANCE', fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioCategoryInsights1', 'portfolioId', portfolioId)
            // verify required parameter 'category' is not null or undefined
            assertParamExists('getPortfolioCategoryInsights1', 'category', category)
            const localVarPath = `/v3/portfolio/{portfolioId}/insight/category/{category}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"category"}}`, encodeURIComponent(String(category)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve insights for portfolio based on income. 
         * @summary Retrieve portfolio insights on income
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCryptoAllocationInsight: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioCryptoAllocationInsight', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/insight/income`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve position explainers for a specific position within a portfolio. 
         * @summary Retrieve position explainers for position
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionExplainers: async (portfolioId: string, positionId: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPositionExplainers', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('getPositionExplainers', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/explainers/position/{positionId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioInsightsAndExplainersApi - functional programming interface
 * @export
 */
export const PortfolioInsightsAndExplainersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioInsightsAndExplainersApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve aggregated explainers for a specific position within a portfolio. 
         * @summary Retrieve aggregated explainers for position
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregateExplainers(portfolioId: string, positionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExplainerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregateExplainers(portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve position explainers for a specific instrument within a portfolio. 
         * @summary Retrieve position explainers for instrument
         * @param {string} portfolioId 
         * @param {string} instrumentId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstrumentExplainers(portfolioId: string, instrumentId: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExplainerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrumentExplainers(portfolioId, instrumentId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve insights summary for a portfolio. 
         * @summary Retrieve portfolio insights summary
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioCategoryInsights(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SummaryInsightDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioCategoryInsights(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve insights for specific category eg - income,pnl,risk, esg etc. 
         * @summary Retrieve portfolio insights for a specific category of data
         * @param {string} portfolioId 
         * @param {'INCOME' | 'PNL' | 'RISK' | 'ESG' | 'STRUCTURE' | 'PERFORMANCE'} category 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioCategoryInsights1(portfolioId: string, category: 'INCOME' | 'PNL' | 'RISK' | 'ESG' | 'STRUCTURE' | 'PERFORMANCE', fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryInsightDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioCategoryInsights1(portfolioId, category, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve insights for portfolio based on income. 
         * @summary Retrieve portfolio insights on income
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioCryptoAllocationInsight(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsightPortfolioIncome>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioCryptoAllocationInsight(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve position explainers for a specific position within a portfolio. 
         * @summary Retrieve position explainers for position
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionExplainers(portfolioId: string, positionId: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExplainerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositionExplainers(portfolioId, positionId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioInsightsAndExplainersApi - factory interface
 * @export
 */
export const PortfolioInsightsAndExplainersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioInsightsAndExplainersApiFp(configuration)
    return {
        /**
         * Retrieve aggregated explainers for a specific position within a portfolio. 
         * @summary Retrieve aggregated explainers for position
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregateExplainers(portfolioId: string, positionId: string, options?: any): AxiosPromise<ExplainerResponse> {
            return localVarFp.getAggregateExplainers(portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve position explainers for a specific instrument within a portfolio. 
         * @summary Retrieve position explainers for instrument
         * @param {string} portfolioId 
         * @param {string} instrumentId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentExplainers(portfolioId: string, instrumentId: string, from?: string, to?: string, options?: any): AxiosPromise<ExplainerResponse> {
            return localVarFp.getInstrumentExplainers(portfolioId, instrumentId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve insights summary for a portfolio. 
         * @summary Retrieve portfolio insights summary
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCategoryInsights(portfolioId: string, options?: any): AxiosPromise<Array<SummaryInsightDTO>> {
            return localVarFp.getPortfolioCategoryInsights(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve insights for specific category eg - income,pnl,risk, esg etc. 
         * @summary Retrieve portfolio insights for a specific category of data
         * @param {string} portfolioId 
         * @param {'INCOME' | 'PNL' | 'RISK' | 'ESG' | 'STRUCTURE' | 'PERFORMANCE'} category 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCategoryInsights1(portfolioId: string, category: 'INCOME' | 'PNL' | 'RISK' | 'ESG' | 'STRUCTURE' | 'PERFORMANCE', fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<CategoryInsightDTO>> {
            return localVarFp.getPortfolioCategoryInsights1(portfolioId, category, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve insights for portfolio based on income. 
         * @summary Retrieve portfolio insights on income
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCryptoAllocationInsight(portfolioId: string, options?: any): AxiosPromise<InsightPortfolioIncome> {
            return localVarFp.getPortfolioCryptoAllocationInsight(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve position explainers for a specific position within a portfolio. 
         * @summary Retrieve position explainers for position
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionExplainers(portfolioId: string, positionId: string, from?: string, to?: string, options?: any): AxiosPromise<ExplainerResponse> {
            return localVarFp.getPositionExplainers(portfolioId, positionId, from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioInsightsAndExplainersApi - object-oriented interface
 * @export
 * @class PortfolioInsightsAndExplainersApi
 * @extends {BaseAPI}
 */
export class PortfolioInsightsAndExplainersApi extends BaseAPI {
    /**
     * Retrieve aggregated explainers for a specific position within a portfolio. 
     * @summary Retrieve aggregated explainers for position
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioInsightsAndExplainersApi
     */
    public getAggregateExplainers(portfolioId: string, positionId: string, options?: AxiosRequestConfig) {
        return PortfolioInsightsAndExplainersApiFp(this.configuration).getAggregateExplainers(portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve position explainers for a specific instrument within a portfolio. 
     * @summary Retrieve position explainers for instrument
     * @param {string} portfolioId 
     * @param {string} instrumentId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioInsightsAndExplainersApi
     */
    public getInstrumentExplainers(portfolioId: string, instrumentId: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return PortfolioInsightsAndExplainersApiFp(this.configuration).getInstrumentExplainers(portfolioId, instrumentId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve insights summary for a portfolio. 
     * @summary Retrieve portfolio insights summary
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioInsightsAndExplainersApi
     */
    public getPortfolioCategoryInsights(portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioInsightsAndExplainersApiFp(this.configuration).getPortfolioCategoryInsights(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve insights for specific category eg - income,pnl,risk, esg etc. 
     * @summary Retrieve portfolio insights for a specific category of data
     * @param {string} portfolioId 
     * @param {'INCOME' | 'PNL' | 'RISK' | 'ESG' | 'STRUCTURE' | 'PERFORMANCE'} category 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioInsightsAndExplainersApi
     */
    public getPortfolioCategoryInsights1(portfolioId: string, category: 'INCOME' | 'PNL' | 'RISK' | 'ESG' | 'STRUCTURE' | 'PERFORMANCE', fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return PortfolioInsightsAndExplainersApiFp(this.configuration).getPortfolioCategoryInsights1(portfolioId, category, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve insights for portfolio based on income. 
     * @summary Retrieve portfolio insights on income
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioInsightsAndExplainersApi
     */
    public getPortfolioCryptoAllocationInsight(portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioInsightsAndExplainersApiFp(this.configuration).getPortfolioCryptoAllocationInsight(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve position explainers for a specific position within a portfolio. 
     * @summary Retrieve position explainers for position
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioInsightsAndExplainersApi
     */
    public getPositionExplainers(portfolioId: string, positionId: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return PortfolioInsightsAndExplainersApiFp(this.configuration).getPositionExplainers(portfolioId, positionId, from, to, options).then((request) => request(this.axios, this.basePath));
    }
}
