import { useParams } from "react-router-dom";
import {
  useAuth,
  usePortfolio,
  usePortfolioList,
  useWorkgroupInfoV3,
} from "@iliotech/data-wire";

export const useWorkgroupPortfolioList = () => {
  const { workgroupName = "Default", portfolioId } = useParams();
  const { illioSelectedAccount } = useAuth();

  const portfolio = usePortfolio(portfolioId!);
  const isModel =
    portfolio.data?.data.workgroupType === "GLOBAL" ||
    portfolio.data?.data.workgroupType === "MODEL";
  const workgroup = portfolio?.data?.data?.workgroup || workgroupName;
  const workgroupRequest = useWorkgroupInfoV3(
    illioSelectedAccount?.externalAccountId!,
    workgroup
  );

  const samplePortfoliosList = usePortfolioList(
    illioSelectedAccount?.externalAccountId || "",
    "GLOBAL"
  );

  const samplePortfolios =
    samplePortfoliosList?.data?.data?.filter(
      (p) => p.accessType !== "DELETING" && p.accessType !== "HIDDEN"
    ) ?? [];

  return {
    portfolio,
    portfolios: workgroupRequest?.data?.data?.portfolios,
    samplePortfolios,
    workgroupRequest: workgroupRequest,
    workgroupName: workgroup,
    isModel,
  };
};
