import cn from "classnames";
import s from "./ToggableChartLegend.module.scss";
import React from "react";
import ToggableItem, { ILegendItem } from "../ToggableItem/ToggableItem";
import { IChartDataItemV2 } from "@iliotech/types";

export interface ILegendProps {
  hideLegend?: boolean;
  hideTotal?: boolean;
  categoryTotalOverride?:
    | ((data: IChartDataItemV2[], category: string) => number)
    | undefined;
}
interface IProps {
  legend: ILegendItem[];
  legendProps?: ILegendProps;
  onItemPress: (key: string) => void;
}

export const ToggableChartLegend = ({
  legend,
  onItemPress,
  legendProps,
}: IProps) => {
  if (legendProps?.hideLegend) {
    return null;
  }
  return (
    <div className={cn(s.legend)}>
      {legend.map((legendItem, idx: number) => {
        if (legendItem.label === "Total" && legendProps?.hideTotal) {
          return null;
        }
        return (
          <ToggableItem key={idx} item={legendItem} onPress={onItemPress} />
        );
      })}
    </div>
  );
};
