import React from "react";
import {
  PortfolioDTOWithDetailsV3,
  PortfolioSnapshotDtoV3,
} from "@iliotech/generated-api-v3";
import {
  AllocationFilter,
  AllocationFilterProps,
  FilterMap,
  GroupingOption,
  LongShortStatus,
  PositionStatus,
} from "../../AllocationFilter/AllocationFilter";
import {
  addPositions,
  AggregatePosition,
  BundlesEnum,
  useAuth,
} from "@iliotech/data-wire";
import { IllioTable } from "../../IllioTable/IllioTable";
import { EnabledColumnMap, IColumn } from "../../IllioTable/types";
import {
  FaArrowDown,
  FaArrowUp,
  FaChevronDown,
  FaRegFileExcel,
  FaTimes,
} from "react-icons/fa";
import cn from "classnames";
import s from "./PositionsTransactions.module.scss";
import { IllioColumnChooser } from "../../IllioTable/IllioColumnChooser";
import { GlobalPeriodChooser } from "../../PeriodChooser";
import { PositionsTransactionsToggle } from "./PositionsTransactionsToggle";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import RoundButton from "../../RoundButton/RoundButton";
import { PNLLoading } from "../../__wrappers";
import { SearchBox } from "../../SearchBox/SearchBox";
import { IllioTabBar } from "../../IllioTabBar/IllioTabBar";
import {
  PositionViews,
  TabType,
} from "@iliotech/consumer/src/constants/tabSettings";

interface IOwnProps {
  portfolioId: string;
  allocations?: AllocationFilterProps["allocations"];
  positions?: PortfolioSnapshotDtoV3[];
  loading?: boolean;
  portfolioCurrency?: string;
  onClickPosition?(position: PortfolioSnapshotDtoV3): void;
  portfolioInfo?: PortfolioDTOWithDetailsV3;
}

const DEFAULT_COLUMNS = Object.fromEntries(
  IllioTable.ColumnSets.PositionPageColumns.filter(
    (column) => column.isDefault
  ).map((col) => [col.field, { enabled: true, order: col.order || 1000 }])
);
const EMPTY_TOTALS: Partial<PortfolioSnapshotDtoV3> = {
  totalCostBase: 0,
  marketValueBase: 0,
  incomeHistoricBase: 0,
  percentOfPortfolio: 0,
  percentOfGrossPortfolio: 0,
  exposureBase: 0,
  notionalBase: 0,
  pnlBase: 0,
  realisedBase: 0,
  pnlFxBase: 0,
};

const CHEVRON_SIZE = 12;
const CHEVRON_COLUMN_WIDTH = CHEVRON_SIZE + 4;

export const PositionsPage = ({
  allocations,
  positions: positionsIn,
  loading,
  portfolioCurrency,
  onClickPosition,
  portfolioId,
  portfolioInfo,
}: IOwnProps) => {
  const { hasBundle } = useAuth();
  const isModel =
    portfolioInfo?.workgroupType === "MODEL" ||
    portfolioInfo?.workgroupType === "GLOBAL";
  const [filterMap, setFilterMap] = React.useState<FilterMap>({});
  const [grouping, setGrouping] = React.useState<GroupingOption>();
  // GROUPING_OPTIONS[GROUPING_OPTIONS.length - 1]
  const [searchString, setSearchString] = React.useState("");
  const [positionStatuses, setPositionStatuses] =
    React.useState<PositionStatus>("open");
  const [longShortStatuses, setLongShortStatuses] =
    React.useState<LongShortStatus>("all");
  const _exporter = React.createRef<ExcelExport>();
  const [columnSettings, setColumnSettings] =
    React.useState<EnabledColumnMap>(DEFAULT_COLUMNS);
  const [currentSort, setCurrentSort] = React.useState<{
    field: string;
    direction: "asc" | "desc";
    disableSummarySort: boolean;
  }>();
  const [expandedGroupIds, setExpandedGroupIds] = React.useState<{
    [groupId: string]: boolean;
  }>({});

  const positionColumns = React.useMemo(() => {
    let positionColumns = IllioTable.ColumnSets.PositionPageColumns;

    if (isModel || !hasBundle(BundlesEnum.Add_Common_Instruments)) {
      positionColumns = positionColumns.filter((c) => c.field !== "action");
    }

    if (!hasBundle(BundlesEnum.Insights_all)) {
      positionColumns = positionColumns.filter((c) => c.field !== "insights");
    }

    return positionColumns;
  }, [isModel, hasBundle]);

  const columns = React.useMemo(() => {
    return positionColumns
      .filter((column) => columnSettings[column.field]?.enabled)
      .sort((a, b) => (a.order || 900) - (b.order || 900))
      .sort(
        (a, b) =>
          (columnSettings[a.field]?.order ?? (a.order || 900)) -
          (columnSettings[b.field]?.order ?? (b.order || 900))
      );
  }, [columnSettings]);

  const { positions, groups, renderPosition, filters } = React.useMemo(() => {
    let positions = positionsIn ? [...positionsIn] : [];

    const filters = Object.entries(filterMap).map(([field, filter]) => ({
      ...filter,
      field,
    }));

    positions = (positionsIn || []).filter((position) => {
      for (let filter of filters) {
        const field = filter.field as keyof PortfolioSnapshotDtoV3;
        const id = filter.id;
        if (position[field] !== id) {
          return false;
        }
      }
      return true;
    });

    positions = !searchString
      ? positions
      : findMatches(positions, searchString);

    switch (positionStatuses) {
      case "all":
        break;
      case "open":
        positions = positions.filter((position) => position.quantity !== 0);
        break;
      case "closed":
        positions = positions.filter((position) => position.quantity === 0);
        break;
    }
    switch (longShortStatuses) {
      case "all":
        break;
      case "long":
        positions = positions.filter((position) => position.quantity >= 0);
        break;
      case "short":
        positions = positions.filter((position) => position.quantity < 0);
        break;
    }

    if (currentSort) {
      positions = positions.sort(
        sortPositions(currentSort.field as any, currentSort.direction)
      );
    }

    const groupMap: {
      [groupId: string]: {
        entries: PortfolioSnapshotDtoV3[];
        summary: AggregatePosition;
        label: string;
        id: string;
      };
    } = {} as any;

    for (let position of positions) {
      if (grouping) {
        const groupId = `${position[grouping.field] || "Unspecified"}`;
        if (!groupMap[groupId]) {
          groupMap[groupId] = {
            entries: [position],
            summary: createSummaryPosition(position),
            label: `${position[grouping.labelField] || "Unspecified"}`,
            id: `${groupId}`,
          };
        } else {
          groupMap[groupId].entries.push(position);
          groupMap[groupId].summary = addPositions(
            groupMap[groupId].summary,
            position,
            false
          );
        }
      }
    }

    const renderPosition = createRenderPosition(
      columns,
      !!grouping,
      onClickPosition
    );

    let sortedGroups = Object.values(groupMap).sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    if (currentSort && !currentSort.disableSummarySort) {
      sortedGroups = sortedGroups.sort(
        sortSummaries(currentSort.field as any, currentSort.direction)
      );
    }
    return { positions, groups: sortedGroups, renderPosition, filters };
  }, [
    filterMap,
    positionsIn,
    grouping,
    currentSort,
    columns,
    searchString,
    positionStatuses,
    longShortStatuses,
    columnSettings,
  ]);

  const { totals } = React.useMemo(() => {
    const relevantTotalFields = Object.keys(EMPTY_TOTALS).filter(
      (key) => columnSettings[key]
    );
    let totals: Partial<PortfolioSnapshotDtoV3> = { ...EMPTY_TOTALS };
    if (!Object.keys(relevantTotalFields).length) {
      return { totals };
    }

    for (let position of positions) {
      relevantTotalFields.forEach((f) => {
        const field = f as keyof PortfolioSnapshotDtoV3;
        (totals[field] as number) += position[field] as number;
      });
    }

    return { totals };
  }, [positions, columnSettings]);

  const excelExport = () => {
    if (_exporter.current) {
      const exportColumns = columns.filter(
        (col) => !["insights", "action"].includes(col.field)
      );
      _exporter.current.save(positions, exportColumns as any);
    }
  };

  const toggleGroupId = (id: string) => {
    setExpandedGroupIds((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const removeFilter = (field: string) => {
    setFilterMap((prev) => {
      const next = { ...prev } as any;
      delete next[field];
      return next;
    });
  };

  const sort = (column: IColumn) => {
    setCurrentSort((prev) => {
      const direction =
        prev?.field === column.field && prev.direction === "asc"
          ? "desc"
          : "asc";
      return {
        field: column.field,
        direction,
        disableSummarySort: !!column.hideWhenGrouped,
      };
    });
  };

  const renderTh = () => {
    const showTotals = !!Object.keys(totals).length;
    return (
      <thead style={{ position: "sticky", top: 0 }}>
        <tr>
          {!!grouping && (
            <th
              style={{
                width: CHEVRON_COLUMN_WIDTH,
                position: "sticky",
                top: 0,
              }}
            />
          )}
          {columns.map((col) => {
            let SortSymbol = FaChevronDown;
            let isSortColumn = false;
            if (currentSort?.field === col.field) {
              isSortColumn = true;
              SortSymbol =
                currentSort.direction === "desc" ? FaArrowDown : FaArrowUp;
            }

            let total: number | undefined = undefined;
            if (totals.hasOwnProperty(col.field)) {
              // showTotal = true;
              total = (totals as any)[col.field];
            }

            return (
              <th
                key={col.id || col.field}
                onClick={() => sort(col)}
                className={cn(
                  s.horizontal,
                  "k-column-title",
                  col.rightAligned && s.rightAligned
                )}
              >
                <div className={cn(s.horizontal)}>
                  <div>
                    {col.title
                      ?.toUpperCase()
                      .replace(
                        "(£)",
                        portfolioCurrency ? `(${portfolioCurrency})` : "(base)"
                      )}
                  </div>
                  {isSortColumn && (
                    <SortSymbol
                      color={"var(--color)"}
                      size={10}
                      style={{ marginLeft: "0.5rem", marginTop: "0.25rem" }}
                    />
                  )}
                </div>
                {showTotals && (
                  <div data-cy={`total-${col.field}`} className={cn(s.total)}>
                    {typeof total !== "undefined" &&
                      total?.toLocaleString(undefined, {
                        maximumFractionDigits: [
                          "percentOfPortfolio" || "percentOfGrossPortfolio",
                        ].includes(col.field)
                          ? 2
                          : 0,
                      })}
                  </div>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderTableBodySingle = () => {
    return (
      <tbody>
        {positions?.map((position) => (
          <React.Fragment key={position.positionId}>
            {renderPosition(position)}
          </React.Fragment>
        ))}
      </tbody>
    );
  };

  const renderTableBodyGrouped = () => {
    return (
      <tbody>
        {groups.map((group) => {
          const { entries, summary, label } = group;
          const toggleExpanded = () => toggleGroupId(group.id);
          return (
            <React.Fragment key={group.id}>
              <tr>
                <td
                  onClick={toggleExpanded}
                  style={{ width: CHEVRON_COLUMN_WIDTH, cursor: "pointer" }}
                >
                  <FaChevronDown
                    size={CHEVRON_SIZE}
                    style={{
                      transform: expandedGroupIds[group.id]
                        ? undefined
                        : "rotate(-90deg)",
                    }}
                  />
                </td>
                <td
                  onClick={toggleExpanded}
                  style={{ fontWeight: 600, cursor: "pointer" }}
                >
                  {label}
                </td>
                {columns.slice(1).map((col) => {
                  if (col.hideWhenGrouped) {
                    return <td />;
                  }
                  if (col.renderer) {
                    const Comp = col.renderer;
                    return <Comp {...({ dataItem: summary, ...col } as any)} />;
                  }
                  return <td>{(summary as any)[col.field]}</td>;
                })}
              </tr>
              {expandedGroupIds[group.id] && entries.map(renderPosition)}
            </React.Fragment>
          );
        })}
      </tbody>
    );
  };

  return (
    <div
      className={cn("container", "with-header-margin-mobile")}
      style={{
        position: "relative",
      }}
    >
      <GlobalPeriodChooser
        inceptionDateOverride={portfolioInfo?.inceptionDate}
      />

      <AllocationFilter
        {...{
          allocations,
          filters: filterMap,
          setFilters: setFilterMap,
          positions: positionsIn,
          grouping,
          setGrouping,
          positionStatuses,
          setPositionStatuses,
          setLongShortStatuses,
        }}
      />
      {/*<pre style={{fontSize: 12}}>{JSON.stringify({filterMap}, null, 2)}</pre>*/}

      <div className={cn("card")}>
        <div className={cn(s.topRow)}>
          {/*      <PositionsTransactionsToggle
            selectedTab={"positions"}
            portfolioId={portfolioId}
          />*/}
          <div style={{ flex: 1 }} />
          <div className={cn(s.rightButtons)}>
            <RoundButton className={cn(s.exportButton)} onClick={excelExport}>
              Export to Excel <FaRegFileExcel />
            </RoundButton>
            <SearchBox value={searchString} onChange={setSearchString} />
            <div style={{ width: "1rem" }} />
            <IllioColumnChooser
              columns={positionColumns}
              columnSettings={columnSettings}
              setColumnSettings={setColumnSettings}
              userPreferencesTableId={"Positions"}
              baseCurrency={portfolioCurrency}
              buttonStyle={{
                position: "static",
                marginLeft: "2rem",
                top: "unset",
                fontSize: "1rem",
                right: "unset",
                padding: "0.58rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 0,
              }}
            />
          </div>
        </div>
        <div className={cn(s.settingsSurround)}>
          {(!!filters?.length ||
            positionStatuses !== "all" ||
            longShortStatuses !== "all") && (
            <div className={cn(s.filtersSurround)}>
              Filtered by
              {filters.map((filter) => (
                <div
                  onClick={() => removeFilter(filter.field)}
                  className={cn(s.filterSurround)}
                  key={filter.field}
                >
                  {filter.name}&nbsp;
                  <FaTimes />
                </div>
              ))}
              {positionStatuses !== "all" && (
                <div
                  onClick={() => setPositionStatuses("all")}
                  className={cn(s.filterSurround)}
                >
                  {positionStatuses.substr(0, 1).toUpperCase()}
                  {positionStatuses.substr(1)} Positions&nbsp;
                  <FaTimes />
                </div>
              )}
              {longShortStatuses !== "all" && (
                <div
                  onClick={() => setLongShortStatuses("all")}
                  className={cn(s.filterSurround)}
                >
                  {longShortStatuses.substr(0, 1).toUpperCase()}
                  {longShortStatuses.substr(1)} Positions&nbsp;
                  <FaTimes />
                </div>
              )}
            </div>
          )}

          {!!grouping && (
            <div className={cn(s.filtersSurround)}>
              Grouped by
              <div
                onClick={() => setGrouping(undefined)}
                className={cn(s.filterSurround)}
              >
                {grouping.displayLabel} <FaTimes />
              </div>
            </div>
          )}
        </div>
        <PNLLoading loading={loading}>
          <div className={cn("grid-surround", s.tableSurround)}>
            <table className={cn(s.groupedTable)}>
              {renderTh()}
              {grouping ? renderTableBodyGrouped() : renderTableBodySingle()}
            </table>
          </div>
        </PNLLoading>
      </div>

      <ExcelExport
        data={positions}
        collapsible={true}
        fileName="Positions.xlsx"
        ref={_exporter}
      >
        {columns.map((column, i) => (
          <ExcelExportColumn
            key={`${i}-${column.field}`}
            field={column.field}
            title={column.title ?? column.field}
          />
        ))}
      </ExcelExport>

      <div className={cn(s.countLabel)}>
        {`${positions.length} Position${positions.length !== 1 ? "s" : ""}`}
      </div>
    </div>
  );
};

const findMatches = (
  positions: PortfolioSnapshotDtoV3[],
  searchTerm: string
) => {
  const tokens = searchTerm.toLowerCase().split(" ");
  return positions.filter((position) =>
    tokens.every(
      (token) =>
        position.name.toLowerCase().includes(token) ||
        position.underlying.toLowerCase().includes(token) ||
        position.code.toLowerCase().includes(token)
    )
  );
};

const createRenderPosition =
  (
    columns: IColumn[],
    showGroupingSpacer: boolean,
    onClickPosition?: (position: PortfolioSnapshotDtoV3) => void
  ) =>
  (position: PortfolioSnapshotDtoV3) => {
    return (
      <tr
        key={Math.random()}
        style={{ cursor: !!onClickPosition ? "pointer" : undefined }}
        onClick={onClickPosition ? () => onClickPosition(position) : undefined}
      >
        {showGroupingSpacer && <td />}
        {columns.map((col, i) => {
          if (col.renderer) {
            const Comp = col.renderer;
            return (
              <Comp
                key={`${col.id}-${i}`}
                {...({ dataItem: position, ...col } as any)}
              />
            );
          }
          return <td key={`${col.id}-${i}`}>{(position as any)[col.field]}</td>;
        })}
      </tr>
    );
  };

const createSummaryPosition = (
  position: PortfolioSnapshotDtoV3
): AggregatePosition => {
  const summary = { ...position } as any;
  delete summary.quantity;
  delete summary.costPrice;
  delete summary.priceGain;
  delete summary.priceMovePercentage;
  return summary;
};

const sortPositions =
  (field: keyof PortfolioSnapshotDtoV3, direction: "asc" | "desc") =>
  (a: PortfolioSnapshotDtoV3, b: PortfolioSnapshotDtoV3) => {
    const multiplier = direction === "asc" ? 1 : -1;
    const aVal = a[field];
    const bVal = b[field];
    if (typeof aVal === "number" && typeof bVal === "number") {
      return (aVal - bVal) * multiplier;
    }
    return `${aVal}`.localeCompare(`${bVal}`) * multiplier;
  };

const sortSummaries =
  (field: keyof AggregatePosition, direction: "asc" | "desc") =>
  (a: { summary: AggregatePosition }, b: { summary: AggregatePosition }) => {
    const multiplier = direction === "asc" ? 1 : -1;
    const aVal = a.summary?.[field];
    const bVal = b.summary?.[field];
    if (typeof aVal === "number" && typeof bVal === "number") {
      return (aVal - bVal) * multiplier;
    }
    return `${aVal}`.localeCompare(`${bVal}`) * multiplier;
  };
