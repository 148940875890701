import {
  PortfolioAndResourceGroupsWorkgroupsApi,
  PortfolioCreateV3Dto,
  WorkgroupCreateDto,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

export const addPortfolioToWorkgroup = (
  portfolio: PortfolioCreateV3Dto,
  externalAccountId: string,
  workgroupName: string
) => {
  const api = new PortfolioAndResourceGroupsWorkgroupsApi(
    undefined,
    `${API_BASE}/api`
  );
  if (portfolio && !!externalAccountId && !!workgroupName) {
    return api.addPortfolioToWorkgroup(
      portfolio,
      externalAccountId,
      workgroupName
    );
  }

  return Promise.reject("No portfolio passed to assing portfolio");
};
