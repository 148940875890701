/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { PortfolioAndBenchmarkPerfDataDtoV3 } from '../../aggregator';
// @ts-ignore
import { PortfolioPerformanceBenchmarkResponse } from '../../aggregator';
// @ts-ignore
import { PortfolioRecommendedBenchmarksResponse } from '../../aggregator';
// @ts-ignore
import { PortfolioReturnsHistogramResponse } from '../../aggregator';
// @ts-ignore
import { VolatilityVsReturnResponse } from '../../aggregator';
/**
 * PortfolioPerformanceApi - axios parameter creator
 * @export
 */
export const PortfolioPerformanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get benchmarks performance for portfolio
         * @param {string} portfolioId 
         * @param {Set<string>} benchmarks 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkPerformance: async (portfolioId: string, benchmarks: Set<string>, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getBenchmarkPerformance', 'portfolioId', portfolioId)
            // verify required parameter 'benchmarks' is not null or undefined
            assertParamExists('getBenchmarkPerformance', 'benchmarks', benchmarks)
            const localVarPath = `/v3/portfolio/{portfolioId}/performanceBenchmarks`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (benchmarks) {
                localVarQueryParameter['benchmarks'] = Array.from(benchmarks);
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get historic return distribution for portfolio
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricReturns: async (portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getHistoricReturns', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/returns/historic`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get portfolio performance details
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {boolean} [full] 
         * @param {'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country'} [allocationType] 
         * @param {string} [custodianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformance: async (portfolioId: string, from?: string, to?: string, frequency?: 'Monthly' | 'Daily', full?: boolean, allocationType?: 'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country', custodianId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPerformance', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/performance`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (full !== undefined) {
                localVarQueryParameter['full'] = full;
            }

            if (allocationType !== undefined) {
                localVarQueryParameter['allocationType'] = allocationType;
            }

            if (custodianId !== undefined) {
                localVarQueryParameter['custodianId'] = custodianId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recommended benchmarks for portfolio
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedBenchmarks: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getRecommendedBenchmarks', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/recommendedBenchmarks`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get return distribution for portfolio
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReturns: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getReturns', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/returns`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get annualised rolling returns vs volatility distribution for portfolio
         * @param {string} portfolioId 
         * @param {'SHORT' | 'LONG'} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollingReturns: async (portfolioId: string, term: 'SHORT' | 'LONG', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getRollingReturns', 'portfolioId', portfolioId)
            // verify required parameter 'term' is not null or undefined
            assertParamExists('getRollingReturns', 'term', term)
            const localVarPath = `/v3/portfolio/{portfolioId}/returns/rolling`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioPerformanceApi - functional programming interface
 * @export
 */
export const PortfolioPerformanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioPerformanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get benchmarks performance for portfolio
         * @param {string} portfolioId 
         * @param {Set<string>} benchmarks 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmarkPerformance(portfolioId: string, benchmarks: Set<string>, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioPerformanceBenchmarkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmarkPerformance(portfolioId, benchmarks, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get historic return distribution for portfolio
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoricReturns(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioReturnsHistogramResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricReturns(portfolioId, frequency, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get portfolio performance details
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {boolean} [full] 
         * @param {'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country'} [allocationType] 
         * @param {string} [custodianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerformance(portfolioId: string, from?: string, to?: string, frequency?: 'Monthly' | 'Daily', full?: boolean, allocationType?: 'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country', custodianId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioAndBenchmarkPerfDataDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerformance(portfolioId, from, to, frequency, full, allocationType, custodianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recommended benchmarks for portfolio
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendedBenchmarks(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioRecommendedBenchmarksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendedBenchmarks(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get return distribution for portfolio
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReturns(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioReturnsHistogramResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReturns(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get annualised rolling returns vs volatility distribution for portfolio
         * @param {string} portfolioId 
         * @param {'SHORT' | 'LONG'} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRollingReturns(portfolioId: string, term: 'SHORT' | 'LONG', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VolatilityVsReturnResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRollingReturns(portfolioId, term, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioPerformanceApi - factory interface
 * @export
 */
export const PortfolioPerformanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioPerformanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get benchmarks performance for portfolio
         * @param {string} portfolioId 
         * @param {Set<string>} benchmarks 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkPerformance(portfolioId: string, benchmarks: Set<string>, from?: string, to?: string, options?: any): AxiosPromise<PortfolioPerformanceBenchmarkResponse> {
            return localVarFp.getBenchmarkPerformance(portfolioId, benchmarks, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get historic return distribution for portfolio
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricReturns(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: any): AxiosPromise<PortfolioReturnsHistogramResponse> {
            return localVarFp.getHistoricReturns(portfolioId, frequency, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get portfolio performance details
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {boolean} [full] 
         * @param {'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country'} [allocationType] 
         * @param {string} [custodianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerformance(portfolioId: string, from?: string, to?: string, frequency?: 'Monthly' | 'Daily', full?: boolean, allocationType?: 'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country', custodianId?: string, options?: any): AxiosPromise<PortfolioAndBenchmarkPerfDataDtoV3> {
            return localVarFp.getPerformance(portfolioId, from, to, frequency, full, allocationType, custodianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recommended benchmarks for portfolio
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedBenchmarks(portfolioId: string, options?: any): AxiosPromise<PortfolioRecommendedBenchmarksResponse> {
            return localVarFp.getRecommendedBenchmarks(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get return distribution for portfolio
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReturns(portfolioId: string, options?: any): AxiosPromise<PortfolioReturnsHistogramResponse> {
            return localVarFp.getReturns(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get annualised rolling returns vs volatility distribution for portfolio
         * @param {string} portfolioId 
         * @param {'SHORT' | 'LONG'} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollingReturns(portfolioId: string, term: 'SHORT' | 'LONG', options?: any): AxiosPromise<Array<VolatilityVsReturnResponse>> {
            return localVarFp.getRollingReturns(portfolioId, term, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioPerformanceApi - object-oriented interface
 * @export
 * @class PortfolioPerformanceApi
 * @extends {BaseAPI}
 */
export class PortfolioPerformanceApi extends BaseAPI {
    /**
     * 
     * @summary Get benchmarks performance for portfolio
     * @param {string} portfolioId 
     * @param {Set<string>} benchmarks 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioPerformanceApi
     */
    public getBenchmarkPerformance(portfolioId: string, benchmarks: Set<string>, from?: string, to?: string, options?: AxiosRequestConfig) {
        return PortfolioPerformanceApiFp(this.configuration).getBenchmarkPerformance(portfolioId, benchmarks, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get historic return distribution for portfolio
     * @param {string} portfolioId 
     * @param {'Monthly' | 'Daily'} [frequency] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioPerformanceApi
     */
    public getHistoricReturns(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return PortfolioPerformanceApiFp(this.configuration).getHistoricReturns(portfolioId, frequency, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get portfolio performance details
     * @param {string} portfolioId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {'Monthly' | 'Daily'} [frequency] 
     * @param {boolean} [full] 
     * @param {'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country'} [allocationType] 
     * @param {string} [custodianId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioPerformanceApi
     */
    public getPerformance(portfolioId: string, from?: string, to?: string, frequency?: 'Monthly' | 'Daily', full?: boolean, allocationType?: 'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country', custodianId?: string, options?: AxiosRequestConfig) {
        return PortfolioPerformanceApiFp(this.configuration).getPerformance(portfolioId, from, to, frequency, full, allocationType, custodianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recommended benchmarks for portfolio
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioPerformanceApi
     */
    public getRecommendedBenchmarks(portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioPerformanceApiFp(this.configuration).getRecommendedBenchmarks(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get return distribution for portfolio
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioPerformanceApi
     */
    public getReturns(portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioPerformanceApiFp(this.configuration).getReturns(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get annualised rolling returns vs volatility distribution for portfolio
     * @param {string} portfolioId 
     * @param {'SHORT' | 'LONG'} term 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioPerformanceApi
     */
    public getRollingReturns(portfolioId: string, term: 'SHORT' | 'LONG', options?: AxiosRequestConfig) {
        return PortfolioPerformanceApiFp(this.configuration).getRollingReturns(portfolioId, term, options).then((request) => request(this.axios, this.basePath));
    }
}
