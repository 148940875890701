const IllioLogo = () => (
  <svg viewBox="0 0 53 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.48732"
      y="17.1031"
      width="7.49536"
      height="17.0189"
      rx="3.74768"
      fill="white"
    />
    <rect
      x="26.9415"
      y="17.1031"
      width="7.49536"
      height="17.0189"
      rx="3.74768"
      fill="white"
    />
    <rect
      x="9.30539"
      y="0.87793"
      width="7.49536"
      height="33.2441"
      rx="3.74768"
      fill="white"
    />
    <rect
      x="18.1235"
      y="0.87793"
      width="7.49536"
      height="33.2441"
      rx="3.74768"
      fill="white"
    />
    <ellipse cx="44.1368" cy="25.7448" rx="8.37717" ry="8.37717" fill="white" />
  </svg>
);

export default IllioLogo;
