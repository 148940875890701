import { IColumn } from "../types";
import { DecimalCell } from "../Cells/DecimalCell";
import {
  greenRedCellRenderer,
  overrideDefaults,
  override,
  greenRedCellPercentageRenderer,
} from "./helpers";
import { DateCell } from "../Cells/DateCell";
import { PortfolioSnapshotDtoV3 } from "@iliotech/generated-api-v3";
import { NameCell } from "../Cells/NameCell";
import { InsightCell } from "../Cells/InsightCell";
import { ActionCell } from "../Cells/ActionCell";

// an AND of all the defaults of Snapshot tables
type SnapshotGroup =
  | "Value"
  | "Mandatory"
  | "P&L"
  | "Option"
  | "Descriptive"
  | "Bond";
type SnapshotColumn = IColumn<PortfolioSnapshotDtoV3> & {
  group?: SnapshotGroup;
};

const displayId: SnapshotColumn = {
  field: "displayId",
  title: "Ticker",
  isDefault: true,
  order: 30,
  group: "Descriptive",
};
const currencyLocalName: SnapshotColumn = {
  field: "currencyLocalName",
  title: "Currency",
  order: 110,
  group: "Descriptive",
};
const countryName: SnapshotColumn = {
  field: "countryName",
  title: "Country",
  order: 140,
  group: "Descriptive",
};
const regionName: SnapshotColumn = {
  field: "regionName",
  title: "Region",
  order: 144,
  group: "Descriptive",
};
const sectorName: SnapshotColumn = {
  field: "sectorName",
  title: "Sector",
  order: 150,
  group: "Descriptive",
};
const name: SnapshotColumn = {
  field: "name",
  isDefault: true,
  renderer: NameCell(),
  title: "Name",
  hideWhenGrouped: true,
  order: 20,
  group: "Mandatory",
  mandatory: true,
};
const quantity: SnapshotColumn = {
  field: "quantity",
  title: "Quantity",
  centerAligned: true,
  renderer: DecimalCell(),
  order: 40,
  hideWhenGrouped: true,
  group: "Value",
  isDefault: true,
};
const investmentVehicleName: SnapshotColumn = {
  field: "investmentVehicleName",
  title: "Instrument Type",
  order: 50,
  group: "Descriptive",
};
const assetClassName: SnapshotColumn = {
  field: "assetClassName",
  title: "Asset Class",
  order: 60,
  hideWhenGrouped: true,
  group: "Descriptive",
};

const custodianName: SnapshotColumn = {
  field: "custodianName",
  title: "Account",
  order: 200,
  hideWhenGrouped: true,
  group: "Descriptive",
  // mandatory: true,
};
const localPrice: SnapshotColumn = {
  field: "localPrice",
  title: "Price",
  renderer: greenRedCellRenderer,
  order: 300,
  centerAligned: true,
  hideWhenGrouped: true,
  group: "Value",
};
const costPrice: SnapshotColumn = {
  field: "costPrice",
  title: "Average Cost",
  renderer: greenRedCellRenderer,
  order: 305,
  centerAligned: true,
  group: "P&L",
  hideWhenGrouped: true,
};
const pricingDate: SnapshotColumn = {
  field: "pricingDate",
  title: "Pricing Date",
  renderer: DateCell(),
  width: 120,
  order: 310,
  hideWhenGrouped: true,
  group: "Descriptive",
};
const totalCostBase: SnapshotColumn = {
  field: "totalCostBase",
  title: "Avg Cost Value (£)",
  centerAligned: true,
  renderer: DecimalCell({ maxDecimals: 0 }),
  order: 400,
  group: "Value",
};
const totalCostLocal: SnapshotColumn = {
  field: "totalCostLocal",
  title: "Avg Cost Value (local)",
  centerAligned: true,
  renderer: DecimalCell({ maxDecimals: 0 }),
  order: 400,
  group: "Value",
};
const marketValueBase: SnapshotColumn = {
  field: "marketValueBase",
  renderer: DecimalCell({ maxDecimals: 0 }),
  title: "Value (£)",
  centerAligned: true,
  order: 402,
  group: "Value",
  // mandatory: true,
  isDefault: true,
};
const marketValueLocal: SnapshotColumn = {
  field: "marketValueLocal",
  renderer: DecimalCell({ maxDecimals: 0 }),
  title: "Value (Local)",
  centerAligned: true,
  group: "Value",
  order: 403,
};
const incomeLocal: SnapshotColumn = {
  field: "incomeLocal",
  title: "Income (Local)",
  renderer: DecimalCell({ maxDecimals: 0 }),
  order: 420,
  hideWhenGrouped: true,
  centerAligned: true,
  group: "P&L",
};
const incomeHistoricBase: SnapshotColumn = {
  field: "incomeHistoricBase",
  title: "Income (£)",
  renderer: DecimalCell({ maxDecimals: 0 }),
  order: 422,
  centerAligned: true,
  group: "P&L",
};
const priceGain: SnapshotColumn = {
  field: "priceGain",
  title: "Price move vs Cost",
  // title: "Price vs Book %",
  renderer: greenRedCellPercentageRenderer,
  order: 440,
  centerAligned: true,
  group: "P&L",
  hideWhenGrouped: true,
};
/*  {: SnapshotColumn
  field: "priceMovePercentage",
  title: "P %",
  renderer: greenRedCellRenderer,
  order: 442,
  rightAligned: true,

  hideWhenGrouped: true,
};*/
const percentOfPortfolio: SnapshotColumn = {
  field: "percentOfPortfolio",
  title: "Portfolio %",
  centerAligned: true,
  renderer: DecimalCell(),
  order: 490,
  group: "Value",
};
const percentOfGrossPortfolio: SnapshotColumn = {
  field: "percentOfGrossPortfolio",
  title: "Portfolio % (Gross)",
  renderer: DecimalCell(),
  centerAligned: true,
  group: "Value",
  order: 500,
};
const exposureBase: SnapshotColumn = {
  field: "exposureBase",
  title: "Exposure (£)",
  renderer: DecimalCell({ maxDecimals: 0, greenForPositive: true }),
  order: 510,
  hideWhenGrouped: true,
  centerAligned: true,
  group: "Option",
};
const notionalBase: SnapshotColumn = {
  field: "notionalBase",
  title: "Notional (£)",
  renderer: DecimalCell({ maxDecimals: 0, greenForPositive: true }),
  order: 515,
  hideWhenGrouped: true,
  rightAligned: true,
  group: "Option",
};
const accruedLocal: SnapshotColumn = {
  field: "accruedLocal",
  title: "Accrued Interest (Local)",
  renderer: DecimalCell({ maxDecimals: 0 }),
  order: 550,
  centerAligned: true,
  group: "Bond",
  hideWhenGrouped: true,
};
const dirtyPrice: SnapshotColumn = {
  field: "dirtyPrice",
  title: "Dirty Price",
  renderer: DecimalCell({ maxDecimals: 3 }),
  order: 552,
  centerAligned: true,
  group: "Bond",
};
const pnlLocal: SnapshotColumn = {
  field: "pnlLocal",
  title: "P&L (Local)",
  renderer: DecimalCell({ maxDecimals: 0, greenForPositive: true }),
  order: 678,
  centerAligned: true,
  hideWhenGrouped: true,
  group: "P&L",
};
const pnlBase: SnapshotColumn = {
  field: "pnlBase",
  title: "P&L (£)",
  renderer: DecimalCell({ maxDecimals: 0, greenForPositive: true }),
  order: 680,
  centerAligned: true,
  group: "P&L",
  isDefault: true,
};
const realisedBase: SnapshotColumn = {
  field: "realisedBase",
  title: "Realised P&L",
  renderer: DecimalCell({ maxDecimals: 0, greenForPositive: true }),
  centerAligned: true,
  order: 684,
  group: "P&L",
};
const pnlFxBase: SnapshotColumn = {
  field: "pnlFxBase",
  title: "FX P&L",
  renderer: DecimalCell({ maxDecimals: 0, greenForPositive: true }),
  order: 694,
  centerAligned: true,
  group: "P&L",
};
// const exposureBase: SnapshotColumn = {
//   field: "pnlFxBase",
//   title: "FX P&L",
//   renderer: DecimalCell({ maxDecimals: 0, greenForPositive: true }),
//   order: 694,
//   rightAligned: true,
//   group: "P&L",
// };
export const Snapshot: SnapshotColumn[] = [
  name,
  displayId,
  currencyLocalName,
  countryName,
  regionName,
  sectorName,
  quantity,
  investmentVehicleName,
  assetClassName,
  custodianName,
  localPrice,
  costPrice,
  pricingDate,
  totalCostBase,
  totalCostLocal,
  marketValueBase,
  marketValueLocal,
  incomeLocal,
  incomeHistoricBase,
  priceGain,
  percentOfPortfolio,
  percentOfGrossPortfolio,
  exposureBase,
  notionalBase,
  accruedLocal,
  dirtyPrice,
  pnlLocal,
  pnlBase,
  realisedBase,
  pnlFxBase,
];

const insightsColumn: IColumn<any> = {
  field: "insights",
  title: "Insights",
  renderer: InsightCell(),
  sortable: false,
  width: 80,
  order: 999,
  isDefault: true,
  mandatory: true,
  hideWhenGrouped: true,
};

const actionColumn: IColumn<any> = {
  field: "action",
  title: "Actions",
  renderer: ActionCell(),
  width: 80,
  order: 999,
  isDefault: true,
  hideWhenGrouped: true,
  mandatory: true,
};

/**
 * Net Worth Page
 */

// here we add the columns only required by that specific table
const netWorthDefaults: Partial<Record<keyof PortfolioSnapshotDtoV3, boolean>> =
  {
    name: true,
    quantity: true,
    investmentVehicleName: true,
    custodianName: true,
    marketValueBase: true,
    percentOfPortfolio: true,
    pnlBase: true,
    costPrice: true,
  };

const netWorthHiddenColumns: Partial<
  Record<keyof PortfolioSnapshotDtoV3, boolean>
> = {
  priceMovePercentage: true,
  accruedLocal: true,
  dirtyPrice: true,
  percentOfGrossPortfolio: true,
  pricingDate: true,
  // multiplier: true, // not available from backend
};

export const SnapshotForNetWorth = [
  ...override(
    overrideDefaults(
      Snapshot.filter((f) => !netWorthHiddenColumns[f.field]),
      netWorthDefaults
    ),
    {
      // percentOfPortfolio: {}
    }
  ),
  insightsColumn,
];

/**
 * Performance Page
 */

// here we add the columns only required by that specific table

const performanceDefaults: Partial<
  Record<keyof PortfolioSnapshotDtoV3, boolean>
> = {
  name: true,
  custodianName: true,
  localPrice: true,
  quantity: true,
  costPrice: true,
  priceGain: true,
  marketValueBase: false,
  // returnPercent: true // not available from backend
};
const performanceHiddenColumns: Partial<
  Record<keyof PortfolioSnapshotDtoV3, boolean>
> = {
  priceMovePercentage: true,
  accruedLocal: true,
  dirtyPrice: true,
  notionalBase: true,
  exposureBase: true,
  marketValueLocal: true,
  // deltaExposure: true,  // not available from backend
  // notional: true, // not available from backend
  // multiplier: true, // not available from backend
  // pricingDate: true,
};

export const SnapshotForPerformance = [
  ...overrideDefaults(
    Snapshot.filter((f) => !performanceHiddenColumns[f.field]),
    performanceDefaults
  ),
  insightsColumn,
];

/**
 * PNL Page
 */

const pnlHiddenColumns: Partial<Record<keyof PortfolioSnapshotDtoV3, boolean>> =
  {
    costPrice: true,
    priceMovePercentage: true,
    accruedLocal: true,
    dirtyPrice: true,
    notionalBase: true,
    exposureBase: true,
    // deltaExposure: true,  // not available from backend
    // notional: true, // not available from backend
    // multiplier: true, // not available from backend
  };
const pnlDefaults: Partial<Record<keyof PortfolioSnapshotDtoV3, boolean>> = {
  name: true,
  custodianName: true,
  localPrice: true,
  marketValueBase: true,
  pnlFxBase: true,
  incomeHistoricBase: true,
  quantity: true,
  pnlBase: true,
  pricingDate: true,
};
export const SnapshotForPnl = [
  ...overrideDefaults(
    Snapshot.filter((f) => !pnlHiddenColumns[f.field]),
    pnlDefaults
  ),
  insightsColumn,
];

/**
 * Instrument details page
 */

const instrumentDefaults: Partial<
  Record<keyof PortfolioSnapshotDtoV3, boolean>
> = {
  name: true,
  custodianName: true,
  pnlBase: true,
  quantity: true,
  exposureBase: true,
  marketValueBase: true,
  // currencyLocalName: true,
  // percentOfPortfolio: true,
};
const instrumentHiddenColumns: Partial<
  Record<keyof PortfolioSnapshotDtoV3, boolean>
> = {
  notionalBase: true,
  pricingDate: true,
  assetClassName: true,
  countryName: true,
  priceGain: true,
  regionName: true,
  sectorName: true,
  // deltaExposure: true,  // not available from backend
  // notional: true, // not available from backend
  // multiplier: true, // not available from backend
};
export const InstrumentPositionColumns = overrideDefaults(
  Snapshot.filter((f) => !instrumentHiddenColumns[f.field]),
  instrumentDefaults
);

/**
 * Positions page
 */
const positionDefaults: Partial<Record<keyof PortfolioSnapshotDtoV3, boolean>> =
  {
    name: true,
    custodianName: true,
    marketValueBase: true,
    pnlBase: true,
    quantity: true,
    currencyLocalName: true,
    percentOfPortfolio: true,
    // pricingDate: true,
  };

const positionsHiddenColumns: Partial<
  Record<keyof PortfolioSnapshotDtoV3, boolean>
> = {
  priceMovePercentage: true,
  notionalBase: true,
};

export const PositionPageColumns = [
  ...overrideDefaults(
    Snapshot.filter((f) => !positionsHiddenColumns[f.field]),
    positionDefaults
  ),
  insightsColumn,
  actionColumn,
];
