import {
  SharedTooltipContext,
  TooltipContext,
  TooltipPoint,
} from "@progress/kendo-react-charts";
import s from "../ColumnChart/ColumnChart.module.scss";
import { Typography } from "@progress/kendo-react-common";
import * as React from "react";
import groupBy from "lodash.groupby";
import { IChartDataItemV2, ITooltipItem } from "@iliotech/types";
import moment from "moment";
import { CHART_DATE_FORMAT } from "@iliotech/data-wire/src/constants/constants";

export const tooltipRenderer = (
  context: TooltipContext | SharedTooltipContext,
  valueFormatter: (value: number) => string,
  chartData: IChartDataItemV2[],
  valueLabel?: string
) => {
  const point: TooltipPoint =
    (context as SharedTooltipContext)?.points?.[0] ||
    (context as TooltipContext).point;

  const foundPoint = chartData.find((item) => item.name === point.category);

  return (
    <div className={s.tooltip}>
      <div className={s.tooltipRow}>
        <div
          style={{ backgroundColor: point.dataItem.color }}
          className={s.tooltipCircle}
        />
        <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
          {point.category}
        </Typography.p>
      </div>
      <div className={s.tooltipRow}>
        <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
          {valueLabel || "Value"}:&nbsp;
        </Typography.p>
        <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
          {valueFormatter(point.dataItem.value)}
        </Typography.p>
      </div>
      {(foundPoint?.tooltipExtra || []).map((item) => (
        <div className={s.tooltipRow}>
          <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
            {item.label}:&nbsp;
          </Typography.p>
          <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
            {item.value}
          </Typography.p>
        </div>
      ))}
    </div>
  );
};

export const stackedTooltipRenderer = (
  context: TooltipContext | SharedTooltipContext,
  valueFormatter: (value: number) => string,
  chartData: IChartDataItemV2[],
  prefix = "Total Value"
) => {
  const point: TooltipPoint =
    (context as SharedTooltipContext)?.points?.[0] ||
    (context as TooltipContext).point;

  const foundPoints = chartData.filter((item) => item.name === point.category);

  const extras: ITooltipItem[] = foundPoints
    .map((item) => item.tooltipExtra || [])
    .flat();

  const totalValue = foundPoints
    .map((item) => item.value)
    .reduce((prev, curr) => prev + curr, 0);

  const sumArray = (array: IChartDataItemV2[]) =>
    array.reduce((prev, curr) => ({
      category: prev.category,
      name: prev.name,
      id: prev.id,
      value: prev.value + curr.value,
      color: prev.color,
    }));

  const groupedPoints = Object.values(
    groupBy(foundPoints, (item) => item.category)
  )
    .map(sumArray)
    .sort((a, b) => a.category.localeCompare(b.category));
  // .sort((a, b) => a.category?.localeCompare(b.category));

  return (
    <div className={s.tooltip}>
      <div className={s.tooltipRow}>
        <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
          {point.category}
        </Typography.p>
      </div>
      {!!totalValue && (
        <div className={s.tooltipRow}>
          <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
            {prefix}:&nbsp;
          </Typography.p>
          <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
            {valueFormatter(totalValue)}
          </Typography.p>
        </div>
      )}
      {(groupedPoints || [])
        .filter((item) => item.value)
        .map((point) => (
          <div>
            <div key={point.value + point.id} className={s.tooltipRow}>
              <div
                style={{ backgroundColor: point.color }}
                className={s.tooltipCircle}
              />
              <Typography.p
                style={{ marginTop: 2, flex: 1 }}
                fontSize={"medium"}
              >
                {point.category}:&nbsp;
              </Typography.p>
              <Typography.p
                style={{ marginTop: 2 }}
                fontSize={"medium"}
                className={s.tooltipValue}
              >
                {valueFormatter(point.value)}
              </Typography.p>
            </div>
          </div>
        ))}
      {extras
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((item, index) => {
          return (
            <div key={item.label + item.value + index} className={s.tooltipRow}>
              <div className={s.tooltipRow}>
                <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
                  {item.label}:&nbsp;
                </Typography.p>
                <Typography.p
                  style={{ marginTop: 2, minWidth: 50 }}
                  fontSize={"medium"}
                >
                  {item.value}
                </Typography.p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const PercentRenderer =
  (dp = 1) =>
  ({ point }: TooltipContext) => {
    // const { dataItem } = point;
    const formattedDate = moment(point.category)?.format(CHART_DATE_FORMAT);
    return (
      <div>
        {formattedDate && (
          <span>
            {formattedDate}
            <br />
          </span>
        )}
        {typeof point.value === "number"
          ? point.value.toLocaleString(undefined, {
              maximumFractionDigits: dp,
              minimumFractionDigits: dp,
            })
          : point.value}
        {" %"}
      </div>
    );
  };

export const PriceRenderer =
  (instrumentCurrencySymbol = "", prefix = "Price") =>
  ({ point }: TooltipContext) => {
    // const { dataItem } = point;
    const formattedDate = moment(point.category)?.format(CHART_DATE_FORMAT);
    return (
      <div>
        {formattedDate && (
          <span>
            {formattedDate}
            <br />
          </span>
        )}
        {prefix}: {instrumentCurrencySymbol}{" "}
        {typeof point.value === "number"
          ? point.value.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })
          : point.value}
      </div>
    );
  };
export const lineChartTooltipRenderer = (
  context: TooltipContext | SharedTooltipContext,
  valueFormatter: (value: number) => string,
  chartData: IChartDataItemV2[],
  isWeekly: boolean,
  hideTotal?: boolean
) => {
  const point: TooltipPoint =
    (context as SharedTooltipContext)?.points?.[0] ||
    (context as TooltipContext).point;

  const foundPoints = isWeekly
    ? chartData.filter((item) =>
        moment(item.date!).isSame(moment(point.category), "day")
      )
    : chartData.filter(
        (item) =>
          moment(item.date!).format("MMM YY DD") ===
          moment(point.category).format("MMM YY DD")
      );

  const totalValue = foundPoints
    .map((item) => item.value)
    .reduce((prev, curr) => prev + curr, 0);

  const sumArray = (array: IChartDataItemV2[]) =>
    array.reduce((prev, curr) => ({
      category: prev.category,
      name: prev.name,
      id: prev.id,
      value: prev.value + curr.value,
      color: prev.color,
    }));

  const groupedPoints = Object.values(
    groupBy(foundPoints, (item) => item.category)
  ).map(sumArray);

  return (
    <div className={s.tooltip}>
      <div className={s.tooltipRow}>
        <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
          {moment(point.category).format(CHART_DATE_FORMAT)}
        </Typography.p>
      </div>
      {!hideTotal && (
        <div className={s.tooltipRow}>
          <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
            Total Value:&nbsp;
          </Typography.p>
          <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
            {valueFormatter(totalValue)}
          </Typography.p>
        </div>
      )}
      {(groupedPoints || [])
        .filter((item) => item.value)
        .sort((a, b) => b.value - a.value)
        .map((point) => (
          <div key={point.value + point.id} className={s.tooltipRow}>
            <div
              style={{ backgroundColor: point.color }}
              className={s.tooltipCircle}
            />
            <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
              {point.category}:&nbsp;
            </Typography.p>
            <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
              {valueFormatter(point.value)}
            </Typography.p>
          </div>
        ))}
      {(foundPoints || []).map((point) => {
        if (point?.tooltipExtra) {
          return (point.tooltipExtra || []).map((item) => (
            <div key={item.label + item.value} className={s.tooltipRow}>
              <div className={s.tooltipRow}>
                <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
                  {item.label}:&nbsp;
                </Typography.p>
                <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
                  {item.value}
                </Typography.p>
              </div>
            </div>
          ));
        } else return null;
      })}
    </div>
  );
};
