import {createContext, useContext} from 'react';
import {useGlobalCurrency, useGlobalPeriod, useStructureGrouping} from "../../context";
import useSmallScreen from "../useSmallScreen";
import {IChartDataItemV2} from "@iliotech/types";
import * as React from "react";
import {
  addAlpha, convertDataToQuarter,
  convertDataToYears,
  currencyFormatterV2,
  DRILL_DOWNS,
  getBackgroundColor,
  reduceArrays
} from "../../../utils";
import groupby from "lodash.groupby";
import {ClickableLegendItem} from "@iliotech/component-library";
import {CHART_DATE_FORMAT} from "../../../constants/constants";
import moment from "moment";

export const IncomePageContext = createContext<ReturnType<typeof useIncomePageContextHook> | null>(null);

export interface IncomePageContextProps {
  incomeReport: Record<string, string | number | IIncomeItem[]>;
}


interface IIncomeItem {
  date: moment.Moment;
  instrumentName: string;
  amountBase: number;
  amountLocal: number;
  currencyLocalSymbol: string;
}


export const useIncomePageContextHook = ({incomeReport}: IncomePageContextProps) => {
  const { period } = useGlobalPeriod();
  const { currencyFormatter } = useGlobalCurrency();
  const { selectedGroup, setSelectedGroup, grouping } = useStructureGrouping();
  const isMobile = useSmallScreen();
  
  const filterDataByPeriod = (data: IChartDataItemV2[]) => {
    return (data || []).filter((item: IChartDataItemV2) => {
      return (
        moment(item.date).isSameOrAfter(period?.from, "days") &&
        moment(item.date).isSameOrBefore(period?.to, "days"))
    });
  };
  
  const { pastDataTotalAbsolute, futureDataTotalAbsolute } =
    React.useMemo(() => {
      return {
        pastDataTotalAbsolute: (
          (incomeReport?.pastIncome || []) as IIncomeItem[]
        )
          .filter((item) =>
            moment(item.date)?.isAfter(moment().subtract(1, "year").toDate())
          )
          .map((item) => item.amountBase)
          .reduce((a, b) => a + b, 0),
        futureDataTotalAbsolute: (
          (incomeReport?.futureIncome || []) as IIncomeItem[]
        )
          .map((item) => item.amountBase)
          .reduce((a, b) => a + b, 0),
      };
    }, [incomeReport]);
  
  const { pastIncomeTotals, pastIncome, futureIncomeTotals, futureIncome } =
    React.useMemo(() => {
      const filterField = grouping.field; // filter.field as keyof PortfolioSnapshotDTO;
      const filterLabel = grouping.labelField; // filter.field as keyof PortfolioSnapshotDTO;
      const filterId = selectedGroup?.code;
      const aggregateField = (
        !filterId
          ? filterField
          : DRILL_DOWNS[grouping.field]?.field || filterField
      ) as keyof IIncomeItem;
      
      const aggregateLabel = (
        !filterId
          ? filterLabel
          : DRILL_DOWNS[grouping.field]?.labelField || filterLabel
      ) as keyof IIncomeItem;
      
      const getChartItemFromIncomePoint = (item: any): IChartDataItemV2 => {
        const getColor = () => {
          const color =
            grouping?.getColor?.(item[aggregateField] as string) ||
            getBackgroundColor((item[aggregateField] as any) || "null");
          if (filterId) {
            return item.isLong ? color : addAlpha(color, 0.6);
          }
          return color;
        };
        
        return {
          category: item[aggregateLabel as keyof IIncomeItem] as any,
          name: moment(item.date).format("MMM 'YY"),
          date: moment(item.date).toDate() as any,
          id: item.instrumentName,
          color: getColor(),
          value: item.amountBase,
          tooltipExtra: item.amountBase
            ? [
              {
                label: item.instrumentName,
                value:
                  item.amountLocal === item.amountBase
                    ? currencyFormatter!(item.amountBase)
                    : item.currencyLocalSymbol +
                    " " +
                    currencyFormatterV2(item.amountLocal, 2, 0) +
                    " / " +
                    currencyFormatter!(item.amountBase),
              },
            ]
            : [],
        } as any;
      };
      
      const processIncomeData = (data: any[], isPastData?: boolean) => {
        const groupedData: { [grouping: string]: IChartDataItemV2[] } = {};
        const filteredData = filterId
          ? (data || []).filter((s: any) => s[filterField] === filterId)
          : data;
        
        filteredData?.forEach((item) => {
          groupedData[item[aggregateField] as string] =
            groupedData[item[aggregateField] as string] || [];
          
          groupedData[item[aggregateField] as string].push(
            getChartItemFromIncomePoint(item as any)
          );
        });
        
        let incomeData = reduceArrays(Object.values(groupedData));
        //apply period filter
        incomeData = isPastData ? filterDataByPeriod(incomeData) : incomeData;
        // we check how many items in the category axis we ended up to
        // if it is more than 20, we should reduce to quarter
        const itemsInCategoryAxis = Object.values(
          groupby(incomeData, (item: IChartDataItemV2) => item?.name)
        ).length;
        
        if (itemsInCategoryAxis > 20) {
          incomeData = isMobile
            ? convertDataToYears(incomeData)
            : convertDataToQuarter(incomeData);
        } else if (itemsInCategoryAxis > 15 && isMobile) {
          incomeData = convertDataToQuarter(data);
        }
        
        let totals: ClickableLegendItem[] = [];
        const rawValue = [...incomeData]
          .map((item) => item.value)
          .reduce((a, b) => a + b, 0);
        totals.push({
          label: "Total",
          onClick: () => onReset(),
          rawValue,
          value: currencyFormatter!(rawValue),
        });
        
        Object.keys(groupedData).forEach((key) => {
          const dataForLegend = isPastData
            ? filterDataByPeriod(groupedData[key])
            : groupedData[key];
          
          const totalValue = dataForLegend
            .map((item) => item.value)
            .reduce((a, b) => a + b, 0);
          
          totals.push({
            label: dataForLegend[0]?.category,
            onClick: () => onItemClick(key, dataForLegend[0]?.category),
            value: currencyFormatter!(totalValue),
            rawValue: totalValue,
            color: groupedData[key][0].color,
          });
        });
        
        totals = totals.filter(
          (item) => item.rawValue! >= 1 || item.rawValue! <= -1
        );
        
        return {
          data: incomeData,
          totals,
        };
      };
      
      const pastIncomeData = processIncomeData(
        incomeReport?.pastIncome as unknown as any[],
        true
      );
      const futureIncomeData = processIncomeData(
        incomeReport?.futureIncome as unknown as any[]
      );
      const onItemClick = (code: string, label: string) => {
        if (!selectedGroup?.code) {
          setSelectedGroup?.({ code, label });
        } else {
          // reset
          setSelectedGroup?.(undefined);
        }
      };
      
      const onReset = () => {
        setSelectedGroup?.(undefined);
      };
      
      return {
        pastIncomeTotals: pastIncomeData.totals,
        futureIncomeTotals: futureIncomeData.totals,
        pastIncome: pastIncomeData.data,
        futureIncome: futureIncomeData.data,
      };
    }, [
      incomeReport,
      selectedGroup,
      grouping,
      currencyFormatter,
      period,
      filterDataByPeriod,
      setSelectedGroup,
      isMobile,
    ]);
  
  const futureDate = React.useMemo(
    () =>
      `${moment().add(1, "days").format(CHART_DATE_FORMAT)} - ${moment()
        .add(365, "days")
        .format(CHART_DATE_FORMAT)}`,
    []
  );
  
  const pastIncomeTitle = React.useMemo(
    () =>
      !pastIncome?.length
        ? `No income by ${
          !selectedGroup?.code ? `${grouping.label}` : selectedGroup.label
        }`
        : `Income by ${
          !selectedGroup?.code ? `${grouping.label}` : selectedGroup?.label
        }`,
    [selectedGroup, pastIncome]
  );
  
  const futureIncomeTitle = React.useMemo(
    () =>
      !futureIncome.length
        ? `No projected income from ${
          !selectedGroup?.code ? `${grouping.label}` : selectedGroup.label
        }`
        : `Projected future income from ${
          !selectedGroup?.code ? `${grouping.label}` : selectedGroup.label
        }`,
    [selectedGroup, futureIncome]
  );
  
  return {
    pastDataTotalAbsolute, futureDataTotalAbsolute,
    pastIncomeTotals, pastIncome, futureIncomeTotals, futureIncome,
    futureDate,
    pastIncomeTitle,
    futureIncomeTitle,
    selectedGroup,
  }
  
}

export const useIncomePageContext = () => {
  const context = useContext(IncomePageContext);
  
  if(context === null) {
    throw new Error("useIncomePageContext called outside of IncomePageContextProvider")
  }
  
  return context;
}
