import { IColumn } from "../types";
import { NameCell } from "../Cells/NameCell";
import { NumberCell } from "../Cells/NumberCell";
import { PrivateEquityPosition } from "@iliotech/generated-api-v3";

export const PrivateEquitiesTable: IColumn<PrivateEquityPosition>[] = [
  { field: "name", isDefault: true, renderer: NameCell(), title: "ASSET NAME" },
  {
    field: "vintageYear",
    isDefault: true,
    renderer: NameCell(),
    title: "VINTAGE",
  },
  {
    field: "currencyCode",
    isDefault: true,
    renderer: NameCell(),
    title: "CURRENCY",
  },
  {
    field: "committedCapital",
    isDefault: true,
    renderer: NumberCell(),
    title: "COMMITTED",
    rightAligned: true,
  },
  {
    field: "unfunded",
    isDefault: true,
    renderer: NumberCell(),
    title: "UNFUNDED",
    rightAligned: true,
  },
  {
    field: "capitalCalled",
    isDefault: true,
    renderer: NumberCell(),
    title: "CAPITAL CALLED",
    rightAligned: true,
  },
];
