import { AccountBrokerManagementApi } from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";

export const useAccountIntegrations = (externalAccountId: string) => {
  const api = new AccountBrokerManagementApi(undefined, `${API_BASE}/api`);

  return useQuery(
    [`account-integrations`, { externalAccountId }],
    () => api.getAllIntegrations(externalAccountId),
    { enabled: !!externalAccountId }
  );
};
