import moment from "moment";
export const defaultValueAxisFormatter = (e: number) => {
  return formatNumber(e);
};

function formatNumber(value: number) {
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  });
}

export const defaultCategoryAxisFormatter = (e?: string) => {
  if (!e) return "";
  return moment(e).format("MMM' YY");
};
