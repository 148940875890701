import classNames from "classnames";
import React from "react";
import IllioDateRangePicker from "src/components/FormInputs/IllioDateRangePicker/IllioDateRangePicker";
import s from "../ReportCreator.module.scss";
import { Switch } from "@progress/kendo-react-inputs";
import { PeriodChooser } from "src/main";
import { SYSTEM_EPOCH } from "@iliotech/data-wire";
import { ITimePeriod, ITimePeriodV2 } from "@iliotech/types";
interface IProps {
  inceptionDate: Date;
  from: Date | null;
  setFrom: (from: Date | null) => void;
  to: Date | null;
  setTo: (to: Date | null) => void;
  settings: Record<string, any>;
  updateSettings: (newSettings: Record<string, any>) => void;
}
export const CommonHeader = ({
  inceptionDate,
  from,
  setFrom,
  to,
  setTo,
  settings,
  updateSettings,
}: IProps) => {
  const selectedPeriod: ITimePeriodV2 = {
    from: from!,
    to: to!,
  };
  return (
    <div>
      <div className={s.periodWrapper}>
        <PeriodChooser
          selectedPeriod={selectedPeriod}
          epochDate={SYSTEM_EPOCH}
          inceptionDate={inceptionDate}
          hideCustomDate
          hidePeriodString
          onPeriodSelected={(period) => {
            setFrom(period?.from!);
            setTo(period?.to!);
          }}
        />
      </div>
      <IllioDateRangePicker
        name={"dateRange"}
        value={{ start: from, end: to }}
        onChange={(e) => {
          setFrom(e.value.start);
          setTo(e.value.end);
        }}
        format={"yyyy-MM-dd"}
        min={inceptionDate}
        max={to || new Date()}
      />
      <div style={{ height: 20 }} />
      <div style={{ marginBottom: -5 }}>
        <b>Include insights</b>
      </div>
      <br />
      <Switch
        size={"small"}
        className={s.toggleContainer}
        onLabel=""
        offLabel=""
        defaultChecked={settings?.insights}
        value={settings?.insights}
        onChange={(v) => updateSettings({ insights: v.value })}
      />
      <div style={{ height: 20 }} />
    </div>
  );
};
