import { useParams } from "react-router";
import { PnlCalculationState } from "../context";
import { useEffect, useState } from "react";
import PusherHandler from "../../utils/PusherHandler";

export const useWhatIfPusherLogic = () => {
  const { virtualId, basePortfolioId } =
    useParams<{
      portfolioId: string;
      virtualId: string;
      basePortfolioId: string;
    }>();
  const [basePortfolioProcessed, setBasePortfolioProcessed] = useState(false);
  const [whatIfPortfolioProcessed, setWhatIfPortfolioProcessed] =
    useState(false);

  const resetWhatIfState = () => {
    setBasePortfolioProcessed(false);
    setWhatIfPortfolioProcessed(false);
  };

  const handleBasePortfolioProcessed = (data: { message: string }) => {
    if ((data.message.toLowerCase() as PnlCalculationState) === "green") {
      setBasePortfolioProcessed(true);
    }
  };

  const handleWhatIfPortfolioProcessed = (data: { message: string }) => {
    if ((data.message.toLowerCase() as PnlCalculationState) === "green") {
      setWhatIfPortfolioProcessed(true);
    }
  };

  useEffect(() => {
    if (!basePortfolioId) {
      return;
    }
    PusherHandler.subscribeToChannel(basePortfolioId);
    PusherHandler.addListener(
      basePortfolioId,
      PusherHandler.EVENT_NAMES.PNL_CALCULATION,
      handleBasePortfolioProcessed
    );
    return () => {
      PusherHandler.removeListener(
        basePortfolioId,
        PusherHandler.EVENT_NAMES.PNL_CALCULATION,
        handleBasePortfolioProcessed
      );
    };
  }, [basePortfolioId]);

  useEffect(() => {
    if (!virtualId) {
      return;
    }
    PusherHandler.subscribeToChannel(virtualId);
    PusherHandler.addListener(
      virtualId,
      PusherHandler.EVENT_NAMES.PNL_CALCULATION,
      handleWhatIfPortfolioProcessed
    );
    return () => {
      PusherHandler.removeListener(
        virtualId,
        PusherHandler.EVENT_NAMES.PNL_CALCULATION,
        handleWhatIfPortfolioProcessed
      );
    };
  }, [virtualId]);

  return {
    resetWhatIfState,
    bothPortfolioProcessed: basePortfolioProcessed && whatIfPortfolioProcessed,
    whatIfPortfolioProcessed,
  };
};
