import React from "react";
import {
  DateRangePicker as KendoDateRangePicker,
  DateRangePickerProps,
} from "@progress/kendo-react-dateinputs";
import s from "./DateRangePicker.module.scss";
import cn from "classnames";

export const DateRangePicker = ({
  hideWeekends = true,
  className,
  ...props
}: IDatePickerProps) => {
  // todo: use customInput everywhere (have problems with onBlur in period component)
  return (
    <KendoDateRangePicker
      {...props}
      format={"dd-MMM-yyyy"}
      popupSettings={{
        popupClass: cn(
          hideWeekends && s.hideWeekends,
          s.removeHighlight,
          s.datePickerPopup
        ),
      }}
      className={cn(s.datePicker, hideWeekends && s.hideWeekends, className)}
    />
  );
};

export interface IDatePickerProps extends DateRangePickerProps {
  hideWeekends?: boolean;
  className?: any;
}

export default React.memo(DateRangePicker);
