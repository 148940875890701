/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { MarketDataLight } from '../../aggregator';
/**
 * PriceControllerV3Api - axios parameter creator
 * @export
 */
export const PriceControllerV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {string} date 
         * @param {boolean} [splitAdjusted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1: async (sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', date: string, splitAdjusted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('get1', 'sourceId', sourceId)
            // verify required parameter 'sourceData' is not null or undefined
            assertParamExists('get1', 'sourceData', sourceData)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('get1', 'date', date)
            const localVarPath = `/v3/price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sourceId !== undefined) {
                localVarQueryParameter['sourceId'] = sourceId;
            }

            if (sourceData !== undefined) {
                localVarQueryParameter['sourceData'] = sourceData;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (splitAdjusted !== undefined) {
                localVarQueryParameter['splitAdjusted'] = splitAdjusted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} benchmarkId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomBenchmarkPrices: async (benchmarkId: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchmarkId' is not null or undefined
            assertParamExists('getCustomBenchmarkPrices', 'benchmarkId', benchmarkId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getCustomBenchmarkPrices', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getCustomBenchmarkPrices', 'to', to)
            const localVarPath = `/v3/benchmark/{benchmarkId}/prices`
                .replace(`{${"benchmarkId"}}`, encodeURIComponent(String(benchmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceControllerV3Api - functional programming interface
 * @export
 */
export const PriceControllerV3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceControllerV3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {string} date 
         * @param {boolean} [splitAdjusted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get1(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', date: string, splitAdjusted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get1(sourceId, sourceData, date, splitAdjusted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} benchmarkId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomBenchmarkPrices(benchmarkId: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketDataLight>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomBenchmarkPrices(benchmarkId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriceControllerV3Api - factory interface
 * @export
 */
export const PriceControllerV3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceControllerV3ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {string} date 
         * @param {boolean} [splitAdjusted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', date: string, splitAdjusted?: boolean, options?: any): AxiosPromise<number> {
            return localVarFp.get1(sourceId, sourceData, date, splitAdjusted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} benchmarkId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomBenchmarkPrices(benchmarkId: string, from: string, to: string, options?: any): AxiosPromise<Array<MarketDataLight>> {
            return localVarFp.getCustomBenchmarkPrices(benchmarkId, from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceControllerV3Api - object-oriented interface
 * @export
 * @class PriceControllerV3Api
 * @extends {BaseAPI}
 */
export class PriceControllerV3Api extends BaseAPI {
    /**
     * 
     * @param {string} sourceId 
     * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
     * @param {string} date 
     * @param {boolean} [splitAdjusted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceControllerV3Api
     */
    public get1(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', date: string, splitAdjusted?: boolean, options?: AxiosRequestConfig) {
        return PriceControllerV3ApiFp(this.configuration).get1(sourceId, sourceData, date, splitAdjusted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} benchmarkId 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceControllerV3Api
     */
    public getCustomBenchmarkPrices(benchmarkId: string, from: string, to: string, options?: AxiosRequestConfig) {
        return PriceControllerV3ApiFp(this.configuration).getCustomBenchmarkPrices(benchmarkId, from, to, options).then((request) => request(this.axios, this.basePath));
    }
}
