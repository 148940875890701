import React from "react";
import { FormInputs } from "../../FormInputs";
import { Form, FormSubmitClickEvent } from "@progress/kendo-react-form";
import { Step } from "../Step";
import { EnumDisplay } from "../../../../../generated-api-v3";
import { countryValidator } from "@iliotech/data-wire";

interface IOwnProps {
  submit(countryCode: EnumDisplay): void;
  countries?: EnumDisplay[];
  selectedCountry: EnumDisplay;
}

export const IBDetails = ({
  submit,
  countries,
  selectedCountry,
}: IOwnProps) => {
  const handleSubmission = ({ values, isValid }: FormSubmitClickEvent) => {
    if (isValid) {
      submit(values.country);
    }
  };

  return (
    <Form
      onSubmitClick={handleSubmission}
      render={({ onSubmit, errors }) => {
        return (
          <Step currentStep={1} onNext={onSubmit as any}>
            <p>
              We’re really excited to help you understand your wealth better.
              Before we can do that there are just a couple of extra things for
              you to do and then you can be on your way!
            </p>
            <FormInputs.Common.CountryField
              defaultValue={selectedCountry}
              errors={errors}
              validator={countryValidator}
              allCountries={countries}
              label={"Country of residence"}
            />
          </Step>
        );
      }}
    />
  );
};
