import { Typography } from "@progress/kendo-react-common";
import * as React from "react";
// TODO importing a type from @iliotech/generated-api-v3 means some business logic bleeds into the component library
// ideally use values pertaining to the domain of this\ component and use data-wire to convert the data from generated-api to generic data to be consumed by this component
import {
  CategoryInsightDTO,
  SummaryInsightDTO,
} from "@iliotech/generated-api-v3";
import cn from "classnames";
import s from "./Insight.module.scss";

export const Insight = ({
  insight,
  onClick,
}: {
  insight: CategoryInsightDTO | SummaryInsightDTO;
  onClick?: (insight: CategoryInsightDTO | SummaryInsightDTO) => void;
}) => {
  return (
    <article
      onClick={() => onClick?.(insight as CategoryInsightDTO)}
      data-cy={"insight-card"}
      className={cn(s.insight)}
    >
      <Typography.p className={cn("detail", s.label)}>
        {insight.label}
      </Typography.p>
      <Typography.p>{insight.headlineInsight}</Typography.p>
      {insight.detailedInsight && (
        <Typography.p>{insight.detailedInsight}</Typography.p>
      )}
      {onClick && (
        <Typography.p className={s.readMore}>See insight</Typography.p>
      )}
    </article>
  );
};
