import React from "react";
import cn from "classnames";
import s from "./DefaultMarketStoryHeader.module.scss";
import { useStoryContext } from "@widgets/components/__Insights/InsightStoryList/context/StoryContext";
import { FaChevronLeft } from "react-icons/fa";
import IllioLogo from "@widgets/assets/IllioLogo";

interface IOwnProps {
  watchlistName?: string;
  title?: string;
  onBackPress?: () => void;
}

export const DefaultMarketStoryHeader = React.memo((props: IOwnProps) => {
  const { currentInsight } = useStoryContext();

  const title = props.title || (currentInsight?.data as any)?.title;
  const watchlistName =
    props.watchlistName || (currentInsight?.data as any)?.watchlistName;
  const onBackPress = props?.onBackPress;

  const renderTitle = (hideMobile?: boolean) => {
    return (
      <div
        className={cn(
          s.detailsSurround,
          hideMobile ? s.hideMobile : s.hideDesktop
        )}
      >
        <div data-testid={`market-insights-title`} className={s.title}>
          {title}
        </div>
        <div data-testid={`market-insights-name`}>{watchlistName}</div>
      </div>
    );
  };

  return (
    <div className={s.wrapper}>
      <div className={cn(s.surround)}>
        <div className={cn(s.backArea)}>
          <div
            className={cn(s.horizontal, onBackPress && s.clickable)}
            {...(onBackPress
              ? { onClick: onBackPress, tabIndex: 0, role: "button" }
              : {})}
          >
            {onBackPress && (
              <div className={cn(s.backArrow)}>
                <BackArrow />
              </div>
            )}
            Insights by illio
          </div>
        </div>
        {renderTitle(true)}
        <div className={cn(s.logoSurround)}>
          <PoweredByLogo />
        </div>
        <hr className={cn(s.divider)} />
      </div>
      {renderTitle(false)}
      {/*<pre style={{fontSize: 12}}>{JSON.stringify({currentInsight}, null, 2)}</pre>*/}
    </div>
  );
});

const BackArrow = () => {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.01 1.65207L1.19592 10.466M1.19592 10.466L10.01 19.2799M1.19592 10.466L22.5 10.466"
        stroke="currentColor"
        stroke-width="2.37976"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PoweredByLogo = () => {
  return (
    <svg
      width="46"
      height="36"
      viewBox="0 0 46 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="36" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_2596_29890"
            transform="matrix(0.0142292 0 0 0.0181818 -0.00513834 0)"
          />
        </pattern>
        <image
          id="image0_2596_29890"
          width="71"
          height="55"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAAA3CAYAAABeklfeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQvSURBVHgB7VvhVdswEL708b/pBmYCAgsQOkCbdgGgHYCwQIEuACzQhC4A7QAkHaAEFkiyAWEC9S76FBQjy3LivIdTfe8dsqXTSfp8UuSTIVoSSqkWy6NyY8SyD73Eoydo07qBBzVQfvSg183Re6RXhje0POpUDsqyUxrKIGdtEcnxIJLjQSTHg0iOB5EcDyI5HkRyPIjkeBDJ8SCS40Ekx4NIjgeRHA8iOR5sZBVw8EniKxLFe+coHtVqtStaEbjtJifNjOIutz2mfBuSJLidcJ0JlQWEOFVeWDNALzQSqKB3kKM2xINzEoL6PfUyJDti6ShNfBB80yohP7ZoNUgCyufIASnbfDli6ZD2urqj3gFLDyQllIPKrzlwuAOWO8on1kD0b/MIqjQ5IGaPtLcUxSZpgjJj15UlB8TIj8UPWhxC0LeswqpPqxaFT6UstJnohqugyuTUyPPUC9ppuQqqTE5Cy3uNwQdXZtXJWamtSI6G8xeryuSMqTw4Xy2qTM49lQenrSqTI0+7T8tDNky/XQVVJkcG9ZPKwY0rs7LkcAhCki4tv/Z8zwqBVH2HLN7znjIW1AAMmJjTrMJKkwPvkTCFEDSmYhigXiZ85JQXOSuGQu2CIBmovJ2HRCfF/hnX21k4OsgvY6eeaNzIxEIQeRtl6Ek0rgW9Ro7eKfQS5Y8unnv6bNqRqOMgZb+HMb26z+siIiIiIiLWEMo6fMNPdF0FnDWlbMzqIW1YP/fTNMRILyX5lVYM7sPQup6SZfZCBWyItLG/kfodlk0WOZKpsdz59j2zs3LeLe7BYMLJNcs27utFd5IhdaQd1wtfXl2lj2SC+oTd8wXqNFNlkrmTsjvXpzeeTogbyjl3BzvWE7inOfue7oxxLW56g3Ih9hwe2ES51L/GkzM6J7aXcnoku1q0l7ULTki/iYvO0PKCOmyIN9zaA5Z+kj7hTI+vBhtyLedXPfTpdm7moJMdiFy3MPh2SkcIu8d9FwNuwHX3kdewyDXkKfX8utG1ruuW3ZE1yKZjWu2ZPGuaddDPU/X8EUE7TQ7ENa2GhlA8NEK5HC3PfYJyJn+MW8kg8JQMHlhk4H14xFuWS9JnPrssh3hC0gHj8mOTWu66BR3LtLZrpgqn/VT5tEuEyJ9MFy7/Q/rc6hfLX/RF7r1v2hlIrHGPTeZsWklhag2Q66Z1v4s8iZqdsDzIIEgPdoK6QuAx1q9PNE+ubfcMOofQEW+cubLK/kFIUC7JR9iSf2KTiKBMxX7ItzuuPmEGzNoQbHgqXJB+wte4v0LDY+QdI/+Jnj3E1Dkifdxx6bB7DJ19dETInPD9FaZhH3VdC+4T2pYyeSifkS/tiPcs4jXCyFfSZ+5j2P0SVlOvB4Ve8zF/60V1VMBeButCkr5nWeaDghd21wJYULuqwBdb/w1U6E53AfwDS6LKFrHRu40AAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};
