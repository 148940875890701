import React from "react";
import { TabMenu } from "@iliotech/component-library";
import { Security } from "./components/Security";
import { Billing } from "./components/Billing";
import { AccountManagement } from "./components/AccountManagement";

interface IOwnProps { }

const tabs = [
  // {id: "details", label: "Personal Details"},
  { id: "security", label: "Security" },
  // { id: "account", label: "Manage Account" },
  // { id: "billing", label: "Billing" },
];

export const MyProfile = (props: IOwnProps) => {
  const [selectedTab, setSelectedTab] = React.useState("security");

  const content = React.useMemo(() => {
    switch (selectedTab) {
      case "details":
        return <div>Details</div>;
      case "security":
        return <Security />;
      case "account":
        return <AccountManagement />;
      case "billing":
        return <Billing />;
    }
  }, [selectedTab]);

  return (
    <TabMenu {...{ tabs, selectedTab, setSelectedTab }} title={"My Profile"}>
      {content}
    </TabMenu>
  );
};
