import * as React from "react";
import s from "./RoundToggle.module.scss";
import RoundButton from "../RoundButton/RoundButton";
import { ButtonProps } from "@progress/kendo-react-buttons";

type ValidValue = number | "DEPOSIT" | "WITHDRAWAL";

interface IProps {
  activeValue: number;
  onChange: (v: number) => void;
  values: { label: string; value: number }[];
  fillMode?: ButtonProps["fillMode"];
}

export const RoundToggle = ({
  values,
  activeValue,
  onChange,
  fillMode = "outline",
}: IProps) => {
  return (
    <div className={s.container}>
      {values.map(({ label, value }) => (
        <RoundButton
          data-cy={`round-toggle-${label.toLowerCase()}`}
          className={s.button}
          key={label}
          onClick={() => onChange(value)}
          selected={activeValue === value}
          togglable={true}
          rounded={"full"}
          fillMode={activeValue === value ? "solid" : fillMode}
        >
          {label}
        </RoundButton>
      ))}
    </div>
  );
};

export default RoundToggle;
