import { isScientificNotation } from "../../utils";
import { IPositionTradeLocal } from "../../reactHooks/context";
import { ITableRow } from "@iliotech/data-wire";

export const API_ERROR_STRING =
  "Trade upload failed. Following is shown the error returned from the system:\n ";

const API_ERROR_STRING_AFTER =
  "Click here to re-validate the trade and try again.";

export const getCellWarning = (selectedInstrument: Partial<ITableRow>) => {
  let icon: "OK" | "WARNING" | "ERROR" | "SPINNER" = "OK";
  let text = "";

  if (selectedInstrument.apiError) {
    icon = "WARNING";
    text =
      API_ERROR_STRING +
      " " +
      selectedInstrument.apiErrorString +
      " " +
      API_ERROR_STRING_AFTER;
    return { text, icon };
  }

  // check if instrument getOrCreate is already resolved
  if (
    selectedInstrument?.status?.sourceId !==
    selectedInstrument.selectedInstrument?.sourceId?.sourceId!
  ) {
    icon = "SPINNER";
    text = "Instrument status is not ready, please wait for completion.";
  }

  if (selectedInstrument.status?.status === "DRAFT") {
    icon = "SPINNER";
    text = "Instrument status is not ready, please wait for completion.";
  }

  if (selectedInstrument.status?.status === "ERROR") {
    icon = "ERROR";
    text = "Error loading the instrument. Please remove it";
  }

  if (
    selectedInstrument.searchResults?.length &&
    !selectedInstrument.selectedInstrument
  ) {
    icon = "WARNING";
    text =
      "More than one instrument option were found, select instrument option to resolve.";
  }
  if (!selectedInstrument?.selectedInstrument?.code) {
    icon = "ERROR";
    text = "The instrument could not be found. Please resolve this instrument.";
  }
  if (!selectedInstrument.custodianResolved) {
    icon = "ERROR";
    text = "The custodian could not be found. Please resolve custodian.";
  }
  if (!selectedInstrument?.quantity) {
    icon = "ERROR";
    text = "Quantity field is mandatory";
  }
  if (!selectedInstrument?.price || selectedInstrument.price < 0) {
    icon = "ERROR";
    text = "Price field is mandatory";
  }

  if (isScientificNotation(Number(selectedInstrument?.quantity))) {
    icon = "ERROR";
    text = "Quantity field is too large or too small";
  }

  return { text, icon };
};
