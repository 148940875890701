import {
  ALLOCATION_COLORS,
  WhatIfResultsRowContextProvider,
  useIncome,
  useWhatIfResultsRowContext,
} from "@iliotech/data-wire";

import { ComparatorWrapper } from "./ComparatorWrapper";
import { ColumnChartStacked, ValueLegend } from "src/main";
import { IChartDataItemV2 } from "@iliotech/types";
import moment from "moment";
import { IncomeDataResponseV3 } from "@iliotech/generated-api-v3";
import s from "./shared.module.scss";
import classNames from "classnames";
import { ResultsRowWrapper } from "./ResultsRowWrapper/ResultsRowWrapper";

interface IProps {
  valueFormatter: (v: number) => string;
  virtualId: string;
  basePortfolioId: string;
}

const parseIncome = (
  income: IncomeDataResponseV3,
  currencyFormatter: (v: number) => string
) => {
  return (income?.futureIncome || []).map((item) => ({
    id: item.instrumentName,
    date: new Date(item.date),
    value: item.amountBase,
    name: moment(item.date).format("MMM 'YY"),
    category: item.assetClassName,
    color: ALLOCATION_COLORS[item.assetClassCode],
    tooltipExtra: item.amountBase
      ? [
          {
            label: item.instrumentName,
            value:
              item.amountLocal === item.amountBase
                ? currencyFormatter!(item.amountBase)
                : item.currencyLocalSymbol +
                  " " +
                  currencyFormatter(item.amountLocal) +
                  " / " +
                  currencyFormatter!(item.amountBase),
          },
        ]
      : [],
  }));
};

interface IInnerProps {
  baseFutureIncome: IChartDataItemV2[];
  virtualFutureIncome: IChartDataItemV2[];
  valueFormatter: (v: number) => string;
  direction?: string;
}

const IncomeComparatorContent = ({
  baseFutureIncome,
  virtualFutureIncome,
  valueFormatter,
}: IInnerProps) => {
  const { direction } = useWhatIfResultsRowContext();

  return (
    <article
      className={classNames(s.card)}
      style={{ flexDirection: direction === "Columns" ? "column" : "row" }}
    >
      <div className={s.half}>
        <div className={s.titleWrap}>
          <b>Your Scenario</b>
        </div>
        <div style={{ padding: 20 }}>
          <ColumnChartStacked
            data={baseFutureIncome.sort((a, b) =>
              a.category.localeCompare(b.category)
            )}
            valueFormatter={valueFormatter!}
          />
        </div>
      </div>
      <div className={s.half}>
        <div className={classNames(s.titleWrap, s.borderTop)}>
          <b>What-if Scenario</b>
        </div>
        <div style={{ height: 20 }} />
        <ColumnChartStacked
          data={virtualFutureIncome.sort((a, b) =>
            a.category.localeCompare(b.category)
          )}
          valueFormatter={valueFormatter!}
        />
      </div>
    </article>
  );
};
export const IncomeComparator = ({
  valueFormatter,
  virtualId,
  basePortfolioId,
}: IProps) => {
  const { data: baseIncome, isLoading: bLoading } = useIncome(basePortfolioId!);
  const { data: virtualIncome, isLoading: vLoading } = useIncome(virtualId!);

  const baseFutureIncome: IChartDataItemV2[] = parseIncome(
    baseIncome?.data!,
    valueFormatter
  );
  const virtualFutureIncome: IChartDataItemV2[] = parseIncome(
    virtualIncome?.data!,
    valueFormatter
  );

  return (
    <ComparatorWrapper
      loading={bLoading || vLoading}
      noData={!virtualIncome || !baseIncome}
    >
      <WhatIfResultsRowContextProvider>
        <ResultsRowWrapper title="Projected Income">
          <IncomeComparatorContent
            baseFutureIncome={baseFutureIncome}
            virtualFutureIncome={virtualFutureIncome}
            valueFormatter={valueFormatter}
          />
        </ResultsRowWrapper>
      </WhatIfResultsRowContextProvider>
    </ComparatorWrapper>
  );
};
