import IllioDatePicker from "./IllioDatePicker/IllioDatePicker";
import Autocomplete from "./Autocomplete/Autocomplete";
import { Input } from "./Input/Input";
import {
  DateField,
  InstrumentSearchField,
  QuantityField,
  PriceField,
  RatioField,
  CustodianField,
  NotesField,
  RadioField,
  FxRateField,
  CurrencySearchField,
  PositionSearchField,
  FeeTypeSelectField,
  HiddenField,
  NameField,
  UnderlyingField,
  OptionsPositionField,
  SingleSelect,
  OptionsTradeField,
  OptionsDateSearchField,
  CountryField,
  CommissionField,
  CouponDateSearchField,
  WorkgroupSearchField,
  YearField,
} from "./Common";
import { EmailField } from "./Common/EmailField";
import { EnumDisplaySearchField } from "./Common/EnumDisplaySearchField";

export class FormInputs {
  static DatePicker = IllioDatePicker;
  static Autocomplete = Autocomplete;
  static Input = Input;
  static Common = {
    EnumDisplaySearchField,
    DateField,
    InstrumentSearchField,
    QuantityField,
    PriceField,
    YearField,
    CustodianField,
    SingleSelect,
    FeeTypeSelectField,
    FxRateField,
    NotesField,
    RadioField,
    HiddenField,
    CurrencySearchField,
    PositionSearchField,
    NameField,
    UnderlyingField,
    OptionsDateSearchField,
    CouponDateSearchField,
    OptionsPositionField,
    OptionsTradeField,
    EmailField,
    CountryField,
    WorkgroupSearchField,
    CommissionField,
    RatioField,
  };
}
