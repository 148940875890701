// REQUESTS
import { API_BASE } from "@iliotech/data-wire";
import {
  InstrumentControllerApi,
  ExchangeRateControllerV3Api,
  PriceControllerV3Api,
} from "@iliotech/generated-api-v3";
export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getOrCreate = async (sourceId: string, sourceData: string) => {
  const api = new InstrumentControllerApi(undefined, `${API_BASE}/api`);
  return api.getOrCreateInstrumentBySourceId1(sourceId, sourceData as any);
};

// REQUESTS
export const getFxRate = async (
  from: string,
  to: string,
  tradeDate: string
) => {
  const api = new ExchangeRateControllerV3Api(undefined, `${API_BASE}/api`);

  return api.get3(from as any, to as any, tradeDate);
};

// REQUESTS :update original price
export const getPrice = (
  sourceId: string,
  sourceData: string,
  tradeDate: string
) => {
  const api = new PriceControllerV3Api(undefined, `${API_BASE}/api`);

  return api.get1(sourceId, sourceData as any, tradeDate);
};
