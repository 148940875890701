import React from "react";
import { Popup, PopupProps } from "@progress/kendo-react-popup";
import { useClickAway } from "react-use";
import cn from "classnames";
import s from "./DropDownMenu.module.scss";

export interface DropDownMenuProps<T> {
  data: T[];
  onSelect?(item: { value: T }): void;
  textField: keyof T;
  dataItemKey: keyof T;
  button: React.ReactNode | React.ReactNode[];
  extraClass?: string;
  emptyMessage?: string;
  popupProps?: PopupProps;
  title?: React.ReactNode;
}

export const DropDownMenu = <T,>({
  data,
  button,
  onSelect,
  dataItemKey,
  textField,
  extraClass,
  popupProps,
  title,
  emptyMessage = "Nothing to show",
}: DropDownMenuProps<T>) => {
  const [show, setShow] = React.useState(false);
  const anchor = React.useRef<HTMLDivElement>(null);
  const clickRef = React.useRef<HTMLDivElement>(null);

  useClickAway(clickRef, (e) => {
    if (show) {
      setShow(false);
    }
  });

  return (
    <div>
      <div
        className={s.button}
        ref={anchor}
        id={"opener"}
        role={"button"}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShow((show) => !show);
        }}
      >
        {button}
      </div>
      <Popup
        data-cy={"k-popup"}
        animate={false}
        appendTo={document.getElementById("app")}
        anchor={anchor.current}
        show={show}
        popupClass={"popup-content"}
        {...popupProps}
      >
        <div ref={clickRef} style={{ minWidth: 120, padding: "0.5rem" }}>
          {title}
          <div className={s.scrollContainer}>
            {data?.length === 0 ? (
              <div>{emptyMessage}</div>
            ) : (
              (data || []).map((item) => {
                return (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onSelect?.({ value: item });
                      setShow(false);
                    }}
                    key={`${item[dataItemKey]}`}
                    className={cn(s.item, extraClass)}
                  >
                    {item[textField]}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};
