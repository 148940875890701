/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { PortfolioCustodian } from '../../aggregator';
// @ts-ignore
import { UserUpdateCustodian } from '../../aggregator';
/**
 * CustodianManagementApi - axios parameter creator
 * @export
 */
export const CustodianManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new custodian
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createUserCustodian: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createUserCustodian', 'body', body)
            const localVarPath = `/v3/custodian`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a custodian
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserCustodian: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserCustodian', 'id', id)
            const localVarPath = `/v3/custodian/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a custodian by custodian Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCustodianById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustodianById', 'id', id)
            const localVarPath = `/v3/custodian/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all custodians
         * @param {boolean} [hidden] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserCustodians: async (hidden?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/custodian`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a custodian
         * @param {UserUpdateCustodian} userUpdateCustodian 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCustodian: async (userUpdateCustodian: UserUpdateCustodian, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdateCustodian' is not null or undefined
            assertParamExists('updateUserCustodian', 'userUpdateCustodian', userUpdateCustodian)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserCustodian', 'id', id)
            const localVarPath = `/v3/custodian/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateCustodian, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustodianManagementApi - functional programming interface
 * @export
 */
export const CustodianManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustodianManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new custodian
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createUserCustodian(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioCustodian>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserCustodian(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a custodian
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserCustodian(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserCustodian(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a custodian by custodian Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCustodianById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioCustodian>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustodianById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of all custodians
         * @param {boolean} [hidden] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getUserCustodians(hidden?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortfolioCustodian>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCustodians(hidden, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a custodian
         * @param {UserUpdateCustodian} userUpdateCustodian 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserCustodian(userUpdateCustodian: UserUpdateCustodian, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioCustodian>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserCustodian(userUpdateCustodian, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustodianManagementApi - factory interface
 * @export
 */
export const CustodianManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustodianManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new custodian
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createUserCustodian(body: string, options?: any): AxiosPromise<PortfolioCustodian> {
            return localVarFp.createUserCustodian(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a custodian
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserCustodian(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserCustodian(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a custodian by custodian Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCustodianById(id: string, options?: any): AxiosPromise<PortfolioCustodian> {
            return localVarFp.getCustodianById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all custodians
         * @param {boolean} [hidden] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserCustodians(hidden?: boolean, options?: any): AxiosPromise<Array<PortfolioCustodian>> {
            return localVarFp.getUserCustodians(hidden, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a custodian
         * @param {UserUpdateCustodian} userUpdateCustodian 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCustodian(userUpdateCustodian: UserUpdateCustodian, id: string, options?: any): AxiosPromise<PortfolioCustodian> {
            return localVarFp.updateUserCustodian(userUpdateCustodian, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustodianManagementApi - object-oriented interface
 * @export
 * @class CustodianManagementApi
 * @extends {BaseAPI}
 */
export class CustodianManagementApi extends BaseAPI {
    /**
     * 
     * @summary Create a new custodian
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CustodianManagementApi
     */
    public createUserCustodian(body: string, options?: AxiosRequestConfig) {
        return CustodianManagementApiFp(this.configuration).createUserCustodian(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a custodian
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodianManagementApi
     */
    public deleteUserCustodian(id: string, options?: AxiosRequestConfig) {
        return CustodianManagementApiFp(this.configuration).deleteUserCustodian(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a custodian by custodian Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CustodianManagementApi
     */
    public getCustodianById(id: string, options?: AxiosRequestConfig) {
        return CustodianManagementApiFp(this.configuration).getCustodianById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all custodians
     * @param {boolean} [hidden] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CustodianManagementApi
     */
    public getUserCustodians(hidden?: boolean, options?: AxiosRequestConfig) {
        return CustodianManagementApiFp(this.configuration).getUserCustodians(hidden, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a custodian
     * @param {UserUpdateCustodian} userUpdateCustodian 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodianManagementApi
     */
    public updateUserCustodian(userUpdateCustodian: UserUpdateCustodian, id: string, options?: AxiosRequestConfig) {
        return CustodianManagementApiFp(this.configuration).updateUserCustodian(userUpdateCustodian, id, options).then((request) => request(this.axios, this.basePath));
    }
}
