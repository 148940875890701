import { Field } from "@progress/kendo-react-form";
import { RadioGroup, RadioGroupProps } from "../../RadioGroup/RadioGroup";
import * as React from "react";

interface IOwnProps extends Omit<RadioGroupProps, "name" | "label"> {
  name?: string;
  label?: string | null;
  layout?: "horizontal" | "vertical";
  defaultValue?: string;
}

export const RadioField = ({
  name = "radio",
  label,
  layout = "horizontal",
  defaultValue,
  ...rest
}: IOwnProps) => {
  return (
    <Field
      name={name}
      component={RadioGroup}
      defaultValue={defaultValue}
      label={label ?? undefined}
      layout={layout}
      {...rest}
    />
  );
};
