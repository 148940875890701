import { API_BASE } from "../constants/constants";
import {
  AssignDto,
  PortfolioAndResourceGroupsWorkgroupsApi,
} from "@iliotech/generated-api-v3";
const api = new PortfolioAndResourceGroupsWorkgroupsApi(
  undefined,
  `${API_BASE}/api`
);
export const assignToWorkgroup = (
  externalAccountId: string,
  assignDto: AssignDto,
  workgroupName: string
) => {
  if (!!externalAccountId) {
    return api.shareWorkgroup(assignDto, externalAccountId, workgroupName);
  }

  return Promise.reject("No externalAccountId passed to assignToWorkgroup");
};

export const unAssignToWorkgroup = (
  externalAccountId: string,
  assignDto: AssignDto,
  workgroupName: string
) => {
  if (!!externalAccountId) {
    return api.unshareWorkgroup(assignDto, externalAccountId, workgroupName);
  }
  return Promise.reject("No externalAccountId passed to unassignToWorkgroup");
};

export const deleteWorkgroup = (
  externalAccountId: string,
  workgroupId: number
) => {
  return api.deleteWorkgroup(externalAccountId, workgroupId);
};
