import React from "react";
import cn from "classnames";
import s from "./BetaBandsVisualisation.module.scss";
import { stringToColor } from "@widgets/utils/colorUtils";
import { PlainTooltip } from "@widgets/components/Tooltips/PlainTooltip/PlainTooltip";

interface IOwnProps {
  height?: number;
  items?: Item[];
}

type Item = {
  label: string;
  value: number;
};

type Group = {
  lowerBound?: number;
  upperBound?: number;
  heightUnits: number;
  label?: string;
  lowerBoundLabel?: string;
  items: Item[];
  lineStyle?: "dashed" | "solid" | "none";
};

const TOTAL_HEIGHT_UNITS = 3;
const SPACING = 4;

const GROUP_STRUCTURE: Group[] = [
  {
    lowerBound: 2,
    heightUnits: 0.5,
    label: "Greater than 2.00x",
    lowerBoundLabel: "2.00x",
    items: [],
  },
  {
    lowerBound: 1.25,
    upperBound: 2,
    heightUnits: 0.75,
    lowerBoundLabel: "1.25x",
    items: [],
  },
  {
    lowerBound: 1,
    upperBound: 1.25,
    heightUnits: 0.25,
    lowerBoundLabel: "1.00x",
    items: [],
  },
  {
    lowerBound: 0.75,
    upperBound: 1,
    heightUnits: 0.25,
    lowerBoundLabel: "0.75x",
    items: [],
  },
  {
    lowerBound: 0,
    upperBound: 0.75,
    heightUnits: 0.75,
    lowerBoundLabel: "0.00x",
    items: [],
    lineStyle: "solid",
  },

  {
    upperBound: 0,
    heightUnits: 0.5,
    label: "Less than 0.00x",
    items: [],
    lineStyle: "none",
  },
];

const BandsContext =
  React.createContext<ReturnType<typeof useBetaBandsValue> | null>(null);

export const BetaBandsVisualisation = ({ height = 400, items }: IOwnProps) => {
  const value = useBetaBandsValue(height, [...GROUP_STRUCTURE], items);

  return (
    <BandsContext.Provider value={value}>
      <div className={cn(s.surround)} style={{ height: 400 }}>
        <Axis />
        <div className={cn(s.chartArea)}>
          <ChartContent />
        </div>
      </div>
    </BandsContext.Provider>
  );
};

const useBetaBandsValue = (
  height: number,
  groupStructure: Group[],
  items?: Item[]
) => {
  const groups = groupStructure.map((group) => {
    const topPoint = getTop(group) + 0.5;
    const top = ((TOTAL_HEIGHT_UNITS - topPoint) * height) / TOTAL_HEIGHT_UNITS;
    const bottom =
      height - (top + (group.heightUnits * height) / TOTAL_HEIGHT_UNITS);
    return {
      ...group,
      items: [] as Item[],
      top,
      bottom,
      weight: 0.5,
    };
  });

  items?.forEach((item) => {
    const group = groups.find((group) => {
      return (
        (group.lowerBound ?? -Infinity) <= item.value &&
        item.value < (group.upperBound || Infinity)
      );
    });

    if (!group) return;
    group.items.push(item);
  });
  return {
    height,
    groups,
    totalItems: items?.length ?? 0,
  };
};

function getTop(group: Group) {
  return group.upperBound ?? (group.lowerBound ?? 0) + (group.heightUnits ?? 0);
}

const useBetaBands = () => {
  const context = React.useContext(BandsContext);
  if (!context) throw new Error("Must be used within a BandsContext.Provider");
  return context;
};

const Axis = () => {
  const { groups } = useBetaBands();
  return (
    <div className={cn(s.axisSurround)}>
      {groups.map((group, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={cn(s.axisLabelSurround)}
              style={{ bottom: group.bottom }}
            >
              {group.lowerBoundLabel}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const X_LINES = Array.from({ length: 10 }, (_, i) => i + 1);

const ChartContent = () => {
  const { groups, height, totalItems } = useBetaBands();
  const barHeight = (height * 0.25) / TOTAL_HEIGHT_UNITS - SPACING * 2;
  const pixelsPerHeightUnit = height / TOTAL_HEIGHT_UNITS;

  const maxWeight = Math.max(
    ...groups.map((group) =>
      Math.round((group.items.length / totalItems) * 100)
    )
  );
  return (
    <div className={cn(s.gridLinesSurround)}>
      {X_LINES.map((i) => {
        return (
          <div key={i} className={cn(s.xLine)} style={{ left: `${i * 10}%` }} />
        );
      })}
      {groups.map((group, index) => {
        const barBottom =
          group.bottom +
          (group.heightUnits * pixelsPerHeightUnit) / 2 -
          barHeight / 2;
        const weight = Math.round((group.items.length / totalItems) * 100);
        const relativeWeight = (weight * 100) / maxWeight;
        return (
          <React.Fragment key={index}>
            <div
              className={cn(s.gridLine)}
              style={{
                bottom: group.bottom,
                borderBottom: `2px ${group.lineStyle ?? "dashed"} #aaa`,
              }}
            />
            {!!group?.items?.length && (
              <PlainTooltip
                content={<BetaBandsTooltip items={group.items!} />}
                style={{
                  width: `${Math.max(10, relativeWeight)}%`,
                  position: "absolute",
                  bottom: barBottom,
                }}
              >
                <div
                  className={cn(s.bar)}
                  style={{
                    height: barHeight,
                    background: stringToColor(
                      group.items?.[0]?.label || "random"
                    ),
                  }}
                >
                  <div className={cn(s.barLabel)}>{weight?.toFixed(1)}%</div>
                </div>
              </PlainTooltip>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const BetaBandsTooltip = ({ items }: { items: Item[] }) => {
  const formatValue = (v: number) =>
    v.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  return (
    <div className={cn(s.tooltip)}>
      <span className="tooltip-title">Impact</span>
      {items.map((item, index) => {
        return (
          <div key={index} style={{ fontSize: "0.85rem" }}>
            {item.label}: {formatValue(item.value)}x
          </div>
        );
      })}
    </div>
  );
};
