import React, { PropsWithChildren, useEffect } from "react";
import {
  Authenticator,
  Button,
  Heading,
  TextField,
  View,
  useTheme,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { AuthContextProvider } from "./AuthContext";
import { Auth, Hub } from "aws-amplify";
import axios from "axios";
import { Userpilot } from "userpilot";
import { useUserPilot } from "../../hooks/useUserPilot";
import { Link } from "react-router-dom";
import { sendTracking } from "@iliotech/data-wire";

export const CustomAuthenticator = ({ children }: PropsWithChildren<any>) => {
  useUserPilot();

  useEffect(() => {
    const listener = Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          // console.log("the user signs in");
          sendTracking(`user_sign_in`, "auth_flow");
          break;
        case "signUp":
          // console.log("the user signs up");
          sendTracking(`user_sign_up`, "auth_flow");
          break;
        case "signOut":
          // console.log("the user signs out");
          sendTracking(`user_sign_out`, "auth_flow");
          break;
        case "signIn_failure":
          // console.log("error signing in");
          sendTracking(`user_sign_in_error`, "auth_flow");
          break;
        case "forgotPassword":
          // console.log("the user clicks on send code");
          sendTracking(`send_code_clicked`, "auth_flow");
      }
    });

    return () => Hub.remove("auth", listener);
  }, []);

  return (
    <Authenticator
      loginMechanisms={["email"]}
      hideSignUp={true}
      components={components}
      formFields={formFields}
    >
      {({ signOut, user }) => {
        if (user?.username) {
          Userpilot.identify(user.username);
        } else {
          Userpilot.anonymous();
        }
        return <InternalComponent {...{ signOut, user, children }} />;
      }}
    </Authenticator>
  );
};

const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      useEffect(() => {
        console.log("user_visits_login_page");
        sendTracking(`user_visits_login_page`, "auth_flow");
      }, []);

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Log in
        </Heading>
      );
    },

    Footer() {
      const { toResetPassword } = useAuthenticator();
      const { tokens } = useTheme();

      const resetPassword = () => {
        sendTracking(`forgot_password_clicked`, "auth_flow");
        toResetPassword();
      };
      return (
        <>
          {/*<View padding={`0 0 ${tokens.space.small} ${tokens.space.xl}`}>*/}
          {/*  By signing in, you agree to illio's terms and conditions and privacy*/}
          {/*  policy*/}
          {/*</View>*/}
          <View
            padding={`0 ${tokens.space.xl} ${tokens.space.small} `}
            // textAlign="left"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              className={"forgotPasswordButton"}
              fontWeight="normal"
              onClick={resetPassword}
              size="small"
            >
              Forgot password?
            </Button>
            {/*            <Link to={"/join"}>
              <Button
                className={"forgotPasswordButton"}
                fontWeight="normal"
                size="small"
              >
                No account? Sign up!
              </Button>
            </Link> */}
          </View>
        </>
      );
    },
  },
  SignUp: {
    FormFields() {
      const t = (str: string) => str;

      return (
        <>
          <TextField
            autoComplete="given-name"
            key={"name"}
            isRequired
            label={t("First Name")}
            name={"name"}
            labelHidden={true}
            placeholder={t("First Name")}
          />
          <TextField
            autoComplete="family-name"
            key={"family_name"}
            isRequired
            label={t("Last Name")}
            name={"family_name"}
            labelHidden={true}
            placeholder={t("Last Name")}
          />
          <Authenticator.SignUp.FormFields />
        </>
      );
    },
  },
};
const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: "Enter your email address",
      isRequired: true,
      label: "Email",
    },

    password: {
      labelHidden: false,
      placeholder: "Enter your password",
      isRequired: true,
      label: "Password",
    },
  },
};

const InternalComponent = ({
  signOut,
  user,
  children,
}: PropsWithChildren<any>) => {
  React.useEffect(() => {
    const getAccessToken = async () => {
      return Auth.currentAuthenticatedUser()
        .then((credentials) => {
          const jwtToken =
            credentials?.signInUserSession?.accessToken?.jwtToken;
          return jwtToken || "";
        })
        .catch(() => "");
    };

    axios.interceptors.request.use(async (config) => {
      const token = await getAccessToken();
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
      return config;
    });

    /**
     * Possible starting point for more control over rejected calls
     // axiosApiInstance.interceptors.response.use(
     //   undefined,
     //   async (error) => {
    //     const originalRequest = error.config;
    //     const errMessage = error.response.data.message as string;
    //     alert("Rejected");
    //     if (!originalRequest._retry) {
    //       console.log(
    //         "retrying after axios error",
    //         errMessage,
    //         JSON.stringify(error, null, 2)
    //       );
    //       // if (errMessage.includes("not logged in") && !originalRequest._retry) {
    //       originalRequest._retry = true;
    //       // await refreshAccessTokenFn();
    //       const nextAccessToken = await getAccessToken();
    //       originalRequest.headers = {
    //         ...originalRequest.headers,
    //         Authorization: `Bearer ${nextAccessToken}`,
    //       };
    //
    //       axios(originalRequest);
    //       // return authApi(originalRequest);
    //     }
    //     return Promise.reject(error);
    //   }
    // );
     */
  }, []);
  return (
    <AuthContextProvider
      {...{
        signOut: signOut ?? (() => {}),
        user,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};
