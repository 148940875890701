import * as React from "react";
import InputWrapper from "../../InputWrapper/InputWrapper";
import {
  NumericTextBoxProps as KendoInputProps,
  NumericTextBox,
} from "@progress/kendo-react-inputs";
import s from "./NumericInput.module.scss";
import cn from "classnames";

export interface NumericInputProps extends KendoInputProps {
  name: string;
  error?: string;
  label?: string;
  visited?: boolean;
  errorOverride?: boolean;
}

export const NumericInput = ({
  name,
  error,
  label,
  visited,
  errorOverride,
  ...restProps
}: NumericInputProps) => {
  const ref = React.useRef<any>();

  const handleOnWheel = (event: any) => {
    event.stopPropagation();
  };

  React.useEffect(() => {
    const current = ref?.current;
    current?.element.addEventListener("wheel", handleOnWheel);

    return () => {
      current?.element.removeEventListener("wheel", handleOnWheel);
    };
  }, []);

  const isError = (error && visited) || errorOverride;
  return (
    <div className={cn("illio-input", s.input)}>
      <InputWrapper error={isError ? error : ""} label={label} name={name}>
        <NumericTextBox
          spinners={false}
          ref={ref}
          className={s.input}
          id={name}
          name={name}
          valid={!isError}
          {...restProps}
        />
      </InputWrapper>
    </div>
  );
};

export default NumericInput;
