import React from 'react';
import { VolatilityBreakdownChart } from './VolatilityBreakdownChart';
import {IVolatilityProps} from "./types";
import {VolatilityBreakdownTable} from "./VolatilityBreakdownTable";







export const VolatilityBreakdown = React.memo((props: IVolatilityProps) => {
  
  
    
    const {width: widthIn = 1200, data: {portfolio}} = props;
    // if (widthIn >= (portfolio?.assets?.length ?? 0) * 60) {
    //   return <VolatilityBreakdownChart {...props} />
    // }
    
    return <VolatilityBreakdownTable {...props} />
    
  }
)


