import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import {
  InputChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { TransactionTableCellProps } from "./PriceCell";
import { Input } from "@progress/kendo-react-inputs";
import s from "./Cells.module.scss";
import { observer } from "mobx-react-lite";
import { usePositionUploadV2 } from "@iliotech/data-wire/src/stores/PositionsUpload";
const QuantityCell = ({ index }: TransactionTableCellProps) => {
  const store = usePositionUploadV2();
  const quantity = store.position.getField("quantity", index + "") as number;

  const onChange = (event: NumericTextBoxChangeEvent) => {
    store.position.updateTableRowByIndex("quantity", event.value, index + "");
  };

  return (
    <NumericTextBox
      name={"quantity"}
      value={quantity}
      format={{
        maximumFractionDigits: 5,
      }}
      spinners={false}
      onChange={onChange}
    />
  );
};

export default observer(QuantityCell);
