import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { notEmptyValidator, positiveValidator } from "@iliotech/data-wire";
import * as React from "react";
import { Input } from "../Input/Input";
import { InputProps } from "@progress/kendo-react-inputs";

interface IOwnProps extends Omit<InputProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  validator?: (value: string) => string;
}

export const RatioField = ({
  errors,
  name = "ratio",
  label = "Ratio",
  ...rest
}: IOwnProps) => {
  return (
    <Field
      name={name}
      error={errors?.[name]}
      component={Input}
      format={{ maximumFractionDigits: 4 }}
      placeholder={"Enter ratio (e.g. 4:1)"}
      label={label ?? undefined}
      validator={positiveValidator}
      {...rest}
    />
  );
};
