import React from "react";

import cn from "classnames";
import s from "./StepTracker.module.scss";

interface IOwnProps {
  currentStep: number;
  totalSteps: number;
  width?: React.ReactText;
  onClickStep?(num: number): void;
  onBack?(): void;
  onNext?(): void;
}

export const StepTracker = ({
  currentStep,
  totalSteps,
  onClickStep,
  width = "100%",
  onBack,
  onNext,
}: IOwnProps) => {
  const showBack = typeof onBack === "function" && currentStep > 1;
  const showNext = typeof onNext === "function" && currentStep < totalSteps;
  const showButtons = showBack || showNext;

  const steps = Array.from({ length: totalSteps }).map((_, i) => i + 1);
  return (
    <div className={cn(s.surround)}>
      <div className={cn(s.stepsSurround)} style={{ width }}>
        {steps.map((step) => (
          <div
            key={step}
            onClick={
              typeof onClickStep === "function"
                ? () => onClickStep(step)
                : undefined
            }
            className={cn(s.step, step <= currentStep && s.active)}
          >
            {step}
          </div>
        ))}
      </div>
      {showButtons && (
        <div className={cn(s.buttonsSurround)}>
          {showBack ? (
            <button onClick={onBack} className={cn(s.back)}>
              Back
            </button>
          ) : (
            <div />
          )}
          {showNext ? (
            <button onClick={onNext} className={cn(s.next)}>
              Next
            </button>
          ) : (
            <div />
          )}
        </div>
      )}
    </div>
  );
};
