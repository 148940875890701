import React, { FC } from "react";
import { IDecimalCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

export const NameCell = (): FC<IDecimalCellProps> => {
  return React.memo(({ width = 200, ...props }) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const field = props.field || "";
    const value = props.dataItem[field];
    const icon = props.dataItem.icon;
    const imgUrl = `https://cdn.illio.com/${icon}`;
    const closed = props.dataItem.quantity === 0;

    const isDefaultIcon = icon?.startsWith("images/DEFAULT-ICON");

    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div
          style={{
            display: "flex",
            pointerEvents: "none",
            alignItems: "center",
          }}
        >
          {icon ? (
            <img
              className="no-invert"
              alt={value}
              src={imgUrl}
              height={15}
              width={20}
              style={{
                marginRight: 5,
                filter: isDefaultIcon ? "invert(40%)" : undefined,
              }}
            />
          ) : (
            <div style={{ width: 15, marginRight: 5 }} />
          )}
          <div
            title={closed ? `Closed ${value} position` : value}
            style={{
              width,
              color: closed ? "var(--muted-color)" : undefined,
              fontStyle: closed ? "italic" : undefined,
            }}
          >
            {value}
          </div>
        </div>
      </td>
    );
  });
};
