import moment from "moment";
import { ALLOCATION_COLORS } from "@iliotech/data-wire";
import { IChartDataItemV2, TPortfolioPerformance } from "@iliotech/types";
import {
  PageDTOPortfolioSnapshotDTO,
  PortfolioAllocationHistoryResponse,
  PortfolioSnapshotDTO,
  PortfolioSnapshotDtoV3RiskAssetClassCodeEnum,
} from "@iliotech/generated-api-v3";
import groupby from "lodash.groupby";
import { PortfolioSnapshotDtoV3 } from "@iliotech/generated-api-v3";
import { generatePath } from "react-router";
import ReactGA from "react-ga4";
import { IPrice } from "../reactHooks/hooks/useOtcPriceLogic";
import { FormRenderProps } from "@progress/kendo-react-form";

type Breakdown = {
  labelField: keyof PortfolioSnapshotDtoV3;
  field: keyof PortfolioSnapshotDtoV3;
  id?: string;
};

export const sendTracking = (action: string, category?: string) => {
  ReactGA.event({
    category: category || "action",
    action,
  });
};

export const DRILL_DOWNS: Record<string, Breakdown> = {
  assetClassCode: {
    field: "assetSubClassCode",
    labelField: "assetSubClassName",
  },
  riskAssetClassCode: {
    field: "investmentVehicleCode",
    labelField: "investmentVehicleName",
  },
  regionCode: {
    field: "countryCode",
    labelField: "countryName",
  },
};

export const VOLATILITY_INFO = (
  volatilityNumber: number,
  t: any,
  loading: boolean
) => ({
  label: loading
    ? "Loading Data"
    : !volatilityNumber
    ? "No Data"
    : volatilityNumber < 7
    ? t("Low")
    : volatilityNumber > 15
    ? t("High")
    : t("Medium"),
  color: loading
    ? "Loading Data"
    : !volatilityNumber
    ? "var(--border-light-color)"
    : volatilityNumber < 7
    ? "green"
    : volatilityNumber > 15
    ? "red"
    : "orange",
});

export const sortByDate = (array: IChartDataItemV2[]) => {
  const getValidDateFormat = (d: string | moment.Moment | Date) => moment(d);
  return array.sort(
    (a, b) =>
      (getValidDateFormat(a.date!) as moment.Moment)?.diff(
        getValidDateFormat(b.date!)
      )!
  );
};

export const addAlpha = (color: string, opacity: number) => {
  if (color.startsWith("hsl")) {
    const [h, s, l] = color.split(",");
    return `hsla(${h.replace("hsl(", "")},${s},${l.replace(
      ")",
      ""
    )},${opacity})`;
  }
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const humanizeAssetLabel = (asset: string) => {
  switch (asset) {
    case "CryptoCurrencies":
      return "Crypto";
    case "CashAndEquivalents":
      return "Cash Balances";
    case "FixedIncome":
      return "Fixed Income";
    default:
      return asset;
  }
};

export const mapSnapshots: (
  snapshots: PageDTOPortfolioSnapshotDTO
) => IChartDataItemV2[] = (snapshots) => {
  const groupedByCode = groupby(snapshots?.content, "code");
  const assembledSnapshots: PortfolioSnapshotDTO[] = [];

  Object.values(groupedByCode).forEach((group: PortfolioSnapshotDTO[]) => {
    const totalAmount = group
      .map((group) => group.amount)
      .reduce((a, b) => a + b);

    assembledSnapshots.push({ ...group[0], amount: totalAmount });
  });
  /*
   * {
   * assetClassId : [],
   * assetSubClassId : {
   *  Equities:[],
   *  Funds:[],
   *  ...
   *  },
   * currency: [],
   * country:[],
   * }
   *
   * */
  return assembledSnapshots
    ?.map((item) => ({
      name: item.underlying,
      category: item.assetClassId,
      id: item.instrumentId,
      color: ALLOCATION_COLORS[item.assetClassId],
      value: item.amount,
      date: item.pricingDate as string,
      tooltipExtra: [
        {
          label: "Instrument name",
          value: item.name,
        },
        {
          label: "Date",
          value: moment(item.pricingDate as string).format("YYYY-MM-DD"),
        },
      ],
    }))
    .sort((a, b) => Math.abs(b.value) - Math.abs(a.value));
};

export function getBackgroundColor(stringInput: string = "") {
  console.log({ stringInput });
  // @ts-ignore
  let stringUniqueHash = [...(stringInput || [""])].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}

export const mapPnlByAllocation: (
  data: PortfolioAllocationHistoryResponse | undefined,
  getColor: (s: string) => string
) => IChartDataItemV2[] = (data, getColor) => {
  return (data?.pnlHistory || [])
    .map((item) => [
      ...item.pnlDateAllocation.map((curr) => ({
        name: moment(curr.date).format("MMM 'YY"),
        date: moment(curr.date),
        value: curr.pnl,
        color:
          getColor?.(item.code! as any) ||
          getBackgroundColor(item.code || "null"),
        id: item.code!,
        category: item.name,
      })),
    ])
    .reduce((prev: IChartDataItemV2[], curr: any) => [...prev, ...curr], []);
};

export const sumArray = (array: IChartDataItemV2[]) =>
  array.reduce((prev, curr) => ({
    category: prev?.category,
    name: prev?.name,
    id: prev?.id,
    value: prev?.value + curr?.value,
    color: prev?.color,
  }));

export const reduceArrays = (arrays: IChartDataItemV2[][]) => {
  return arrays.reduce((prev, curr) => [...prev, ...curr], []);
};

export const convertDataToQuarter = (array: IChartDataItemV2[]) => {
  const getQuarterString = (item: IChartDataItemV2) =>
    `Q${moment(item.date).quarter()} ${moment(item.date).year()}`;
  // we reduceDataToQuarter
  // map data to their quarter and year
  const groupedByQuarter = Object.values(
    groupby(array, (item: IChartDataItemV2) => getQuarterString(item))
  );

  const temp = groupedByQuarter.map((group) => {
    return group.map((item) => ({
      ...item,
      name: getQuarterString(item),
    }));
  });
  return reduceArrays(temp);
};

export const convertDataToLastQuarters = (array: IChartDataItemV2[]) => {
  const getQuarterString = (item: IChartDataItemV2) =>
    `Q${moment(item.date).quarter()} ${moment(item.date).year()}`;
  // we reduceDataToQuarter
  // map data to their quarter and year
  const groupedByQuarter = Object.values(
    groupby(array, (item: IChartDataItemV2) => getQuarterString(item))
  );

  const toLastMonths = groupedByQuarter.map((quarter) => {
    const months = quarter.sort(
      (a, b) =>
        moment(a.name, "MMM 'YY").valueOf?.() -
        moment(b.name, "MMM 'YY").valueOf?.()
    );

    const lastMonth = moment(months[months.length - 1].name, "MMM 'YY")
      .toDate()
      .getMonth();

    return quarter.filter(
      (item) => moment(item.name, "MMM 'YY").toDate().getMonth() === lastMonth
    );
  });

  const temp = toLastMonths.map((group) => {
    return group.map((item) => ({
      ...item,
      name: getQuarterString(item),
    }));
  });
  return reduceArrays(temp);
};

export const convertDataToLastYears = (array: IChartDataItemV2[]) => {
  const getYearString = (item: IChartDataItemV2) =>
    `${moment(item.date).year()}`;
  // we reduceDataToQuarter
  // map data to their quarter and year
  const groupedByYear = Object.values(
    groupby(array, (item: IChartDataItemV2) => getYearString(item))
  );

  const toLastMonths = groupedByYear.map((year) => {
    const months = year.sort(
      (a, b) =>
        moment(a.name, "MMM 'YY").valueOf?.() -
        moment(b.name, "MMM 'YY").valueOf?.()
    );

    const lastMonth = moment(months[months.length - 1].name, "MMM 'YY")
      .toDate()
      .getMonth();

    return year.filter(
      (item) => moment(item.name, "MMM 'YY").toDate().getMonth() === lastMonth
    );
  });

  const temp = toLastMonths.map((group) => {
    return group.map((item) => ({
      ...item,
      name: getYearString(item),
    }));
  });
  return reduceArrays(temp);
};

export const convertDataToYears = (array: IChartDataItemV2[]) => {
  const getYearString = (item: IChartDataItemV2) =>
    `${moment(item.date).year()}`;
  // we reduceDataToQuarter
  // map data to their quarter and year
  const groupedByYear = Object.values(
    groupby(array, (item: IChartDataItemV2) => getYearString(item))
  );

  const temp = groupedByYear.map((group) => {
    return group.map((item) => ({
      ...item,
      name: getYearString(item),
    }));
  });
  return reduceArrays(temp);
};

export const mapPnlByValue: (
  data: any,
  currencyFormatter: (v: number) => string
) => IChartDataItemV2[] = (data, currencyFormatter) => {
  return [
    ...(data?.portfolioData || []).map((item: any) => ({
      name: moment(item.date).format("MMM 'YY") as string,
      date: moment(item.date) as any,
      category: "Net Worth",
      color: "green",
      //@ts-ignore
      value: item.nav as number,
      id: JSON.stringify(item),
    })),
    ...(data?.portfolioData || []).map((item: any) => ({
      name: moment(item.date).format("MMM 'YY") as string,
      date: moment(item.date),
      category: "Net Investment",
      color: "orange",
      //@ts-ignore
      value: item.totalInv as number,
      id: JSON.stringify(item),
      tooltipExtra: [
        {
          label: "P&L",
          //@ts-ignore
          value: currencyFormatter?.(item.nav - item.totalInv),
        },
      ],
    })),
  ];
};

export const mapIncomeData: any = (data: any) =>
  (data || [])
    .map((item: { data: any[]; id: unknown; name: any }) => [
      ...item.data.map((curr) => ({
        name: moment(curr[0]).format("MMM 'YY"),
        date: moment(curr[0]),
        value: curr[1],
        color: ALLOCATION_COLORS[item.id as any],
        id: item.id!,
        category: item.name,
      })),
    ])
    .reduce((prev: IChartDataItemV2[], curr: any) => [...prev, ...curr], []);

export const mapIncomeDataWithShortAndLongs: any = (
  data: any,
  portfolioCurrencyFormatter: (value: number) => string
) =>
  (data || [])
    .map(
      (item: {
        longDetails: { valueBase: number; instrumentName: any }[];
        date: any;
        shortDetails: { valueBase: number; instrumentName: any }[];
      }) => [
        ...item.longDetails.map((long: any) => ({
          category: "Long Positions",
          value: long.valueBase,
          name: moment(item.date).format("MMM 'YY"),
          date: item.date,
          color: "blue",
          tooltipExtra: long.valueBase
            ? [
                {
                  label: long.instrumentName,
                  value:
                    long.valueLocal === long.valueBase
                      ? portfolioCurrencyFormatter(long.valueBase)
                      : long.currencyLocal?.symbol +
                        " " +
                        long.valueLocal +
                        " / " +
                        portfolioCurrencyFormatter(long.valueBase),
                },
              ]
            : [],
        })),
        ...item.shortDetails.map(
          (long: { valueBase: number; instrumentName: any }) => ({
            category: "Short Positions",
            value: long.valueBase,
            name: moment(item.date).format("MMM 'YY"),
            date: item.date,
            color: "purple",
            tooltipExtra: long.valueBase
              ? [
                  {
                    label: long.instrumentName,
                    value: portfolioCurrencyFormatter(long.valueBase),
                  },
                ]
              : [],
          })
        ),
      ]
    )
    .reduce((prev: IChartDataItemV2[], curr: any) => [...prev, ...curr], []);

export const preparePeriodForRequest = (d: Date | string) => {
  return moment(d).format("YYYY-MM-DD");
};

export const openPositionHelper = (
  position?: PortfolioSnapshotDtoV3,
  navigate?: any,
  portfolioId?: string
) => {
  if (
    !position?.positionId
    // || position.assetClassCode ===
    //   PortfolioSnapshotDtoV3RiskAssetClassCodeEnum.CashAndEquivalents
  )
    return;
  const positionId = position.positionId;

  navigate(
    generatePath?.(`/portfolio/:portfolioId/instrumentByPosition/:positionId`, {
      portfolioId,
      positionId,
    }),
    {
      state: {
        isCash: position.assetSubClassName === "Cash",
      },
    }
  );
};

export function getFlagEmoji(countryCode?: string) {
  if (!countryCode) {
    return "";
  }
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    //@ts-ignore
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export const getIconUrl = (icon: string) => {
  return `https://cdn.illio.com/${icon}`;
};

export const getNextTwoWeekDay = (date: Date = new Date()) => {
  const day = date.getDay();

  // if Thursday we add 4 days
  if (day == 4) {
    return moment(date).add(4, "days").toDate();
  }

  // if friday we add 4 days
  if (day == 5) {
    return moment(date).add(4, "days").toDate();
  }

  // if Saturday we add 2 days
  if (day === 6) {
    return moment(date).add(3, "days").toDate();
  }

  return moment(date).add(2, "days").toDate();
};

export const getNextWeekDay = (date: Date = new Date()) => {
  const isToday = new Date().toDateString() === date.toDateString();

  if (isToday) {
    return date;
  }
  const day = date.getDay();

  // if friday we add 3 days
  if (day == 5) {
    return moment(date).add(3, "days").toDate();
  }

  // if Saturday we add 2 days
  if (day === 6) {
    return moment(date).add(2, "days").toDate();
  }

  return moment(date).add(1, "days").toDate();
};

export const getLastWeekDay = (date: Date = new Date()) => {
  const day = date.getDay();
  // if monday we subtract 3 days
  if (day == 1) {
    return moment(date).subtract(3, "days").toDate();
  }

  // if Sunday we remove 2 days
  if (day === 0) {
    return moment(date).subtract(2, "days").toDate();
  }

  return moment(date).subtract(1, "days").toDate();
};

export const humanizeBrokerString = (broker: string) => {
  const dictionary = {
    IB: "Interactive Brokers",
    PLAID: "Plaid",
    VEZGO: "Vezgo",
    Q9: "Q9",
    ILLIO: "Illio",
  };

  // @ts-ignore
  return dictionary?.[broker] ?? broker;
};

export const isScientificNotation = (n: number) => n.toString().includes("e");

export const scientificNotationToString = (n: number) =>
  n.toLocaleString("fullwide", { useGrouping: true, maximumFractionDigits: 9 });

export const generateOtcCode = (str: string, codes: string[]) => {
  let code = str
    // @ts-ignore
    .match(/[\p{Alpha}\p{Nd}]+/gu)
    ?.reduce(
      (previous, next) =>
        previous +
        (+next === 0 || parseInt(next) ? parseInt(next) : next[0] || ""),
      ""
    )
    .toUpperCase();
  if (code) {
    code = code.length > 20 ? code.slice(0, 19) : code + "_1";
  } else {
    code = `OTC_AUTO_${str.substr(0, 3)}_0001`;
  }

  let finalCode = code;
  let idx = 2;

  while (codes.indexOf(finalCode) > -1) {
    finalCode = finalCode.slice(0, -1) + idx;
    idx++;
  }
  return finalCode;
};

export const isFormModified = (
  formRenderProps: FormRenderProps,
  currentForm: { [key: string]: any }
) => {
  const keys = Object.keys(formRenderProps.errors);

  const keyMatch = keys.map((k) => {
    return (
      JSON.stringify(currentForm[k] || undefined) ===
      JSON.stringify(formRenderProps.valueGetter(k) || undefined)
    );
  });
  return keyMatch.some((v) => !v);
};

const arePricesModified = (prices: IPrice[], defaultPrices: IPrice[]) => {
  // if prices are untouched, then prices will have default value and defaultPrices will be empty
  if (prices.length === 1 && defaultPrices.length === 0 && !prices?.[0].price) {
    return false;
  }

  return JSON.stringify(prices) !== JSON.stringify(defaultPrices);
};

export const isFormWithPricesModified = (
  formRenderProps: FormRenderProps,
  currentForm: { [key: string]: any },
  prices: IPrice[],
  defaultPrices: IPrice[]
) => {
  return (
    isFormModified(formRenderProps, currentForm) ||
    arePricesModified(prices, defaultPrices)
  );
};
