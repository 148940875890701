import React, { PropsWithChildren, useContext } from "react";
import { useParams } from "react-router-dom";
import PusherHandler from "../../utils/PusherHandler";
import { useQueryClient } from "react-query";
import { usePreferences } from "./UserPreferencesContext";
import { useNotification } from "@iliotech/component-library";
import { useWhatIfPusherLogic } from "../hooks/useWhatIfPusherLogic";
import debounce from "lodash/debounce";
//
interface IOwnProps {
  portfolioId?: string;
  pusherId: string;
  pusherCluster: string;
}

export enum UploadType {
  TRADES,
  POSITIONS,
  WHAT_IF,
}
export type PnlCalculationState = "red" | "yellow" | "green";

interface IPusherContext extends ReturnType<typeof useWhatIfPusherLogic> {
  pnlCalcState: PnlCalculationState;
  hidePortfolioUpdatingModal?: boolean;
  ready: boolean;
  disablePNLCalculationWarning(): void;

  setPnlCalcState: React.Dispatch<React.SetStateAction<PnlCalculationState>>;
  completedUpload: { id?: string; tradeType: UploadType } | undefined;
  setCompletedUpload: React.Dispatch<
    { id?: string; tradeType: UploadType } | undefined
  >;

  awaitingUploadId: string | undefined;
  setAwaitingUploadId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const noop = () => {
  console.log("Pusher context not ready yet");
};
export const PusherContext = React.createContext<IPusherContext>({
  pnlCalcState: "green",
  disablePNLCalculationWarning: noop,
  ready: false,
  setPnlCalcState: noop,
  completedUpload: undefined,
  setCompletedUpload: noop,
  awaitingUploadId: undefined,
  setAwaitingUploadId: noop,
  bothPortfolioProcessed: false,
  whatIfPortfolioProcessed: false,
  resetWhatIfState: noop,
});
//
export const PusherContextProvider = ({
  children,
  pusherId,
  pusherCluster,
}: PropsWithChildren<IOwnProps>) => {
  const [pnlCalcState, setPnlCalcState] =
    React.useState<PnlCalculationState>("green");
  const [completedUpload, setCompletedUpload] =
    React.useState<{ id?: string; tradeType: UploadType } | undefined>(
      undefined
    );
  const [awaitingUploadId, setAwaitingUploadId] =
    React.useState<string | undefined>(undefined);
  const { portfolioId } =
    useParams<{
      portfolioId: string;
    }>();

  const { bothPortfolioProcessed, resetWhatIfState, whatIfPortfolioProcessed } =
    useWhatIfPusherLogic();
  const { preferences, isFetched, updateMiscPreference } = usePreferences();

  const notify = useNotification();
  const { hidePortfolioUpdatingModal } = preferences?.misc || {};

  const client = useQueryClient();

  const notificationCallback = React.useCallback(() => {
    notify.success({
      title: "Portfolio updated",
      description: "Your recently added positions have finished processing!",
    });
  }, []);

  const debouncedNotification = debounce(notificationCallback, 1000);
  React.useEffect(() => {
    if (!pusherId || !pusherCluster) {
      return;
    }
    PusherHandler.init(pusherId, pusherCluster);
  }, [pusherId, pusherCluster]);

  const handlePnlCalculation = (data: { message: PnlCalculationState }) => {
    setPnlCalcState(data.message.toLowerCase() as PnlCalculationState);
    switch (data.message) {
      case "green":
        client.invalidateQueries({
          predicate: (query) => !!(query.queryKey?.[1] as any)?.portfolioId,
        });
        break;
    }
  };

  const handlePositionProcessed = (data: { message: string }) => {
    setCompletedUpload({ id: data.message, tradeType: UploadType.POSITIONS });
  };

  const handleTradeProcessed = (data: { message: string }) => {
    setCompletedUpload({ id: data.message, tradeType: UploadType.TRADES });
  };

  const handlePositionAsTradesProcessed = (data: { message: string }) => {
    debouncedNotification();
    client.invalidateQueries({
      predicate: (query) => !!(query.queryKey?.[1] as any)?.portfolioId,
    });
    // refetch errors
    client.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === "position-upload-results" &&
        //@ts-ignore
        query.queryKey?.[1]?.status === "ERROR",
    });
  };

  React.useEffect(() => {
    if (!portfolioId) {
      return;
    }
    PusherHandler.subscribeToChannel(portfolioId);
    PusherHandler.addListener(
      portfolioId,
      PusherHandler.EVENT_NAMES.PNL_CALCULATION,
      handlePnlCalculation
    );
    PusherHandler.addListener(
      portfolioId,
      PusherHandler.EVENT_NAMES.POSITION_PROCESSED,
      handlePositionProcessed
    );
    PusherHandler.addListener(
      portfolioId,
      PusherHandler.EVENT_NAMES.TRADES_PROCESSED,
      handleTradeProcessed
    );
    PusherHandler.addListener(
      portfolioId,
      PusherHandler.EVENT_NAMES.POSITION_AS_TRADES_PROCESSED,
      handlePositionAsTradesProcessed
    );

    return () => {
      PusherHandler.removeListener(
        portfolioId,
        PusherHandler.EVENT_NAMES.PNL_CALCULATION,
        handlePnlCalculation
      );
      PusherHandler.removeListener(
        portfolioId,
        PusherHandler.EVENT_NAMES.POSITION_PROCESSED,
        handlePositionProcessed
      );
      PusherHandler.removeListener(
        portfolioId,
        PusherHandler.EVENT_NAMES.TRADES_PROCESSED,
        handlePositionProcessed
      );
      PusherHandler.removeListener(
        portfolioId,
        PusherHandler.EVENT_NAMES.POSITION_AS_TRADES_PROCESSED,
        handlePositionAsTradesProcessed
      );
    };
  }, [portfolioId]);

  const disablePNLCalculationWarning = () => {
    updateMiscPreference("hidePortfolioUpdatingModal", true);
  };

  return (
    <PusherContext.Provider
      value={{
        pnlCalcState,
        disablePNLCalculationWarning,
        hidePortfolioUpdatingModal: hidePortfolioUpdatingModal === "true",
        ready: isFetched,
        setPnlCalcState,
        completedUpload,
        setCompletedUpload,
        awaitingUploadId,
        setAwaitingUploadId,
        bothPortfolioProcessed,
        whatIfPortfolioProcessed,
        resetWhatIfState,
      }}
    >
      {children}
    </PusherContext.Provider>
  );
};

export const usePusherContext = () => useContext(PusherContext);
