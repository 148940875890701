import React, { createContext, useContext } from "react";
import { INVESTMENT_VEHICLE_COLORS } from "@iliotech/data-wire";
import moment, { MomentInput } from "moment/moment";
import { IChartDataItemV2 } from "@iliotech/types";
import {
  EnumDisplay,
  IncomePositionDetailsV3,
  PortfolioDTOWithDetailsV3,
  PortfolioPositionsFixedIncomeResponse,
} from "@iliotech/generated-api-v3";
import { FUNDAMENTALS_COLUMNS } from "./FundamentalsColumns";

export const BondsPageContext = createContext<ReturnType<
  typeof useBondsPageContextHook
> | null>(null);

export interface BondsPageContextProps {
  portfolioInfo?: PortfolioDTOWithDetailsV3;
  fixedIncomePositions?: PortfolioPositionsFixedIncomeResponse;

  Country?: Record<string, EnumDisplay> | undefined;
}

type YieldTimeSeries = {
  category: string;
  name: string;
  id: string;
  color: string;
  data: {
    date: MomentInput;
    value: number;
    details?: string[];
  }[];
};

export type RepaymentTooltipContents = {
  [dateString: string]: {
    total: number;
    assetsTotal: number;
    liabilitiesTotal: number;
    assets: {
      category: string;
      details: IncomePositionDetailsV3[];
      color: string;
    }[];
    liabilities: {
      category: string;
      details: IncomePositionDetailsV3[];
      color: string;
    }[];
  };
};

export const useBondsPageContextHook = ({
  portfolioInfo,
  fixedIncomePositions,

  Country,
}: BondsPageContextProps) => {
  const currencySymbol = portfolioInfo?.currencySymbol;
  const fundamentals = React.useMemo(() => {
    return [...(fixedIncomePositions?.fundamentals || [])]
      .sort(
        (a, b) =>
          (a.liability ? 4 : 0) +
          (a.isTotal ? -2 : 0) -
          ((b.liability ? 4 : 0) + (b.isTotal ? -2 : 0)) +
          a.name.localeCompare(b.name)
      )
      .map((f) => ({ ...f, key: `BondItem-${f.code}` }));
  }, [fixedIncomePositions?.fundamentals]);

  const yieldToWorseSeries: YieldTimeSeries = {
    category: "Yield to Worst",
    name: "Yield to Worst",
    id: "yieldToWorse",
    color: INVESTMENT_VEHICLE_COLORS.Bonds,
    data: [],
  };
  const yieldToMaturitySeries: YieldTimeSeries = {
    category: "Yield to Maturity",
    name: "Yield to Maturity",
    id: "yieldToMaturity",
    color: "var(--color)",
    data: [],
  };

  const [repaymentsKey, setRepaymentsKey] = React.useState<
    "repaymentsToFirstEvent" | "repaymentsToMaturity"
  >("repaymentsToFirstEvent");

  const fundamentalsColumns = React.useMemo(
    () => FUNDAMENTALS_COLUMNS(Country),
    [Country]
  );

  let firstTime = new Date().getTime();
  let lastTime = new Date(1970, 1, 1).getTime();

  fixedIncomePositions?.yield?.forEach((y) => {
    firstTime = Math.min(firstTime, new Date(y.date).getTime());
    lastTime = Math.max(firstTime, new Date(y.date).getTime());
    yieldToWorseSeries.data.push({
      date: y.date,
      value: y.yieldToWorse?.value,
      details: y.yieldToWorse?.details,
    });
    yieldToMaturitySeries.data.push({
      date: y.date,
      value: y.yieldToMaturity?.value,
      details: y.yieldToMaturity?.details,
    });
  });

  const yieldSeries = [yieldToWorseSeries, yieldToMaturitySeries];
  const aggregateLineChart =
    Math.abs(moment(lastTime).diff(firstTime, "days")) > 30;
  // const aggregateLineChart = yieldToWorseSeries?.data?.length > 30 || yieldToMaturitySeries.data?.length > 30;

  const { principalRepayments, repaymentTooltipContents } =
    React.useMemo(() => {
      const principalRepayments: IChartDataItemV2[] = [];

      const repaymentTooltipContents: RepaymentTooltipContents = {};

      fixedIncomePositions?.[repaymentsKey]
        ?.sort((a, b) => moment(a.date).diff(b.date))
        .forEach((repayment) => {
          Object.entries(repayment.investmentVehicleIncome).forEach(
            ([key, entry]) => {
              if (entry.longTotal !== 0) {
                const repaymentDate =
                  moment(repayment.date).toDate().getFullYear() + "";

                principalRepayments.push({
                  category: repaymentDate, //`${key}-${repayment.date}-long`,
                  name: `${key}`,
                  // name: `Long ${key}`,
                  value: entry.longTotal,
                  date: moment(repayment.date).toDate(),
                  color: INVESTMENT_VEHICLE_COLORS[key] || "blue",
                  id: "long",
                });
                repaymentTooltipContents[`${repaymentDate}`] =
                  repaymentTooltipContents[`${repaymentDate}`] || {
                    total: 0,
                    assetsTotal: 0,
                    liabilitiesTotal: 0,
                    assets: [],
                    liabilities: [],
                  };
                repaymentTooltipContents[`${repaymentDate}`].total +=
                  entry.longTotal;
                repaymentTooltipContents[`${repaymentDate}`].assetsTotal +=
                  entry.longTotal;
                repaymentTooltipContents[`${repaymentDate}`].assets.push({
                  category: `Long ${key}`,
                  details: entry.longDetails,
                  color: INVESTMENT_VEHICLE_COLORS[key] || "blue",
                });
              }
              if (entry.shortTotal !== 0) {
                const repaymentDate =
                  moment(repayment.date).toDate().getFullYear() + "";

                principalRepayments.push({
                  category: repaymentDate, //`${key}-${repayment.date}-long`,
                  name: `${key}`,
                  value: entry.shortTotal,
                  date: moment(repayment.date).toDate(),
                  color: INVESTMENT_VEHICLE_COLORS[key] || "red",
                  id: "short",
                });

                repaymentTooltipContents[`${repaymentDate}`] =
                  repaymentTooltipContents[`${repaymentDate}`] || {
                    total: 0,
                    assetsTotal: 0,
                    liabilitiesTotal: 0,
                    assets: [],
                    liabilities: [],
                  };
                repaymentTooltipContents[`${repaymentDate}`].total +=
                  entry.shortTotal;
                repaymentTooltipContents[`${repaymentDate}`].liabilitiesTotal +=
                  entry.shortTotal;
                repaymentTooltipContents[`${repaymentDate}`].liabilities.push({
                  category: `Short ${key}`,
                  details: entry.shortDetails,
                  color: INVESTMENT_VEHICLE_COLORS[key] || "red",
                });
              }
            }
          );
        });

      return { principalRepayments, repaymentTooltipContents };
    }, [fixedIncomePositions, repaymentsKey]);

  const toggleRepaymentsKey = () =>
    setRepaymentsKey((prev) =>
      prev === "repaymentsToFirstEvent"
        ? "repaymentsToMaturity"
        : "repaymentsToFirstEvent"
    );

  return {
    repaymentsKey,
    toggleRepaymentsKey,
    repaymentTooltipContents,
    principalRepayments,
    aggregateLineChart,
    yieldSeries,
    currencySymbol,
    fundamentalsColumns,
    fundamentals,
  };
};

export const useBondsPageContext = () => {
  const context = useContext(BondsPageContext);

  if (context === null) {
    throw new Error(
      "useBondsPageContext called outside of BondsPageContextProvider"
    );
  }

  return context;
};
