import * as React from "react";
import s from "./PasswordEyeButton.module.scss";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
interface IProps {
  visible?: boolean;
  onClick?(): void;
}

const PasswordEyeButton = ({ visible, onClick }: IProps) => {
  return (
    <div onClick={onClick} className={s.container}>
      {!visible ? (
        <AiOutlineEyeInvisible color={"var(--neutral)"} />
      ) : (
        <AiOutlineEye color={"var(--neutral)"} />
      )}
    </div>
  );
};

export default PasswordEyeButton;
