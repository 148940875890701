import {
  ModalLayout,
  NotificationsProvider,
  Loader,
  RemoveModalContextProvider,
} from "@iliotech/component-library";
import React, { Suspense } from "react";
import Helmet from "react-helmet";
import { SignUp } from "../../pages/Registration/SignUp";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { CustomAuthenticator } from "../../containers/Auth/CustomAuthenticator";
import {
  GlobalCurrencyProvider,
  GlobalPeriodProvider,
  PositionUploadProvider,
  PusherContextProvider,
  StructureGroupingProvider,
  ThemeContextProvider,
  UserPreferencesProvider,
  useThemeContext,
} from "@iliotech/data-wire";
import { AccountStatusChecker } from "../../pages/Landing/AccountStatusChecker";

import PageWrapper from "../../containers/PageWrapper/PageWrapper";
import IntegrationDone from "../../pages/integrationDone/IntegrationDone";
import { Userpilot } from "userpilot";
import { Home } from "../../pages/Home/Home";
import { CreatePortfolio } from "../../formModals";
import { MyProfile } from "../../containers/Auth/MyProfile/MyProfile";
import { ReferralRedirect } from "../../containers/Auth/ReferralRedirect/ReferralRedirect";
import {
  transactionUploadStore,
  TransactionUploadStoreContextProvider,
} from "@iliotech/data-wire";
import { InstrumentInsightsDemo } from "../../pages/InstrumentInsights/InstrumentInsightsDemo/InstrumentInsightsDemo";
import PortfolioList from "../../pages/PortfolioList/PortfolioList";
import { PUSHER_CLUSTER, PUSHER_ID } from "../../constants/constants";
import { PortfolioPageList } from "../../pages/LandingPage/components/PortfolioPageList/PortfolioPageList";
import { LandingPortfoliosPage } from "../../pages/LandingPage/LandingPortfoliosPage";

const PaymentSuccess = React.lazy(
  () => import("../../pages/Registration/PaymentSuccess")
);
const PortfolioRoutes = React.lazy(() => import("./PortfolioRoutes"));
export const ModalRoute = ({
  backToPortfolio,
  hideBack,
}: {
  backToPortfolio?: boolean;
  hideBack?: boolean;
}) => {
  return (
    <ModalLayout hideBack={hideBack} backToPortfolio={backToPortfolio}>
      <Outlet />
    </ModalLayout>
  );
};
// console.log({ PUSHER_ID, pusherId: window.environmentSettings.pusherId });

// // @ts-ignore
// console.log("env", {
//   win: window.environmentSettings,
//   // @ts-ignore
//   glob: globalThis.environmentSettings,
// });

export const MainRoute = () => {
  return (
    <Router>
      <Routes>
        <Route
          path={"/signup"}
          element={
            <PageTitle title={"Illio - sign up by referral"}>
              <SignUp />
            </PageTitle>
          }
        />
        <Route
          path={"/join"}
          element={
            <PageTitle title={"Illio - join"}>
              <SignUp initialStatus={"ENTER_DETAILS"} />
            </PageTitle>
          }
        />
        <Route
          path={"/referral"}
          element={
            <PageTitle title={"Illio - referral"}>
              <ReferralRedirect />
            </PageTitle>
          }
        />

        <Route
          path={"/payment-success"}
          element={
            <Suspense fallback={<Loader />}>
              <PaymentSuccess />
            </Suspense>
          }
        />
        {/*<Route path={"/register"} element={<Registration />} />*/}
        <Route path={"/"} element={<InternalRoutes />}>
          {/*<Route path={"/"} element={<AuthenticatedRoutes />}>*/}
          <Route index element={<Home />} />
          <Route
            path={"/portfolios/:workgroupName"}
            element={
              <PageWrapperTitle title={"Illio - Portfolio list"}>
                <LandingPortfoliosPage />
              </PageWrapperTitle>
            }
          />
          <Route
            path={"/examples"}
            element={
              <PageWrapperTitle title={"Illio - Example Portfolios"}>
                <LandingPortfoliosPage sample />
              </PageWrapperTitle>
            }
          />
          <Route
            path={"/portfolios/"}
            element={
              <PageWrapperTitle title={"Illio - Portfolio list"}>
                <LandingPortfoliosPage />
              </PageWrapperTitle>
            }
          />
          <Route
            path={"/workgroup/:workgroupName"}
            element={
              <PageWrapperTitle title={"Illio - Portfolio list"}>
                <LandingPortfoliosPage />
              </PageWrapperTitle>
            }
          />
          <Route path={"/integrationDone"} element={<IntegrationDone />} />

          <Route
            path={"/home"}
            element={
              <PageWrapperTitle
                title={"Illio - All your wealth in a single platform"}
              >
                <AccountStatusChecker />
              </PageWrapperTitle>
            }
          />
          <Route path={"/profile"} element={<ModalRoute />}>
            <Route
              path={"/profile"}
              element={
                <PageWrapperTitle title={"Illio - My Profile"}>
                  <MyProfile />
                </PageWrapperTitle>
              }
            />
          </Route>
          <Route
            path={"/get-started"}
            element={
              <PageWrapperTitle title={"Illio - Get started"}>
                <CreatePortfolio />
              </PageWrapperTitle>
            }
          />
          <Route
            path={"/create-portfolio"}
            element={
              <PageWrapperTitle title={"Illio - Create portfolio"}>
                <CreatePortfolio />
              </PageWrapperTitle>
            }
          />
          <Route
            path={"/create-portfolio/:workgroup"}
            element={
              <PageWrapperTitle title={"Illio - Create portfolio"}>
                <CreatePortfolio />
              </PageWrapperTitle>
            }
          />
          <Route
            path={"/instrument-insights"}
            element={<InstrumentInsightsDemo />}
          />

          {/*<Route path={"/portfolio/:portfolioId"} element={<PortfolioOutlet/>}>*/}
          {/*</Route>*/}

          <Route
            path={"*"}
            element={
              <Suspense fallback={<Loader />}>
                <PortfolioRoutes />
              </Suspense>
            }
          />
        </Route>
        {/*</Route>*/}
      </Routes>
    </Router>
  );
};

const OutletWrapper = () => {
  const { mode } = useThemeContext();
  return (
    <div className="App" data-theme={mode} id="app">
      <Outlet />
    </div>
  );
};
const InternalRoutes = () => {
  const location = useLocation();
  React.useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return (
    <PositionUploadProvider>
      <NotificationsProvider>
        <TransactionUploadStoreContextProvider value={transactionUploadStore}>
          <CustomAuthenticator>
            <GlobalPeriodProvider>
              <UserPreferencesProvider>
                <GlobalCurrencyProvider>
                  <StructureGroupingProvider enableSelection={true}>
                    <RemoveModalContextProvider>
                      <AccountStatusChecker>
                        <ThemeContextProvider>
                          <OutletWrapper />
                        </ThemeContextProvider>
                      </AccountStatusChecker>
                    </RemoveModalContextProvider>
                  </StructureGroupingProvider>
                </GlobalCurrencyProvider>
              </UserPreferencesProvider>
            </GlobalPeriodProvider>
          </CustomAuthenticator>
        </TransactionUploadStoreContextProvider>
      </NotificationsProvider>
    </PositionUploadProvider>
  );
};

const PageWrapperTitle = ({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>) => {
  return (
    <PageWrapper>
      <Helmet title={title} />
      {children}
    </PageWrapper>
  );
};
const PageTitle = ({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>) => {
  return (
    <>
      <Helmet title={title} />
      {children}
    </>
  );
};
