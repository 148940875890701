import {
  REQUEST_DATE_FORMAT,
  useAuth,
  usePortfolio,
  usePortfolioComparison,
  useWhatIfPositions,
  useWhatIfScenario,
} from "@iliotech/data-wire";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import { observer } from "mobx-react-lite";
import {
  BOTTOM_BAR_HEIGHT,
  BottomBar,
  FullScreenCssSpinner,
  RoundButton,
} from "src/main";
import WhatIfStepper from "src/components/__stepTrackers/WhatIfStepper/WhatIfStepper";
import { WhatIfResultsTable } from "src/components/__tables/WhatIfResultsTable/WhatIfResultsTable";
import s from "./WhatIfresultsPage.module.scss";
import {
  EsgComparator,
  IncomeComparator,
  NetWorthComparator,
  PerformanceComparator,
  RiskComparator,
} from "./comparators";
import moment from "moment";
import { SaveScenarioModal } from "src/components/__modals/SaveScenarioModal/SaveScenarioModal";
import { PortfolioComparisonResponse } from "@iliotech/generated-api-v3";
interface WhatIfResultsPageProps {
  onSave: (name: string) => void;
  onDelete: () => void;
  onNotSave: () => void;
  isLoading: boolean;
  scenarioData: PortfolioComparisonResponse;
  virtualId: string;
  basePortfolioId: string;
  title?: string;
  skipCloseModal?: boolean;
  onCloseWithNoSave?: () => void;
}

export const WhatIfResultsPage = observer(
  ({
    onSave,
    isLoading,
    scenarioData,
    virtualId,
    basePortfolioId,
    skipCloseModal,
    title = "What-If",
    onNotSave,
    onCloseWithNoSave,
  }: WhatIfResultsPageProps) => {
    const baseCurrency = usePortfolio(basePortfolioId!)?.data?.data
      ?.currencySymbol;

    const valueFormatter = (v: number) =>
      baseCurrency +
      " " +
      v.toLocaleString(undefined, { maximumFractionDigits: 0 });

    const period = {
      from: moment(moment(new Date()).subtract(1, "year").toDate()).format(
        REQUEST_DATE_FORMAT
      ),
      to: moment(moment(new Date()).toDate()).format(REQUEST_DATE_FORMAT),
    };
    const store = useWhatIfPositions();
    const loading = store.transitionLoading || isLoading;

    const onFinish = () => {
      if (skipCloseModal || store.skipSave) {
        onCloseWithNoSave?.();
        store.skipSave = false;
        return;
      } else {
        store.saveModal = true;
      }
    };

    const SaveScenarioModalWrapper = observer(() => {
      const store = useWhatIfPositions();

      return (
        <SaveScenarioModal
          onSave={onSave}
          buttonLoading={store.loading}
          handleDelete={onNotSave}
          handleClose={() => (store.saveModal = false)}
          visible={store.saveModal}
        />
      );
    });

    const renderInner = () => {
      if (loading)
        return (
          <FullScreenCssSpinner
            text="Your What-if Scenario is being calculated"
            footerText={"Your results will be ready \n" + "in a few seconds"}
            containerProps={{ style: { marginTop: 80 } }}
          />
        );
      return (
        <div
          style={{ margin: "0 30px 0 30px", paddingBottom: BOTTOM_BAR_HEIGHT }}
        >
          <div className={s.tableContainer}>
            <h3>Summary</h3>
            <WhatIfResultsTable
              data={scenarioData?.results!}
              baseCurrencySymbol="€"
            />
          </div>

          <NetWorthComparator
            virtualId={virtualId}
            basePortfolioId={basePortfolioId!}
            valueFormatter={valueFormatter}
          />
          <div style={{ height: 20 }} />
          <PerformanceComparator
            virtualId={virtualId}
            basePortfolioId={basePortfolioId!}
            period={period}
          />
          <div style={{ height: 20 }} />
          <IncomeComparator
            virtualId={virtualId}
            basePortfolioId={basePortfolioId!}
            valueFormatter={valueFormatter}
          />
          <div style={{ height: 20 }} />
          <RiskComparator
            virtualId={virtualId}
            basePortfolioId={basePortfolioId!}
            period={period}
          />
          <div style={{ height: 20 }} />
          <EsgComparator
            virtualId={virtualId}
            basePortfolioId={basePortfolioId!}
          />
        </div>
      );
    };

    return (
      <div className={"container"}>
        <Typography.h1
          fontWeight={"light"}
          textAlign={"center"}
          style={{ marginTop: "5px" }}
        >
          {title}
        </Typography.h1>
        <SaveScenarioModalWrapper />
        {renderInner()}
        {!skipCloseModal && (
          <BottomBar height={BOTTOM_BAR_HEIGHT} absolute>
            <WhatIfStepper
              currentStep={2}
              edit={false}
              rightContent={
                <RoundButton onClick={onFinish} style={{ maxWidth: 100 }}>
                  Finish
                </RoundButton>
              }
            />
          </BottomBar>
        )}
      </div>
    );
  }
);

export default WhatIfResultsPage;
