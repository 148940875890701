import * as React from "react";
import IllioDialog from "../IllioDialog/IllioDialog";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import RoundButton from "../RoundButton/RoundButton";

interface IProps {
  visible: boolean;
  closeModal: () => void;
}

const AwaitingDialog = ({ closeModal, visible }: IProps) => {
  return visible ? (
    <IllioDialog width={600} onClose={() => closeModal()}>
      <p className={"element-title"}>Your portfolio is being populated</p>
      <p>
        While we’re populating your portfolio with your assets from Interactive
        Brokers you can add other investments you have with other brokers via
        connection, excel upload or manually entering your assets.
      </p>
      <br />
      <p>
        WARNING: Do not add any investments you have held with Interactive
        Brokers otherwise these will be duplicated in your portfolio.
      </p>
      <DialogActionsBar layout={"center"}>
        <RoundButton style={{ maxWidth: 160 }} onClick={closeModal}>
          Ok
        </RoundButton>
      </DialogActionsBar>
    </IllioDialog>
  ) : null;
};

export default AwaitingDialog;
