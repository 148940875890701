import { useThemeContext } from "@iliotech/data-wire";
import Sun from "./assets/sun.svg";
import Moon from "./assets/moon.svg";
import s from "./ThemeSwitcher.module.scss";

export const ThemeSwitcher = () => {
  const { mode, setMode } = useThemeContext();
  const toggleDarkMode = () => {
    if (mode === "dark") {
      setMode("light");
    } else {
      setMode("dark");
    }
  };
  return (
    <div onClick={toggleDarkMode} className={s.icon}>
      {mode === "dark" ? (
        <img src={Sun} className="no-invert" />
      ) : (
        <img src={Moon} className="no-invert" />
      )}
    </div>
  );
};
