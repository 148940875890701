/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AssignDto
 */
export interface AssignDto {
    /**
     * 
     * @type {string}
     * @memberof AssignDto
     */
    'userIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof AssignDto
     */
    'accountRole': AssignDtoAccountRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AssignDtoAccountRoleEnum {
    Owner = 'OWNER',
    Manager = 'MANAGER',
    Customer = 'CUSTOMER',
    RoCustomer = 'RO_CUSTOMER'
}


