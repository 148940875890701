import {
  CreatePositionRequest,
  PortfolioTradesApi,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

export const sendFinalPositionsAsync = (
  rows: CreatePositionRequest[],
  portfolioId: string,
  tradeType?: "REGULAR_TRADES" | "POSITION_TRADES" | "WHAT_IF_POSITIONS",
  deleteExistingPositions?: boolean
) => {
  if (!portfolioId || !rows) {
    return;
  }
  const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);
  return api.addPortfolioPositionsAsync(
    rows,
    portfolioId,
    tradeType,
    deleteExistingPositions
  );
};
