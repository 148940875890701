import { useQuery } from "react-query";
import { PortfolioTradesApi } from "@iliotech/generated-api-v3";
import { API_BASE } from "../../constants/constants";

export const usePortfolioPrivateEquities = (
  portfolioId: string,
  currency?: string
) => {
  const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);
  return useQuery(
    [`portfolio-position-private-equity`, { portfolioId, currency }],
    () => api.getPortfolioPositionsPrivateEquity(portfolioId, currency as any)
  );
};
