import React, { useEffect, useState } from "react";
import { usePreferences } from "./UserPreferencesContext";

export const ThemeContext =
  React.createContext<ReturnType<typeof useThemeContextHook> | undefined>(
    undefined
  );

const useThemeContextHook = () => {
  const { preferences, updateMiscPreference } = usePreferences();
  const [mode, setMode] = useState("dark");

  function getPreferredColorScheme() {
    if (window.matchMedia) {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
      } else {
        return "light";
      }
    }
    return "light";
  }
  const preferredMode = getPreferredColorScheme();

  const updateMode = (mode: string) => {
    console.log("UPDATING MODE TO " + mode);
    setMode(mode);
    updateMiscPreference("theme", mode);
  };

  useEffect(() => {
    if (preferences?.misc?.theme) {
      console.log(preferences.misc.theme);
      setMode(preferences.misc.theme.replaceAll('"', ""));
    } else if (preferredMode) {
      // setMode(preferredMode);
    }
  }, [preferences?.misc?.theme]);

  return { mode, setMode: updateMode };
};

export const ThemeContextProvider = ({
  children,
}: React.PropsWithChildren<any>) => {
  const { mode, setMode } = useThemeContextHook();
  return (
    <ThemeContext.Provider value={{ mode, setMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = React.useContext(ThemeContext);
  if (typeof context === "undefined") {
    throw new Error(
      "theme context can only be accessed from within it's provider"
    );
  }
  return context;
};
