import * as React from "react";
import s from "./Empty.module.scss";
//@ts-ignore
import EmptyImage from "./assets/nodata.svg";
// "../../../../nodata.svg";
import { Typography } from "@progress/kendo-react-common";

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  text?: string;
}

export const Empty = ({ text, ...rest }: IProps) => {
  return (
    <div className={s.container} {...rest}>
      <img src={EmptyImage} className="no-invert" alt={"empty"} />
      <Typography.p style={{ marginTop: 2 }} fontSize={"medium"}>
        {text || "No data"}
      </Typography.p>
    </div>
  );
};

export default Empty;
