import React from 'react';
import {Loader} from "@iliotech/component-library";

const PUBLIC_WEBSITE_URL = "https://www.illio.com/individual-investor";

const CHECK_INTERVAL = 200;

export const ReferralRedirect = () => {
  const checkReferralAndMaybeRedirect = React.useCallback((count = 0) => {
    const affiliateReferrer = window.Rewardful?.referral;
    if(!!affiliateReferrer || count > 10){
      window.location.href = PUBLIC_WEBSITE_URL;
    } else {
      setTimeout(() => {
        console.log(count)
        checkReferralAndMaybeRedirect(count + 1);
      }, CHECK_INTERVAL)
    }
  
  }, [])
  
  React.useEffect(() => {
    checkReferralAndMaybeRedirect();
  }, []);
  
  
  
  
  return <Loader />
};
