import { useWhatIfPositions } from "@iliotech/data-wire/src/stores";
import { observer } from "mobx-react-lite";
import { WhatIfResultsCellProps } from "../WhatIfResultsColumn";
import UP from "./assets/UP.svg";
import DOWN from "./assets/DOWN.svg";
import EQUAL from "./assets/EQUAL.svg";
import s from "./ChangeCell.module.scss";

export const ChangeCell = ({ dataItem }: WhatIfResultsCellProps) => {
  const renderDirection = (v?: string) => {
    switch (v) {
      case "Higher":
        return <img className={s.isArrow} src={UP} />;
      case "Equal":
        return <img src={EQUAL} />;
      case "Lower":
        return <img className={s.isArrow} src={DOWN} />;
      default:
        return <span />;
    }
  };
  return (
    <div className={s.directionContainer}>
      {dataItem?.change}
      {renderDirection(dataItem?.direction)}
    </div>
  );
};

export default ChangeCell;
