import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import s from "./IllioTabBar.module.scss";
import { TabType } from "@iliotech/consumer/src/constants/tabSettings";

export type IllioTabBarViewItem = { name: string; id: string };

export const IllioTabBar = ({
  views,
  initialView,
  selectedView,
  onTabClick,
}: {
  views: IllioTabBarViewItem[];
  initialView?: TabType;
  selectedView?: TabType;
  onTabClick?: (id: string) => void;
}) => {
  const { view = initialView || TabType.Summary } = useParams();
  const navigate = useNavigate();

  const defaultTabClick = (id: string) => {
    const currentLocation = (window?.location?.pathname || "").split("/");
    if (
      Object.values(TabType).includes(
        currentLocation[currentLocation.length - 1] as any as TabType
      )
    ) {
      currentLocation.pop();
    }
    const finalLocation = currentLocation.join("/");
    navigate(`${finalLocation}/${id}`);
  };

  const handleTabClick = (id: string) => {
    if (onTabClick) {
      onTabClick(id);
    } else {
      defaultTabClick(id);
    }
  };

  return (
    <div className={s.tabWrapper}>
      {views.map(({ name, id }) => (
        <div
          onClick={() => handleTabClick(id)}
          key={id}
          className={cn(s.tab, selectedView?.includes(id) && s.active)}
        >
          {name}
        </div>
      ))}
    </div>
  );
};
