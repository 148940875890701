import * as React from "react";
import { Blur } from "../../../Blur/Blur";
import { useEffect } from "react";
import { useAuth } from "@iliotech/data-wire";
import TrialEndedDialog from "../../../TrialEndedDialog/TrialEndedDialog";
import { useUser } from "@iliotech/data-wire/src/reactHooks/hooks/useUser";
interface IProps {}

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "8344059",
          formId: "b7786d74-efbe-411d-a6d6-37d02456b761",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  );
};

export const IBContactUserSupport = ({}: IProps) => {
  const { logOut } = useAuth();
  const { user } = useUser();

  return (
    <Blur>
      <TrialEndedDialog
        username={user?.name! || ""}
        logout={logOut}
        path={"https://support.illio.com/kb-tickets/new"}
        visible={true}
      />
    </Blur>
  );
};
