import React from "react";
import { Typography } from "@progress/kendo-react-common";
import { PortfolioSettingsDetails } from "./pages/PortfolioSettingsDetails";
import cn from "classnames";
import s from "./styles/PortfolioSettings.module.scss";
import { PortfolioSettingsMenu } from "./pages/PortfolioSettingsMenu";
import { PortfolioSettingsCustodians } from "./pages/PortfolioSettingsCustodians";
import { IPortfolioSettingsProps } from "./types";
import { PortfolioSettingsBenchmark } from "./pages/PortfolioSettingsBenchmark";

export const PortfolioSettings = ({
  portfolioInfo,
  loading,
  setDefaultCustodian,
  savePortfolioName,
  setBenchmark,
  availableBenchmarks,
  refetch,
}: IPortfolioSettingsProps) => {
  const [selectedTab, setSelectedTab] = React.useState("details");
  let content = null;
  switch (selectedTab) {
    case "details":
      content = (
        <PortfolioSettingsDetails
          loading={loading}
          portfolioInfo={portfolioInfo}
          saveName={savePortfolioName}
        />
      );
      break;
    case "custodians":
      content = (
        <PortfolioSettingsCustodians
          loading={loading}
          portfolioInfo={portfolioInfo}
          setDefaultCustodian={setDefaultCustodian}
          refetch={refetch}
        />
      );
      break;
    case "benchmark":
      content = (
        <PortfolioSettingsBenchmark
          loading={loading}
          portfolioInfo={portfolioInfo}
          setBenchmark={setBenchmark}
          availableBenchmarks={availableBenchmarks}
          refetch={refetch}
        />
      );
      break;
  }

  return (
    <>
      <Typography.h3 style={{ paddingLeft: "2rem" }}>
        Portfolio settings
      </Typography.h3>
      <div className={cn(s.layout)}>
        <PortfolioSettingsMenu {...{ selectedTab, setSelectedTab }} />
        <div className={cn(s.content)}>{content}</div>
      </div>
    </>
  );
};
