import React from 'react';
import {Notification} from "./Notification";

interface IOwnProps {
  // setNotifyFunction: (fn: (notifyFn: any) => void)  => void;
}

const noop = (functionName: string = "function") => () => void console.log(`${functionName} not yet available`)

export const NotificationsContext = React.createContext<INotificationsContext>({
  success: noop("success"),
  error: noop("error"),
  warn: noop("warn"),
})

type NotificationType = "success" | "warn" | "error";
interface INotification {
  title?: string;
  description?: string;
  type: NotificationType;
  duration?: number;
  id: string;
}

interface INotificationsContext {
  success: INotificationFunction;
  error: INotificationFunction;
  warn: INotificationFunction;
}

type NotificationSettings = Omit<INotification, "type" | "id">;

interface INotificationFunction{
  (settings: NotificationSettings): void
}



export const NotificationsProvider: React.FC<IOwnProps> = ({children}) => {
  
  const [notifications, setNotifications] = React.useState<INotification[]>([]);
  
  const createNotification = (type: NotificationType) => (settings: NotificationSettings) => {
    const id = `${Math.random()}`;
    setNotifications(prev => ([...prev, {type, ...settings, id}]));
    return id;
  }
  
  const deleteNotification = (id: string) => {
    setNotifications(prev => [...prev].filter(n => n.id !== id));
  }
  
  const {success, error, warn} = React.useMemo(() => {
    const success = createNotification("success");
    const error = createNotification("error");
    const warn = createNotification("warn");
    return {success, error, warn}
  }, [])
  
  
  
  return (
    <NotificationsContext.Provider value={{success, error, warn}}>
      {children}
  
      {notifications.map(({ id, type, title, description, duration }) => (
        <Notification
          key={id}
          type={type}
          onClose={() => deleteNotification(id)}
          duration={duration}
          {...{title, description}}
         />
      ))}

    </NotificationsContext.Provider>
  );
};


export const useNotification = () => React.useContext(NotificationsContext);
