import React, { useRef } from "react";
import { useInformation, useSmallScreen } from "@iliotech/data-wire";
import cn from "classnames";
import s from "./InformationButton.module.scss";
import {
  Popover,
  PopoverHandle,
  PopoverProps,
} from "@progress/kendo-react-tooltip";
import { useClickAway } from "react-use";

interface IOwnProps extends PopoverProps {
  code: string;
  extraClass?: string;
  invert?: boolean;
}

const DISABLED_FOR_NOW = false;

export const ConnectedInformationButton = ({
  code,
  children,
  extraClass,
  invert,
  ...rest
}: React.PropsWithChildren<IOwnProps>) => {
  const information = useInformation(code);
  const anchor = useRef<HTMLInputElement>(null);
  const [show, setShow] = React.useState(false);
  const small = useSmallScreen();

  const divProps = React.useMemo(() => {
    if (small) {
      return { onClick: () => (show ? setShow(false) : setShow(true)) };
    } else {
      return {
        onMouseEnter: () => setShow(true),
        onMouseLeave: () => setShow(false),
      };
    }
  }, [small, show]);

  useClickAway(anchor, (e) => {
    if (show) {
      setShow(false);
    }
  });

  return (
    <div className={cn(s.container, extraClass, invert && s.reverse)}>
      {children}
      {!DISABLED_FOR_NOW && (
        <>
          <div ref={anchor} className={cn(s.infoButtonSurround)} {...divProps}>
            i
          </div>
          <Popover
            data-cy={"k-popup"}
            show={show}
            appendTo={document.getElementById("app")}
            anchor={anchor.current}
            position={"right"}
            animate={false}
            collision={{
              vertical: "flip",
              horizontal: "flip",
            }}
            contentStyle={{
              maxWidth: 250,
            }}
            {...rest}
          >
            <div>{information?.text ?? code}</div>
            <div>
              <i>{information?.subText}</i>
            </div>
          </Popover>
        </>
      )}
      {/*<div className={cn(s.content)}>{information?.text ?? code}</div>*/}
    </div>
  );
};
