import Pusher from "pusher-js";
export const PUSHER_ID = import.meta.env.VITE_PUSHER_ID;
export const PUSHER_CLUSTER = import.meta.env.VITE_PUSHER_CLUSTER;

console.log({ PUSHER_ID });

export class PusherHandler {
  static instance: Pusher | undefined = PUSHER_ID
    ? new Pusher(PUSHER_ID!, {
        cluster: PUSHER_CLUSTER,
      })
    : undefined;

  static EVENT_NAMES = {
    PNL_CALCULATION: "pnl_calculation",
    POSITION_PROCESSED: "positions_processed",
    TRADES_PROCESSED: "trades_mapped",
    POSITION_AS_TRADES_PROCESSED: "positions_uploaded_as_trades",
    WHAT_IF_POSITIONS_PROCESSED: "positions_uploaded_as_trades_for_what_if",
  };

  static subscribeToChannel = (channelName: string) => {
    PusherHandler.instance?.subscribe(channelName);
  };

  static addListener(
    channelName: string,
    eventName: string,
    handler: Function
  ) {
    console.log(
      "SUBSCRIBED TO CHANNEL" + channelName + "EVENT NAME" + eventName
    );
    PusherHandler.instance?.channel(channelName).bind(eventName, handler);
  }

  static unsubscribe(channelName: string) {
    PusherHandler.instance?.unsubscribe(channelName);
  }

  static removeListener(
    channelName: string,
    eventName: string,
    handler: Function
  ) {
    PusherHandler.instance?.channel(channelName).unbind(eventName, handler);
  }

  static init = (
    pusherId = process.env.VITE_PUSHER_ID,
    pusherCluster = process.env.VITE_PUSHER_ENV
  ) => {
    PusherHandler.instance = new Pusher(pusherId!, {
      cluster: pusherCluster,
    });
  };
}

export default PusherHandler;
// export const pusherInstance = PusherHandler.instance;
