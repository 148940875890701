import { I18n } from "aws-amplify";

import { translations } from "@aws-amplify/ui-react";
I18n.putVocabularies(translations);

I18n.putVocabularies({
  en: {
    ...translations.en,
    "We Texted You": "We sent you a code",
  },
});

export const configureLocalisation = () => {};
