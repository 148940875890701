import React from "react";

import cn from "classnames";
import s from "./InstrumentInsightHeader.module.scss";

interface IOwnProps {
  instrument: IInstrumentHeaderInfo | undefined;
  title?: string;
}

export const InstrumentInsightHeader = ({ instrument, title }: IOwnProps) => {
  const priceChangeIsPositive = (instrument?.totalReturn1yPct ?? 0) > 0;
  const priceChangeSymbol = !instrument?.totalReturn1yPct
    ? ""
    : priceChangeIsPositive
      ? "+"
      : "";

  return (
    <div className={cn(s.surround)}>
      {!!instrument?.logoUrl && (
        <div className={cn(s.logo)}>
          <img src={instrument?.logoUrl} alt={instrument?.name} />
        </div>
      )}
      <div>
        <div className={cn(s.name)}>{instrument?.name}</div>
        <div>
          <span
            className={cn(
              s.priceChangePercent,
              priceChangeIsPositive ? s.positive : s.negative
            )}
          >
            {priceChangeSymbol}
            {instrument?.totalReturn1yPct}%
          </span>
          <span>Total 1 Yr Return</span>
          <span className={cn(s.subtleText)}>{title}</span>
        </div>
      </div>
    </div >
  );
};
