import { createContext, useContext } from "react";
import { WhatIfTableStore } from "./WhatIfTableStore";

export const whatIfPositionsStore = WhatIfTableStore();

export const WhatIfPositionsStoreContext = createContext(whatIfPositionsStore);
export const WhatIfPosisionStoreContextProvider =
  WhatIfPositionsStoreContext.Provider;

export const useWhatIfPositions = () => {
  return useContext(WhatIfPositionsStoreContext) as typeof whatIfPositionsStore;
};

export default whatIfPositionsStore;

export * from "./WhatIfTableStore";
