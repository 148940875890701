import React from "react";
import cn from "classnames";
import s from "./VolatilityBandsVisualization.module.scss";
import { stringToColor } from "@widgets/utils/colorUtils";
import { PlainTooltip } from "@widgets/components/Tooltips/PlainTooltip/PlainTooltip";
import {
  BENCHMARK_COLOR,
  LOW_VOL_COLOR,
  POSITIVE_COLOR,
  VOL_INTERSECT_COLOR,
} from "@widgets/constants";

interface IOwnProps {
  height?: number;
  items?: Item[];
  marketLine?: number;
}

type Item = {
  label: string;
  value: number;
};

type Group = {
  lowerBound?: number;
  upperBound?: number;
  heightUnits: number;
  label?: string;
  lowerBoundLabel?: string;
  items: Item[];
  lineStyle?: "dashed" | "solid" | "none";
};

const TOTAL_HEIGHT_UNITS = 60;
const SPACING = -10;

//  400/TOTAL_HEIGHT_UNITS
const GROUP_STRUCTURE: Group[] = [
  {
    lowerBound: 50.01,
    heightUnits: 5,
    lowerBoundLabel: "50%",
    items: [],
  },
  {
    lowerBound: 45.01,
    upperBound: 50,
    heightUnits: 5,
    lowerBoundLabel: "45%",
    items: [],
  },
  {
    lowerBound: 40.01,
    upperBound: 45,
    heightUnits: 5,
    lowerBoundLabel: "40%",
    items: [],
  },
  {
    lowerBound: 35.01,
    upperBound: 40,
    heightUnits: 5,
    lowerBoundLabel: "35%",
    items: [],
  },
  {
    lowerBound: 30.01,
    upperBound: 35,
    heightUnits: 5,
    lowerBoundLabel: "30%",
    items: [],
  },
  {
    lowerBound: 25.01,
    upperBound: 30,
    heightUnits: 5,
    lowerBoundLabel: "25%",
    items: [],
  },
  {
    lowerBound: 20.01,
    upperBound: 25,
    heightUnits: 5,
    lowerBoundLabel: "20%",
    items: [],
  },
  {
    lowerBound: 15.01,
    upperBound: 20,
    heightUnits: 5,
    lowerBoundLabel: "15%",
    items: [],
  },
  {
    lowerBound: 10.01,
    upperBound: 15,
    heightUnits: 5,
    lowerBoundLabel: "10%",
    items: [],
  },
  {
    lowerBound: 5.01,
    upperBound: 10,
    heightUnits: 5,
    lowerBoundLabel: "5%",
    items: [],
  },
  {
    upperBound: 5,
    lowerBound: 0,
    heightUnits: 5,
    lowerBoundLabel: "0%",
    items: [],
  },
];

const BandsContext =
  React.createContext<ReturnType<typeof useVolatilityBandsValue> | null>(null);

export const VolatilityBandsVisualization = ({
  height = 400,
  items,
  marketLine = 20,
}: IOwnProps) => {
  const value = useVolatilityBandsValue(
    height,
    [...GROUP_STRUCTURE],
    items,
    marketLine
  );

  return (
    <BandsContext.Provider value={value}>
      <div className={cn(s.surround)} style={{ height: 400 }}>
        <Axis />
        <div className={cn(s.chartArea)}>
          <ChartContent />
        </div>
      </div>
    </BandsContext.Provider>
  );
};

const useVolatilityBandsValue = (
  height: number,
  groupStructure: Group[],
  items?: Item[],
  marketLine?: number
) => {
  const groups = groupStructure.map((group) => {
    const topPoint = getTop(group) + 0.1;
    const top = ((TOTAL_HEIGHT_UNITS - topPoint) * height) / TOTAL_HEIGHT_UNITS;
    const bottom =
      height - (top + (group.heightUnits * height) / TOTAL_HEIGHT_UNITS);
    return {
      ...group,
      items: [] as Item[],
      top,
      bottom,
      weight: 0.5,
    };
  });

  items?.forEach((item) => {
    const group = groups.find((group) => {
      return (
        (group.lowerBound ?? -Infinity) < item.value &&
        item.value <= (group.upperBound || Infinity)
      );
    });

    if (!group) return;
    group.items.push(item);
  });
  return {
    height,
    groups,
    totalItems: items?.length ?? 0,
    marketLine: marketLine || 50,
  };
};

function getTop(group: Group) {
  return group.upperBound ?? (group.lowerBound ?? 0) + (group.heightUnits ?? 0);
}

const useVolatilityBands = () => {
  const context = React.useContext(BandsContext);
  if (!context) throw new Error("Must be used within VolatilityBands.Provider");
  return context;
};

const Axis = () => {
  const { groups } = useVolatilityBands();
  return (
    <div className={cn(s.axisSurround)}>
      {groups.map((group, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={cn(s.axisLabelSurround)}
              style={{ bottom: group.bottom }}
            >
              {group.lowerBoundLabel}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const X_LINES = Array.from({ length: 10 }, (_, i) => i + 1);

const ChartContent = () => {
  const { groups, height, marketLine } = useVolatilityBands();
  const barHeight = (height * 0.25) / TOTAL_HEIGHT_UNITS - SPACING * 2;
  const pixelsPerHeightUnit = height / TOTAL_HEIGHT_UNITS;

  const maxWeight = Math.max(...groups.map((group) => group.items.length));

  const getMarketLineBottom = (v: number) => {
    return (height / TOTAL_HEIGHT_UNITS) * v + 0.5;
  };

  const groupsAboveMarketLine = groups
    .filter((item) => item.lowerBound! >= marketLine)
    .sort((a, b) => b.lowerBound! - a.lowerBound!);

  const getBarColor = (group: Group) => {
    const isAboveMarketLine = group.lowerBound! >= marketLine;
    const intersectsMarketLine =
      (group.lowerBound! || 0) < marketLine &&
      (group.upperBound! || Infinity) >= marketLine;
    if (isAboveMarketLine) {
      const index = groupsAboveMarketLine.findIndex((item) => item === group);
      const opacityRange = 1 - 0.2; // Range between 1 and 0.2
      const step = opacityRange / groupsAboveMarketLine.length; // Step size for interpolation
      const opacity = 1 - step * index; // Calculate opacity

      return `rgba(255, 0, 0, ${opacity})`;
    } else if (intersectsMarketLine) {
      return VOL_INTERSECT_COLOR;
    } else {
      return LOW_VOL_COLOR;
    }
  };

  return (
    <div className={cn(s.gridLinesSurround)}>
      {groups.map((group, index) => {
        const barBottom =
          group.bottom +
          (group.heightUnits * pixelsPerHeightUnit) / 2 -
          barHeight / 2;
        const weight = group.items.length;
        const relativeWeight = (weight / maxWeight) * 90;
        return (
          <React.Fragment key={index}>
            <div
              className={cn(s.gridLine)}
              style={{
                bottom: group.bottom,
                borderBottom: `2px ${group.lineStyle ?? "dashed"} #aaa`,
              }}
            />
            {
              <PlainTooltip
                content={
                  <VolatilityBandsTooltip
                    items={group.items!}
                    max={group?.upperBound}
                    min={group?.lowerBound}
                  />
                }
                style={{
                  width: `${relativeWeight}%`,
                  position: "absolute",
                  bottom: barBottom,
                }}
              >
                <div
                  className={cn(s.bar)}
                  style={{
                    height: barHeight,
                    background: getBarColor(group),
                  }}
                >
                  <div className={cn(s.barLabel)}>{group.items.length}</div>
                </div>
              </PlainTooltip>
            }
            <div
              className={cn(s.gridLine)}
              style={{
                bottom: getMarketLineBottom(marketLine),
                borderTop: `1px solid #18A0FB`,
              }}
            ></div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const VolatilityBandsTooltip = ({
  items,
  max,
  min,
}: {
  items: Item[];
  max?: number;
  min?: number;
}) => {
  const formatValue = (v: number) =>
    v.toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });

  const getString = () => {
    if (!max) {
      return `Above ${min}%`;
    }

    return "Between " + min + " and " + max + "%";
  };

  const sortedItems = items.sort((a, b) => b.value - a.value);
  return (
    <div className={cn(s.tooltip)}>
      <span className="tooltip-title">{getString()}</span>
      <div>
        {sortedItems.map((item, index) => {
          return (
            <div key={index} style={{ fontSize: "0.85rem" }}>
              {item.label}: {formatValue(item.value)}%
            </div>
          );
        })}
      </div>
    </div>
  );
};
