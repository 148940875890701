import * as React from "react";
import s from "./PortfolioListPage.module.scss";

import { SearchablePortfolioList } from "../../PortfolioList";
import { Blur } from "../../Blur/Blur";
import { Link } from "react-router-dom";
import { BasicHeader } from "../../BasicHeader/BasicHeader";
import { SearchableWorkgroupPortfolioList } from "../../PortfolioList/SearchableWorkgroupPortfolioList";
import {
  useAuth,
  usePortfolioListLogic,
  useWorkgroupList,
} from "@iliotech/data-wire";

export const DEFAULT_LOGO_SOURCE =
  "https://uploads-ssl.webflow.com/60b7a1112331947d8a44a36d/60b7a472b76004ab6b185470_illio-logo.svg";

export const PortfolioListPage = () => {
  const store = useWorkgroupList();
  const { workgroupName, workgroupId } = usePortfolioListLogic();
  const { isOwner, isCustomer } = useAuth();

  const isWorkgroup = workgroupName !== "Default";

  const { logOut } = useAuth();

  const onManagerShare = () => {
    store.setModalState({
      visible: true,
      id: workgroupId!,
      name: workgroupName,
      type: "managerShare",
      loading: false,
    });
  };

  return (
    <Blur>
      <div className={s.container}>
        <BasicHeader
          logOut={logOut}
          showHelp={isWorkgroup}
          hideLogo={isWorkgroup}
          title={isWorkgroup ? workgroupName : "All Portfolios"}
        />
        <div className={s.wrapper}>
          <div className={s.links}>
            {!isCustomer && (
              <Link
                to={
                  isWorkgroup
                    ? `/create-portfolio/${workgroupName}`
                    : "/create-portfolio"
                }
              >
                + Create portfolio
              </Link>
            )}
            {isWorkgroup && isOwner && (
              <div onClick={onManagerShare}>Manager Sharing</div>
            )}
          </div>

          <div className={s.inner}>
            {isWorkgroup ? (
              <SearchableWorkgroupPortfolioList />
            ) : (
              <SearchablePortfolioList />
            )}
          </div>
        </div>
      </div>
    </Blur>
  );
};
