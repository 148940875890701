import { AccountManagementApi } from "../../../../../generated-api-v3";
import { API_BASE } from "../../../constants/constants";
import { useQuery } from "react-query";
import { useAuth } from "../../context";

const api = new AccountManagementApi(undefined, `${API_BASE}/api`);
export const useAccountDetails = (externalAccountId?: string) => {
  console.log("Using accounts");

  const query = useQuery(
    ["account-details", { externalAccountId }],
    () => api.accountDetails(externalAccountId!),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  return {
    ...query,
  };
};

export const useSelectedAccountDetails = () => {
  const { illioSelectedAccount } = useAuth();
  return useAccountDetails(illioSelectedAccount?.externalAccountId);
};
