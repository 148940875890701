import * as React from "react";
import { AccountSummaryDTO } from "@iliotech/generated-api-v3";
import s from "./AccountCard.module.scss";
import istitution from "./assets/istitution.svg";
interface IProps {
  account: AccountSummaryDTO;
  onClick(): void;
}

export const AccountCard = ({ account, onClick }: IProps) => {
  return (
    <div onClick={onClick} className={s.surround}>
      <img src={istitution} alt={account.name} />
      <h3>{account.name}</h3>
    </div>
  );
};

export default AccountCard;
