import React from "react";
import moment, { MomentInput } from "moment";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import s from "./PeriodChooser.module.scss";
import { ITimePeriod } from "./types";
import { getLastWeekDay, useGlobalPeriod } from "@iliotech/data-wire";
import CustomDateSelector from "../CustomDateSelector/CustomDateSelectors";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

interface IOwnProps {
  inceptionDate: MomentInput;
  epochDate: Date;
  selectedPeriod?: ITimePeriod;
  onPeriodSelected(period?: ITimePeriod): void;
  today?: Date;
  excludedPeriods?: PERIOD_IDS[];
  hideCustomDate?: boolean;
  hidePeriodString?: boolean;
}

export enum PERIOD_IDS {
  ONE_DAY,
  MTD,
  THIRTY_DAYS,
  ONE_YEAR,
  YTD,
  ONE_QUARTER,
  INCEPTION,
}
export const PeriodChooser = ({
  inceptionDate,
  selectedPeriod,
  onPeriodSelected,
  epochDate,
  excludedPeriods = [],
  today = moment().startOf("day").set({ hours: 3 }).toDate(),
  hideCustomDate = false,
  hidePeriodString = false,
}: IOwnProps) => {
  const { periodString } = useGlobalPeriod();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const allPeriods = React.useMemo(() => {
    const tonight = moment(today)
      .set({ hours: 21, minutes: 0, seconds: 0, millisecond: 0 })
      .toDate();
    const lastWeekDay = getLastWeekDay();
    const startOfPreviousQuarter = moment(today)
      .startOf("quarter")
      .subtract(1, "month")
      .startOf("quarter")
      .toDate();
    const quarterLabel = `Q${moment(startOfPreviousQuarter).quarter()}`;
    const namedPeriods: (ITimePeriod & {
      label: string;
      order: number;
      id: PERIOD_IDS;
      selected?: boolean;
    })[] = [
      {
        from: lastWeekDay,
        to: lastWeekDay,
        label: t("1 day"),
        id: PERIOD_IDS.ONE_DAY,
        order: 0,
      },
      {
        from: moment(today).startOf("month").toDate(),
        to: tonight,
        label: t("MTD"),
        id: PERIOD_IDS.MTD,

        order: 1,
      },
      {
        from: moment(today).startOf("year").toDate(),
        to: tonight,
        label: t("YTD"),
        id: PERIOD_IDS.YTD,

        order: 4,
      },

      {
        from: moment(today).subtract(30, "day").toDate(),
        to: tonight,
        id: PERIOD_IDS.THIRTY_DAYS,

        label: t("30 days"),
        order: 2,
      },
      {
        from: moment(today).subtract(1, "year").add(1, "day").toDate(),
        to: tonight,
        id: PERIOD_IDS.ONE_YEAR,

        label: t("1 Year"),
        order: 5,
      },
      {
        from: startOfPreviousQuarter,
        to: moment(today)
          .startOf("quarter")
          .subtract(1, "month")
          .endOf("quarter")
          .toDate(),
        id: PERIOD_IDS.ONE_QUARTER,
        label: t(quarterLabel),
        order: 3,
      },
      {
        from: inceptionDate ? moment(inceptionDate).toDate() : undefined,
        to: today,
        label: t("All"),
        order: 6,
        id: PERIOD_IDS.INCEPTION,
      },
    ];

    const periods = namedPeriods
      .filter(
        (p) =>
          (!inceptionDate || moment(p.from).isSameOrAfter(inceptionDate)) &&
          !excludedPeriods.includes(p.id)
      )
      .sort((a, b) => (moment(a.from).isAfter(b.from) ? -1 : 1))
      .map((p) => ({ ...p, selected: checkIsSelected(selectedPeriod, p) }));

    // Add custom period

    return periods;
  }, [inceptionDate, selectedPeriod, today, t, excludedPeriods]);

  const toggleOpen = () => setOpen(!open);

  return (
    <div
      className={cn("card", "tight", s.container, open && s.active)}
      data-cy={"illio-period-chooser"}
    >
      <div onClick={toggleOpen} className={s.rowMobile}>
        <div className={cn(s.dateRangeLabel)}>
          {t("Date Range")}{" "}
          <span className={s.onlyMobile}>
            :{" " + allPeriods.find((p) => p.selected)?.label}
          </span>
        </div>
        <span className={s.onlyMobile}>
          {open ? <BsChevronUp size={16} /> : <BsChevronDown size={16} />}
        </span>
      </div>
      <div
        className={cn(s.surround)}
        style={{ maxWidth: `${allPeriods.length * 7.5 + 1}rem` }}
      >
        {allPeriods
          .sort((periodA, periodB) => periodA.order - periodB.order)
          .map((period) => (
            <div
              onClick={() => {
                const { selected, ...rest } = period;
                onPeriodSelected(rest);
              }}
              key={period.label}
              className={cn(s.period, period.selected && s.selected)}
            >
              {period.label}
            </div>
          ))}
      </div>
      {!(hideCustomDate && hidePeriodString) && <div className={s.line} />}
      {!hideCustomDate && (
        <CustomDateSelector
          epochDate={epochDate}
          inceptionDate={
            inceptionDate ? moment(inceptionDate).toDate() : undefined
          }
        />
      )}
      {!hidePeriodString && (
        <span className={s.periodString} data-cy="illio-periodString">
          {periodString}
        </span>
      )}
    </div>
  );
};

function checkIsSelected(selectedPeriod?: ITimePeriod, p?: ITimePeriod) {
  return (
    !!selectedPeriod &&
    !!p &&
    moment(selectedPeriod.from).isSame(p.from, "day") &&
    moment(selectedPeriod.to).isSame(p.to, "day")
  );
}
