import React from "react";
import cn from "classnames";
import s from "./BandsChart.module.scss";
import { PlainTooltip } from "@widgets/components/Tooltips/PlainTooltip/PlainTooltip";

interface IOwnProps {
  bands: Band[];
  mode?: "weighted";
  height?: number;
  valueAxis?: {
    label: string;
    width?: number;
  };
  tooltipRenderer?: TooltipRenderer;
}

type TooltipRenderer = (band: Band, data: Band[]) => string | React.ReactNode;

export type Band<MetaShape = any> = {
  label: string;
  lowerBound: number;
  upperBound: number;
  axisLabelLower?: string;
  axisLabelUpper?: string;
  color?: string;
  labelColor?: string;
  meta?: MetaShape;
  tooltipContent?: string;
};

const DEFAULT_CATEGORY_AXIS_SETTINGS = {
  label: "",
  width: 60,
};

export const BandsChart = React.memo(
  ({
    height = 280,
    mode = "weighted",
    valueAxis: valueAxisIn = DEFAULT_CATEGORY_AXIS_SETTINGS,
    bands,
    tooltipRenderer,
  }: IOwnProps) => {
    const valueAxis = {
      ...DEFAULT_CATEGORY_AXIS_SETTINGS,
      ...valueAxisIn,
    };

    let min = 0;
    let max = 0;
    bands.forEach((band) => {
      min = Math.min(min, band.lowerBound);
      max = Math.max(max, band.upperBound);
    });

    const range = max - min;
    const data = { bands, height, min, max, range };

    return (
      <div className={cn(s.surround)}>
        <div style={{ position: "relative", height, width: valueAxis?.width }}>
          <ValueAxis {...data} label={valueAxis.label} />
        </div>
        <div style={{ position: "relative", height, flex: 1 }}>
          <Bands {...data} tooltipRenderer={tooltipRenderer} />
        </div>
      </div>
    );
  }
);

const ValueAxis = ({
  bands,
  height,
  range,
  label,
}: {
  bands: Band[];
  height: number;
  range: number;
  label?: string;
}) => {
  const size = height;

  return (
    <>
      <div className={cn(s.valueAxis)} />
      <div className={cn(s.valueAxisLabel)} style={{ width: size }}>
        {label}
      </div>
      {bands.map((band, index) => {
        const top = ((range - band.lowerBound) / range) * height;
        const itemHeight =
          ((band.upperBound - band.lowerBound) / range) * height;

        let lowerLabel = !band.axisLabelLower ? null : (
          <div
            className={cn(s.valueAxisTickLabel)}
            style={{
              top,
              height: itemHeight,
            }}
          >
            <div>{band.axisLabelLower}</div>
          </div>
        );

        let upperLabel = !band.axisLabelUpper ? null : (
          <div
            className={cn(s.valueAxisTickLabel)}
            style={{
              top: top - itemHeight,
              height: ((band.upperBound - band.lowerBound) / range) * height,
            }}
          >
            <div>{band.axisLabelUpper}</div>
          </div>
        );

        if (!band.axisLabelLower && !band.axisLabelUpper) {
          return null;
        }

        return (
          <React.Fragment key={index}>
            {lowerLabel}
            {upperLabel}
          </React.Fragment>
        );
      })}
    </>
  );
};

const Bands = ({
  bands,
  height,
  range,
  tooltipRenderer,
}: {
  bands: Band[];
  height: number;
  range: number;
  tooltipRenderer?: TooltipRenderer;
}) => {
  return (
    <>
      {bands.map((band, index) => {
        const top = ((range - band.upperBound) / range) * height;
        const tooltipContent = band.tooltipContent
          ? band.tooltipContent
          : tooltipRenderer
          ? tooltipRenderer(band, bands)
          : `${band.label}: ${band.lowerBound} - ${band.upperBound}`;

        return (
          <div
            key={index}
            className={cn(s.band)}
            style={{
              top,
              height: ((band.upperBound - band.lowerBound) / range) * height,
            }}
          >
            <PlainTooltip
              key={index}
              content={tooltipContent}
              style={{ height: "100%", width: "100%" }}
            >
              <div
                className={cn(s.bandContent)}
                style={{ background: band.color }}
              >
                {band.label}
              </div>
            </PlainTooltip>
          </div>
        );
      })}
    </>
  );
};
