import {
  PageDTOPortfolioDataV3Dto,
  PageDTOWorkgroupWithDataV3Dto,
  PortfolioAndResourceGroupsWorkgroupsApi,
  PortfolioDataV3Dto,
  WorkgroupWithPortfolioDataV3Dto,
} from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../../constants/constants";
import { usePaginator } from "../../hooks/usePaginator";
import { AxiosResponse } from "axios";

interface IProps {
  externalAccountId: string;
  name: string;
  page?: number;
  size?: number;
  workgroupType?: "CLIENT" | "INTERNAL" | "GLOBAL" | "MODEL" | undefined;
  portfolioName?: string;

  displayCurrency?: string;
}

export const useWorkgroupData = ({
  externalAccountId,
  name,
  size,
  displayCurrency,
  workgroupType,
  portfolioName,
}: IProps) => {
  const api = new PortfolioAndResourceGroupsWorkgroupsApi(
    undefined,
    `${API_BASE}/api`
  );
  const fetch: (
    page: number,
    limit: number
  ) => Promise<AxiosResponse<PageDTOPortfolioDataV3Dto>> = async (
    page,
    limit
  ) => {
    const data = await api.getWorkgroupDataByName(
      externalAccountId,
      name,
      page,
      limit,
      portfolioName,
      displayCurrency as any
    );

    return { ...data, data: data?.data?.portfoliosData };
  };

  return usePaginator<PortfolioDataV3Dto>(
    {
      queryKey: [`workgroup-data`, portfolioName!, name],
      fetch,
      size: size || 9,
    },
    {
      enabled: !!externalAccountId && !!name,
    }
  );
};
