import cn from 'classnames';
import React, { ButtonHTMLAttributes, PureComponent } from 'react';
import s from './Button.module.scss';

interface IOwnProps {
  active: boolean;
  type: string;
  disabled: boolean;
}

type IProps = ButtonHTMLAttributes<HTMLButtonElement> & IOwnProps;

class Button extends PureComponent<IProps> {
  static readonly defaultProps: Partial<IProps> = {
    active: true,
    className: '',
    type: 'button',
    onClick: () => {},
  };

  render() {
    const { active, children, className, type, disabled, ...otherProps } = this.props;

    return (
      <button
        type={type}
        className={cn(className, s.btn, {
          [s.active]: active,
          [s.disabled]: disabled,
        })}
        disabled={disabled}
        {...otherProps}
      >
        {children}
      </button>
    );
  }
}

export default Button;
