import React from 'react';
import {Blur} from "@iliotech/component-library";
import {useVariableValue} from "@devcycle/devcycle-react-sdk";

// import {
//   DownAndUpDaysChart,
//   PriceVolChart,
//   DailyReturnsChart,
//   Week52RangeChart,
//   MarketSensitivityChart,
//   PerfVsBenchmarkChart,
//   RiskReturnChart,
//   VolatilityMeasuresChart
// } from "@iliotech/widgets";

import {InstrumentInsight} from "../Containers/InstrumentInsight";

interface IOwnProps {

}

export const InstrumentInsightsDemo = (props: IOwnProps) => {
  // const [symbol, setSymbol] = React.useState("AAPL");
  const [instrumentId, setInstrumentId] = React.useState(11480);
  
  const showPage = useVariableValue("show-instruments-insights-page", false);
  
  if (!showPage) return (
    <Blur>
      <div className={"container"}>
        <h1>This page is not enabled in the current environment</h1>
      </div>
    </Blur>
  );
  
  
  return (
    <div>
      <div className={"container"}>
        {/*<h1>Instrument Insights Demo</h1>*/}
        {/*<InstrumentInsight Component={PerfVsBenchmarkChart} instrumentId={instrumentId} insightId={"PERFORMANCE_VS_BENCHMARK"}/>*/}
        {/*<InstrumentInsight Component={DownAndUpDaysChart} instrumentId={instrumentId} insightId={"UP_AND_DOWN_DAYS"}/>*/}
        {/*<InstrumentInsight Component={DailyReturnsChart} instrumentId={instrumentId} insightId={"DAILY_MOVES"}/>*/}
        {/*<InstrumentInsight Component={Week52RangeChart} instrumentId={instrumentId} insightId={"FIVE_TWO_W_HL"}/>*/}
        {/*<InstrumentInsight Component={MarketSensitivityChart} instrumentId={instrumentId} insightId={"MARKET_SENSITIVITY"}/>*/}
        {/*<InstrumentInsight Component={RiskReturnChart} instrumentId={instrumentId} insightId={"INSTRUMENT_RISK_REWARD"}/>*/}
        {/*<InstrumentInsight Component={PriceVolChart} instrumentId={instrumentId} insightId={"PRICE_VOL"}/>*/}
        {/*<InstrumentInsight Component={VolatilityMeasuresChart} instrumentId={instrumentId} insightId={"VOLATILITY_MEASURES"}/>*/}
      </div>
    </div>
  );
};
