// TODO: This will eventually be connected to the backend, but is returning static data for now
export const useInformation = (code: string) => {
  return INFORMATION_CODES.find((c) => c.code === code);
};

const INFORMATION_CODES = [
  {
    code: "portfolioTombstonePNL",
    text: "You can see your P&L by asset class, region, sector, currency or account using the “Show by” filter. Use the “Date Range” to change timeframes.",
    subText:
      "This helps you see where you made or lost money in your portfolio.",
  },
  {
    code: "portfolioTombstoneNetWorth",
    text: "This is your Net worth as at the end date in the “Custom Range” filter. You can see your asset class, region, sector, currency or account mix by using the “Show by” filter. Click any segment to get more granularity.",
    subText:
      "This helps you understand your portfolio concentration and diversification.",
  },
  {
    code: "portfolioTombstoneIncome",
    text: "You can see your past income by asset class, region, sector, currency or account using the “Show by” filter. Use the “Date Range” to change timeframes.",
    subText: "This helps you see where you have generated income.",
  },
  {
    code: "portfolioTombstoneRisk",
    text: "This risk dial calculates your historic portfolio volatility. ",
    subText:
      "To help you understand more about your portfolio risk, see the Risk page for further risk measures.",
  },
  {
    code: "portfolioTombstonePerformance",
    text: "You can compare your performance against your portfolio currency adjusted benchmark. Use the cog next to “Portfolio Overview” to change the benchmark and the “Date Range” to change timeframes.",
    subText: "This graph helps contextualize your performance.",
  },
  {
    code: "portfolioTombstoneESG",
    text: "You can see your portfolio ESG score and how this breaks down by the individual Environmental, Social and Governance factors.",
    subText: "This helps you align what you invest in, to what you care about.",
  },
  {
    code: "netWorthPieChart",
    text: "This is your Net worth as at the end date in the “Custom Range” filter. You can see your asset class, region, sector, currency or account mix by using the “Show by” filter. Click any segment to get more granularity.",
    subText:
      "This helps you further understand your portfolio concentration and diversification.",
  },
  {
    code: "netWorthPositions",
    text: "This simplified Position monitor allows you to sort and view what you own against various selectable columns. Click each position to see more details and the Insights icon to learn even more",
    subText:
      "This gives you a quick overview of your positions and you can access more filters and columns on the Positions page.",
  },
  {
    code: "netWorthBreakdown",
    text: "You can see your portfolio breakdown over time by using the “Date Range” filter. Use the “Show by” to see allocations by different categories and click any chart key for more detail.",
    subText:
      "This helps you see how your portfolio composition has changed over time.",
  },
  {
    code: "pnlByAssetClass",
    text: "Use the “Show by” to see P&L allocations by different categories and click any chart key for more detail. Use the “Date Range” to change timeframes. Click the toggle to see your total P&L against what you have invested.",
    subText:
      "This helps you see where you made or lost money in your portfolio.",
  },
  {
    code: "pnlPositionsByAbsolute",
    text: "This graph shows your top 10 biggest profit and loss makers. Use the “Show by” to see these same positions by different categories and click any chart key for more detail. Use the “Date Range” to change timeframes and the toggle hides or shows your closed positions.",
    subText:
      "This helps you focus on your positions with the largest P&L impact.",
  },
  {
    code: "pnlPositions",
    text: "This simplified Position monitor allows you to sort and view what you own against various selectable columns. Click each position to see more details and the Insights icon to learn even more.",
    subText:
      "This gives you a quick overview of your positions and you can access more filters and columns on the Positions page.",
  },
  {
    code: "incomeByAssetClass",
    text: "You can see your past income over time by changing the “Date Range”. Use the “Show by” to display by income sources and click any chart key for more detail.",
    subText:
      "This helps you see where you have generated income and how that might change when used in conjunction with Projected Income graph below.",
  },
  {
    code: "incomeProjected",
    text: "This graph projects your 1-year income on a monthly basis. Use the “Show by” to display various income sources.",
    subText:
      "When used in conjunction with the graph above, you can see why your income may change, when it will come and from where it comes from.",
  },
  {
    code: "risk",
    text: "Our Risk dial calculates your historic portfolio volatility. Use the cog next to “Portfolio Overview” to change the benchmark and the “Date Range” to change timeframes.",
    subText:
      "This helps you compare key risk metrics against your benchmark, including your risk adjusted return (Sharpe Ratio).",
  },
  {
    code: "riskDrawdown",
    text: "This visualizes how long, how deep and how often your losses have been. Use the “Date Range” to change timeframes.",
    subText:
      "This helps you get a deeper understanding of your performance by highlighting the pullbacks rather than just the return.",
  },
  {
    code: "riskScenarioManager",
    text: "You can estimate the impact on your portfolio by changing one or more of the sliders and clicking “Calculate”. The dropdown shows the results by different categories. You can always change your benchmark.",
    subText:
      "Seeing how your portfolio responds to market factors helps you decide if you need to make changes to your allocations or implement a hedge.",
  },
  {
    code: "performanceRelative",
    text: "You can compare your performance against your portfolio currency adjusted benchmark or other comparatives. Use the cog next to “Portfolio Overview” to change the benchmark and the “Date Range” to change timeframes.",
    subText: "This graph helps contextualize your performance.",
  },
  {
    code: "performancePositions",
    text: "This simplified Position monitor allows you to sort and view what you own against various selectable columns. Click each position to see more details and the Insights icon to learn even more.",
    subText:
      "This gives you a quick overview of your positions and you can access more filters and columns on the Positions page.",
  },
  {
    code: "esgScore",
    text: "You can see your portfolio ESG score and how this breaks down by the individual Environmental, Social and Governance factors.",
    subText: "This helps you align what you invest in, to what you care about.",
  },
  {
    code: "esgNetWorth",
    text: "You can sort and view your positions based on the individual Environmental, Social, and Governance metrics. ",
    subText:
      "This helps you see how each of your positions align with what you care about.",
  },
  {
    code: "optionSummary",
    text: "This table gives you a more granular view for your Option positions where you can sort and view them against various selectable columns such as notional, moneyness% and delta%.",
  },
  {
    code: "optionCumulativePNL",
    text: "This table summarizes the total P&L that options have achieved in your portfolio.",
  },
  {
    code: "optionPremiums",
    text: "This graph displays your option premiums, calculated by the sum of the intrinsic and time value, according to its future expiry date.",
    subText:
      "This helps you visualize the current market value of your options by expiry date. ",
  },
  {
    code: "optionPNL",
    text: "This graph shows how much your options have made or lost at separate points in time. Unlike the graph above, this is not cumulative but discrete periods.",
  },
  {
    code: "optionCalls",
    text: "This table breakdowns calls by moneyness. Calls with a moneyness greater than 100% are out of the money (strike price > spot price).",
    subText:
      "Any calls with a moneyness of <100% are roll candidates as they are in the money.",
  },
  {
    code: "optionPuts",
    text: "This table breakdowns puts by moneyness. Puts with a moneyness less than 100% are out of the money (strike price < spot price).",
    subText:
      "Any puts with a moneyness of >100% are roll candidates as they are in the money.",
  },
  {
    code: "positions",
    text: "This is your detailed Positions monitor as at the end date in the “Custom Range” filter. You can “Filter by” and “Group by” different categories, select a variety of columns, “Export to Excel” and add dividends.",
    subText:
      "This helps you sort and view your positions in the way you want. Click individual positions to see more detail. ",
  },
  {
    code: "transactions",
    text: "This is your Transactions monitor. You can edit and delete transactions, “Filter by” different categories, change the “Date Range”, select a variety of columns and “Export to Excel”.",
    subText:
      "This helps you sort and view your transactions in the way you want. Click individual transactions to see more detail.",
  },
  {
    code: "manualEntry",
    text: "Please note the difference between positions and transactions. Positions are instruments that enter the portfolio without an opposing cash move. Transactions have an opposing cash move.",
    subText:
      "If you are only adding transactions, you might need to add a cash deposit.",
  },
  {
    code: "manualEntryMortgage",
    text: "A mortgage is a type of loan that is most commonly used to fund the purchase or maintenance of various types of land and real estate.",
    subText:
      "Only the interest payments are considered for mortgage payments" +
      "\n" +
      "Upon expiry, the mortgage payments reduce the mortgage automatically.",
  },
  {
    code: "addInvestments",
    text: "You can add investments in 3 ways. The easiest is to connect to your broker so that your positions and transactions update automatically. Alternatively, you can manually add in bulk via a file or individually using one of the forms below.",
  },
  {
    code: "onlyManualDividends",
    text: "By selecting 'Manual' will system will not generate any Corporate Actions or Income events like dividends or coupons. The system will be reliant on connections, external sources or files to provide this data. By selecting 'Auto', the system will attempt to adjust for corporate actions like bond maturities and stock splits. It will also generate dividends and coupons. If you expect to provide this data for this Account, then you must select 'Manual'.",
  },
  {
    code: "addCommitment",
    text:
      "Create new instruments in the “Fund Legal Name” field in the commitments tabs and add commitments in the same tab after an instrument has been created. All cash flow events can be entered from the Cash Flow Tab." +
      "\n" +
      "A User will need to create an underlying instrument first before adding commitments or cash flow events associated with that investment.",
  },
];
