import { useTranslation } from "react-i18next";
import { BottomBar } from "../../BottomBar/BottomBar";
import { Typography } from "@progress/kendo-react-common";
import { WhatIfStepper } from "src/components/__stepTrackers/WhatIfStepper/WhatIfStepper";
import { IllioAlertBox } from "src/components/IllioAlertBox/IllioAlertBox";
import { FullScreenCssSpinner, RoundButton } from "src/main";
import { WhatIfPositionsTable } from "src/components/__tables/WhatIfPositionsTable/WhatIfPositionsTable";
import s from "./WhatIfPositionsPage.module.scss";
import {
  CRYPTO_SEARCH_TYPES,
  EQUITIES_SEARCH_TYPES,
  FIXED_INCOME_SEARCH_TYPES,
  usePusherContext,
  useWhatIfPositions,
} from "@iliotech/data-wire";
import { observer, Observer } from "mobx-react-lite";
import { IoAdd } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import { useInterval, useTimeout } from "react-use";
interface WhatIfPositionsPageProps {
  isEdit?: boolean;
}

export const BOTTOM_BAR_HEIGHT = 275;
const WhatIfAlert = observer(() => {
  const store = useWhatIfPositions();
  if (!store.showAlert) return null;
  return (
    <IllioAlertBox text="We’ve imported all the compatible positions from your existing portfolio covering Equities, ETFs, Crypto, Bonds and Funds. Other asset classes are coming soon" />
  );
});

const TransitionLoading = observer(() => {
  const store = useWhatIfPositions();

  const [calculations, setCalculations] = useState(1);

  const transitionLoading = store.transitionLoading;

  const tableLenght = store.tableDataArray().length;

  useInterval(
    () => {
      setCalculations((calculations) => {
        const random = Math.floor(Math.random() * (1000 * tableLenght)) + 1;
        return calculations + random;
      });
    },
    transitionLoading ? 600 : null
  );

  return (
    <div style={{ height: "100vh" }}>
      <FullScreenCssSpinner
        text="Your What-if Scenario is being calculated"
        containerProps={{ style: { marginTop: 80 } }}
        footerText={"Processing " + calculations + " calculations"}
      />
    </div>
  );
});

export function WhatIfPositionsPage({ isEdit }: WhatIfPositionsPageProps) {
  const store = useWhatIfPositions();

  const onNextPress = () => {
    store.submitModal = true;
  };

  const TotalTodayView = observer(() => {
    const store = useWhatIfPositions();

    return (
      <Typography.h3>
        <span className={s.bold}>What if portfolio total (1yr ago):</span>
        {" " + formatTotal(store.whatIfPortfolioTotal, store.currencySymbol)}
      </Typography.h3>
    );
  });

  const TotalLastYearView = observer(() => {
    const store = useWhatIfPositions();

    return (
      <Typography.p>
        <span className={s.bold}>Base portfolio total (1yr ago):</span>
        {" " + formatTotal(store.basePortfolioTotal, store.currencySymbol)}
      </Typography.p>
    );
  });

  const DifferenceView = observer(() => {
    const store = useWhatIfPositions();

    return (
      <Typography.p>
        <span className={s.bold}>Difference:</span>
        {(store.portfolioDifference > 0 ? "+" : " ") +
          formatTotal(store.portfolioDifference, store.currencySymbol)}
      </Typography.p>
    );
  });

  const formatTotal = (v: number, baseCurrencySymbol: string) => {
    return (
      baseCurrencySymbol +
      v?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })
    );
  };

  const Inner = observer(() => {
    const store = useWhatIfPositions();
    if (store.transitionLoading) {
      return <TransitionLoading />;
    }
    return (
      <div
        className={s.tableContainer}
        style={{ marginBottom: BOTTOM_BAR_HEIGHT }}
      >
        <div className={s.header}>
          <TotalTodayView />
          <TotalLastYearView />
          <DifferenceView />
        </div>
        <WhatIfPositionsTable />
        <div className={s.add}>
          <span>Add new:</span>
          <div
            onClick={() => store.onAddNewRow(EQUITIES_SEARCH_TYPES)}
            className={s.button}
          >
            <IoAdd />
            <span>Shares, ETFs and Funds</span>
          </div>
          <div
            onClick={() => store.onAddNewRow(CRYPTO_SEARCH_TYPES)}
            className={s.button}
          >
            <IoAdd />
            <span>Crypto</span>
          </div>
          <div
            onClick={() => store.onAddNewRow(FIXED_INCOME_SEARCH_TYPES)}
            className={s.button}
          >
            <IoAdd />
            <span>Bonds</span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <div className={"container"}>
        <Typography.h1
          fontWeight={"light"}
          textAlign={"center"}
          style={{ marginTop: "5px" }}
        >
          What-if
        </Typography.h1>
        <div style={{ height: 20 }} />
        <WhatIfAlert />
        <Inner />
      </div>

      <BottomBar height={BOTTOM_BAR_HEIGHT} absolute>
        <WhatIfStepper
          edit={isEdit}
          currentStep={1}
          rightContent={
            <RoundButton onClick={onNextPress} style={{ maxWidth: 100 }}>
              Next
            </RoundButton>
          }
        />
      </BottomBar>
    </div>
  );
}

export default WhatIfPositionsPage;
