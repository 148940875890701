import { ITableRow } from "./TransactionsTableStore";
import {
  InstrumentDtoStatusEnum,
  InstrumentSearchResultV3,
  PortfolioCustodian,
  TradeImport,
  TradeImportWithSearchResult,
} from "@iliotech/generated-api-v3";
import { IErroredPositionItem } from "../PositionsUpload/utils";
import { getLastWeekDay } from "../../utils";

export const parseSearchResultToEnhancedInstrument = (
  i: InstrumentSearchResultV3
) => {
  return {
    ...i,
    sourceId: i.instrumentSourceId,
  };
};

export const parseErroredTradeItem = (
  item: IErroredPositionItem,
  custodians: PortfolioCustodian[],
  index: string
) => {
  let parsedItem: ITableRow;
  const message = item.message;
  const tradeImport: TradeImport & {
    tradeTime: string;
    custodianCode: string;
  } = item.createPositionRequest as TradeImport & {
    tradeTime: string;
    custodianCode: string;
  };
  parsedItem = {
    ...tradeImport,
    tradeType: tradeImport?.buySell,
    type: tradeImport?.buySell,
    ticker: "ERRORED",
    tradeCosts: tradeImport.tradeFee,
    instrumentType: tradeImport.assetClass,
    assetClass: tradeImport.assetClass!,
    settlementCurrency: tradeImport?.settlementCurrency || tradeImport.currency,
    date: tradeImport.tradeTime
      ? new Date(tradeImport.tradeTime)
      : getLastWeekDay(),
    id: index + "",
    custodian: tradeImport.custodianCode,
    overridePrice: !!tradeImport.price,
    overrideFxRate: !!tradeImport.totalAmountBase,
    totalBase: tradeImport.totalAmountBase,
    fxRate: 1,
    currency: tradeImport.currency, //
    apiError: true,
    apiErrorString: message,
    status: {
      status: InstrumentDtoStatusEnum.Error,
      sourceId: tradeImport.sourceId?.sourceId || "",
    },
  };

  const foundCustodian = (custodians || []).find((item) => {
    const code = item?.code || "";
    const currentCustodian = parsedItem?.custodian || "";
    return code.toLowerCase() === currentCustodian.toLowerCase();
  });

  // we check if the custodian is found in the list of custodians of the portfolio
  if (foundCustodian) {
    parsedItem = {
      ...parsedItem,
      custodianResolved: true,
    };
  }

  return parsedItem;
};

export const parseTradeItem = (
  item: TradeImportWithSearchResult,
  custodians: PortfolioCustodian[],
  index: string
) => {
  let parsedItem: ITableRow;
  const preSelectedInstrument =
    item.searchResult?.length === 1 ? item.searchResult[0] : undefined;
  // we parse the item, selecting the first search result if there is only one
  parsedItem = {
    type: item.tradeImport?.buySell,
    ticker: item.tradeImport.ticker!,
    commission: item.tradeImport.commission,
    tradeCosts: item.tradeImport.tradeFee,
    instrumentType: item.tradeImport.assetClass,
    assetClass: item.tradeImport.assetClass!,
    settlementCurrency:
      item?.tradeImport?.settlementCurrency ||
      preSelectedInstrument?.currency ||
      item.tradeImport.currency,
    date: item.tradeImport.activityDate
      ? new Date(item.tradeImport.activityDate)
      : getLastWeekDay(),
    quantity:
      item.tradeImport?.buySell === "BUY"
        ? item.tradeImport.quantity
        : -item.tradeImport.quantity,
    notes: item.tradeImport.notes,
    searchResults: item.searchResult,
    id: index + "",
    price: item.tradeImport?.price || preSelectedInstrument?.price,
    custodian: item?.tradeImport.custodian,
    overridePrice: !!item.tradeImport.price,
    overrideFxRate: !!item.tradeImport.totalAmountBase,
    totalBase: item.tradeImport.totalAmountBase,
    selectedInstrument: preSelectedInstrument!,
    fxRate: 1,
    currency: preSelectedInstrument?.currency || item.tradeImport.currency,
    status: {
      status:
        preSelectedInstrument?.instrumentStatus! as any as InstrumentDtoStatusEnum,
      sourceId: preSelectedInstrument?.sourceId?.sourceId!,
    },
  };

  const foundCustodian = (custodians || []).find((item) => {
    const code = item?.code || "";
    const currentCustodian = parsedItem?.custodian || "";
    return code.toLowerCase() === currentCustodian.toLowerCase();
  });

  // we check if the custodian is found in the list of custodians of the portfolio
  if (foundCustodian) {
    parsedItem = {
      ...parsedItem,
      custodianResolved: true,
    };
  }

  return parsedItem;
};
