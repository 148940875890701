import * as React from "react";
import FormModal from "../../../../FormModal/FormModal";
import RoundButton from "../../../../RoundButton/RoundButton";
import ToggableButtons, {
  IToggableItem,
} from "../../../../ToggableButtons/ToggableButtons";
import s from "./AssignToClientModal.module.scss";
import { Checkbox } from "@progress/kendo-react-inputs";
import { FadeLoading } from "../../../../FadeLoading/FadeLoading";
interface IProps {
  visible: boolean;
  handleClose: () => void;
  onSave: (items: string[]) => void;
  name: string;
  clientList: IToggableItem[];
  loading?: boolean;
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
}

const AssignToClientModal = ({
  visible,
  handleClose,
  onSave,
  name,
  clientList,
  loading,
  checked,
  setChecked,
}: IProps) => {
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  const onSaveClicked = () => {
    onSave(selectedItems);
  };
  return (
    <FormModal
      title={"Assign to Client"}
      visible={visible}
      handleClose={handleClose}
      footer={
        <FormModal.Footer className={s.footer}>
          <div style={{ flex: 1 }}>
            <Checkbox
              label={"Include clients with existing portfolios"}
              checked={checked}
              onChange={(event) => setChecked?.(event.value)}
            />
          </div>
          <RoundButton fillMode={"outline"} onClick={handleClose}>
            Close
          </RoundButton>
          <RoundButton loading={loading} onClick={onSaveClicked}>
            Save
          </RoundButton>
        </FormModal.Footer>
      }
    >
      <div>
        <span>Client account:</span> <br />
        <b>{name}</b>
      </div>
      <div>
        <span className={s.assign}>
          Assign portfolios in client account {name} to:
        </span>
        <FadeLoading loading={loading}>
          <ToggableButtons
            size={"large"}
            singleSelect={true}
            toggableItems={clientList}
            onChange={setSelectedItems}
          />
        </FadeLoading>
      </div>
    </FormModal>
  );
};

export default AssignToClientModal;
