import {
  ExchangeRateControllerApi,
  ExchangeRateGetResponse,
} from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../../constants/constants";
import { AxiosResponse } from "axios";

export const useFxRateCached = (
  date: string,
  from?: string,
  to?: string,
  onSuccess?:
    | ((data: AxiosResponse<ExchangeRateGetResponse>) => void)
    | undefined
) => {
  const api = new ExchangeRateControllerApi(undefined, `${API_BASE}/api`);
  return useQuery(
    [`fx-rate`, { date, from, to }],
    () => api.getFxRate(from as any, to as any, date),
    { enabled: false, onSuccess }
  );
};
