import { AccountManagementApi } from "../../../../../generated-api-v3";
import { API_BASE } from "../../../constants/constants";
import { useQuery } from "react-query";
import { useAuth } from "../../context";

const api = new AccountManagementApi(undefined, `${API_BASE}/api`);
export const useAccountStatus = (externalAccountId: string | undefined) => {
  const query = useQuery(["account-status", { externalAccountId }], () =>
    api.checkAuthorisation1(externalAccountId!)
  );

  return {
    ...query,
  };
};

export const useSelectedAccountStatus = () => {
  const { illioSelectedAccount } = useAuth();
  return useAccountStatus(illioSelectedAccount?.externalAccountId);
};
