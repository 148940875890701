import { countries, zones } from "moment-timezone/data/meta/latest.json";

export const getUserCountry = () => {
  try {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneToCountry = {};

    Object.keys(zones).forEach((z) => {
      //@ts-ignore
      timeZoneToCountry[z] = countries[zones[z].countries[0]].abbr;
    });
    //@ts-ignore
    const userCountry = timeZoneToCountry[userTimeZone];
    return userCountry;
  } catch (e) {
    return undefined;
  }
};
