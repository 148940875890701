import React, { useEffect, useState } from "react";
import {
  getOrCreate,
  randomIntFromInterval,
} from "@iliotech/component-library/src/components/__tables/TransactionUploadTable/utils";
import { useInterval } from "react-use";
import {
  EnhancedInstrumentSearchResult,
  InstrumentDtoStatusEnum,
} from "@iliotech/generated-api-v3";

interface GenericRowDataCommon {
  selectedInstrument?: EnhancedInstrumentSearchResult;
  status?: {
    status: InstrumentDtoStatusEnum;
    sourceId: string;
  };
}

// Define a type for 'IProps' without a circular reference
type IProps<T> = {
  dataItem: T;
  updateTableRow(row: T): void;
};

export const useGetOrCreateRoutine = <T extends GenericRowDataCommon>({
  dataItem,
  updateTableRow,
}: IProps<T>) => {
  const [delay, setDelay] = React.useState<number | null>(null);

  const updateStatus = (status: {
    status: InstrumentDtoStatusEnum;
    sourceId: string;
  }) => {
    updateTableRow({ ...dataItem, status });
  };

  const getOrCreateRoutine = async (dataItem: T) => {
    const sourceId = dataItem.selectedInstrument?.sourceId.sourceId!;
    if (
      dataItem?.status?.status === "ACTIVE" &&
      dataItem.status.sourceId === sourceId
    ) {
      setDelay(null);
      return;
    }
    const status = await getOrCreate(
      sourceId,
      dataItem.selectedInstrument?.sourceId.sourceData!
    );
    if (status.data.status === "ERROR" || status.data.status === "ACTIVE") {
      setDelay(null);
      updateStatus({ status: status.data.status, sourceId });
      return;
    }

    if (status.data.status === "DRAFT") {
      const newDelay = randomIntFromInterval(5000, 7000);
      setDelay(newDelay);
      updateStatus({ status: status.data.status, sourceId });
    }
  };

  useEffect(() => {
    if (!dataItem?.selectedInstrument?.sourceId?.sourceId) {
      return;
    }
    getOrCreateRoutine(dataItem);
  }, [dataItem?.selectedInstrument]);

  useInterval(() => getOrCreateRoutine(dataItem), delay);

  return {};
};
