import { PortfolioPerformanceApi } from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";

export const useRecommendedBenchmarks = (portfolioId: string) => {
  const api = new PortfolioPerformanceApi(undefined, `${API_BASE}/api`);
  return useQuery(
    [`portfolio-recommended-benchmarks`, { portfolioId }],
    () => api.getRecommendedBenchmarks(portfolioId),
    { enabled: !!portfolioId }
  );
};
