import { Typography } from "@progress/kendo-react-common";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { observer } from "mobx-react-lite";
import { AiOutlineClose } from "react-icons/ai";
import s from "../../../PositionEntryCellRenderer/PositionEntryCellRenderer.module.scss";
import { parseLocaleNumber } from "@iliotech/data-wire";
import { TransactionTableCellProps } from "./MarketPriceCell";
import { usePositionUploadV2 } from "@iliotech/data-wire/src/stores/PositionsUpload";

const DeleteCell = ({ index }: TransactionTableCellProps) => {
  const store = usePositionUploadV2();

  const onDelete = () => {
    store.position.removeTableRow(index + "");
  };
  const CloseBtn = ({ ...props }) => (
    <AiOutlineClose
      {...props}
      className={s.closeIcon}
      onClick={() => onDelete()}
    />
  );

  return <CloseBtn />;
};

export default observer(DeleteCell);
