import React, { version } from "react";
import { version as reactDomVersion } from "react-dom";
import { MainRoute } from "./navigation/Routes/MainRoute";
import { Auth } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  useIsDVCInitialized,
  withDVCProvider,
} from "@devcycle/devcycle-react-sdk";
import cn from "classnames";

import {
  API_BASE,
  ThemeContextProvider,
  useThemeContext,
} from "@iliotech/data-wire";
import { Loader } from "@iliotech/component-library";
import "@iliotech/themes/lib/illio-theme.scss";
import "@progress/kendo-theme-default/dist/all.css";
import { configureLocalisation } from "./initialisation-scripts/configureLocalisation";
import { configureLocalhostEnvironmentVariables } from "./initialisation-scripts/configureLocalhostEnvironmentVariables";

configureLocalisation();
configureLocalhostEnvironmentVariables();
const devCycleEnv = window.environmentSettings.devcycleEnv;
console.log("Consumer react version", version);
console.log("Consumer react dom version", reactDomVersion);

// axios.defaults.headers["Cookie"] = "";
const API_URL = API_BASE;
const awsconfig = {
  aws_project_region: window.environmentSettings?.awsRegionName,
  aws_cognito_region: window.environmentSettings?.awsCognitoRegionName,
  oauth: {},
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true, // Refetch *stale* queries when mounting a component that invokes useQuery
      refetchOnWindowFocus: false, // Refetch *stale* queries when user comes back from another window
      staleTime: 5 * 60 * 60 * 1000,
    }, // Queries are considered stale after 3 hours (5[h] * 60[m] * 60[s] * 1000ms)
  },
});

function App() {
  const [ready, setReady] = React.useState(false);
  /**
   * Side effect to load third party scripts (e.g. hubspot, userpilot, etc)
   */
  React.useEffect(() => {
    injectScripts();
  }, []);
  const devCycleReady = useIsDVCInitialized();

  React.useEffect(() => {
    fetch(API_URL + "/api/public/frontend/properties", { method: "GET" })
      .then(async (response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const json = await response?.json();
          // console.log({json})
          return json;
        } else {
          console.error("UNABLE TO LOAD POOL ID");
          return {
            pid: window.environmentSettings?.fallBackCognitoPid,
            acid: window.environmentSettings?.fallbackCognitoAcid,
          };
        }
      })
      .then((result) => {
        let environment = window.environmentSettings.env || "wealth";

        // eslint-disable-next-line no-restricted-globals
        const isLocalhost =
          location.hostname === "localhost" ||
          location.hostname === "127.0.0.1";
        const host = environment === "prod" ? "wealth" : environment;
        const domain = isLocalhost
          ? "localhost"
          : environment
          ? host + ".illio.com"
          : "illio.com";

        console.log("cookieDomain:" + domain);
        console.log("environment:" + environment);

        const configuration = {
          ...awsconfig,
          aws_user_pools_id: result.pid,
          aws_user_pools_web_client_id: result.acid,
          cookieStorage: {
            domain,
          },
        };
        Auth.configure(configuration);
        setReady(true);
      })
      .catch(() => null);
  }, []);

  if (!ready || !devCycleReady) {
    return <Loader fullScreen />;
  }

  return (
    <div className={cn("illio-consumer-app")}>
      <QueryClientProvider client={queryClient}>
        <MainRoute />
        <ReactQueryDevtools
          initialIsOpen={false}
          position={"bottom-left"}
          toggleButtonProps={{
            style: {
              position: "absolute",
              bottom: 10,
              left: 170,
              zIndex: 99999,
            },
          }}
        />
      </QueryClientProvider>
    </div>
  );
}

const injectScripts = () => {
  const hubspotId = window.environmentSettings.hubspotId! as string;

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "hs-script-loader";
  script.async = true;
  script.defer = true;
  script.src = `//js.hs-scripts.com/${hubspotId}.js`;
  document.head.appendChild(script);
  // <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8361594.js"></script>
};

console.log("MOVE CONFIG TO ENV FROM HERE");
export default withDVCProvider({
  envKey: devCycleEnv as string,
  user: { isAnonymous: true },
})(App);
