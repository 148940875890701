import React from "react";
import s from "./SubscriptionOption.module.scss";
import cn from "classnames";
import { BillingOption } from "@iliotech/data-wire";
import IllioLogo from "../../__icons/illio-logo";
import Button from "../../Button/Button";

interface IOwnProps {
  billingOption: BillingOption;
  onSelect(): void;
}

export const SubscriptionOption = ({ billingOption, onSelect }: IOwnProps) => {
  return (
    <div className={cn(s.surround)}>
      <div className={cn(s.logoSurround)}>
        <IllioLogo />
      </div>
      <label className={cn(s.name)}>{billingOption.name} Package</label>
      <p className={cn(s.description)}>{billingOption.description}</p>
      {!!billingOption.features?.length && (
        <>
          <label>Package features:</label>
          <ul>
            <li>10 broker integrations</li>
            <li>Insights on Net Worth, P&L, Risk, Performance and Income</li>
            <li>ESG scoring</li>
            {/*{billingOption.features.map((feature) => {*/}
            {/*  return <li key={feature.name}>{feature.description}</li>;*/}
            {/*})}*/}
          </ul>
        </>
      )}
      <strong className={cn(s.price)}>
        {billingOption.currencySymbol}
        {billingOption.amount} per {billingOption.interval}
      </strong>
      <div className={cn(s.taxNote)}>*excluding local taxes</div>

      <Button onClick={onSelect} className={cn(s.subscribeButton)} style={{display: "inline-block", padding: "0.4rem 2rem"}}>
        Start 1 month free trial
      </Button>
    </div>
  );
};
