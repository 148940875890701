import { Auth } from "aws-amplify";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { LOCAL_STORAGE_KEYS } from "@iliotech/data-wire";

export const useLogout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const logOut = () => {
    queryClient.clear();
    Auth.signOut().then(() => {
      window.sessionStorage.clear();
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ILLIO_SELECTED_ACCOUNT);

      navigate("/");
    });
  };

  return logOut;
};
