import React from "react";
import { observer } from "mobx-react-lite";
import FormModal from "../../FormModal/FormModal";
import s from "../UploadIssueModal/UploadIssueModal.module.scss";
import RoundButton from "../../RoundButton/RoundButton";
import { Typography } from "@progress/kendo-react-common";
import {
  IPositionTableRow,
  usePositionUploadV2,
} from "@iliotech/data-wire/src/stores/PositionsUpload";
import { getCellWarning } from "@iliotech/data-wire";
import { useParams } from "react-router";

interface IOwnProps {
  onSave: () => void;
  loading?: boolean;
}

export const SubmitPositionsModal = observer((props: IOwnProps) => {
  const store = usePositionUploadV2();
  const visible = store.position.submitModal.visible;

  const handleClose = () => {
    store.position.submitModal.visible = false;
  };

  const tableData = store.position.tableDataArray();
  const { validTrades, invalidTrades } = React.useMemo(() => {
    const invalidTrades: IPositionTableRow[] = [];
    const validTrades = tableData.filter((item) => {
      if (getCellWarning(item).icon === "OK") {
        return true;
      }
      invalidTrades.push(item);
      return false;
    });
    return { validTrades, invalidTrades };
  }, [tableData]);

  const summary = invalidTrades?.length ? (
    <Typography.p>
      We will submit <b>{validTrades.length} valid trades</b> and ignore the{" "}
      <b>{invalidTrades.length}</b> trades with errors.
    </Typography.p>
  ) : (
    <Typography.p>
      We will submit <b>{validTrades.length} trades</b>
    </Typography.p>
  );

  return (
    <FormModal
      hideClose={true}
      visible={visible}
      handleClose={() => {}}
      footer={
        <div className={s.footer}>
          <RoundButton fillMode={"outline"} onClick={handleClose}>
            Cancel
          </RoundButton>
          <RoundButton
            disabled={props.loading}
            loading={props.loading}
            className={s.save}
            onClick={props.onSave}
          >
            Continue
          </RoundButton>
        </div>
      }
    >
      <div className={s.container}>
        <Typography.h3 fontWeight={"bold"} style={{ marginBottom: 25 }}>
          Submit your positions
        </Typography.h3>
        {summary}
      </div>
    </FormModal>
  );
});
