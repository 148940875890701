import * as React from "react";
import s from "./TransactionUploadTable.module.scss";
import empty from "./assets/no-data.svg";
interface IProps {}

export const TransactionUploadEmpty = () => {
  return (
    <div className={s.empty}>
      <img src={empty} />
      <p>No records available.</p>
    </div>
  );
};

export default TransactionUploadEmpty;
