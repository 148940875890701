import { OTCInstrumentsApi } from "../../../../generated-api-v3";
import { API_BASE } from "../../constants/constants";
import { useQuery } from "react-query";

export const usePortfolioUnlistedInstruments = (portfolioId: string) => {
  const api = new OTCInstrumentsApi(undefined, `${API_BASE}/api`);

  const query = useQuery(
    ["portfolio-unlisted-instruments", { portfolioId }],
    () => api.getActivePortfolioOTCInstruments(portfolioId),
    {
      enabled: !!portfolioId,
    }
  );

  return {
    ...query,
  };
};
