import React, { PropsWithChildren } from "react";
import { FilterMap } from "../../../AllocationFilter/AllocationFilter";
import {
  InstrumentInfoV3,
  TradeDtoV3,
  PortfolioTradeControllerApi,
  PortfolioTradesApi,
} from "@iliotech/generated-api-v3";
import { EnabledColumnMap } from "../../../IllioTable/types";
import { IllioTable } from "../../../IllioTable/IllioTable";
import {
  TransactionsContext,
  TransactionsContextProps,
} from "./TransactionsContext";
import { API_BASE } from "@iliotech/data-wire";
import axios from "axios";

const DEFAULT_COLUMNS = Object.fromEntries(
  IllioTable.ColumnSets.Trades.filter((column) => column.isDefault).map(
    (col) => [col.field, { enabled: true, order: col.order ?? 1000 }]
  )
);
export const TransactionsContextProvider = ({
  children,
  ...props
}: PropsWithChildren<TransactionsContextProps>) => {
  const value = useTransactionsContextHook(props);
  return (
    <TransactionsContext.Provider value={value}>
      {children}
    </TransactionsContext.Provider>
  );
};
export const useTransactionsContextHook = ({
  portfolioInfo,
  referenceData,
  transactions: transactionsIn,
  refetchTrades,
  onDeleteTrade,
  onEditTrade,
  onClickTrade,
  loading,
}: TransactionsContextProps) => {
  const [selectedTrades, setSelectedTrades] = React.useState<
    Map<number, boolean>
  >(new Map());
  const [filterMap, setFilterMap] = React.useState<FilterMap<TradeDtoV3>>({});
  const [tradeToDelete, setTradeToDelete] = React.useState<TradeDtoV3>();
  const [enabledColumns, setEnabledColumns] =
    React.useState<EnabledColumnMap>(DEFAULT_COLUMNS);
  const [tradeDeletionModalVisible, setTradeDeletionModalVisible] =
    React.useState(false);
  const [instrumentFilterMap, setInstrumentFilterMap] = React.useState<
    FilterMap<InstrumentInfoV3>
  >({});
  // const searchInput = React.useRef<HTMLInputElement>(null);
  const [searchString, setSearchString] = React.useState("");

  const currency = portfolioInfo?.currencySymbol;

  React.useEffect(() => {
    refetchTrades?.();
  }, []);

  const visibleColumns = IllioTable.ColumnSets.TransactionsPage.filter(
    (column) => enabledColumns[column.field]
  ).sort((a, b) => (a.order ?? 100) - (b.order ?? 100));

  const toggleTradeSelection = (tradeKey: number) => {
    const tradeIsCurrentlySelected = !!selectedTrades.get(tradeKey);
    if (tradeIsCurrentlySelected) {
      const nextTrades = new Map(selectedTrades);
      nextTrades.delete(tradeKey);
      setSelectedTrades(nextTrades);
      return;
    }
    setSelectedTrades(new Map(selectedTrades.set(tradeKey, true)));
  };

  const deleteSelectedTrades = () => {
    const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);
    if (!portfolioInfo?.id) return;
    const tradeKeys = [...selectedTrades.keys()]; //.map(key => parseInt(key));

    // @ts-ignore - a Set is not required; this should be an array of keys
    return api
      .deletePortfolioTrades({ tradeIds: tradeKeys }, portfolioInfo?.id)
      .then(() => {
        setSelectedTrades(new Map());
        refetchTrades?.();
      });
  };

  const { transactions } = React.useMemo(() => {
    let transactions = transactionsIn ? [...transactionsIn] : [];
    transactions = transactions.map((t) => ({
      ...t,
      instrumentName: t.instrument?.name,
      instrumentCode: t.instrument?.code,
    }));

    const filters = Object.entries(filterMap).map(([field, filter]) => ({
      ...filter,
      field,
    }));

    const instrumentFilters = Object.entries(instrumentFilterMap).map(
      ([field, filter]) => ({
        ...filter,
        field,
      })
    );

    transactions = transactions.filter((transaction) => {
      if (transaction.operation === "SWAP_S") return false;
      for (let filter of filters) {
        const field = filter.field as keyof TradeDtoV3;
        const id = filter.id;
        if (transaction[field] !== id) {
          return false;
        }
      }

      for (let filter of instrumentFilters) {
        const field = filter.field as keyof InstrumentInfoV3;
        const id = filter.id;
        if (transaction.instrument[field] !== id) {
          return false;
        }
      }
      return true;
    });

    if (!!searchString) {
      transactions = transactions.filter((t) => {
        const tokens = searchString.toUpperCase().split(" ");
        return tokens.every(
          (token) =>
            t.instrument?.name?.toUpperCase().includes(token) ||
            t.instrument?.code?.toUpperCase().includes(token) ||
            (t.instrument?.specificMetadata as any)?.underlyingSourceId
              ?.toUpperCase()
              .includes(token)
        );
      });
    }

    return { transactions };
  }, [transactionsIn, filterMap, instrumentFilterMap, searchString]);

  const removeFilter = (field: keyof TradeDtoV3) => {
    setFilterMap((prev) => {
      const next = { ...prev };
      delete next[field];
      return next;
    });
  };
  const removeInstrumentFilter = (field: keyof InstrumentInfoV3) => {
    setInstrumentFilterMap((prev) => {
      const next = { ...prev };
      delete next[field];
      return next;
    });
  };

  // const setTradeToDelete = (trade: TradeDtoV3) => {
  //   onDeleteTrade(dataItem.key as any).then(refetchTrades)
  //
  // }

  const deleteAction = (dataItem: TradeDtoV3) => {
    if (!onDeleteTrade) return undefined;
    return setTradeToDelete(dataItem);
  };

  const selectedTradeCount = React.useMemo(() => {
    return [...selectedTrades].filter(([k, v]) => v).length;
  }, [selectedTrades]);

  const editAction = (trade: TradeDtoV3) => gotoTradeEditor(trade, onEditTrade);

  const isModel =
    portfolioInfo?.workgroupType === "MODEL" ||
    portfolioInfo?.workgroupType === "GLOBAL";

  return {
    transactions,
    filterMap,
    setFilterMap,
    setInstrumentFilterMap,
    referenceData,
    searchString,
    setSearchString,
    enabledColumns,
    setEnabledColumns,
    visibleColumns,
    currency,
    instrumentFilterMap,
    removeFilter,
    removeInstrumentFilter,
    isModel,
    deleteAction,
    editAction,
    tradeToDelete,
    setTradeToDelete,
    onDeleteTrade,
    refetchTrades,
    onClickTrade,
    loading,
    toggleTradeSelection,
    selectedTrades,
    deleteSelectedTrades,
    tradeDeletionModalVisible,
    setTradeDeletionModalVisible,
    selectedTradeCount,
  };
};
const gotoTradeEditor = (
  trade: TradeDtoV3,
  onEditTrade?: (tradeKey: number, path: string) => void
) => {
  let path = "";

  switch (trade.instrument?.assetClassCode) {
    case "Funds":
    case "Equities":
      if (trade.instrument?.assetSubClassCode === "FunAltPrivateEquity") {
        path = "private-fund";
        break;
      }
      if (trade.instrument?.assetSubClassCode === "EqOptions") {
        path = "options";
        break;
      }
      if (trade.instrument?.sourceData === "OTCService") {
        path = "unlisted-equity";
        break;
      }
      if (trade.operation !== "DIVIDEND_RECEIVED") {
        path = "equity";
      }
      break;
    case "RealAssets":
      path = "real-asset";
      break;
    case "RealEstate":
      path = "real-estate";
      break;

    case "Adjustments": {
      if (trade.instrument?.assetSubClassCode === "AdjFXSpot") {
        path = "fx-spot";
        break;
      }
      path = "cash-adjustments";
      break;
    }
    case "CryptoCurrencies":
      if (trade.operation !== "EARN") path = "crypto";
      break;
    case "FixedIncome":
      if (trade.instrument?.assetSubClassCode === "FiMortgage") {
        path = "mortgage";
        break;
      }
      if (trade.operation !== "COUPON") {
        path = "bonds";
      }
      break;
    case "SubscriptionWithdrawal":
    case "SubscriptionDeposit":
      path = "cash-transactions";
      break;
  }

  if (!path?.length) {
    return undefined;
  }
  return onEditTrade?.(trade.key as any, path);
};
