import * as React from "react";
import { Label, Error } from "@progress/kendo-react-labels";
import { FieldWrapper } from "@progress/kendo-react-form";

interface IProps {
  error?: string;
  label?: string;
  name: string;
  children: React.ReactNode | React.ReactNode[];
  dataCy?: string;
}

const InputWrapper = ({ error, name, label, children, dataCy }: IProps) => {
  return (
    <FieldWrapper data-cy={dataCy} className={"illio-input-wrapper"}>
      {label && (
        <Label editorId={name} editorValid={!error}>
          {label}
        </Label>
      )}
      {error && (
        <Error style={{ marginBottom: "2px", marginTop: -2 }} id={name}>
          {error}
        </Error>
      )}
      {children}
    </FieldWrapper>
  );
};

export default InputWrapper;
