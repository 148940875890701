import { Field } from "@progress/kendo-react-form";
import type { KeyValue, FieldValidatorType } from "@progress/kendo-react-form";
import * as React from "react";
import { Autocomplete, AutocompleteProps } from "../Autocomplete/Autocomplete";
import { EnumDisplay } from "@iliotech/generated-api-v3";

interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  allCountries?: EnumDisplay[];
  validator?: FieldValidatorType | FieldValidatorType[] | undefined;
}

const defaultValidator = (value: any) => {
  return value?.length > 0 ? "" : "Please select a country";
};

export const CountryField = ({
  errors,
  name = "country",
  label = "Country",
  defaultValue,
  allCountries,
  validator = defaultValidator,
  ...rest
}: IOwnProps) => {
  const memoData = React.useMemo(
    () => [
      ...(allCountries ?? []).map(({ code, label }) => ({
        code,
        label: `${label} (${code})`,
      })),
    ],
    [allCountries]
  );

  const [open, setOpen] = React.useState(false);
  const component = React.useMemo(
    () => (props: AutocompleteProps) =>
      (
        <Autocomplete
          {...props}
          defaultValue={defaultValue}
          opened={open}
          textField={"label"}
          onOpen={() => setOpen(true)}
          onClose={() => setTimeout(() => setOpen(false), 100)}
          onFocus={() => setOpen(true)}
        />
      ),
    [open, setOpen, defaultValue]
  );

  if (!allCountries) return null;

  return (
    <Field
      component={component}
      label={label}
      name={name}
      error={errors?.[name]}
      placeholder={"Select Country of Residence"}
      validator={validator}
      data={memoData}
      filterData={(filter?: string) => {
        return memoData?.filter(
          (item) =>
            item.label.toLowerCase().startsWith(filter?.toLowerCase() ?? "") ||
            item.code.toLowerCase().startsWith(filter?.toLowerCase() ?? "")
        );
      }}
      {...rest}
    />
  );
};
