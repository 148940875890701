import { DecimalCell } from "../Cells/DecimalCell";
import { NameCell } from "../Cells/NameCell";
import { IColumn } from "../types";
import { SensitivityRowType } from "../../Menu/Menu"

const titleMapping = new Map<string, string>();
titleMapping.set("name", "Name");
titleMapping.set("value", "Value");
titleMapping.set("exposure", "Exposure");
titleMapping.set("priceImpact", "Price impact");
titleMapping.set("fxImpact", "Fx impact");
titleMapping.set("yieldImpact", "Yield impact");
titleMapping.set("volatilityImpact", "Volatility impact");
titleMapping.set("totalImpact", "Total impact");

export const Sensitivity: IColumn<SensitivityRowType>[] = [
  {
    field: "name",
    isDefault: true,
    renderer: NameCell()
    
  },
  {
    field: "value",
    isDefault: true,
    width: 120,
    renderer: DecimalCell(),
  },
  {
    field: "exposure",
    isDefault: true,
    width: 120,
    renderer: DecimalCell(),
  },
  {
    field: "priceImpact",
    isDefault: true,
    width: 120,
    renderer: DecimalCell(),
  },
  {
    field: "fxImpact",
    isDefault: true,
    width: 120,
    renderer: DecimalCell(),
  },
  {
    field: "totalImpact",
    isDefault: true,
    width: 120,
    renderer: DecimalCell(),
  },
];

export const getTranslatedSensitivity = (t = (toTranslate:string) => toTranslate) => {
  return Sensitivity.map(column => {
    return {
      ...column,
      title: t(titleMapping.get(column.field) ?? '')
    }
  })
}
