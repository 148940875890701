import * as React from "react";
import s from "./SearchablePortfolioList.module.scss";
import { WorkgroupV3Dto } from "@iliotech/generated-api-v3";
import WorkgroupTable from "../WorkgroupTable/WorkgroupTable";
import { SearchBox } from "../../../../SearchBox/SearchBox";
import cn from "classnames";
import { PortfolioListStore, useAuth } from "@iliotech/data-wire";
import { observer } from "mobx-react-lite";
import { PortfolioGrid } from "../../../../../components/PortfolioList";
import { PortfolioGridWorkgroup } from "src/components/PortfolioList/PortfolioGridWorkgroup";

interface IProps {
  // workgroups: WorkgroupV3Dto[];
  // loading: boolean;
}

export const SearchablePortfolioList = observer(
  ({ }: IProps) => {
    const {
      illioSelectedAccount
    } = useAuth();
    const store = PortfolioListStore(illioSelectedAccount?.externalAccountId);

    const filteredPortfolios = store.filteredPortfolios();


    return (
      <div className={s.surround}>
        <SearchBox
          value={store.search}
          onChange={(e) => store.setSearch(e)}
          dataCy={"search-workgroup"}
          placeholder={"Search Portfolios"}
          className={cn(s.searchSurround)}
        />
        <h6 className={"element-title"}>Portfolio</h6>
        <p>Here are the portfolios matching your search</p>

        <PortfolioGridWorkgroup
          portfolios={filteredPortfolios.map(p => ({ ...p, portfolioName: p.name, integrations: [], })) as any}
          loading={store.state === "pending"}
          refetch={() => {
            store.refetch();
          }}
        />
      </div>
    );
  }
);



export default SearchablePortfolioList;
