import styled from '@emotion/styled';

export const StyledGridCard = styled.article`
    //background: linear-gradient(var(--tertiary), var(--secondary));
    background-color: var(--secondary);
    height: 100%;
`;

export const StyledGridCardHeader = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3rem;
    line-height: 3rem;
    border-bottom: 2px solid var(--tertiary);
`;

export const StyledGridCardTitle = styled.h3`
    align-self: center;
    margin-left: 2rem;
    text-align: left;
    font-size: 1.25rem;
    flex-grow: 1;
`;

export const StyledCollapsibleOptions = styled.div`
    height: 100%;
    background-color: var(--tertiary);
    display: ${(props) => (props.hidden ? 'none' : 'block')};
`;

export const StyledCardSection = styled.section`
    height: 100%;
`;

export const StyledGridCardButton = styled.button`
    color: white;
    font-weight: bold;
    background-color: transparent;
    width: 40px;
    border: none;

    &:hover {
        border: 1px solid var(--primary)
    }
`;
