import * as React from "react";
import InputWrapper from "../../InputWrapper/InputWrapper";
import {
  Input as KendoInput,
  InputProps as KendoInputProps,
} from "@progress/kendo-react-inputs";
import s from "./Input.module.scss";

export interface InputProps extends KendoInputProps {
  name: string;
  error?: string;
  label?: string;
  visited?: boolean;
}

export const Input = ({
  name,
  error,
  label,
  visited,
  ...restProps
}: InputProps) => {
  const isError = error && visited;
  return (
    <div className={"illio-input"}>
      <InputWrapper error={isError ? error : ""} label={label} name={name}>
        <KendoInput
          className={s.input}
          id={name}
          name={name}
          valid={!isError}
          {...restProps}
        />
      </InputWrapper>
    </div>
  );
};

export default Input;
