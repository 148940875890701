import {
  PortfolioAndResourceGroupsWorkgroupsApi,
  WorkgroupWithDataV3Dto,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../../../constants/constants";
import { PaginatableData, usePaginator } from "../../hooks/usePaginator";
import { AxiosResponse } from "axios";
interface IProps {
  externalAccountId: string;
  page?: number;
  size?: number;
  workgroupType?: "CLIENT" | "INTERNAL" | "GLOBAL" | "MODEL" | undefined;
  workgroupName?: string;
  displayCurrency?: string;
}

export const useWorkgroupsData = ({
  externalAccountId,
  displayCurrency,
  workgroupType,
  workgroupName,
  size,
}: IProps) => {
  const api = new PortfolioAndResourceGroupsWorkgroupsApi(
    undefined,
    `${API_BASE}/api`
  );

  const fetch = (page: number, limit: number) => {
    return api.getWorkgroupsData(
      externalAccountId,
      workgroupType,
      workgroupName,
      page,
      limit,
      displayCurrency as any
    );
  };

  return usePaginator<WorkgroupWithDataV3Dto>({
    queryKey: [`workgroups-data`, workgroupName!],
    fetch: fetch,
    size: size || 9,
  });
};
