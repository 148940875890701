/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { BenchmarkIndexDto } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { ReferenceDataResponse } from '../../aggregator';
/**
 * ReferenceDataApi - axios parameter creator
 * @export
 */
export const ReferenceDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * code is the internal code, label is for user display, parent code is the canonical parent code, hexColourCode is a unique hexcode you can use for display.You should find across the api\'s the same naming conventions
         * @summary Get static reference data, used in api request across the system. assetClasses, assetSubclasses, riskAssetClasses, currencies, countries, regions, sectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/referenceData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * These are used against a portfolio to compare the performance vs a benchmark
         * @summary Benchmark Index list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/referenceData/benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceDataApi - functional programming interface
 * @export
 */
export const ReferenceDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferenceDataApiAxiosParamCreator(configuration)
    return {
        /**
         * code is the internal code, label is for user display, parent code is the canonical parent code, hexColourCode is a unique hexcode you can use for display.You should find across the api\'s the same naming conventions
         * @summary Get static reference data, used in api request across the system. assetClasses, assetSubclasses, riskAssetClasses, currencies, countries, regions, sectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * These are used against a portfolio to compare the performance vs a benchmark
         * @summary Benchmark Index list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndexes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BenchmarkIndexDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndexes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferenceDataApi - factory interface
 * @export
 */
export const ReferenceDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferenceDataApiFp(configuration)
    return {
        /**
         * code is the internal code, label is for user display, parent code is the canonical parent code, hexColourCode is a unique hexcode you can use for display.You should find across the api\'s the same naming conventions
         * @summary Get static reference data, used in api request across the system. assetClasses, assetSubclasses, riskAssetClasses, currencies, countries, regions, sectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): AxiosPromise<ReferenceDataResponse> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
        /**
         * These are used against a portfolio to compare the performance vs a benchmark
         * @summary Benchmark Index list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexes(options?: any): AxiosPromise<Array<BenchmarkIndexDto>> {
            return localVarFp.getIndexes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferenceDataApi - object-oriented interface
 * @export
 * @class ReferenceDataApi
 * @extends {BaseAPI}
 */
export class ReferenceDataApi extends BaseAPI {
    /**
     * code is the internal code, label is for user display, parent code is the canonical parent code, hexColourCode is a unique hexcode you can use for display.You should find across the api\'s the same naming conventions
     * @summary Get static reference data, used in api request across the system. assetClasses, assetSubclasses, riskAssetClasses, currencies, countries, regions, sectors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceDataApi
     */
    public get(options?: AxiosRequestConfig) {
        return ReferenceDataApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * These are used against a portfolio to compare the performance vs a benchmark
     * @summary Benchmark Index list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceDataApi
     */
    public getIndexes(options?: AxiosRequestConfig) {
        return ReferenceDataApiFp(this.configuration).getIndexes(options).then((request) => request(this.axios, this.basePath));
    }
}
