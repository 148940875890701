import React from "react";
import cn from "classnames";
import s from "./ValueLegend.module.scss";

export type ClickableLegendItem = {
  label: string;
  value?: string | number;
  color?: string;
  onClick?(): void;
  rawValue?: number;
  forceToEnd?: boolean;
};

interface IOwnProps {
  items: ClickableLegendItem[];
  resetFilter?(): void;
  sortItems?(items: ClickableLegendItem[]): ClickableLegendItem[];
}

export const ValueLegend = ({
  items,
  resetFilter,
  sortItems = defaultSortItems,
}: IOwnProps) => {
  const sortedItems = sortItems(items);

  return (
    <div className={cn(s.surround)}>
      {sortedItems.map(({ label, value, color, onClick }, i) => (
        <figure
          onClick={onClick}
          key={`${i}-${label}`}
          data-cy="chart-legend-item"
          className={cn(s.itemSurround, onClick && s.clickable)}
        >
          {!!color && (
            <div
              className={cn(s.colorIndicator)}
              style={{ backgroundColor: color }}
            />
          )}
          <figcaption>
            <dl>
              <dt>{label}</dt>
              <dd
                data-cy={"chart-" + label?.replaceAll?.(" ", "-").toLowerCase()}
              >
                {value}
              </dd>
            </dl>
          </figcaption>
        </figure>
      ))}
      {!!resetFilter && <button onClick={resetFilter}>Reset</button>}
    </div>
  );
};

const defaultSortItems = (items: ClickableLegendItem[]) => {
  return items.sort(moveForcedItemsToEnd).sort(sortTotalsToStart);
};

const moveForcedItemsToEnd = (
  a: ClickableLegendItem,
  b: ClickableLegendItem
) => {
  return a.forceToEnd
    ? Infinity
    : b.forceToEnd
    ? -Infinity
    : a?.label?.localeCompare(b?.label);
};

const sortTotalsToStart = (a: ClickableLegendItem, b: ClickableLegendItem) =>
  a.label === "Total" ? -1 : b.label === "Total" ? 1 : 0;
