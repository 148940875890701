import { Field } from "@progress/kendo-react-form";
import type { KeyValue, FieldValidatorType } from "@progress/kendo-react-form";
import {
  currencyCodeValidator,
  enumDisplayValidator,
} from "@iliotech/data-wire";
import * as React from "react";
import { Autocomplete, AutocompleteProps } from "../Autocomplete/Autocomplete";
import { EnumDisplay } from "../../../../../generated-api-v3";

interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  items: EnumDisplay[];
  validator?: FieldValidatorType | FieldValidatorType[] | undefined;
}

export const EnumDisplaySearchField = ({
  errors,
  name = "sector",
  label = "Sectors",
  defaultValue,
  items,
  validator,
  ...rest
}: IOwnProps) => {
  const memoData = React.useMemo(() => items ?? [], [items]);
  const [open, setOpen] = React.useState(false);
  const component = React.useMemo(
    () => (props: AutocompleteProps) =>
      (
        <Autocomplete
          {...props}
          opened={open}
          onOpen={() => setOpen(true)}
          onClose={() => setTimeout(() => setOpen(false), 100)}
          onFocus={() => setOpen(true)}
        />
      ),
    [open, setOpen]
  );

  if (!items) return null;

  return (
    <Field
      component={component}
      label={label}
      name={name}
      error={errors?.[name]}
      // defaultValue={defaultValue}
      placeholder={"Enter currency"}
      validator={enumDisplayValidator}
      data={memoData}
      filterData={(filter?: string) => {
        return memoData?.filter((item) =>
          item.label.toLowerCase().startsWith(filter?.toLowerCase() ?? "")
        );
      }}
      {...rest}
    />
  );
};
