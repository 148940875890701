import { usePerformance } from "@iliotech/data-wire";
import { useParams } from "react-router";
import { ComparatorWrapper } from "./ComparatorWrapper";
import React from "react";
import { mapPerformanceV3 } from "../../PerformancePage";
import { IChartDataItemV2 } from "@iliotech/types";
import { ClickableLegendItem, MultiLineChart, ValueLegend } from "src/main";
import TimeSeriesLineChart from "@iliotech/widgets/src/components/TimeSeriesLineChart/TimeSeriesLineChart";
import { TimeSeriesChartItem } from "@iliotech/widgets";
import { PortfolioAndBenchmarkPerfDataDtoV3 } from "@iliotech/generated-api-v3";
import s from "./shared.module.scss";
interface IProps {
  period: {
    from: string;
    to: string;
  };
  virtualId: string;
  basePortfolioId: string;
}

export const PerformanceComparator = ({
  period,
  virtualId,
  basePortfolioId,
}: IProps) => {
  const { data: virtualPerformance, isLoading: vLoading } = usePerformance(
    virtualId!,
    period
  );
  const { data: basePerformance, isLoading: bLoading } = usePerformance(
    basePortfolioId!,
    period
  );
  const percentageValueFormatter = (v: number | undefined) =>
    v?.toFixed(2) + "%";

  const { data, min, itemsForLegend } = React.useMemo(() => {
    const mapPerformanceDataEntries = (
      data: PortfolioAndBenchmarkPerfDataDtoV3["portfolioData"]
    ) => {
      return data.map((item) => ({
        date: new Date(item.date),
        value: item.twr!,
      }));
    };

    const data: TimeSeriesChartItem[] = [
      {
        category: "Your Portfolio",
        color: "var(--illio-favourable-color)",
        entries: mapPerformanceDataEntries(
          basePerformance?.data?.portfolioData || []
        ),
      },
      {
        category: "What if Portfolio",
        color: "var(--illio-chart-secondary-line-color)",
        entries: mapPerformanceDataEntries(
          virtualPerformance?.data?.portfolioData || []
        ),
      },
      {
        category: basePerformance?.data?.benchmarkName || "",
        color: "var(--illio-orange)",
        entries: (basePerformance?.data?.benchmarkData || []).map((item) => ({
          date: new Date(item.date),
          value: item.perf,
        })),
      },
    ];

    const itemsForLegend = data.reduce<ClickableLegendItem[]>(
      (legendItems, chartItem) => {
        const totalValue = chartItem.entries.reduce(
          (sum, entry) => sum + entry.value,
          0
        );
        return [
          ...legendItems,
          {
            label: chartItem.category,
            color: chartItem.color,
          },
        ];
      },
      []
    );
    // get minimum value of all data entries
    const min = Math.min(
      ...data.map((item) => Math.min(...item.entries.map((e) => e.value)))
    );
    console.log({ min });
    return { data, min, itemsForLegend };
  }, [basePerformance, virtualPerformance]);

  return (
    <ComparatorWrapper
      loading={vLoading || bLoading}
      noData={!virtualPerformance?.data || !basePerformance?.data}
    >
      <h3>Performance</h3>
      <article className={s.card}>
        <div className={s.half}>
          <div className={s.titleWrap}>
            <b className={s.title}>Your Portfolio vs What-if Scenario</b>
          </div>
          <div style={{ padding: 20 }}>
            <TimeSeriesLineChart
              valueAxisFormatter={percentageValueFormatter}
              items={data}
              axisCrossingValue={min + min * 0.2}
              tooltipSettings={{
                hideTotal: true,
              }}
            />
            <ValueLegend items={itemsForLegend} />
          </div>
        </div>
      </article>
    </ComparatorWrapper>
  );
};
