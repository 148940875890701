import * as React from "react";
import s from "./BottomBar.module.scss";

interface IProps {
  children: React.ReactNode | React.ReactNode[];
  absolute?: boolean;
  height?: number;
}

export const BottomBar = ({ children, height = 275 }: IProps) => {
  return (
    <div className={s.bar} style={{ position: "fixed" }}>
      {children}
    </div>
  );
};

export default BottomBar;
