import { IllioTabBar } from "@iliotech/component-library/src/components/IllioTabBar/IllioTabBar";
import s from "./ListHeader.module.scss";
import React from "react";
import {
  PortfolioGroupViews,
  TabType,
} from "../../../../constants/tabSettings";
import { SearchAsync } from "@iliotech/component-library";
import { useNavigate, useParams } from "react-router";

interface IProps {
  onSearch: (search: string) => void;
  initialView?: TabType;
  showTab?: boolean;
}

export const ListHeader = ({
  onSearch,
  initialView = TabType.Summary,
  showTab = true,
}: IProps) => {
  const navigate = useNavigate();
  const onTabClick = (tab: string) => {
    if (tab === TabType.Summary) {
      navigate("/");
    } else if (tab === TabType.Examples) {
      navigate("/examples");
    } else {
      navigate("/portfolios");
    }
  };

  const selectedView = location.pathname.includes("examples")
    ? TabType.Examples
    : location.pathname.includes("portfolios")
    ? TabType.Portfolios
    : TabType.Summary;

  return (
    <div className={s.row}>
      {showTab ? (
        <IllioTabBar
          selectedView={selectedView}
          onTabClick={onTabClick}
          views={PortfolioGroupViews}
          initialView={initialView}
        />
      ) : (
        <div />
      )}
      <SearchAsync
        className={s.search}
        isAsync={false}
        searchFunction={onSearch}
      />
    </div>
  );
};
