import { AccountManagementApi } from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";

export const useUsers = (
  externalAccountId: string,
  role?: "OWNER" | "MANAGER" | "CUSTOMER" | "RO_CUSTOMER",
  includeAssigned?: boolean,
  enabled: boolean = true
) => {
  const api = new AccountManagementApi(undefined, `${API_BASE}/api`);

  return useQuery(
    [`list-users`, { externalAccountId, role, includeAssigned }],
    () => api.listUsers(externalAccountId!, role, includeAssigned),
    { enabled: !!externalAccountId && enabled }
  );
};
