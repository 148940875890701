import * as React from "react";
import SimpleTable from "../../SimpleTable/SimpleTable";
import {
  REQUEST_DATE_FORMAT,
  useFetchFxRateLite,
  useGetOrCreateRoutine,
} from "@iliotech/data-wire";
import { observer } from "mobx-react-lite";
import { useAuth, useAutoResolveInstrument } from "@iliotech/data-wire";
import { PortfolioDTOWithDetailsV3 } from "@iliotech/generated-api-v3";
import { columns } from "./PositionUploadColumns";

import { TransactionUploadEmpty } from "../TransactionUploadTable/TransactionUploadEmpty";
import { FadeLoading } from "../../FadeLoading/FadeLoading";
import { usePositionUploadV2 } from "@iliotech/data-wire/src/stores/PositionsUpload";

interface IProps {
  tableData: any[];
  portfolioInfo?: PortfolioDTOWithDetailsV3;
  workgroup?: any;
  externalAccountId?: any;
}

// find out if row has been deleted, without making the whole table an observer
const RowRenderer = observer(({ row }: any) => {
  const store = usePositionUploadV2();
  const dataItem = store.position.tableData[row.props["data-grid-row-index"]];

  useGetOrCreateRoutine({
    dataItem,
    // important the arrow syntax to modify the store, otherwise the store will not be modified
    updateTableRow: (dataItem) => store.position.updateTableRow(dataItem),
  });

  if (dataItem?.ticker) {
    return row;
  }
  return <></>;
});

export const PositionUploadTable = observer(
  ({ tableData, portfolioInfo }: IProps) => {
    const finalColumns = columns(portfolioInfo?.currencyCode!);
    const { illioSelectedAccount } = useAuth();
    const store = usePositionUploadV2();
    const loading = store.position.loading;

    return (
      <div>
        <FadeLoading loading={loading}>
          <SimpleTable
            emptyGridRenderer={() => <TransactionUploadEmpty />}
            rowRender={(row, props) => <RowRenderer row={row} />}
            columns={finalColumns}
            data={tableData}
          />
        </FadeLoading>
      </div>
    );
  }
);

export default PositionUploadTable;
