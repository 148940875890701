import React from "react";
import { FormModal } from "../../FormModal/FormModal";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../index";
import {
  useAccountUpgrades,
  useSelectedAccountDetails,
} from "@iliotech/data-wire";

interface IOwnProps {}

export const PortfolioUsageExceeded = (props: IOwnProps) => {
  const accountDetails = useSelectedAccountDetails();

  const upgrades = useAccountUpgrades();

  const usage = accountDetails.data?.data?.usage;

  const closeUsageModal = () => {
    navigate("/");
  };

  const buyPortfolio = () => {};

  const navigate = useNavigate();

  return (
    <FormModal
      title={`Maximum portfolios reached`}
      visible
      handleClose={closeUsageModal}
      // footer={
      //   <FormModal.Footer style={{ justifyContent: "flex-end", gap: "1rem" }}>
      //     <Button onClick={buyPortfolio}>Buy additional portfolio</Button>
      //   </FormModal.Footer>
      // }
    >
      <p>
        You have reached your portfolio limit on your account. (
        {usage?.activePortfolios}/{usage?.maxPortfolios} used)
      </p>
      {/*<p>*/}
      {/*  You can add another portfolio to your account by following the link*/}
      {/*  below.*/}
      {/*</p>*/}
      <p>
        If you have any questions about this please feel free to get in touch
        at: <a href={"mailto:support@illio.com"}>support@illio.com</a>
      </p>
      {/*<pre style={{ fontSize: 12 }}>*/}
      {/*  {JSON.stringify(upgrades.data?.data, null, 2)}*/}
      {/*</pre>*/}
    </FormModal>
  );
};
