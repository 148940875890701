import moment, { MomentInput } from "moment";
import React from "react";
import cn from "classnames";
import s from "./Event.module.scss";
import { IEvent } from "@iliotech/data-wire/src/reactHooks/hooks/useEventsBreakdown";

export const Event = React.memo(({ title, date, description }: IEvent) => {
  return (
    <dl className={cn(s.eventSurround)}>
      <div className={cn(s.dateSurround)}>
        <div className={cn(s.date)}>{moment(date).format("DD")}</div>
        <div className={cn(s.month)}>{moment(date).format("MMM")}</div>
      </div>
      <div className={cn(s.eventContent)}>
        <dt>{title}</dt>
        <dd>{description}</dd>
      </div>
    </dl>
  );
});
