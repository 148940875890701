import {
  useAuth,
  usePortfoliosData,
  usePortfoliosSummary,
  useWorkgroupData,
  useWorkgroupSummary,
} from "@iliotech/data-wire";
import React from "react";

import {
  BasicHeader,
  Blur,
  ModalBackButton,
  PortfolioSettingsModal,
  useRemoveModal,
} from "@iliotech/component-library";
import { PageSummary } from "./components/PageSummary/PageSummary";
import { ListHeader } from "./components/ListHeader/ListHeader";
import { PortfolioDataV3Dto } from "@iliotech/generated-api-v3";
import { PaginatorReturnType } from "@iliotech/data-wire/src/reactHooks/hooks/usePaginator";
import { PageNavigator } from "./components/PageNavigator/PageNavigator";
import { useNavigate, useParams } from "react-router";
import { PortfolioPageList } from "./components/PortfolioPageList/PortfolioPageList";
import { TabType } from "../../constants/tabSettings";
import { deletePortfolio } from "@iliotech/data-wire/src/requests/deletePortfolio";

interface IProps {
  sample?: boolean;
}
export const LandingPortfoliosPage = ({ sample }: IProps) => {
  const {
    logOut,
    illioSelectedAccount,
    availableAccounts,
    selectIllioAccount,
  } = useAuth();
  const { workgroupName, workgroupType } = useParams();
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();
  const valueFormatter = (v: number) =>
    "USD " +
    (v?.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }) || 0);
  const hasMoreAccounts = availableAccounts?.length! > 1;
  const onSelectAccounts = () => {
    selectIllioAccount(null);
  };
  const [settingsOpen, setSettingsId] = React.useState<string | null>(null);
  const { openModal, closeModal } = useRemoveModal();

  const workgroupSummary = useWorkgroupSummary({
    externalAccountId: illioSelectedAccount?.externalAccountId!,
    name: workgroupName!,
  });

  const portfolioSummary: any = usePortfoliosSummary(
    illioSelectedAccount?.externalAccountId!
  );

  const headerData: {
    portfolios: number;
    totalMarketValue: number;
    workgroups: number;
    loading?: boolean;
  } = workgroupName
    ? {
        portfolios: workgroupSummary?.data?.data?.nrPortfolios!,
        totalMarketValue: workgroupSummary?.data?.data?.totalWealth!,
        workgroups: 1,
        loading: workgroupSummary?.isFetching,
      }
    : {
        ...portfolioSummary?.data?.data,
        loading: portfolioSummary?.isFetching,
      };

  const portfoliosData: PaginatorReturnType<PortfolioDataV3Dto> =
    usePortfoliosData({
      size: 10,
      externalAccountId: illioSelectedAccount?.externalAccountId!,
      enabled: !workgroupName,
      portfolioName: search,
      workgroupType: sample ? "GLOBAL" : undefined,
    });

  const workgroupData: PaginatorReturnType<PortfolioDataV3Dto> =
    useWorkgroupData({
      size: 10,
      externalAccountId: illioSelectedAccount?.externalAccountId!,
      name: workgroupName!,
      portfolioName: search,
    });

  const onDeletePortfolio = async (id: string) => {
    try {
      await deletePortfolio(illioSelectedAccount?.externalAccountId!, id);
      closeModal();
      if (workgroupName) {
        workgroupData.refetch();
      } else {
        portfoliosData.refetch();
      }
    } catch (e) {
      console.log(e);
      closeModal();
    }
  };

  const listData = workgroupName ? workgroupData : portfoliosData;
  const onBack = () => {
    navigate("/");
  };

  return (
    <Blur>
      <PortfolioSettingsModal
        settingsOpen={!!settingsOpen}
        closeSettings={() => setSettingsId(null)}
        passedPortfolioId={settingsOpen!}
      />
      <div className="container">
        {!!workgroupName && (
          <ModalBackButton
            style={{ marginTop: -30 }}
            onPress={onBack}
            text={illioSelectedAccount?.name}
          />
        )}

        <BasicHeader
          marginTop={-10}
          logOut={logOut}
          hideLogo={!!workgroupName || hasMoreAccounts}
          onSelectAccount={
            hasMoreAccounts && !workgroupName ? onSelectAccounts : undefined
          }
          title={workgroupName || illioSelectedAccount?.name!}
          showHelp={true}
          showThemeSwitcher
        />
        <PageNavigator />
        <PageSummary valueFormatter={valueFormatter} data={{ ...headerData }} />
        <ListHeader
          showTab={!workgroupName}
          initialView={TabType.Portfolios}
          onSearch={(v: string) => setSearch(v)}
        />
        <PortfolioPageList
          openSettings={setSettingsId}
          onDeletePortfolio={(id: string) =>
            openModal(() => onDeletePortfolio(id))
          }
          valueFormatter={valueFormatter}
          {...listData}
        />
      </div>
    </Blur>
  );
};
