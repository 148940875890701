import { createContext, useContext } from "react";
import { TransactionsTableStore } from "./TransactionsTableStore";
import { TransactionsUploadStateStore } from "./TransactionsUploadStateStore";

export const transactionUploadStore = {
  equities: TransactionsTableStore(),
  options: TransactionsTableStore(),
  bonds: TransactionsTableStore(),
  upload: TransactionsUploadStateStore(),
};

export const TransactionUploadStoreContext = createContext(
  transactionUploadStore
);
export const TransactionUploadStoreContextProvider =
  TransactionUploadStoreContext.Provider;

export const useTransactionUploadV2 = () => {
  return useContext(
    TransactionUploadStoreContext
  ) as typeof transactionUploadStore;
};

export default transactionUploadStore;

export * from "./TransactionsTableStore";
