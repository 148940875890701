import React from "react";

interface DrawdownChartLegendProps {
  t: (toTranslate: string) => string;
}

export const DrawdownChartLegend = ({ t }: DrawdownChartLegendProps) => {
  return (
    <div
      style={{
        display: "flex",
        gridGap: 20,
        marginTop: -15,
        marginBottom: 15,
      }}
    >
      <div>
        <span>
          <svg width="20" height="15">
            <circle fill="var(--portfolio-color)" r={6} cx={6} cy={6} />
          </svg>
        </span>
        <span>{t("Portfolio")}</span>
      </div>
      <div>
        <span>
          <svg width="20" height="15">
            <circle
              fill="transparent"
              stroke="var(--illio-chart-watermark-color)"
              strokeDasharray="2,2"
              r={6}
              cx={6}
              cy={6}
            />
          </svg>
        </span>
        <span>{t("Watermark")}</span>
      </div>
      <div>
        <span>
          <svg width="20" height="15">
            <circle
              fill="var(--illio-chart-drawdown-color)"
              r={6}
              cx={6}
              cy={6}
            />
          </svg>
        </span>
        <span>{t("Drawdown")}</span>
      </div>
    </div>
  );
};
