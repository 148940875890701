import {
  PortfolioAndResourceGroupsWorkgroupsApi,
  PortfolioDataV3Dto,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../../../constants/constants";
import { PaginatableData, usePaginator } from "../../hooks/usePaginator";
import { AxiosResponse } from "axios";
interface IProps {
  externalAccountId: string;
  page?: number;
  size?: number;
  workgroupType?: "CLIENT" | "INTERNAL" | "GLOBAL" | "MODEL" | undefined;
  portfolioName?: string;
  displayCurrency?: string;
  enabled?: boolean;
}

export const usePortfoliosData = ({
  externalAccountId,
  displayCurrency,
  workgroupType,
  size,
  enabled,
  portfolioName,
}: IProps) => {
  const api = new PortfolioAndResourceGroupsWorkgroupsApi(
    undefined,
    `${API_BASE}/api`
  );

  const fetch = (page: number, limit: number) => {
    return api.listPortfoliosWithData(
      externalAccountId,
      workgroupType,
      displayCurrency as any,
      portfolioName,
      page,
      limit
    );
  };

  return usePaginator<PortfolioDataV3Dto>(
    {
      queryKey: [
        `portfolios-data`,
        externalAccountId,
        portfolioName!,
        workgroupType!,
      ],
      fetch: fetch,
      size: size || 9,
    },
    {
      enabled,
    }
  );
};
