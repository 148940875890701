import {
  AccountBrokerManagementApi,
  CreateIntegrationRequest,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../exportsMain";

export const createBrokerIntegration = (
  externalAccountId: string,
  data: CreateIntegrationRequest
) => {
  const api = new AccountBrokerManagementApi(undefined, `${API_BASE}/api`);
  return api.initiateIntegration(data, externalAccountId);
};
