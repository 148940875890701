import React, { FC, useState } from "react";
import { IDecimalCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  PortfolioSnapshotDtoV3,
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum,
} from "@iliotech/generated-api-v3";
import { DropDownMenu } from "../../DropDownMenu/DropDownMenu";
import { GoKebabHorizontal } from "react-icons/go";
import { generatePath, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import cn from "classnames";
import s from "./ActionCell.module.scss";

const investmentsVehicleToInclude = [
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.Shares,
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.PrefShares,
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.Etf,
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.Fund,
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.CryptoCurrencies,
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.Bonds,
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.RealAssets,
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.Physical,
];

export const ActionCell = (): FC<IDecimalCellProps<PortfolioSnapshotDtoV3>> => {
  return React.memo(({ width, dataItem, ...props }) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const instrumentId = dataItem.instrumentId;
    const navigate = useNavigate();
    const { portfolioId = "" } = useParams<{ portfolioId: string }>();
    const renderContent = () => {
      if (
        !dataItem.investmentVehicleCode ||
        !investmentsVehicleToInclude.includes(dataItem.investmentVehicleCode)
      ) {
        return null;
      }

      const data =
        dataItem.investmentVehicleCode ===
        PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.CryptoCurrencies
          ? [
              {
                label: "Add Earn",
                key: "add-earn" + instrumentId,
                value: "ADD_EARN",
              },
            ]
          : dataItem.investmentVehicleCode ===
            PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.Bonds
          ? [
              {
                label: "Add Income",
                key: "add-coupon" + instrumentId,
                value: "ADD_COUPON",
              },
            ]
          : dataItem.investmentVehicleCode ===
              PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.RealAssets ||
            dataItem.investmentVehicleCode ===
              PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.Physical
          ? [
              {
                label: "Add Income",
                key: "add-income" + instrumentId,
                value: "ADD_INCOME",
              },
            ]
          : [
              {
                label: "Add Dividends",
                key: "add-dividend" + instrumentId,
                value: "ADD_DIVIDENDS",
              },
              {
                label: "Add Splits",
                key: "add-split" + instrumentId,
                value: "ADD_SPLITS",
              },
            ];

      const onSelect = (e: any) => {
        // console.log(e.value.value);
        // console.log(dataItem);

        switch (e.value.value) {
          case "ADD_EARN":
            navigate(
              generatePath(
                "/portfolio/:portfolioId/positions/:positionId/add-earn",
                {
                  portfolioId: portfolioId ?? "",
                  positionId: dataItem.positionId ?? "",
                }
              )
            );
            return;
          case "ADD_COUPON":
            navigate(
              generatePath(
                "/portfolio/:portfolioId/positions/:positionId/add-coupon",
                {
                  portfolioId: portfolioId ?? "",
                  positionId: dataItem.positionId ?? "",
                }
              )
            );
            return;
          case "ADD_INCOME":
            navigate(
              generatePath(
                "/portfolio/:portfolioId/positions/:positionId/add-income",
                {
                  portfolioId: portfolioId ?? "",
                  positionId: dataItem.underlying ?? "",
                }
              )
            );

            return;
          case "ADD_DIVIDENDS":
            navigate(
              generatePath(
                "/portfolio/:portfolioId/positions/:positionId/add-dividend",
                {
                  portfolioId: portfolioId ?? "",
                  positionId: dataItem.positionId ?? "",
                }
              )
            );

            return;
          case "ADD_SPLITS":
            navigate(
              generatePath(
                "/portfolio/:portfolioId/positions/:positionId/add-split",
                {
                  portfolioId: portfolioId ?? "",
                  positionId: dataItem.positionId ?? "",
                }
              )
            );

            return;
        }
      };

      return (
        <DropDownMenu
          data={data}
          textField={"label"}
          onSelect={onSelect}
          dataItemKey={"key"}
          popupProps={{
            anchorAlign: {
              horizontal: "left",
              vertical: "center",
            },
            popupAlign: {
              horizontal: "right",
              vertical: "center",
            },
          }}
          button={
            <div className={cn(s.buttonSurround)}>
              <GoKebabHorizontal color={"var(--muted-color)"} />
            </div>
          }
        />
      );
    };

    return (
      <td
        style={{
          zIndex: 999,
          // TODO: replace colors with appropriate css-vars
          color: undefined,
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div style={{ width }} className={cn(s.content)}>
          {renderContent()}
        </div>
      </td>
    );
  });
};
