/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CryptoWalletQuantityAllocation } from './crypto-wallet-quantity-allocation';

/**
 * 
 * @export
 * @interface PortfolioSnapshotDtoV3
 */
export interface PortfolioSnapshotDtoV3 {
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'instrumentId': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'assetClassCode': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'assetClassName': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'assetSubClassCode': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'assetSubClassName': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'riskAssetClassCode'?: PortfolioSnapshotDtoV3RiskAssetClassCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'riskAssetClassName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'investmentVehicleCode'?: PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'investmentVehicleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'custodianName': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'strategy': string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'quantity': number;
    /**
     * 
     * @type {CryptoWalletQuantityAllocation}
     * @memberof PortfolioSnapshotDtoV3
     */
    'quantityAllocation'?: CryptoWalletQuantityAllocation;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'costPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'localPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'marketValueBase': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'marketValueLocal': number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'currencyLocalName': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'pricingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'fxDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'pnlBase': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'pnlLocal': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'pnlFxBase': number;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioSnapshotDtoV3
     */
    'calculated': boolean;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'priceGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'priceChange'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'priceMovePercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'positionId': string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'totalCostLocal': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'totalCostBase': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'percentOfPortfolio': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'percentOfGrossPortfolio': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'notionalBase': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'exposureBase': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'incomeHistoricBase': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'incomeLocal': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'realisedBase': number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'unrealisedBase': number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'displayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'sectorName': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'sectorCode': PortfolioSnapshotDtoV3SectorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'underlying': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'underlyingId': string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'accruedBase'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'accruedLocal'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'dirtyPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'regionCode'?: PortfolioSnapshotDtoV3RegionCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'countryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'countryCode'?: PortfolioSnapshotDtoV3CountryCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'instrumentAssetSubClassName': string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioSnapshotDtoV3
     */
    'instrumentAssetSubClassCode': PortfolioSnapshotDtoV3InstrumentAssetSubClassCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof PortfolioSnapshotDtoV3
     */
    'liquidityDays': number;
}

/**
    * @export
    * @enum {string}
    */
export enum PortfolioSnapshotDtoV3RiskAssetClassCodeEnum {
    CashAndEquivalents = 'CashAndEquivalents',
    Equities = 'Equities',
    FixedIncome = 'FixedIncome',
    RealEstate = 'RealEstate',
    Alternatives = 'Alternatives',
    Commodities = 'Commodities',
    RealAssets = 'RealAssets',
    CryptoCurrencies = 'CryptoCurrencies',
    Futures = 'Futures',
    Adjustments = 'Adjustments',
    MixedAllocation = 'MixedAllocation',
    Fx = 'Fx'
}
/**
    * @export
    * @enum {string}
    */
export enum PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum {
    Etf = 'ETF',
    Fund = 'Fund',
    Physical = 'Physical',
    Option = 'Option',
    Warrant = 'Warrant',
    Loan = 'Loan',
    Bonds = 'Bonds',
    MoneyMarket = 'MoneyMarket',
    Mortgage = 'Mortgage',
    Shares = 'Shares',
    PrefShares = 'PrefShares',
    HedgeFunds = 'HedgeFunds',
    PrivateEquity = 'PrivateEquity',
    VentureCapital = 'VentureCapital',
    SpecialityFunds = 'SpecialityFunds',
    FundOfFunds = 'FundOfFunds',
    CryptoCurrencies = 'CryptoCurrencies',
    Adjustment = 'Adjustment',
    Fees = 'Fees',
    Fx = 'FX',
    Subscription = 'Subscription',
    Cash = 'Cash',
    Future = 'Future',
    RealAssets = 'RealAssets',
    TermDeposit = 'TermDeposit',
    Fcn = 'Fcn',
    StructuredProduct = 'StructuredProduct',
    Cfd = 'CFD',
    FxFwd = 'FXFwd',
    Tarf = 'Tarf'
}
/**
    * @export
    * @enum {string}
    */
export enum PortfolioSnapshotDtoV3SectorCodeEnum {
    LiquidAlternatives = 'LiquidAlternatives',
    EmergingMktBonds = 'EmergingMktBonds',
    DevelopedMktBonds = 'DevelopedMktBonds',
    HighYieldBonds = 'HighYieldBonds',
    GovernmentBonds = 'GovernmentBonds',
    Convertiblebonds = 'Convertiblebonds',
    GlobalBonds = 'GlobalBonds',
    MoneyMarket = 'MoneyMarket',
    Commodities = 'Commodities',
    CommPreciousMetals = 'CommPreciousMetals',
    EquityGeographicFocus = 'EquityGeographicFocus',
    MarketCapEquityFocus = 'MarketCapEquityFocus',
    HealthCare = 'HealthCare',
    CommunicationServices = 'CommunicationServices',
    ConsumerDiscretionary = 'ConsumerDiscretionary',
    ConsumerStaples = 'ConsumerStaples',
    Energy = 'Energy',
    Financials = 'Financials',
    Materials = 'Materials',
    Industrials = 'Industrials',
    InformationTechnology = 'InformationTechnology',
    RealEstate = 'RealEstate',
    Utilities = 'Utilities',
    EquityThemeFocus = 'EquityThemeFocus',
    Other = 'Other',
    HedgeFundStrategies = 'HedgeFundStrategies',
    MixedAsset = 'MixedAsset',
    Diverse = 'Diverse',
    Cryptocurrencies = 'Cryptocurrencies',
    RealAssets = 'RealAssets',
    CashAndEquivalents = 'CashAndEquivalents',
    PrivateEquity = 'PrivateEquity',
    VentureCapital = 'VentureCapital'
}
/**
    * @export
    * @enum {string}
    */
export enum PortfolioSnapshotDtoV3RegionCodeEnum {
    MiddleEast = 'MIDDLE_EAST',
    Europe = 'EUROPE',
    EasternEurope = 'EASTERN_EUROPE',
    Africa = 'AFRICA',
    Pacific = 'PACIFIC',
    Latam = 'LATAM',
    CentralAsia = 'CENTRAL_ASIA',
    Australasia = 'AUSTRALASIA',
    SouthAsia = 'SOUTH_ASIA',
    NorthAmerica = 'NORTH_AMERICA',
    GreaterChina = 'GREATER_CHINA',
    Scandinavia = 'SCANDINAVIA',
    UnitedKingdom = 'UNITED_KINGDOM',
    Japan = 'JAPAN',
    WestAsia = 'WEST_ASIA',
    NorthAsia = 'NORTH_ASIA',
    Global = 'GLOBAL',
    Asia = 'ASIA',
    AsiaPacific = 'ASIA_PACIFIC',
    AsiaExJapan = 'ASIA_EX_JAPAN',
    EmergingMarkets = 'EMERGING_MARKETS',
    FrontierMarkets = 'FRONTIER_MARKETS',
    DevelopedMarkets = 'DEVELOPED_MARKETS'
}
/**
    * @export
    * @enum {string}
    */
export enum PortfolioSnapshotDtoV3CountryCodeEnum {
    Af = 'AF',
    Ax = 'AX',
    Al = 'AL',
    Dz = 'DZ',
    As = 'AS',
    Ad = 'AD',
    Ao = 'AO',
    Ai = 'AI',
    Ag = 'AG',
    Ar = 'AR',
    Am = 'AM',
    Aw = 'AW',
    Au = 'AU',
    Xt = 'XT',
    Xe = 'XE',
    Xy = 'XY',
    At = 'AT',
    Az = 'AZ',
    Bs = 'BS',
    Bh = 'BH',
    Bd = 'BD',
    Bb = 'BB',
    By = 'BY',
    Be = 'BE',
    Bz = 'BZ',
    Bj = 'BJ',
    Bm = 'BM',
    Bt = 'BT',
    Bo = 'BO',
    Bq = 'BQ',
    Ba = 'BA',
    Bw = 'BW',
    Bv = 'BV',
    Br = 'BR',
    Io = 'IO',
    Bn = 'BN',
    Bg = 'BG',
    Bf = 'BF',
    Bi = 'BI',
    Cv = 'CV',
    Kh = 'KH',
    Cm = 'CM',
    Ca = 'CA',
    Ky = 'KY',
    Cf = 'CF',
    Td = 'TD',
    Cl = 'CL',
    Cn = 'CN',
    Cx = 'CX',
    Cc = 'CC',
    Co = 'CO',
    Km = 'KM',
    Cg = 'CG',
    Cd = 'CD',
    Ck = 'CK',
    Cr = 'CR',
    Ci = 'CI',
    Hr = 'HR',
    Cu = 'CU',
    Cw = 'CW',
    Cy = 'CY',
    Cz = 'CZ',
    Dk = 'DK',
    Xs = 'XS',
    Dj = 'DJ',
    Dm = 'DM',
    Do = 'DO',
    Ec = 'EC',
    Eg = 'EG',
    Eu = 'EU',
    Sv = 'SV',
    Xp = 'XP',
    Gq = 'GQ',
    Er = 'ER',
    Ee = 'EE',
    Sz = 'SZ',
    Et = 'ET',
    Fk = 'FK',
    Fo = 'FO',
    Fj = 'FJ',
    Fi = 'FI',
    Fr = 'FR',
    Gf = 'GF',
    Pf = 'PF',
    Tf = 'TF',
    Xr = 'XR',
    Ga = 'GA',
    Gm = 'GM',
    Ge = 'GE',
    De = 'DE',
    Gh = 'GH',
    Gi = 'GI',
    Gr = 'GR',
    Gl = 'GL',
    Gd = 'GD',
    Gp = 'GP',
    Gu = 'GU',
    Gt = 'GT',
    Gg = 'GG',
    Gn = 'GN',
    Gw = 'GW',
    Gy = 'GY',
    Ht = 'HT',
    Hm = 'HM',
    Va = 'VA',
    Hn = 'HN',
    Hk = 'HK',
    Hu = 'HU',
    Is = 'IS',
    In = 'IN',
    Id = 'ID',
    Ir = 'IR',
    Iq = 'IQ',
    Ie = 'IE',
    Im = 'IM',
    Il = 'IL',
    It = 'IT',
    Jm = 'JM',
    Jp = 'JP',
    Xh = 'XH',
    Je = 'JE',
    Jo = 'JO',
    Kz = 'KZ',
    Ke = 'KE',
    Ki = 'KI',
    Kp = 'KP',
    Kr = 'KR',
    Kw = 'KW',
    Kg = 'KG',
    La = 'LA',
    Lv = 'LV',
    Lb = 'LB',
    Ls = 'LS',
    Lr = 'LR',
    Ly = 'LY',
    Li = 'LI',
    Lt = 'LT',
    Lu = 'LU',
    Mo = 'MO',
    Mg = 'MG',
    Mw = 'MW',
    My = 'MY',
    Mv = 'MV',
    Ml = 'ML',
    Mt = 'MT',
    Mh = 'MH',
    Mq = 'MQ',
    Mr = 'MR',
    Mu = 'MU',
    Yt = 'YT',
    Mx = 'MX',
    Fm = 'FM',
    Md = 'MD',
    Mc = 'MC',
    Mn = 'MN',
    Me = 'ME',
    Ms = 'MS',
    Ma = 'MA',
    Mz = 'MZ',
    Mm = 'MM',
    Na = 'NA',
    Nr = 'NR',
    Np = 'NP',
    Nl = 'NL',
    Nc = 'NC',
    Nz = 'NZ',
    Ni = 'NI',
    Ne = 'NE',
    Ng = 'NG',
    Nu = 'NU',
    Nf = 'NF',
    Mk = 'MK',
    Mp = 'MP',
    No = 'NO',
    Om = 'OM',
    Pk = 'PK',
    Pw = 'PW',
    Ps = 'PS',
    Pa = 'PA',
    Pg = 'PG',
    Py = 'PY',
    Pe = 'PE',
    Ph = 'PH',
    Pn = 'PN',
    Pl = 'PL',
    Pt = 'PT',
    Pr = 'PR',
    Qa = 'QA',
    Re = 'RE',
    Ro = 'RO',
    Ru = 'RU',
    Rw = 'RW',
    Bl = 'BL',
    Sh = 'SH',
    Kn = 'KN',
    Lc = 'LC',
    Mf = 'MF',
    Pm = 'PM',
    Vc = 'VC',
    Ws = 'WS',
    Sm = 'SM',
    St = 'ST',
    Sa = 'SA',
    Sn = 'SN',
    Rs = 'RS',
    Sc = 'SC',
    Sl = 'SL',
    Sg = 'SG',
    Sx = 'SX',
    Sk = 'SK',
    Si = 'SI',
    Sb = 'SB',
    So = 'SO',
    Za = 'ZA',
    Gs = 'GS',
    Ss = 'SS',
    Es = 'ES',
    Lk = 'LK',
    Sd = 'SD',
    Sr = 'SR',
    Sj = 'SJ',
    Se = 'SE',
    Ch = 'CH',
    Sy = 'SY',
    Tw = 'TW',
    Tj = 'TJ',
    Tz = 'TZ',
    Th = 'TH',
    Tl = 'TL',
    Tg = 'TG',
    Tk = 'TK',
    To = 'TO',
    Tt = 'TT',
    Tn = 'TN',
    Tr = 'TR',
    Tm = 'TM',
    Tc = 'TC',
    Tv = 'TV',
    Ug = 'UG',
    Ua = 'UA',
    Ae = 'AE',
    Gb = 'GB',
    Uk = 'UK',
    Us = 'US',
    Um = 'UM',
    Uy = 'UY',
    Uz = 'UZ',
    Vu = 'VU',
    Ve = 'VE',
    Vn = 'VN',
    Vg = 'VG',
    Vi = 'VI',
    Wf = 'WF',
    Eh = 'EH',
    Ye = 'YE',
    Zm = 'ZM',
    Zw = 'ZW',
    Xf = 'XF',
    Xx = 'XX',
    Xa = 'XA',
    Xb = 'XB',
    Xc = 'XC',
    Xd = 'XD',
    Xg = 'XG',
    Xi = 'XI',
    Xj = 'XJ',
    Xk = 'XK',
    Xl = 'XL',
    Xm = 'XM',
    Xn = 'XN',
    Xo = 'XO',
    Xq = 'XQ'
}
/**
    * @export
    * @enum {string}
    */
export enum PortfolioSnapshotDtoV3InstrumentAssetSubClassCodeEnum {
    SubCash = 'SubCash',
    CshCash = 'CshCash',
    CshTermDeposit = 'CshTermDeposit',
    CshMoneyMarket = 'CshMoneyMarket',
    CshCommercialPaper = 'CshCommercialPaper',
    CshCashFunds = 'CshCashFunds',
    AdjFees = 'AdjFees',
    AdjFxSpot = 'AdjFXSpot',
    AdjIncome = 'AdjIncome',
    AdjPnL = 'AdjPnL',
    AdjInterest = 'AdjInterest',
    EqShares = 'EqShares',
    EqEtfs = 'EqETFs',
    EqWarrants = 'EqWarrants',
    EqOptions = 'EqOptions',
    EqIndex = 'EqIndex',
    EqPreferenceShares = 'EqPreferenceShares',
    EqUnlisted = 'EqUnlisted',
    EqCfd = 'EqCfd',
    EqStruct = 'EqStruct',
    EqFutures = 'EqFutures',
    FutOptions = 'FutOptions',
    FutIndex = 'FutIndex',
    FiBonds = 'FiBonds',
    FiEtf = 'FiETF',
    FiLoans = 'FiLoans',
    FiStructuredProducts = 'FiStructuredProducts',
    FiOptions = 'FiOptions',
    FiCds = 'FiCDS',
    FiDistressedDebt = 'FiDistressedDebt',
    FiIndex = 'FiIndex',
    FiConvertibles = 'FiConvertibles',
    FiCoCosAt1sEtc = 'FiCoCosAT1sEtc',
    FiMortgage = 'FiMortgage',
    FiMoneyMarket = 'FiMoneyMarket',
    FiTermDep = 'FiTermDep',
    FiFcn = 'FiFcn',
    FiFutures = 'FiFutures',
    RePhysical = 'RePhysical',
    ReReit = 'ReREIT',
    ReEquity = 'ReEquity',
    ReLoan = 'ReLoan',
    ReIndex = 'ReIndex',
    ReEtf = 'ReETF',
    AltDirectInvestments = 'AltDirectInvestments',
    AltIndex = 'AltIndex',
    ComPhysical = 'ComPhysical',
    ComNonPhysical = 'ComNonPhysical',
    ComIndices = 'ComIndices',
    ComEtfs = 'ComETFs',
    ComOptions = 'ComOptions',
    ComCfd = 'ComCfd',
    ComStruct = 'ComStruct',
    ComFutures = 'ComFutures',
    RaArt = 'RaArt',
    RaWineAndSpirits = 'RaWineAndSpirits',
    RaMotorVehicle = 'RaMotorVehicle',
    RaYacht = 'RaYacht',
    RaJewellery = 'RaJewellery',
    RaAircraft = 'RaAircraft',
    RaOther = 'RaOther',
    RaDigital = 'RaDigital',
    RaLivestock = 'RaLivestock',
    RaHorses = 'RaHorses',
    CryptoCurrencies = 'CryptoCurrencies',
    CryptoIndex = 'CryptoIndex',
    CryptoTokens = 'CryptoTokens',
    CryptoStablecoins = 'CryptoStablecoins',
    CryptoOptions = 'CryptoOptions',
    CryptoStruct = 'CryptoStruct',
    CryptoFutures = 'CryptoFutures',
    FunEquity = 'FunEquity',
    FunEtf = 'FunETF',
    FunFixedIncome = 'FunFixedIncome',
    FunMixed = 'FunMixed',
    FunSpeciality = 'FunSpeciality',
    FunRealEstate = 'FunRealEstate',
    FunCommodity = 'FunCommodity',
    FunMoneyMarket = 'FunMoneyMarket',
    FunAlternatives = 'FunAlternatives',
    FunAltHedgeFunds = 'FunAltHedgeFunds',
    FunAltPrivateEquity = 'FunAltPrivateEquity',
    PrivateEquityVc = 'PrivateEquityVC',
    FunAltVentureCapital = 'FunAltVentureCapital',
    FunAltFundOfFunds = 'FunAltFundOfFunds',
    FunOther = 'FunOther',
    FundCfd = 'FundCfd',
    FundStruct = 'FundStruct',
    CfdOther = 'CFDOther',
    FxOptions = 'FxOptions',
    FxFwd = 'FxFwd',
    FxTarf = 'FxTarf',
    FxStruct = 'FxStruct',
    FxFutures = 'FxFutures'
}


