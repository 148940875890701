import React, { FC } from "react";
import { IDecimalCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

export const TextCell = ({
  checkIsTotal,
}: {
  checkIsTotal?(item: any): boolean;
}): FC<IDecimalCellProps> => {
  return React.memo(({ width, ...props }) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const field = props.field || "";
    const value = props.dataItem[field];

    // TODO fix this issue
    return (
      //@ts-ignore
      <td
        style={{
          // TODO: replace colors with appropriate css-vars
          color: "var(--color)",
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
        {...props}
      >
        <div style={{ display: "flex", width }}>
          <div
            style={{
              width,
              fontWeight: checkIsTotal?.(props.dataItem) ? 600 : undefined,
            }}
          >
            {value}
          </div>
        </div>
      </td>
    );
  });
};
