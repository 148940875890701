import React, { FC, useEffect, useRef } from "react";
import { AllocationOption } from "./types";
import {
  ALLOCATION_COLORS,
  CUSTODIAN_COLORS,
  INVESTMENT_VEHICLE_COLORS,
  useUserCustodians,
  usePortfolio,
  useReferenceData,
  sendTracking,
  SECTOR_COLORS,
  REGION_COLORS_ENUM,
} from "@iliotech/data-wire";
import cash from "./icons/cash.svg";
import commodities from "./icons/commodities.svg";
import cryptoCurrency from "./icons/crypto-currency.svg";
import equity from "./icons/equity.svg";
import fixedIncome from "./icons/fixedIncome.svg";
import fund from "./icons/funds.svg";
import realAssets from "./icons/real-assets.svg";
import realEstate from "./icons/real-estate.svg";
import { getBackgroundColor } from "@iliotech/data-wire";
import {
  EnumDisplay,
  PortfolioCustodian,
} from "../../../../../generated-api-v3";

interface IOwnProps {
  enableSelection?: boolean;
}

interface IStructureGrouping {
  lockedGroup?: string;
  setLockedGroup?: React.Dispatch<React.SetStateAction<string | undefined>>;
  grouping: AllocationOption;
  setGrouping(key: string): void;
  selectedGroup?: { code: string; label: string };
  setSelectedGroup?: React.Dispatch<
    React.SetStateAction<{ code: string; label: string } | undefined>
  >;
  setCustodians?: React.Dispatch<React.SetStateAction<PortfolioCustodian[]>>;
  enableSelection?: boolean;
  setRiskAssetView?: React.Dispatch<React.SetStateAction<string | undefined>>;
  structureOptions: AllocationOption[];
}

const renderIcon = (src: string) => {
  return <img alt="" className="no-invert" src={src} />;
};

const colorIconRenderer = (id: string) => {
  return (
    <div
      style={{
        width: "2.5rem",
        padding: "0.2rem",
        backgroundColor: getBackgroundColor(id),
        borderRadius: "1.25rem",
      }}
    />
  );
};

const Icons: { [id: string]: JSX.Element } = {
  Equities: renderIcon(equity),
  FixedIncome: renderIcon(fixedIncome),
  RealEstate: renderIcon(realEstate),
  Funds: renderIcon(fund),
  Alternatives: renderIcon(fund),
  Commodities: renderIcon(commodities),
  CryptoCurrencies: renderIcon(cryptoCurrency),
  CashAndEquivalents: renderIcon(cash),
  RealAssets: renderIcon(realAssets),
};

export const STRUCTURE_OPTIONS: (
  s: string,
  custodians: PortfolioCustodian[],
  CurrencyReferenceData?: Record<string, EnumDisplay> | undefined
) => AllocationOption[] = (s, custodians, CurrencyReferenceData) => [
  {
    key: s === "RISK_ASSET_CLASS" ? "RiskAssetClass" : "AssetClass",
    field: s === "RISK_ASSET_CLASS" ? "riskAssetClassCode" : "assetClassCode",
    labelField:
      s === "RISK_ASSET_CLASS" ? "riskAssetClassName" : "assetClassName",
    iconRenderer: (id: string) => Icons[id],
    getColor: (id: string) =>
      INVESTMENT_VEHICLE_COLORS[id] || ALLOCATION_COLORS[id],
    label: "Asset Class",
  },
  {
    key: "Region",
    field: "regionCode",
    labelField: "regionName",
    label: "Region",
    getColor: (id: string) =>
      //@ts-ignore
      REGION_COLORS_ENUM?.[id] || getBackgroundColor(id),
  },
  {
    key: "Currency",
    // @ts-ignore
    field: "currencyLocalName",
    labelField: "currencyLocalName",
    label: "Currency",
    iconRenderer: (id: string) => (
      <div style={{ color: "white" }}>{CurrencyReferenceData?.[id]?.label}</div>
    ),
    getColor: (id: string) => getBackgroundColor(id),
  },
  {
    key: "Custodian",
    field: "custodianName",
    labelField: "custodianName",
    label: "Account",
    getColor: (id: string) => {
      return (
        CUSTODIAN_COLORS?.[custodians.findIndex((item) => item.code === id)] ||
        getBackgroundColor(id)
      );
    },
  },
  {
    key: "IndustrySector",
    labelField: "sectorName",
    field: "sectorCode",
    label: "Sector",
    getColor: (id: string) => SECTOR_COLORS?.[id] || getBackgroundColor(id),
  },
];

const noop = () => {
  console.log("Not initialised");
};

const DEFAULT_CONTEXT: IStructureGrouping = {
  grouping: STRUCTURE_OPTIONS("RISK_ASSET_CLASS", [])[0],
  selectedGroup: undefined,
  setSelectedGroup: noop,
  setGrouping: noop,
  setCustodians: noop,
  setRiskAssetView: noop,
  structureOptions: [],
  enableSelection: false,
};

export const StructureGroupingContext = React.createContext(DEFAULT_CONTEXT);

export const StructureGroupingProvider: FC<IOwnProps> = ({
  enableSelection = true,
  children,
}) => {
  const [riskAssetView, setRiskAssetView] =
    React.useState<string | undefined>("RISK_ASSET_CLASS");

  const [lockedGroup, setLockedGroup] = React.useState<string | undefined>();
  const [custodians, setCustodians] = React.useState<PortfolioCustodian[]>([]);
  const { Currency } = useReferenceData();
  const structureOptions = React.useMemo(
    () => STRUCTURE_OPTIONS(riskAssetView || "", custodians, Currency),
    [riskAssetView, custodians, Currency]
  );

  const [selectedGroup, setSelectedGroup] =
    React.useState<{ code: string; label: string } | undefined>();
  const [grouping, setGrouping] = React.useState<AllocationOption>(
    structureOptions[0]
  );
  const lockedGroupRef = useRef<boolean>();

  const updateGroup = (key: string) => {
    const nextGroup = structureOptions.find((o) => o.key === key);
    sendTracking(`grouping_changed_to:${key}`, "structure_grouping_tracker");
    setGrouping(nextGroup ?? structureOptions[0]);
    setSelectedGroup(undefined);
  };

  useEffect(() => {
    if (lockedGroup) {
      updateGroup(lockedGroup);
      lockedGroupRef.current = true;
    }
    if (!lockedGroup && lockedGroupRef.current) {
      lockedGroupRef.current = false;
      updateGroup("RiskAssetClass");
    }
  }, [lockedGroup]);

  return (
    <StructureGroupingContext.Provider
      value={{
        setLockedGroup,
        lockedGroup,
        grouping,
        setRiskAssetView,
        setCustodians,
        setGrouping: updateGroup,
        selectedGroup: enableSelection ? selectedGroup : undefined,
        setSelectedGroup: enableSelection ? setSelectedGroup : undefined,
        enableSelection,
        structureOptions,
      }}
    >
      {children}
    </StructureGroupingContext.Provider>
  );
};

export const useStructureGrouping = () =>
  React.useContext(StructureGroupingContext);
