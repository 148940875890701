import React from "react";
import {
  Input,
  InputProps,
  InputChangeEvent,
} from "@progress/kendo-react-inputs";
import Search from "./assets/search.svg";
import s from "./SearchAsync.module.scss";
import classNames from "classnames";
interface IOwnProps extends InputProps {
  searchFunction: (filter: string) => Promise<void> | void;
  isAsync?: boolean;
  className?: string;
}

export function SearchAsync({
  searchFunction,
  placeholder = "Search",
  isAsync = true,
  className,
  ...rest
}: IOwnProps) {
  const pendingRequest = React.useRef<any>();

  const debouncedFetch = async (search: string) => {
    const fetchData = searchFunction;
    if (isAsync) {
      await fetchData(search);
    } else {
      fetchData(search);
    }
  };

  const filterChange = async (event: InputChangeEvent) => {
    const search = event.value;
    if (pendingRequest.current) {
      clearTimeout(pendingRequest.current);
    }
    pendingRequest.current = setTimeout(() => {
      debouncedFetch(search);
    }, 500);
  };

  return (
    <div className={classNames(s.wrapper, className)}>
      <img src={Search} alt="search" className={s.searchIcon} />
      <Input onChange={filterChange} placeholder={placeholder} {...rest} />
    </div>
  );
}
