import React from "react";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { CenteredBlurPage } from "../Blur/CenteredBlurPage";
import { Button } from "@progress/kendo-react-buttons";
import ReactGA from "react-ga4";
import { sendTracking } from "@iliotech/data-wire";
import { Blur } from "../Blur/Blur";
import { BasicHeader } from "../BasicHeader/BasicHeader";

interface IOwnProps {
  email: string;
  onCodeVerified(): void;
}

enum RESENDING_CODE_STATUS {
  NONE,
  SENDING,
  SENT,
}

type ResendingCodeStatus = keyof typeof RESENDING_CODE_STATUS;
export const VerifyUser = ({ email, onCodeVerified }: IOwnProps) => {
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState("");
  const [resendingCode, setResendingCode] =
    React.useState<RESENDING_CODE_STATUS>(RESENDING_CODE_STATUS.NONE);
  const { t } = useTranslation();

  React.useEffect(() => {
    sendTracking(`user_visits_activation_page`, "register_flow");
  }, []);

  const resendCode = () => {
    setResendingCode(RESENDING_CODE_STATUS.SENDING);
    Auth.resendSignUp(email)
      .then((result) => {
        console.log({ result });
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        setResendingCode(RESENDING_CODE_STATUS.SENT);
      });
  };

  const codeSendingMessage = React.useMemo(() => {
    let message = "";
    switch (resendingCode) {
      case RESENDING_CODE_STATUS.SENDING:
        message = " - Sending";
        break;
      case RESENDING_CODE_STATUS.SENT:
        message = " - Sent!";
        break;
    }

    return message;
  }, [resendingCode]);

  const validateCode = () => {
    if (code.length === 6) return true;
    setError(t("The code should be 6 characters long"));
  };

  const submitCode = () => {
    sendTracking(`user_continue`, "activation-flow");
    if (!validateCode()) return;

    Auth.confirmSignUp(email, code.trim())
      .then(onCodeVerified)
      .catch((e) => {
        setError(
          "Unable to verify code. Try again or click 'Resend Code', below"
        );
        console.error("Confirm signup ERROR", e);
      });
  };

  return (
    <Blur>
      <BasicHeader title={"Activate your account"} hideProfile hideLogOff />
      <div style={{ maxWidth: 600, margin: "auto" }}>
        <article>
          <h6>{t("Activate your account")}</h6>
          <p>
            {t(
              `To verify your account, please enter the code sent to: ${email}`
            )}
          </p>
          <input
            type={"text"}
            value={code}
            style={{
              borderColor: error ? "var(--bad)" : "undefined",
              textAlign: "center",
            }}
            onChange={(e) => void setCode(e.target.value)}
          />
          {!!error && (
            <div
              style={{
                color: "var(--bad)",
                marginTop: "0.5rem",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {t(error)}
            </div>
          )}
          <p style={{ textAlign: "center", paddingTop: "1rem" }}>
            <b>Didn't receive the code?</b> Please check your spam/junk, or
            <br />
            <a
              href={"#"}
              onClick={resendCode}
              style={{ textDecorationLine: "underline" }}
            >
              Resend Code
            </a>
            {codeSendingMessage}
          </p>
          <br />
          <br />
          <p style={{ textAlign: "center", paddingTop: "1rem" }}>
            {t("If you are still having issues, please send us an email:")}{" "}
            <a href={"mailto:support@illio.com"}>support@illio.com</a>
          </p>
        </article>
        <Button
          style={{ float: "right", marginTop: "2rem" }}
          onClick={submitCode}
        >
          Continue
        </Button>
      </div>
    </Blur>
  );
};
