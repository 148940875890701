export const ASSETS: Record<string, string> = {
  adjustments: "Adjustments",
  alternatives: "Alternatives",
  cash: "CashAndEquivalents",
  commodities: "Commodities",
  fixedIncome: "FixedIncome",
  equity: "Equities",
  fund: "Funds",
  realEstate: "RealEstate",
  realAssets: "RealAssets",
  cryptoCurrencies: "CryptoCurrencies",
  subscriptionWithdrawal: "SubscriptionWithdrawal",
  futures: "Futures",
  mixed: "MixedAllocation",
  fx: "Fx",
  other: "Other",
  derivatives: "Derivatives",
};

export const ASSETS_LABELS: Record<string, string> = {
  adjustments: "Adjustments",
  alternatives: "Alternatives",
  cash: "Cash and equivalents",
  commodities: "Commodities",
  fixedIncome: "Fixed Income",
  equity: "Equities",
  fund: "Funds",
  realEstate: "Real Estate",
  realAssets: "Real Assets",
  cryptoCurrencies: "Crypto Currencies",
  subscriptionWithdrawal: "Subscription and Withdrawal",
  futures: "Futures",
  mixed: "Mixed Allocation",
  fx: "Fx",
  other: "Other",
  derivatives: "Derivatives",
};
