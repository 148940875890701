import {
  SharedTooltipContext,
  TooltipPoint,
} from "@progress/kendo-react-charts";
import s from "./TimeSeriesSharedTooltipRenderer.module.scss";
import classNames from "classnames";

export const TimeSeriesSharedTooltipRenderer = (
  info: SharedTooltipContext,
  valueFormatter?: (v?: number) => string,
  categoryFormatter?: (v?: string) => string,
  settings?: StandardTimeSeriesTooltipContentProps["settings"]
) => {
  return (
    <StandardTimeSeriesTooltipContent
      info={info}
      valueFormatter={valueFormatter}
      categoryFormatter={categoryFormatter}
      settings={settings}
    />
  );
};

export type TooltipSettings = {
  hideTotal?: boolean;
  showZeroes?: boolean;
};

type StandardTimeSeriesTooltipContentProps = {
  info: SharedTooltipContext;
  valueFormatter?: (v?: number) => string;
  categoryFormatter?: (v?: string, ev?: any) => string;
  settings?: TooltipSettings;
};

export const StandardTimeSeriesTooltipContent = ({
  info,
  categoryFormatter,
  valueFormatter,
  settings,
}: StandardTimeSeriesTooltipContentProps) => {
  const showTotal = !settings?.hideTotal;
  const total = showTotal
    ? info.points.reduce((acc, curr) => acc + curr.value, 0)
    : undefined;

  const nonZeroPoints = settings?.showZeroes
    ? info?.points
    : info?.points.filter((point) => point.value !== 0);

  const formatTooltipPoint = (point: TooltipPoint) =>
    valueFormatter ? valueFormatter?.(point.value as number) : point.value;
  const totalString = valueFormatter ? valueFormatter?.(total) : total;

  const formattedCategoryText = categoryFormatter
    ? categoryFormatter(info.categoryText)
    : info.categoryText;

  return (
    <div>
      <h4 className={s.pointCategory}>{formattedCategoryText}</h4>
      <div className={classNames(s.spaceBetween, "tooltip-content")}>
        {showTotal && `Total: ${totalString}`}
      </div>
      {nonZeroPoints.map((point, key) => (
        <div className={s.row} key={key}>
          <div
            className={s.point}
            style={{ background: (point as any).point.color }}
          />
          <div className={classNames(s.spaceBetween, "tooltip-content")}>
            <div>{point.series.name}</div>
            <div className={s.value}>{formatTooltipPoint(point)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
