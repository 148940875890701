import React from "react";
import { Autocomplete } from "../../FormInputs";
import { IPortfolioSettingsBenchmarkProps } from "../types";

export const PortfolioSettingsBenchmark = ({
  portfolioInfo,
  setBenchmark,
  availableBenchmarks,
  loading,
}: IPortfolioSettingsBenchmarkProps) => {
  const [working, setWorking] = React.useState(false);
  const [text, setText] = React.useState("");
  const benchmarks = availableBenchmarks?.filter(
    (item) => item.type === "Portfolio"
  );
  const handleChangeBenchmark = (event: any) => {
    if (event.value?.name) {
      setWorking(true);
      setText("");
      setBenchmark(event.value).finally(() => setWorking(false));
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <label>Benchmark</label>
      <Autocomplete
        loading={loading || working}
        disabled={working}
        clearButton={false}
        name={""}
        data={
          benchmarks
            ?.filter(
              (b) =>
                b.name.toUpperCase().includes(text.toUpperCase()) ||
                b.code.toUpperCase().includes(text.toUpperCase())
            )
            .sort((a, b) => a.name.localeCompare(b.name)) ?? []
        }
        textField={"name"}
        onChange={handleChangeBenchmark}
        value={portfolioInfo?.benchmarkIndex}
        filterable={true}
        onFilterChange={(event) => setText(event.filter.value)}
        itemRender={(li, itemProps) => {
          return React.cloneElement(
            li,
            li.props,
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0.25rem",
              }}
            >
              <div style={{ flex: 1 }}>{itemProps.dataItem.name}</div>
              {/*<div>{itemProps.dataItem.code?.split(".")[0]?.split(":")[0]}</div>*/}
            </div>
          );
        }}
      />
      {/*<pre style={{ fontSize: 12 }}>*/}
      {/*  {JSON.stringify({ availableBenchmarks }, null, 2)}*/}
      {/*</pre>*/}
    </div>
  );
};
