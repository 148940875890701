import {
  assignToWorkgroup,
  sendRequest,
  useAuth,
  useWorkgroupList,
} from "@iliotech/data-wire";
import AssignToClientModal from "@iliotech/component-library/src/components/__pageComponents/WorkgroupListPage/components/AssignToClientModal/AssignToClientModal";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useUsers } from "@iliotech/data-wire";
import { IToggableItem } from "@iliotech/component-library/src/components/ToggableButtons/ToggableButtons";
import { AssignDtoAccountRoleEnum } from "@iliotech/generated-api-v3";
import { useState } from "react";

export const AssignToClientModalWrapper = observer(
  ({ onComplete }: { onComplete: () => void }) => {
    const { illioSelectedAccount, isCustomer } = useAuth();
    const [includeAssigned, setIncludeAssigned] = useState(false);
    const usersRequest = useUsers(
      illioSelectedAccount?.externalAccountId!,
      "CUSTOMER",
      includeAssigned,
      !isCustomer
    );

    const users = usersRequest.data?.data;
    const clientList: IToggableItem[] = (users || []).map((user) => ({
      label: user.fullName,
      value: user.identifier,
    }));

    const store = useWorkgroupList();
    const visible = store.modalState.type === "assign";

    const onAssignToClient = async (items: string[]) => {
      try {
        store.setModalLoading(true);
        await assignToWorkgroup(
          illioSelectedAccount?.externalAccountId!,
          {
            userIdentifier: items[0],
            accountRole: AssignDtoAccountRoleEnum.Customer,
          },
          store?.modalState?.name
        );
        onComplete();
        store.resetModalState();
      } catch (e) {
        console.log(e);
        store.resetModalState();
      }
    };

    return (
      <AssignToClientModal
        loading={store.modalState.loading || usersRequest.isLoading}
        visible={visible}
        checked={includeAssigned}
        setChecked={setIncludeAssigned}
        handleClose={() => store.resetModalState()}
        onSave={onAssignToClient}
        name={store.modalState.name}
        clientList={clientList}
      />
    );
  }
);
