import * as React from "react";
import s from "./FormModal.module.scss";
import { createPortal } from "react-dom";
import { CgClose } from "react-icons/cg";
import cn from "classnames";
import { CSSProperties, PropsWithChildren, useState } from "react";
import { Typography } from "@progress/kendo-react-common";

export interface FormModalProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  visible: boolean;
  handleClose: (e?: any) => void;
  children: React.ReactNode | React.ReactNode[];
  wrapperId?: string;
  footer?: React.ReactNode;
  hideClose?: boolean;
  closeOnBackdropClick?: boolean;
  innerContainerStyle?: CSSProperties;
  modalStyle?: CSSProperties;
  modified?: boolean;
}

export const FormModal = ({
  wrapperId = "app",
  visible,
  handleClose,
  children,
  footer,
  hideClose,
  closeOnBackdropClick = true,
  innerContainerStyle,
  modalStyle,
  title,
  modified,
  ...restProps
}: FormModalProps) => {
  const [active, setActive] = useState(false);

  React.useEffect(() => {
    const closeOnEscapeKey = (e: any) =>
      e.key === "Escape" ? handleClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);

  React.useEffect(() => {
    setActive(visible);
  }, [visible]);

  if (!visible) {
    return null;
  }

  return createPortal(
    <div
      onMouseDown={closeOnBackdropClick ? handleClose : undefined}
      className={cn(s.modalOverlay, active && s.active)}
    >
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className={cn(s.modalContainer, active && s.active)}
        style={modalStyle}
        {...restProps}
      >
        <div className={s.modalInnerContainer} style={innerContainerStyle}>
          {!!title && (
            <Typography.h3
              style={{
                paddingBottom: "1rem",
                borderBottom: "1px solid var(--muted-border-color)",
              }}
            >
              {title}
            </Typography.h3>
          )}
          {!hideClose && (
            <CgClose onClick={handleClose} size={26} className={s.modalClose} />
          )}
          {children}
        </div>
        {footer}
      </div>
    </div>,
    document.getElementById(wrapperId)!
  );
};

export const FormModalFooter = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>) => {
  return (
    <div className={cn(s.footer, className)} style={style}>
      {children}
    </div>
  );
};

FormModal.Footer = FormModalFooter;

export default FormModal;
