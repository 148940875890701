import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { positionValidator } from "@iliotech/data-wire";
import * as React from "react";
import { Autocomplete, AutocompleteProps } from "../Autocomplete/Autocomplete";
import { AddNewButton } from "../../AddNewButton/AddNewButton";

interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  instruments?: any;
  onChangeText?(text: string): void;
  validator?: any;
  openModal?: () => void;
  buttonText?: string;
}

function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    //@ts-ignore
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export const InstrumentSearchField = ({
  errors,
  openModal,
  name = "instrument",
  label = "Name",
  instruments,
  onChangeText,
  filterData,
  buttonText = "Add new instrument",
  ...rest
}: IOwnProps) => {
  const [open, setOpen] = React.useState(false);

  const component = React.useMemo(
    () => (props: AutocompleteProps) =>
      (
        <Autocomplete
          {...props}
          opened={open}
          onOpen={() => setOpen(true)}
          onClose={() => setTimeout(() => setOpen(false), 200)}
          onFocus={() => setOpen(true)}
        />
      ),
    [open, setOpen]
  );

  return (
    <Field
      component={component}
      label={label}
      name={name}
      dataItemKey={"instrumentSourceId"}
      textField={"name"}
      placeholder={"Enter name or ticker"}
      error={errors?.[name]}
      validator={positionValidator}
      {...rest}
      // onChange={
      //   onChangeText
      //     ? (event) => {
      //       console.log({ event });
      //       // onChangeText(event.target.value)
      //     }
      //     : undefined
      // }
      filterData={
        typeof filterData === "function"
          ? filterData
          : (filter: string) => {
              return instruments
                ?.filter(
                  (item: any) =>
                    item.code.toLowerCase().startsWith(filter.toLowerCase()) ||
                    item.name.toLowerCase().startsWith(filter.toLowerCase())
                )
                ?.map((pos: any) => ({
                  ...pos,
                  name: `${getFlagEmoji(pos.country) + " "}${pos.name} [${
                    pos.code
                  } - ${pos.currencyCode}]`,
                }));
            }
      }
      header={
        openModal && (
          <AddNewButton
            text={buttonText}
            setOpen={setOpen}
            openModal={openModal}
          />
        )
      }
      data={instruments}
      allowCustom
    />
  );
};
