import * as React from "react";
import s from "./ToggableButton.module.scss";
import cn from "classnames";
import { observer } from "mobx-react-lite";

interface IProps {
  onClick(): void;
  toggled?: boolean;
  label: string;
  size?: "small" | "large";
}

const ToggableButton = observer(
  ({ onClick, label, toggled, size = "small" }: IProps) => {
    return (
      <div
        className={cn(
          s.itemContainer,
          toggled && s.active,
          size === "large" && s.large
        )}
        onClick={onClick}
      >
        <div
          className={cn(s.circle, toggled && s.active)}
          style={{ backgroundColor: toggled ? "black" : "white" }}
        />
        {label}
      </div>
    );
  }
);

export default ToggableButton;
