import { BundlesEnum, useAuth } from "@iliotech/data-wire";
import s from "./PageNavigator.module.scss";
import React from "react";
import { SYSADMIN_BASE_URL } from "../../../../constants/constants";

const baseUrl = SYSADMIN_BASE_URL;

export const PageNavigator = () => {
  const { isCustomer, isOwner, hasBundle } = useAuth();
  const managerFunctions = hasBundle(BundlesEnum.Manager_Functions);

  if (!managerFunctions) return null;
  return (
    <div className={s.row}>
      {!isCustomer && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 30,
            paddingBottom: 10,
          }}
        >
          <a rel="noreferrer">Clients & Portfolio</a>
          {isOwner && (
            <a
              href={baseUrl + "/manage-account/UserManagement"}
              target={"_blank"}
              rel="noreferrer"
            >
              User management
            </a>
          )}
          <a
            href={baseUrl + "/unlisted/Equities"}
            target={"_blank"}
            rel="noreferrer"
          >
            Unlisted instrument management
          </a>
          <a
            href={baseUrl + "/manage-account/OAuthManagement"}
            target={"_blank"}
            rel="noreferrer"
          >
            Manage broker connections
          </a>
        </div>
      )}
    </div>
  );
};
