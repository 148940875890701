import * as React from "react";
import cn from "classnames";
import s from "./CreatePortfolioForm.module.scss";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { PortfolioForm } from "./components/PortfolioForm";
import {
  EnumDisplay,
  WorkgroupCreateDtoDistributionEnum,
} from "@iliotech/generated-api-v3";
import { Loader } from "../Loader/Loader";
import {
  addPortfolioToWorkgroup,
  createWorkgroup,
  useAuth,
  useCreatePortfolio,
  usePortfolio,
  useWithTryAndCatch,
} from "@iliotech/data-wire";
import { sendRequest } from "@iliotech/data-wire";
import { useParams } from "react-router";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import { updatePortfolio } from "@iliotech/data-wire/src/requests/updatePortfolio";
import { generatePath, useNavigate } from "react-router-dom";
import { CreatePortfolioStepper } from "../";

interface IProps {
  t: (toTranslate: string) => string;
  allCurrencies: string[] | undefined;
  allPositions: Record<string, EnumDisplay> | undefined;
  loading?: boolean;
  onBackPress?(): void;
  onAfterSubmit: (response: any) => void;
}

export const CreatePortfolioForm = ({
  t = (s) => s,
  allCurrencies,
  allPositions,
  onAfterSubmit,
  onBackPress,
  loading,
}: IProps) => {
  const { h3: H3, h6: H6 } = Typography;
  const { illioSelectedAccount } = useAuth();
  const { withTryAndCatch } = useWithTryAndCatch();
  const { workgroup = "" } = useParams();
  const { portfolioId = "" } = useParams();
  const portfolio = usePortfolio(portfolioId)?.data?.data;
  const workgroupPassed = workgroup && workgroup !== "select";
  const createPortfolio = useCreatePortfolio(
    sendRequest,
    illioSelectedAccount?.externalAccountId!
  );
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    const isNewWorkgroup =
      data.workgroupName?.code === data.workgroupName?.label;
    let validData = {
      ...data,
      draft: false,
    };

    // if portfolioId we update portfolio instead of creating
    if (portfolioId) {
      const validData = {
        ...data,
        workgroup: portfolio?.workgroup,
      };
      const response = await updatePortfolio(validData, portfolioId);
      onAfterSubmit(response?.data);
      return;
    }
    // if no workgroup assign to default
    if (!workgroup) {
      const response = await createPortfolio(validData);
      onAfterSubmit(response);
      return;
      // if we pass the workgroup name use that workgroupName
    } else if (workgroupPassed) {
      // if workgroup is selected, assign it to the portfolio
      const response = await addPortfolioToWorkgroup(
        {
          ...validData,
        },
        illioSelectedAccount?.externalAccountId!,
        workgroup
      );

      onAfterSubmit(response?.data);
      return;
    }
    // following is the flow when an enterprise user selects a workgroup from the dropdown
    let response;
    //if new workgroup, create workgroup first
    if (isNewWorkgroup) {
      const [result, isError] = await withTryAndCatch(() =>
        createWorkgroup(
          {
            name: data.workgroupName.code,
            distribution: WorkgroupCreateDtoDistributionEnum.Client,
          },
          illioSelectedAccount?.externalAccountId!
        )
      );
      if (isError) return;
      response = result;
    }

    //if new workgroup was created, get the workgroup name from response else from data
    const workgroupName = isNewWorkgroup
      ? response?.data.name
      : data.workgroupName.label;

    // assign it to newly created workgroup
    const [assignRes, assignError] = await withTryAndCatch(() =>
      addPortfolioToWorkgroup(
        {
          ...validData,
        },
        illioSelectedAccount?.externalAccountId!,
        workgroupName
      )
    );
    if (assignError) return;

    // finally call onAfterSubmit with the response from portfolio

    onAfterSubmit(assignRes.data);
  };

  const renderCloseButton = () => {
    if (!onBackPress) return null;
    return (
      <ModalCloseButton
        text={"Close"}
        onPress={onBackPress}
        style={{ marginTop: 30 }}
      />
    );
  };

  const onSubmitClick = () => {
    if (portfolioId) {
      navigate(
        generatePath(`/portfolio/:portfolioId/add-investments/onboarding`, {
          portfolioId,
        })
      );
    }
  };

  if (loading) {
    return (
      <>
        {renderCloseButton()}
        <Loader fullScreen />;
      </>
    );
  }

  return (
    <div id={"portal"} className={cn(s.portal)}>
      {renderCloseButton()}
      <Form
        key={JSON.stringify(portfolio)}
        onSubmit={onSubmit}
        onSubmitClick={onSubmitClick}
        initialValues={{
          name: portfolio?.name,
          currency: portfolio?.currencyCode,
        }}
        render={(formRenderProps) => (
          <div style={{ margin: 10 }}>
            <CreatePortfolioStepper
              onBack={onBackPress!}
              onNext={formRenderProps.onSubmit}
            />
            <article style={{ maxWidth: 600 }}>
              <H6>
                {t(
                  "In order to start aggregating your wealth, you need to setup your first portfolio"
                )}
              </H6>
              <FormElement style={{ width: "100%" }}>
                <PortfolioForm
                  allCurrencies={allCurrencies}
                  allPositions={allPositions}
                  formRenderProps={formRenderProps}
                />
              </FormElement>
            </article>
          </div>
        )}
      />
    </div>
  );
};

export default CreatePortfolioForm;
