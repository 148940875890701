import React from "react";
import {StepTracker} from "../StepTracker/StepTracker";

interface IStepProps {
  currentStep: number;
  onBack?(): void;
  onNext?(): void;
}
export const Step: React.FC<IStepProps> = ({currentStep, onBack, onNext, children}) => {
  return (
    <>
      <StepTracker totalSteps={4} {...{currentStep, onBack, onNext}} />
      <article>
        {children}
      </article>
  
      
    </>
  
  )
}
