"use client";
import axios from "axios";

import React, { FC, PropsWithChildren, useState } from "react";

import { SortCategoriesGetter, MarketInsightsSortType } from "../types";
import { saEvent } from "../utils";

interface IOwnProps {}

const SortContext =
  React.createContext<ReturnType<typeof useSortContextValue> | null>(null);

export const INITIAL_SORT = {
  categoryId: "PERFORMANCE",
  subCategoryId: "PERFORMANCE_VS_MARKET",
  subCategoryLabel: "Largest Market Out and Under Performers",
  mode: "top",
  isAll: false,
  period: 0,
} as const;

export const INITIAL_WATCHLIST = "SnP500";
export const INITIAL_WATCHLIST_NAME = "US 500 Stocks";
export const SortContextProvider: FC<PropsWithChildren<IOwnProps>> = ({
  children,
}) => {
  const value = useSortContextValue();
  return (
    <SortContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </SortContext.Provider>
  );
};

const useOptimisticValues = ({ sort }: { sort: MarketInsightsSortType }) => {
  const [optimisticSort, setOptimisticSort] = React.useState(sort);
  const category = sort.categoryId;
  const subCategory = sort.subCategoryId;

  const isLoading =
    category !== optimisticSort.categoryId ||
    subCategory !== optimisticSort.subCategoryId;

  React.useEffect(() => {
    setOptimisticSort(sort);
  }, [sort]);

  return {
    optimisticSort,
    setOptimisticSort,
    isLoading,
  };
};

const useSortContextValue = () => {
  const [sort, setSort] = useState<MarketInsightsSortType>(INITIAL_SORT);
  const [watchlistId, setWatchlistId] = useState<string>(INITIAL_WATCHLIST);
  const [watchlistName, setWatchlistName] = useState<string>(
    INITIAL_WATCHLIST_NAME
  );
  const optimisticSort = useOptimisticValues({ sort });

  const setAllSort = () => {
    saEvent("table-category-change", {
      selectedCategory: "All",
    });
    setSort({ ...INITIAL_SORT, isAll: true });
  };

  const resetSort = () => {
    setSort(INITIAL_SORT);
  };
  const setSortFake = (newSort: any) => {
    setSort(newSort);
  };

  const setWatchlist = (watchlist: string, name: string) => {
    setWatchlistId(watchlist);
    setWatchlistName(name);
  };

  const fakeSetOptimistic = (newSortOptimistic: any) => {
    optimisticSort.setOptimisticSort(newSortOptimistic);
  };
  const isAllSort = sort.isAll || false;

  return {
    sort,
    setSort: setSortFake,
    setOptimisticSort: fakeSetOptimistic,
    optimisticSort,
    setAllSort,
    isAllSort,
    watchlistId,
    setWatchlist,
    watchlistName,
    resetSort,
  };
};

export const useSortContext = () => {
  const value = React.useContext(SortContext);
  if (!value) {
    throw new Error("SortContext is not initialized");
  }
  return value;
};
