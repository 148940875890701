import React from 'react';
import {Blur} from "./Blur";

export const CenteredBlurPage: React.FC = ({ children }) => {
  // return (
  //   <Blur>
  //     {children}
  //   </Blur>
  // )
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "relative",
      }}
    >
      <Blur>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </div>
      </Blur>
    </div>
  );
};
