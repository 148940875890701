import React from "react";
import {
  PriceSummaryDto,
  SubscriptionControllerApi,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../../constants/constants";
import { useAuth } from "../context";

const api = new SubscriptionControllerApi(undefined, `${API_BASE}/api`);

export type BillingOption = {
  tierProduct?: PriceSummaryDto;
  name: string;
  amount: number;
  interval: string;
  currencyCode: string;
  currencySymbol: string;
  description: string;
  features: Feature[];
};

type ServerProductWithName = {
  name: string;
  prices: { [p: string]: PriceSummaryDto[] };
};

type Feature = {
  name?: string;
  description: string;
};

export const useBilling = (currency: string, interval: "month" | "year") => {
  const [availableProducts, setAvailableProducts] =
    React.useState<ServerProductWithName[]>();

  const { illioSelectedAccount } = useAuth();
  const [availableOptions, setAvailableOptions] = React.useState<
    BillingOption[]
  >([]);

  React.useEffect(() => {
    (async () => {
      const response = await api.getAccTierPriceInformation(
        illioSelectedAccount?.externalAccountId!
      );
      const filteredProducts = Object.entries(response.data || {})
        .filter(([packageName, pack]) =>
          Object.entries(pack).some(([, price]) =>
            price.some(
              (p) =>
                p.type === "TIER" &&
                p.currencyCode === currency &&
                p.interval === interval
            )
          )
        )
        .map(([key, priceSummaries]) => ({
          name: key,
          prices: priceSummaries,
        }));

      const nextBillingOptions: BillingOption[] = [];

      filteredProducts.forEach((product) => {
        const tierProduct =
          product.prices[`TIER_${currency.toUpperCase()}_${interval}`]?.[0];

        nextBillingOptions.push({
          name: product.name,
          features: getFeaturesFromPrices(
            product.prices[
              `recurring_${currency.toUpperCase()}_${interval}`
            ].filter((p) => !p.amount)
          ),
          description:
            product.prices[`TIER_${currency.toUpperCase()}_${interval}`]?.[0]
              ?.description ?? "",
          tierProduct,
          currencyCode: tierProduct?.currencyCode ?? "",
          currencySymbol: tierProduct?.currencySymbol ?? "",
          amount: tierProduct.amount,
          interval: tierProduct.interval,
        });
      });

      setAvailableProducts(filteredProducts);
      setAvailableOptions(nextBillingOptions.filter((p) => !!p.tierProduct));
    })();
  }, [currency, interval, illioSelectedAccount?.externalAccountId]);

  const createSubscriptionCheckoutSession = (
    option: BillingOption,
    accountId: string
  ) => {
    if (!option.tierProduct?.name) return;

    const referral =
      (window as any).Rewardful?.referral || "checkout_" + new Date().getTime();

    const tierLevel = option.tierProduct.name;
    const interval = option.tierProduct.interval.toUpperCase();
    const currency = option.currencyCode;

    return api
      .createCheckoutSession(
        {
          tierLevel,
          currency,
          interval,
        } as any,
        accountId,
        referral
      )
      .then((result) => {
        document.location.href = result.data;
      });
  };

  return {
    availableOptions,
    createSubscriptionCheckoutSession,
    // * TODO
    loading: !availableProducts,
  };
};

const getFeaturesFromPrices = (prices: PriceSummaryDto[]): Feature[] => {
  if (!prices.length) return [];
  const featureMap: { [featureName: string]: Feature } = {};

  prices.forEach((price) => {
    featureMap[price.name] = {
      name: price.name,
      description: price.name,
    };
  });

  return Object.values(featureMap);
};
