import * as React from "react";
import FormModal from "../../../../FormModal/FormModal";
import RoundButton from "../../../../RoundButton/RoundButton";
import ToggableButtons, {
  IToggableItem,
} from "../../../../ToggableButtons/ToggableButtons";
import s from "./ManagerShareModal.module.scss";
import { FadeLoading } from "../../../../FadeLoading/FadeLoading";
interface IProps {
  visible: boolean;
  handleClose: () => void;
  onSave: (items: string[]) => void;
  name: string;
  managerList: IToggableItem[];
  initialManagers: IToggableItem[];
  loading?: boolean;
  fetching?: boolean;
}

const ManagerShareModal = ({
  visible,
  handleClose,
  onSave,
  name,
  managerList,
  initialManagers,
  loading,
  fetching,
}: IProps) => {
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const onSaveClicked = () => {
    onSave(selectedItems);
  };
  return (
    <FormModal
      key={JSON.stringify(initialManagers) + name}
      title={"Manager sharing"}
      visible={visible}
      handleClose={handleClose}
      footer={
        <FormModal.Footer className={s.footer}>
          <RoundButton fillMode={"outline"} onClick={handleClose}>
            Close
          </RoundButton>
          <RoundButton loading={loading} onClick={onSaveClicked}>
            Save
          </RoundButton>
        </FormModal.Footer>
      }
    >
      <div>
        <span>Client account:</span> <br />
        <b>{name}</b>
      </div>
      <br />
      <div>
        <span>Client name:</span> <br />
        <b>{name}</b>
      </div>
      <div>
        <span className={s.assign}>
          Select the managers who should have access to this client's
          portfolios:
        </span>
        <FadeLoading loading={fetching}>
          <ToggableButtons
            searchPlaceholder={"Search Manager"}
            initialItems={initialManagers}
            toggableItems={managerList}
            onChange={setSelectedItems}
          />
        </FadeLoading>
      </div>
    </FormModal>
  );
};

export default ManagerShareModal;
