import React, { PropsWithChildren } from "react";
import { arc, DefaultArcObject } from "d3-shape";
import cn from "classnames";
import s from "./ArcGauge.module.scss";

export interface ArcGaugeProps {
  values?: {
    min?: number;
    max?: number;
    actual?: number;
  };
  color?: string;
  loading?: boolean;
  error?: any;
  width?: number;
  thickness?: number;
  title?: string;
  valueLabel?: string;
  valueLabelColor?: string;
  valueLabelCy?: string;
  mini?: boolean;
}

export const ArcGauge = React.memo(
  ({
    values,
    error,
    width: widthIn,
    thickness,
    title,
    valueLabel,
    valueLabelColor,
    valueLabelCy,
    color = "blue",
    mini = false,
  }: PropsWithChildren<ArcGaugeProps>) => {
    const randomId = Math.random();
    const width = typeof widthIn === "number" ? widthIn : mini ? 25 : 200;

    // Range defaults to 100; is the difference between min and max
    const range = (values?.max ?? 100) - (values?.min ?? 0);

    if (error) {
      return <div>ERROR (ArcGauge)</div>;
    }

    const backgroundArcGenerator = arc()
      .outerRadius(width / 2)
      .innerRadius(width / 2 - (thickness ?? width / 6))
      .cornerRadius(2);

    const background = {
      startAngle: -Math.PI / 2,
      endAngle: Math.PI / 2,
      fill: "#EEE",
    };
    const foreground = {
      startAngle: -Math.PI / 2,
      endAngle: ((values?.actual || 1) / range - 0.5) * Math.PI,
      fill: mini ? color : `url('#myGradient${randomId}')`,
    };

    const items = [background, foreground];
    // @ts-ignore
    const slices = items.map(({ startAngle, endAngle }) =>
      backgroundArcGenerator({ startAngle, endAngle } as DefaultArcObject)
    );

    if (mini) {
      return (
        <div className={cn(s.gaugeSurround, s.mini)}>
          <figure className={cn(s.mini)}>
            <figcaption className={cn(s.caption, s.mini)}>{title}</figcaption>
            <svg
              height={width / 2}
              width={width}
              style={{ marginRight: "0.5rem" }}
            >
              <g transform={`translate(${width / 2} ${width / 2})`}>
                <path
                  d={backgroundArcGenerator(background as any) || ""}
                  className={cn(s.gradientBg)}
                />
                {slices.map((slice: string | null, i: number) => (
                  <path
                    x={"50%"}
                    y={"50%"}
                    key={slice}
                    d={slice ?? undefined}
                    fill={items[i]?.fill}
                  />
                ))}
              </g>
            </svg>
            <figcaption
              data-cy={valueLabelCy}
              className={cn(s.valueLabel, s.mini)}
              style={{ color: valueLabelColor || color }}
            >
              {valueLabel}
            </figcaption>
          </figure>
        </div>
      );
    }

    return (
      <div
        className={cn(s.gaugeSurround)}
        style={{
          position: "relative",
          display: "inline-block",
          overflow: "visible",
        }}
      >
        <figure>
          <svg height={width / 2} width={width}>
            <defs>
              <linearGradient
                x1={1}
                x2={0}
                y1={0.5}
                y2={0.7}
                id={`myGradient${randomId}`}
                gradientTransform="rotate(0)"
              >
                <stop offset="0%" stopColor={color} />
                <stop offset="100%" className={cn(s.gradientStart)} />
              </linearGradient>
            </defs>
            <g transform={`translate(${width / 2} ${width / 2})`}>
              <path
                d={backgroundArcGenerator(background as any) || ""}
                className={cn(s.gradientBg)}
              />
              {slices.map((slice: string | null, i: number) => (
                <path
                  x={"50%"}
                  y={"50%"}
                  key={slice}
                  d={slice ?? undefined}
                  fill={items[i]?.fill}
                />
              ))}
            </g>
          </svg>
          {title && <figcaption className={cn(s.caption)}>{title}</figcaption>}
          <figcaption
            data-cy={valueLabelCy}
            className={cn(s.valueLabel)}
            style={{ color: valueLabelColor || color }}
          >
            {valueLabel}
          </figcaption>
        </figure>
      </div>
    );
  }
) as any;
