import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { Typography } from "@progress/kendo-react-common";
import s from "./Cells.module.scss";
import cn from "classnames";
import { TransactionTableCellProps } from "./MarketPriceCell";
import { observer } from "mobx-react-lite";
import { usePositionUploadV2 } from "@iliotech/data-wire/src/stores/PositionsUpload";

const CustodianCell = ({ index }: TransactionTableCellProps) => {
  const store = usePositionUploadV2();

  const custodian = store.position.getField("custodian", index + "") as string;
  const custodianResolved = store.position.getField(
    "custodianResolved",
    index + ""
  ) as boolean;

  if (!custodian || !custodianResolved) {
    return (
      <Typography.p
        className={cn(s.link, s.error)}
        onClick={() =>
          store.position.setCustodianModal({
            visible: true,
            id: index + "",
            replace: true,
          })
        }
      >
        Resolve {custodian ? `[${custodian}]` : "Account"}
      </Typography.p>
    );
  }
  return (
    <Typography.p
      className={cn(s.link, s.black)}
      onClick={() =>
        store.position.setCustodianModal({
          visible: true,
          id: index + "",
          replace: false,
        })
      }
    >
      {custodian}
    </Typography.p>
  );
};

export default observer(CustodianCell);
