import {
  PageDTOPortfolioDataV3Dto,
  PageDTOWorkgroupWithDataV3Dto,
  PortfolioAndResourceGroupsWorkgroupsApi,
  PortfolioDataV3Dto,
  WorkgroupWithPortfolioDataV3Dto,
} from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../../constants/constants";
import { usePaginator } from "../../hooks/usePaginator";
import { AxiosResponse } from "axios";

interface IProps {
  externalAccountId: string;
  name: string;
  page?: number;
  size?: number;
  workgroupType?: "CLIENT" | "INTERNAL" | "GLOBAL" | "MODEL" | undefined;
  portfolioName?: string;

  displayCurrency?: string;
}

export const useWorkgroupSummary = ({
  externalAccountId,
  name,
  portfolioName,
}: IProps) => {
  const api = new PortfolioAndResourceGroupsWorkgroupsApi(
    undefined,
    `${API_BASE}/api`
  );

  return useQuery(
    [`workgroup-summary`, { externalAccountId, portfolioName, name }],
    () => api.getWorkgroupDataByName(externalAccountId, name),
    { enabled: !!externalAccountId && !!name }
  );
};
