import { CommonHeader } from "../Components/CommonHeader";
interface IProps {
  inceptionDate: Date;
  from: Date | null;
  setFrom: (from: Date | null) => void;
  to: Date | null;
  setTo: (to: Date | null) => void;
  settings: Record<string, any>;
  updateSettings: (newSettings: Record<string, any>) => void;
}
export const StandardReport = ({ ...props }: IProps) => {
  return <CommonHeader {...props} />;
};
