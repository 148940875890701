import * as React from "react";
import {
  CategoryAxisLabels,
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisLabels,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  SharedTooltipContext,
  TooltipContext,
} from "@progress/kendo-react-charts";
import { currencyFormatterV2, sumArray } from "@iliotech/data-wire";
import s from "./ColumnChartStacked.module.scss";
import { Empty } from "@iliotech/widgets";
import { stackedTooltipRenderer } from "../TooltipRenderer/TooltipRenderer";

import groupby from "lodash.groupby";
import { useToggableLegendV2 } from "@iliotech/data-wire";
import { ToggableChartLegend } from "../ToggableChartLegend/ToggableChartLegend";
import { IChartDataItemV2 } from "@iliotech/types";
import moment from "moment";

interface IProps {
  data?: IChartDataItemV2[];
  onSelectPosition?: (item: any) => void;
  valueFormatter?: (value: number) => string;
  hideLegend?: boolean;
  untoggableLegend?: boolean;
  maxBars?: number;
  withAnimations?: boolean;
  renderEmpty?: JSX.Element;
  tooltipRenderer?(
    context: TooltipContext | SharedTooltipContext
  ): React.ReactNode;
  tooltipPrefix?: string;
  labelsProps?: CategoryAxisLabels;
}

export const ColumnChartStacked = ({
  data = [],
  valueFormatter = (value: number) => currencyFormatterV2(value || 0, 0, 0),
  onSelectPosition,
  hideLegend,
  maxBars,
  withAnimations = false,
  untoggableLegend,
  tooltipRenderer,
  renderEmpty,
  tooltipPrefix,
  labelsProps,
}: IProps) => {
  const { legend, onLegendItemClick, toggledData } = useToggableLegendV2({
    valueFormatter,
    chartData: data,
    toggableTotal: false,
  });
  const legendWithoutZeroValues = legend.filter((item) => item?.value !== "0");

  const sortData = (item: IChartDataItemV2[]) =>
    // @ts-ignore
    item.sort(
      (a, b) =>
        moment(a.name, "MMM 'YY").valueOf?.() -
        moment(b.name, "MMM 'YY").valueOf?.()
    );

  const groupedDataByCategory: IChartDataItemV2[][] = React.useMemo(
    () =>
      Object.values(
        groupby(toggledData, (item: IChartDataItemV2) => item?.category)
      ),
    [toggledData]
  );

  const groupedDataByCategoryAndName: IChartDataItemV2[][] =
    React.useMemo(() => {
      return (groupedDataByCategory || []).map((arr: IChartDataItemV2[]) =>
        Object.values(groupby(arr, (item: IChartDataItemV2) => item?.name)).map(
          sumArray as any
        )
      );
    }, [groupedDataByCategory]);

  const chartData = React.useMemo(
    () => sortData(groupedDataByCategoryAndName.flat()),
    [groupedDataByCategoryAndName]
  );

  const longestGroup: number = React.useMemo(() => {
    return Object.values(
      groupby(toggledData, (item: IChartDataItemV2) => item?.name)
    ).length;
  }, [toggledData]);

  const minValue = React.useMemo(() => {
    const minimumValue = (data ? [...data] : []).sort(
      (a, b) => a?.value - b?.value
    )?.[0]?.value;

    return minimumValue > 0 ? 0 : minimumValue;
  }, [data]);

  const barLimits = maxBars || 40;

  if (!data?.length) {
    return renderEmpty ? renderEmpty : <Empty />;
  }
  return (
    <div>
      <Chart
        onSeriesClick={(e) => {
          onSelectPosition?.({
            ...e,
          });
        }}
        className={s.chartContainer}
        transitions={withAnimations}
      >
        <ChartTooltip
          opacity={0.9}
          background={"var(--dark)"}
          shared={true}
          render={
            tooltipRenderer ||
            ((context) =>
              stackedTooltipRenderer(
                context,
                valueFormatter,
                data,
                tooltipPrefix
              ))
          }
        />
        <ChartArea background={"transparent"} />
        <ChartValueAxis>
          <ChartValueAxisItem
            axisCrossingValue={minValue}
            background={"transparent"}
            majorGridLines={{
              color: "var(--muted-border-color)",
              width: 1,
              skip: 1,
            }}
            minorGridLines={{ visible: false }}
          >
            <ChartCategoryAxisLabels
              color={"var(--color)"}
              content={(e) => valueFormatter(e.value)}
            />
          </ChartValueAxisItem>
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            majorGridLines={{
              color: "var(--muted-border-color)",
              width: 1,
            }}
            labels={{
              padding: 10,
              rotation: -45,
              color: "var(--color)",
              position: "start",
              content: (e) =>
                e.value?.length > 22 ? e.value.substr(0, 22) + "..." : e.value,
              ...labelsProps,
            }}
            max={longestGroup < barLimits ? longestGroup : barLimits}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          {chartData.map((item, i) => (
            <ChartSeriesItem
              key={`${i}-${item.id}`}
              border={{
                width: 0.1,
              }}
              negativeValues={{ visible: true, color: "magenta" }}
              stack={true}
              width={0.1}
              data={[item]}
              categoryField="name"
            />
          ))}
        </ChartSeries>
      </Chart>
      {!hideLegend && (
        <ToggableChartLegend
          legend={legendWithoutZeroValues}
          onItemPress={!untoggableLegend ? onLegendItemClick : () => {}}
        />
      )}
    </div>
  );
};
