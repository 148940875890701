import { useWhatIfPositions } from "@iliotech/data-wire/src/stores";
import { observer } from "mobx-react-lite";
import { WhatIfResultsCellProps } from "../WhatIfResultsColumn";
import { getComparisonValue } from "./utils";

export const WhatIfScenarioCell = ({ dataItem }: WhatIfResultsCellProps) => {
  return <div>{getComparisonValue(dataItem?.comparisonValue)}</div>;
};

export default WhatIfScenarioCell;
