import { useEffect } from "react";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
const gaId =
  process.env.VITE_GA_TRACKER_ID! ||
  (window.environmentSettings.gaTrackerId! as string);

if (gaId && !ReactGA.isInitialized) {
  ReactGA.initialize(gaId);
}

export const usePageTracking = () => {
  let location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (gaId) {
        ReactGA.send({
          hitType: "pageview",
          path: ` ${location.pathname}${location.search}`,
          title: document.title,
        });
      }
    }, 250);
  }, [location]);
};
