import React, { PropsWithChildren, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import type { GridProps } from "@progress/kendo-react-grid";
import cn from "classnames";
import s from "./IllioTable.module.scss";

import {
  ColumnChooserProps,
  EnabledColumnMap,
  IActionColumn,
  IColumn,
} from "./types";
import { IllioColumnChooser } from "./IllioColumnChooser";
import * as ColumnSets from "./ColumnSets/ColumnSets";
import { useTranslation } from "react-i18next";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import * as Cells from "./Cells/Cells";
import { useWindowSize } from "react-use";
import { BREAKPOINTS } from "@iliotech/data-wire";
import { Typography } from "@progress/kendo-react-common";
import { DropDownMenu } from "../DropDownMenu/DropDownMenu";
import { GoKebabHorizontal } from "react-icons/go";
import TableLoading from "./TableLoading";

interface IOwnProps<T> {
  gridProps?: GridProps;
  columns: IColumn<T>[];
  data: T[];
  initialSort?: SortDescriptor[];
  sortable?: boolean;
  hideHeader?: boolean;
  useColumnChooser?:
    | boolean
    | {
        enabled: boolean;
        showButton: boolean;
      };
  isPageable?: boolean;
  pageSize?: number;
  onRowClick?: GridProps["onRowClick"];
  baseCurrency?: string;
  actionColumns?: IActionColumn<T>[];
  loading?: boolean;
  shouldResetPaging?: boolean;
  setShouldResetPaging?: (value: boolean) => void;
  enforceSort?:
    | SortDescriptor[]
    | ((sorts?: SortDescriptor[]) => SortDescriptor[] | undefined);
  dataCyPrefix?: string;
}

export const IllioTable = <T,>({
  useColumnChooser = true,
  isPageable = true,
  columns,
  pageSize,
  onRowClick,
  baseCurrency = "£",
  columnSettings: enabledColumnsIn,
  setColumnSettings: setEnabledColumnsIn,
  sortable = true,
  setShouldResetPaging = () => {},
  shouldResetPaging = false,
  userPreferencesTableId,
  dataCyPrefix,
  loading,
  ...props
}: PropsWithChildren<IOwnProps<T>> & Partial<ColumnChooserProps>) => {
  const defaultColumns = React.useMemo(() => {
    const obj: EnabledColumnMap = {};
    columns?.forEach((column) => {
      if (column.isDefault) {
        obj[column.field] = {
          enabled: true,
          order: column.order ?? 1000,
        };
      }
    });
    return obj;
  }, [columns]);
  const [enabledColumns, setEnabledColumns] =
    React.useState<EnabledColumnMap>(defaultColumns);

  const hideColumnChooserButton =
    typeof useColumnChooser === "object" && !useColumnChooser.showButton;

  return (
    <div style={{ position: "relative" }}>
      {useColumnChooser && (
        <IllioColumnChooser
          columns={columns}
          columnSettings={enabledColumnsIn || enabledColumns}
          baseCurrency={baseCurrency || "£"}
          setColumnSettings={setEnabledColumnsIn || setEnabledColumns}
          userPreferencesTableId={userPreferencesTableId}
          hideButton={hideColumnChooserButton}
        />
      )}
      {loading ? (
        <TableLoading />
      ) : (
        <TableInner
          columns={columns}
          columnSettings={enabledColumnsIn || enabledColumns}
          isPageable={isPageable}
          shouldResetPaging={shouldResetPaging}
          setShouldResetPaging={setShouldResetPaging}
          setColumnSettings={setEnabledColumnsIn || setEnabledColumns}
          pageSize={pageSize}
          onRowClick={onRowClick}
          baseCurrency={baseCurrency}
          sortable={sortable}
          {...props}
        />
      )}
    </div>
  );
};

const TableInner = <T,>({
  data,
  columns,
  gridProps,
  onRowClick,
  pageSize = 0,
  columnSettings = {},
  actionColumns,
  hideHeader,
  children,
  isPageable,
  initialSort = [],
  enforceSort,
  baseCurrency,
  loading,
  sortable,
  setShouldResetPaging = () => {},
  shouldResetPaging = false,
}: PropsWithChildren<IOwnProps<T> & ColumnChooserProps>) => {
  const enabledColumns = Object.fromEntries(
    Object.entries(columnSettings).filter(
      ([field, settings]) => settings.enabled
    )
  );

  const [paging, setPaging] = React.useState({
    skip: 0,
    take: pageSize !== 0 ? pageSize : data?.length,
  });
  const [renderKey, setRenderKey] = React.useState(0);
  const [sort, setSort] = React.useState<SortDescriptor[] | undefined>(
    initialSort
  );
  const { t } = useTranslation();

  const pageChange = (event: GridPageChangeEvent) => {
    setPaging({
      ...event.page,
      take: pageSize !== 0 ? pageSize : data?.length,
    });
  };

  const enabledColumnsCount = React.useMemo(
    () => Object.values(enabledColumns).filter((item) => item?.enabled).length,
    [enabledColumns]
  );

  useEffect(() => {
    const listener = () => {
      setRenderKey((prev) => prev + 1);
    };
    window?.addEventListener("resize", listener);

    return () => {
      window?.removeEventListener("resize", listener);
    };
  }, []);

  React.useEffect(() => {
    setRenderKey((prev) => prev + 1);
  }, [enabledColumnsCount]);

  useEffect(() => {
    //reset paging when data changes
    setPaging({
      skip: 0,
      take: pageSize !== 0 ? pageSize : data?.length,
    });
  }, [data?.length]);

  useEffect(() => {
    if (shouldResetPaging) {
      setPaging({
        skip: 0,
        take: pageSize !== 0 ? pageSize : data?.length,
      });
      setShouldResetPaging(false);
    }
  }, [shouldResetPaging, pageSize, data?.length, setShouldResetPaging]);

  const dimensions = useWindowSize();
  const isMobile = dimensions.width < BREAKPOINTS.SMALL;
  const maxColumns = isMobile ? 6 : 12;
  const titleRenderer = (title: string) => {
    return t(title.replace("£", baseCurrency || "£"));
  };

  const maybeEnforcedSort = Array.isArray(enforceSort)
    ? enforceSort
    : !enforceSort
    ? []
    : enforceSort(sort) ?? [];
  const sorts = [...maybeEnforcedSort, ...(sort ?? [])];
  let sortedData = orderBy(data, sorts); //sorts.reduce((acc, curr) => orderBy(acc, curr), [...data])
  const tableData = !!pageSize
    ? (sortedData || []).slice(paging.skip, paging.skip + paging.take)
    : sortedData;

  if (!enabledColumnsCount) {
    return (
      <div className={cn(s.surround)}>
        No columns selected - please select one or more columns
      </div>
    );
  }

  const { h6: H6 } = Typography; // variable lookups inside jsx tags makes optimisation fail and rendering slower

  return (
    <div className={cn(s.surround, loading && s.loading)}>
      <Grid
        key={renderKey}
        data={tableData}
        className={cn(
          "grid-surround",
          s.gridSurround,
          hideHeader && s.hideHeader
        )}
        resizable={true}
        total={data?.length}
        pageable={
          isPageable && data && data.length > pageSize && pageSize > 0
            ? {
                buttonCount: Math.min(
                  isMobile ? 3 : 10,
                  Math.ceil(data.length / pageSize)
                ),
              }
            : undefined
        }
        onPageChange={pageChange}
        onDataStateChange={(e) => {
          setSort(e.dataState.sort);
          setPaging((prev) => ({ ...prev, skip: 0 }));
        }}
        sortable={sortable}
        rowRender={(
          trElement: React.ReactElement<HTMLTableRowElement>,
          rowProps
        ) => {
          const isClickable =
            typeof (onRowClick || gridProps?.onRowClick) === "function";
          const trProps: any = isClickable
            ? {
                style: { cursor: "pointer" },
                className: cn(trElement.props.className),
                "data-cy": `rowItem-${rowProps.dataItem?.key}`,
              }
            : {
                "data-cy": `rowItem-${rowProps.dataItem?.key}`,
              };
          return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
          );
        }}
        sort={sort}
        {...paging}
        {...gridProps}
        onRowClick={
          typeof onRowClick === "function" ? onRowClick : gridProps?.onRowClick
        }
      >
        {columns
          ?.filter((column) => enabledColumns[column.field])
          ?.sort(
            (a, b) =>
              (enabledColumns[a.field].order ?? (a?.order || 900)) -
              (enabledColumns[b.field].order ?? (b?.order || 900))
          )
          ?.map(
            (
              {
                field,
                title,
                width,
                renderer,
                rightAligned,
                headerClassName,
                centerAligned,
                ...args
              },
              i
            ) => (
              <GridColumn
                key={`${field}${i}`}
                resizable={true}
                {...args}
                field={field}
                title={titleRenderer(title ?? field).toUpperCase()}
                width={
                  width ? width : isMobile ? 150 : undefined // LI: Not sure the merits of fixing to 100 width... seems to be better to use normal table width calculations by default?
                }
                cell={renderer}
                // TODO: Support fixedDecimal class to indent right aligned column headers
                headerClassName={cn(
                  s.columnHeader,
                  headerClassName,
                  rightAligned && s.rightAligned,
                  centerAligned && s.centerAligned
                )}
              />
            )
          )}

        {actionColumns?.length && (
          <GridColumn
            title={"ACTIONS"}
            width={75}
            cell={(renderer) => {
              const renderDropdown =
                actionColumns.filter((i) => !i?.disabled?.(renderer.dataItem))
                  .length > 0;

              return !renderDropdown ? null : (
                <DropDownMenu
                  data={actionColumns.filter(
                    (i) => !i?.disabled?.(renderer.dataItem)
                  )}
                  textField={"text"}
                  dataItemKey={"text"}
                  onSelect={(e) => {
                    e.value?.action?.(renderer?.dataItem!);
                  }}
                  extraClass={s.menuItem}
                  button={
                    <div className={s.buttonSurround}>
                      <GoKebabHorizontal color={"var(--muted-color)"} />
                    </div>
                  }
                />
              );
            }}
            headerClassName={s.columnHeader}
          />
        )}
        {children}
      </Grid>
    </div>
  );
};

IllioTable.DecimalCell = Cells.DecimalCell;
IllioTable.PercentCell = Cells.PercentCell;
IllioTable.DateCell = Cells.DateCell;
IllioTable.NameCell = Cells.NameCell;
IllioTable.TextCell = Cells.TextCell;
IllioTable.NumberCell = Cells.NumberCell;
IllioTable.ColumnSets = ColumnSets;
