import React, { PropsWithChildren, useEffect } from "react";
import { currencyFormatterV2 } from "@iliotech/data-wire";

interface IGlobalCurrencyContext {
  currency: string | undefined;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  setBaseCurrency: React.Dispatch<React.SetStateAction<string>>;
  currencyFormatter: (s?: number | undefined, decimal?: number) => string;
  baseCurrencyFormatter: (value: number, decimal: number) => string;
}

const noop = () => {
  console.log("GlobalPeriodContext not yet initialised");
};

const noopString = (s?: number, d?: number) => {
  return "";
};

const GlobalCurrencyContext = React.createContext<IGlobalCurrencyContext>({
  currency: "€",
  setCurrency: noop,
  setBaseCurrency: noop,
  currencyFormatter: (s) => noopString(s),
  baseCurrencyFormatter: (s, d) => noopString(s, d),
});

export const GlobalCurrencyProvider = ({ children }: PropsWithChildren<{}>) => {
  const [baseCurrency, setBaseCurrency] = React.useState<string>("");
  const [currency, setCurrency] = React.useState<string>(baseCurrency);

  console.log({ currency });
  const currencyFormatter = React.useMemo(
    () => (value: number | undefined, decimal?: number) => {
      return (
        (currency === "All" ? baseCurrency : currency) +
        " " +
        currencyFormatterV2(value || 0, decimal || 0, 0)
      );
    },
    [currency]
  );

  const baseCurrencyFormatter = React.useMemo(
    () => (value: number, decimal?: number) =>
      baseCurrency + " " + currencyFormatterV2(value, decimal || 0, 0),
    [baseCurrency]
  );

  return (
    <GlobalCurrencyContext.Provider
      value={{
        currency,
        setCurrency,
        currencyFormatter,
        baseCurrencyFormatter,
        setBaseCurrency,
      }}
    >
      {children}
    </GlobalCurrencyContext.Provider>
  );
};

export const useGlobalCurrency = () => React.useContext(GlobalCurrencyContext);
