/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BrokerInstituteDto
 */
export interface BrokerInstituteDto {
    /**
     * 
     * @type {number}
     * @memberof BrokerInstituteDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BrokerInstituteDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BrokerInstituteDto
     */
    'brokerName': BrokerInstituteDtoBrokerNameEnum;
    /**
     * 
     * @type {string}
     * @memberof BrokerInstituteDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof BrokerInstituteDto
     */
    'illioRating'?: BrokerInstituteDtoIllioRatingEnum;
    /**
     * 
     * @type {string}
     * @memberof BrokerInstituteDto
     */
    'connectionStatus'?: BrokerInstituteDtoConnectionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BrokerInstituteDto
     */
    'dataStatus'?: BrokerInstituteDtoDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BrokerInstituteDto
     */
    'brokerProviderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerInstituteDto
     */
    'icon'?: string;
    /**
     * 
     * @type {number}
     * @memberof BrokerInstituteDto
     */
    'order'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrokerInstituteDto
     */
    'popular': boolean;
    /**
     * 
     * @type {string}
     * @memberof BrokerInstituteDto
     */
    'notes'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BrokerInstituteDtoBrokerNameEnum {
    Ib = 'IB',
    Plaid = 'PLAID',
    Vezgo = 'VEZGO',
    Q9 = 'Q9',
    Illio = 'ILLIO'
}
/**
    * @export
    * @enum {string}
    */
export enum BrokerInstituteDtoIllioRatingEnum {
    Green = 'GREEN',
    Amber = 'AMBER',
    Red = 'RED'
}
/**
    * @export
    * @enum {string}
    */
export enum BrokerInstituteDtoConnectionStatusEnum {
    Green = 'GREEN',
    Amber = 'AMBER',
    Red = 'RED'
}
/**
    * @export
    * @enum {string}
    */
export enum BrokerInstituteDtoDataStatusEnum {
    Green = 'GREEN',
    Amber = 'AMBER',
    Red = 'RED'
}


