import {
  useAuth,
  usePortfoliosSummary,
  useReferenceData,
  useWorkgroupsData,
} from "@iliotech/data-wire";
import React from "react";
import { AssignToClientModalWrapper } from "../WorkgroupList/wrappers/AssignToClientModalWrapper";
import { ManagerShareModalWrapper } from "../WorkgroupList/wrappers/ManagerShareModalWrapper";
import RenameModalWrapper from "../WorkgroupList/wrappers/RenameModalWrapper";
import { DeleteWorkgroupModalWrapper } from "../WorkgroupList/wrappers/DeleteWorkgroupModalWrapper";
import { BasicHeader, Blur, Loader } from "@iliotech/component-library";
import { PageSummary } from "./components/PageSummary/PageSummary";
import { ListHeader } from "./components/ListHeader/ListHeader";
import { PageList } from "./components/PageList/PageList";
import { WorkgroupWithDataV3Dto } from "@iliotech/generated-api-v3";
import { PaginatorReturnType } from "@iliotech/data-wire/src/reactHooks/hooks/usePaginator";
import { PageNavigator } from "./components/PageNavigator/PageNavigator";

const renderModals = (onComplete: () => void) => {
  return (
    <>
      <AssignToClientModalWrapper onComplete={onComplete} />
      <ManagerShareModalWrapper onComplete={onComplete} />
      <RenameModalWrapper onComplete={onComplete} />
      <DeleteWorkgroupModalWrapper onComplete={onComplete} />
    </>
  );
};
export const LandingPage = () => {
  const {
    logOut,
    illioSelectedAccount,
    availableAccounts,
    selectIllioAccount,
  } = useAuth();
  const [search, setSearch] = React.useState("");

  const valueFormatter = (v: number) =>
    "USD " +
    v?.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    });
  const hasMoreAccounts = availableAccounts?.length! > 1;
  const onSelectAccounts = () => {
    selectIllioAccount(null);
  };

  const { data, isFetching }: any = usePortfoliosSummary(
    illioSelectedAccount?.externalAccountId!
  );

  const workgroups: PaginatorReturnType<WorkgroupWithDataV3Dto> =
    useWorkgroupsData({
      externalAccountId: illioSelectedAccount?.externalAccountId!,
      workgroupName: search,
      size: 12,
    });

  return (
    <Blur>
      <div className="container">
        <BasicHeader
          marginTop={-10}
          logOut={logOut}
          hideLogo={!!hasMoreAccounts}
          onSelectAccount={hasMoreAccounts ? onSelectAccounts : undefined}
          title={illioSelectedAccount?.name!}
          showHelp={true}
          showThemeSwitcher
        />
        <PageNavigator />
        <PageSummary
          valueFormatter={valueFormatter}
          {...{ data: { ...data?.data, loading: isFetching } }}
        />
        <ListHeader onSearch={(v: string) => setSearch(v)} />
        <PageList valueFormatter={valueFormatter} {...workgroups} />
        {renderModals(() => workgroups.refetch())}
      </div>
    </Blur>
  );
};
