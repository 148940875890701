/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { AddPositionCouponRequest } from '../../aggregator';
// @ts-ignore
import { AddPositionDividendRequest } from '../../aggregator';
// @ts-ignore
import { AddPositionEarnRequest } from '../../aggregator';
// @ts-ignore
import { AddPositionSplitRequest } from '../../aggregator';
// @ts-ignore
import { AddPrivateEquityCashFlowRequest } from '../../aggregator';
// @ts-ignore
import { CreateEarnTrade } from '../../aggregator';
// @ts-ignore
import { CreateFXTrade } from '../../aggregator';
// @ts-ignore
import { CreateFixedIncomeTrade } from '../../aggregator';
// @ts-ignore
import { CreateFuturesTrade } from '../../aggregator';
// @ts-ignore
import { CreateMortgageTrade } from '../../aggregator';
// @ts-ignore
import { CreateOptionTrade } from '../../aggregator';
// @ts-ignore
import { CreatePositionRequest } from '../../aggregator';
// @ts-ignore
import { CreatePrivateEquityTrade } from '../../aggregator';
// @ts-ignore
import { CreateReduceMortgageTrade } from '../../aggregator';
// @ts-ignore
import { CreateReserveTrade } from '../../aggregator';
// @ts-ignore
import { CreateStandardTrade } from '../../aggregator';
// @ts-ignore
import { CreateSwapTrade } from '../../aggregator';
// @ts-ignore
import { EditTradeRequest } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { PageDTOTradeDtoV3 } from '../../aggregator';
// @ts-ignore
import { PortfolioEquityFundamentalsResponse } from '../../aggregator';
// @ts-ignore
import { PortfolioEventsResponseV3 } from '../../aggregator';
// @ts-ignore
import { PortfolioPositionsFixedIncomeResponse } from '../../aggregator';
// @ts-ignore
import { PortfolioPositionsPrivateEquityResponse } from '../../aggregator';
// @ts-ignore
import { PortfolioTradeInfoResponse } from '../../aggregator';
// @ts-ignore
import { PortfolioTradesIdsDto } from '../../aggregator';
// @ts-ignore
import { PositionDetailsV3 } from '../../aggregator';
// @ts-ignore
import { PositionInstrumentAggregation } from '../../aggregator';
// @ts-ignore
import { PositionSummaryDTO } from '../../aggregator';
// @ts-ignore
import { PositionTradeUploadAsyncResponse } from '../../aggregator';
// @ts-ignore
import { TradeActionMessage } from '../../aggregator';
// @ts-ignore
import { TradeActionMessageWithRequest } from '../../aggregator';
// @ts-ignore
import { UpdatePrivateEquityTrade } from '../../aggregator';
// @ts-ignore
import { UpdateTransitTrade } from '../../aggregator';
/**
 * PortfolioTradesApi - axios parameter creator
 * @export
 */
export const PortfolioTradesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Use for adding a cash flow from a private equity position
         * @param {AddPrivateEquityCashFlowRequest} addPrivateEquityCashFlowRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCashFlowForPrivateEquityPosition: async (addPrivateEquityCashFlowRequest: AddPrivateEquityCashFlowRequest, portfolioId: string, positionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addPrivateEquityCashFlowRequest' is not null or undefined
            assertParamExists('addCashFlowForPrivateEquityPosition', 'addPrivateEquityCashFlowRequest', addPrivateEquityCashFlowRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addCashFlowForPrivateEquityPosition', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('addCashFlowForPrivateEquityPosition', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/position/{positionId}/privateEquityCashFlow`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPrivateEquityCashFlowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for adding a coupon from a position
         * @param {AddPositionCouponRequest} addPositionCouponRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCouponForPosition: async (addPositionCouponRequest: AddPositionCouponRequest, portfolioId: string, positionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addPositionCouponRequest' is not null or undefined
            assertParamExists('addCouponForPosition', 'addPositionCouponRequest', addPositionCouponRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addCouponForPosition', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('addCouponForPosition', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/position/{positionId}/coupon`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPositionCouponRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for adding a dividend from a position
         * @param {AddPositionDividendRequest} addPositionDividendRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDividendForPosition: async (addPositionDividendRequest: AddPositionDividendRequest, portfolioId: string, positionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addPositionDividendRequest' is not null or undefined
            assertParamExists('addDividendForPosition', 'addPositionDividendRequest', addPositionDividendRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addDividendForPosition', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('addDividendForPosition', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/position/{positionId}/dividend`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPositionDividendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for adding a crypto earn from a position
         * @param {AddPositionEarnRequest} addPositionEarnRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEarnForPosition: async (addPositionEarnRequest: AddPositionEarnRequest, portfolioId: string, positionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addPositionEarnRequest' is not null or undefined
            assertParamExists('addEarnForPosition', 'addPositionEarnRequest', addPositionEarnRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addEarnForPosition', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('addEarnForPosition', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/position/{positionId}/earn`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPositionEarnRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for cryptocurrency earn trades
         * @param {CreateEarnTrade} createEarnTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioCryptoEarnTrade: async (createEarnTrade: CreateEarnTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEarnTrade' is not null or undefined
            assertParamExists('addPortfolioCryptoEarnTrade', 'createEarnTrade', createEarnTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioCryptoEarnTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/cryptoEarn`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEarnTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for cryptocurrency wallet move trades
         * @param {CreateReserveTrade} createReserveTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioCryptoReserveTrade: async (createReserveTrade: CreateReserveTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReserveTrade' is not null or undefined
            assertParamExists('addPortfolioCryptoReserveTrade', 'createReserveTrade', createReserveTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioCryptoReserveTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/cryptoReserve`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReserveTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for cryptocurrency swap trades
         * @param {CreateSwapTrade} createSwapTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioCryptoSwapTrade: async (createSwapTrade: CreateSwapTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSwapTrade' is not null or undefined
            assertParamExists('addPortfolioCryptoSwapTrade', 'createSwapTrade', createSwapTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioCryptoSwapTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/cryptoSwap`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSwapTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for fx spot trades
         * @param {CreateFXTrade} createFXTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioFXTrade: async (createFXTrade: CreateFXTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFXTrade' is not null or undefined
            assertParamExists('addPortfolioFXTrade', 'createFXTrade', createFXTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioFXTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/fx`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFXTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for fixed income trades
         * @param {CreateFixedIncomeTrade} createFixedIncomeTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioFixedIncomeTrade: async (createFixedIncomeTrade: CreateFixedIncomeTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFixedIncomeTrade' is not null or undefined
            assertParamExists('addPortfolioFixedIncomeTrade', 'createFixedIncomeTrade', createFixedIncomeTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioFixedIncomeTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/fixedIncome`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFixedIncomeTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for futures trades
         * @param {CreateFuturesTrade} createFuturesTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioFuturesTrade: async (createFuturesTrade: CreateFuturesTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFuturesTrade' is not null or undefined
            assertParamExists('addPortfolioFuturesTrade', 'createFuturesTrade', createFuturesTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioFuturesTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/futures`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFuturesTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for mortgage reducing trades
         * @param {CreateReduceMortgageTrade} createReduceMortgageTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioMortgageReductionTrade: async (createReduceMortgageTrade: CreateReduceMortgageTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReduceMortgageTrade' is not null or undefined
            assertParamExists('addPortfolioMortgageReductionTrade', 'createReduceMortgageTrade', createReduceMortgageTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioMortgageReductionTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/mortgageReduce`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReduceMortgageTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for new mortgage trades
         * @param {CreateMortgageTrade} createMortgageTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioMortgageTrade: async (createMortgageTrade: CreateMortgageTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMortgageTrade' is not null or undefined
            assertParamExists('addPortfolioMortgageTrade', 'createMortgageTrade', createMortgageTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioMortgageTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/mortgage`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMortgageTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for option trades
         * @param {CreateOptionTrade} createOptionTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioOptionTrade: async (createOptionTrade: CreateOptionTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOptionTrade' is not null or undefined
            assertParamExists('addPortfolioOptionTrade', 'createOptionTrade', createOptionTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioOptionTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/option`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOptionTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePositionRequest} createPositionRequest 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioPosition: async (createPositionRequest: CreatePositionRequest, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPositionRequest' is not null or undefined
            assertParamExists('addPortfolioPosition', 'createPositionRequest', createPositionRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioPosition', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/position`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreatePositionRequest>} createPositionRequest 
         * @param {string} portfolioId 
         * @param {'AUTO' | 'BASE' | 'LOCAL'} [settlementOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioPositions: async (createPositionRequest: Array<CreatePositionRequest>, portfolioId: string, settlementOption?: 'AUTO' | 'BASE' | 'LOCAL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPositionRequest' is not null or undefined
            assertParamExists('addPortfolioPositions', 'createPositionRequest', createPositionRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioPositions', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/positions`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (settlementOption !== undefined) {
                localVarQueryParameter['settlementOption'] = settlementOption;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreatePositionRequest>} createPositionRequest 
         * @param {string} portfolioId 
         * @param {'REGULAR_TRADES' | 'POSITION_TRADES' | 'WHAT_IF_POSITIONS'} [positionDataSource] 
         * @param {boolean} [deleteExistingPositions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioPositionsAsync: async (createPositionRequest: Array<CreatePositionRequest>, portfolioId: string, positionDataSource?: 'REGULAR_TRADES' | 'POSITION_TRADES' | 'WHAT_IF_POSITIONS', deleteExistingPositions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPositionRequest' is not null or undefined
            assertParamExists('addPortfolioPositionsAsync', 'createPositionRequest', createPositionRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioPositionsAsync', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/positionsAsync`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (positionDataSource !== undefined) {
                localVarQueryParameter['positionDataSource'] = positionDataSource;
            }

            if (deleteExistingPositions !== undefined) {
                localVarQueryParameter['deleteExistingPositions'] = deleteExistingPositions;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for private equity trades
         * @param {CreatePrivateEquityTrade} createPrivateEquityTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioPrivateEquityTrade: async (createPrivateEquityTrade: CreatePrivateEquityTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPrivateEquityTrade' is not null or undefined
            assertParamExists('addPortfolioPrivateEquityTrade', 'createPrivateEquityTrade', createPrivateEquityTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioPrivateEquityTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/privateEquity`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPrivateEquityTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for equity, fund, etf, cryptocurrency, commodity, cash deposit and cash withdrawal trades
         * @param {CreateStandardTrade} createStandardTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioStandardTrade: async (createStandardTrade: CreateStandardTrade, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStandardTrade' is not null or undefined
            assertParamExists('addPortfolioStandardTrade', 'createStandardTrade', createStandardTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addPortfolioStandardTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStandardTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for adding a split to a position
         * @param {AddPositionSplitRequest} addPositionSplitRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSplitForPosition: async (addPositionSplitRequest: AddPositionSplitRequest, portfolioId: string, positionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addPositionSplitRequest' is not null or undefined
            assertParamExists('addSplitForPosition', 'addPositionSplitRequest', addPositionSplitRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addSplitForPosition', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('addSplitForPosition', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/position/{positionId}/split`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPositionSplitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for batch confirming transit trades
         * @param {PortfolioTradesIdsDto} portfolioTradesIdsDto 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPortfolioTransitTrades: async (portfolioTradesIdsDto: PortfolioTradesIdsDto, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioTradesIdsDto' is not null or undefined
            assertParamExists('confirmPortfolioTransitTrades', 'portfolioTradesIdsDto', portfolioTradesIdsDto)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('confirmPortfolioTransitTrades', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trades/confirm`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioTradesIdsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {number} tradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolioTrade: async (portfolioId: string, tradeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('deletePortfolioTrade', 'portfolioId', portfolioId)
            // verify required parameter 'tradeId' is not null or undefined
            assertParamExists('deletePortfolioTrade', 'tradeId', tradeId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/{tradeId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for batch deleting trades
         * @param {PortfolioTradesIdsDto} portfolioTradesIdsDto 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolioTrades: async (portfolioTradesIdsDto: PortfolioTradesIdsDto, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioTradesIdsDto' is not null or undefined
            assertParamExists('deletePortfolioTrades', 'portfolioTradesIdsDto', portfolioTradesIdsDto)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('deletePortfolioTrades', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trades/delete`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioTradesIdsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {number} transitTradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolioTransitTrade: async (portfolioId: string, transitTradeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('deletePortfolioTransitTrade', 'portfolioId', portfolioId)
            // verify required parameter 'transitTradeId' is not null or undefined
            assertParamExists('deletePortfolioTransitTrade', 'transitTradeId', transitTradeId)
            const localVarPath = `/v3/portfolio/{portfolioId}/transitTrade/{transitTradeId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"transitTradeId"}}`, encodeURIComponent(String(transitTradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditTradeRequest} editTradeRequest 
         * @param {string} portfolioId 
         * @param {number} tradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPortfolioTrade: async (editTradeRequest: EditTradeRequest, portfolioId: string, tradeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editTradeRequest' is not null or undefined
            assertParamExists('editPortfolioTrade', 'editTradeRequest', editTradeRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('editPortfolioTrade', 'portfolioId', portfolioId)
            // verify required parameter 'tradeId' is not null or undefined
            assertParamExists('editPortfolioTrade', 'tradeId', tradeId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/{tradeId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editTradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Equity Fundamentals
         * @param {string} portfolioId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioEquityFundamentals: async (portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioEquityFundamentals', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/equityFundamentals`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Bond/Fixed Income Positions
         * @param {string} portfolioId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
         * @param {string} [settlementDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioPositionsFixedIncome: async (portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', settlementDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioPositionsFixedIncome', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/positions/fixedIncome`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (settlementDate !== undefined) {
                localVarQueryParameter['settlementDate'] = (settlementDate as any instanceof Date) ?
                    (settlementDate as any).toISOString().substr(0,10) :
                    settlementDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Private Equity Positions
         * @param {string} portfolioId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioPositionsPrivateEquity: async (portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioPositionsPrivateEquity', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/positions/privateEquity`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Aggregated Portfolio Trade Information
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioTradeInfo: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioTradeInfo', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/tradeInfo`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioTrades1: async (portfolioId: string, from?: string, to?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioTrades1', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trades`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosition: async (portfolioId: string, positionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPosition', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('getPosition', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/position/{positionId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Events are aggregated for related instruments, eg. an amazon option position would include events for amazon, and other amazon options
         * @summary Get portfolio events aggregated for a position instrument and its related instruments
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionEventAggregations: async (portfolioId: string, positionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPositionEventAggregations', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('getPositionEventAggregations', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/positionEventsAggregation/{positionId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionInstrumentAggregation: async (portfolioId: string, positionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPositionInstrumentAggregation', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('getPositionInstrumentAggregation', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/positionInstrumentAggregation/{positionId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionSummary: async (portfolioId: string, positionId: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPositionSummary', 'portfolioId', portfolioId)
            // verify required parameter 'positionId' is not null or undefined
            assertParamExists('getPositionSummary', 'positionId', positionId)
            const localVarPath = `/v3/portfolio/{portfolioId}/position/{positionId}/summary`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"positionId"}}`, encodeURIComponent(String(positionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update private equity commitments trades
         * @param {UpdatePrivateEquityTrade} updatePrivateEquityTrade 
         * @param {number} tradeId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolioPrivateEquityTrade: async (updatePrivateEquityTrade: UpdatePrivateEquityTrade, tradeId: number, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePrivateEquityTrade' is not null or undefined
            assertParamExists('updatePortfolioPrivateEquityTrade', 'updatePrivateEquityTrade', updatePrivateEquityTrade)
            // verify required parameter 'tradeId' is not null or undefined
            assertParamExists('updatePortfolioPrivateEquityTrade', 'tradeId', tradeId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('updatePortfolioPrivateEquityTrade', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/trade/privateEquityCommitment/{tradeId}`
                .replace(`{${"tradeId"}}`, encodeURIComponent(String(tradeId)))
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrivateEquityTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use for updating 
         * @param {UpdateTransitTrade} updateTransitTrade 
         * @param {string} portfolioId 
         * @param {number} transitTradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolioTransitTrade: async (updateTransitTrade: UpdateTransitTrade, portfolioId: string, transitTradeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTransitTrade' is not null or undefined
            assertParamExists('updatePortfolioTransitTrade', 'updateTransitTrade', updateTransitTrade)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('updatePortfolioTransitTrade', 'portfolioId', portfolioId)
            // verify required parameter 'transitTradeId' is not null or undefined
            assertParamExists('updatePortfolioTransitTrade', 'transitTradeId', transitTradeId)
            const localVarPath = `/v3/portfolio/{portfolioId}/transitTrade/{transitTradeId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"transitTradeId"}}`, encodeURIComponent(String(transitTradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTransitTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioTradesApi - functional programming interface
 * @export
 */
export const PortfolioTradesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioTradesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Use for adding a cash flow from a private equity position
         * @param {AddPrivateEquityCashFlowRequest} addPrivateEquityCashFlowRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCashFlowForPrivateEquityPosition(addPrivateEquityCashFlowRequest: AddPrivateEquityCashFlowRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCashFlowForPrivateEquityPosition(addPrivateEquityCashFlowRequest, portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for adding a coupon from a position
         * @param {AddPositionCouponRequest} addPositionCouponRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCouponForPosition(addPositionCouponRequest: AddPositionCouponRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCouponForPosition(addPositionCouponRequest, portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for adding a dividend from a position
         * @param {AddPositionDividendRequest} addPositionDividendRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDividendForPosition(addPositionDividendRequest: AddPositionDividendRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDividendForPosition(addPositionDividendRequest, portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for adding a crypto earn from a position
         * @param {AddPositionEarnRequest} addPositionEarnRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEarnForPosition(addPositionEarnRequest: AddPositionEarnRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEarnForPosition(addPositionEarnRequest, portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for cryptocurrency earn trades
         * @param {CreateEarnTrade} createEarnTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioCryptoEarnTrade(createEarnTrade: CreateEarnTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioCryptoEarnTrade(createEarnTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for cryptocurrency wallet move trades
         * @param {CreateReserveTrade} createReserveTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioCryptoReserveTrade(createReserveTrade: CreateReserveTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioCryptoReserveTrade(createReserveTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for cryptocurrency swap trades
         * @param {CreateSwapTrade} createSwapTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioCryptoSwapTrade(createSwapTrade: CreateSwapTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioCryptoSwapTrade(createSwapTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for fx spot trades
         * @param {CreateFXTrade} createFXTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioFXTrade(createFXTrade: CreateFXTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioFXTrade(createFXTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for fixed income trades
         * @param {CreateFixedIncomeTrade} createFixedIncomeTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioFixedIncomeTrade(createFixedIncomeTrade: CreateFixedIncomeTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioFixedIncomeTrade(createFixedIncomeTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for futures trades
         * @param {CreateFuturesTrade} createFuturesTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioFuturesTrade(createFuturesTrade: CreateFuturesTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioFuturesTrade(createFuturesTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for mortgage reducing trades
         * @param {CreateReduceMortgageTrade} createReduceMortgageTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioMortgageReductionTrade(createReduceMortgageTrade: CreateReduceMortgageTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioMortgageReductionTrade(createReduceMortgageTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for new mortgage trades
         * @param {CreateMortgageTrade} createMortgageTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioMortgageTrade(createMortgageTrade: CreateMortgageTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioMortgageTrade(createMortgageTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for option trades
         * @param {CreateOptionTrade} createOptionTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioOptionTrade(createOptionTrade: CreateOptionTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioOptionTrade(createOptionTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePositionRequest} createPositionRequest 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioPosition(createPositionRequest: CreatePositionRequest, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioPosition(createPositionRequest, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreatePositionRequest>} createPositionRequest 
         * @param {string} portfolioId 
         * @param {'AUTO' | 'BASE' | 'LOCAL'} [settlementOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioPositions(createPositionRequest: Array<CreatePositionRequest>, portfolioId: string, settlementOption?: 'AUTO' | 'BASE' | 'LOCAL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeActionMessageWithRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioPositions(createPositionRequest, portfolioId, settlementOption, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreatePositionRequest>} createPositionRequest 
         * @param {string} portfolioId 
         * @param {'REGULAR_TRADES' | 'POSITION_TRADES' | 'WHAT_IF_POSITIONS'} [positionDataSource] 
         * @param {boolean} [deleteExistingPositions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioPositionsAsync(createPositionRequest: Array<CreatePositionRequest>, portfolioId: string, positionDataSource?: 'REGULAR_TRADES' | 'POSITION_TRADES' | 'WHAT_IF_POSITIONS', deleteExistingPositions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionTradeUploadAsyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioPositionsAsync(createPositionRequest, portfolioId, positionDataSource, deleteExistingPositions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for private equity trades
         * @param {CreatePrivateEquityTrade} createPrivateEquityTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioPrivateEquityTrade(createPrivateEquityTrade: CreatePrivateEquityTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioPrivateEquityTrade(createPrivateEquityTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for equity, fund, etf, cryptocurrency, commodity, cash deposit and cash withdrawal trades
         * @param {CreateStandardTrade} createStandardTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolioStandardTrade(createStandardTrade: CreateStandardTrade, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolioStandardTrade(createStandardTrade, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for adding a split to a position
         * @param {AddPositionSplitRequest} addPositionSplitRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSplitForPosition(addPositionSplitRequest: AddPositionSplitRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSplitForPosition(addPositionSplitRequest, portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for batch confirming transit trades
         * @param {PortfolioTradesIdsDto} portfolioTradesIdsDto 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmPortfolioTransitTrades(portfolioTradesIdsDto: PortfolioTradesIdsDto, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmPortfolioTransitTrades(portfolioTradesIdsDto, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {number} tradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePortfolioTrade(portfolioId: string, tradeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePortfolioTrade(portfolioId, tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for batch deleting trades
         * @param {PortfolioTradesIdsDto} portfolioTradesIdsDto 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePortfolioTrades(portfolioTradesIdsDto: PortfolioTradesIdsDto, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePortfolioTrades(portfolioTradesIdsDto, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {number} transitTradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePortfolioTransitTrade(portfolioId: string, transitTradeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePortfolioTransitTrade(portfolioId, transitTradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditTradeRequest} editTradeRequest 
         * @param {string} portfolioId 
         * @param {number} tradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPortfolioTrade(editTradeRequest: EditTradeRequest, portfolioId: string, tradeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editPortfolioTrade(editTradeRequest, portfolioId, tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Equity Fundamentals
         * @param {string} portfolioId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioEquityFundamentals(portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioEquityFundamentalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioEquityFundamentals(portfolioId, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Bond/Fixed Income Positions
         * @param {string} portfolioId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
         * @param {string} [settlementDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioPositionsFixedIncome(portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', settlementDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioPositionsFixedIncomeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioPositionsFixedIncome(portfolioId, currency, settlementDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Private Equity Positions
         * @param {string} portfolioId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioPositionsPrivateEquity(portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioPositionsPrivateEquityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioPositionsPrivateEquity(portfolioId, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Aggregated Portfolio Trade Information
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioTradeInfo(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioTradeInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioTradeInfo(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioTrades1(portfolioId: string, from?: string, to?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDTOTradeDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioTrades1(portfolioId, from, to, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPosition(portfolioId: string, positionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionDetailsV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPosition(portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Events are aggregated for related instruments, eg. an amazon option position would include events for amazon, and other amazon options
         * @summary Get portfolio events aggregated for a position instrument and its related instruments
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionEventAggregations(portfolioId: string, positionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioEventsResponseV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositionEventAggregations(portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionInstrumentAggregation(portfolioId: string, positionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionInstrumentAggregation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositionInstrumentAggregation(portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionSummary(portfolioId: string, positionId: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionSummaryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositionSummary(portfolioId, positionId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update private equity commitments trades
         * @param {UpdatePrivateEquityTrade} updatePrivateEquityTrade 
         * @param {number} tradeId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePortfolioPrivateEquityTrade(updatePrivateEquityTrade: UpdatePrivateEquityTrade, tradeId: number, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePortfolioPrivateEquityTrade(updatePrivateEquityTrade, tradeId, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use for updating 
         * @param {UpdateTransitTrade} updateTransitTrade 
         * @param {string} portfolioId 
         * @param {number} transitTradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePortfolioTransitTrade(updateTransitTrade: UpdateTransitTrade, portfolioId: string, transitTradeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeActionMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePortfolioTransitTrade(updateTransitTrade, portfolioId, transitTradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioTradesApi - factory interface
 * @export
 */
export const PortfolioTradesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioTradesApiFp(configuration)
    return {
        /**
         * 
         * @summary Use for adding a cash flow from a private equity position
         * @param {AddPrivateEquityCashFlowRequest} addPrivateEquityCashFlowRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCashFlowForPrivateEquityPosition(addPrivateEquityCashFlowRequest: AddPrivateEquityCashFlowRequest, portfolioId: string, positionId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addCashFlowForPrivateEquityPosition(addPrivateEquityCashFlowRequest, portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for adding a coupon from a position
         * @param {AddPositionCouponRequest} addPositionCouponRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCouponForPosition(addPositionCouponRequest: AddPositionCouponRequest, portfolioId: string, positionId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addCouponForPosition(addPositionCouponRequest, portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for adding a dividend from a position
         * @param {AddPositionDividendRequest} addPositionDividendRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDividendForPosition(addPositionDividendRequest: AddPositionDividendRequest, portfolioId: string, positionId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addDividendForPosition(addPositionDividendRequest, portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for adding a crypto earn from a position
         * @param {AddPositionEarnRequest} addPositionEarnRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEarnForPosition(addPositionEarnRequest: AddPositionEarnRequest, portfolioId: string, positionId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addEarnForPosition(addPositionEarnRequest, portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for cryptocurrency earn trades
         * @param {CreateEarnTrade} createEarnTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioCryptoEarnTrade(createEarnTrade: CreateEarnTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioCryptoEarnTrade(createEarnTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for cryptocurrency wallet move trades
         * @param {CreateReserveTrade} createReserveTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioCryptoReserveTrade(createReserveTrade: CreateReserveTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioCryptoReserveTrade(createReserveTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for cryptocurrency swap trades
         * @param {CreateSwapTrade} createSwapTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioCryptoSwapTrade(createSwapTrade: CreateSwapTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioCryptoSwapTrade(createSwapTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for fx spot trades
         * @param {CreateFXTrade} createFXTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioFXTrade(createFXTrade: CreateFXTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioFXTrade(createFXTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for fixed income trades
         * @param {CreateFixedIncomeTrade} createFixedIncomeTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioFixedIncomeTrade(createFixedIncomeTrade: CreateFixedIncomeTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioFixedIncomeTrade(createFixedIncomeTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for futures trades
         * @param {CreateFuturesTrade} createFuturesTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioFuturesTrade(createFuturesTrade: CreateFuturesTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioFuturesTrade(createFuturesTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for mortgage reducing trades
         * @param {CreateReduceMortgageTrade} createReduceMortgageTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioMortgageReductionTrade(createReduceMortgageTrade: CreateReduceMortgageTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioMortgageReductionTrade(createReduceMortgageTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for new mortgage trades
         * @param {CreateMortgageTrade} createMortgageTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioMortgageTrade(createMortgageTrade: CreateMortgageTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioMortgageTrade(createMortgageTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for option trades
         * @param {CreateOptionTrade} createOptionTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioOptionTrade(createOptionTrade: CreateOptionTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioOptionTrade(createOptionTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePositionRequest} createPositionRequest 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioPosition(createPositionRequest: CreatePositionRequest, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioPosition(createPositionRequest, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreatePositionRequest>} createPositionRequest 
         * @param {string} portfolioId 
         * @param {'AUTO' | 'BASE' | 'LOCAL'} [settlementOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioPositions(createPositionRequest: Array<CreatePositionRequest>, portfolioId: string, settlementOption?: 'AUTO' | 'BASE' | 'LOCAL', options?: any): AxiosPromise<Array<TradeActionMessageWithRequest>> {
            return localVarFp.addPortfolioPositions(createPositionRequest, portfolioId, settlementOption, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreatePositionRequest>} createPositionRequest 
         * @param {string} portfolioId 
         * @param {'REGULAR_TRADES' | 'POSITION_TRADES' | 'WHAT_IF_POSITIONS'} [positionDataSource] 
         * @param {boolean} [deleteExistingPositions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioPositionsAsync(createPositionRequest: Array<CreatePositionRequest>, portfolioId: string, positionDataSource?: 'REGULAR_TRADES' | 'POSITION_TRADES' | 'WHAT_IF_POSITIONS', deleteExistingPositions?: boolean, options?: any): AxiosPromise<PositionTradeUploadAsyncResponse> {
            return localVarFp.addPortfolioPositionsAsync(createPositionRequest, portfolioId, positionDataSource, deleteExistingPositions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for private equity trades
         * @param {CreatePrivateEquityTrade} createPrivateEquityTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioPrivateEquityTrade(createPrivateEquityTrade: CreatePrivateEquityTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioPrivateEquityTrade(createPrivateEquityTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for equity, fund, etf, cryptocurrency, commodity, cash deposit and cash withdrawal trades
         * @param {CreateStandardTrade} createStandardTrade 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolioStandardTrade(createStandardTrade: CreateStandardTrade, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addPortfolioStandardTrade(createStandardTrade, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for adding a split to a position
         * @param {AddPositionSplitRequest} addPositionSplitRequest 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSplitForPosition(addPositionSplitRequest: AddPositionSplitRequest, portfolioId: string, positionId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.addSplitForPosition(addPositionSplitRequest, portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for batch confirming transit trades
         * @param {PortfolioTradesIdsDto} portfolioTradesIdsDto 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPortfolioTransitTrades(portfolioTradesIdsDto: PortfolioTradesIdsDto, portfolioId: string, options?: any): AxiosPromise<void> {
            return localVarFp.confirmPortfolioTransitTrades(portfolioTradesIdsDto, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {number} tradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolioTrade(portfolioId: string, tradeId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePortfolioTrade(portfolioId, tradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for batch deleting trades
         * @param {PortfolioTradesIdsDto} portfolioTradesIdsDto 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolioTrades(portfolioTradesIdsDto: PortfolioTradesIdsDto, portfolioId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePortfolioTrades(portfolioTradesIdsDto, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {number} transitTradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolioTransitTrade(portfolioId: string, transitTradeId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePortfolioTransitTrade(portfolioId, transitTradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditTradeRequest} editTradeRequest 
         * @param {string} portfolioId 
         * @param {number} tradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPortfolioTrade(editTradeRequest: EditTradeRequest, portfolioId: string, tradeId: number, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.editPortfolioTrade(editTradeRequest, portfolioId, tradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Equity Fundamentals
         * @param {string} portfolioId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioEquityFundamentals(portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: any): AxiosPromise<PortfolioEquityFundamentalsResponse> {
            return localVarFp.getPortfolioEquityFundamentals(portfolioId, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Bond/Fixed Income Positions
         * @param {string} portfolioId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
         * @param {string} [settlementDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioPositionsFixedIncome(portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', settlementDate?: string, options?: any): AxiosPromise<PortfolioPositionsFixedIncomeResponse> {
            return localVarFp.getPortfolioPositionsFixedIncome(portfolioId, currency, settlementDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Private Equity Positions
         * @param {string} portfolioId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioPositionsPrivateEquity(portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: any): AxiosPromise<PortfolioPositionsPrivateEquityResponse> {
            return localVarFp.getPortfolioPositionsPrivateEquity(portfolioId, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Aggregated Portfolio Trade Information
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioTradeInfo(portfolioId: string, options?: any): AxiosPromise<PortfolioTradeInfoResponse> {
            return localVarFp.getPortfolioTradeInfo(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioTrades1(portfolioId: string, from?: string, to?: string, page?: number, size?: number, options?: any): AxiosPromise<PageDTOTradeDtoV3> {
            return localVarFp.getPortfolioTrades1(portfolioId, from, to, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosition(portfolioId: string, positionId: string, options?: any): AxiosPromise<PositionDetailsV3> {
            return localVarFp.getPosition(portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Events are aggregated for related instruments, eg. an amazon option position would include events for amazon, and other amazon options
         * @summary Get portfolio events aggregated for a position instrument and its related instruments
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionEventAggregations(portfolioId: string, positionId: string, options?: any): AxiosPromise<PortfolioEventsResponseV3> {
            return localVarFp.getPositionEventAggregations(portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionInstrumentAggregation(portfolioId: string, positionId: string, options?: any): AxiosPromise<PositionInstrumentAggregation> {
            return localVarFp.getPositionInstrumentAggregation(portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} positionId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionSummary(portfolioId: string, positionId: string, date?: string, options?: any): AxiosPromise<PositionSummaryDTO> {
            return localVarFp.getPositionSummary(portfolioId, positionId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update private equity commitments trades
         * @param {UpdatePrivateEquityTrade} updatePrivateEquityTrade 
         * @param {number} tradeId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolioPrivateEquityTrade(updatePrivateEquityTrade: UpdatePrivateEquityTrade, tradeId: number, portfolioId: string, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.updatePortfolioPrivateEquityTrade(updatePrivateEquityTrade, tradeId, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use for updating 
         * @param {UpdateTransitTrade} updateTransitTrade 
         * @param {string} portfolioId 
         * @param {number} transitTradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolioTransitTrade(updateTransitTrade: UpdateTransitTrade, portfolioId: string, transitTradeId: number, options?: any): AxiosPromise<TradeActionMessage> {
            return localVarFp.updatePortfolioTransitTrade(updateTransitTrade, portfolioId, transitTradeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioTradesApi - object-oriented interface
 * @export
 * @class PortfolioTradesApi
 * @extends {BaseAPI}
 */
export class PortfolioTradesApi extends BaseAPI {
    /**
     * 
     * @summary Use for adding a cash flow from a private equity position
     * @param {AddPrivateEquityCashFlowRequest} addPrivateEquityCashFlowRequest 
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addCashFlowForPrivateEquityPosition(addPrivateEquityCashFlowRequest: AddPrivateEquityCashFlowRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addCashFlowForPrivateEquityPosition(addPrivateEquityCashFlowRequest, portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for adding a coupon from a position
     * @param {AddPositionCouponRequest} addPositionCouponRequest 
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addCouponForPosition(addPositionCouponRequest: AddPositionCouponRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addCouponForPosition(addPositionCouponRequest, portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for adding a dividend from a position
     * @param {AddPositionDividendRequest} addPositionDividendRequest 
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addDividendForPosition(addPositionDividendRequest: AddPositionDividendRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addDividendForPosition(addPositionDividendRequest, portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for adding a crypto earn from a position
     * @param {AddPositionEarnRequest} addPositionEarnRequest 
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addEarnForPosition(addPositionEarnRequest: AddPositionEarnRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addEarnForPosition(addPositionEarnRequest, portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for cryptocurrency earn trades
     * @param {CreateEarnTrade} createEarnTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioCryptoEarnTrade(createEarnTrade: CreateEarnTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioCryptoEarnTrade(createEarnTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for cryptocurrency wallet move trades
     * @param {CreateReserveTrade} createReserveTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioCryptoReserveTrade(createReserveTrade: CreateReserveTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioCryptoReserveTrade(createReserveTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for cryptocurrency swap trades
     * @param {CreateSwapTrade} createSwapTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioCryptoSwapTrade(createSwapTrade: CreateSwapTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioCryptoSwapTrade(createSwapTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for fx spot trades
     * @param {CreateFXTrade} createFXTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioFXTrade(createFXTrade: CreateFXTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioFXTrade(createFXTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for fixed income trades
     * @param {CreateFixedIncomeTrade} createFixedIncomeTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioFixedIncomeTrade(createFixedIncomeTrade: CreateFixedIncomeTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioFixedIncomeTrade(createFixedIncomeTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for futures trades
     * @param {CreateFuturesTrade} createFuturesTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioFuturesTrade(createFuturesTrade: CreateFuturesTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioFuturesTrade(createFuturesTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for mortgage reducing trades
     * @param {CreateReduceMortgageTrade} createReduceMortgageTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioMortgageReductionTrade(createReduceMortgageTrade: CreateReduceMortgageTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioMortgageReductionTrade(createReduceMortgageTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for new mortgage trades
     * @param {CreateMortgageTrade} createMortgageTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioMortgageTrade(createMortgageTrade: CreateMortgageTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioMortgageTrade(createMortgageTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for option trades
     * @param {CreateOptionTrade} createOptionTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioOptionTrade(createOptionTrade: CreateOptionTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioOptionTrade(createOptionTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePositionRequest} createPositionRequest 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioPosition(createPositionRequest: CreatePositionRequest, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioPosition(createPositionRequest, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreatePositionRequest>} createPositionRequest 
     * @param {string} portfolioId 
     * @param {'AUTO' | 'BASE' | 'LOCAL'} [settlementOption] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioPositions(createPositionRequest: Array<CreatePositionRequest>, portfolioId: string, settlementOption?: 'AUTO' | 'BASE' | 'LOCAL', options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioPositions(createPositionRequest, portfolioId, settlementOption, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreatePositionRequest>} createPositionRequest 
     * @param {string} portfolioId 
     * @param {'REGULAR_TRADES' | 'POSITION_TRADES' | 'WHAT_IF_POSITIONS'} [positionDataSource] 
     * @param {boolean} [deleteExistingPositions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioPositionsAsync(createPositionRequest: Array<CreatePositionRequest>, portfolioId: string, positionDataSource?: 'REGULAR_TRADES' | 'POSITION_TRADES' | 'WHAT_IF_POSITIONS', deleteExistingPositions?: boolean, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioPositionsAsync(createPositionRequest, portfolioId, positionDataSource, deleteExistingPositions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for private equity trades
     * @param {CreatePrivateEquityTrade} createPrivateEquityTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioPrivateEquityTrade(createPrivateEquityTrade: CreatePrivateEquityTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioPrivateEquityTrade(createPrivateEquityTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for equity, fund, etf, cryptocurrency, commodity, cash deposit and cash withdrawal trades
     * @param {CreateStandardTrade} createStandardTrade 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addPortfolioStandardTrade(createStandardTrade: CreateStandardTrade, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addPortfolioStandardTrade(createStandardTrade, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for adding a split to a position
     * @param {AddPositionSplitRequest} addPositionSplitRequest 
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public addSplitForPosition(addPositionSplitRequest: AddPositionSplitRequest, portfolioId: string, positionId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).addSplitForPosition(addPositionSplitRequest, portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for batch confirming transit trades
     * @param {PortfolioTradesIdsDto} portfolioTradesIdsDto 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public confirmPortfolioTransitTrades(portfolioTradesIdsDto: PortfolioTradesIdsDto, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).confirmPortfolioTransitTrades(portfolioTradesIdsDto, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {number} tradeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public deletePortfolioTrade(portfolioId: string, tradeId: number, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).deletePortfolioTrade(portfolioId, tradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for batch deleting trades
     * @param {PortfolioTradesIdsDto} portfolioTradesIdsDto 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public deletePortfolioTrades(portfolioTradesIdsDto: PortfolioTradesIdsDto, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).deletePortfolioTrades(portfolioTradesIdsDto, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {number} transitTradeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public deletePortfolioTransitTrade(portfolioId: string, transitTradeId: number, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).deletePortfolioTransitTrade(portfolioId, transitTradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditTradeRequest} editTradeRequest 
     * @param {string} portfolioId 
     * @param {number} tradeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public editPortfolioTrade(editTradeRequest: EditTradeRequest, portfolioId: string, tradeId: number, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).editPortfolioTrade(editTradeRequest, portfolioId, tradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Equity Fundamentals
     * @param {string} portfolioId 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public getPortfolioEquityFundamentals(portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).getPortfolioEquityFundamentals(portfolioId, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Bond/Fixed Income Positions
     * @param {string} portfolioId 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
     * @param {string} [settlementDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public getPortfolioPositionsFixedIncome(portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', settlementDate?: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).getPortfolioPositionsFixedIncome(portfolioId, currency, settlementDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Private Equity Positions
     * @param {string} portfolioId 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public getPortfolioPositionsPrivateEquity(portfolioId: string, currency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).getPortfolioPositionsPrivateEquity(portfolioId, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Aggregated Portfolio Trade Information
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public getPortfolioTradeInfo(portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).getPortfolioTradeInfo(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public getPortfolioTrades1(portfolioId: string, from?: string, to?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).getPortfolioTrades1(portfolioId, from, to, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public getPosition(portfolioId: string, positionId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).getPosition(portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Events are aggregated for related instruments, eg. an amazon option position would include events for amazon, and other amazon options
     * @summary Get portfolio events aggregated for a position instrument and its related instruments
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public getPositionEventAggregations(portfolioId: string, positionId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).getPositionEventAggregations(portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public getPositionInstrumentAggregation(portfolioId: string, positionId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).getPositionInstrumentAggregation(portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {string} positionId 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public getPositionSummary(portfolioId: string, positionId: string, date?: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).getPositionSummary(portfolioId, positionId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update private equity commitments trades
     * @param {UpdatePrivateEquityTrade} updatePrivateEquityTrade 
     * @param {number} tradeId 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public updatePortfolioPrivateEquityTrade(updatePrivateEquityTrade: UpdatePrivateEquityTrade, tradeId: number, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).updatePortfolioPrivateEquityTrade(updatePrivateEquityTrade, tradeId, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use for updating 
     * @param {UpdateTransitTrade} updateTransitTrade 
     * @param {string} portfolioId 
     * @param {number} transitTradeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTradesApi
     */
    public updatePortfolioTransitTrade(updateTransitTrade: UpdateTransitTrade, portfolioId: string, transitTradeId: number, options?: AxiosRequestConfig) {
        return PortfolioTradesApiFp(this.configuration).updatePortfolioTransitTrade(updateTransitTrade, portfolioId, transitTradeId, options).then((request) => request(this.axios, this.basePath));
    }
}
