import React from "react";
import s from "./ListPaginator.module.scss";
import { PaginatorReturnType } from "@iliotech/data-wire/src/reactHooks/hooks/usePaginator";
import Left from "./assets/left.svg";
import Reset from "./assets/reset.svg";

type AnyPaginator = PaginatorReturnType<any>;
type IProps = AnyPaginator;

export const ListPaginator = ({
  nextPage,
  prevPage,
  hasPrevPage,
  hasNextPage,
  pageToDisplay,
  resetPage,
  infoString,
}: IProps) => {
  return (
    <div className={s.row}>
      <div className={s.paginator}>
        {hasPrevPage ? (
          <img onClick={resetPage} className={s.left} src={Reset} alt="reset" />
        ) : (
          <div className={s.spacer} />
        )}
        {hasPrevPage ? (
          <img
            onClick={prevPage}
            className={s.left}
            src={Left}
            alt="previous"
          />
        ) : (
          <div className={s.spacer} />
        )}
        {hasPrevPage ? (
          <span onClick={prevPage} className={s.page}>
            {pageToDisplay - 1}
          </span>
        ) : (
          <div className={s.spacer} />
        )}
        <span className={s.currentPage}>{pageToDisplay}</span>
        {hasNextPage ? (
          <span onClick={nextPage} className={s.page}>
            {pageToDisplay + 1}
          </span>
        ) : (
          <div className={s.spacer} />
        )}
        {hasNextPage ? (
          <img onClick={nextPage} className={s.right} src={Left} alt="next" />
        ) : (
          <div className={s.spacer} />
        )}
      </div>
      <span>{infoString}</span>
    </div>
  );
};
