import {
  OTCInstrumentsApi,
  PricedInstrumentCreateRequestEquitySystemInstrumentRequest,
} from "../../../generated-api-v3";
import { API_BASE } from "../constants/constants";

const api = new OTCInstrumentsApi(undefined, `${API_BASE}/api`);

export const createEquityInstrumentOtc = (
  instrument: PricedInstrumentCreateRequestEquitySystemInstrumentRequest,
  externalAccountId: string
) => {
  return api.addEquityInstrument(instrument, externalAccountId);
};
