import {
  PortfolioAndResourceGroupsWorkgroupsApi,
  WorkgroupCreateDto,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

export const createWorkgroup = (
  newWorkgroup: WorkgroupCreateDto,
  externalAccountId: string
) => {
  const api = new PortfolioAndResourceGroupsWorkgroupsApi(
    undefined,
    `${API_BASE}/api`
  );
  if (newWorkgroup && !!externalAccountId) {
    return api.createWorkgroup(newWorkgroup, externalAccountId);
  }

  return Promise.reject("No workgroup passed to create workgroup");
};
