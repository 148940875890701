import * as React from "react";
import "hammerjs";
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisItemProps,
  ChartLegend,
  ChartProps,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesMarkers,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  MarkersVisualArgs,
  SharedTooltipContext,
  ValueAxisNotes,
} from "@progress/kendo-react-charts";
import {
  defaultCategoryAxisFormatter,
  defaultValueAxisFormatter,
} from "../../utils/defaultFormatters";
import {
  TimeSeriesSharedTooltipRenderer,
  TooltipSettings,
} from "../TimeSeriesSharedTooltip/TimeSeriesSharedTooltipRenderer";
import { TimeSeriesChartItem } from "../TimeSeriesStackedChart/TimeSeriesStackedChart";
import { drawing } from "@progress/kendo-drawing";
import { CategoryAxisNotes } from "@progress/kendo-react-charts/dist/npm/option-types/category-axis-item/notes.interface";

type SharedTooltipRenderType = (
  info: SharedTooltipContext,
  valueAxisFormatter?: (e?: number) => string,
  categoryAxisFormatter?: (e?: string) => string,
  settings?: TooltipSettings
) => React.ReactNode;

export interface TimeSeriesLineChartProps extends ChartProps {
  items: TimeSeriesChartItem[];
  sharedTooltipRender?: SharedTooltipRenderType;
  valueAxisFormatter?(e?: number): string;
  categoryAxisFormatter?(e?: string): string;
  chartStyle?: React.CSSProperties;
  axisCrossingValue?: number;
  baseUnit?: "days" | "months" | "years" | "weeks";
  baseUnitStep?: number | "auto";
  maxDivisions?: number;
  lineStyle?: "smooth" | "normal" | "step";
  markers?: boolean;
  min?: number;
  max?: number;
  aggregate?: any;
  markerRenderer?: (e: MarkersVisualArgs) => drawing.Element;
  categoryAxisNotes?: CategoryAxisNotes;
  valueAxisNotes?: ValueAxisNotes;
  tooltipSettings?: TooltipSettings;
  chartCategoryAxisItemProps?: ChartCategoryAxisItemProps;
  seriesType?: "line" | "area";
}

const DEFAULT_MAX_DIVISIONS = 12;
const TimeSeriesLineChart = ({
  items,
  sharedTooltipRender = TimeSeriesSharedTooltipRenderer,
  valueAxisFormatter = defaultValueAxisFormatter,
  categoryAxisFormatter = defaultCategoryAxisFormatter,
  chartStyle,
  axisCrossingValue = 0,
  baseUnit = "days",
  maxDivisions = DEFAULT_MAX_DIVISIONS,
  lineStyle = "normal",
  markers = false,
  max,
  min,
  aggregate,
  markerRenderer,
  categoryAxisNotes,
  valueAxisNotes,
  tooltipSettings,
  chartCategoryAxisItemProps,
  seriesType = "line",
  ...props
}: TimeSeriesLineChartProps) => {
  return (
    // @ts-ignore - bad type missing children
    <Chart style={chartStyle} {...props}>
      <ChartArea background={"transparent"} />
      <ChartLegend visible={false} />
      {typeof sharedTooltipRender === "function" && (
        <ChartTooltip
          opacity={0.9}
          color={"var(--tooltip-color)"}
          background={"var(--tooltip-background-color)"}
          render={(info: any) =>
            sharedTooltipRender(
              info as SharedTooltipContext,
              valueAxisFormatter,
              categoryAxisFormatter,
              tooltipSettings
            )
          }
          shared={true}
        />
      )}
      <ChartSeries>
        {items.map((item) => (
          <ChartSeriesItem
            key={item.category}
            type={seriesType}
            categoryField="date"
            style={lineStyle}
            line={{
              style: lineStyle,
            }}
            data={item.entries}
            name={item.category}
            markers={{
              visible: markers,
            }}
            color={item.color}
            border={{
              width: 0.1,
            }}
          >
            {markerRenderer && <ChartSeriesMarkers visual={markerRenderer} />}
          </ChartSeriesItem>
        ))}
      </ChartSeries>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          majorGridLines={{
            color: "var(--muted-text-color)",
            width: 0.1,
          }}
          notes={categoryAxisNotes}
          line={{ color: "transparent" }}
          labels={{
            color: "var(--color)",
            content: (e: any) => categoryAxisFormatter(e.value),
          }}
          maxDivisions={maxDivisions}
          baseUnit={baseUnit}
          {...chartCategoryAxisItemProps}
        />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem
          majorGridLines={{
            color: "var(--muted-text-color)",
            width: 0.1,
          }}
          notes={valueAxisNotes}
          min={min}
          max={max}
          axisCrossingValue={axisCrossingValue}
          labels={{
            color: "var(--color)",
            content: (e: any) => valueAxisFormatter(e.value),
          }}
        />
      </ChartValueAxis>
    </Chart>
  );
};

export default TimeSeriesLineChart;
