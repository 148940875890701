import {
  useNetWorthBreakdown,
  usePortfolio,
  useSnapshot,
} from "@iliotech/data-wire";
import { useParams } from "react-router";
import { ComparatorWrapper } from "../ComparatorWrapper";
import { NetWorthPie } from "src/components/PieTable/NetWorthPie";
import s from "../shared.module.scss";
import { ESGTombstone } from "src/components/__pageComponents/DashboardPage/ESGTombstone";

interface IProps {
  virtualId: string;
  basePortfolioId: string;
}
export const EsgComparator = ({ virtualId, basePortfolioId }: IProps) => {
  const { data: virtualPortfolio, isLoading: virtualLoading } = usePortfolio(
    virtualId!
  );
  const { data: basePortfolio, isLoading: baseLoading } = usePortfolio(
    basePortfolioId!
  );

  const virtualEsg = virtualPortfolio?.data?.esg;
  const baseEsg = basePortfolio?.data?.esg;

  return (
    <ComparatorWrapper
      loading={baseLoading || virtualLoading}
      noData={!basePortfolio?.data || !virtualPortfolio?.data}
    >
      <h3>ESG</h3>
      <article className={s.card}>
        <div className={s.half}>
          <div className={s.titleWrap}>
            <b className={s.title}>Your Portfolio</b>
          </div>
          <ESGTombstone className={s.esgWrapper} scores={baseEsg} />
        </div>
        <div className={s.half}>
          <div className={s.titleWrap}>
            <b className={s.title}>What if Scenario</b>
          </div>
          <ESGTombstone className={s.esgWrapper} scores={virtualEsg} />
        </div>
      </article>
    </ComparatorWrapper>
  );
};
