import { useScenariosCoefficients } from "@iliotech/data-wire";
import AllocationSettings from "../Components/AllocationSettings";
import { CommonHeader } from "../Components/CommonHeader";
import PNLSettings from "../Components/PNLSettings";
import RiskSettings from "../Components/RiskSettings";
import s from "../ReportCreator.module.scss";
import { useParams } from "react-router";
import SensitivitySettings from "../Components/SensitivitySettings";

interface IProps {
  inceptionDate: Date;
  from: Date | null;
  setFrom: (from: Date | null) => void;
  to: Date | null;
  setTo: (to: Date | null) => void;
  settings: Record<string, any>;
  updateSettings: (newSettings: Record<string, any>) => void;
}
export const CustomReport = ({ ...props }: IProps) => {
  const { portfolioId } = useParams();
  const { data } = useScenariosCoefficients(portfolioId!);
  return (
    <div>
      <CommonHeader {...props} />
      <div className={s.settings}>
        <AllocationSettings {...props} />
        <PNLSettings {...props} />
        <RiskSettings {...props} />
        <SensitivitySettings {...props} data={data?.data!} />
      </div>
    </div>
  );
};
