import React, { PropsWithChildren } from "react";
import {
  PortfolioSnapshotDtoV3,
  UserManagementApi,
  UserPreferences,
} from "@iliotech/generated-api-v3";
import { useQuery, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import { API_BASE } from "../../constants/constants";
import { EnabledColumnMap } from "@iliotech/component-library";

const DEFAULT_PREFERENCES = {
  locale: {},
  misc: {},
  defaultToPerformance: true,
};

export type TableId =
  | "Positions"
  | "Transactions"
  | "NetWorthPositions"
  | "PerformanceSnapshots"
  | "PNLSnapshots"
  | "OptionsSummary"
  | "FixedIncomeFundamentals"
  | "UnlistedInstrumentManagement";

interface IUserPreferencesContext {
  query?: UseQueryResult<AxiosResponse<UserPreferences>, unknown>;
  isLoading: boolean;
  isFetched: boolean;
  preferences?: UserPreferences;
  updateMiscPreference(key: string, value: any): Promise<any>;
  updateColumnPreferences<T = PortfolioSnapshotDtoV3>(
    tableId: TableId,
    settings: { visibleColumns: EnabledColumnMap; sort: {} }
  ): Promise<any>;
}

const noop = (field: string) => () => {
  const message = `'${field}' function of UserPreferencesContext not yet available`;
  return Promise.reject(message);
};
const api = new UserManagementApi(undefined, `${API_BASE}/api`);

const DEFAULT_CONTEXT: IUserPreferencesContext = {
  query: undefined,
  isLoading: true,
  isFetched: false,
  updateMiscPreference: noop("updateMiscPreferences"),
  updateColumnPreferences: noop("updateColumnPreferences"),
};

export const UserPreferencesContext =
  React.createContext<IUserPreferencesContext>(DEFAULT_CONTEXT);

export const UserPreferencesProvider = ({
  children,
}: PropsWithChildren<any>) => {
  const query = useQuery("user-preferences", () => api.getPreferences(), {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  const savePreference = (newPreferences: UserPreferences) => {
    return api
      .updatePreferences(newPreferences, {
        headers: {
          common: {
            "Content-Type": "application/json",
          },
        },
      })
      .then(() => query.refetch());
  };

  const updateMiscPreference = (key: string, value: any) => {
    if (!query.isFetched) {
      return Promise.resolve({}); // don't try and save the preferences, in case we lose all old preferences
    }
    return savePreference({
      ...DEFAULT_PREFERENCES,
      ...query.data?.data,
      misc: {
        ...query.data?.data.misc,
        [key]: JSON.stringify(value),
      },
    });
  };

  const updateColumnPreferences = <T extends any = PortfolioSnapshotDtoV3>(
    tableId: TableId,
    settings: { visibleColumns: EnabledColumnMap; sort: {} }
  ) => {
    return updateMiscPreference("consumerColumnSettings", {
      ...((query.data?.data.misc as any).consumerColumnSettings
        ? JSON.parse((query.data?.data.misc as any).consumerColumnSettings)
        : {}),
      [tableId]: settings,
    });
  };

  return (
    <UserPreferencesContext.Provider
      value={{
        query,
        isLoading: query.isLoading,
        isFetched: query.isFetched,
        preferences: query.data?.data,
        updateMiscPreference,
        updateColumnPreferences,
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
};

export const usePreferences = () => React.useContext(UserPreferencesContext);
