import React, { FC } from "react";
import { IDecimalCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

type Options = {
  greenForPositive?: boolean;
  multiply?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const DEFAULT_OPTIONS: Options = {
  greenForPositive: false,
  multiply: false,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export const PercentCell = (optionsIn?: Options): FC<IDecimalCellProps> => {
  const options = {
    ...DEFAULT_OPTIONS,
    ...optionsIn,
  };
  const { greenForPositive } = options;

  return React.memo(({ width = 100, ...props }) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const field = props.field || "";
    const value = props.dataItem[field];

    const finalValue = options.multiply ? value * 100 : value;

    const minimumFractionDigits = ifBlank(options.minimumFractionDigits, 0);
    const maximumFractionDigits = ifBlank(options.minimumFractionDigits, 2);

    const stringValue =
      typeof value === "undefined"
        ? ""
        : `${finalValue?.toLocaleString(undefined, {
            minimumFractionDigits,
            maximumFractionDigits,
          })}%`;

    const color = !greenForPositive
      ? undefined
      : parseFloat(value) > 0
      ? "green"
      : parseFloat(value) === 0
      ? undefined
      : "red";

    return (
      <td
        style={{ color }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div style={{ display: "flex", width }}>
          <div
            style={{
              width,
              textAlign: "right",
              fontWeight: 600,
              fontFamily: `var(--font-family-mono)`,
            }}
          >
            {stringValue}
          </div>
        </div>
      </td>
    );
  });
};

function ifBlank(value: number | undefined, replacement: number) {
  if (typeof value === "undefined") return replacement;
  return value;
}
