import { useQuery } from "react-query";
import { Period, PortfolioPerformanceApi } from "@iliotech/generated-api-v3";
import { API_BASE } from "../../constants/constants";
import { PortfolioPerformanceRequestAssetClassIdEnum } from "@iliotech/generated-api";

export const usePerformance = (
  portfolioId: string,
  period?: Period,
  allocationType?:
    | "AssetClass"
    | "AssetSubClass"
    | "RiskAssetClass"
    | "Region"
    | "Currency"
    | "IndustrySector"
    | "Custodian"
    | "CreditRating"
    | "FundStrategy"
    | "ETFSubClass"
    | "PropertySector",
  custodianId?: string,
  enabled = true,
  full = true
) => {
  const api = new PortfolioPerformanceApi(undefined, `${API_BASE}/api`);

  // const a = api.getPerformance(portfolioId).then(b => {
  //   b.data.
  // })

  const { from, to } = period ?? {};

  //TODO: For some reason this calls through to v1 api... generation of api to be checked
  return useQuery(
    [
      `portfolio-performance`,
      { portfolioId, from, to, custodianId, full: full ? "TRUE" : "FALSE" },
    ],
    () =>
      api.getPerformance(
        portfolioId,
        from,
        to,
        "Daily",
        full,
        allocationType || undefined,
        custodianId
        // allocationTypeId || undefined
      ),

    { enabled: !!portfolioId && enabled }
  );
};
