import { makeAutoObservable } from "mobx";
import {
  InstrumentDtoStatusEnum,
  EnhancedInstrumentSearchResult,
  PortfolioCustodian,
  SourceId,
  PortfolioTradesApi,
  CreatePositionRequest,
  CreatePositionRequestBuySellEnum,
  TradeImportWithSearchResult,
} from "@iliotech/generated-api-v3";
import { getCellWarning } from "../../processing";
import { parseErroredPositionItem, parsePositionItem } from "./utils";
import { API_BASE, REQUEST_DATE_FORMAT } from "../../constants/constants";
import moment from "moment";
import { sendFinalPositionsAsync } from "../../requests/sendFinalPositionsAsync";
import { getLastWeekDay } from "../../utils";
export interface IPositionTableRow {
  apiErrorString?: string;
  totalBase?: number;
  status?: { status: InstrumentDtoStatusEnum; sourceId: string };
  searchResults?: EnhancedInstrumentSearchResult[];
  selectedInstrument?: EnhancedInstrumentSearchResult;
  instrumentStatus?: string;
  selected?: boolean;
  id: string;
  ticker?: string;
  quantity: number;
  price?: number;
  custodian?: string;
  type?: string;
  currency?: string;
  custodianResolved?: boolean;
  settlementCurrency?: string;
  instrumentType?: string;
  apiError?: boolean;
  fxRate?: number;
  historicPrice?: number;
  overridePrice?: boolean;
  isCash?: boolean;
  multiplier?: number;
}

const INITIAL_CUSTODIAN_MODAL_STATE = {
  visible: false,
  id: "0",
  replace: false,
};
const INITIAL_INSTRUMENT_MODAL_STATE = {
  visible: false,
  id: "0",
};

export const DEFAULT_POSITION_DATE = getLastWeekDay();
export const PositionsTableStore = () =>
  makeAutoObservable({
    tableData: {} as { [id: string]: IPositionTableRow },
    invalidRows: {} as { [id: string]: unknown },
    errorMessage: "",
    tradeDate: DEFAULT_POSITION_DATE,
    setErrorMessage(val: string) {
      this.errorMessage = val;
    },
    setInvalidRows(val: { [id: string]: unknown }) {
      if (Object.values(val).length > 0) {
        this.errorMessage =
          Object.values(val).length +
          ` rows in the file have invalid values. \n Rather than try to guess what they should be we've prepared them to download into a separate file`;
      }
      this.invalidRows = val;
    },
    loading: false,
    blockingLoading: false,
    custodianModal: INITIAL_CUSTODIAN_MODAL_STATE,
    instrumentModal: INITIAL_INSTRUMENT_MODAL_STATE,
    submitModal: false,
    eraseModal: false,
    setCustodianModal(val: { visible: boolean; id: string; replace: boolean }) {
      this.custodianModal = val;
    },
    setInstrumentModal(val: { visible: boolean; id: string }) {
      this.instrumentModal = val;
    },
    tableDataArray() {
      return Object.values(this.tableData || {});
    },
    resetTableData() {
      this.tableData = {};
    },
    tableLength() {
      return Object.entries(this.tableData).length;
    },
    updateTableRow(row: IPositionTableRow) {
      this.tableData[row.id] = row;
    },
    processErroredData(data: any, custodians: PortfolioCustodian[]) {
      this.tableData = {};
      this.tradeDate =
        moment(
          data?.resultData?.[0]?.createPositionRequest.tradeTime
        ).toDate() || DEFAULT_POSITION_DATE;
      (data?.resultData as any)?.forEach((item: any, index: number) => {
        this.tableData[index + ""] = parseErroredPositionItem(
          item,
          custodians,
          index + ""
        );
      });
    },
    processInitialData(
      data: TradeImportWithSearchResult[],
      custodians: PortfolioCustodian[]
    ) {
      this.tableData = {};
      data.forEach((item: TradeImportWithSearchResult, index) => {
        this.tableData[index + ""] = parsePositionItem(
          item,
          custodians,
          index + ""
        );
      });
    },
    updateTableRowByIndex(
      key: keyof IPositionTableRow,
      value: any,
      id: string
    ) {
      // @ts-ignore
      this.tableData[id][key] = value;
    },
    getField(key: keyof IPositionTableRow, id: string) {
      return this.tableData[id]?.[key] || undefined;
    },
    removeTableRow(id: string) {
      delete this.tableData[id];
    },
    async onSave(portfolioId: string) {
      const validTrades = this.tableDataArray().filter(
        (item) => getCellWarning(item).icon === "OK"
      );
      if (!validTrades.length) return;

      const tradeDate = this.tradeDate;

      const positionsPayload: CreatePositionRequest[] = validTrades.map(
        (item) => {
          const isCash = item.instrumentType === "CASH";
          const buySell = (
            isCash
              ? (item?.quantity || 0) < 0
                ? "WITHDRAW"
                : "ADD"
              : item.type || ((item?.quantity || 0) < 0 ? "SELL" : "BUY")
          ) as CreatePositionRequestBuySellEnum;
          return {
            sourceId: item?.selectedInstrument?.sourceId as SourceId,
            tradeTime: moment(tradeDate).format(REQUEST_DATE_FORMAT),
            buySell,
            quantity: Math.abs(Number(item.quantity)),
            custodianCode: item.custodian!,
            currency: item.currency,
            // set price to historic price to reflect changes on manual entries
            price: isCash ? 1 : item.price,
            fxRate: item?.fxRate,
            historicPrice: item.price!,
          };
        }
      );

      this.loading = true;
      const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);

      try {
        const result = await sendFinalPositionsAsync(
          positionsPayload,
          portfolioId,
          "POSITION_TRADES"
        );
        this.loading = false;
        return result;
      } catch (e) {
        this.loading = false;
        return false;
      }

      this.loading = true;
    },
    updateRowsBatch(rows: IPositionTableRow[]) {
      const nextData: { [id: string]: IPositionTableRow } = {};
      rows.forEach((row) => {
        nextData[row.id] = row;
      });
      this.tableData = { ...this.tableData, ...nextData };
    },
  });

export default PositionsTableStore;
