import { IColumn } from "../types";
import { NameCell } from "../Cells/NameCell";
import { EsgCell } from "../Cells/EsgCell";
import { NumberCell } from "../Cells/NumberCell";

export const EsgRankings: IColumn<any>[] = [
  { field: "name", isDefault: true, renderer: NameCell() },
  {
    field: "netExposure",
    isDefault: true,
    renderer: NumberCell(),
    title: "Total Exposure",
    rightAligned: true,
  },
  { field: "total", isDefault: true, renderer: EsgCell(), title: "Total ESG" },
  { field: "e", isDefault: true, renderer: EsgCell(), title: "ENVIRONMENTAL" },
  { field: "s", isDefault: true, renderer: EsgCell(), title: "SOCIAL" },
  { field: "g", isDefault: true, renderer: EsgCell(), title: "GOVERNANCE" },
];
