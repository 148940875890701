import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import {
  Input,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import { usePositionUploadV2 } from "@iliotech/data-wire/src/stores/PositionsUpload";

export interface TransactionTableCellProps {
  index: number;
}
const PriceCell = ({ index }: TransactionTableCellProps) => {
  const store = usePositionUploadV2();

  const price = store.position.getField("price", index + "");
  const isCash = store.position.getField("isCash", index + "");
  const onChange = (event: NumericTextBoxChangeEvent) => {
    store.position.updateTableRowByIndex("price", event.value, index + "");
  };

  return (
    <NumericTextBox
      name={"price"}
      min={0.00001}
      disabled={!!isCash}
      format={{
        maximumFractionDigits: 5,
      }}
      value={price as number}
      onChange={onChange}
      spinners={false}
    />
  );
};

export default observer(PriceCell);
