import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import * as React from "react";
import { Autocomplete, AutocompleteProps } from "../Autocomplete/Autocomplete";
// TODO importing a type from @iliotech/generated-api-v3 means some business logic bleeds into the component library
// ideally use values pertaining to the domain of this component and use data-wire to convert the data from generated-api to generic data to be consumed by this component
import { PortfolioCustodian } from "@iliotech/generated-api-v3";
import { custodianValidator } from "@iliotech/data-wire";
import { AddNewButton } from "../../AddNewButton/AddNewButton";

interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  openModal: () => void;
  name?: string;
  label?: string | null;
  allCustodians: PortfolioCustodian[] | undefined;
}

export const CustodianField = ({
  errors,
  openModal,
  name = "custodian",
  label = "Account",
  defaultValue,
  allCustodians,
  ...rest
}: IOwnProps) => {
  const [open, setOpen] = React.useState(false);

  const component = React.useMemo(
    () => (props: AutocompleteProps) =>
      (
        <Autocomplete
          {...props}
          opened={open}
          onOpen={() => setOpen(true)}
          onClose={() => setTimeout(() => setOpen(false), 200)}
          onFocus={() => setOpen(true)}
        />
      ),
    [open, setOpen]
  );

  const filterData = (filter?: string) => {
    return allCustodians
      ?.filter(
        (item: any) =>
          (item.code ?? "")
            .toLowerCase()
            .startsWith(filter?.toLowerCase() ?? "") ||
          (item.name ?? "")
            .toLowerCase()
            .startsWith(filter?.toLowerCase() ?? "")
      )
      ?.map((cus: any) => ({
        ...cus,
        name: `${cus?.name}`,
      }));
  };

  return (
    <Field
      label={label}
      component={component}
      name={name}
      allowCustom
      dataItemKey={"name"}
      defaultValue={{ name: defaultValue }}
      textField={"name"}
      error={errors?.[name]}
      filterData={filterData}
      header={
        <AddNewButton
          setOpen={setOpen}
          openModal={openModal}
          text={"Add new account"}
        />
      }
      validator={custodianValidator}
      data={allCustodians}
      {...rest}
    />
  );
};
