import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import FormModal from "../../FormModal/FormModal";
import s from "../UploadIssueModal/UploadIssueModal.module.scss";
import RoundButton from "../../RoundButton/RoundButton";
import { Typography } from "@progress/kendo-react-common";
import { Form } from "@progress/kendo-react-form";
import { FormInputs } from "src/main";
export interface EraseModalProps {
  handleClose: () => void;
  handleDelete: () => void;
  onSave: (name: string) => void;
  buttonLoading?: boolean;
  visible: boolean;
}

export const SaveScenarioModal = (props: EraseModalProps) => {
  const { handleClose, visible, onSave, buttonLoading, handleDelete } = props;

  const [title, setTitle] = useState("");
  const handleSubmit = () => {
    onSave(title);
  };

  return (
    <FormModal
      hideClose={true}
      visible={visible}
      handleClose={handleClose}
      footer={
        <div className={s.footer}>
          <RoundButton fillMode={"outline"} onClick={handleDelete}>
            Don't save
          </RoundButton>
          <RoundButton
            disabled={!title?.length}
            onClick={handleSubmit}
            loading={buttonLoading}
            className={s.save}
          >
            Save
          </RoundButton>
        </div>
      }
    >
      <div className={s.container}>
        <Typography.h3 fontWeight={"bold"} style={{ marginBottom: 25 }}>
          Save scenario?
        </Typography.h3>
        <Typography.p>
          If you’d like to save this scenario for future reference please enter
          the name you’d like to save it as below:
        </Typography.p>
        <Typography.p>Scenario name</Typography.p>
        <FormInputs.Input
          onChange={(e) => setTitle(e.value)}
          required
          name="scenarioName"
          placeholder="e.g. My Portfolio"
        />
      </div>
    </FormModal>
  );
};
