import { Field, FieldValidatorType } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { notEmptyValidator } from "@iliotech/data-wire";
import * as React from "react";
import { NumericInput, InputProps } from "../NumericInput/NumericInput";

interface IOwnProps extends Omit<InputProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  officialPrice?: number;
  validator?: FieldValidatorType;
  officialPriceLabel?: string;
}

export const PriceField = ({
  errors,
  name = "price",
  label = "Price",
  officialPrice,
  officialPriceLabel = "Close price",
  ...rest
}: IOwnProps) => {
  return (
    <>
      <Field
        name={name}
        error={errors?.[name]}
        format={{ maximumFractionDigits: 4, minimumFractionDigits: 2 }}
        component={NumericInput}
        label={label ?? undefined}
        validator={notEmptyValidator}
        {...rest}
      />
      {typeof officialPrice === "number" && (
        <div style={{ color: "var(--muted-color)", fontStyle: "italic" }}>
          {officialPriceLabel}: {officialPrice}
        </div>
      )}
    </>
  );
};
