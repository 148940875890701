import React from "react";
import { FormModal } from "../FormModal/FormModal";
import { PortfolioSettings } from "./PortfolioSettings";
import { usePortfolio, useAvailableBenchmarks } from "@iliotech/data-wire";
import { useParams } from "react-router-dom";
import useSmallScreen from "@iliotech/data-wire/src/reactHooks/hooks/useSmallScreen";

interface IOwnProps {
  settingsOpen: boolean;
  closeSettings(): void;
  passedPortfolioId?: string;
}

export const PortfolioSettingsModal = ({
  settingsOpen,
  closeSettings,
  passedPortfolioId,
}: IOwnProps) => {
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const portfolioIdToUse = passedPortfolioId || portfolioId;
  const portfolioInfo = usePortfolio(portfolioIdToUse!);
  const benchmarks = useAvailableBenchmarks();
  const { savePortfolioName, setDefaultCustodian, setBenchmark } =
    portfolioInfo;

  // To Do - add other data fetching states to loading
  const loading = portfolioInfo.isFetching;

  const isMobile = useSmallScreen();

  const modalStyle = isMobile
    ? {
        padding: "30px 0 0 0",
        marginLeft: 10,
        marginRight: 10,
        marginBottom: "auto",
        minHeight: 400,
      }
    : {
        padding: "30px 0 0 0",
        margin: "auto",
      };

  return (
    <FormModal
      handleClose={closeSettings}
      visible={settingsOpen}
      closeOnBackdropClick={false}
      innerContainerStyle={{ padding: 0 }}
      modalStyle={modalStyle}
    >
      <PortfolioSettings
        setBenchmark={setBenchmark}
        loading={loading}
        portfolioInfo={portfolioInfo.data?.data}
        setDefaultCustodian={setDefaultCustodian}
        savePortfolioName={savePortfolioName}
        availableBenchmarks={benchmarks.data?.data}
        refetch={() => portfolioInfo?.refetch?.()}
      />
    </FormModal>
  );
};
