import { makeAutoObservable } from "mobx";
type ModalState = "assign" | "managerShare" | "rename" | "delete" | null;

const INITIAL_MODAL_STATE = {
  visible: false,
  id: 0,
  name: "name",
  type: null as ModalState,
  loading: false,
};

export const WorkgroupListStore = () =>
  makeAutoObservable({
    modalState: INITIAL_MODAL_STATE,
    search: "",
    setSearch(search: string) {
      this.search = search;
    },
    setModalState(val: {
      visible: boolean;
      id: number;
      type: ModalState;
      name: string;
      loading: boolean;
    }) {
      this.modalState = val;
    },
    setModalLoading(loading: boolean) {
      this.modalState.loading = loading;
    },
    resetModalState() {
      this.modalState = INITIAL_MODAL_STATE;
    },
  });

export default WorkgroupListStore;
