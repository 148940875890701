import { useWhatIfPositions } from "@iliotech/data-wire/src/stores";
import { WhatIfPositionsTableCellProps } from "../WhatIfPositionsTable";
import { observer } from "mobx-react-lite";
import { AiOutlineClose } from "react-icons/ai";
import s from "../../../PositionEntryCellRenderer/PositionEntryCellRenderer.module.scss";
import classNames from "classnames";

const DeleteCell = ({ index }: WhatIfPositionsTableCellProps) => {
  const store = useWhatIfPositions();
  const quantity = store.getField<number>("quantity", index);
  const originalQuantity = store.getField<number>("oldQuantity", index);

  const disabled = !quantity && !!originalQuantity;
  const onDelete = () => {
    console.log("on delete called with index " + index);
    if (!originalQuantity) {
      store.removeTableRow(index);
    } else {
      store.hideTableRow(index);
    }
  };

  const CloseBtn = ({ ...props }) => (
    <AiOutlineClose
      {...props}
      className={classNames(s.closeIcon, disabled && s.disabled)}
      onClick={onDelete}
    />
  );

  return <CloseBtn />;
};

export default observer(DeleteCell);
