import React from "react";

interface IOwnProps {
  sourceName?: string;
}

export const PlatformContactInfo = ({ sourceName }: IOwnProps) => {
  return <div>Please manage or close your account through the broker you signed up with: ({getBrokerName(sourceName)})</div>;
};

function getBrokerName(sourceName?: string) {
  switch(sourceName){
    case "IB":
      return "Interactive Brokers";
  }
  return sourceName;
}
