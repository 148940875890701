import { PropsWithChildren } from "react";

interface IProps {
  loading: boolean;
  noData: boolean;
}

export const ComparatorWrapper = ({
  children,
  loading,
  noData,
}: PropsWithChildren<IProps>) => {
  if (loading) {
    return null;
  }
  if (noData) {
    return null;
  }
  return children;
};
