import React from "react";
import { Typography } from "@progress/kendo-react-common";
import { StepTracker } from "../../";
import s from "./WhatIfStepper.module.scss";
import { useParams } from "react-router";

interface IOwnProps {
  currentStep: number;
  rightContent?: React.ReactNode;
  leftContent?: React.ReactNode;
  edit?: boolean;
}
export function WhatIfStepper({
  currentStep,
  rightContent,
  leftContent,
  edit,
}: IOwnProps) {
  return (
    <div className={s.container}>
      <div className={s.leftContent}>{leftContent}</div>
      <div className={s.stepperWrapper}>
        <Typography.h3 textAlign={"center"}>
          {edit ? "Edit" : "Create"} your what-if scenario
        </Typography.h3>
        <StepTracker currentStep={currentStep} totalSteps={2} />
      </div>
      <div className={s.rightContent}>{rightContent}</div>
    </div>
  );
}

export default WhatIfStepper;
