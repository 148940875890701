import React, { FC } from "react";
import cn from "classnames";
import s from "../ReportCreator.module.scss";
import { Checkbox } from "@progress/kendo-react-inputs";

interface IOwnProps {
  settings: Record<string, any>;
  updateSettings(newSettings: Record<string, any>): void;
}

const PNLSettings: FC<IOwnProps> = ({ settings, updateSettings }) => {
  const isAllSelected =
    !!settings.performance &&
    !!settings.assetClassPnl &&
    !!settings.attributionPnl;

  const toggleAll = () => {
    updateSettings({
      performance: !isAllSelected,
      assetClassPnl: !isAllSelected,
      attributionPnl: !isAllSelected,
    });
  };

  const PnlToggle = ({ field }: { field: string }) => {
    return (
      <Checkbox
        checked={settings[field]}
        onChange={({ value }) => updateSettings({ [field]: value })}
      />
    );
  };

  return (
    <div className={cn(s.advancedSettingsSection)}>
      <span className={s.sectionTitle}>P&L Attribution</span>
      <br />
      <div className={cn(s.settingsRow)}>
        <Checkbox
          checked={isAllSelected}
          onChange={({ value }) => toggleAll()}
        />
        <span>Select All</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <PnlToggle field={"performance"} />
        <span>Portfolio Performance</span>
      </div>

      <div className={cn(s.settingsRow)}>
        <PnlToggle field={"assetClassPnl"} />
        <span>P&L by Asset Class</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <PnlToggle field={"attributionPnl"} />
        <span>P&L by Attribution</span>
      </div>
    </div>
  );
};

export default PNLSettings;
