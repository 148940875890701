import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { notEmptyValidator, positiveValidator } from "@iliotech/data-wire";
import * as React from "react";
import { NumericInput, InputProps } from "../NumericInput/NumericInput";

interface IOwnProps extends Omit<InputProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  optional?: boolean;
  validator?: (value: string) => string;
}

export const QuantityField = ({
  errors,
  name = "quantity",
  label = "Quantity",
  optional,
  ...rest
}: IOwnProps) => {
  const validatorCallBack = React.useCallback(
    (value) => positiveValidator(value, optional),
    [optional]
  );
  return (
    <Field
      name={name}
      error={errors?.[name]}
      component={NumericInput}
      format={{ maximumFractionDigits: 4 }}
      placeholder={"Enter quantity"}
      label={label ?? undefined}
      validator={validatorCallBack}
      {...rest}
    />
  );
};
