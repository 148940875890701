import { PortfolioInsightsAndExplainersApi } from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";
import { useGlobalPeriod } from "../context";
import moment from "moment";

export const useInsightsByInstrument = (
  portfolioId: string,
  instrumentId: string
) => {
  const { period } = useGlobalPeriod();
  const from = !!period?.from
    ? moment(period.from).format("YYYY-MM-DD")
    : undefined;
  const to = !!period?.to ? moment(period.to).format("YYYY-MM-DD") : undefined;
  const api = new PortfolioInsightsAndExplainersApi(
    undefined,
    `${API_BASE}/api`
  );
  return useQuery([`insights-by-instrument`, {portfolioId, instrumentId, from, to}], () =>
    api.getInstrumentExplainers(portfolioId, instrumentId, from, to)
  );
};
