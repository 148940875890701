import React, { useState } from "react";
import {
  useAuth,
  usePortfolioList,
  useWorkgroupPortfolioList,
} from "@iliotech/data-wire";
import { useParams } from "react-router";
import {
  InstrumentAssetClassEnum,
  InstrumentNews,
  PortfolioDTOWithDetailsV3,
  PortfolioEventsResponseV3,
  PortfolioSnapshotDtoV3,
  PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum,
  PositionInstrumentAggregation,
  TradeDtoV3,
} from "@iliotech/generated-api-v3";
import { useTranslation } from "react-i18next";
import { IKeyStat } from "../../../KeyStat/KeyStat";

export interface IInstrumentPageProvider {
  positionInstrumentAggregation: PositionInstrumentAggregation | undefined;
  portfolioInfo: PortfolioDTOWithDetailsV3 | undefined;
  snapshots: PortfolioSnapshotDtoV3[] | undefined;
  transactionsIn: TradeDtoV3[] | undefined;
  news: InstrumentNews[] | undefined;
  events: PortfolioEventsResponseV3 | undefined;
}

const ASSET_CLASS_TO_EXCLUDE_ESG = [
  InstrumentAssetClassEnum.CryptoCurrencies,
  InstrumentAssetClassEnum.FixedIncome,
  InstrumentAssetClassEnum.Commodities,
  InstrumentAssetClassEnum.CashAndEquivalents,
  InstrumentAssetClassEnum.RealAssets,
  InstrumentAssetClassEnum.RealEstate,
];
const ASSET_CLASS_TO_EXCLUDE_FUNDAMENTALS = [
  InstrumentAssetClassEnum.RealAssets,
  InstrumentAssetClassEnum.RealEstate,
];
const ASSET_CLASS_TO_EXCLUDE_NEWS = [
  InstrumentAssetClassEnum.RealAssets,
  InstrumentAssetClassEnum.RealEstate,
];

export const useInstrumentPageHook = ({
  positionInstrumentAggregation,
  portfolioInfo,
  snapshots,
  transactionsIn,
  news,
  events,
}: IInstrumentPageProvider) => {
  const [modal, setModal] = useState<"PNL" | "EQUIVSHARES" | undefined>();
  const [selectedPositionId, setSelectedPositionId] = React.useState<string>();
  const [selectedInstrumentId, setSelectedInstrumentId] =
    React.useState<string>();
  const relevantPositionIdMap: { [positionId: string]: boolean } = {};
  positionInstrumentAggregation?.positions.forEach((id) => {
    relevantPositionIdMap[id] = true;
  });

  const currencySymbol = positionInstrumentAggregation?.currencySymbol || "";
  const currencyCode = positionInstrumentAggregation?.currencyCode || "";

  const withDerivatives = React.useMemo(
    () =>
      positionInstrumentAggregation?.totalExposureBase !==
      positionInstrumentAggregation?.totalValueBase,
    [positionInstrumentAggregation]
  );

  const portfolioCurrencySymbol = portfolioInfo?.currencySymbol;
  const portfolioCurrencyCode = portfolioInfo?.currencyCode;
  const { illioSelectedAccount } = useAuth();
  const { portfolios } = useWorkgroupPortfolioList();
  const modelList = usePortfolioList(
    illioSelectedAccount?.externalAccountId || "",
    "GLOBAL"
  );
  const { portfolioId = "" } = useParams<{ portfolioId: string }>();
  const models = modelList?.data?.data ?? [];

  const mainInstrumentId = (positionInstrumentAggregation as any)?.instrumentId;

  // TODO All the code below (up until line 144 ) is valuable illio code it should be moved to data-wire and memoized
  const relevantPositions = (snapshots || [])
    .filter((s) => relevantPositionIdMap[s.positionId])
    .sort((a, b) =>
      a.instrumentId === mainInstrumentId
        ? -10
        : b.instrumentId === mainInstrumentId
        ? 10
        : a.name.localeCompare(b.name)
    );

  const isCash =
    relevantPositions[0]?.investmentVehicleCode ===
    PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.Cash;

  const isCrypto =
    relevantPositions[0]?.investmentVehicleCode ===
    PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.CryptoCurrencies;
  const isFx =
    relevantPositions[0]?.investmentVehicleCode ===
    PortfolioSnapshotDtoV3InvestmentVehicleCodeEnum.Fx;

  const showEsg = React.useMemo(
    () =>
      !isCash &&
      !ASSET_CLASS_TO_EXCLUDE_ESG.includes(
        relevantPositions?.[0]?.assetClassCode as any
      ),
    [relevantPositions]
  );
  const showFundamentals = React.useMemo(
    () =>
      !isCash &&
      !ASSET_CLASS_TO_EXCLUDE_FUNDAMENTALS.includes(
        relevantPositions?.[0]?.assetClassCode as any
      ),
    [relevantPositions]
  );
  const showPriceWithBuysAndSells = !isCash;
  const showCumulativePNL =
    !isCash ||
    positionInstrumentAggregation?.currencyCode !== portfolioInfo?.currencyCode;
  const showPNLStats = !isCash;
  const showIncome = !isCash;
  const showTransactions = !isCash;

  const newsIsEmpty = !news || news.length === 0;
  const eventsIsEmpty =
    !events || !Object.values(events).some((e) => e.length > 0);
  const eventsAndNewsAreEmpty = newsIsEmpty && eventsIsEmpty;

  const showNews = React.useMemo(
    () =>
      !isCash &&
      !ASSET_CLASS_TO_EXCLUDE_NEWS.includes(
        relevantPositions?.[0]?.assetClassCode as any
      ) &&
      !eventsAndNewsAreEmpty,
    [relevantPositions]
  );

  let transactions = transactionsIn ? [...transactionsIn] : [];
  transactions = transactions.map((t) => ({
    ...t,
    instrumentName: t.instrument?.name,
    instrumentCode: t.instrument?.code,
  }));

  const relevantTransactions = (transactions || []).filter(
    (t) =>
      relevantPositionIdMap[t.positionId || "VOID"] &&
      (!selectedPositionId || t.positionId === selectedPositionId)
  );

  const cumulativePnlHistory = React.useMemo(() => {
    const series = positionInstrumentAggregation?.cumulativePnlHistory;

    if (!selectedPositionId) {
      const serie = series?.total || {};
      return Object.entries(serie).map(([key, value]) => {
        return {
          value,
          date: new Date(key),
        };
      });
    }

    const serie = series?.positions?.[selectedPositionId];
    return (
      serie?.map(({ value, date }) => {
        return {
          value,
          date: new Date(date),
        };
      }) || []
    );
  }, [positionInstrumentAggregation, selectedPositionId]);

  const { stats, title } = React.useMemo(() => {
    return {
      stats: getStats(
        positionInstrumentAggregation,
        portfolioInfo,
        withDerivatives,
        isCash,
        isCrypto,
        isFx
      ),

      title: !positionInstrumentAggregation?.instrumentName
        ? ""
        : isCash
        ? positionInstrumentAggregation?.instrumentName
        : `${positionInstrumentAggregation?.instrumentName || ""} [${
            (positionInstrumentAggregation as any)?.instrumentDisplayId || ""
          }] - ${positionInstrumentAggregation?.currencyCode}`,
    };
  }, [positionInstrumentAggregation, portfolioInfo, isCash]);

  const { t } = useTranslation();

  const toggleSelectedIds = (nextItem?: PortfolioSnapshotDtoV3) => {
    if (!nextItem) {
      return;
    }

    const nextPositionId = nextItem.positionId;
    const nextInstrumentId = nextItem.instrumentId;
    const isSame =
      nextPositionId === selectedPositionId &&
      nextInstrumentId === selectedInstrumentId;
    setSelectedPositionId((prev) => (isSame ? undefined : nextPositionId));
    setSelectedInstrumentId((prev) => (isSame ? undefined : nextInstrumentId));
  };

  const iconUrl = (positionInstrumentAggregation as any)?.instrumentIcon;

  const { esg, fundamentals } = positionInstrumentAggregation ?? {};

  return {
    modal,
    setModal,
    currencySymbol,
    currencyCode,
    portfolioCurrencySymbol,
    portfolioCurrencyCode,
    portfolioId,
    portfolios,
    models,
    showEsg,
    isCash,
    showFundamentals,
    showPriceWithBuysAndSells,
    selectedInstrumentId,
    showCumulativePNL,
    showPNLStats,
    showIncome,
    showTransactions,
    showNews,
    transactions,
    relevantPositions,
    selectedPositionId,
    relevantTransactions,
    esg,
    fundamentals,
    cumulativePnlHistory,
    iconUrl,
    stats,
    positionInstrumentAggregation,
    title,
    currencyValueFormatter: (v?: number) =>
      `${(portfolioInfo as any)?.currencySymbol}${v?.toLocaleString(undefined, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      })}`,
    toggleSelectedIds,
    news,
    events,
  };
};

const format = (val: number | "unknown", maximumFractionDigits = 0) =>
  typeof val !== "number"
    ? " "
    : val?.toLocaleString(undefined, { maximumFractionDigits });

const goodBad = (val: any) =>
  typeof val !== "number" ? "neutral" : val < 0 ? "bad" : "good";

const getStats = (
  positionInstrumentAggregation?: PositionInstrumentAggregation,
  portfolioInfo?: PortfolioDTOWithDetailsV3,
  withDerivatives?: boolean,
  isCash?: boolean,
  isCrypto?: boolean,
  isFx?: boolean
) => {
  const stats: [
    IKeyStat[],
    { [key: string]: IKeyStat },
    { [key: string]: IKeyStat }
  ] = [[], {}, {}];
  if (!positionInstrumentAggregation || !portfolioInfo) {
    return stats;
  }

  const baseCurrency = (portfolioInfo as any)?.currencyCode || "";
  const baseCurrencySymbol =
    (portfolioInfo as any)?.currencySymbol || baseCurrency || "";
  const instrumentCurrencySymbol =
    positionInstrumentAggregation?.currencySymbol;

  const makeAddStat0 = (
    label: string,
    value: string,
    type: "good" | "bad" | "neutral" = "neutral"
  ) =>
    stats[0].push({
      label,
      value,
      type,
    });

  const makeAddStat1 = (
    key: string,
    label: string,
    value: string,
    type: "good" | "bad" | "neutral" = "neutral"
  ) =>
    (stats[1][key] = {
      label,
      value,
      type,
    });

  const makeAddStat2 = (
    key: string,
    label: string,
    value: string,
    type: "good" | "bad" | "neutral" = "neutral"
  ) =>
    (stats[2][key] = {
      label,
      value,
      type,
    });
  const addStatRow0 = makeAddStat0;
  const addStatRow1 = makeAddStat1;
  const addStatRow2 = makeAddStat2;

  if (isCash) {
    addStatRow0(
      `Total value`,
      `${baseCurrencySymbol}${positionInstrumentAggregation.totalExposureBase?.toLocaleString(
        undefined,
        { maximumFractionDigits: 0 }
      )}`
    );

    {
      baseCurrency !== positionInstrumentAggregation.currencyCode &&
        addStatRow0(
          `Value (${positionInstrumentAggregation?.currencyCode})`,
          `${instrumentCurrencySymbol}${positionInstrumentAggregation.totalValueLocal?.toLocaleString(
            undefined,
            { maximumFractionDigits: 0 }
          )}`
        ) &&
        addStatRow0(
          `Fx P&L (${positionInstrumentAggregation?.currencyCode})`,
          `${instrumentCurrencySymbol}${format(
            positionInstrumentAggregation.pnl?.pnlFromFXBase
          )}`,
          goodBad(positionInstrumentAggregation.pnl?.pnlFromFXBase)
        );
    }
  } else {
    addStatRow0(
      withDerivatives ? "Equiv Shares" : "Open QTY",
      positionInstrumentAggregation.quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: isFx || isCrypto ? 8 : 4,
      }) ?? ""
    );
    addStatRow0(
      "Local Price",
      `${positionInstrumentAggregation.localPrice?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: isFx || isCrypto ? 8 : 4,
      })}`
    );
    addStatRow0(
      `Value (${portfolioInfo?.currencyCode})`,
      `${baseCurrencySymbol}${positionInstrumentAggregation.totalValueBase?.toLocaleString(
        undefined,
        { maximumFractionDigits: 0 }
      )}`
    );
    {
      baseCurrency !== positionInstrumentAggregation.currencyCode &&
        addStatRow0(
          `Value (${positionInstrumentAggregation?.currencyCode})`,
          `${instrumentCurrencySymbol}${positionInstrumentAggregation.totalValueLocal?.toLocaleString(
            undefined,
            { maximumFractionDigits: 0 }
          )}`
        );
    }
  }

  withDerivatives &&
    addStatRow0(
      `Exposure (${portfolioInfo?.currencyCode})`,
      `${baseCurrencySymbol}${positionInstrumentAggregation.totalExposureBase?.toLocaleString(
        undefined,
        { maximumFractionDigits: 0 }
      )}`
    );

  addStatRow1(
    "total",
    "Total P&L",
    `${baseCurrencySymbol}${format(
      positionInstrumentAggregation.pnl?.pnlTotalBase
    )}`,
    goodBad(positionInstrumentAggregation.pnl?.pnlTotalBase)
  );

  addStatRow1(
    "realised",
    "Realised P&L",
    `${baseCurrencySymbol}${format(
      positionInstrumentAggregation.pnl?.pnlRealisedBase
    )}`,
    goodBad(positionInstrumentAggregation.pnl?.pnlRealisedBase)
  );

  addStatRow1(
    "income",

    "Income P&L",
    `${baseCurrencySymbol}${format(
      positionInstrumentAggregation.pnl?.pnlFromIncomeBase
    )}`,
    goodBad(positionInstrumentAggregation.pnl?.pnlFromIncomeBase)
  );

  addStatRow1(
    "unrealised",

    "Unrealised P&L",
    `${baseCurrencySymbol}${format(
      positionInstrumentAggregation.pnl?.pnlUnrealisedBase
    )}`,
    goodBad(positionInstrumentAggregation.pnl?.pnlUnrealisedBase)
  );

  addStatRow2(
    "unrealised",

    "Unrealised P&L",
    `${baseCurrencySymbol}${format(
      positionInstrumentAggregation.pnl?.pnlUnrealisedBase
    )}`,
    goodBad(positionInstrumentAggregation.pnl?.pnlUnrealisedBase)
  );

  addStatRow2(
    "price",

    "Price P&L",
    `${baseCurrencySymbol}${format(
      positionInstrumentAggregation.pnl?.pnlFromPriceBase
    )}`,
    goodBad(positionInstrumentAggregation.pnl?.pnlFromPriceBase)
  );

  addStatRow2(
    "fx",

    "FX P&L",
    `${baseCurrencySymbol}${format(
      positionInstrumentAggregation.pnl?.pnlFromFXBase
    )}`,
    goodBad(positionInstrumentAggregation.pnl?.pnlFromFXBase)
  );

  return stats;
};
