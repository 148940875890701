import React from "react";
import s from "./FullScreenCssSpinner.module.scss";
import { CssSpinner, CssSpinnerProps } from "../CssSpinner/CssSpinner";

interface IProps extends CssSpinnerProps {
  text?: string;
  footerText?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export function FullScreenCssSpinner({
  text,
  containerProps,
  footerText,
  ...props
}: IProps) {
  return (
    <div className={s.container} {...containerProps}>
      {text}
      <CssSpinner className={s.spinner} {...props} />
      <div style={{ whiteSpace: "pre-line", textAlign: "center" }}>
        {footerText}
      </div>
    </div>
  );
}

export default FullScreenCssSpinner;
