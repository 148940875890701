import * as React from "react";
import {
  Grid,
  GridColumn,
  GridColumnProps,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { GridProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridProps";
import s from "./SimpleTable.module.scss";
import classNames from "classnames";
type SimpleTableColumnProps = GridColumnProps & {
  rightAligned?: boolean;
  removeBorderLeft?: boolean;
};
export interface SimpleTableProps extends GridProps {
  data: any[];
  columns: SimpleTableColumnProps[];
  rightAligned?: boolean;
  emptyGridRenderer?: () => React.ReactNode;
}

export const SimpleTable = ({
  data,
  columns,
  emptyGridRenderer,
  ...restProps
}: SimpleTableProps) => {
  return (
    <Grid
      className={"grid-surround"}
      data={data}
      resizable={true}
      {...restProps}
    >
      <GridNoRecords>
        {emptyGridRenderer ? emptyGridRenderer() : "No records available."}
      </GridNoRecords>
      {columns.map((col, index) => (
        <GridColumn
          key={`col-${col.field}-${index}`}
          headerClassName={classNames(
            col.rightAligned && s.rightAligned,
            col.removeBorderLeft && s.removeBorderLeft
          )}
          {...col}
        />
      ))}
    </Grid>
  );
};

export default SimpleTable;
