import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { BLUE_BASE_URL } from "../../../constants/constants";
import s from "./NotAllowed.module.scss";
import alert from "./assets/alert.svg";
export const NotAllowed = () => {
  useEffect(() => {
    setTimeout(() => {
      window.open(BLUE_BASE_URL, "_self");
    }, 1000);
  }, []);

  return (
    <div className={s.container}>
      <img src={alert} alt="alert" />
      <h3>You are not allowed to see this page</h3>

      <p>
        You are not allowed to see this page. You are being redirected to the
        home page of the right app.
      </p>
    </div>
  );
};
