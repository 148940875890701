import { PortfolioApi, PortfolioUpdateDtoV3 } from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

export const updatePortfolio = (
  payload: PortfolioUpdateDtoV3,
  portfolioId: string
) => {
  if (!portfolioId) {
    return;
  }
  const api = new PortfolioApi(undefined, `${API_BASE}/api`);
  return api.updatePortfolio(payload, portfolioId);
};
