import {
  Field,
  FieldProps,
  FieldValidatorType,
} from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { optionPositionValidator } from "@iliotech/data-wire";
import * as React from "react";
import { Autocomplete, AutocompleteProps } from "../Autocomplete/Autocomplete";
interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  trades?: any;
  onChange?: any;
  validator?: FieldValidatorType;
}

export const OptionsTradeField = ({
  errors,
  name = "Transaction",
  label = "Name",
  trades,
  onChange,
  ...rest
}: IOwnProps) => {
  const [open, setOpen] = React.useState(false);

  const component = React.useMemo(
    () => (props: AutocompleteProps) =>
      (
        <Autocomplete
          {...props}
          opened={open}
          onOpen={() => setOpen(true)}
          onClose={() => setTimeout(() => setOpen(false), 200)}
          onFocus={() => setOpen(true)}
        />
      ),
    [open, setOpen]
  );
  return (
    <Field
      component={component}
      label={label}
      name={name}
      onChange={onChange}
      dataItemKey={"name"}
      textField={"name"}
      placeholder={"Enter name"}
      error={errors?.[name]}
      filterData={(filter: string) => {
        // console.log({positions})
        return trades
          ?.filter((item: any) =>
            item.name.toLowerCase().includes(filter.toLowerCase())
          )
          ?.map((pos: any) => ({
            ...pos,
            name: `${pos.name}`,
          }));
      }}
      validator={optionPositionValidator as any}
      data={trades}
      allowCustom
      {...rest}
    />
  );
};
