import * as React from "react";
import s from "./IntegrationDone.module.scss";
import { useEffect } from "react";

interface IProps {}

export const IntegrationDone = ({}: IProps) => {
  /**
   *   * Note: using getParameterByName as URLSearchParams support is not fully complete at the moment, and this page is critical and happens very early in the user journey
   *   const urlSearchParams = new URLSearchParams(window.location.search);
   *   const params = Object.fromEntries(urlSearchParams.entries());
   **/

  const broker = getParameterByName("brokerName", window.location.href);

  const selfDestruct = () => {
    setTimeout(() => {
      var me = window.self;
      me.opener = window.self;
      me.close();
    }, 5000);
  };

  const notifyIbCompletion = () => {
    var opener = window.opener;
    // TODO remember
    opener?.postMessage("ibAuthenticationComplete", opener.origin);
  };

  useEffect(() => {
    switch (broker) {
      case "IB":
        notifyIbCompletion();
        break;
    }
    selfDestruct();
  }, [broker]);

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <h6>
          The broker authorisation was successful. This pop-up will close in 5
          seconds and you can continue in the other tab
        </h6>
        {/*<pre style={{fontSize: 12}}>{JSON.stringify({broker, location: document.location}, null, 2)}</pre>*/}
      </div>
    </div>
  );
};

export default IntegrationDone;

function getParameterByName(name: string, url: string) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
