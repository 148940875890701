import React, { useState } from "react";
import cn from "classnames";
import s from "./RangeBulletChart.module.scss";
import { useDelay, useSmallScreen } from "@widgets/utils";

export interface RangeBulletChartProps {
  high: number;
  low: number;
  current: number;
  highLabel?: React.ReactNode;
  lowLabel?: React.ReactNode;
  currentLabel?: React.ReactNode;
  valueFormatter?: (value: number) => string;
  showPercentageMove?: boolean;
  showValue?: boolean;
  highPercent?: number;
  lowPercent?: number;
}

export const RangeBulletChart = React.memo(
  ({
    high,
    low,
    current,
    highLabel = "52 weeks High",
    lowLabel = "52 weeks Low",
    currentLabel = "Current Price",
    showPercentageMove,
    showValue = true,
    highPercent,
    lowPercent,
    valueFormatter = (value: number) => String(value),
  }: RangeBulletChartProps) => {
    const range = high - low;
    const offset = (current - low) / range;
    const currentValue = valueFormatter(current);
    return (
      <div className={cn(s.around)}>
        <div className={cn(s.surround)}>
          <div className={cn(s.bar)}></div>
          <div className={cn(s.labelsArea)}>
            <BulletAxisLabel
              valueFormatter={valueFormatter}
              className={s.low}
              value={low}
              percent={lowPercent}
              current={current}
              label={lowLabel}
              showValue={showValue}
              showPercentageMove={showPercentageMove}
            />
            <BulletAxisLabel
              valueFormatter={valueFormatter}
              className={s.high}
              value={high}
              current={current}
              percent={highPercent}
              label={highLabel}
              showValue={showValue}
              showPercentageMove={showPercentageMove}
            />
          </div>
          <BulletMarker
            currentLabel={currentLabel}
            value={currentValue}
            offset={offset}
          />
        </div>
      </div>
    );
  }
);

const BulletMarker = ({
  value,
  offset,
  currentLabel,
}: {
  value: string;
  offset: number;
  currentLabel: React.ReactNode;
}) => {
  const [distance, setDistance] = useState("50%");
  const [opacity, setOpacity] = useState(0);
  const smallScreen = useSmallScreen(575);

  useDelay({
    callback: () => {
      setDistance(`${offset * 100}%`);
      setOpacity(1);
    },
    delay: 200,
  });

  return (
    <div
      className={cn(s.markerSurround)}
      style={smallScreen ? { bottom: distance } : { left: distance }}
    >
      <div className={s.currentPriceSurround}>
        <label style={{ opacity }}>{currentLabel}</label>
      </div>
      <div className={cn(s.marker)}>
        <div className={cn(s.topLine)} />
        {/*<div className={cn(s.markerLine)} />*/}
        <div className={cn(s.bottomLine)} />
      </div>
    </div>
  );
};

const BulletAxisLabel = ({
  className,
  label,
  value,
  current,
  showPercentageMove,
  showValue,
  percent,
  valueFormatter = (value: number) => String(value),
}: {
  className?: string;
  value: number;
  current: number;
  label: React.ReactNode;
  showPercentageMove?: boolean;
  percent?: number;
  showValue?: boolean;
  valueFormatter?: (value: number) => string;
}) => {
  const distance = Math.abs(value - current);
  const positive = current < value;
  const percentageMove = Math.abs((distance / value) * 100).toLocaleString(
    undefined,
    { minimumFractionDigits: 1, maximumFractionDigits: 1 }
  );

  return (
    <div className={cn(s.label, className)}>
      <label>{label}</label>
      {showValue && <span className={s.value}>{valueFormatter(value)}</span>}
      {!!showPercentageMove && (
        <div
          className={cn(s.percentageMove, positive ? s.positive : s.negative)}
        >
          {positive ? "+" : ""}
          {percent?.toFixed(2)}%{positive ? `\u25B2` : `\u25BC`}
        </div>
      )}
      <div className={cn(s.axisLine)} />
    </div>
  );
};
