import {Userpilot} from "userpilot";
import React from "react";

export const useUserPilot = () => {
  React.useEffect(() => {
    const token = process.env.VITE_USERPILOT_TOKEN! || window.environmentSettings.userpilotToken! as string;
    Userpilot.initialize(token);
    Userpilot.anonymous();
  }, [])
}
