import { makeAutoObservable } from "mobx";
import {
  InstrumentDtoStatusEnum,
  TradeImportWithSearchResult,
  EnhancedInstrumentSearchResult,
  CreatePositionRequest,
  CreatePositionRequestBuySellEnum,
  SourceId,
  PortfolioTradesApi,
  PortfolioCustodian,
} from "@iliotech/generated-api-v3";
import { getCellWarning } from "../../processing";
import { parseErroredTradeItem, parseTradeItem } from "./utils";
import moment from "moment/moment";
import { API_BASE, REQUEST_DATE_FORMAT } from "../../constants/constants";
import { sendFinalPositionsAsync } from "../../requests/sendFinalPositionsAsync";
import { parseErroredPositionItem } from "../PositionsUpload/utils";
import { DEFAULT_POSITION_DATE } from "../PositionsUpload";

export interface ITableRow {
  apiErrorString?: string;
  totalBase?: number;
  status?: { status: InstrumentDtoStatusEnum; sourceId: string };
  searchResults?: EnhancedInstrumentSearchResult[];
  selectedInstrument?: EnhancedInstrumentSearchResult;
  instrumentStatus?: string;
  selected?: boolean;
  commission?: number;
  id: string;
  ticker: string;
  quantity: number;
  price?: number;
  assetClass: string;
  date: Date;
  custodian?: string;
  type: string;
  currency?: string;
  custodianResolved?: boolean;
  settlementCurrency?: string;
  instrumentType?: string;
  apiError?: boolean;
  fxRate?: number;
  overrideFxRate?: boolean;
  overridePrice?: boolean;
  dateChanged?: boolean;
  tradeCosts?: number;
  notes?: string;
}

const INITIAL_CUSTODIAN_MODAL_STATE = {
  visible: false,
  id: "0",
  replace: false,
};
const INITIAL_INSTRUMENT_MODAL_STATE = {
  visible: false,
  id: "0",
};

export const TransactionsTableStore = () =>
  makeAutoObservable({
    tableData: {} as { [id: string]: ITableRow },
    invalidRows: {} as { [id: string]: unknown },
    submitModal: false,
    eraseModal: false,
    errorMessage: "",
    setErrorMessage(val: string) {
      this.errorMessage = val;
    },
    processErroredData(data: any, custodians: PortfolioCustodian[]) {
      this.tableData = {};
      (data?.resultData as any)?.forEach((item: any, index: number) => {
        this.tableData[index + ""] = parseErroredTradeItem(
          item,
          custodians,
          index + ""
        );
      });
    },
    processInitialData(
      data: TradeImportWithSearchResult[],
      custodians: PortfolioCustodian[]
    ) {
      this.tableData = {};
      data.forEach((item: TradeImportWithSearchResult, index) => {
        this.tableData[index + ""] = parseTradeItem(
          item,
          custodians,
          index + ""
        );
      });
    },
    setInvalidRows(val: { [id: string]: unknown }) {
      if (Object.values(val).length > 0) {
        this.errorMessage =
          Object.values(val).length +
          ` rows in the file have invalid values. \n Rather than try to guess what they should be we've prepared them to download into a separate file`;
      }
      this.invalidRows = val;
    },
    loading: false,
    blockingLoading: false,
    custodianModal: INITIAL_CUSTODIAN_MODAL_STATE,
    instrumentModal: INITIAL_INSTRUMENT_MODAL_STATE,
    setCustodianModal(val: { visible: boolean; id: string; replace: boolean }) {
      this.custodianModal = val;
    },
    setInstrumentModal(val: { visible: boolean; id: string }) {
      this.instrumentModal = val;
    },
    tableDataArray() {
      return Object.values(this.tableData || {});
    },
    resetTableData() {
      this.tableData = {};
    },
    tableLength() {
      return Object.entries(this.tableData).length;
    },
    updateTableRow(row: ITableRow) {
      this.tableData[row.id] = row;
    },
    updateTableRowByIndex(key: keyof ITableRow, value: any, id: string) {
      // @ts-ignore
      this.tableData[id][key] = value;
    },
    getField(key: keyof ITableRow, id: string) {
      return this.tableData[id]?.[key] || undefined;
    },
    removeTableRow(id: string) {
      delete this.tableData[id];
    },
    async onSave(portfolioId: string) {
      const validTrades = this.tableDataArray().filter(
        (item) => getCellWarning(item).icon === "OK"
      );
      if (!validTrades.length) return;

      const positionsPayload: CreatePositionRequest[] = validTrades.map(
        (item) => {
          return {
            sourceId: item?.selectedInstrument?.sourceId as SourceId,
            tradeTime: moment(item?.date).format(REQUEST_DATE_FORMAT),
            buySell:
              item.quantity > 0
                ? CreatePositionRequestBuySellEnum.Buy
                : CreatePositionRequestBuySellEnum.Sell,
            quantity: Math.abs(Number(item.quantity)),
            custodianCode: item.custodian!,
            currency: item.currency || (undefined as any),
            // set price to historic price to reflect changes on manual entries
            price: item.price,
            fxRate: item?.fxRate,
            historicPrice: item.price!,
            notes: item?.notes,
            settlementCurrencyCode: item.settlementCurrency,
            commission: item.commission,
            tradeCosts: item.tradeCosts,
          };
        }
      );

      this.loading = true;
      const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);

      try {
        const result = await sendFinalPositionsAsync(
          positionsPayload,
          portfolioId,
          "REGULAR_TRADES"
        );
        this.loading = false;
        return result;
      } catch (e) {
        this.loading = false;
        return false;
      }

      this.loading = true;
    },
    updateRowsBatch(rows: ITableRow[]) {
      rows.forEach((row) => {
        this.tableData[row.id] = row;
      });
    },
  });

export default TransactionsTableStore;
