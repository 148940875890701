import { GridColumnProps } from "@progress/kendo-react-grid";
import { CellWrapper } from "./cells/CellWrapper";
import StatusCell from "./cells/StatusCell";
import TickerCell from "./cells/TickerCell";
import NameCell from "./cells/NameCell";
import DateCell from "./cells/DateCell";
import QuantityCell from "./cells/QuantityCell";
import TradeTypeCell from "./cells/TradeTypeCell";
import InstrumentTypeCell from "./cells/InstrumentTypeCell";
import PriceCell from "./cells/PriceCell";
import CurrencyCell from "./cells/CurrencyCell";
import CustodianCell from "./cells/CustodianCell";
import CommissionCell from "./cells/CommissionCell";
import SettlementCurrencyCell from "./cells/SettlementCurrencyCell";
import * as React from "react";
import FxRateCell from "./cells/FxRateCell";
import TotalBaseCell from "./cells/TotalBaseCell";
import TotalLocalCell from "./cells/TotalLocalCell";
import OtherCostsCell from "./cells/OtherCostsCell";
import NotesCell from "./cells/NotesCell";

export const columns: (baseCurrency: string) => GridColumnProps[] = (
  baseCurrency: string
) => [
  {
    field: "status",
    title: "STATUS",
    width: 50,
    cell: (props) => <CellWrapper gridCellProps={props} cell={StatusCell} />,
  },
  {
    field: "ticker",
    title: "TICKER",
    minResizableWidth: 60,
    cell: (props) => <CellWrapper gridCellProps={props} cell={TickerCell} />,
  },
  {
    field: "name",
    title: "INSTRUMENT NAME",
    minResizableWidth: 150,
    cell: (props) => <CellWrapper gridCellProps={props} cell={NameCell} />,
  },
  {
    field: "instrumentType",
    title: "INSTRUMENT TYPE",
    minResizableWidth: 150,
    cell: (props) => (
      <CellWrapper gridCellProps={props} cell={InstrumentTypeCell} />
    ),
  },
  {
    field: "date",
    title: "TRANSACTION DATE",
    minResizableWidth: 120,
    cell: (props) => <CellWrapper gridCellProps={props} cell={DateCell} />,
  },
  {
    field: "quantity",
    title: "QUANTITY",
    minResizableWidth: 100,
    cell: (props) => <CellWrapper gridCellProps={props} cell={QuantityCell} />,
  },
  {
    field: "price",
    title: "LOCAL PRICE",
    minResizableWidth: 150,

    cell: (props) => <CellWrapper gridCellProps={props} cell={PriceCell} />,
  },
  {
    field: "fxRate",
    title: "FX Rate",
    minResizableWidth: 120,
    cell: (props) => <CellWrapper gridCellProps={props} cell={FxRateCell} />,
  },
  {
    field: "currency",
    title: "CURRENCY",
    minResizableWidth: 80,

    cell: (props) => <CellWrapper gridCellProps={props} cell={CurrencyCell} />,
  },

  {
    field: "custodian",
    title: "ACCOUNT",
    minResizableWidth: 100,

    cell: (props) => <CellWrapper gridCellProps={props} cell={CustodianCell} />,
  },
  {
    field: "commission",
    title: "COMMISSION LOCAL",
    minResizableWidth: 80,

    cell: (props) => (
      <CellWrapper gridCellProps={props} cell={CommissionCell} />
    ),
  },
  {
    field: "tradeCosts",
    title: "OTHER COSTS LOCAL",
    minResizableWidth: 80,

    cell: (props) => (
      <CellWrapper gridCellProps={props} cell={OtherCostsCell} />
    ),
  },

  {
    field: "settlementCurrency",
    title: "SETTLEMENT CURRENCY",
    minResizableWidth: 100,

    cell: (props) => (
      <CellWrapper
        gridCellProps={props}
        cell={SettlementCurrencyCell}
        baseCurrency={baseCurrency}
      />
    ),
  },
  {
    field: "notes",
    title: "NOTES",
    minResizableWidth: 80,

    cell: (props) => <CellWrapper gridCellProps={props} cell={NotesCell} />,
  },
  {
    field: "total",
    title: "TRANSACTION VALUE LOCAL",
    minResizableWidth: 100,

    cell: (props) => (
      <CellWrapper gridCellProps={props} cell={TotalLocalCell} />
    ),
  },
  {
    field: "total",
    title: "TRANSACTION VALUE BASE",
    minResizableWidth: 100,

    cell: (props) => (
      <CellWrapper
        gridCellProps={props}
        cell={TotalBaseCell}
        baseCurrency={baseCurrency}
      />
    ),
  },
];
