import React, { FC } from "react";
import { IllioTable } from "../IllioTable/IllioTable";
import { IColumn } from "../IllioTable/types";
import { GridCellProps } from "@progress/kendo-react-grid";
import cn from "classnames";
import s from "./PieTable.module.scss";
import { PieTableEntry } from "@iliotech/types";
import { NetWorthPie } from "./NetWorthPie";

interface IOwnProps {
  data: PieTableEntry[];
  valueRenderer?: FC<GridCellProps>;
  valueFormatter?(val: number): string;
  resetFilter?(): void;
  withAnimations?: boolean;
  vertical?: boolean;
}

export const PieTable = ({
  data,
  valueRenderer,
  valueFormatter = (val) =>
    `${val.toLocaleString(undefined, { maximumFractionDigits: 0 })}`,
  resetFilter,
  withAnimations = false,
  vertical,
}: IOwnProps) => {
  const COLUMNS: IColumn[] = [
    {
      field: "legendKey",
      isDefault: true,
      width: 20,
      renderer: ({ dataItem }) => (
        <td style={{}}>
          <div
            style={{
              backgroundColor: dataItem.color || "black",
              height: 10,
              width: 10,
              borderRadius: "50%",
            }}
          />
        </td>
      ),
    },
    {
      field: "label",
      isDefault: true,
    },
    {
      field: "value",
      renderer:
        valueRenderer ??
        (({ dataItem }) => (
          <td>
            <div
              data-cy={
                "pie-table-value-" +
                dataItem?.label.replaceAll(" ", "-").toLowerCase()
              }
              style={{
                textAlign: "right",
                fontFamily: "var(--number-font-family)",
                fontSize: "0.75rem",
              }}
            >
              {valueFormatter(dataItem?.value)}
            </div>
          </td>
        )),
      isDefault: true,
      // width: 120
    },
    {
      field: "percentShare",
      renderer: ({ dataItem }) => (
        <td>
          <div
            style={{
              textAlign: "right",
              fontFamily: "var(--number-font-family)",
              fontSize: "0.75rem",
            }}
          >
            {(dataItem?.percentShare * 100).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })}
            %
          </div>
        </td>
      ), //(val: any) => <div>{JSON.stringify(val)}%</div>,
      isDefault: true,
      width: 70,
    },
    // {
    //   field: "absoluteShare",
    //   renderer: ({ dataItem }) => (
    //     <td style={{ textAlign: "right" }}>
    //       {(dataItem?.absoluteShare * 100).toLocaleString(undefined, {
    //         maximumFractionDigits: 1,
    //       })}
    //       %
    //     </td>
    //   ), //(val: any) => <div>{JSON.stringify(val)}%</div>,
    //   isDefault: true,
    // },
  ];

  return (
    <div className={cn(vertical && s.vertical, s.surround)}>
      <div className={s.table}>
        <IllioTable
          useColumnChooser={false}
          columns={COLUMNS}
          data={data}
          hideHeader={true}
          pageSize={0}
          isPageable={false}
          gridProps={{
            onRowClick: (event) => {
              event.dataItem?.onClick?.();
            },
          }}
        />
        {!!resetFilter && <button onClick={resetFilter}>Reset</button>}
      </div>
      <NetWorthPie data={data} withAnimations={withAnimations} />
    </div>
  );
};
