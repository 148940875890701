/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { AccountDetailsDtoV3 } from '../../aggregator';
// @ts-ignore
import { AccountInfo } from '../../aggregator';
// @ts-ignore
import { AccountPreferences } from '../../aggregator';
// @ts-ignore
import { AccountSummaryDTO } from '../../aggregator';
// @ts-ignore
import { AccountUserCapabilitiesDTO } from '../../aggregator';
// @ts-ignore
import { ApiKeyReveal } from '../../aggregator';
// @ts-ignore
import { ApiUserDetailsDTO } from '../../aggregator';
// @ts-ignore
import { BulkUserUpdate } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { InlineObject } from '../../aggregator';
// @ts-ignore
import { PortfolioResponse } from '../../aggregator';
// @ts-ignore
import { TransitTradeSummary } from '../../aggregator';
// @ts-ignore
import { UploadedFileLight } from '../../aggregator';
// @ts-ignore
import { UploadedFileStatus } from '../../aggregator';
// @ts-ignore
import { UserDetailsDTO } from '../../aggregator';
// @ts-ignore
import { UserSummaryDTO } from '../../aggregator';
// @ts-ignore
import { UserUpdate } from '../../aggregator';
/**
 * AccountManagementApi - axios parameter creator
 * @export
 */
export const AccountManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve all the account and usage details for the user\'s account.
         * @summary Retrieve account and usage details
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDetails: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('accountDetails', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a summary list of all the accounts associated with the user.
         * @summary Retrieve the list of accounts for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSummaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve account capabilities, actions and limits. Capabilities are the list of business capabilities that account is allowed to access. Actions are the list of actions that the user can perform on the account. Limits on the account include maxPortfolios allowed, maxTrades etc. ScreenMask is screens that should be hidden in the UI. AllowedConnections are the broker connections that the account is allowed to use. 
         * @summary Retrieve account capabilities, actions and limits
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUserCapabilities: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('accountUserCapabilities', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/capabilities`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates an exclusive set of public and secret API keys for the account.
         * @summary Generate a unique ApiKey for the account
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApiKey: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addApiKey', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/apiKey`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Apikey attached to the account. 
         * @summary Delete ApiKey attached to the account
         * @param {string} externalAccountId 
         * @param {string} apiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApiKey1: async (externalAccountId: string, apiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addApiKey1', 'externalAccountId', externalAccountId)
            // verify required parameter 'apiKey' is not null or undefined
            assertParamExists('addApiKey1', 'apiKey', apiKey)
            const localVarPath = `/v3/account/{externalAccountId}/apiKey/{apiKey}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"apiKey"}}`, encodeURIComponent(String(apiKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a new user to access wealth management API\'s. 
         * @summary Register a new API user
         * @param {ApiUserDetailsDTO} apiUserDetailsDTO 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApiUser: async (apiUserDetailsDTO: ApiUserDetailsDTO, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiUserDetailsDTO' is not null or undefined
            assertParamExists('addApiUser', 'apiUserDetailsDTO', apiUserDetailsDTO)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addApiUser', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/apiUser`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiUserDetailsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a new GUI user to access wealth management portal. 
         * @summary Register a new GUI user
         * @param {UserDetailsDTO} userDetailsDTO 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGuiUser: async (userDetailsDTO: UserDetailsDTO, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDetailsDTO' is not null or undefined
            assertParamExists('addGuiUser', 'userDetailsDTO', userDetailsDTO)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addGuiUser', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/guiUser`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDetailsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update users in bulk
         * @param {Array<BulkUserUpdate>} bulkUserUpdate 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUser: async (bulkUserUpdate: Array<BulkUserUpdate>, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUserUpdate' is not null or undefined
            assertParamExists('bulkUpdateUser', 'bulkUserUpdate', bulkUserUpdate)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('bulkUpdateUser', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/updateUsers`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUserUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves users account status and issues list. 
         * @summary Retrieve users account status information
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthorisation1: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('checkAuthorisation1', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/checkStatus`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the list of all the portfolios associated with the account.
         * @summary Retrieve a list of portfolios associated with the account
         * @param {string} externalAccountId 
         * @param {'LIVE' | 'PAPER'} [type] 
         * @param {'ACTIVE' | 'DRAFT' | 'HIDDEN' | 'DELETING'} [accessType] 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserPortfolios1: async (externalAccountId: string, type?: 'LIVE' | 'PAPER', accessType?: 'ACTIVE' | 'DRAFT' | 'HIDDEN' | 'DELETING', workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getCurrentUserPortfolios1', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/portfolios`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (accessType !== undefined) {
                localVarQueryParameter['accessType'] = accessType;
            }

            if (workgroupType !== undefined) {
                localVarQueryParameter['workgroupType'] = workgroupType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve summary of portfolios associated to this account
         * @summary Retrieve summary of portfolios associated to this account
         * @param {string} externalAccountId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserPortfoliosSummary: async (externalAccountId: string, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getCurrentUserPortfoliosSummary', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/portfoliosSummary`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (displayCurrency !== undefined) {
                localVarQueryParameter['displayCurrency'] = displayCurrency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of uploaded trade files where each row identifies the portfolio , trade details and its processing status.
         * @summary Retrieve a list of uploaded trade files with processing status
         * @param {string} externalAccountId 
         * @param {string} tradeSourceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeFile: async (externalAccountId: string, tradeSourceName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getTradeFile', 'externalAccountId', externalAccountId)
            // verify required parameter 'tradeSourceName' is not null or undefined
            assertParamExists('getTradeFile', 'tradeSourceName', tradeSourceName)
            const localVarPath = `/v3/account/{externalAccountId}/tradeFiles/{tradeSourceName}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"tradeSourceName"}}`, encodeURIComponent(String(tradeSourceName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of failed trades associated with the account and apply filters based on limit and portfolio ID.
         * @summary Retrieve a list of failed trades attached to the account
         * @param {string} externalAccountId 
         * @param {number} [limit] 
         * @param {string} [portfolioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransitTradesForAccount: async (externalAccountId: string, limit?: number, portfolioId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getTransitTradesForAccount', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/transitTrades`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (portfolioId !== undefined) {
                localVarQueryParameter['portfolioId'] = portfolioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of failed trades from a previously uploaded file, including error descriptions. 
         * @summary Retrieve a list of failed trades from previously uploaded file
         * @param {string} externalAccountId 
         * @param {string} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransitTradesForFile: async (externalAccountId: string, file: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getTransitTradesForFile', 'externalAccountId', externalAccountId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('getTransitTradesForFile', 'file', file)
            const localVarPath = `/v3/account/{externalAccountId}/tradeFiles/{file}/transitTrades`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"file"}}`, encodeURIComponent(String(file)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of uploaded trade files where each row identifies the portfolio and trade details.
         * @summary Retrieve a list of uploaded trade files
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTradeFiles: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('listTradeFiles', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/tradeFiles`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return all users or filter them by account role, or use includeAssigned to see Customers that are assigned to a workgroup.
         * @summary Retrieve a list of users associated with the account
         * @param {string} externalAccountId 
         * @param {'OWNER' | 'MANAGER' | 'CUSTOMER' | 'RO_CUSTOMER'} [role] 
         * @param {boolean} [includeAssigned] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (externalAccountId: string, role?: 'OWNER' | 'MANAGER' | 'CUSTOMER' | 'RO_CUSTOMER', includeAssigned?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('listUsers', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/users`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (includeAssigned !== undefined) {
                localVarQueryParameter['includeAssigned'] = includeAssigned;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user account preferences based on user criteria such as Default Risk Asset View, Performance View, etc.
         * @summary Update the preferences for the user\'s account
         * @param {AccountPreferences} accountPreferences 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreferences1: async (accountPreferences: AccountPreferences, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountPreferences' is not null or undefined
            assertParamExists('updatePreferences1', 'accountPreferences', accountPreferences)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('updatePreferences1', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/preferences`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountPreferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an accounts user
         * @param {UserUpdate} userUpdate 
         * @param {string} externalAccountId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userUpdate: UserUpdate, externalAccountId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('updateUser', 'userUpdate', userUpdate)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('updateUser', 'externalAccountId', externalAccountId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            const localVarPath = `/v3/account/{externalAccountId}/user/{userId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Import account-level trade file in Ilio with comma-separated values, identifying portfolios and trade details per row. 
         * @summary Upload an account level comma separated trade file
         * @param {string} externalAccountId 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTrades: async (externalAccountId: string, inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('uploadTrades', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/tradeFile`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountManagementApi - functional programming interface
 * @export
 */
export const AccountManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve all the account and usage details for the user\'s account.
         * @summary Retrieve account and usage details
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountDetails(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDetailsDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountDetails(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a summary list of all the accounts associated with the user.
         * @summary Retrieve the list of accounts for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountSummaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountSummaryDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountSummaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve account capabilities, actions and limits. Capabilities are the list of business capabilities that account is allowed to access. Actions are the list of actions that the user can perform on the account. Limits on the account include maxPortfolios allowed, maxTrades etc. ScreenMask is screens that should be hidden in the UI. AllowedConnections are the broker connections that the account is allowed to use. 
         * @summary Retrieve account capabilities, actions and limits
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUserCapabilities(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountUserCapabilitiesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUserCapabilities(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates an exclusive set of public and secret API keys for the account.
         * @summary Generate a unique ApiKey for the account
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApiKey(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeyReveal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApiKey(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Apikey attached to the account. 
         * @summary Delete ApiKey attached to the account
         * @param {string} externalAccountId 
         * @param {string} apiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApiKey1(externalAccountId: string, apiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApiKey1(externalAccountId, apiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register a new user to access wealth management API\'s. 
         * @summary Register a new API user
         * @param {ApiUserDetailsDTO} apiUserDetailsDTO 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApiUser(apiUserDetailsDTO: ApiUserDetailsDTO, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApiUser(apiUserDetailsDTO, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register a new GUI user to access wealth management portal. 
         * @summary Register a new GUI user
         * @param {UserDetailsDTO} userDetailsDTO 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGuiUser(userDetailsDTO: UserDetailsDTO, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGuiUser(userDetailsDTO, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update users in bulk
         * @param {Array<BulkUserUpdate>} bulkUserUpdate 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateUser(bulkUserUpdate: Array<BulkUserUpdate>, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateUser(bulkUserUpdate, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves users account status and issues list. 
         * @summary Retrieve users account status information
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAuthorisation1(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAuthorisation1(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the list of all the portfolios associated with the account.
         * @summary Retrieve a list of portfolios associated with the account
         * @param {string} externalAccountId 
         * @param {'LIVE' | 'PAPER'} [type] 
         * @param {'ACTIVE' | 'DRAFT' | 'HIDDEN' | 'DELETING'} [accessType] 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserPortfolios1(externalAccountId: string, type?: 'LIVE' | 'PAPER', accessType?: 'ACTIVE' | 'DRAFT' | 'HIDDEN' | 'DELETING', workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortfolioResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserPortfolios1(externalAccountId, type, accessType, workgroupType, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve summary of portfolios associated to this account
         * @summary Retrieve summary of portfolios associated to this account
         * @param {string} externalAccountId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserPortfoliosSummary(externalAccountId: string, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortfolioResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserPortfoliosSummary(externalAccountId, displayCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of uploaded trade files where each row identifies the portfolio , trade details and its processing status.
         * @summary Retrieve a list of uploaded trade files with processing status
         * @param {string} externalAccountId 
         * @param {string} tradeSourceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradeFile(externalAccountId: string, tradeSourceName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradeFile(externalAccountId, tradeSourceName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of failed trades associated with the account and apply filters based on limit and portfolio ID.
         * @summary Retrieve a list of failed trades attached to the account
         * @param {string} externalAccountId 
         * @param {number} [limit] 
         * @param {string} [portfolioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransitTradesForAccount(externalAccountId: string, limit?: number, portfolioId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransitTradeSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransitTradesForAccount(externalAccountId, limit, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of failed trades from a previously uploaded file, including error descriptions. 
         * @summary Retrieve a list of failed trades from previously uploaded file
         * @param {string} externalAccountId 
         * @param {string} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransitTradesForFile(externalAccountId: string, file: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransitTradeSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransitTradesForFile(externalAccountId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of uploaded trade files where each row identifies the portfolio and trade details.
         * @summary Retrieve a list of uploaded trade files
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTradeFiles(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UploadedFileLight>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTradeFiles(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return all users or filter them by account role, or use includeAssigned to see Customers that are assigned to a workgroup.
         * @summary Retrieve a list of users associated with the account
         * @param {string} externalAccountId 
         * @param {'OWNER' | 'MANAGER' | 'CUSTOMER' | 'RO_CUSTOMER'} [role] 
         * @param {boolean} [includeAssigned] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(externalAccountId: string, role?: 'OWNER' | 'MANAGER' | 'CUSTOMER' | 'RO_CUSTOMER', includeAssigned?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserSummaryDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(externalAccountId, role, includeAssigned, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user account preferences based on user criteria such as Default Risk Asset View, Performance View, etc.
         * @summary Update the preferences for the user\'s account
         * @param {AccountPreferences} accountPreferences 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreferences1(accountPreferences: AccountPreferences, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountPreferences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreferences1(accountPreferences, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an accounts user
         * @param {UserUpdate} userUpdate 
         * @param {string} externalAccountId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userUpdate: UserUpdate, externalAccountId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userUpdate, externalAccountId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Import account-level trade file in Ilio with comma-separated values, identifying portfolios and trade details per row. 
         * @summary Upload an account level comma separated trade file
         * @param {string} externalAccountId 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTrades(externalAccountId: string, inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTrades(externalAccountId, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountManagementApi - factory interface
 * @export
 */
export const AccountManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountManagementApiFp(configuration)
    return {
        /**
         * Retrieve all the account and usage details for the user\'s account.
         * @summary Retrieve account and usage details
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDetails(externalAccountId: string, options?: any): AxiosPromise<AccountDetailsDtoV3> {
            return localVarFp.accountDetails(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a summary list of all the accounts associated with the user.
         * @summary Retrieve the list of accounts for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSummaries(options?: any): AxiosPromise<Array<AccountSummaryDTO>> {
            return localVarFp.accountSummaries(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve account capabilities, actions and limits. Capabilities are the list of business capabilities that account is allowed to access. Actions are the list of actions that the user can perform on the account. Limits on the account include maxPortfolios allowed, maxTrades etc. ScreenMask is screens that should be hidden in the UI. AllowedConnections are the broker connections that the account is allowed to use. 
         * @summary Retrieve account capabilities, actions and limits
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUserCapabilities(externalAccountId: string, options?: any): AxiosPromise<AccountUserCapabilitiesDTO> {
            return localVarFp.accountUserCapabilities(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates an exclusive set of public and secret API keys for the account.
         * @summary Generate a unique ApiKey for the account
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApiKey(externalAccountId: string, options?: any): AxiosPromise<ApiKeyReveal> {
            return localVarFp.addApiKey(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Apikey attached to the account. 
         * @summary Delete ApiKey attached to the account
         * @param {string} externalAccountId 
         * @param {string} apiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApiKey1(externalAccountId: string, apiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.addApiKey1(externalAccountId, apiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Register a new user to access wealth management API\'s. 
         * @summary Register a new API user
         * @param {ApiUserDetailsDTO} apiUserDetailsDTO 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApiUser(apiUserDetailsDTO: ApiUserDetailsDTO, externalAccountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.addApiUser(apiUserDetailsDTO, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Register a new GUI user to access wealth management portal. 
         * @summary Register a new GUI user
         * @param {UserDetailsDTO} userDetailsDTO 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGuiUser(userDetailsDTO: UserDetailsDTO, externalAccountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.addGuiUser(userDetailsDTO, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update users in bulk
         * @param {Array<BulkUserUpdate>} bulkUserUpdate 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUser(bulkUserUpdate: Array<BulkUserUpdate>, externalAccountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.bulkUpdateUser(bulkUserUpdate, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves users account status and issues list. 
         * @summary Retrieve users account status information
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthorisation1(externalAccountId: string, options?: any): AxiosPromise<AccountInfo> {
            return localVarFp.checkAuthorisation1(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the list of all the portfolios associated with the account.
         * @summary Retrieve a list of portfolios associated with the account
         * @param {string} externalAccountId 
         * @param {'LIVE' | 'PAPER'} [type] 
         * @param {'ACTIVE' | 'DRAFT' | 'HIDDEN' | 'DELETING'} [accessType] 
         * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserPortfolios1(externalAccountId: string, type?: 'LIVE' | 'PAPER', accessType?: 'ACTIVE' | 'DRAFT' | 'HIDDEN' | 'DELETING', workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options?: any): AxiosPromise<Array<PortfolioResponse>> {
            return localVarFp.getCurrentUserPortfolios1(externalAccountId, type, accessType, workgroupType, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve summary of portfolios associated to this account
         * @summary Retrieve summary of portfolios associated to this account
         * @param {string} externalAccountId 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserPortfoliosSummary(externalAccountId: string, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: any): AxiosPromise<Array<PortfolioResponse>> {
            return localVarFp.getCurrentUserPortfoliosSummary(externalAccountId, displayCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of uploaded trade files where each row identifies the portfolio , trade details and its processing status.
         * @summary Retrieve a list of uploaded trade files with processing status
         * @param {string} externalAccountId 
         * @param {string} tradeSourceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeFile(externalAccountId: string, tradeSourceName: string, options?: any): AxiosPromise<UploadedFileStatus> {
            return localVarFp.getTradeFile(externalAccountId, tradeSourceName, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of failed trades associated with the account and apply filters based on limit and portfolio ID.
         * @summary Retrieve a list of failed trades attached to the account
         * @param {string} externalAccountId 
         * @param {number} [limit] 
         * @param {string} [portfolioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransitTradesForAccount(externalAccountId: string, limit?: number, portfolioId?: string, options?: any): AxiosPromise<Array<TransitTradeSummary>> {
            return localVarFp.getTransitTradesForAccount(externalAccountId, limit, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of failed trades from a previously uploaded file, including error descriptions. 
         * @summary Retrieve a list of failed trades from previously uploaded file
         * @param {string} externalAccountId 
         * @param {string} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransitTradesForFile(externalAccountId: string, file: string, options?: any): AxiosPromise<Array<TransitTradeSummary>> {
            return localVarFp.getTransitTradesForFile(externalAccountId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of uploaded trade files where each row identifies the portfolio and trade details.
         * @summary Retrieve a list of uploaded trade files
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTradeFiles(externalAccountId: string, options?: any): AxiosPromise<Array<UploadedFileLight>> {
            return localVarFp.listTradeFiles(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return all users or filter them by account role, or use includeAssigned to see Customers that are assigned to a workgroup.
         * @summary Retrieve a list of users associated with the account
         * @param {string} externalAccountId 
         * @param {'OWNER' | 'MANAGER' | 'CUSTOMER' | 'RO_CUSTOMER'} [role] 
         * @param {boolean} [includeAssigned] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(externalAccountId: string, role?: 'OWNER' | 'MANAGER' | 'CUSTOMER' | 'RO_CUSTOMER', includeAssigned?: boolean, options?: any): AxiosPromise<Array<UserSummaryDTO>> {
            return localVarFp.listUsers(externalAccountId, role, includeAssigned, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user account preferences based on user criteria such as Default Risk Asset View, Performance View, etc.
         * @summary Update the preferences for the user\'s account
         * @param {AccountPreferences} accountPreferences 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreferences1(accountPreferences: AccountPreferences, externalAccountId: string, options?: any): AxiosPromise<AccountPreferences> {
            return localVarFp.updatePreferences1(accountPreferences, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an accounts user
         * @param {UserUpdate} userUpdate 
         * @param {string} externalAccountId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userUpdate: UserUpdate, externalAccountId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(userUpdate, externalAccountId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Import account-level trade file in Ilio with comma-separated values, identifying portfolios and trade details per row. 
         * @summary Upload an account level comma separated trade file
         * @param {string} externalAccountId 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTrades(externalAccountId: string, inlineObject?: InlineObject, options?: any): AxiosPromise<void> {
            return localVarFp.uploadTrades(externalAccountId, inlineObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountManagementApi - object-oriented interface
 * @export
 * @class AccountManagementApi
 * @extends {BaseAPI}
 */
export class AccountManagementApi extends BaseAPI {
    /**
     * Retrieve all the account and usage details for the user\'s account.
     * @summary Retrieve account and usage details
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountDetails(externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).accountDetails(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a summary list of all the accounts associated with the user.
     * @summary Retrieve the list of accounts for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountSummaries(options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).accountSummaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve account capabilities, actions and limits. Capabilities are the list of business capabilities that account is allowed to access. Actions are the list of actions that the user can perform on the account. Limits on the account include maxPortfolios allowed, maxTrades etc. ScreenMask is screens that should be hidden in the UI. AllowedConnections are the broker connections that the account is allowed to use. 
     * @summary Retrieve account capabilities, actions and limits
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountUserCapabilities(externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).accountUserCapabilities(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates an exclusive set of public and secret API keys for the account.
     * @summary Generate a unique ApiKey for the account
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public addApiKey(externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).addApiKey(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Apikey attached to the account. 
     * @summary Delete ApiKey attached to the account
     * @param {string} externalAccountId 
     * @param {string} apiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public addApiKey1(externalAccountId: string, apiKey: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).addApiKey1(externalAccountId, apiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a new user to access wealth management API\'s. 
     * @summary Register a new API user
     * @param {ApiUserDetailsDTO} apiUserDetailsDTO 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public addApiUser(apiUserDetailsDTO: ApiUserDetailsDTO, externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).addApiUser(apiUserDetailsDTO, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a new GUI user to access wealth management portal. 
     * @summary Register a new GUI user
     * @param {UserDetailsDTO} userDetailsDTO 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public addGuiUser(userDetailsDTO: UserDetailsDTO, externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).addGuiUser(userDetailsDTO, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update users in bulk
     * @param {Array<BulkUserUpdate>} bulkUserUpdate 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public bulkUpdateUser(bulkUserUpdate: Array<BulkUserUpdate>, externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).bulkUpdateUser(bulkUserUpdate, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves users account status and issues list. 
     * @summary Retrieve users account status information
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public checkAuthorisation1(externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).checkAuthorisation1(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the list of all the portfolios associated with the account.
     * @summary Retrieve a list of portfolios associated with the account
     * @param {string} externalAccountId 
     * @param {'LIVE' | 'PAPER'} [type] 
     * @param {'ACTIVE' | 'DRAFT' | 'HIDDEN' | 'DELETING'} [accessType] 
     * @param {'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL'} [workgroupType] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public getCurrentUserPortfolios1(externalAccountId: string, type?: 'LIVE' | 'PAPER', accessType?: 'ACTIVE' | 'DRAFT' | 'HIDDEN' | 'DELETING', workgroupType?: 'CLIENT' | 'INTERNAL' | 'GLOBAL' | 'MODEL', page?: number, size?: number, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).getCurrentUserPortfolios1(externalAccountId, type, accessType, workgroupType, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve summary of portfolios associated to this account
     * @summary Retrieve summary of portfolios associated to this account
     * @param {string} externalAccountId 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [displayCurrency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public getCurrentUserPortfoliosSummary(externalAccountId: string, displayCurrency?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).getCurrentUserPortfoliosSummary(externalAccountId, displayCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of uploaded trade files where each row identifies the portfolio , trade details and its processing status.
     * @summary Retrieve a list of uploaded trade files with processing status
     * @param {string} externalAccountId 
     * @param {string} tradeSourceName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public getTradeFile(externalAccountId: string, tradeSourceName: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).getTradeFile(externalAccountId, tradeSourceName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of failed trades associated with the account and apply filters based on limit and portfolio ID.
     * @summary Retrieve a list of failed trades attached to the account
     * @param {string} externalAccountId 
     * @param {number} [limit] 
     * @param {string} [portfolioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public getTransitTradesForAccount(externalAccountId: string, limit?: number, portfolioId?: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).getTransitTradesForAccount(externalAccountId, limit, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of failed trades from a previously uploaded file, including error descriptions. 
     * @summary Retrieve a list of failed trades from previously uploaded file
     * @param {string} externalAccountId 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public getTransitTradesForFile(externalAccountId: string, file: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).getTransitTradesForFile(externalAccountId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of uploaded trade files where each row identifies the portfolio and trade details.
     * @summary Retrieve a list of uploaded trade files
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public listTradeFiles(externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).listTradeFiles(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return all users or filter them by account role, or use includeAssigned to see Customers that are assigned to a workgroup.
     * @summary Retrieve a list of users associated with the account
     * @param {string} externalAccountId 
     * @param {'OWNER' | 'MANAGER' | 'CUSTOMER' | 'RO_CUSTOMER'} [role] 
     * @param {boolean} [includeAssigned] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public listUsers(externalAccountId: string, role?: 'OWNER' | 'MANAGER' | 'CUSTOMER' | 'RO_CUSTOMER', includeAssigned?: boolean, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).listUsers(externalAccountId, role, includeAssigned, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user account preferences based on user criteria such as Default Risk Asset View, Performance View, etc.
     * @summary Update the preferences for the user\'s account
     * @param {AccountPreferences} accountPreferences 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public updatePreferences1(accountPreferences: AccountPreferences, externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).updatePreferences1(accountPreferences, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an accounts user
     * @param {UserUpdate} userUpdate 
     * @param {string} externalAccountId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public updateUser(userUpdate: UserUpdate, externalAccountId: string, userId: string, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).updateUser(userUpdate, externalAccountId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Import account-level trade file in Ilio with comma-separated values, identifying portfolios and trade details per row. 
     * @summary Upload an account level comma separated trade file
     * @param {string} externalAccountId 
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public uploadTrades(externalAccountId: string, inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return AccountManagementApiFp(this.configuration).uploadTrades(externalAccountId, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
}
