import { FunctionComponent, useContext, useState } from "react";
import React from "react";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import IllioDialog from "../IllioDialog/IllioDialog";
import RoundButton from "../RoundButton/RoundButton";

interface IRemoveModalContext {
  openModal(
    onConfirm: () => Promise<void>,
    text?: string,
    replace?: boolean
  ): void;
  closeModal(): void;
  setLoading(v: boolean): void;
}
const noop = console.log(`modal not initialised`);

const RemoveModalContext = React.createContext<IRemoveModalContext>({
  openModal: () => noop,
  closeModal: () => noop,
  setLoading: () => noop,
});

export const RemoveModalContextProvider: FunctionComponent = ({ children }) => {
  const [text, setText] = React.useState<string>("");
  const [replace, setReplace] = React.useState<boolean>(false);
  const [onConfirm, setOnConfirm] = React.useState<() => Promise<void>>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModal = (
    onConfirm: () => Promise<void>,
    text?: string,
    replace?: boolean
  ) => {
    setText(text || "");
    setOnConfirm(() => onConfirm);
    setVisible(true);
    setReplace(replace || false);
  };

  const closeModal = () => {
    setVisible(false);
    setText("");
    setOnConfirm(undefined);
  };

  return (
    <RemoveModalContext.Provider
      value={{
        openModal,
        closeModal,
        setLoading,
      }}
    >
      {visible && (
        <IllioDialog
          className={"illio-dialog"}
          title={replace ? "Attention" : `Delete ${text}`}
          onClose={() => closeModal()}
        >
          <div style={{ margin: "2rem 0" }}>
            {replace ? text : `Are you sure you want to delete this ${text}?`}
          </div>
          <DialogActionsBar layout={"center"}>
            <RoundButton
              style={{ maxWidth: 160 }}
              fillMode={"outline"}
              onClick={closeModal}
            >
              Cancel
            </RoundButton>
            <RoundButton
              loading={loading}
              style={{ maxWidth: 160 }}
              onClick={onConfirm}
            >
              Delete
            </RoundButton>
          </DialogActionsBar>
        </IllioDialog>
      )}
      {children}
    </RemoveModalContext.Provider>
  );
};

export const useRemoveModal = () => useContext(RemoveModalContext);
