import { makeAutoObservable } from "mobx";

export const TransactionsUploadStateStore = () =>
  makeAutoObservable({
    loading: false,
    setLoading(val: boolean) {
      this.loading = val;
    },
  });

export default TransactionsUploadStateStore;
