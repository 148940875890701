import * as React from "react";
import { useEffect } from "react";

interface IProps {
  callback: () => void;
  delay: number;
}

export const useDelay = ({ callback, delay }: IProps) => {
  useEffect(() => {
    const timer = setTimeout(callback, delay);
    return () => clearTimeout(timer);
  }, [callback, delay]);
};

export default useDelay;
