import React from "react";
import {
  PortfolioApi,
  PortfolioBenchmarkIndex,
  PortfolioCustodian,
} from "@iliotech/generated-api-v3";
import { useQuery, useQueryClient } from "react-query";
import { API_BASE } from "../../../constants/constants";

export const usePortfolio = (portfolioId: string, options?: any) => {
  const api = new PortfolioApi(undefined, `${API_BASE}/api`);
  const [showNegativeBalanceModal, setShowNegativeBalanceModal] = React.useState(false);
  const client = useQueryClient();

  const query = useQuery(
    [`portfolio`, { portfolioId }],
    () => api.get2(portfolioId),
    {
      enabled: !!portfolioId,
      refetchOnMount: "always",
      ...options,
    }
  );
  const totalWealth = query.data?.data?.totalWealth;
  React.useEffect(() => {
    if(typeof totalWealth !== "undefined" && totalWealth < 0) {
      setShowNegativeBalanceModal(true);
    }
  }, [totalWealth]);

  const refetchQueries = () => {
    query.refetch();
    void client.refetchQueries({
      active: true,
      predicate: (query) => {
        return (
          Array.isArray(query.queryKey) &&
          (query.queryKey[0] === `portfolio-performance` ||
            query.queryKey[0] === `portfolio-category-insights` ||
            query.queryKey[0] === `portfolio-dashboard-insights`)
        );
      },
    });
  };
  const addCustodian = (name: string, isDefault?: boolean) => {
    return api
      .updatePortfolio(
        {
          custodians: new Set([
            {
              name,
              code: name,
              default: isDefault,
            },
          ]),
        },
        portfolioId
      )
      .then(() => {
        query.refetch?.();
      });
  };
  const setDefaultCustodian = (custodian: PortfolioCustodian) => {
    return api
      .updatePortfolio(
        {
          //@ts-ignore - generated API dictates a Set, but should be an array...
          custodians: [{ ...custodian, default: true }],
        },
        portfolioId
      )
      .then(() => {
        query.refetch?.();
      });
  };
  const savePortfolioName = (name: string) => {
    return api
      .updatePortfolio(
        {
          name,
        },
        portfolioId
      )
      .then(() => {
        query.refetch?.();
      });
  };
  const setBenchmark = (benchmarkIndex: PortfolioBenchmarkIndex) => {
    return api
      .updatePortfolio(
        {
          benchmarkIndex,
        },
        portfolioId
      )
      .then(() => {
        refetchQueries();
      });
  };
  
  const isModel =
    query.data?.data?.workgroupType === "MODEL" ||
    query.data?.data?.workgroupType === "GLOBAL";

  return {
    addCustodian,
    setDefaultCustodian,
    savePortfolioName,
    setBenchmark,
    showNegativeBalanceModal,
    setShowNegativeBalanceModal,
    isModel,
    ...query,
  };
};
