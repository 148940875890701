import React from 'react';
import {Field, Form} from "@progress/kendo-react-form";
import {matchValidator, notEmptyValidator, passwordValidator} from "@iliotech/data-wire";
import Button from '../Button/Button';
import {Input} from "../FormInputs";
import {useNotification} from "../Notifications";


interface IOwnProps {
  onSubmit(currentPassword: string, newPassword: string, onSuccess?: () => void): Promise<any>;
}


export const ResetPasswordComponent = ({onSubmit}: IOwnProps) => {
  const notify = useNotification()
  
  const handleSubmit  = async (data: any) => {
    if(data.newPassword === data.repeatPassword){
      const onSuccess = () => {
        notify.success({title: "Success", description: "Password Changed"});
      }
      onSubmit(data.currentPassword, data.newPassword, onSuccess)
        .catch((error) => {
          notify.error({title: "Error", description: error?.message || "Something went wrong when trying to reset your password"})
        })
    } else {
      notify.error({title: "Password's don't match", description: "Please make sure you repeat your password correctly"})
  
    }
  }
  
  return (
    
      <Form
        onSubmit={handleSubmit}
        ignoreModified={true}
        render={(formRenderProps) => (
          <div>
            <h6>Reset Password</h6>
            {
              [
                {name: "currentPassword", label: "Current Password", validator: notEmptyValidator},
                {name: "newPassword", label: "New Password", validator: passwordValidator},
                {name: "repeatPassword", label: "Repeat Password", validator: matchValidator(formRenderProps.valueGetter("newPassword"))}
              ].map(({name, label, validator}) => (
                <div key={name} style={{marginBottom: "0.5rem"}}>
                  <Field
                    name={name}
                    error={formRenderProps.errors?.[name]}
                    component={Input}
                    label={label}
                    validator={validator}
                    type={"password"}
                  />
                </div>
              ))
            }
            <Button onClick={formRenderProps.onSubmit} style={{margin: "0.5rem 0 1rem"}}>Reset Password</Button>

          </div>
        )}
      />
  );
};
