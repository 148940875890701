import React from "react";
import {
  EquityOptionMoneynessBand,
  EquityOptionMoneynessBandV3,
} from "@iliotech/generated-api-v3";
import { Typography } from "@progress/kendo-react-common";
import { IllioTable } from "../../IllioTable/IllioTable";
import { IColumn } from "../../IllioTable/types";
import { ConnectedInformationButton } from "../../InformationButton";

interface IOwnProps {
  data?: (EquityOptionMoneynessBandV3 & { isTotal?: number })[];
  ready?: boolean;
  title?: string;
}

const COLUMNS: IColumn[] = [
  {
    field: "label",
    title: "Moneyness",
    isDefault: true,
    renderer: IllioTable.TextCell({
      checkIsTotal: (dataItem) => dataItem?.isTotal === 1,
    }),
  },
  {
    field: "currentValueBase",
    title: "Current Premium",
    renderer: IllioTable.DecimalCell({ maxDecimals: 0 }),
    rightAligned: true,

    isDefault: true,
  },
  {
    field: "notional",
    title: "Notional",
    renderer: IllioTable.DecimalCell({ maxDecimals: 0 }),
    rightAligned: true,

    isDefault: true,
  },
  {
    field: "deltaExposure",
    title: "Delta Exposure",
    renderer: IllioTable.DecimalCell({ maxDecimals: 0 }),
    rightAligned: true,
    isDefault: true,
  },
];

export const MoneynessTable = ({ data, ready, title }: IOwnProps) => {
  if (!ready) {
    return <div style={{ width: "100%", height: 100 }} className={"shine"} />;
  }
  return (
    <div style={{ marginBottom: "2rem" }}>
      <ConnectedInformationButton
        extraClass={"with-margin-bottom"}
        code={"option" + title}
      >
        <Typography.h6 className={"element-title"}>{title}</Typography.h6>
      </ConnectedInformationButton>
      <IllioTable
        dataCyPrefix={"moneyness-table"}
        columns={COLUMNS}
        data={data?.map((d) => ({ ...d, key: `moneyness-${d.label}` })) ?? []}
        enforceSort={[{ field: "isTotal", dir: "asc" }]}
        // enforceSort={(sorts) => !sorts?.length ? undefined : [{field: "isTotal", dir: "asc"}]}
        useColumnChooser={false}
      />
      {/*<pre style={{ fontSize: 12 }}>{JSON.stringify({ data }, null, 2)}</pre>*/}
    </div>
  );
};
