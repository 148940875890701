import React, { useState } from "react";
import s from "./UnderlineTabs.module.scss";
import classNames from "classnames";

interface IProps {
  tabs: string[];
  onTabChange: (tab: string) => void;
  initialTab?: string;
}

export const UnderlineTabs = ({ tabs, onTabChange, initialTab }: IProps) => {
  const [active, setActive] = useState(tabs?.[0] || initialTab);
  const onTabClick = (tab: string) => {
    setActive(tab);
    onTabChange(tab);
  };

  return (
    <div className={s.container}>
      {tabs.map((tab) => (
        <div
          className={classNames(active === tab && s.active, s.tab)}
          key={tab}
          onClick={() => onTabClick(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};
