import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import {
  notEmptyValidator,
  positiveValidator,
  yearValidator,
} from "@iliotech/data-wire";
import * as React from "react";
import { Input, InputProps } from "../Input/Input";

interface IOwnProps extends Omit<InputProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  validator?: (value: string) => string;
}

export const YearField = ({
  errors,
  name = "year",
  label = "Year",
  ...rest
}: IOwnProps) => {
  return (
    <Field
      name={name}
      error={errors?.[name]}
      component={Input}
      format={{ maximumFractionDigits: 0 }}
      placeholder={"Enter year"}
      label={label ?? undefined}
      validator={yearValidator}
      {...rest}
    />
  );
};
