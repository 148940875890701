import { API_BASE } from "../constants/constants";
import {
  AssignDto,
  PortfolioAndResourceGroupsWorkgroupsApi,
} from "@iliotech/generated-api-v3";
const api = new PortfolioAndResourceGroupsWorkgroupsApi(
  undefined,
  `${API_BASE}/api`
);
export const deletePortfolio = (
  externalAccountId: string,
  portfolioId: string
) => {
  if (!!externalAccountId && !!portfolioId) {
    return api.deletePortfolio(externalAccountId, portfolioId);
  }

  return Promise.reject("No externalAccountId passed to assignToWorkgroup");
};
