import {
  CreatePositionRequest,
  PortfolioTradesApi,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);
export const addPosition = (
  portfolioId: string,
  position: CreatePositionRequest
) => {
  return api.addPortfolioPosition(position, portfolioId);
};
