import React from "react";
import {
  EquityOptionData,
  EquityOptionsResponse,
} from "@iliotech/generated-api-v3";
import { EnabledColumnMap, IColumn } from "../../IllioTable/types";
import { IllioTable } from "../../IllioTable/IllioTable";
import { IllioColumnChooser } from "../../IllioTable/IllioColumnChooser";
import { Typography } from "@progress/kendo-react-common";
import s from "./OptionsSummaryTable.module.scss";
interface IOwnProps {
  optionsDetails?: Pick<
    EquityOptionsResponse,
    "longCalls" | "shortCalls" | "longPuts" | "shortPuts"
  >;
  portfolioCurrency?: string;
}

interface ISubTableProps {
  data?: EquityOptionData[];
  title: string;
  enabledColumns: EnabledColumnMap;
  hideHeader?: boolean;
  portfolioCurrency?: string;
}

const DEFAULT_COLUMNS = Object.fromEntries(
  IllioTable.ColumnSets.Options.filter((column) => column.isDefault).map(
    (col) => [col.field, { enabled: true, order: col.order || 1000 }]
  )
);

const columns = IllioTable.ColumnSets.Options;

export const OptionsSummaryTable = ({
  optionsDetails,
  portfolioCurrency,
}: IOwnProps) => {
  const [enabledColumns, setEnabledColumns] =
    React.useState<EnabledColumnMap>(DEFAULT_COLUMNS);

  const { longCalls, longPuts, shortCalls, shortPuts } = optionsDetails || {
    longCalls: [],
    shortCalls: [],
    longPuts: [],
    shortPuts: [],
  };

  return (
    <div style={{ position: "relative" }} className={s.container}>
      <IllioColumnChooser
        columns={IllioTable.ColumnSets.Options}
        columnSettings={enabledColumns}
        setColumnSettings={setEnabledColumns}
        userPreferencesTableId={"OptionsSummary"}
        baseCurrency={portfolioCurrency}
        // buttonStyle={{
        //   position: "static",
        //   marginLeft: "2rem",
        //   top: "unset",
        //   fontSize: "1rem",
        //   right: "unset",
        //   padding: "0.58rem",
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        //   margin: 0,
        // }}
      />
      <SubTable
        data={longCalls}
        title={"Long Calls"}
        enabledColumns={enabledColumns}
        hideHeader={false}
        portfolioCurrency={portfolioCurrency}
      />
      <SubTable
        data={longPuts}
        title={"Long Puts"}
        enabledColumns={enabledColumns}
        portfolioCurrency={portfolioCurrency}
        // hideHeader={!!longCalls.length}
      />
      <SubTable
        data={shortCalls}
        title={"Short Calls"}
        enabledColumns={enabledColumns}
        portfolioCurrency={portfolioCurrency}
        //hideHeader={!!(longCalls.length + longPuts.length)}
      />
      <SubTable
        data={shortPuts}
        title={"Short Puts"}
        enabledColumns={enabledColumns}
        portfolioCurrency={portfolioCurrency}
        //hideHeader={!!(longCalls.length + longPuts.length + shortCalls.length)}
      />
      <SubTable
        data={[...shortPuts, ...longPuts, ...shortCalls, ...longCalls]}
        title={"Grand Total"}
        enabledColumns={enabledColumns}
        portfolioCurrency={portfolioCurrency}
        //hideHeader={!!(longCalls.length + longPuts.length + shortCalls.length)}
      />
    </div>
  );
};

const SubTable = ({
  data,
  title,
  enabledColumns,
  hideHeader,
  portfolioCurrency,
}: ISubTableProps) => {
  const rows = getRows(data!, title);

  if (!rows.length) {
    return null;
  }

  return (
    <div style={{ marginTop: "1rem" }}>
      <Typography.h6
        style={{ fontSize: "1rem", fontWeight: 500, color: "var(--dark)" }}
      >
        {title}
      </Typography.h6>

      <IllioTable
        columns={columns}
        data={rows.map((d) => ({ ...d, key: `equityOption-${d.name}` }))}
        useColumnChooser={false}
        hideHeader={hideHeader}
        baseCurrency={portfolioCurrency}
        // enforceSort={(sorts) => !sorts?.length ? undefined : [{field: "isTotal", dir: "asc"}]}
        enforceSort={[
          { field: "currency", dir: "asc" },
          { field: "isTotal", dir: "asc" },
        ]}
        columnSettings={{
          name: { enabled: true, order: 0 },
          ...enabledColumns,
        }}
      />
    </div>
  );
};

const getRows = (data: EquityOptionData[], title: string) => {
  const currencyTotals: {
    [currencyCode: string]: Partial<EquityOptionData> & { isTotal: true };
  } = {};

  const isGrandTotal = title === "Grand Total";
  data.forEach((item) => {
    currencyTotals[item.currency] = currencyTotals[item.currency] ?? {
      name: `${!isGrandTotal ? "Total " : ""}${title} (${item.currency})`,
      isTotal: true,
      currency: item.currency,
      // openQuantity: 0,
      // underlyingSpotPrice: 0,
      // initialPremium: 0,
      // initialPremiumBase: 0,
      // currentValue: 0,
      // currentValueBase: 0,
      // currentPrice: 0,
      // notional: 0,
      // notionalBase: 0,
      // deltaExposure: 0,
      // deltaExposureBase: 0,
      // delta: 0,
      // impliedVol: 0,
      // maturity: 0,
      // moneyness: 0,
      // annualYield: 0,
      // returnPercent: 0,
      // maxProfitPercent: 0,
      // pnlBase: 0,
      // pnlLocal: 0,
      // costPrice: 0,
    };

    [
      "currentValueBase",
      "pnlBase",
      "deltaExposureBase",
      "notionalBase",
      "initialPremiumBase",
    ].forEach((field) => {
      //@ts-ignore
      currencyTotals[item.currency][field] =
        //@ts-ignore
        (currencyTotals[item.currency][field] ?? 0) + item[field];
    });
  });

  return (
    (isGrandTotal
      ? Object.values(currencyTotals)
      : [...data, ...Object.values(currencyTotals)]) as (EquityOptionData & {
      isTotal?: boolean;
    })[]
  ).sort(
    (a, b) =>
      (a.isTotal ? 1 : 0) +
      (b.isTotal ? -1 : 0) +
      a.currency?.localeCompare(b.currency) * 10
  );
};
