import { IColumn } from "../types";
import { TradeDtoV3 } from "@iliotech/generated-api-v3";
import { DateCell } from "../Cells/DateCell";
import { DecimalCell } from "../Cells/DecimalCell";
import { override, overrideDefaults } from "./helpers";
import { NumberCell } from "../Cells/NumberCell";
import { NameCell } from "../Cells/NameCell";

type TransactionGroup =
  | "Value"
  | "Mandatory"
  | "P&L"
  | "Option"
  | "Descriptive"
  | "Bond"
  | "Trade Info";
type TransactionColumn = IColumn<TradeDtoV3> & {
  group?: TransactionGroup;
};

export const Trades: TransactionColumn[] = [
  {
    field: "tradeTime",
    title: "Trade Date",
    renderer: DateCell(),
    isDefault: true,
    width: 100,
    order: 1,
    group: "Trade Info",
  },
  {
    field: "instrumentCode" as any,
    title: "Ticker",
    isDefault: true,
    order: 20,
    group: "Descriptive",
  },
  {
    field: "assetClassName",
    title: "Asset Class",
    // isDefault: true,
    order: 50,
    group: "Descriptive",
  },
  // {
  //   field: "assetSubClassName",
  //   title: "Instrument Type",
  //   isDefault: true,
  //   order: 60,
  //   group: "Descriptive",
  // },
  {
    field: "instrumentName" as any,
    title: "Name",
    isDefault: true,
    order: 10,
    width: 200,
    renderer: NameCell(),
    mandatory: true,
    group: "Descriptive",
  },
  {
    field: "custodianName",
    title: "Account",
    isDefault: true,
    order: 110,
    group: "Descriptive",
  },
  {
    field: "operationName",
    title: "Operation",
    isDefault: true,
    order: 40,
    group: "Trade Info",
  },
  // { field: "tradeType", title: "Type", isDefault: true, order: 22 },
  {
    field: "quantity",
    isDefault: true,
    order: 30,
    centerAligned: true,
    renderer: DecimalCell({}),
    group: "Value",
  },
  {
    field: "price",
    title: "Cost Price",
    isDefault: true,
    centerAligned: true,
    renderer: DecimalCell({}),
    order: 140,
    group: "Trade Info",
  },
  /*  {
    field: "amount",
    title: "TBC value (£)",
    order: 40,
    isDefault: true,
    renderer: DecimalCell({ maxDecimals: 0 }),
  },*/
  {
    field: "currencyCode",
    title: "Currency",
    order: 70,
    isDefault: true,
    group: "Descriptive",
  },
  // { field: "fxRate", order: 90 }, // use displayBaseFx instead
  {
    field: "commission",
    order: 240,
    centerAligned: true,
    renderer: DecimalCell({}),
    group: "Trade Info",
  },
  {
    field: "tradeCosts",
    title: "Other Charges",
    order: 250,
    group: "P&L",
    centerAligned: true,
    renderer: DecimalCell({}),
  },
  { field: "notes", order: 750, group: "Trade Info" },
  {
    field: "netAmountLocal",
    title: "Transaction Value (local)",
    centerAligned: true,
    renderer: DecimalCell({ maxDecimals: 0 }),
    order: 120,
    group: "Value",
  },
  {
    field: "netAmountBase",
    title: "Transaction Value (£)",
    order: 130,
    centerAligned: true,
    renderer: DecimalCell({ maxDecimals: 0 }),
    group: "Value",
  },
  // { field: "netAmountLocal" },
  {
    field: "multiplier",
    order: 390,
    centerAligned: true,
    renderer: DecimalCell({ maxDecimals: 2 }),
    isDefault: true,
    group: "Option",
  },
  {
    field: "settlementDate",
    order: 290,
    title: "Settle Date",
    group: "Trade Info",
  },
  {
    field: "accruedInterestLocal",
    title: "Accrued Interest (local)",
    order: 630,
    centerAligned: true,
    renderer: DecimalCell({ maxDecimals: 0 }),
    group: "Bond",
  },
  {
    field: "accruedInterestBase",
    title: "Accrued Interest (£)",
    order: 620,
    centerAligned: true,
    renderer: DecimalCell({ maxDecimals: 0 }),
    group: "Bond",
  },
  // { field: "accruedInterestIsCalculated" },
  {
    field: "settlementOption",
    title: "Settle Option",
    order: 300,
    group: "Trade Info",
  },
  {
    field: "investmentVehicleName",
    title: "Instrument Type",
    order: 60,
    group: "Descriptive",
  },
  { field: "tradeSource", title: "Source", order: 1000, group: "Trade Info" },
  {
    field: "otherInstrumentName",
    title: "Other Instrument",
    order: 270,
    group: "Trade Info",
  },
  {
    field: "otherQuantity",
    order: 280,
    title: "Other Quantity",
    centerAligned: true,
    renderer: DecimalCell({}),
    group: "Trade Info",
  },
  {
    field: "otherCurrency",
    title: "Other Currency",
    order: 260,
    group: "Trade Info",
  },
  // { field: "displayName" },
  {
    field: "displayBaseFx",
    title: "FX Rate",
    order: 700,
    centerAligned: true,
    renderer: DecimalCell({ maxDecimals: 8 }),
    group: "Trade Info",
  },
  // { field: "historic", order: 60 },
  {
    field: "historicPrice",
    title: "Historic Price",
    order: 720,
    group: "P&L",
    centerAligned: true,
    renderer: DecimalCell({}),
  },
  {
    field: "historicPnl",
    title: "Historic P&L",
    order: 730,
    group: "P&L",
    centerAligned: true,

    renderer: DecimalCell({}),
  },
  // { field: "key" },
];

export const TransactionsPage = Trades;

// const InstrumentTransactionsDefaults: (keyof TradeDtoV3)[] = [
//   "code",
//   "instrumentName" as any,
//   "tradeTime",
//   "instrument",
//   "amount",
//   "custodianName",
//   "currencyCode",
//   "operationName",
//   "tradeType",
//   "quantity",
//   "price",
//   // "netAmountLocal",
//   // "netAmountBase",
// ];

export const InstrumentTransactions = override(
  Trades.filter((f) => f.field !== "assetClassName"),
  {
    currencyCode: { isDefault: false },
  }
);
