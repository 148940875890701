import React, { useEffect } from "react";
import { ITableRow } from "@iliotech/data-wire";
import { fetchInstrumentPrice } from "../../../requests";

interface IProps {
  dataItem: ITableRow;
  updateTableRowByIndex(key: keyof ITableRow, value: any, id: string): void;
}

export const useFetchPriceLite = ({
  dataItem,
  updateTableRowByIndex,
}: IProps) => {
  const setPrice = (data: number) =>
    updateTableRowByIndex("price", data, dataItem.id);
  const date = dataItem?.date;
  const overridePrice = dataItem?.overridePrice;
  const selectedInstrument = dataItem?.selectedInstrument;
  const dateChanged = dataItem?.dateChanged;

  const fetchPrice = async () => {
    if (!dateChanged) {
      setPrice(selectedInstrument?.price || 1);
      return;
    }
    const price = await fetchInstrumentPrice(
      selectedInstrument?.sourceId!,
      date
    );
    setPrice(price.data);
  };

  useEffect(() => {
    if (!!date && !!selectedInstrument && !overridePrice) {
      fetchPrice();
    }
  }, [date, selectedInstrument]);

  return {};
};
