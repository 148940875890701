import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { Input, InputProps } from "../Input/Input";
import { notEmptyValidator } from "@iliotech/data-wire";
import * as React from "react";
import NumericInput from "../NumericInput/NumericInput";

interface IOwnProps extends Omit<InputProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
}

export const FxRateField = ({
  errors,
  name = "fxRate",
  label = "FX Rate",
  ...rest
}: IOwnProps) => {
  return (
    <Field
      name={name}
      error={errors?.[name]}
      component={NumericInput}
      label={label ?? undefined}
      validator={notEmptyValidator}
      {...rest}
    />
  );
};
