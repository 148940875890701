import React, { FC } from "react";
import { IDecimalCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

export const NumberCell = (optionsIn?: {
  greenForPositive?: boolean;
  maxDecimals?: number;
  minDecimals?: number;
}): FC<IDecimalCellProps> => {
  return React.memo(({ width, ...props }) => {
    const maxDecimals = optionsIn?.maxDecimals ?? 0;
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const { greenForPositive } = optionsIn ?? {};

    const field = props.field || "";
    const value = props.dataItem[field] as number;

    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div
          style={{
            width,
            textAlign: "right",
            // fontWeight: 600,
            fontFamily: `"Azeret Mono", "DM Sans", Nunito, sans-serif`,
            color: !greenForPositive
              ? undefined
              : value > 0
              ? "var(--good)"
              : value === 0
              ? undefined
              : "var(--bad)",
          }}
        >
          {value?.toLocaleString?.(undefined, {
            maximumFractionDigits: maxDecimals,
            minimumFractionDigits: optionsIn?.minDecimals,
          })}
        </div>
      </td>
    );
  });
};
