import React from "react";
import { PortfolioResponse } from "../../../../generated-api-v3";
import Loader from "../Loader/Loader";
import BigButtonWithIcon from "../BigButtonWithIcon/BigButtonWithIcon";
import s from "./PortfolioGrid.module.scss";
import clockIcon from "./assets/clock.svg";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useAuth, usePortfolioListGridSharedLogic } from "@iliotech/data-wire";
import { GoKebabHorizontal } from "react-icons/go";
import { useRemoveModal } from "../RemoveModals/RemoveModals";
import { deletePortfolio } from "@iliotech/data-wire/src/requests/deletePortfolio";
import { PortfolioSettingsModal } from "../PortfolioSettings";
interface IOwnProps {
  portfolios?: PortfolioResponse[];
  loading?: boolean;
  refetch?: () => void;
}

export const PortfolioGridWorkgroup = ({
  portfolios = [],
  refetch,
  loading,
}: IOwnProps) => {
  const { visiblePortfolios, showExpand, setExpanded, description } =
    usePortfolioListGridSharedLogic({ portfolios });

  const { illioSelectedAccount, isCustomer } = useAuth();

  const { openModal, closeModal } = useRemoveModal();
  const [settingsOpen, setSettingsOpen] = React.useState<string | null>(null);

  if (loading) {
    return <Loader />;
  }

  if (!portfolios.length) {
    if (isCustomer) {
      return (
        <div className={s.empty}>
          <p>You don't have any portfolios yet.</p>
        </div>
      );
    }
    return (
      <div className={s.empty}>
        <p>You don't have any portfolios in this client group.</p>
        <p>Use the "Create portfolio" button above to get started.</p>
      </div>
    );
  }

  const onConfirm = async (id: string) => {
    try {
      await deletePortfolio(illioSelectedAccount?.externalAccountId!, id);
      closeModal();
      refetch?.();
    } catch (e) {
      console.log(e);
      closeModal();
    }
  };

  const closeSettings = () => {
    setSettingsOpen(null);
  };

  const openSettings = (id: string) => {
    setSettingsOpen(id);
  };

  const dropdownItems = [
    {
      text: "Settings ",
      action: (dataItem: PortfolioResponse) => openSettings(dataItem.id),
    },
    ...(!isCustomer
      ? [
          {
            text: "Delete",
            action: (dataItem: PortfolioResponse) =>
              openModal(() => onConfirm(dataItem.id), "Portfolio"),
          },
        ]
      : []),
  ];

  return (
    <div>
      <PortfolioSettingsModal
        settingsOpen={!!settingsOpen}
        closeSettings={closeSettings}
        passedPortfolioId={settingsOpen!}
      />
      <div className={s.portfolioList}>
        {visiblePortfolios?.map((p) => (
          <BigButtonWithIcon
            key={p.id}
            href={portfolioLink(p.id)}
            extraClass={s.button}
            title={p.portfolioName}
            titleIcon={
              description(p).awaitingIntegrations ? (
                <Tooltip openDelay={100} anchorElement={"target"}>
                  <img
                    src={clockIcon}
                    title={"Your imported trades will be ready soon"}
                  />
                </Tooltip>
              ) : null
            }
            text={description(p).description || p.description || ""}
            dropdowwnProps={{
              data: dropdownItems,
              textField: "text",
              dataItemKey: "text",
              onSelect: (e) => e.value?.action?.(p),
              button: (
                <div className={s.buttonSurround}>
                  <GoKebabHorizontal color={"var(--dark)"} />
                </div>
              ),
            }}
          />
        ))}
      </div>
      {showExpand && (
        <button
          style={{ width: 120, marginTop: "1rem" }}
          onClick={() => setExpanded(true)}
        >
          Show all
        </button>
      )}
    </div>
  );
};

const portfolioLink = (id: string) => `/portfolio/${id}`;
