/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { DatedValueDto } from '../../aggregator';
// @ts-ignore
import { DatedValueUpdateRequest } from '../../aggregator';
// @ts-ignore
import { DividendRequest } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { InstrumentSummaryV3 } from '../../aggregator';
// @ts-ignore
import { InstrumentUpdateRequest } from '../../aggregator';
// @ts-ignore
import { PriceRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestEquitySystemInstrumentRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestFundSystemInstrumentRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestFutureSystemInstrumentRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestFxForwardSystemInstrumentRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestLoanSystemInstrumentRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest } from '../../aggregator';
// @ts-ignore
import { PricedInstrumentCreateRequestTermDepositSystemInstrumentRequest } from '../../aggregator';
/**
 * OTCInstrumentsApi - axios parameter creator
 * @export
 */
export const OTCInstrumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PricedInstrumentCreateRequestEquitySystemInstrumentRequest} pricedInstrumentCreateRequestEquitySystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEquityInstrument: async (pricedInstrumentCreateRequestEquitySystemInstrumentRequest: PricedInstrumentCreateRequestEquitySystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestEquitySystemInstrumentRequest' is not null or undefined
            assertParamExists('addEquityInstrument', 'pricedInstrumentCreateRequestEquitySystemInstrumentRequest', pricedInstrumentCreateRequestEquitySystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addEquityInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/equity`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestEquitySystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest} pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFixedIncomeInstrument: async (pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest: PricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest' is not null or undefined
            assertParamExists('addFixedIncomeInstrument', 'pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest', pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addFixedIncomeInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/fixedIncome`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFundSystemInstrumentRequest} pricedInstrumentCreateRequestFundSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFundInstrument: async (pricedInstrumentCreateRequestFundSystemInstrumentRequest: PricedInstrumentCreateRequestFundSystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestFundSystemInstrumentRequest' is not null or undefined
            assertParamExists('addFundInstrument', 'pricedInstrumentCreateRequestFundSystemInstrumentRequest', pricedInstrumentCreateRequestFundSystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addFundInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/fund`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestFundSystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFutureSystemInstrumentRequest} pricedInstrumentCreateRequestFutureSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFutureInstrument: async (pricedInstrumentCreateRequestFutureSystemInstrumentRequest: PricedInstrumentCreateRequestFutureSystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestFutureSystemInstrumentRequest' is not null or undefined
            assertParamExists('addFutureInstrument', 'pricedInstrumentCreateRequestFutureSystemInstrumentRequest', pricedInstrumentCreateRequestFutureSystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addFutureInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/futures`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestFutureSystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFxForwardSystemInstrumentRequest} pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFxForwardInstrument: async (pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest: PricedInstrumentCreateRequestFxForwardSystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest' is not null or undefined
            assertParamExists('addFxForwardInstrument', 'pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest', pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addFxForwardInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/fxForward`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInstrumentDividends: async (datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datedValueUpdateRequest' is not null or undefined
            assertParamExists('addInstrumentDividends', 'datedValueUpdateRequest', datedValueUpdateRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addInstrumentDividends', 'externalAccountId', externalAccountId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('addInstrumentDividends', 'code', code)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/{code}/income`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datedValueUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestLoanSystemInstrumentRequest} pricedInstrumentCreateRequestLoanSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLoanInstrument: async (pricedInstrumentCreateRequestLoanSystemInstrumentRequest: PricedInstrumentCreateRequestLoanSystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestLoanSystemInstrumentRequest' is not null or undefined
            assertParamExists('addLoanInstrument', 'pricedInstrumentCreateRequestLoanSystemInstrumentRequest', pricedInstrumentCreateRequestLoanSystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addLoanInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/loan`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestLoanSystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest} pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOptionWarrantInstrument: async (pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest: PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest' is not null or undefined
            assertParamExists('addOptionWarrantInstrument', 'pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest', pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addOptionWarrantInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/option_warrant`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest} pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPrivateEquityInstrument: async (pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest: PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest' is not null or undefined
            assertParamExists('addPrivateEquityInstrument', 'pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest', pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addPrivateEquityInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/privateEquity`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest} pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredProductInstrument: async (pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest: PricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest' is not null or undefined
            assertParamExists('addStructuredProductInstrument', 'pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest', pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addStructuredProductInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/structuredProduct`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestTermDepositSystemInstrumentRequest} pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermDepositInstrument: async (pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest: PricedInstrumentCreateRequestTermDepositSystemInstrumentRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest' is not null or undefined
            assertParamExists('addTermDepositInstrument', 'pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest', pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addTermDepositInstrument', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/termDeposit`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOtcInstrument: async (externalAccountId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('deleteOtcInstrument', 'externalAccountId', externalAccountId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('deleteOtcInstrument', 'code', code)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/{code}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DividendRequest>} dividendRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deprecatedDividendUpdate: async (dividendRequest: Array<DividendRequest>, externalAccountId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dividendRequest' is not null or undefined
            assertParamExists('deprecatedDividendUpdate', 'dividendRequest', dividendRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('deprecatedDividendUpdate', 'externalAccountId', externalAccountId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('deprecatedDividendUpdate', 'code', code)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/{code}/dividends`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dividendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<PriceRequest>} priceRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deprecatedPriceUpdate: async (priceRequest: Array<PriceRequest>, externalAccountId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceRequest' is not null or undefined
            assertParamExists('deprecatedPriceUpdate', 'priceRequest', priceRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('deprecatedPriceUpdate', 'externalAccountId', externalAccountId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('deprecatedPriceUpdate', 'code', code)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/{code}/prices`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} [positionsAtDate] 
         * @param {string} [portfolioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllOtcInstruments: async (externalAccountId: string, positionsAtDate?: string, portfolioId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('exportAllOtcInstruments', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/export`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (positionsAtDate !== undefined) {
                localVarQueryParameter['positionsAtDate'] = (positionsAtDate as any instanceof Date) ?
                    (positionsAtDate as any).toISOString().substr(0,10) :
                    positionsAtDate;
            }

            if (portfolioId !== undefined) {
                localVarQueryParameter['portfolioId'] = portfolioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns Active Otc Instruments for a portfolio
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivePortfolioOTCInstruments: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getActivePortfolioOTCInstruments', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/otcInstruments`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentIncome: async (externalAccountId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getInstrumentIncome', 'externalAccountId', externalAccountId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getInstrumentIncome', 'code', code)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/{code}/income`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentPrices: async (externalAccountId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getInstrumentPrices', 'externalAccountId', externalAccountId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getInstrumentPrices', 'code', code)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/{code}/valuations`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {'Shares' | 'Crypto' | 'Cash' | 'Adjustments' | 'FixedIncome' | 'Options' | 'Warrants' | 'Futures' | 'StructuredProducts' | 'CFDs' | 'Forwards' | 'RealAssets' | 'RealEstate' | 'Alternatives'} [instrumentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtcInstruments: async (externalAccountId: string, instrumentType?: 'Shares' | 'Crypto' | 'Cash' | 'Adjustments' | 'FixedIncome' | 'Options' | 'Warrants' | 'Futures' | 'StructuredProducts' | 'CFDs' | 'Forwards' | 'RealAssets' | 'RealEstate' | 'Alternatives', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getOtcInstruments', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (instrumentType !== undefined) {
                localVarQueryParameter['instrumentType'] = instrumentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstrumentPrices: async (datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datedValueUpdateRequest' is not null or undefined
            assertParamExists('updateInstrumentPrices', 'datedValueUpdateRequest', datedValueUpdateRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('updateInstrumentPrices', 'externalAccountId', externalAccountId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('updateInstrumentPrices', 'code', code)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/{code}/valuations`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datedValueUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InstrumentUpdateRequest} instrumentUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOtcInstrument: async (instrumentUpdateRequest: InstrumentUpdateRequest, externalAccountId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instrumentUpdateRequest' is not null or undefined
            assertParamExists('updateOtcInstrument', 'instrumentUpdateRequest', instrumentUpdateRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('updateOtcInstrument', 'externalAccountId', externalAccountId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('updateOtcInstrument', 'code', code)
            const localVarPath = `/v3/account/{externalAccountId}/otcInstrument/{code}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instrumentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OTCInstrumentsApi - functional programming interface
 * @export
 */
export const OTCInstrumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OTCInstrumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PricedInstrumentCreateRequestEquitySystemInstrumentRequest} pricedInstrumentCreateRequestEquitySystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEquityInstrument(pricedInstrumentCreateRequestEquitySystemInstrumentRequest: PricedInstrumentCreateRequestEquitySystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEquityInstrument(pricedInstrumentCreateRequestEquitySystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest} pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFixedIncomeInstrument(pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest: PricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFixedIncomeInstrument(pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFundSystemInstrumentRequest} pricedInstrumentCreateRequestFundSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFundInstrument(pricedInstrumentCreateRequestFundSystemInstrumentRequest: PricedInstrumentCreateRequestFundSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFundInstrument(pricedInstrumentCreateRequestFundSystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFutureSystemInstrumentRequest} pricedInstrumentCreateRequestFutureSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFutureInstrument(pricedInstrumentCreateRequestFutureSystemInstrumentRequest: PricedInstrumentCreateRequestFutureSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFutureInstrument(pricedInstrumentCreateRequestFutureSystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFxForwardSystemInstrumentRequest} pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFxForwardInstrument(pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest: PricedInstrumentCreateRequestFxForwardSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFxForwardInstrument(pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInstrumentDividends(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatedValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInstrumentDividends(datedValueUpdateRequest, externalAccountId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestLoanSystemInstrumentRequest} pricedInstrumentCreateRequestLoanSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLoanInstrument(pricedInstrumentCreateRequestLoanSystemInstrumentRequest: PricedInstrumentCreateRequestLoanSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLoanInstrument(pricedInstrumentCreateRequestLoanSystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest} pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOptionWarrantInstrument(pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest: PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOptionWarrantInstrument(pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest} pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPrivateEquityInstrument(pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest: PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPrivateEquityInstrument(pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest} pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStructuredProductInstrument(pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest: PricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStructuredProductInstrument(pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestTermDepositSystemInstrumentRequest} pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTermDepositInstrument(pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest: PricedInstrumentCreateRequestTermDepositSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSummaryV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTermDepositInstrument(pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOtcInstrument(externalAccountId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOtcInstrument(externalAccountId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DividendRequest>} dividendRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deprecatedDividendUpdate(dividendRequest: Array<DividendRequest>, externalAccountId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deprecatedDividendUpdate(dividendRequest, externalAccountId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<PriceRequest>} priceRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deprecatedPriceUpdate(priceRequest: Array<PriceRequest>, externalAccountId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deprecatedPriceUpdate(priceRequest, externalAccountId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} [positionsAtDate] 
         * @param {string} [portfolioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAllOtcInstruments(externalAccountId: string, positionsAtDate?: string, portfolioId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAllOtcInstruments(externalAccountId, positionsAtDate, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns Active Otc Instruments for a portfolio
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivePortfolioOTCInstruments(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstrumentSummaryV3>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivePortfolioOTCInstruments(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstrumentIncome(externalAccountId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatedValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrumentIncome(externalAccountId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstrumentPrices(externalAccountId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatedValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrumentPrices(externalAccountId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {'Shares' | 'Crypto' | 'Cash' | 'Adjustments' | 'FixedIncome' | 'Options' | 'Warrants' | 'Futures' | 'StructuredProducts' | 'CFDs' | 'Forwards' | 'RealAssets' | 'RealEstate' | 'Alternatives'} [instrumentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOtcInstruments(externalAccountId: string, instrumentType?: 'Shares' | 'Crypto' | 'Cash' | 'Adjustments' | 'FixedIncome' | 'Options' | 'Warrants' | 'Futures' | 'StructuredProducts' | 'CFDs' | 'Forwards' | 'RealAssets' | 'RealEstate' | 'Alternatives', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstrumentSummaryV3>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOtcInstruments(externalAccountId, instrumentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInstrumentPrices(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatedValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInstrumentPrices(datedValueUpdateRequest, externalAccountId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InstrumentUpdateRequest} instrumentUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOtcInstrument(instrumentUpdateRequest: InstrumentUpdateRequest, externalAccountId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOtcInstrument(instrumentUpdateRequest, externalAccountId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OTCInstrumentsApi - factory interface
 * @export
 */
export const OTCInstrumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OTCInstrumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {PricedInstrumentCreateRequestEquitySystemInstrumentRequest} pricedInstrumentCreateRequestEquitySystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEquityInstrument(pricedInstrumentCreateRequestEquitySystemInstrumentRequest: PricedInstrumentCreateRequestEquitySystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addEquityInstrument(pricedInstrumentCreateRequestEquitySystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest} pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFixedIncomeInstrument(pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest: PricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addFixedIncomeInstrument(pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFundSystemInstrumentRequest} pricedInstrumentCreateRequestFundSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFundInstrument(pricedInstrumentCreateRequestFundSystemInstrumentRequest: PricedInstrumentCreateRequestFundSystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addFundInstrument(pricedInstrumentCreateRequestFundSystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFutureSystemInstrumentRequest} pricedInstrumentCreateRequestFutureSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFutureInstrument(pricedInstrumentCreateRequestFutureSystemInstrumentRequest: PricedInstrumentCreateRequestFutureSystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addFutureInstrument(pricedInstrumentCreateRequestFutureSystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestFxForwardSystemInstrumentRequest} pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFxForwardInstrument(pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest: PricedInstrumentCreateRequestFxForwardSystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addFxForwardInstrument(pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInstrumentDividends(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, code: string, options?: any): AxiosPromise<Array<DatedValueDto>> {
            return localVarFp.addInstrumentDividends(datedValueUpdateRequest, externalAccountId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestLoanSystemInstrumentRequest} pricedInstrumentCreateRequestLoanSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLoanInstrument(pricedInstrumentCreateRequestLoanSystemInstrumentRequest: PricedInstrumentCreateRequestLoanSystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addLoanInstrument(pricedInstrumentCreateRequestLoanSystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest} pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOptionWarrantInstrument(pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest: PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addOptionWarrantInstrument(pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest} pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPrivateEquityInstrument(pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest: PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addPrivateEquityInstrument(pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest} pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStructuredProductInstrument(pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest: PricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addStructuredProductInstrument(pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PricedInstrumentCreateRequestTermDepositSystemInstrumentRequest} pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermDepositInstrument(pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest: PricedInstrumentCreateRequestTermDepositSystemInstrumentRequest, externalAccountId: string, options?: any): AxiosPromise<InstrumentSummaryV3> {
            return localVarFp.addTermDepositInstrument(pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOtcInstrument(externalAccountId: string, code: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteOtcInstrument(externalAccountId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DividendRequest>} dividendRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deprecatedDividendUpdate(dividendRequest: Array<DividendRequest>, externalAccountId: string, code: string, options?: any): AxiosPromise<void> {
            return localVarFp.deprecatedDividendUpdate(dividendRequest, externalAccountId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<PriceRequest>} priceRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deprecatedPriceUpdate(priceRequest: Array<PriceRequest>, externalAccountId: string, code: string, options?: any): AxiosPromise<void> {
            return localVarFp.deprecatedPriceUpdate(priceRequest, externalAccountId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} [positionsAtDate] 
         * @param {string} [portfolioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllOtcInstruments(externalAccountId: string, positionsAtDate?: string, portfolioId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.exportAllOtcInstruments(externalAccountId, positionsAtDate, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns Active Otc Instruments for a portfolio
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivePortfolioOTCInstruments(portfolioId: string, options?: any): AxiosPromise<Array<InstrumentSummaryV3>> {
            return localVarFp.getActivePortfolioOTCInstruments(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentIncome(externalAccountId: string, code: string, options?: any): AxiosPromise<Array<DatedValueDto>> {
            return localVarFp.getInstrumentIncome(externalAccountId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentPrices(externalAccountId: string, code: string, options?: any): AxiosPromise<Array<DatedValueDto>> {
            return localVarFp.getInstrumentPrices(externalAccountId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {'Shares' | 'Crypto' | 'Cash' | 'Adjustments' | 'FixedIncome' | 'Options' | 'Warrants' | 'Futures' | 'StructuredProducts' | 'CFDs' | 'Forwards' | 'RealAssets' | 'RealEstate' | 'Alternatives'} [instrumentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtcInstruments(externalAccountId: string, instrumentType?: 'Shares' | 'Crypto' | 'Cash' | 'Adjustments' | 'FixedIncome' | 'Options' | 'Warrants' | 'Futures' | 'StructuredProducts' | 'CFDs' | 'Forwards' | 'RealAssets' | 'RealEstate' | 'Alternatives', options?: any): AxiosPromise<Array<InstrumentSummaryV3>> {
            return localVarFp.getOtcInstruments(externalAccountId, instrumentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstrumentPrices(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, code: string, options?: any): AxiosPromise<Array<DatedValueDto>> {
            return localVarFp.updateInstrumentPrices(datedValueUpdateRequest, externalAccountId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InstrumentUpdateRequest} instrumentUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOtcInstrument(instrumentUpdateRequest: InstrumentUpdateRequest, externalAccountId: string, code: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateOtcInstrument(instrumentUpdateRequest, externalAccountId, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OTCInstrumentsApi - object-oriented interface
 * @export
 * @class OTCInstrumentsApi
 * @extends {BaseAPI}
 */
export class OTCInstrumentsApi extends BaseAPI {
    /**
     * 
     * @param {PricedInstrumentCreateRequestEquitySystemInstrumentRequest} pricedInstrumentCreateRequestEquitySystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addEquityInstrument(pricedInstrumentCreateRequestEquitySystemInstrumentRequest: PricedInstrumentCreateRequestEquitySystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addEquityInstrument(pricedInstrumentCreateRequestEquitySystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest} pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addFixedIncomeInstrument(pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest: PricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addFixedIncomeInstrument(pricedInstrumentCreateRequestFixedIncomeSystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricedInstrumentCreateRequestFundSystemInstrumentRequest} pricedInstrumentCreateRequestFundSystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addFundInstrument(pricedInstrumentCreateRequestFundSystemInstrumentRequest: PricedInstrumentCreateRequestFundSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addFundInstrument(pricedInstrumentCreateRequestFundSystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricedInstrumentCreateRequestFutureSystemInstrumentRequest} pricedInstrumentCreateRequestFutureSystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addFutureInstrument(pricedInstrumentCreateRequestFutureSystemInstrumentRequest: PricedInstrumentCreateRequestFutureSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addFutureInstrument(pricedInstrumentCreateRequestFutureSystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricedInstrumentCreateRequestFxForwardSystemInstrumentRequest} pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addFxForwardInstrument(pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest: PricedInstrumentCreateRequestFxForwardSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addFxForwardInstrument(pricedInstrumentCreateRequestFxForwardSystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
     * @param {string} externalAccountId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addInstrumentDividends(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, code: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addInstrumentDividends(datedValueUpdateRequest, externalAccountId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricedInstrumentCreateRequestLoanSystemInstrumentRequest} pricedInstrumentCreateRequestLoanSystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addLoanInstrument(pricedInstrumentCreateRequestLoanSystemInstrumentRequest: PricedInstrumentCreateRequestLoanSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addLoanInstrument(pricedInstrumentCreateRequestLoanSystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest} pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addOptionWarrantInstrument(pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest: PricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addOptionWarrantInstrument(pricedInstrumentCreateRequestOptionWarrantSystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest} pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addPrivateEquityInstrument(pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest: PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addPrivateEquityInstrument(pricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest} pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addStructuredProductInstrument(pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest: PricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addStructuredProductInstrument(pricedInstrumentCreateRequestStructuredProductSystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricedInstrumentCreateRequestTermDepositSystemInstrumentRequest} pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public addTermDepositInstrument(pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest: PricedInstrumentCreateRequestTermDepositSystemInstrumentRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).addTermDepositInstrument(pricedInstrumentCreateRequestTermDepositSystemInstrumentRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public deleteOtcInstrument(externalAccountId: string, code: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).deleteOtcInstrument(externalAccountId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DividendRequest>} dividendRequest 
     * @param {string} externalAccountId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public deprecatedDividendUpdate(dividendRequest: Array<DividendRequest>, externalAccountId: string, code: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).deprecatedDividendUpdate(dividendRequest, externalAccountId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<PriceRequest>} priceRequest 
     * @param {string} externalAccountId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public deprecatedPriceUpdate(priceRequest: Array<PriceRequest>, externalAccountId: string, code: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).deprecatedPriceUpdate(priceRequest, externalAccountId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} [positionsAtDate] 
     * @param {string} [portfolioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public exportAllOtcInstruments(externalAccountId: string, positionsAtDate?: string, portfolioId?: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).exportAllOtcInstruments(externalAccountId, positionsAtDate, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns Active Otc Instruments for a portfolio
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public getActivePortfolioOTCInstruments(portfolioId: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).getActivePortfolioOTCInstruments(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public getInstrumentIncome(externalAccountId: string, code: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).getInstrumentIncome(externalAccountId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public getInstrumentPrices(externalAccountId: string, code: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).getInstrumentPrices(externalAccountId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {'Shares' | 'Crypto' | 'Cash' | 'Adjustments' | 'FixedIncome' | 'Options' | 'Warrants' | 'Futures' | 'StructuredProducts' | 'CFDs' | 'Forwards' | 'RealAssets' | 'RealEstate' | 'Alternatives'} [instrumentType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public getOtcInstruments(externalAccountId: string, instrumentType?: 'Shares' | 'Crypto' | 'Cash' | 'Adjustments' | 'FixedIncome' | 'Options' | 'Warrants' | 'Futures' | 'StructuredProducts' | 'CFDs' | 'Forwards' | 'RealAssets' | 'RealEstate' | 'Alternatives', options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).getOtcInstruments(externalAccountId, instrumentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
     * @param {string} externalAccountId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public updateInstrumentPrices(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, code: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).updateInstrumentPrices(datedValueUpdateRequest, externalAccountId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InstrumentUpdateRequest} instrumentUpdateRequest 
     * @param {string} externalAccountId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OTCInstrumentsApi
     */
    public updateOtcInstrument(instrumentUpdateRequest: InstrumentUpdateRequest, externalAccountId: string, code: string, options?: AxiosRequestConfig) {
        return OTCInstrumentsApiFp(this.configuration).updateOtcInstrument(instrumentUpdateRequest, externalAccountId, code, options).then((request) => request(this.axios, this.basePath));
    }
}
