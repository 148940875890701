/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { InstrumentSearchResultV3 } from '../../aggregator';
// @ts-ignore
import { InstrumentSourceLabelSummary } from '../../aggregator';
// @ts-ignore
import { OptionSearchResult } from '../../aggregator';
/**
 * InstrumentsApi - axios parameter creator
 * @export
 */
export const InstrumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Subscription / Deposit Cash Instruments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashSubscriptionInstruments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/instruments/cashSubscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Fees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeeInstruments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/instruments/fees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for an instrument by ticker or name, includes market and otc instruments
         * @param {string} externalAccountId 
         * @param {string} query 
         * @param {Array<'Fx' | 'Subscription' | 'Crypto' | 'Equity' | 'Fund' | 'Index' | 'FixedIncome' | 'Commodity' | 'Option' | 'OtcOption' | 'Future' | 'StructuredProduct'>} [types] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currencyCode] 
         * @param {string} [tradeDate] 
         * @param {boolean} [excludeOtc] 
         * @param {boolean} [byUnderlyingSourceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (externalAccountId: string, query: string, types?: Array<'Fx' | 'Subscription' | 'Crypto' | 'Equity' | 'Fund' | 'Index' | 'FixedIncome' | 'Commodity' | 'Option' | 'OtcOption' | 'Future' | 'StructuredProduct'>, currencyCode?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', tradeDate?: string, excludeOtc?: boolean, byUnderlyingSourceId?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('search', 'externalAccountId', externalAccountId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('search', 'query', query)
            const localVarPath = `/v3/account/{externalAccountId}/instruments/search`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (currencyCode !== undefined) {
                localVarQueryParameter['currencyCode'] = currencyCode;
            }

            if (tradeDate !== undefined) {
                localVarQueryParameter['tradeDate'] = (tradeDate as any instanceof Date) ?
                    (tradeDate as any).toISOString().substr(0,10) :
                    tradeDate;
            }

            if (excludeOtc !== undefined) {
                localVarQueryParameter['excludeOtc'] = excludeOtc;
            }

            if (byUnderlyingSourceId !== undefined) {
                localVarQueryParameter['byUnderlyingSourceId'] = byUnderlyingSourceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} underlyingSourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} underlyingSourceData 
         * @param {string} quoteDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOptions: async (externalAccountId: string, underlyingSourceId: string, underlyingSourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', quoteDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('searchOptions', 'externalAccountId', externalAccountId)
            // verify required parameter 'underlyingSourceId' is not null or undefined
            assertParamExists('searchOptions', 'underlyingSourceId', underlyingSourceId)
            // verify required parameter 'underlyingSourceData' is not null or undefined
            assertParamExists('searchOptions', 'underlyingSourceData', underlyingSourceData)
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('searchOptions', 'quoteDate', quoteDate)
            const localVarPath = `/v3/account/{externalAccountId}/otcOptions`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (underlyingSourceId !== undefined) {
                localVarQueryParameter['underlyingSourceId'] = underlyingSourceId;
            }

            if (underlyingSourceData !== undefined) {
                localVarQueryParameter['underlyingSourceData'] = underlyingSourceData;
            }

            if (quoteDate !== undefined) {
                localVarQueryParameter['quoteDate'] = (quoteDate as any instanceof Date) ?
                    (quoteDate as any).toISOString().substr(0,10) :
                    quoteDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} underlyingSourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} underlyingSourceData 
         * @param {'CALL' | 'PUT'} optionType 
         * @param {number} year 
         * @param {string} quoteDate 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOptions1: async (externalAccountId: string, underlyingSourceId: string, underlyingSourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', optionType: 'CALL' | 'PUT', year: number, quoteDate: string, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('searchOptions1', 'externalAccountId', externalAccountId)
            // verify required parameter 'underlyingSourceId' is not null or undefined
            assertParamExists('searchOptions1', 'underlyingSourceId', underlyingSourceId)
            // verify required parameter 'underlyingSourceData' is not null or undefined
            assertParamExists('searchOptions1', 'underlyingSourceData', underlyingSourceData)
            // verify required parameter 'optionType' is not null or undefined
            assertParamExists('searchOptions1', 'optionType', optionType)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('searchOptions1', 'year', year)
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('searchOptions1', 'quoteDate', quoteDate)
            const localVarPath = `/v3/account/{externalAccountId}/instruments/options/search`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (underlyingSourceId !== undefined) {
                localVarQueryParameter['underlyingSourceId'] = underlyingSourceId;
            }

            if (underlyingSourceData !== undefined) {
                localVarQueryParameter['underlyingSourceData'] = underlyingSourceData;
            }

            if (optionType !== undefined) {
                localVarQueryParameter['optionType'] = optionType;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (quoteDate !== undefined) {
                localVarQueryParameter['quoteDate'] = (quoteDate as any instanceof Date) ?
                    (quoteDate as any).toISOString().substr(0,10) :
                    quoteDate;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstrumentsApi - functional programming interface
 * @export
 */
export const InstrumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstrumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Subscription / Deposit Cash Instruments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCashSubscriptionInstruments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstrumentSourceLabelSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCashSubscriptionInstruments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Fees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeeInstruments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstrumentSourceLabelSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeeInstruments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for an instrument by ticker or name, includes market and otc instruments
         * @param {string} externalAccountId 
         * @param {string} query 
         * @param {Array<'Fx' | 'Subscription' | 'Crypto' | 'Equity' | 'Fund' | 'Index' | 'FixedIncome' | 'Commodity' | 'Option' | 'OtcOption' | 'Future' | 'StructuredProduct'>} [types] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currencyCode] 
         * @param {string} [tradeDate] 
         * @param {boolean} [excludeOtc] 
         * @param {boolean} [byUnderlyingSourceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(externalAccountId: string, query: string, types?: Array<'Fx' | 'Subscription' | 'Crypto' | 'Equity' | 'Fund' | 'Index' | 'FixedIncome' | 'Commodity' | 'Option' | 'OtcOption' | 'Future' | 'StructuredProduct'>, currencyCode?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', tradeDate?: string, excludeOtc?: boolean, byUnderlyingSourceId?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstrumentSearchResultV3>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(externalAccountId, query, types, currencyCode, tradeDate, excludeOtc, byUnderlyingSourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} underlyingSourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} underlyingSourceData 
         * @param {string} quoteDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchOptions(externalAccountId: string, underlyingSourceId: string, underlyingSourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', quoteDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionSearchResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchOptions(externalAccountId, underlyingSourceId, underlyingSourceData, quoteDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} underlyingSourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} underlyingSourceData 
         * @param {'CALL' | 'PUT'} optionType 
         * @param {number} year 
         * @param {string} quoteDate 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchOptions1(externalAccountId: string, underlyingSourceId: string, underlyingSourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', optionType: 'CALL' | 'PUT', year: number, quoteDate: string, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionSearchResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchOptions1(externalAccountId, underlyingSourceId, underlyingSourceData, optionType, year, quoteDate, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstrumentsApi - factory interface
 * @export
 */
export const InstrumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstrumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Subscription / Deposit Cash Instruments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashSubscriptionInstruments(options?: any): AxiosPromise<Array<InstrumentSourceLabelSummary>> {
            return localVarFp.getCashSubscriptionInstruments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Fees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeeInstruments(options?: any): AxiosPromise<Array<InstrumentSourceLabelSummary>> {
            return localVarFp.getFeeInstruments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for an instrument by ticker or name, includes market and otc instruments
         * @param {string} externalAccountId 
         * @param {string} query 
         * @param {Array<'Fx' | 'Subscription' | 'Crypto' | 'Equity' | 'Fund' | 'Index' | 'FixedIncome' | 'Commodity' | 'Option' | 'OtcOption' | 'Future' | 'StructuredProduct'>} [types] 
         * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currencyCode] 
         * @param {string} [tradeDate] 
         * @param {boolean} [excludeOtc] 
         * @param {boolean} [byUnderlyingSourceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(externalAccountId: string, query: string, types?: Array<'Fx' | 'Subscription' | 'Crypto' | 'Equity' | 'Fund' | 'Index' | 'FixedIncome' | 'Commodity' | 'Option' | 'OtcOption' | 'Future' | 'StructuredProduct'>, currencyCode?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', tradeDate?: string, excludeOtc?: boolean, byUnderlyingSourceId?: boolean, options?: any): AxiosPromise<Array<InstrumentSearchResultV3>> {
            return localVarFp.search(externalAccountId, query, types, currencyCode, tradeDate, excludeOtc, byUnderlyingSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} underlyingSourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} underlyingSourceData 
         * @param {string} quoteDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOptions(externalAccountId: string, underlyingSourceId: string, underlyingSourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', quoteDate: string, options?: any): AxiosPromise<Array<OptionSearchResult>> {
            return localVarFp.searchOptions(externalAccountId, underlyingSourceId, underlyingSourceData, quoteDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} underlyingSourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} underlyingSourceData 
         * @param {'CALL' | 'PUT'} optionType 
         * @param {number} year 
         * @param {string} quoteDate 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOptions1(externalAccountId: string, underlyingSourceId: string, underlyingSourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', optionType: 'CALL' | 'PUT', year: number, quoteDate: string, code?: string, options?: any): AxiosPromise<Array<OptionSearchResult>> {
            return localVarFp.searchOptions1(externalAccountId, underlyingSourceId, underlyingSourceData, optionType, year, quoteDate, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstrumentsApi - object-oriented interface
 * @export
 * @class InstrumentsApi
 * @extends {BaseAPI}
 */
export class InstrumentsApi extends BaseAPI {
    /**
     * 
     * @summary Get Subscription / Deposit Cash Instruments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentsApi
     */
    public getCashSubscriptionInstruments(options?: AxiosRequestConfig) {
        return InstrumentsApiFp(this.configuration).getCashSubscriptionInstruments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Fees
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentsApi
     */
    public getFeeInstruments(options?: AxiosRequestConfig) {
        return InstrumentsApiFp(this.configuration).getFeeInstruments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for an instrument by ticker or name, includes market and otc instruments
     * @param {string} externalAccountId 
     * @param {string} query 
     * @param {Array<'Fx' | 'Subscription' | 'Crypto' | 'Equity' | 'Fund' | 'Index' | 'FixedIncome' | 'Commodity' | 'Option' | 'OtcOption' | 'Future' | 'StructuredProduct'>} [types] 
     * @param {'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC'} [currencyCode] 
     * @param {string} [tradeDate] 
     * @param {boolean} [excludeOtc] 
     * @param {boolean} [byUnderlyingSourceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentsApi
     */
    public search(externalAccountId: string, query: string, types?: Array<'Fx' | 'Subscription' | 'Crypto' | 'Equity' | 'Fund' | 'Index' | 'FixedIncome' | 'Commodity' | 'Option' | 'OtcOption' | 'Future' | 'StructuredProduct'>, currencyCode?: 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'USD' | 'KWD' | 'JOD' | 'CHF' | 'CAD' | 'SGD' | 'ILS' | 'QAR' | 'AED' | 'SAR' | 'PLN' | 'MYR' | 'BRL' | 'RON' | 'TRY' | 'CNY' | 'CNH' | 'HKD' | 'NOK' | 'SEK' | 'ZAR' | 'EGP' | 'MXN' | 'CZK' | 'TWD' | 'THB' | 'UYU' | 'PHP' | 'ARS' | 'RUB' | 'INR' | 'JPY' | 'ISK' | 'PKR' | 'HUF' | 'NGN' | 'KRW' | 'IDR' | 'DKK' | 'VND' | 'CLP' | 'GBX' | 'ILA' | 'ZAC', tradeDate?: string, excludeOtc?: boolean, byUnderlyingSourceId?: boolean, options?: AxiosRequestConfig) {
        return InstrumentsApiFp(this.configuration).search(externalAccountId, query, types, currencyCode, tradeDate, excludeOtc, byUnderlyingSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} underlyingSourceId 
     * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} underlyingSourceData 
     * @param {string} quoteDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentsApi
     */
    public searchOptions(externalAccountId: string, underlyingSourceId: string, underlyingSourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', quoteDate: string, options?: AxiosRequestConfig) {
        return InstrumentsApiFp(this.configuration).searchOptions(externalAccountId, underlyingSourceId, underlyingSourceData, quoteDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} underlyingSourceId 
     * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} underlyingSourceData 
     * @param {'CALL' | 'PUT'} optionType 
     * @param {number} year 
     * @param {string} quoteDate 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentsApi
     */
    public searchOptions1(externalAccountId: string, underlyingSourceId: string, underlyingSourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', optionType: 'CALL' | 'PUT', year: number, quoteDate: string, code?: string, options?: AxiosRequestConfig) {
        return InstrumentsApiFp(this.configuration).searchOptions1(externalAccountId, underlyingSourceId, underlyingSourceData, optionType, year, quoteDate, code, options).then((request) => request(this.axios, this.basePath));
    }
}
