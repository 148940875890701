import {
  GRID_COL_INDEX_ATTRIBUTE,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { FunctionComponent } from "react";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import * as React from "react";
import s from "./Cells.module.scss";
export const CellWrapper = ({
  gridCellProps,
  cell,
  baseCurrency,
}: {
  gridCellProps: GridCellProps;
  cell: FunctionComponent<any>;
  baseCurrency?: string;
}) => {
  const Cell = cell;
  const index = gridCellProps.dataItem.id;
  const navigationAttributes = useTableKeyboardNavigation(gridCellProps.id);

  return (
    <td
      style={{
        // TODO: replace colors with appropriate css-vars
        color: "var(--color)",
      }}
      colSpan={gridCellProps.colSpan}
      role={"gridcell"}
      className={s.tableCell}
      aria-colindex={gridCellProps.ariaColumnIndex}
      aria-selected={gridCellProps.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: gridCellProps.columnIndex,
      }}
      {...navigationAttributes}
    >
      <Cell index={index} baseCurrency={baseCurrency} />
    </td>
  );
};
