import * as React from "react";
import s from "./CustomDateSelector.module.scss";
import {
  BundlesEnum,
  sendTracking,
  useGlobalPeriod,
} from "@iliotech/data-wire";
import { Form, FormElement } from "@progress/kendo-react-form";
import { FormInputs } from "../FormInputs";
import RoundButton from "../RoundButton/RoundButton";
import { Popup } from "@progress/kendo-react-popup";
import cn from "classnames";
import { FaChevronDown } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { WithBundle } from "../__wrappers";

interface IProps {
  inceptionDate?: Date;
  epochDate?: Date;
}

export const CustomDateSelector = ({ inceptionDate, epochDate }: IProps) => {
  const { period, setPeriod } = useGlobalPeriod();
  const [show, setShow] = React.useState(false);
  const anchor = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const onSubmit = (data: { from: Date; to: Date }) => {
    sendTracking(`custom_period_selected:${data}`, "period_select");

    setPeriod({
      from: data.from,
      to: data.to,
      label: t("Custom"),
    });
    setShow(false);
  };

  return (
    <div className={s.noMobile}>
      {
        <WithBundle bundle={BundlesEnum.Manager_Functions}>
          <div
            className={s.button}
            ref={anchor}
            role={"button"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShow((prev) => !prev);
            }}
          >
            <div className={cn(s.button)}>
              Custom Range
              <FaChevronDown className={cn(s.chevron)} size={8} />
            </div>
          </div>
        </WithBundle>
      }
      <Popup
        appendTo={document.getElementById("app")}
        data-cy={"k-popup"}
        anchor={anchor.current}
        show={show}
        popupClass={"popup-content"}
      >
        <div
          className={s.container}
          style={{ minWidth: 100, padding: "1.5rem" }}
        >
          <AiOutlineClose
            onClick={() => setShow(false)}
            size={16}
            className={s.close}
          />
          <Form
            onSubmit={(data: any) => onSubmit(data)}
            initialValues={{
              from: period?.from,
              to: period?.to,
            }}
            render={(formRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <FormInputs.Common.DateField
                  name={"from"}
                  label={"from"}
                  dataCy="custom-date-from"
                  errors={formRenderProps.errors}
                  min={inceptionDate ?? epochDate}
                />
                <FormInputs.Common.DateField
                  name={"to"}
                  label={"to"}
                  dataCy="custom-date-to"
                  errors={formRenderProps.errors}
                  min={formRenderProps.valueGetter("from") ?? epochDate}
                />
                <div style={{ height: 40 }} />
                <RoundButton type={"submit"}>Save</RoundButton>
              </FormElement>
            )}
          />
        </div>
      </Popup>
    </div>
  );
};

export default CustomDateSelector;
