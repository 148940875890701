import { PortfolioCreateDTO, } from "@iliotech/generated-api-v3";
import { AxiosRequestConfig } from "axios";

export const useCreatePortfolio = (sendRequest: <T = any>(url: string, options?: AxiosRequestConfig | undefined) => Promise<T>, accountId: string, overridePayloadObject?:Partial<PortfolioCreateDTO>) => {
  const portfolioTradeFn = (payload:PortfolioCreateDTO) => sendRequest<any>(`api/v3/account/${accountId}/portfolio`, {method: "post", data: {
    ...payload,
    type: "LIVE",
    "accessType": "ACTIVE",
    "valid": true,
    "summary": "",
    "description": "",
    "benchmarkIndex": "",
    ...overridePayloadObject
  }, });

  return (payload:PortfolioCreateDTO) => portfolioTradeFn(payload);
}
