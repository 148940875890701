import React, { useState } from "react";
import cn from "classnames";
import s from "./ReportCreator.module.scss";
import FormModal from "../FormModal/FormModal";
import { BundlesEnum, SYSTEM_EPOCH, useAuth } from "@iliotech/data-wire";
import Button from "../Button/Button";
import useReportGenerator, {
  RunReportPayload,
} from "@iliotech/data-wire/src/reactHooks/hooks/useReportGenerator";
import {
  POSITION_GROUPING_OPTIONS,
  SENSITIVITY_GROUPING_OPTIONS,
} from "./constants";
import { StandardReport } from "./Tabs/StandardReport";
import { UnderlineTabs } from "../UnderlineTabs/UnderlineTabs";
import { CustomReport } from "./Tabs/CustomReport";
import RoundButton from "../RoundButton/RoundButton";

interface IProps {
  button?: React.ReactNode;
}
export const ReportCreatorButton = ({ button }: IProps) => {
  const { runReport, inceptionDate, open, visible, close, error, loading } =
    useReportGenerator();
  return (
    <>
      {<div onClick={open}>{button || "Generate report"}</div>}
      <ReportCreatorInner
        onGenerate={runReport}
        inceptionDate={inceptionDate}
        visible={visible}
        onClose={close}
        error={error}
        loading={loading}
      />
    </>
  );
};

interface IOwnProps {
  wrapperId?: string;
  inceptionDate?: Date;
  onGenerate(
    from: Date | null,
    to: Date | null,
    options: RunReportPayload,
    custom?: boolean
  ): void;
  visible?: boolean;
  onClose(): void;
  error?: string;
  loading?: boolean;
}

export const DEFAULT_SETTINGS = {
  performance: true,
  assetClassPnl: true,
  attributionPnl: true,
  returnVolatilityDaily: true,
  returnVolatilityMonthly: false,
  returnsHistogramDaily: false,
  returnsHistogramMonthly: false,
  allPnl: true,
  overview: true,
  structure: true,
  openPositions: false,
  transactionHistory: "NONE",
  sensitivity: false,
  sensitivityOptions: {
    equityPercent: -10,
    fxPercent: -2,
    yieldPercent: 0,
    volatilityPercent: 0,
  },
  sensitivityGrouping: SENSITIVITY_GROUPING_OPTIONS[0],
  positionGrouping: POSITION_GROUPING_OPTIONS[0],
  insights: false,
};

export const ReportCreatorInner = ({
  wrapperId = "app",
  inceptionDate = SYSTEM_EPOCH,
  onGenerate,
  visible,
  onClose,
  error,
  loading,
}: IOwnProps) => {
  const [from, setFrom] = React.useState<Date | null>(inceptionDate);
  const [to, setTo] = React.useState<Date | null>(new Date());
  const [settings, setSettings] =
    React.useState<typeof DEFAULT_SETTINGS>(DEFAULT_SETTINGS);
  const [tab, setTab] = useState("Standard");

  const { hasBundle } = useAuth();

  const tabs = hasBundle(BundlesEnum.Manager_Functions)
    ? ["Standard", "Custom"]
    : ["Standard"];
  React.useEffect(() => {
    setFrom(inceptionDate);
  }, [JSON.stringify(inceptionDate)]);

  const updateSettings = (newSettings: Record<string, any>) => {
    setSettings({ ...settings, ...newSettings });
  };

  const props = {
    inceptionDate,
    to,
    setTo,
    settings,
    from,
    setFrom,
    updateSettings,
  };
  const renderInner = React.useMemo(() => {
    if (tab === "Standard") {
      return <StandardReport {...props} />;
    }
    return <CustomReport {...props} />;
  }, [tab, props]);

  return (
    <FormModal
      wrapperId={wrapperId}
      handleClose={onClose}
      visible={!!visible}
      modalStyle={{ maxWidth: 1100 }}
      title={"Generate report"}
      footer={
        <FormModal.Footer style={{ justifyContent: "space-between" }}>
          <div className={cn(s.error)}>{error}</div>
          <RoundButton
            style={{ minWidth: 150 }}
            loading={loading}
            onClick={() => onGenerate(from, to, settings, tab !== "Standard")}
          >
            Generate report
          </RoundButton>
        </FormModal.Footer>
      }
    >
      <div className={s.content}>
        <UnderlineTabs tabs={tabs} onTabChange={setTab} />
        {renderInner}
        <div style={{ height: 40 }} />
      </div>
    </FormModal>
  );
};
