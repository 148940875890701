import * as React from "react";
import { useParams } from "react-router-dom";
import { usePortfolio } from "../api";
import moment, { MomentInput } from "moment/moment";
import { API_BASE } from "../../constants/constants";
import { Auth } from "aws-amplify";
import {
  PortfolioReportsApi,
  ReportsPagesOptions,
} from "@iliotech/generated-api-v3";
import axios from "axios";

const format = (d: MomentInput) => moment(d).format("YYYY-MM-DD");

export interface RunReportPayload
  extends Omit<
    ReportsPagesOptions,
    "positionGrouping" | "sensitivityGrouping"
  > {
  positionGrouping: { value: string };
  sensitivityGrouping: { value: string };
}
const useReportGenerator = () => {
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const portfolioInfo = usePortfolio(portfolioId!);
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const portfolioInceptionDate = portfolioInfo?.data?.data.inceptionDate;
  const api = new PortfolioReportsApi(undefined, `${API_BASE}/api`);
  const inceptionDate = !!portfolioInceptionDate
    ? moment(portfolioInceptionDate).toDate()
    : undefined;

  const runStandardReport = (
    fromDate: string,
    toDate: string,
    authToken: string,
    insights: boolean
  ) => {
    api
      .getPortfolioConsumerReport(
        {
          period: { from: fromDate, to: toDate || format(undefined) },
          options: { insights },
        },
        portfolioId!,
        `Bearer ${authToken}`,
        {
          timeout: 55000,
          responseType: "blob",
          headers: {
            common: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        }
      )
      .then((response) => {
        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = objectURL;
        link.setAttribute(
          "download",
          `${portfolioInfo?.data?.data?.name ?? "Portfolio report"} ${format(
            undefined
          )}.pdf`
        );
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          })
        );
      })
      .catch(() => {
        setError(
          "Sorry - we were unable to generate your report. Please try again in a few moments"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const runCustomLocalReport = (
    fromDate: string,
    toDate: string,
    authToken: string,
    options: ReportsPagesOptions
  ) => {
    axios
      .post(
        "http://localhost:3000/generate",
        {
          period: { from: fromDate, to: toDate || format(undefined) },
          options,
        },
        {
          params: { portfolioId: portfolioId! },
          responseType: "blob",
          headers: {
            common: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        }
      )
      .then((response) => {
        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = objectURL;
        link.setAttribute(
          "download",
          `${portfolioInfo?.data?.data?.name ?? "Portfolio report"} ${format(
            undefined
          )}.pdf`
        );
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          })
        );
      })
      .catch(() => {
        setError(
          "Sorry - we were unable to generate your report. Please try again in a few moments"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const runCustomReport = (
    fromDate: string,
    toDate: string,
    authToken: string,
    options: ReportsPagesOptions
  ) => {
    api
      .getPortfolioCustomReport(
        {
          period: { from: fromDate, to: toDate || format(undefined) },
          options,
        },
        portfolioId!,
        `Bearer ${authToken}`,
        {
          timeout: 55000,
          responseType: "blob",
          headers: {
            common: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        }
      )
      .then((response) => {
        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = objectURL;
        link.setAttribute(
          "download",
          `${portfolioInfo?.data?.data?.name ?? "Portfolio report"} ${format(
            undefined
          )}.pdf`
        );
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          })
        );
      })
      .catch(() => {
        setError(
          "Sorry - we were unable to generate your report. Please try again in a few moments"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const runReport = async (
    from: Date | null,
    to: Date | null,
    options: RunReportPayload,
    custom?: boolean
  ) => {
    setLoading(true);
    setError(undefined);

    // * Ensure the end date is based on the current system time, if "since inception" is selected.
    const fromDate = format(from ?? portfolioInceptionDate);
    const toDate = to ? format(to) : null;

    console.log("Getting report from ", api);

    const authToken = await Auth.currentSession().then((s) =>
      s.getAccessToken().getJwtToken()
    );

    if (custom) {
      runCustomReport(fromDate, toDate!, authToken, {
        ...options,
        positionGrouping: options.positionGrouping.value,
        sensitivityGrouping: options.sensitivityGrouping.value,
        transactionHistory: options?.transactionHistory
          ? "REPORTING_PERIOD"
          : "NONE",
      });
    } else {
      runStandardReport(fromDate, toDate!, authToken, options.insights);
    }
  };

  const close = () => {
    setVisible(false);
    setLoading(false);
  };
  const open = () => setVisible(true);

  return {
    open,
    close,
    visible,
    setVisible,
    runReport,
    loading,
    error,
    inceptionDate,
  };
};

export default useReportGenerator;
