export function configureLocalhostEnvironmentVariables() {
  const hostname = window.location.hostname;
  console.log("initialising configureLocalhost Environment", hostname);

  if (hostname !== "localhost") {
    return;
  }

  window.environmentSettings = {
    env: import.meta.env.VITE_ENVIRONMENT_NAME,
    rootUrl: import.meta.env.VITE_ROOT_URL,
    illioApiUrl: import.meta.env.VITE_ILLIO_API_URL,
    appPkgVersion: import.meta.env.VITE_PKG_VERSION,
    appBuildVersion: import.meta.env.VITE_BUILD_VERSION,
    appVersion: import.meta.env.VITE_VERSION,
    buildVersion: import.meta.env.VITE_BUILD_VERSION,
    gaTrackerId: import.meta.env.VITE_GA_TRACKER_ID,
    devTrackerId: import.meta.env.VITE_DEV_TRACKER_ID,
    ontologyUrl: import.meta.env.VITE_ONTOLOGY_URL,
    pusherCluster: import.meta.env.VITE_PUSHER_CLUSTER,
    pusherId: import.meta.env.VITE_PUSHER_ID,
    hotjarId: import.meta.env.VITE_HOTJAR_ID,
    userpilotToken: import.meta.env.VITE_USERPILOT_TOKEN,
    hubspotId: import.meta.env.VITE_HUBSPOT_ID,
    devcycleEnv: import.meta.env.VITE_DEV_CYCLE_ENV,
    activateMocks: import.meta.env.VITE_ACTIVATE_MOCKS === "true",
    widgetSettings: {
      showErrorComponents:
        import.meta.env.VITE_WIDGET_HIDE_ERROR_COMPONENTS === "false",
      showExpandButton:
        import.meta.env.VITE_WIDGET_SHOW_EXPAND_BUTTON === "true",
    },
  };

  console.log("dev cycle env", window.environmentSettings.devcycleEnv);
}
