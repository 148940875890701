import {
  InstrumentDtoStatusEnum,
  PortfolioCustodian,
  TradeImport,
  TradeImportBuySellEnum,
  TradeImportWithSearchResult,
} from "@iliotech/generated-api-v3";
import { IPositionTableRow } from "./PositionsTableStore";

export interface IErroredPositionItem {
  status: string;
  createPositionRequest: TradeImport;
  isError: boolean;
  message: string;
}
export const parseErroredPositionItem = (
  item: IErroredPositionItem,
  custodians: PortfolioCustodian[],
  index: string
) => {
  let parsedItem: IPositionTableRow;

  const tradeImport = item.createPositionRequest;
  const quantitySign =
    tradeImport.buySell === TradeImportBuySellEnum.Buy ? 1 : -1;
  const quantity = tradeImport.quantity * quantitySign;
  parsedItem = {
    ...tradeImport,
    id: index,
    quantity: quantity,
    price: tradeImport.price,
    overridePrice: true,
    apiError: true,
    apiErrorString: item.message,
    ticker: "ERROR",
    status: {
      status: InstrumentDtoStatusEnum.Error,
      sourceId: tradeImport.sourceId?.sourceId!,
    },
  };

  return parsedItem;
};

export const parsePositionItem = (
  item: TradeImportWithSearchResult,
  custodians: PortfolioCustodian[],
  index: string
) => {
  let parsedItem: IPositionTableRow;
  const quantitySign =
    item.tradeImport.buySell === TradeImportBuySellEnum.Buy ? 1 : -1;
  const quantity = item.tradeImport.quantity * quantitySign;
  const preSelectedInstrument =
    item.searchResult?.length === 1 ? item.searchResult[0] : undefined;
  // we parse the item, selecting the first search result if there is only one
  parsedItem = {
    ...item.tradeImport,
    id: index,
    quantity: quantity,
    price: item.tradeImport.price,
    searchResults: item.searchResult,
    overridePrice: true,
    fxRate: preSelectedInstrument?.fxRate,
    selectedInstrument: preSelectedInstrument,
    currency: preSelectedInstrument?.currency,
    historicPrice: preSelectedInstrument?.price,
    status: {
      status:
        preSelectedInstrument?.instrumentStatus! as any as InstrumentDtoStatusEnum,
      sourceId: preSelectedInstrument?.sourceId?.sourceId!,
    },
  };

  const foundCustodian = (custodians || []).find((item) => {
    const code = item?.code || "";
    const currentCustodian = parsedItem?.custodian || "";
    return code.toLowerCase() === currentCustodian.toLowerCase();
  });

  // we check if the custodian is found in the list of custodians of the portfolio
  if (foundCustodian) {
    parsedItem = {
      ...parsedItem,
      custodianResolved: true,
    };
  }

  return parsedItem;
};
