import React, { PropsWithChildren, useState } from "react";
import {
  useCreatePortfolio,
  usePortfolioList,
  useSelectedAccountStatus,
} from "@iliotech/data-wire";
import { useAuth } from "@iliotech/data-wire";
import {
  Loader,
  IBAccountOnboarding,
  IBContactUserSupport,
} from "@iliotech/component-library";
import {
  AccountIssueActionCodeEnum,
  AccountInfo,
  AccountUserCapabilitiesDTOAllowedViewsEnum,
} from "@iliotech/generated-api-v3";
import { sendRequest } from "../../hooks/useApi";
import { useLogout } from "../../hooks/useLogout";
import { SubscriptionChooser } from "../../containers/Auth/SubscriptionChooser";
import { PaymentResolutionPrompt } from "../../containers/Auth/PaymentResolutionPrompt";
import { NotAllowed } from "../../containers/Auth/NotAllowed/NotAllowed";

export const AccountStatusChecker = ({ children }: PropsWithChildren<any>) => {
  const { illioSelectedAccount, capabilities } = useAuth();
  const isAllowed = capabilities?.allowedViews?.includes(
    AccountUserCapabilitiesDTOAllowedViewsEnum.D2c
  );
  const status = illioSelectedAccount?.status;
  const [popupModal, setPopupModal] = useState(false);
  const portfolios = usePortfolioList(
    illioSelectedAccount?.externalAccountId!,
    undefined,
    status === "SUSPENDED"
  );
  const noPortfolios = (portfolios.data?.data?.length || 0) === 0;
  const accountStatus = useSelectedAccountStatus();
  const accountIssues = accountStatus.data?.data.issues;
  const nextAccountAction = getNextAccountAction(accountIssues);
  const hasPaymentIssues =
    nextAccountAction === AccountIssueActionCodeEnum.PaymentFailed ||
    status === "SUSPENDED";
  const createPortfolio = useCreatePortfolio(
    sendRequest,
    illioSelectedAccount?.externalAccountId!
  );

  const logout = useLogout();

  /**
   * Handle Loading
   */
  if (accountStatus.isLoading || portfolios.isLoading) {
    return <Loader fullScreen />;
  }

  /**
   * Handle the case where the user is an Interactive Broker client, and they have not yet mapped their IB account
   * to an illio Portfolio
   */
  if (
    (nextAccountAction === AccountIssueActionCodeEnum.MapBrokerIb &&
      noPortfolios) ||
    !!popupModal
  ) {
    return (
      <div id="app">
        <IBAccountOnboarding
          popupModal={popupModal}
          setPopupModal={setPopupModal}
          createPortfolio={createPortfolio}
          onLogout={logout}
        />
      </div>
    );
  }

  /**
   * Handle general account issues with a contact page
   */
  if (
    nextAccountAction === AccountIssueActionCodeEnum.ContactSupport ||
    nextAccountAction === AccountIssueActionCodeEnum.UpdateSubscription
  ) {
    return (
      <div id="app">
        <IBContactUserSupport />
      </div>
    );
  }

  // return <IBContactUserSupport />;
  /**
   * Handle case where the user has no valid subscription
   */
  if (nextAccountAction === AccountIssueActionCodeEnum.AddSubscription) {
    return (
      <div id="app">
        <SubscriptionChooser />
      </div>
    );
  }

  if (!isAllowed) {
    return <NotAllowed />;
  }
  return (
    <>
      {children}
      {hasPaymentIssues && <PaymentResolutionPrompt />}
    </>
  );
};

const ORDERED_ACTION_CODES: AccountIssueActionCodeEnum[] = [
  AccountIssueActionCodeEnum.ContactSupport,
  AccountIssueActionCodeEnum.AddSubscription,
  AccountIssueActionCodeEnum.UpdateSubscription,
  AccountIssueActionCodeEnum.MapBrokerIb,
];

const getNextAccountAction = (
  accountIssues?: AccountInfo["issues"]
): AccountIssueActionCodeEnum | "NONE" => {
  if (!accountIssues || Object.keys(accountIssues)?.length === 0) {
    // * We can't identify any issues with the account
    return "NONE";
  }

  /**
   * Returns a map like:
   * {
   *   MapBrokerIb: "text reason",
   *   ContactSupport: "text reason"
   * }
   */

  const actionCodes: any = {};

  Object.values(accountIssues).forEach((issue) => {
    issue.forEach((subIssue) => {
      //@ts-ignore
      actionCodes[subIssue.actionCode] = subIssue.reason;
    });
  });

  /**
   * Return the highest priority issue for the user to deal with (urgent account issues like missing subscriptions,
   * down to less urgent problems like an unmapped broker account. In future, we might not block the UI for certain issues
   */
  for (let code of ORDERED_ACTION_CODES) {
    if (!!actionCodes[code]) {
      return code;
    }
  }

  return "NONE";
};
