import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { DatePicker } from "../../../DatePicker/DatePicker";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ITableRow } from "@iliotech/data-wire";
import cn from "classnames";
import s from "./Cells.module.scss";
import { observer } from "mobx-react-lite";

export interface TransactionTableCellProps {
  index: number;
}

const DateCell = ({ index }: TransactionTableCellProps) => {
  const store = useTransactionUploadV2();
  const date = store.equities.getField("date", index + "");
  const onChange = (event: DatePickerChangeEvent) => {
    store.equities.updateTableRowByIndex("date", event.value, index + "");
    store.equities.updateTableRowByIndex("dateChanged", true, index + "");
  };

  return (
    <div className={cn("illio-input")}>
      <DatePicker name={"date"} value={date as Date} onChange={onChange} />
    </div>
  );
};

export default observer(DateCell);
