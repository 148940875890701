import React, { FC, useState } from "react";
import { IDecimalCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import insight from "../../Insights/Bell.svg";
import { PortfolioSnapshotDtoV3RiskAssetClassCodeEnum } from "@iliotech/generated-api-v3";
import InstrumentInsightsModal from "../../Insights/InsightsModal/InstrumentInsightsModal";
import { useSnapshot } from "@iliotech/data-wire";
import { useParams } from "react-router";

const assetClassesToExclude = [
  PortfolioSnapshotDtoV3RiskAssetClassCodeEnum.Adjustments,
  PortfolioSnapshotDtoV3RiskAssetClassCodeEnum.RealAssets,
  PortfolioSnapshotDtoV3RiskAssetClassCodeEnum.RealEstate,
  PortfolioSnapshotDtoV3RiskAssetClassCodeEnum.CashAndEquivalents,
];

export const InsightCell = (): FC<IDecimalCellProps> => {
  return React.memo(({ width, ...props }) => {
    const instrumentId = props.dataItem.instrumentId;

    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const [visible, setVisible] = useState(false);

    const renderContent = () => {
      if (
        assetClassesToExclude.includes(props.dataItem.assetClassCode) ||
        props.dataItem.marketValueBase === 0
      ) {
        return null;
      } else {
        return (
          <>
            {visible && (
              <InstrumentInsightsModal
                visible={visible}
                setVisible={setVisible}
                instrumentId={instrumentId}
              />
            )}
            <div
              onClick={(e) => {
                e.stopPropagation();
                setVisible(true);
              }}
              style={{ padding: "0.25rem 0", margin: "auto" }}
            >
              <img src={insight} alt={"insight-for-instrument"} />
            </div>
          </>
        );
      }
    };

    return (
      <td
        style={{
          zIndex: 999,
          // TODO: replace colors with appropriate css-vars
          color: undefined,
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div style={{ display: "flex", width }}>{renderContent()}</div>
      </td>
    );
  });
};
