/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { ScenarioValuesResponse } from '../../aggregator';
// @ts-ignore
import { ScenariosCoefficientsResponse } from '../../aggregator';
/**
 * PortfolioSensitivityApi - axios parameter creator
 * @export
 */
export const PortfolioSensitivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get coefficients for sensitivity analysis calculation for portfolios with no derivative instruments
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoefficients: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getCoefficients', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/sensitivity/coefficients`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate values for sensitivity analysis
         * @param {string} portfolioId 
         * @param {number} [price] 
         * @param {number} [vol] 
         * @param {number} [_yield] 
         * @param {number} [fx] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValues: async (portfolioId: string, price?: number, vol?: number, _yield?: number, fx?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getValues', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/sensitivity/values`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (vol !== undefined) {
                localVarQueryParameter['vol'] = vol;
            }

            if (_yield !== undefined) {
                localVarQueryParameter['yield'] = _yield;
            }

            if (fx !== undefined) {
                localVarQueryParameter['fx'] = fx;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioSensitivityApi - functional programming interface
 * @export
 */
export const PortfolioSensitivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioSensitivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get coefficients for sensitivity analysis calculation for portfolios with no derivative instruments
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoefficients(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenariosCoefficientsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoefficients(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate values for sensitivity analysis
         * @param {string} portfolioId 
         * @param {number} [price] 
         * @param {number} [vol] 
         * @param {number} [_yield] 
         * @param {number} [fx] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValues(portfolioId: string, price?: number, vol?: number, _yield?: number, fx?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioValuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValues(portfolioId, price, vol, _yield, fx, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioSensitivityApi - factory interface
 * @export
 */
export const PortfolioSensitivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioSensitivityApiFp(configuration)
    return {
        /**
         * 
         * @summary Get coefficients for sensitivity analysis calculation for portfolios with no derivative instruments
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoefficients(portfolioId: string, options?: any): AxiosPromise<ScenariosCoefficientsResponse> {
            return localVarFp.getCoefficients(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate values for sensitivity analysis
         * @param {string} portfolioId 
         * @param {number} [price] 
         * @param {number} [vol] 
         * @param {number} [_yield] 
         * @param {number} [fx] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValues(portfolioId: string, price?: number, vol?: number, _yield?: number, fx?: number, options?: any): AxiosPromise<ScenarioValuesResponse> {
            return localVarFp.getValues(portfolioId, price, vol, _yield, fx, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioSensitivityApi - object-oriented interface
 * @export
 * @class PortfolioSensitivityApi
 * @extends {BaseAPI}
 */
export class PortfolioSensitivityApi extends BaseAPI {
    /**
     * 
     * @summary Get coefficients for sensitivity analysis calculation for portfolios with no derivative instruments
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioSensitivityApi
     */
    public getCoefficients(portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioSensitivityApiFp(this.configuration).getCoefficients(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate values for sensitivity analysis
     * @param {string} portfolioId 
     * @param {number} [price] 
     * @param {number} [vol] 
     * @param {number} [_yield] 
     * @param {number} [fx] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioSensitivityApi
     */
    public getValues(portfolioId: string, price?: number, vol?: number, _yield?: number, fx?: number, options?: AxiosRequestConfig) {
        return PortfolioSensitivityApiFp(this.configuration).getValues(portfolioId, price, vol, _yield, fx, options).then((request) => request(this.axios, this.basePath));
    }
}
