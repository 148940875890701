import { Field } from "@progress/kendo-react-form";
import { Input, InputProps } from "../Input/Input";
import * as React from "react";

interface IOwnProps extends Omit<InputProps, "name" | "label"> {
  name?: string;
}

export const HiddenField = ({
  name = "nextAction",
  ...rest
}: IOwnProps) => {
  return (
    <Field
      name={name}
      component={Input}
      type={'hidden'}
      {...rest}
    />
  );
};
