import * as React from "react";
import { PortfolioResponse } from "@iliotech/generated-api-v3";

const PORTFOLIOS_TO_SHOW = 20;
const mapBrokerLabel = {
  IB: "Interactive Brokers",
};

interface IProps {
  portfolios?: PortfolioResponse[];
}

export const usePortfolioListGridSharedLogic = ({ portfolios }: IProps) => {
  const [expanded, setExpanded] = React.useState(
    (portfolios?.length ?? 0) > PORTFOLIOS_TO_SHOW
  );

  React.useEffect(() => {
    if (portfolios) {
      setExpanded(portfolios.length < PORTFOLIOS_TO_SHOW);
    }
  }, [portfolios]);

  const description = React.useMemo(
    () => (p: PortfolioResponse) => {
      /* const integrations: { [key: string]: string } = {
          IB: "AWAITING_FILES",
        };*/
      const integrations: { [key: string]: string } = p.integrations || {};
      const keys = Object.keys(integrations).filter(
        (s) => integrations[s] === "AWAITING_FILES"
      );

      const integrationsString = (keys || []).map((s: string, index) => {
        return `${s.substr(0, 1) + s.substr(1).toLowerCase()}${
          index === keys.length - 1 ? "" : ", "
        }`;
      });

      const integrationString = `We’ve requested your data from ${
        // @ts-ignore
        mapBrokerLabel?.[integrationsString.join("")] ||
        integrationsString.join("")
      }. 
      While we’re waiting  to receive this you can explore our example portfolio below or add other investments to your portfolio by clicking here.`;
      return {
        description: keys?.length > 0 ? integrationString : p.description,
        awaitingIntegrations: keys?.length > 0,
      };
    },
    []
  );
  const visiblePortfolios = expanded
    ? portfolios
    : portfolios?.slice(0, PORTFOLIOS_TO_SHOW);
  const showExpand =
    (portfolios?.length || 0) > PORTFOLIOS_TO_SHOW && !expanded;
  return { visiblePortfolios, description, setExpanded, showExpand };
};

export default usePortfolioListGridSharedLogic;
