import React from 'react';
import {Section} from "./Section";
import {IllioTable} from "../../../IllioTable/IllioTable";
import {useInstrumentPage} from "../context/useInstrumentPage";
import {useTranslation} from "react-i18next";
import {IColumn} from "../../../IllioTable/types";
import {PositionInstrumentAggregation} from "@iliotech/generated-api-v3";

interface IOwnProps {

}

export const InstrumentIncomeTable = (props: IOwnProps) => {
  
  const {positionInstrumentAggregation} = useInstrumentPage();
  const {t} = useTranslation();
  
  const cashFlowIncome =
    positionInstrumentAggregation?.cashFlowIncome?.map((i) => {
      return { ...i, key: `instrument-${i.positionId}` };
    }) || [];
  return (
  
    <Section title={t("Income")}>
      <IllioTable
        columns={IncomeColumns}
        data={cashFlowIncome}
        initialSort={[{ field: "bookingDate", dir: "desc" }]}
        useColumnChooser={false}
        isPageable={true}
        pageSize={10}
      />
    </Section>
  );
};


const IncomeColumns: IColumn<
  PositionInstrumentAggregation["cashFlowIncome"][number]
>[] = [
  // {
  //   field: "eventType",
  //   title: "Type",
  //   isDefault: true,
  // },
  {
    field: "bookingDate",
    renderer: IllioTable.DateCell(),
    title: "Date",
    isDefault: true,
  },
  // {
  //   field: "balance",
  //   renderer: IllioTable.DecimalCell({}),
  //   title: "Balance",
  //   isDefault: true,
  // },
  {
    field: "eventName",
    title: "Event",
    isDefault: true,
  },
  // {
  //   field: "settledOnDate",
  //   renderer: IllioTable.DateCell(),
  //   title: "Settlement Date",
  //   isDefault: true,
  // },
  {
    field: "amountLocal",
    renderer: IllioTable.NumberCell(),
    width: 100,
    title: "Amount",
    isDefault: true,
  },
  
  {
    field: "reference",
    title: "Source",
    isDefault: true,
  },
];
