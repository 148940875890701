import {
  PortfolioAndResourceGroupsWorkgroupsApi,
  PortfolioSummaryDto,
} from "@iliotech/generated-api-v3";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import { API_BASE } from "../../constants/constants";
import debounce from "lodash/debounce";

type LoadingState = "idle" | "pending" | "done" | "error";
type Portfolio = {};
const StoreMap = new Map<string, ReturnType<typeof PortfolioListFactory>>();

const api = new PortfolioAndResourceGroupsWorkgroupsApi(
  undefined,
  `${API_BASE}/api`
);

const PortfolioListFactory = (externalAccountId: string) => {
  const store = makeAutoObservable({
    externalAccountId,
    search: "",
    results: undefined as PortfolioSummaryDto[] | undefined,
    initialList: [] as PortfolioSummaryDto[],
    state: "idle" as LoadingState,
    debouncedSearch: undefined as ((search: string) => void) | undefined,
    loadInitialList() {
      this.setState("pending");
      api.listPortfolios(externalAccountId).then((response) => {
        this.initialList = response.data.content;
        this.setState("idle");
      });
    },
    _createDebouncedSearch() {
      const debouncedSearch = debounce((search: string) => {
        api.searchPortfolio(externalAccountId, search).then((response) => {
          if (this.search !== search) return;
          this.results = response.data;
          this.setState("idle");
        });
      }, 1000);
      this.debouncedSearch = debouncedSearch;
      return debouncedSearch;
    },
    setSearch(search: string) {
      const debouncedSearch =
        this.debouncedSearch || this._createDebouncedSearch();
      this.search = search;
      if (!!search) {
        this.setState("pending");
        debouncedSearch(search);
      }
    },
    setState(state: LoadingState) {
      this.state = state;
    },
    filteredPortfolios() {
      if (!this.search) return this.initialList;
      return (this.results || this.initialList)?.filter((portfolio) => {
        return portfolio.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    refetch() {
      if (this.search) {
        this.setSearch(this.search);
      } else {
        this.loadInitialList();
      }
    },
  });

  store.loadInitialList();
  return store;
};
export const PortfolioListStore = (selectedAccountId = "undefined") => {
  if (StoreMap.has(selectedAccountId)) {
    return StoreMap.get(selectedAccountId)!;
  }
  const store = PortfolioListFactory(selectedAccountId);
  StoreMap.set(selectedAccountId, store);
  return store;
};
