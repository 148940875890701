/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { AdvancedRiskDto } from '../../aggregator';
// @ts-ignore
import { AllocationLevelVolatilityDtoV3 } from '../../aggregator';
// @ts-ignore
import { BacktestDataResponse } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { RiskDtoV3 } from '../../aggregator';
// @ts-ignore
import { ScatterDtoV3 } from '../../aggregator';
/**
 * PortfolioRiskApi - axios parameter creator
 * @export
 */
export const PortfolioRiskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} portfolioId 
         * @param {'SHORT' | 'LONG'} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacktest: async (portfolioId: string, term: 'SHORT' | 'LONG', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getBacktest', 'portfolioId', portfolioId)
            // verify required parameter 'term' is not null or undefined
            assertParamExists('getBacktest', 'term', term)
            const localVarPath = `/v3/portfolio/{portfolioId}/backtest`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricBacktest: async (portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getHistoricBacktest', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/volatility/historic`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country'} allocationType 
         * @param {string} [toDate] 
         * @param {number} [volatilityDays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricVolatilityAllocation: async (portfolioId: string, allocationType: 'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country', toDate?: string, volatilityDays?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getHistoricVolatilityAllocation', 'portfolioId', portfolioId)
            // verify required parameter 'allocationType' is not null or undefined
            assertParamExists('getHistoricVolatilityAllocation', 'allocationType', allocationType)
            const localVarPath = `/v3/portfolio/{portfolioId}/volatility/historic/allocation`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allocationType !== undefined) {
                localVarQueryParameter['allocationType'] = allocationType;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }

            if (volatilityDays !== undefined) {
                localVarQueryParameter['volatilityDays'] = volatilityDays;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScatter: async (portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getScatter', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/scatter`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScatterAndHistogram: async (portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getScatterAndHistogram', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/scatterHistogram`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioRiskApi - functional programming interface
 * @export
 */
export const PortfolioRiskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioRiskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} portfolioId 
         * @param {'SHORT' | 'LONG'} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBacktest(portfolioId: string, term: 'SHORT' | 'LONG', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BacktestDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBacktest(portfolioId, term, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoricBacktest(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricBacktest(portfolioId, frequency, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country'} allocationType 
         * @param {string} [toDate] 
         * @param {number} [volatilityDays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoricVolatilityAllocation(portfolioId: string, allocationType: 'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country', toDate?: string, volatilityDays?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationLevelVolatilityDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricVolatilityAllocation(portfolioId, allocationType, toDate, volatilityDays, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScatter(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScatterDtoV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScatter(portfolioId, frequency, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScatterAndHistogram(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvancedRiskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScatterAndHistogram(portfolioId, frequency, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioRiskApi - factory interface
 * @export
 */
export const PortfolioRiskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioRiskApiFp(configuration)
    return {
        /**
         * 
         * @param {string} portfolioId 
         * @param {'SHORT' | 'LONG'} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacktest(portfolioId: string, term: 'SHORT' | 'LONG', options?: any): AxiosPromise<BacktestDataResponse> {
            return localVarFp.getBacktest(portfolioId, term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricBacktest(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: any): AxiosPromise<RiskDtoV3> {
            return localVarFp.getHistoricBacktest(portfolioId, frequency, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country'} allocationType 
         * @param {string} [toDate] 
         * @param {number} [volatilityDays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricVolatilityAllocation(portfolioId: string, allocationType: 'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country', toDate?: string, volatilityDays?: number, options?: any): AxiosPromise<AllocationLevelVolatilityDtoV3> {
            return localVarFp.getHistoricVolatilityAllocation(portfolioId, allocationType, toDate, volatilityDays, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScatter(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: any): AxiosPromise<ScatterDtoV3> {
            return localVarFp.getScatter(portfolioId, frequency, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {'Monthly' | 'Daily'} [frequency] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScatterAndHistogram(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: any): AxiosPromise<AdvancedRiskDto> {
            return localVarFp.getScatterAndHistogram(portfolioId, frequency, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioRiskApi - object-oriented interface
 * @export
 * @class PortfolioRiskApi
 * @extends {BaseAPI}
 */
export class PortfolioRiskApi extends BaseAPI {
    /**
     * 
     * @param {string} portfolioId 
     * @param {'SHORT' | 'LONG'} term 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioRiskApi
     */
    public getBacktest(portfolioId: string, term: 'SHORT' | 'LONG', options?: AxiosRequestConfig) {
        return PortfolioRiskApiFp(this.configuration).getBacktest(portfolioId, term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {'Monthly' | 'Daily'} [frequency] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioRiskApi
     */
    public getHistoricBacktest(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return PortfolioRiskApiFp(this.configuration).getHistoricBacktest(portfolioId, frequency, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country'} allocationType 
     * @param {string} [toDate] 
     * @param {number} [volatilityDays] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioRiskApi
     */
    public getHistoricVolatilityAllocation(portfolioId: string, allocationType: 'AssetClass' | 'AssetSubClass' | 'RiskAssetClass' | 'Region' | 'Currency' | 'IndustrySector' | 'Custodian' | 'CreditRating' | 'FundStrategy' | 'ETFSubClass' | 'PropertySector' | 'RegionOfIssue' | 'MSCIRegion' | 'MSCIClassification' | 'Industry' | 'Country', toDate?: string, volatilityDays?: number, options?: AxiosRequestConfig) {
        return PortfolioRiskApiFp(this.configuration).getHistoricVolatilityAllocation(portfolioId, allocationType, toDate, volatilityDays, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {'Monthly' | 'Daily'} [frequency] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioRiskApi
     */
    public getScatter(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return PortfolioRiskApiFp(this.configuration).getScatter(portfolioId, frequency, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {'Monthly' | 'Daily'} [frequency] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioRiskApi
     */
    public getScatterAndHistogram(portfolioId: string, frequency?: 'Monthly' | 'Daily', fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return PortfolioRiskApiFp(this.configuration).getScatterAndHistogram(portfolioId, frequency, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}
