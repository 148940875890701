import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { emailValidator, textValidatorWrapper } from "@iliotech/data-wire";
import * as React from "react";
import Input from "../Input/Input";

interface IOwnProps {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  placeholder?: string;
}

export const EmailField = ({
  errors,
  name = "email",
  label = "Email",
  placeholder,
  ...rest
}: IOwnProps) => {
  return (
    <Field
      name={name}
      error={errors?.[name]}
      type={"text"}
      component={Input}
      placeholder={placeholder}
      label={label ?? undefined}
      validator={emailValidator}
      {...rest}
    />
  );
};
