import * as React from "react";
import { ForwardedRef } from "react";

interface IProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  handleFile: (f: File) => void;
}

export const HiddenFileInput = React.forwardRef(
  ({ handleFile, ...rest }: IProps, ref: ForwardedRef<HTMLInputElement>) => {
    const handleChange = (e: any) => {
      handleFile(e.target.files[0]);
    };
    return (
      <input
        ref={ref}
        type="file"
        onChange={handleChange}
        style={{ display: "none" }}
        {...rest}
      />
    );
  }
);

export default HiddenFileInput;
