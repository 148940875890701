/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CryptoAdditionalData } from './crypto-additional-data';
import { CryptoEarnData } from './crypto-earn-data';
import { LocalTime } from './local-time';
import { PrivateEquityCashFlow } from './private-equity-cash-flow';
import { PrivateEquityCommitment } from './private-equity-commitment';
import { SourceId } from './source-id';
import { SplitData } from './split-data';
import { TradeImportDividendData } from './trade-import-dividend-data';

/**
 * 
 * @export
 * @interface TradeImport
 */
export interface TradeImport {
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'assetClass'?: TradeImportAssetClassEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'assetSubClass'?: TradeImportAssetSubClassEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'currency'?: TradeImportCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'baseCurrency'?: TradeImportBaseCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'settlementCurrency'?: TradeImportSettlementCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'activityDate': string;
    /**
     * 
     * @type {LocalTime}
     * @memberof TradeImport
     */
    'activityTime'?: LocalTime;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'expiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'buySell': TradeImportBuySellEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'historicPrice'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TradeImport
     */
    'historic': boolean;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'exchange'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'tradeFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'commission'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'fxRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'settlementDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'accruedInterest'?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'settlementOption'?: TradeImportSettlementOptionEnum;
    /**
     * 
     * @type {SourceId}
     * @memberof TradeImport
     */
    'sourceId'?: SourceId;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'custodian'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'strategy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'country'?: TradeImportCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'totalAmountBase'?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'otherTicker'?: string;
    /**
     * 
     * @type {SourceId}
     * @memberof TradeImport
     */
    'otherSourceId'?: SourceId;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'otherQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeImport
     */
    'amendingTradeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'listingExchange'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'isin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'externalInstrumentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'uniqueKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'instrumentId'?: string;
    /**
     * 
     * @type {TradeImportDividendData}
     * @memberof TradeImport
     */
    'dividendData'?: TradeImportDividendData;
    /**
     * 
     * @type {SplitData}
     * @memberof TradeImport
     */
    'split'?: SplitData;
    /**
     * 
     * @type {CryptoEarnData}
     * @memberof TradeImport
     */
    'cryptoEarnData'?: CryptoEarnData;
    /**
     * 
     * @type {CryptoAdditionalData}
     * @memberof TradeImport
     */
    'cryptoAdditionalOperation'?: CryptoAdditionalData;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'portfolioId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeImport
     */
    'otc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeImport
     */
    'brokerAccountId'?: string;
    /**
     * 
     * @type {PrivateEquityCommitment}
     * @memberof TradeImport
     */
    'privateEquityCommitmentData'?: PrivateEquityCommitment;
    /**
     * 
     * @type {PrivateEquityCashFlow}
     * @memberof TradeImport
     */
    'privateEquityCashFlowData'?: PrivateEquityCashFlow;
}

/**
    * @export
    * @enum {string}
    */
export enum TradeImportAssetClassEnum {
    CashAndEquivalents = 'CashAndEquivalents',
    Equities = 'Equities',
    FixedIncome = 'FixedIncome',
    RealEstate = 'RealEstate',
    Alternatives = 'Alternatives',
    Commodities = 'Commodities',
    RealAssets = 'RealAssets',
    CryptoCurrencies = 'CryptoCurrencies',
    SubscriptionWithdrawal = 'SubscriptionWithdrawal',
    Funds = 'Funds',
    Adjustments = 'Adjustments',
    Cfd = 'CFD',
    Fx = 'Fx'
}
/**
    * @export
    * @enum {string}
    */
export enum TradeImportAssetSubClassEnum {
    SubCash = 'SubCash',
    CshCash = 'CshCash',
    CshTermDeposit = 'CshTermDeposit',
    CshMoneyMarket = 'CshMoneyMarket',
    CshCommercialPaper = 'CshCommercialPaper',
    CshCashFunds = 'CshCashFunds',
    AdjFees = 'AdjFees',
    AdjFxSpot = 'AdjFXSpot',
    AdjIncome = 'AdjIncome',
    AdjPnL = 'AdjPnL',
    AdjInterest = 'AdjInterest',
    EqShares = 'EqShares',
    EqEtfs = 'EqETFs',
    EqWarrants = 'EqWarrants',
    EqOptions = 'EqOptions',
    EqIndex = 'EqIndex',
    EqPreferenceShares = 'EqPreferenceShares',
    EqUnlisted = 'EqUnlisted',
    EqCfd = 'EqCfd',
    EqStruct = 'EqStruct',
    EqFutures = 'EqFutures',
    FutOptions = 'FutOptions',
    FutIndex = 'FutIndex',
    FiBonds = 'FiBonds',
    FiEtf = 'FiETF',
    FiLoans = 'FiLoans',
    FiStructuredProducts = 'FiStructuredProducts',
    FiOptions = 'FiOptions',
    FiCds = 'FiCDS',
    FiDistressedDebt = 'FiDistressedDebt',
    FiIndex = 'FiIndex',
    FiConvertibles = 'FiConvertibles',
    FiCoCosAt1sEtc = 'FiCoCosAT1sEtc',
    FiMortgage = 'FiMortgage',
    FiMoneyMarket = 'FiMoneyMarket',
    FiTermDep = 'FiTermDep',
    FiFcn = 'FiFcn',
    FiFutures = 'FiFutures',
    RePhysical = 'RePhysical',
    ReReit = 'ReREIT',
    ReEquity = 'ReEquity',
    ReLoan = 'ReLoan',
    ReIndex = 'ReIndex',
    ReEtf = 'ReETF',
    AltDirectInvestments = 'AltDirectInvestments',
    AltIndex = 'AltIndex',
    ComPhysical = 'ComPhysical',
    ComNonPhysical = 'ComNonPhysical',
    ComIndices = 'ComIndices',
    ComEtfs = 'ComETFs',
    ComOptions = 'ComOptions',
    ComCfd = 'ComCfd',
    ComStruct = 'ComStruct',
    ComFutures = 'ComFutures',
    RaArt = 'RaArt',
    RaWineAndSpirits = 'RaWineAndSpirits',
    RaMotorVehicle = 'RaMotorVehicle',
    RaYacht = 'RaYacht',
    RaJewellery = 'RaJewellery',
    RaAircraft = 'RaAircraft',
    RaOther = 'RaOther',
    RaDigital = 'RaDigital',
    RaLivestock = 'RaLivestock',
    RaHorses = 'RaHorses',
    CryptoCurrencies = 'CryptoCurrencies',
    CryptoIndex = 'CryptoIndex',
    CryptoTokens = 'CryptoTokens',
    CryptoStablecoins = 'CryptoStablecoins',
    CryptoOptions = 'CryptoOptions',
    CryptoStruct = 'CryptoStruct',
    CryptoFutures = 'CryptoFutures',
    FunEquity = 'FunEquity',
    FunEtf = 'FunETF',
    FunFixedIncome = 'FunFixedIncome',
    FunMixed = 'FunMixed',
    FunSpeciality = 'FunSpeciality',
    FunRealEstate = 'FunRealEstate',
    FunCommodity = 'FunCommodity',
    FunMoneyMarket = 'FunMoneyMarket',
    FunAlternatives = 'FunAlternatives',
    FunAltHedgeFunds = 'FunAltHedgeFunds',
    FunAltPrivateEquity = 'FunAltPrivateEquity',
    PrivateEquityVc = 'PrivateEquityVC',
    FunAltVentureCapital = 'FunAltVentureCapital',
    FunAltFundOfFunds = 'FunAltFundOfFunds',
    FunOther = 'FunOther',
    FundCfd = 'FundCfd',
    FundStruct = 'FundStruct',
    CfdOther = 'CFDOther',
    FxOptions = 'FxOptions',
    FxFwd = 'FxFwd',
    FxTarf = 'FxTarf',
    FxStruct = 'FxStruct',
    FxFutures = 'FxFutures'
}
/**
    * @export
    * @enum {string}
    */
export enum TradeImportCurrencyEnum {
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Nzd = 'NZD',
    Usd = 'USD',
    Kwd = 'KWD',
    Jod = 'JOD',
    Chf = 'CHF',
    Cad = 'CAD',
    Sgd = 'SGD',
    Ils = 'ILS',
    Qar = 'QAR',
    Aed = 'AED',
    Sar = 'SAR',
    Pln = 'PLN',
    Myr = 'MYR',
    Brl = 'BRL',
    Ron = 'RON',
    Try = 'TRY',
    Cny = 'CNY',
    Cnh = 'CNH',
    Hkd = 'HKD',
    Nok = 'NOK',
    Sek = 'SEK',
    Zar = 'ZAR',
    Egp = 'EGP',
    Mxn = 'MXN',
    Czk = 'CZK',
    Twd = 'TWD',
    Thb = 'THB',
    Uyu = 'UYU',
    Php = 'PHP',
    Ars = 'ARS',
    Rub = 'RUB',
    Inr = 'INR',
    Jpy = 'JPY',
    Isk = 'ISK',
    Pkr = 'PKR',
    Huf = 'HUF',
    Ngn = 'NGN',
    Krw = 'KRW',
    Idr = 'IDR',
    Dkk = 'DKK',
    Vnd = 'VND',
    Clp = 'CLP',
    Gbx = 'GBX',
    Ila = 'ILA',
    Zac = 'ZAC'
}
/**
    * @export
    * @enum {string}
    */
export enum TradeImportBaseCurrencyEnum {
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Nzd = 'NZD',
    Usd = 'USD',
    Kwd = 'KWD',
    Jod = 'JOD',
    Chf = 'CHF',
    Cad = 'CAD',
    Sgd = 'SGD',
    Ils = 'ILS',
    Qar = 'QAR',
    Aed = 'AED',
    Sar = 'SAR',
    Pln = 'PLN',
    Myr = 'MYR',
    Brl = 'BRL',
    Ron = 'RON',
    Try = 'TRY',
    Cny = 'CNY',
    Cnh = 'CNH',
    Hkd = 'HKD',
    Nok = 'NOK',
    Sek = 'SEK',
    Zar = 'ZAR',
    Egp = 'EGP',
    Mxn = 'MXN',
    Czk = 'CZK',
    Twd = 'TWD',
    Thb = 'THB',
    Uyu = 'UYU',
    Php = 'PHP',
    Ars = 'ARS',
    Rub = 'RUB',
    Inr = 'INR',
    Jpy = 'JPY',
    Isk = 'ISK',
    Pkr = 'PKR',
    Huf = 'HUF',
    Ngn = 'NGN',
    Krw = 'KRW',
    Idr = 'IDR',
    Dkk = 'DKK',
    Vnd = 'VND',
    Clp = 'CLP',
    Gbx = 'GBX',
    Ila = 'ILA',
    Zac = 'ZAC'
}
/**
    * @export
    * @enum {string}
    */
export enum TradeImportSettlementCurrencyEnum {
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Nzd = 'NZD',
    Usd = 'USD',
    Kwd = 'KWD',
    Jod = 'JOD',
    Chf = 'CHF',
    Cad = 'CAD',
    Sgd = 'SGD',
    Ils = 'ILS',
    Qar = 'QAR',
    Aed = 'AED',
    Sar = 'SAR',
    Pln = 'PLN',
    Myr = 'MYR',
    Brl = 'BRL',
    Ron = 'RON',
    Try = 'TRY',
    Cny = 'CNY',
    Cnh = 'CNH',
    Hkd = 'HKD',
    Nok = 'NOK',
    Sek = 'SEK',
    Zar = 'ZAR',
    Egp = 'EGP',
    Mxn = 'MXN',
    Czk = 'CZK',
    Twd = 'TWD',
    Thb = 'THB',
    Uyu = 'UYU',
    Php = 'PHP',
    Ars = 'ARS',
    Rub = 'RUB',
    Inr = 'INR',
    Jpy = 'JPY',
    Isk = 'ISK',
    Pkr = 'PKR',
    Huf = 'HUF',
    Ngn = 'NGN',
    Krw = 'KRW',
    Idr = 'IDR',
    Dkk = 'DKK',
    Vnd = 'VND',
    Clp = 'CLP',
    Gbx = 'GBX',
    Ila = 'ILA',
    Zac = 'ZAC'
}
/**
    * @export
    * @enum {string}
    */
export enum TradeImportBuySellEnum {
    Sell = 'SELL',
    Buy = 'BUY',
    Add = 'ADD',
    Withdraw = 'WITHDRAW',
    Rebate = 'REBATE',
    Deduction = 'DEDUCTION',
    DividendReceived = 'DIVIDEND_RECEIVED',
    SwapL = 'SWAP_L',
    SwapS = 'SWAP_S',
    Reserve = 'RESERVE',
    Unreserve = 'UNRESERVE',
    Earn = 'EARN',
    Coupon = 'COUPON',
    Accrued = 'ACCRUED'
}
/**
    * @export
    * @enum {string}
    */
export enum TradeImportSettlementOptionEnum {
    Auto = 'AUTO',
    Base = 'BASE',
    Local = 'LOCAL'
}
/**
    * @export
    * @enum {string}
    */
export enum TradeImportCountryEnum {
    Af = 'AF',
    Ax = 'AX',
    Al = 'AL',
    Dz = 'DZ',
    As = 'AS',
    Ad = 'AD',
    Ao = 'AO',
    Ai = 'AI',
    Ag = 'AG',
    Ar = 'AR',
    Am = 'AM',
    Aw = 'AW',
    Au = 'AU',
    Xt = 'XT',
    Xe = 'XE',
    Xy = 'XY',
    At = 'AT',
    Az = 'AZ',
    Bs = 'BS',
    Bh = 'BH',
    Bd = 'BD',
    Bb = 'BB',
    By = 'BY',
    Be = 'BE',
    Bz = 'BZ',
    Bj = 'BJ',
    Bm = 'BM',
    Bt = 'BT',
    Bo = 'BO',
    Bq = 'BQ',
    Ba = 'BA',
    Bw = 'BW',
    Bv = 'BV',
    Br = 'BR',
    Io = 'IO',
    Bn = 'BN',
    Bg = 'BG',
    Bf = 'BF',
    Bi = 'BI',
    Cv = 'CV',
    Kh = 'KH',
    Cm = 'CM',
    Ca = 'CA',
    Ky = 'KY',
    Cf = 'CF',
    Td = 'TD',
    Cl = 'CL',
    Cn = 'CN',
    Cx = 'CX',
    Cc = 'CC',
    Co = 'CO',
    Km = 'KM',
    Cg = 'CG',
    Cd = 'CD',
    Ck = 'CK',
    Cr = 'CR',
    Ci = 'CI',
    Hr = 'HR',
    Cu = 'CU',
    Cw = 'CW',
    Cy = 'CY',
    Cz = 'CZ',
    Dk = 'DK',
    Xs = 'XS',
    Dj = 'DJ',
    Dm = 'DM',
    Do = 'DO',
    Ec = 'EC',
    Eg = 'EG',
    Eu = 'EU',
    Sv = 'SV',
    Xp = 'XP',
    Gq = 'GQ',
    Er = 'ER',
    Ee = 'EE',
    Sz = 'SZ',
    Et = 'ET',
    Fk = 'FK',
    Fo = 'FO',
    Fj = 'FJ',
    Fi = 'FI',
    Fr = 'FR',
    Gf = 'GF',
    Pf = 'PF',
    Tf = 'TF',
    Xr = 'XR',
    Ga = 'GA',
    Gm = 'GM',
    Ge = 'GE',
    De = 'DE',
    Gh = 'GH',
    Gi = 'GI',
    Gr = 'GR',
    Gl = 'GL',
    Gd = 'GD',
    Gp = 'GP',
    Gu = 'GU',
    Gt = 'GT',
    Gg = 'GG',
    Gn = 'GN',
    Gw = 'GW',
    Gy = 'GY',
    Ht = 'HT',
    Hm = 'HM',
    Va = 'VA',
    Hn = 'HN',
    Hk = 'HK',
    Hu = 'HU',
    Is = 'IS',
    In = 'IN',
    Id = 'ID',
    Ir = 'IR',
    Iq = 'IQ',
    Ie = 'IE',
    Im = 'IM',
    Il = 'IL',
    It = 'IT',
    Jm = 'JM',
    Jp = 'JP',
    Xh = 'XH',
    Je = 'JE',
    Jo = 'JO',
    Kz = 'KZ',
    Ke = 'KE',
    Ki = 'KI',
    Kp = 'KP',
    Kr = 'KR',
    Kw = 'KW',
    Kg = 'KG',
    La = 'LA',
    Lv = 'LV',
    Lb = 'LB',
    Ls = 'LS',
    Lr = 'LR',
    Ly = 'LY',
    Li = 'LI',
    Lt = 'LT',
    Lu = 'LU',
    Mo = 'MO',
    Mg = 'MG',
    Mw = 'MW',
    My = 'MY',
    Mv = 'MV',
    Ml = 'ML',
    Mt = 'MT',
    Mh = 'MH',
    Mq = 'MQ',
    Mr = 'MR',
    Mu = 'MU',
    Yt = 'YT',
    Mx = 'MX',
    Fm = 'FM',
    Md = 'MD',
    Mc = 'MC',
    Mn = 'MN',
    Me = 'ME',
    Ms = 'MS',
    Ma = 'MA',
    Mz = 'MZ',
    Mm = 'MM',
    Na = 'NA',
    Nr = 'NR',
    Np = 'NP',
    Nl = 'NL',
    Nc = 'NC',
    Nz = 'NZ',
    Ni = 'NI',
    Ne = 'NE',
    Ng = 'NG',
    Nu = 'NU',
    Nf = 'NF',
    Mk = 'MK',
    Mp = 'MP',
    No = 'NO',
    Om = 'OM',
    Pk = 'PK',
    Pw = 'PW',
    Ps = 'PS',
    Pa = 'PA',
    Pg = 'PG',
    Py = 'PY',
    Pe = 'PE',
    Ph = 'PH',
    Pn = 'PN',
    Pl = 'PL',
    Pt = 'PT',
    Pr = 'PR',
    Qa = 'QA',
    Re = 'RE',
    Ro = 'RO',
    Ru = 'RU',
    Rw = 'RW',
    Bl = 'BL',
    Sh = 'SH',
    Kn = 'KN',
    Lc = 'LC',
    Mf = 'MF',
    Pm = 'PM',
    Vc = 'VC',
    Ws = 'WS',
    Sm = 'SM',
    St = 'ST',
    Sa = 'SA',
    Sn = 'SN',
    Rs = 'RS',
    Sc = 'SC',
    Sl = 'SL',
    Sg = 'SG',
    Sx = 'SX',
    Sk = 'SK',
    Si = 'SI',
    Sb = 'SB',
    So = 'SO',
    Za = 'ZA',
    Gs = 'GS',
    Ss = 'SS',
    Es = 'ES',
    Lk = 'LK',
    Sd = 'SD',
    Sr = 'SR',
    Sj = 'SJ',
    Se = 'SE',
    Ch = 'CH',
    Sy = 'SY',
    Tw = 'TW',
    Tj = 'TJ',
    Tz = 'TZ',
    Th = 'TH',
    Tl = 'TL',
    Tg = 'TG',
    Tk = 'TK',
    To = 'TO',
    Tt = 'TT',
    Tn = 'TN',
    Tr = 'TR',
    Tm = 'TM',
    Tc = 'TC',
    Tv = 'TV',
    Ug = 'UG',
    Ua = 'UA',
    Ae = 'AE',
    Gb = 'GB',
    Uk = 'UK',
    Us = 'US',
    Um = 'UM',
    Uy = 'UY',
    Uz = 'UZ',
    Vu = 'VU',
    Ve = 'VE',
    Vn = 'VN',
    Vg = 'VG',
    Vi = 'VI',
    Wf = 'WF',
    Eh = 'EH',
    Ye = 'YE',
    Zm = 'ZM',
    Zw = 'ZW',
    Xf = 'XF',
    Xx = 'XX',
    Xa = 'XA',
    Xb = 'XB',
    Xc = 'XC',
    Xd = 'XD',
    Xg = 'XG',
    Xi = 'XI',
    Xj = 'XJ',
    Xk = 'XK',
    Xl = 'XL',
    Xm = 'XM',
    Xn = 'XN',
    Xo = 'XO',
    Xq = 'XQ'
}


