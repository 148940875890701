import React from "react";
import {
  ReferenceDataApi,
  ReferenceDataResponse,
} from "@iliotech/generated-api-v3";
// import type { Response } from "@iliotech/generated-api-v3";

import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";

export const useReferenceData = () => {
  const api = new ReferenceDataApi(undefined, `${API_BASE}/api`);
  const { error, isLoading, data, isFetched } = useQuery(`reference-data`, () =>
    api.get()
  );

  return React.useMemo(() => {
    const raw = data?.data;
    // if (!raw) {
    //   return { isLoading, error, raw };
    // }

    const AssetClass = makeMapFromCode(raw, "assetClasses");
    const AssetSubclass = makeMapFromCode(raw, "assetSubclasses");
    const Country = makeMapFromCode(raw, "countries");
    const ResidentialCountries = makeMapFromCode(raw, "residentialCountries");
    const Region = makeMapFromCode(raw, "regions");
    const Sector = makeMapFromCode(raw, "sectors");
    const Currency = makeMapFromCode(raw, "currencies");
    const CurrencyBase = makeMapFromCode(raw, "baseCurrencies");

    const referenceData = {
      isLoading,
      isFetched,
      error,
      raw,
      AssetClass,
      AssetSubclass,
      Country,
      Region,
      Sector,
      Currency,
      CurrencyBase,
      ResidentialCountries
    };

    const makeGetLabel = (key: string, isGroupIdSelected = false) => {
      if (!key) {
        return (id: string) => id;
      }
      let labelField = key;
      switch (labelField) {
        case "regionCode":
          if (isGroupIdSelected) {
            labelField = "country";
          }
      }
      const mappedLabels = (referenceData as any)?.[STRUCTURE_MAP[labelField]];
      return (id: string) => {
        return mappedLabels?.[id]?.label || id;
      };
    };

    return {
      ...referenceData,
      makeGetLabel,
    };

    // return payload;
  }, [data, isLoading, error, isFetched]);
  // return payload;
};

function makeMapFromCode<K extends keyof ReferenceDataResponse>(
  response: ReferenceDataResponse | undefined,
  key: K
) {
  const details: Record<string, ReferenceDataResponse[K][number]> = {};
  if (typeof response === "undefined" || typeof response === "string") {
    return undefined;
  }

  for (let entry of response[key]) {
    details[entry.code] = entry;
  }

  return details;
}

export const STRUCTURE_MAP: Record<string, string> = {
  assetClassCode: "AssetClass",
  riskAssetClassCode: "AssetClass",
  assetSubclasses: "AssetSubclass",
  country: "Country",
  regionCode: "Region",
  sectorCode: "Sector",
  // currencyLocalName: "Currency",
};
