import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { Input } from "@progress/kendo-react-inputs";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";

const OtherCostsCell = ({ index }: { index: number }) => {
  const store = useTransactionUploadV2();
  const commission = store.equities.getField(
    "tradeCosts",
    index + ""
  ) as number;

  const onChange = (event: InputChangeEvent) => {
    store.equities.updateTableRowByIndex("tradeCosts", event.value, index + "");
  };

  return (
    <Input
      name={"tradeCosts"}
      type={"number"}
      value={commission}
      onChange={onChange}
    />
  );
};

export default observer(OtherCostsCell);
