import { Buffer } from "buffer";
import Process from "process";
console.log({Buffer})

// if(import.meta.env.DEV){

// const Buffer = require("Buffer").Buffer;

if(import.meta.env.DEV) {
  // do nothing
} else {
  if (global) {
// @ts-ignore
    (global as any).process = Process;
// @ts-ignore
    (global as any).Buffer = Buffer;
  }
}

if(globalThis){
  // @ts-ignore
  (globalThis as any).Buffer = Buffer;
}
// }

if (globalThis) {
// @ts-ignore
  globalThis.process = Process;
}
