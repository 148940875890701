import React, { ReactElement } from "react";
import FormModal from "../FormModal/FormModal";
import cn from "classnames";
import s from "./TabMenu.module.scss";
import { TabMenuNav } from "./TabMenuNav";
import { Typography } from "@progress/kendo-react-common";
import { useNavigate } from "react-router-dom";
import { CgClose } from "react-icons/cg";

interface IOwnProps {
  title: string;
  tabs: { id: string; label: string }[];
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

export const TabMenu: React.FunctionComponent<IOwnProps> = ({
  title,
  tabs,
  selectedTab,
  setSelectedTab,
  children,
}) => {
  const navigate = useNavigate();

  const close = () => void navigate(-1);

  return (
    <div className={cn(s.outer)}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cn(s.surround)}
        // style={modalStyle}
        // {...restProps}
      >
        <CgClose
          data-cy={"tab-modal-close"}
          onClick={close}
          size={26}
          className={s.closeButton}
        />
        {/*<CgClose onClick={close} size={26} className={s.modalClose}/>*/}
        <Typography.h3 style={{ paddingLeft: "2rem" }}>{title}</Typography.h3>
        <div className={cn(s.layout)}>
          <TabMenuNav
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabs={tabs}
          />
          <div className={cn(s.content)}>{children}</div>
        </div>
      </div>
    </div>
  );
};

// <FormModal
//   handleClose={() => {navigate(-1)}}
//   visible={true}
//   closeOnBackdropClick={false}
//   innerContainerStyle={{ padding: 0 }}
//   modalStyle={{ padding: "30px 0 0 0" }}
// >
