import { useWhatIfPositions } from "@iliotech/data-wire/src/stores";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { WhatIfPositionsTableCellProps } from "../WhatIfPositionsTable";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import s from "./Cells.module.scss";

const QuantityCell = ({ index }: WhatIfPositionsTableCellProps) => {
  const store = useWhatIfPositions();
  const quantity = store.getField<number>("quantity", index);
  const disabled = !quantity;
  const onChange = (event: NumericTextBoxChangeEvent) => {
    store.onRowQuantityChange(index, event?.value || 0);
  };

  return (
    <NumericTextBox
      placeholder="Enter quantity"
      name={"quantity"}
      value={quantity || 0}
      format={{
        maximumFractionDigits: 7,
      }}
      className={classNames(disabled && s.disabled)}
      spinners={false}
      onChange={onChange}
    />
  );
};

export default observer(QuantityCell);
