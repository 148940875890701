import styled from '@emotion/styled';

export const StyledGlowBackground = styled.div`
  position: relative;
  :after {
    content: " ";
    position: absolute;
    top: -25px;
    left: -25px;
    height: calc(100% + 50px);
    width: calc(100% + 50px);
    opacity: 0.15;
    background: radial-gradient(circle at center, var(--primary-bright) 0, var(--secondary) 70%);
  }
`;

