import React from "react";
import s from "./PageSummary.module.scss";
import { useGlobalCurrency } from "@iliotech/data-wire";
import { classNames } from "@progress/kendo-react-common";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { useNavigate, useParams } from "react-router";
import { FadeLoading } from "@iliotech/component-library";
interface IProps {
  data: {
    portfolios: number;
    totalMarketValue: number;
    workgroups: number;
    loading?: boolean;
  };
  valueFormatter: (v: number) => string;
}

export const PageSummary = ({ data, valueFormatter }: IProps) => {
  const positiveNav = data?.totalMarketValue > 0;
  const { workgroupName } = useParams();
  const navigate = useNavigate();

  const onCreatePortfolio = () => {
    if (workgroupName) {
      navigate("/create-portfolio/" + workgroupName);
    } else {
      navigate("/create-portfolio/select");
    }
  };
  return (
    <FadeLoading loading={data?.loading}>
      <div className={s.row}>
        <article>
          <div className={s.row}>
            <h2 className={classNames(s.nav, positiveNav && s.positive)}>
              {valueFormatter(data?.totalMarketValue) || 0}
            </h2>
            {positiveNav ? (
              <GoTriangleUp size={21} style={{ color: "var(--good)" }} />
            ) : (
              <GoTriangleDown size={21} style={{ color: "var(--bad)" }} />
            )}
          </div>
          <span className={classNames("detail", s.bottom)}>TOTAL NAV</span>
        </article>
        {!workgroupName && (
          <article>
            <h2>{data?.workgroups || 0}</h2>
            <span className={classNames("detail", s.bottom)}>WORKGROUPS</span>
          </article>
        )}
        <article>
          <h2>{data?.portfolios || 0}</h2>
          <span className={classNames("detail", s.bottom)}>PORTFOLIOS</span>
        </article>
        <article onClick={onCreatePortfolio} className={s.button}>
          <span>+ Add new portfolio</span>
        </article>
      </div>
    </FadeLoading>
  );
};
