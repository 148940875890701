import * as React from "react";
import { TransactionTableCellProps } from "./DateCell";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { observer } from "mobx-react-lite";

const CurrencyCell = ({ index }: TransactionTableCellProps) => {
  const store = useTransactionUploadV2();
  const currency = store.equities.getField("currency", index + "") as string;
  return <p>{currency}</p>;
};

export default observer(CurrencyCell);
