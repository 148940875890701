import React, { CSSProperties } from "react";
import { ColumnChooserProps, EnabledColumnMap, IColumn } from "./types";
import { Checkbox } from "@progress/kendo-react-inputs";
import cn from "classnames";
import s from "./IllioColumnChooser.module.scss";
import FormModal from "../FormModal/FormModal";
import { RoundButton } from "../RoundButton/RoundButton";
import { Typography } from "@progress/kendo-react-common";
import { sendTracking, usePreferences } from "@iliotech/data-wire";
import { FaChevronLeft, FaChevronRight, FaChevronUp } from "react-icons/all";
import { FaChevronDown } from "react-icons/fa";
import RoundToggle from "../RoundToggle/RoundToggle";

interface IOwnProps<T> {
  columns: IColumn<T>[];
  baseCurrency?: string;
  buttonStyle?: CSSProperties;
}

// const sortByTitleOrField = (a: IColumn, b: IColumn) =>
//   (a.title || a.field || "").localeCompare(b.title || b.field || "");

enum Modes {
  CHOOSE,
  MOVE,
}

const sortByGroupAndTitle = (a: IColumn, b: IColumn) =>
  (a.group ?? "").localeCompare(b.group ?? "") * 1000 +
  (a.title || a.field || "").localeCompare(b.title || b.field || "");

export const IllioColumnChooser = <T,>({
  columns,
  columnSettings,
  setColumnSettings,
  baseCurrency,
  buttonStyle,
  userPreferencesTableId,
  hideButton = false,
}: IOwnProps<T> & ColumnChooserProps) => {
  const [highlightedColumns, setHighlightedColumns] = React.useState<{
    [field: string]: boolean;
  }>({});
  const [search, setSearch] = React.useState("");
  const [mode, setMode] = React.useState(Modes.CHOOSE);

  const enabledColumns = Object.fromEntries(
    Object.entries(columnSettings).filter(([, settings]) => settings.enabled)
  );
  const [visible, setVisible] = React.useState(false);
  const titleRenderer = (title: string) => {
    return title.replace("£", baseCurrency || "Base");
  };

  const { preferences, updateColumnPreferences } = usePreferences();

  const storedPreferences = userPreferencesTableId
    ? preferences?.misc?.consumerColumnSettings
    : undefined;

  const selectDefaults = React.useCallback(() => {
    const nextEnabledColumns: EnabledColumnMap = {};
    columns.forEach((column) => {
      if (!!column.isDefault) {
        nextEnabledColumns[column.field] = {
          enabled: true,
          order: column.order ?? 1000,
        };
      }
    });
    setColumnSettings(nextEnabledColumns);
  }, [setColumnSettings, columns]);

  React.useEffect(() => {
    if (!storedPreferences || !userPreferencesTableId) {
      selectDefaults();
      return;
    }

    const parsedPreferences = JSON.parse(storedPreferences);
    const currentTablePreferences = parsedPreferences[userPreferencesTableId];

    if (
      !currentTablePreferences ||
      !Object.values(currentTablePreferences?.visibleColumns || {}).some(
        (v) => v
      )
    ) {
      return;
    }

    setColumnSettings(currentTablePreferences?.visibleColumns);
  }, [
    columns,
    storedPreferences,
    userPreferencesTableId,
    setColumnSettings,
    selectDefaults,
  ]);

  const handleColumnSelected = (column: IColumn) => {
    setHighlightedColumns((prev) => ({
      ...prev,
      [column.field]: !prev[column.field],
    }));
  };

  const toggleVisible = () => setVisible((prev) => !prev);

  const move = (from: number, to: number) => {
    let nextOrder = 0;
    const targetField = selectedColumns[from].field;
    if (to === 0) {
      nextOrder = (selectedColumns[0].order || 0) - 1;
    } else if (to === selectedColumns.length - 1) {
      nextOrder = Math.max(...selectedColumns.map((col) => col.order ?? 0)) + 1;
    } else {
      const direction = from > to ? -1 : 1;
      const other = to + direction;
      const first = selectedColumns[to]?.order ?? 0;
      const second = selectedColumns[other]?.order ?? 0;
      nextOrder = (first + second) / 2;
    }

    setColumnSettings((prev) =>
      Object.fromEntries(
        Object.entries(columnSettings).map(([field, value]) => [
          field,
          {
            ...value,
            order: field === targetField ? nextOrder : value.order,
          },
        ])
      )
    );
  };

  const renderAvailableColumn =
    (sortable = true, count = 0) =>
    (column: IColumn, index: number) => {
      return (
        <div
          className={cn("illio-checkbox", s.checkbox, sortable && s.sortable)}
          key={column.field}
        >
          <Checkbox
            key={column.field}
            label={titleRenderer(
              column?.title ??
                `${column?.field
                  ?.slice(0, 1)
                  .toUpperCase()}${column?.field?.slice(1)}`
            )}
            value={!!highlightedColumns[column.field]} // double-negation used to ensure component is controlled
            onClick={
              column.mandatory ? undefined : () => handleColumnSelected(column)
            }
            className={cn(s.check, column.mandatory && s.hidden)}
          />

          <>
            {sortable && (
              <>
                {index > 0 && (
                  <div
                    className={cn(s.moveChevronBox)}
                    onClick={() => move(index, index - 1)}
                    style={{ paddingBottom: 5 }}
                  >
                    <FaChevronUp style={{ padding: 0, marginBottom: -5 }} />
                  </div>
                )}
                {index < count - 1 && (
                  <div
                    className={cn(s.moveChevronBox)}
                    onClick={() => move(index, index + 1)}
                    style={{ paddingTop: 5 }}
                  >
                    <FaChevronDown style={{ padding: 0, marginTop: -5 }} />
                  </div>
                )}
              </>
            )}
          </>
        </div>
      );
    };

  const moveColumns = (toSelected: boolean) => () => {
    /**
     * Move all highlighted columns to either selected or deselected
     * Unset the column highlighting status for the columns that were moved
     */

    const nextHighlights = { ...highlightedColumns };
    setColumnSettings((prev) =>
      Object.fromEntries(
        columns
          .map((col) => {
            const isHighlighted = highlightedColumns[col.field];
            if (isHighlighted && !!prev[col.field]?.enabled !== toSelected) {
              nextHighlights[col.field] = false;
            }
            return [
              col.field,
              {
                enabled: highlightedColumns[col.field]
                  ? toSelected
                  : !!prev[col.field]?.enabled,
                order: prev[col.field]?.order || col.order,
              },
            ];
          })
          .filter(([, setting]) => (setting as any).enabled)
      )
    );

    /**
     * Unset the column highlighting status for the columns that were moved
     */
    setHighlightedColumns(nextHighlights);
  };

  const sortByOrder = (a: IColumn, b: IColumn) =>
    (enabledColumns[a.field]?.order ?? 0) -
    (enabledColumns[b.field]?.order ?? 0);

  // const columnsForSelection = highlightedColumns;
  const availableColumns: IColumn<T>[] = [];
  const selectedColumns = columns
    .filter((col) => {
      const isEnabled = !!enabledColumns[col.field]?.enabled;
      if (!isEnabled) {
        availableColumns.push(col);
      }
      return isEnabled;
    })
    .map((col) => ({
      ...col,
      order: enabledColumns[col.field]?.order ?? col.order,
    }))
    .sort(sortByOrder);

  const hasColumnsForDeselection = selectedColumns.some(
    (col) => !!highlightedColumns[col.field]
  );
  const hasColumnsForSelection = availableColumns.some(
    (col) => !!highlightedColumns[col.field]
  );

  const save = () => {
    toggleVisible();
    if (userPreferencesTableId) {
      void updateColumnPreferences(userPreferencesTableId, {
        visibleColumns: enabledColumns,
        sort: {},
      });
    }
    sendTracking(
      `selected_columns:${JSON.stringify(enabledColumns)}`,
      "column_chooser_tracker"
    );
    // console.log(enabledColumns);
  };

  let currentGroup: string;

  return (
    <>
      {!hideButton && (
        <div
          className={cn(s.visibilityToggle)}
          style={buttonStyle}
          onClick={toggleVisible}
          data-cy={"column-chooser-button"}
        >
          Select Columns
        </div>
      )}
      {visible && (
        <FormModal
          handleClose={() => setVisible(false)}
          visible={visible}
          wrapperId={"app"}
          footer={
            <div
              className={s.footer}
              id={"column-chooser-footer"}
              data-cy={"column-chooser-footer"}
            >
              <div className={s.links}>
                <div
                  className={cn(s.selectionOptionButton)}
                  onClick={selectDefaults}
                >
                  Defaults
                </div>
              </div>
              <RoundButton
                disabled={!hasColumnsForSelection}
                onClick={moveColumns(true)}
                className={cn(
                  s.button,
                  s.mobileOnly,
                  mode !== Modes.CHOOSE && s.hiddenOnMobile
                )}
              >
                Add Columns
              </RoundButton>
              <RoundButton
                disabled={!hasColumnsForDeselection}
                onClick={moveColumns(false)}
                className={cn(
                  s.button,
                  s.mobileOnly,
                  mode !== Modes.MOVE && s.hiddenOnMobile
                )}
              >
                Remove Columns
              </RoundButton>
              <RoundButton onClick={save} className={cn(s.button)}>
                Save preferences
              </RoundButton>
              {/*<RoundButton onClick={toggleVisible} className={cn(s.button)}>*/}
              {/*  Close*/}
              {/*</RoundButton>*/}
            </div>
          }
        >
          <div>
            <Typography.h3>Select columns</Typography.h3>
            <Typography.p>
              Add or remove columns by selecting them and clicking the right or
              left arrows
            </Typography.p>
            <div className={cn(s.mobileOnly)} style={{ marginBottom: "1rem" }}>
              <RoundToggle
                values={[
                  {
                    label: "Choose columns",
                    value: Modes.CHOOSE,
                  },
                  {
                    label: "Column Order",
                    value: Modes.MOVE,
                  },
                ]}
                activeValue={mode}
                onChange={setMode}
              />
            </div>
            <div className={s.columns}>
              <div
                className={cn(
                  s.column,
                  mode !== Modes.CHOOSE && s.hiddenOnMobile
                )}
              >
                <p className={cn(s.columnLabel)}>Choose columns from:</p>
                {/*<SearchBox value={search} onChange={setSearch} />*/}
                <div className={cn(s.itemSurround)}>
                  {!!columns?.length &&
                    availableColumns
                      .sort(sortByGroupAndTitle)
                      .filter((c) =>
                        (c.title || c.field)
                          ?.toUpperCase()
                          .includes(search.toUpperCase())
                      )
                      .map((col, i) => {
                        const group = col.group ?? "General Columns";
                        const label =
                          group !== currentGroup ? (
                            <div className={cn(s.checkbox, s.groupName)}>
                              <b>{group}</b>
                            </div>
                          ) : null;
                        currentGroup = group;
                        return (
                          <React.Fragment key={col.field}>
                            {label}
                            {renderAvailableColumn(false)(col, i)}
                          </React.Fragment>
                        );
                      })}
                </div>
              </div>
              <div className={cn(s.column, s.chevronColumn, s.hiddenOnMobile)}>
                <div
                  className={cn(s.chevron, {
                    [s.disabled]: !hasColumnsForSelection,
                  })}
                  onClick={moveColumns(true)}
                >
                  <FaChevronRight color={"white"} />
                </div>
                <div
                  className={cn(s.chevron, {
                    [s.disabled]: !hasColumnsForDeselection,
                  })}
                  onClick={moveColumns(false)}
                >
                  <FaChevronLeft color={"white"} />
                </div>
              </div>
              <div
                className={cn(
                  s.column,
                  mode !== Modes.MOVE && s.hiddenOnMobile
                )}
              >
                <p className={cn(s.columnLabel)}>Selected columns:</p>
                {!!columns?.length &&
                  selectedColumns
                    .sort(sortByOrder)
                    .map(renderAvailableColumn(true, selectedColumns.length))}
              </div>

              {/*<div className={s.column}>*/}
              {/*  {!!columns?.length &&*/}
              {/*    columns*/}
              {/*      .sort(sortByTitleOrField)*/}
              {/*      .slice(0, columns.length / 2)*/}
              {/*      .map(renderColumn)}*/}
              {/*</div>*/}
              {/*<div className={s.column}>*/}
              {/*  {!!columns?.length &&*/}
              {/*    columns*/}
              {/*      .sort(sortByTitleOrField)*/}
              {/*      .slice(columns.length / 2, columns.length)*/}
              {/*      .map(renderColumn)}*/}
              {/*</div>*/}
            </div>
            {/*<pre style={{ fontSize: 12 }}>*/}
            {/*  {JSON.stringify({ enabledColumns }, null, 2)}*/}
            {/*</pre>*/}
          </div>
        </FormModal>
      )}
    </>
  );
};
