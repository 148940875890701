import * as React from "react";
import s from "./InsightsModal.module.scss";
import FormModal from "../../FormModal/FormModal";
import InsightsColumn from "../InsightsColumn/InsightsColumn";
import { useParams } from "react-router-dom";
import { useInsightsByInstrument, useSnapshot } from "@iliotech/data-wire";
import Loader from "../../Loader/Loader";
import cn from "classnames";

interface IProps {
  instrumentId: string;
  visible: boolean;
  setVisible: (v: boolean) => void;
}

const InstrumentInsightsModal = ({
  instrumentId,
  visible,
  setVisible,
}: IProps) => {
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const snapshots = useSnapshot(portfolioId!);

  const name = React.useMemo(() => {
    const found = snapshots?.data?.data.find(
      (item) => item.instrumentId === instrumentId
    );
    if (found) {
      return `[${found.name}]`;
    } else return ``;
  }, [snapshots?.data?.data]);

  const insightsResponse = useInsightsByInstrument(portfolioId!, instrumentId);

  const insights = insightsResponse.data?.data.data.map((item) => ({
    label: item.title!,
    headlineInsight: item.highlight!,
    detailedInsight: item.detail!,
  }))!;

  return (
    <FormModal
      visible={visible}
      handleClose={(e) => {
        e?.stopPropagation?.();
        setVisible(false);
      }}
      closeOnBackdropClick
    >
      <div className={cn(s.columnContainer)}>
        {insightsResponse.isLoading ? (
          <Loader className={s.iconBig} />
        ) : (
          <InsightsColumn instrumentName={name} insights={insights as any} />
        )}
      </div>
    </FormModal>
  );
};

export default InstrumentInsightsModal;
