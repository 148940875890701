import React, { PropsWithChildren, useState } from "react";
import {
  usePerformance,
  usePortfolio,
  usePortfolioAllocation,
  useRecommendedBenchmarks,
  useSnapshot,
} from "../api";
import { useGlobalPeriod } from "./GlobalPeriodContext";
import {
  PortfolioPerformanceApi,
  RecommendedBenchmark,
} from "@iliotech/generated-api-v3/__generated/index";
import { API_BASE } from "../../constants/constants";
import { useQuery } from "react-query";
import {
  IBenchmarkItem,
  mapBenchmarkItems,
  mapPerformanceV3,
} from "@iliotech/component-library";
import { BENCHMARK_COLORS, PORTFOLIO_BENCHMARK_COLOR } from "../../utils";
import { uniqBy } from "lodash";
import { useStructureGrouping } from "../../main";

export const PerformanceContext =
  React.createContext<ReturnType<typeof usePerformanceHook> | undefined>(
    undefined
  );

interface IOwnProps {
  portfolioId: string;
}

export const PerformanceContextProvider = ({
  portfolioId,
  children,
}: PropsWithChildren<IOwnProps>) => {
  const value = usePerformanceHook(portfolioId);

  return (
    <PerformanceContext.Provider value={value}>
      {children}
    </PerformanceContext.Provider>
  );
};

const usePerformanceHook = (portfolioId: string) => {
  const { periodForRequest } = useGlobalPeriod();
  const allocations = usePortfolioAllocation(portfolioId!);
  const snapshots = useSnapshot(portfolioId);
  const portfolioRequest = usePortfolio(portfolioId!);
  const portfolioInfo = portfolioRequest?.data?.data;
  const getCustodianId = (label?: string) => {
    if (!label) {
      return undefined;
    }
    const whatFound = portfolioInfo?.custodians?.find(
      (item) => item.code === label
    );

    return whatFound?.id;
  };
  const { selectedGroup } = useStructureGrouping();
  const performance = usePerformance(
    portfolioId!,
    periodForRequest,
    undefined,
    getCustodianId(selectedGroup?.code)
  );

  const pnlPerformance = performance?.data?.data;
  const portfolioBenchmarkCode = performance?.data?.data?.benchmarkCode;

  const api = new PortfolioPerformanceApi(undefined, `${API_BASE}/api`);

  const from = periodForRequest?.from;
  const to = periodForRequest?.to;

  const [availableBenchmarks, setAvailableBenchmarks] = useState<
    RecommendedBenchmark[]
  >([]);
  const [selectedBenchmarks, setSelectedBenchmarks] = useState<string[]>([]);

  const defaultBenchmarks = useRecommendedBenchmarks(portfolioId!);
  const filteredDefaultBenchmarks = React.useMemo(() => {
    // filter out duplicate benchmarks
    return defaultBenchmarks?.data?.data?.recommendedBenchmarks.filter(
      (item) => item.ticker !== portfolioBenchmarkCode
    );
  }, [defaultBenchmarks, portfolioBenchmarkCode]);

  React.useEffect(() => {
    setAvailableBenchmarks(filteredDefaultBenchmarks || []);
  }, [JSON.stringify(filteredDefaultBenchmarks)]);

  React.useEffect(() => {
    if (portfolioBenchmarkCode && selectedBenchmarks.length === 0) {
      setSelectedBenchmarks([portfolioBenchmarkCode!]);
    }
  }, [selectedBenchmarks.length, portfolioBenchmarkCode]);

  const portfolioBenchmarks: IBenchmarkItem[] = React.useMemo(() => {
    if (!pnlPerformance?.name) return [];
    const injectDefaults = [
      {
        label: pnlPerformance?.name,
        value: pnlPerformance?.name,
        color: PORTFOLIO_BENCHMARK_COLOR,
        disabled: true,
        active: true,
      },
      {
        label: pnlPerformance?.benchmarkName,
        value: pnlPerformance?.benchmarkCode,
        color: BENCHMARK_COLORS[0],
        disabled: true,
        active: true,
      },
    ];
    if (!availableBenchmarks) {
      return injectDefaults;
    }
    const selectableBenchmarks = (availableBenchmarks || []).map(
      (item, index) => ({
        value: item.ticker,
        label: item.name,
        color: BENCHMARK_COLORS[index + 1],
      })
    );
    return uniqBy([...injectDefaults, ...selectableBenchmarks], (b) => b.label);
  }, [
    availableBenchmarks,
    pnlPerformance?.benchmarkName,
    pnlPerformance?.benchmarkCode,
    pnlPerformance?.name,
  ]);

  const updateBenchmarks = (newBenchmarks: RecommendedBenchmark[]) => {
    // we want to add the new optional benchmarks to the list
    // but we want to pre-select only the ones we just added
    // so we filter out the ones that are already in the list
    const actualNewBenchmarks = newBenchmarks.filter(
      (item) => !availableBenchmarks.find((i) => i.ticker === item.ticker)
    );
    setSelectedBenchmarks([
      ...selectedBenchmarks,
      ...actualNewBenchmarks.map((item) => item.ticker),
    ]);
    // here we can set the availableBenchmarks to all the ones that are selected in the modal
    setAvailableBenchmarks(newBenchmarks);
  };

  const query = useQuery(
    [
      `portfolio-performance-benchmarks`,
      { portfolioId, selectedBenchmarks, from, to },
    ],
    () =>
      api.getBenchmarkPerformance(
        portfolioId,
        new Set(selectedBenchmarks),
        from,
        to
      ),
    { enabled: !!selectedBenchmarks?.length && !!portfolioId }
  );

  const onBenchmarkItemDelete = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    itemId: string
  ) => {
    e.stopPropagation();

    if (selectedBenchmarks.includes(itemId)) {
      setSelectedBenchmarks((selectedItems) =>
        selectedItems.filter((item) => item !== itemId)
      );
    }
    setAvailableBenchmarks((prev) =>
      prev.filter((item) => item.ticker !== itemId)
    );
  };

  const toggleBenchmarkLineVisibility = (itemId: string) => {
    if (selectedBenchmarks.includes(itemId)) {
      setSelectedBenchmarks((prev) => prev.filter((item) => item !== itemId));
    } else {
      setSelectedBenchmarks((selectedItems) => [...selectedItems, itemId]);
    }
  };

  const riskAssetView = portfolioInfo?.riskAssetView;

  const isLoading = [snapshots, performance, portfolioRequest].some(
    (item) => item.isLoading
  );

  const benchmarkData = query.data?.data;

  const performanceLineChartData = React.useMemo(() => {
    const initialPnlPerformance = mapPerformanceV3(pnlPerformance);
    if (benchmarkData?.performanceBenchmarks && pnlPerformance) {
      const benchmarksToAdd = mapBenchmarkItems(
        benchmarkData?.performanceBenchmarks,
        portfolioBenchmarks
      );
      return [...initialPnlPerformance, ...benchmarksToAdd];
    }
    return [];
  }, [benchmarkData, portfolioBenchmarks, pnlPerformance]);

  return {
    portfolioController: {
      snapshots,
      portfolioInfo,
      riskAssetView,
      performanceLoading: isLoading,
      pnlPerformance,
      performanceLineChartData,
      allocations: allocations?.data?.data,
    },
    benchmarkController: {
      selectedBenchmarks,
      filteredDefaultBenchmarks,
      onBenchmarkItemDelete,
      toggleBenchmarkLineVisibility,
      updateBenchmarks,
      benchmarksLoading: query.isLoading,
      portfolioBenchmarks,
    },
  };
};

export const usePortfolioBenchmarkPerformance = () => {
  const context = React.useContext(PerformanceContext);
  if (typeof context === "undefined") {
    throw new Error(
      "PerformanceContext can only be accessed from within it's provider"
    );
  }
  return context;
};
