import { IChartDataItem } from "@iliotech/types";
import { ASSETS, ASSETS_LABELS } from "../../constants/assets";

export enum REGION_COLORS_ENUM {
  MIDDLE_EAST = "#E8BD8A", // new
  EUROPE = "#365F70", // new
  EASTERN_EUROPE = "#79D5FC",
  AFRICA = "#5B9FBD",
  PACIFIC = "#5164BD", // new
  LATAM = "#EE6961", // new
  CENTRAL_ASIA = "#3FAAA3",
  AUSTRALASIA = "#CEBCE5", // new
  SOUTH_ASIA = "#E68A99", // new
  NORTH_AMERICA = "#53B5FC", // new
  GREATER_CHINA = "#7CB9B5",
  SCANDINAVIA = "#37AB61",
  UNITED_KINGDOM = "#1C2F68",
  JAPAN = "#426FF5",
  WEST_ASIA = "#3152B5",
  NORTH_ASIA = "#756F14",
  GLOBAL = "#B5AA1F",
  ASIA = "#363309",
  ASIA_PACIFIC = "#6F4F98",
  ASIA_EX_JAPAN = "#412E59",
  EMERGING_MARKETS = "#A777E6",
  FRONTIER_MARKETS = "#F0B511",
  DEVELOPED_MARKETS = "#FF60BF",
}

export enum ALLOCATION_COLORS_ENUM {
  Alternatives = "#3FAAA4", // new
  CashAndEquivalents = "#5494B0", // new
  Adjustments = "#6D6DB1",
  Commodities = "#E68A99",
  FixedIncome = "#6F4F98", // new
  Equities = "#1C2F68", // new
  Funds = "#3FAAA3",
  RealEstate = "#F0B511", // new
  RealAssets = "#AA4A44", // new
  CryptoCurrencies = "#D67104", // new
  Futures = "#A7B9ED",
  MixedAllocation = "#ED7A34",
  Fx = "#D32697",
  Other = "#D0C9F8",
  Derivatives = "#756F14",
}
export const PORTFOLIO_BENCHMARK_COLOR = "#1C2F68";

export const BENCHMARK_COLORS = [
  "#3FAAA3",
  "#6F4F98",
  "#E68A99",
  "#AA4A44",
  "#F0B511",
];

export const CUSTODIAN_COLORS = [
  "#1C2F68",
  "#3FAAA3",
  "#6F4F98",
  "#E68A99",
  "#AA4A44",
  "#F0B511",
  "#5494B0",
  "#5CF7ED",
  "#CEBCE5",
  "#363309",
  "#D67104",
  "#AB5450",
  "#79D5FC",
  "#A777E6",
  "#3152B5",
  "#B5AA1F",
  "#37AB61",
  "#C5ECFC",
  "#5164BD",
  "#426FF5",
  "#365F70",
  "#7CB9B5",
];

export const ALLOCATION_COLORS: Record<string, ALLOCATION_COLORS_ENUM> = {
  [ASSETS.alternatives]: ALLOCATION_COLORS_ENUM.Alternatives,
  [ASSETS.cash]: ALLOCATION_COLORS_ENUM.CashAndEquivalents,
  [ASSETS.adjustments]: ALLOCATION_COLORS_ENUM.Adjustments,
  [ASSETS.fixedIncome]: ALLOCATION_COLORS_ENUM.FixedIncome,
  [ASSETS.equity]: ALLOCATION_COLORS_ENUM.Equities,
  [ASSETS.fund]: ALLOCATION_COLORS_ENUM.Funds,
  [ASSETS.realEstate]: ALLOCATION_COLORS_ENUM.RealEstate,
  [ASSETS.realAssets]: ALLOCATION_COLORS_ENUM.RealAssets,
  [ASSETS.cryptoCurrencies]: ALLOCATION_COLORS_ENUM.CryptoCurrencies,
  [ASSETS.commodities]: ALLOCATION_COLORS_ENUM.Commodities,
  [ASSETS.futures]: ALLOCATION_COLORS_ENUM.Futures,
  [ASSETS.mixed]: ALLOCATION_COLORS_ENUM.Futures,
  [ASSETS.fx]: ALLOCATION_COLORS_ENUM.Fx,
  [ASSETS.other]: ALLOCATION_COLORS_ENUM.Other,
  [ASSETS.derivatives]: ALLOCATION_COLORS_ENUM.Derivatives,
};

type AssetMapping = { name: string; color: string };
export const ASSET_MAPPINGS: { [code: string]: AssetMapping } = {
  adjustments: {
    name: "Adjustments",
    color: ALLOCATION_COLORS_ENUM.Adjustments,
  },
  alternatives: {
    name: "Alternatives",
    color: ALLOCATION_COLORS_ENUM.Alternatives,
  },
  cash: {
    name: "Cash and equivalents",
    color: ALLOCATION_COLORS_ENUM.CashAndEquivalents,
  },
  commodities: {
    name: "Commodities",
    color: ALLOCATION_COLORS_ENUM.Commodities,
  },
  fixedIncome: {
    name: "Fixed Income",
    color: ALLOCATION_COLORS_ENUM.FixedIncome,
  },
  equity: { name: "Equities", color: ALLOCATION_COLORS_ENUM.Equities },
  fund: { name: "Funds", color: ALLOCATION_COLORS_ENUM.Funds },
  realEstate: { name: "Real Estate", color: ALLOCATION_COLORS_ENUM.RealEstate },
  realAssets: { name: "Real Assets", color: ALLOCATION_COLORS_ENUM.RealAssets },
  cryptoCurrencies: {
    name: "Crypto Currencies",
    color: ALLOCATION_COLORS_ENUM.CryptoCurrencies,
  },
  subscriptionWithdrawal: {
    name: "Subscription and Withdrawal",
    color: ALLOCATION_COLORS_ENUM.CashAndEquivalents,
  },
  futures: { name: "Futures", color: ALLOCATION_COLORS_ENUM.Futures },
  mixed: { name: "Mixed Allocation", color: ALLOCATION_COLORS_ENUM.Futures },
  fx: { name: "Fx", color: ALLOCATION_COLORS_ENUM.Fx },
  other: { name: "Other", color: ALLOCATION_COLORS_ENUM.Other },
  derivatives: {
    name: "Derivatives",
    color: ALLOCATION_COLORS_ENUM.Derivatives,
  },
};

// Same as
export const ASSET_MAPPINGS_BY_NAME: {
  [name: string]: AssetMapping & { code: string };
} = Object.fromEntries(
  Object.entries(ASSET_MAPPINGS).map(([code, entry]) => [
    entry.name,
    { ...entry, code },
  ])
);

export const ALLOCATION_COLORS_LABELS: Record<string, ALLOCATION_COLORS_ENUM> =
  {
    [ASSETS_LABELS.alternatives]: ALLOCATION_COLORS_ENUM.Alternatives,
    [ASSETS_LABELS.Cash]: ALLOCATION_COLORS_ENUM.CashAndEquivalents,
    [ASSETS_LABELS.adjustments]: ALLOCATION_COLORS_ENUM.Adjustments,
    [ASSETS_LABELS.fixedIncome]: ALLOCATION_COLORS_ENUM.FixedIncome,
    [ASSETS_LABELS.equity]: ALLOCATION_COLORS_ENUM.Equities,
    [ASSETS_LABELS.fund]: ALLOCATION_COLORS_ENUM.Funds,
    [ASSETS_LABELS.realEstate]: ALLOCATION_COLORS_ENUM.RealEstate,
    [ASSETS_LABELS.realAssets]: ALLOCATION_COLORS_ENUM.RealAssets,
    [ASSETS_LABELS.cryptoCurrencies]: ALLOCATION_COLORS_ENUM.CryptoCurrencies,
    [ASSETS_LABELS.commodities]: ALLOCATION_COLORS_ENUM.Commodities,
    [ASSETS_LABELS.futures]: ALLOCATION_COLORS_ENUM.Futures,
    [ASSETS_LABELS.mixed]: ALLOCATION_COLORS_ENUM.Futures,
    [ASSETS_LABELS.fx]: ALLOCATION_COLORS_ENUM.Fx,
    [ASSETS_LABELS.other]: ALLOCATION_COLORS_ENUM.Other,
    [ASSETS_LABELS.derivatives]: ALLOCATION_COLORS_ENUM.Derivatives,
  };

export const INVESTMENT_VEHICLE_COLORS: Record<string, string> = {
  Physical: "#E68A99",
  RePhysical: "#E68A99",
  ComPhysical: "#E68A99",
  Option: "#756F14",
  EqOptions: "#756F14",
  Future: "#B5AA1F",
  Warrant: "#363309",
  EqWarrants: "#1C2F68",
  Bonds: "#6F4F98",
  FunFixedIncome: "#426FF5",
  FiBonds: "#6F4F98",
  Loan: "#412E59",
  MoneyMarket: "#CEBCE5",
  Mortgage: "#A777E6",
  Shares: "#1C2F68",
  EqShares: "#1C2F68",
  ETF: "#426FF5",
  FunETF: "#426FF5",
  PrefShares: "#3152B5",
  Fund: "#3FAAA3",
  HedgeFunds: "#286B67",
  PrivateEquity: "#5CF7ED",
  VentureCapital: "#AB5450",
  SpecialityFunds: "#7CB9B5",
  FundOfFunds: "#37AB61",
  CryptoCurrencies: "#D67104",
  Cash: "#5494B0",
  CshCash: "#5494B0",
  Adjustment: "#365F70",
  Fees: "#79D5FC",
  FX: "#5B9FBD",
  Subscription: "#5164BD",
  RealAssets: "#AA4A44",
  RealEstate: "#F0B511",
};

export const FEES_COLORS: Record<string, string> = {
  Commission: "#40C9A2",
  "Other Charges": "#F3C969",
  "Custodian Fee": "#E07BE0",
  Tax: "#EB5E55",
  Interest: "#05A8AA",
  "Misc Charge": "#56B877",
  "Management Fee": "#832161",
  "Performance Fee": "#593C8F",
  Total: "#A9B3D1",
};

export const SECTOR_COLORS: Record<string, string> = {
  LiquidAlternatives: "#E8BD8A",
  EmergingMktBonds: "#365F70",
  DevelopedMktBonds: "#79D5FC",
  HighYieldBonds: "#5B9FBD",
  Convertiblebonds: "#EE6961",
  GlobalBonds: "#3FAAA3",
  MoneyMarket: "#CEBCE5",
  Commodities: "#E68A99",
  CommPreciousMetals: "#53B5FC",
  EquityGeographicFocus: "#7CB9B5",
  MarketCapEquityFocus: "#37AB61",
  HealthCare: "#1C2F68",
  CommunicationServices: "#426FF5",
  ConsumerDiscretionary: "#3152B5",
  ConsumerStaples: "#756F14",
  Energy: "#B5AA1F",
  Financials: "#363309",
  Materials: "#6F4F98",
  Industrials: "#412E59",
  InformationTechnology: "#A777E6",
  RealEstate: "#FOB511",
  Utilities: "#FF60BE",
  EquityThemeFocus: "#C5ECFC",
  Other: "#A8E6AE",
  HedgeFundStrategies: "#286B67",
  MixedAsset: "#A8C4E6",
  Diverse: "#B9FEEC",
  CryptoCurrencies: "#D67104",
  RealAssets: "#AA4A44",
  CashAndEquivalents: "#5494B0",
  PrivateEquity: "#5CF7ED",
  VentureCapital: "#AB5450",
};

export function getBarColor(barData: IChartDataItem): string | undefined {
  if (barData.color) {
    return barData.color;
  }
  if (barData.id && barData.id in FEES_COLORS) {
    return FEES_COLORS[barData.id];
  }
  if (barData.id && barData.id in ALLOCATION_COLORS) {
    return ALLOCATION_COLORS[barData.id];
  }
  return "#fafafa" as string;
}

export const calculateValueColor = (v?: number) => {
  if (!v) {
    return "";
  }
  if (v < 40) {
    return "var(--red)";
  }
  if (v < 70) {
    return "var(--amber)";
  }
  return "var(--green)";
};

export const getColor = (v?: "R" | "A" | "G") => {
  switch (v) {
    case "R":
      return "var(--red)";
    case "A":
      return "var(--amber)";
    case "G":
      return "var(--green)";
    default:
      return "var(--gray)";
  }
};

export const calculateRankingString = (v?: number) => {
  if (!v) {
    return "";
  }
  if (v < 40) {
    return "Weak";
  }
  if (v < 70) {
    return "Fair";
  }
  return "Strong";
};

export const getRankingString = (v?: "R" | "A" | "G") => {
  switch (v) {
    case "R":
      return "Weak";
    case "A":
      return "Fair";
    case "G":
      return "Strong";
    default:
      return "No Data";
  }
};

export const positive = (v?: number) => typeof v !== "number" || v >= 0;

export const color = (v?: number) =>
  positive(v) ? "var(--green)" : "var(--red)";
const positiveVol = (v: number) => v < 66;

export const volColor = (v?: number) =>
  positiveVol(v!) ? "var(--green)" : "var(--red)";

export const statValue = (v?: number) =>
  typeof v === "undefined" ? "" : v > 66 ? "High" : v > 44 ? "Medium" : "Low";

export const esgColor = (v?: number) =>
  positiveVol(v!) ? "var(--green)" : "var(--red)";
export const esgValue = (v?: number) =>
  typeof v === "undefined" ? "" : v > 66 ? "High" : v > 44 ? "Medium" : "Low";

export const getVolatilityColor = (v?: number) =>
  !v
    ? "var(--border-light-color)"
    : v < 7
    ? "green"
    : v > 15
    ? "red"
    : "orange";

export const getVolatilityLabel = (v?: number) =>
  !v ? "No Data" : v < 7 ? "Low" : v > 15 ? "High" : "Medium";
