import * as React from "react";
import {
  ComponentType,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import * as XLSX from "xlsx";
import {
  CreatePositionRequest,
  EnhancedInstrumentSearchResult,
  EnhancedInstrumentSearchResultAssetSubClassEnum,
  ExchangeRateControllerV3Api,
  InstrumentControllerApi,
  InstrumentSearchResultV3,
  PortfolioCustodian,
  PortfolioDTOWithDetailsV3,
  PortfolioTradesApi,
  PositionUploadApi,
  PriceControllerV3Api,
  SourceId,
  TradeActionMessage,
  TradeImportBuySellEnum,
  TradeImportWithSearchResult,
} from "@iliotech/generated-api-v3";
import moment from "moment";
import { debounce } from "lodash";
import { usePortfolio } from "../api";
import { API_BASE } from "../../constants/constants";
import { useQueryClient } from "react-query";
import {
  getLastWeekDay,
  isScientificNotation,
  scientificNotationToString,
} from "../../utils";
import { getCellWarning } from "../../processing";

export interface IPositionTradeLocal {
  buySell?: string;
  ticker: string;
  index: number;
  name: string;
  code: string;
  quantity: number | string;
  price: number;
  fxRate: any;
  historicPrice?: number;
  custodian: string;
  assetClass: string;
  assetSubClass: string;
  currency: string;
  exchange: any;
  instrumentId: string;
  instrumentStatus: string;
  pointValue: number;
  isCurrency?: boolean;
  custodianResolved?: boolean;
  isCash?: boolean;
  priceError?: boolean;
  sourceId: {
    sourceData: string;
    sourceId: string;
  };
  searchResult?: EnhancedInstrumentSearchResult[];
  selected?: boolean;
}

interface IUploadTemplateData {
  template: string;
  rows: { [index: string]: Record<string, string> };
}

export const selectFromSearchResult = (
  index: number,
  rowData: IPositionTradeLocal
) => {
  const currInstrument = rowData.searchResult![index];
  const initialPrice =
    currInstrument.assetSubClass ===
    EnhancedInstrumentSearchResultAssetSubClassEnum.SubCash
      ? 1
      : (currInstrument.price as number);

  console.log("currInstrument", currInstrument);
  return {
    ...rowData,
    assetClass: currInstrument.assetClass,
    assetSubClass: currInstrument.assetSubClass,
    code: currInstrument.code,
    currency: currInstrument.currency,
    instrumentStatus: currInstrument.instrumentStatus,
    instrumentId: currInstrument.instrumentId,
    name: currInstrument.name,
    pointValue: currInstrument.pointValue,
    price: initialPrice,
    historicPrice: rowData.historicPrice || initialPrice,
    fxRate: currInstrument.fxRate,
    isCash:
      currInstrument.assetSubClass ===
      EnhancedInstrumentSearchResultAssetSubClassEnum.SubCash,
    selected: true,
    sourceId: {
      ...currInstrument.sourceId,
    },
  };
};

interface IPositionUploadContext {
  onDrop: (
    f: File,
    portfolioId: PortfolioDTOWithDetailsV3,
    callback: () => void
  ) => void;
  onAddInstrument: (
    instrument: InstrumentSearchResultV3 & {
      underlyingInstrumentSourceId?: SourceId;
      multiplier?: number;
      price?: number;
    }
  ) => void;
  loading: boolean;
  resetTableData: () => void;
  downloadInvalidEntries: () => void;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  tableData: IPositionTradeLocal[];
  resolveModal: {
    index: number;
    type: "POSITION" | "CUSTODIAN";
    noReplace?: boolean;
    ticker?: string;
  } | null;
  tradeTime: Date;
  setTradeTime: Dispatch<SetStateAction<Date>>;
  onSave: (successCallback: () => void) => void;
  enabledSave: boolean;
  fetchPortfolio: (id: string) => void;
  porfolioCustodians: PortfolioCustodian[] | undefined;
  onAddCustodian: (v: PortfolioCustodian) => void;
  cellRendererCallBack: (CellRenderer: any) => ComponentType<any> | undefined;
  setResolveModal: Dispatch<
    SetStateAction<{
      index: number;
      type: "POSITION" | "CUSTODIAN";
      noReplace?: boolean;
      ticker?: string;
    } | null>
  >;
}

const noop = () => {
  console.log("GlobalPeriodContext not yet initialised");
};

const PositionUploadContext = React.createContext<IPositionUploadContext>({
  onDrop: noop,
  resetTableData: noop,
  loading: false,
  downloadInvalidEntries: noop,
  errorMessage: "",
  setErrorMessage: noop,
  tableData: [],
  onAddCustodian: noop,
  cellRendererCallBack: () => [] as any,
  porfolioCustodians: [],
  resolveModal: null,
  setResolveModal: noop,
  tradeTime: new Date(),
  setTradeTime: noop,
  enabledSave: false,
  onSave: noop,
  fetchPortfolio: noop,
  onAddInstrument: noop,
});

export const PositionUploadProvider = ({ children }: PropsWithChildren<{}>) => {
  const [tableData, setTableData] = React.useState<IPositionTradeLocal[]>([]);
  const [initialTableData, setTableDataInitial] = React.useState<
    IPositionTradeLocal[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [portfolioId, setPortfolioId] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [unparsedData, setUnparsedData] =
    React.useState<TradeImportWithSearchResult[]>();
  const [resolveModal, setResolveModal] =
    React.useState<{
      index: number;
      type: "POSITION" | "CUSTODIAN";
      noReplace?: boolean;
    } | null>(null);
  const [rowDataStack, setRowDataStack] = React.useState<IPositionTradeLocal[]>(
    []
  );
  const [tradeTime, setTradeTime] = useState(getLastWeekDay(new Date()));
  const [tempCustodians, setTempCustodians] = useState<PortfolioCustodian[]>(
    []
  );
  const portfolioRequest = usePortfolio(portfolioId, { enabled: false });
  const portfolioCustodians = React.useMemo(
    () => [
      ...(portfolioRequest.data?.data.custodians || []),
      ...tempCustodians,
    ],
    [portfolioRequest, tempCustodians]
  );

  const client = useQueryClient();

  const refetchTradesDelayed = () => {
    return client.refetchQueries({
      predicate: (query) => {
        return (
          Array.isArray(query.queryKey) &&
          (query.queryKey[0] === "portfolio-trades" ||
            query.queryKey[0] === "trades-light-api")
        );
      },
    });
  };

  const tradeDate = React.useMemo(
    () => moment(tradeTime).format("YYYY-MM-DD"),
    [tradeTime]
  );
  const fetchPortfolio = (id: string) => {
    setPortfolioId(id);
  };
  useEffect(() => {
    if (portfolioId) {
      portfolioRequest.refetch();
    }
  }, [portfolioId]);

  const tableDataRef = React.useRef<any>(null);

  useEffect(() => {
    const instrumentFetchingTimeout = setInterval(() => {
      getOrCreateAllInstruments();
    }, 5000);
    return () => {
      clearInterval(instrumentFetchingTimeout);
    };
  }, []);

  useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);

  useEffect(() => {
    updatePrices();
  }, [tradeTime]);

  const updatePrices = async () => {
    setLoading(true);
    const newData = await Promise.all(
      tableData.map(async (item) => {
        if (!item.sourceId?.sourceId) {
          return item;
        }
        try {
          let price = item.price;
          if (item?.sourceId?.sourceData !== "OptionsService") {
            price = (
              await getPrice(item.sourceId?.sourceId, item.sourceId?.sourceData)
            ).data;
          }
          const fxRate = await getFxRate(
            item.currency,
            portfolioRequest.data?.data!.currencyCode!
          );

          return {
            ...item,
            price: price,
            fxRate: fxRate.data.fromToFxRate,
            priceError: false,
          };
        } catch (e) {
          return {
            ...item,
            priceError: true,
          };
        }
      })
    );
    setLoading(false);
    updateTableData(newData);
  };

  // REQUESTS
  const getOrCreate = async (sourceId: string, sourceData: string) => {
    const api = new InstrumentControllerApi(undefined, `${API_BASE}/api`);
    return api.getOrCreateInstrumentBySourceId1(sourceId, sourceData as any);
  };

  // REQUESTS
  const getFxRate = async (from: string, to: string) => {
    const api = new ExchangeRateControllerV3Api(undefined, `${API_BASE}/api`);

    return api.get3(from as any, to as any, tradeDate);
  };

  // REQUESTS :update original price
  const getPrice = (sourceId: string, sourceData: string) => {
    const api = new PriceControllerV3Api(undefined, `${API_BASE}/api`);

    return api.get1(sourceId, sourceData as any, tradeDate);
  };

  const onDrop = (
    f: File,
    portfolio: PortfolioDTOWithDetailsV3,
    callBack: () => void
  ) => {
    setLoading(true);

    const reader = new FileReader();
    reader.readAsArrayBuffer(f);
    reader.onload = (e) => {
      if (!e.target?.result) {
        return;
      }
      // @ts-ignore - incorrect types for FileReader.onload
      const data = new Uint8Array(e.target!.result);
      const wb = XLSX.read(data, { type: "array" });
      if (wb.SheetNames) {
        sendRawData(
          wb.Sheets[wb.SheetNames[0]],
          portfolio.id,
          portfolio.custodians
        ).then(callBack);
      }
    };
  };

  // HELPER to update table data and setting the index for row color
  const updateTableData = (data: IPositionTradeLocal[]) => {
    setTableData(data.map((d, i) => ({ ...d, index: i })));
  };

  const getOrCreateAllInstruments = async () => {
    const instrumentsToGetOrCreate = tableDataRef.current.filter(
      (instrument: IPositionTradeLocal) =>
        instrument.instrumentStatus === "DRAFT"
    );
    instrumentsToGetOrCreate.forEach((item: IPositionTradeLocal) => {
      getOrCreate(item.sourceId.sourceId, item.sourceId.sourceData).then(
        (status) => {
          if (status.data.status !== "DRAFT") {
            // try to use previous instead of ref
            updateTableData([
              ...tableDataRef.current.filter(
                (subItem: IPositionTradeLocal) =>
                  subItem.sourceId?.sourceId !== item.sourceId.sourceId
              ),
              {
                ...tableDataRef.current.find(
                  (subItem: IPositionTradeLocal) =>
                    subItem.sourceId?.sourceId === item.sourceId.sourceId
                ),
                instrumentStatus: status.data.status,
              },
            ]);
          }
        }
      );
    });
  };

  // Function to batch updates rows
  const updateRowsBatch = (rowDataStackLocal: IPositionTradeLocal[]) => {
    const newTableData = tableDataRef.current.map(
      (row: IPositionTradeLocal, index: number) => {
        const found = rowDataStackLocal.find((item) => item.index === index);
        if (found) {
          return { ...found, index };
        }
        return row;
      }
    );
    setTableData(newTableData);
    setRowDataStack([]);
  };

  const debouncedUpdate = React.useCallback(
    debounce((rowDataStackLocal: IPositionTradeLocal[]) => {
      updateRowsBatch(rowDataStackLocal);
    }, 200),
    []
  );

  useEffect(() => {
    if (rowDataStack.length) {
      debouncedUpdate(rowDataStack);
    }
  }, [rowDataStack]);
  // Function to add rowData to the update stack
  const addToUpdateStack = async (rowData: IPositionTradeLocal) => {
    setRowDataStack((curr) => [...curr, rowData]);
  };

  // FUNCTION used to update a row, update quantity, price or selectedAsset
  const updateRowData = async (
    rowData: IPositionTradeLocal,
    newData: IPositionTradeLocal
  ) => {
    const newTableData = tableDataRef.current.map((item: IPositionTradeLocal) =>
      item.index === rowData.index ? newData : item
    );
    updateTableData(newTableData);
  };

  const removeRow = (rowData: IPositionTradeLocal) => {
    updateTableData(
      tableDataRef.current.filter((data: any) => data.index !== rowData.index)
    );
  };

  const getWarningText = (selectedInstrument: IPositionTradeLocal) => {
    let icon: "OK" | "WARNING" | "ERROR" = "OK";
    let text = "";

    if (
      selectedInstrument.searchResult?.length &&
      !selectedInstrument.selected
    ) {
      icon = "WARNING";
      text =
        "More than one instrument option were found, select instrument option to resolve.";
    }
    if (!selectedInstrument.name) {
      icon = "ERROR";
      text =
        "The instrument could not be found. Please resolve this instrument.";
    }
    if (
      !selectedInstrument.custodian ||
      (!(portfolioCustodians || []).some(
        (item) => item.code === selectedInstrument.custodian
      ) &&
        !selectedInstrument.custodianResolved)
    ) {
      icon = "ERROR";
      text = "The custodian could not be found. Please resolve custodian.";
    }
    if (selectedInstrument?.instrumentStatus === "DRAFT") {
      icon = "WARNING";
      text = "Instrument status is not ready, please wait for completion.";
    }
    if (selectedInstrument?.instrumentStatus === "ERROR") {
      icon = "ERROR";
      text = "Error loading the instrument. Please remove it";
    }
    if (
      selectedInstrument?.quantity === "" ||
      selectedInstrument?.quantity === 0
    ) {
      icon = "ERROR";
      text = "Quantity field is mandatory";
    }
    if (isScientificNotation(Number(selectedInstrument?.quantity))) {
      icon = "ERROR";
      text = "Quantity field is too large or too small";
    }
    if (selectedInstrument.priceError) {
      icon = "ERROR";
      text =
        "Error fetching instrument price. The instrument might not be available on the selected date.";
    }
    return { text, icon };
  };

  const cellRendererCallBack = (CellRenderer: any) =>
    CellRenderer({
      updateRowData,
      removeRow,
      addToUpdateStack,
      setResolveModal,
      portfolioCustodians,
      getWarningText,
    });

  const downloadInvalidEntries = () => {
    const dictionary = {
      A: "Ticker",
      B: "Quantity",
      C: "Price",
      D: "Custodian",
      error: "error",
    };
    const toParse: any[] = [];
    Object.values(unparsedData!).forEach((row) => {
      const toRet = {};
      Object.keys(dictionary).forEach((key) => {
        // @ts-ignore
        toRet[dictionary[key]] = row[key] || "";
      });

      toParse.push(toRet);
    });
    const newWorkbook = XLSX.utils.book_new();
    const worksheetTrades = XLSX.utils.json_to_sheet(toParse);
    XLSX.utils.book_append_sheet(newWorkbook, worksheetTrades, "Transactions");

    XLSX.writeFile(
      newWorkbook,
      `_Errored_Transactions_${moment().format("DD-MMM-YYYY")}.xlsx`
    );
  };

  const parseUpload = (
    data: TradeImportWithSearchResult[],
    custodians: PortfolioCustodian[]
  ) => {
    const parsedData = data.map((item) => {
      let parsedItem: Partial<IPositionTradeLocal>;
      const quantitySign =
        item.tradeImport.buySell === TradeImportBuySellEnum.Buy ? 1 : -1;
      const quantity = isScientificNotation(item.tradeImport.quantity)
        ? scientificNotationToString(item.tradeImport.quantity)
        : item.tradeImport.quantity * quantitySign;

      if (item.searchResult?.length === 1) {
        parsedItem = selectFromSearchResult(0, {
          ...item.tradeImport,
          quantity,
          fxRate: item.searchResult[0].fxRate,
          searchResult: item.searchResult,
          historicPrice: item.tradeImport.price,
        } as any);
      } else {
        parsedItem = {
          ...item.tradeImport,
          quantity: quantity,
          historicPrice: item.tradeImport.price,
          searchResult: item.searchResult,
        };
      }

      const foundCustodian = (custodians || []).find((item) => {
        const code = item?.code || "";
        const currentCustodian = parsedItem?.custodian || "";
        return code.toLowerCase() === currentCustodian.toLowerCase();
      });

      if (foundCustodian) {
        parsedItem = {
          ...parsedItem,
          custodian: foundCustodian.code,
          custodianResolved: true,
        };
      }

      return parsedItem;
    }) as any;

    updateTableData(parsedData);
    setTableDataInitial(parsedData);
  };

  const resetTableData = () => {
    updateTableData(initialTableData);
  };

  /** Send data from the selected sheet to the server **/
  const sendRawData = async (
    sheet: XLSX.WorkSheet,
    portfolioId: string,
    custodians: PortfolioCustodian[]
  ) => {
    if (!sheet) {
      return;
    }
    const cleanSheet = { ...sheet };
    delete cleanSheet["!ref"];

    const rawData: IUploadTemplateData["rows"] = {};

    Object.entries(cleanSheet).forEach(([key, value]) => {
      const row = key.match(/([0-9])+/g)?.[0];
      if (row) {
        const cell = key.substr(0, key.length - row.length);
        if (!rawData[row]) {
          rawData[row] = {};
        }
        rawData[row][cell] = value.w ?? value.v;
      }
    });

    const api = new PositionUploadApi(undefined, `${API_BASE}/api`);
    try {
      const data = await api.uploadPosition(
        {
          template: "IllioInHousePosition",
          rows: rawData,
          portfolioId,
          tradeDate,
        },
        portfolioId
      );

      const unparsedRows = data.data?.unparsedRows;
      const parsedRows = data.data?.parsedRows;

      if (unparsedRows) {
        setUnparsedData(unparsedRows as any);
        if (Object.values(unparsedRows).length > 0) {
          setErrorMessage(
            Object.values(unparsedRows).length +
              ` rows in the file have invalid values. \n Rather than try to guess what they should be we've prepared them to download into a separate file.`
          );
        }
      }

      if (parsedRows) {
        parseUpload(parsedRows as any, custodians);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onAddCustodian = (custodian: PortfolioCustodian) => {
    // we check if it is portfolioCustodian
    const isPortfolioCustodian = portfolioCustodians.find(
      (item) => item.code === custodian.code
    );
    // we check if it is part of tempCustodians
    const foundCustodian = tempCustodians.find(
      (item) => item.code === custodian.code
    );
    // if is not portfolioCustodian nor tempCustodian we add it to tempCustodians
    if (!isPortfolioCustodian && !foundCustodian) {
      setTempCustodians([...tempCustodians, custodian]);
    }
    // we check if we have to skip updating all the custodians with the same name
    const skipUpdate = resolveModal?.noReplace;
    setResolveModal(null);
    // we get the current custodian of the current row
    const currentCustodian =
      tableDataRef.current[resolveModal!.index].custodian;
    // we get the data to update, if skipUpdate is true we only update the current row otherwise all the rows with the same custodian
    const dataToUpdate = skipUpdate
      ? [tableDataRef.current[resolveModal!.index]]
      : tableDataRef.current.filter(
          (item: IPositionTradeLocal) => item.custodian === currentCustodian
        );
    // we map through the data to update and update the custodian
    const newData = dataToUpdate.map((item: IPositionTradeLocal) => ({
      ...item,
      custodianResolved: true,
      custodian: custodian.code,
    }));
    // we update the rows
    updateRowsBatch(newData);
  };

  const onAddInstrument = async (
    item: InstrumentSearchResultV3 & {
      underlyingInstrumentSourceId?: SourceId;
      multiplier?: number;
      price?: number;
      isCash?: boolean;
    }
  ) => {
    setLoading(true);
    const isOption = item?.multiplier;
    const sourceIdForPrice =
      item?.underlyingInstrumentSourceId || item.instrumentSourceId;
    const { sourceId, sourceData } = item.instrumentSourceId;
    try {
      const price = item.isCash
        ? 1
        : isOption
        ? item.price
        : (await getPrice(sourceId, sourceData))?.data;

      const fxRate = await getFxRate(
        (item as any).currencyCode!,
        portfolioRequest.data?.data?.currencyCode!
      );

      const status = await getOrCreate(
        sourceIdForPrice.sourceId,
        sourceIdForPrice.sourceData
      );
      const currentRow = tableDataRef.current[resolveModal!.index];
      const rowData = {
        ...item,
        custodian: currentRow.custodian,
        custodianResolved: currentRow.custodianResolved,
        quantity: currentRow.quantity,
        currency: (item as any).currencyCode,
        price: price,
        ticker: item.code,
        sourceId: {
          sourceId,
          sourceData,
        },
        historicPrice: price,
        instrumentStatus: status?.data?.status || "ERROR",
        instrumentId: status?.data?.id,
        fxRate: fxRate.data?.fromToFxRate,
        multiplier: item.multiplier,
      } as any;
      updateRowData(currentRow, rowData);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onSave = async (
    successCallback?: (data: TradeActionMessage[]) => void
  ) => {
    const positionsPayload: CreatePositionRequest[] = tableData
      .filter((item) => getCellWarning(item).icon === "OK")
      .map((item) => ({
        sourceId: item?.sourceId as SourceId,
        tradeTime: tradeDate,
        buySell:
          (item?.quantity || 0) < 0
            ? item.isCash
              ? "WITHDRAW"
              : "SELL"
            : item.buySell || ((item.isCash ? "ADD" : "BUY") as any),
        quantity: Math.abs(Number(item.quantity)),
        custodianCode: item.custodian,
        currency:
          portfolioRequest.data?.data?.currencyCode || (undefined as any),
        // set price to historic price to reflect changes on manual entries
        price: item.isCash ? 1 : item.historicPrice,
        fxRate: item?.fxRate,
        historicPrice: item.historicPrice!,
      }));

    setLoading(true);
    const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);

    try {
      const result = await api.addPortfolioPositions(
        positionsPayload,
        portfolioRequest?.data?.data?.id!
      );
      await refetchTradesDelayed();
      successCallback?.(result.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const enabledSave = !(tableData || []).some(
    (item: IPositionTradeLocal) => getWarningText(item).text.length > 0
  );

  return (
    <PositionUploadContext.Provider
      value={{
        onDrop,
        loading,
        downloadInvalidEntries,
        errorMessage,
        setErrorMessage,
        tableData,
        resolveModal,
        cellRendererCallBack,
        tradeTime,
        setTradeTime,
        onSave,
        enabledSave,
        fetchPortfolio,
        setResolveModal,
        porfolioCustodians: portfolioCustodians,
        onAddCustodian,
        resetTableData,
        onAddInstrument,
      }}
    >
      {children}
    </PositionUploadContext.Provider>
  );
};

export const usePositionUpload = () => React.useContext(PositionUploadContext);
