import * as React from "react";
import s from "./CreatePortfolioStepper.module.scss";
import cn from "classnames";
import { StepTracker } from "../../";
import { RoundButton } from "../../";

interface IProps {
  onBack: () => void;
  onNext: (ev: any) => void;
  currentStep?: number;
}

const TOTAL_STEPS = 2;
export const CreatePortfolioStepper = ({
  onBack,
  onNext,
  currentStep = 1,
}: IProps) => {
  return (
    <div className={s.container}>
      <StepTracker currentStep={currentStep} totalSteps={TOTAL_STEPS} />
      <div className={s.buttonsContainer}>
        <RoundButton
          onClick={onBack}
          fillMode={"outline"}
          className={cn(s.white, s.button)}
        >
          Back
        </RoundButton>
        <RoundButton onClick={onNext} className={s.button}>
          {currentStep === TOTAL_STEPS ? "Finish" : "Continue"}
        </RoundButton>
      </div>
    </div>
  );
};

export default CreatePortfolioStepper;
