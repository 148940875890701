import * as React from "react";
import { useWindowSize } from "react-use";
import { BREAKPOINTS } from "../../constants/constants";

export const useSmallScreen = () => {
  const [smallScreen, setSmallScreen] = React.useState(false);

  const measurements = useWindowSize();

  React.useEffect(() => {
    setSmallScreen(measurements.width < BREAKPOINTS.SMALL);
  }, [measurements?.width]);

  return smallScreen;
};

export default useSmallScreen;
