/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AccountCapability } from './account-capability';
import { AccountLimit } from './account-limit';
import { AccountMetadata } from './account-metadata';
import { AccountPreferences } from './account-preferences';
import { EBundleNameDto } from './ebundle-name-dto';
import { PairLongString } from './pair-long-string';
import { UserLimits } from './user-limits';

/**
 * 
 * @export
 * @interface AccountUserCapabilitiesDTO
 */
export interface AccountUserCapabilitiesDTO {
    /**
     * 
     * @type {Array<PairLongString>}
     * @memberof AccountUserCapabilitiesDTO
     */
    'accounts': Array<PairLongString>;
    /**
     * 
     * @type {Set<AccountCapability>}
     * @memberof AccountUserCapabilitiesDTO
     */
    'capabilities': Set<AccountCapability>;
    /**
     * 
     * @type {AccountLimit}
     * @memberof AccountUserCapabilitiesDTO
     */
    'accountLimits': AccountLimit;
    /**
     * 
     * @type {UserLimits}
     * @memberof AccountUserCapabilitiesDTO
     */
    'userLimits': UserLimits;
    /**
     * 
     * @type {Set<string>}
     * @memberof AccountUserCapabilitiesDTO
     */
    'actions': Set<AccountUserCapabilitiesDTOActionsEnum>;
    /**
     * 
     * @type {AccountPreferences}
     * @memberof AccountUserCapabilitiesDTO
     */
    'preferences': AccountPreferences;
    /**
     * 
     * @type {AccountMetadata}
     * @memberof AccountUserCapabilitiesDTO
     */
    'metadata': AccountMetadata;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountUserCapabilitiesDTO
     */
    'allowedViews': Array<AccountUserCapabilitiesDTOAllowedViewsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof AccountUserCapabilitiesDTO
     */
    'screenMask': Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof AccountUserCapabilitiesDTO
     */
    'allowedConnections': Set<string>;
    /**
     * 
     * @type {Set<EBundleNameDto>}
     * @memberof AccountUserCapabilitiesDTO
     */
    'userPermissionsAssigned'?: Set<EBundleNameDto>;
    /**
     * 
     * @type {Set<EBundleNameDto>}
     * @memberof AccountUserCapabilitiesDTO
     */
    'userPermissionsNotAssigned'?: Set<EBundleNameDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountUserCapabilitiesDTOActionsEnum {
    EditPreferences = 'EDIT_PREFERENCES',
    AddPortfolio = 'ADD_PORTFOLIO',
    SharePortfolio = 'SHARE_PORTFOLIO',
    DeletePortfolio = 'DELETE_PORTFOLIO',
    TransferPortfolio = 'TRANSFER_PORTFOLIO',
    AddTrade = 'ADD_TRADE',
    EditTrade = 'EDIT_TRADE',
    AddRealasset = 'ADD_REALASSET',
    AddOwner = 'ADD_OWNER',
    AddManager = 'ADD_MANAGER',
    AddCustomer = 'ADD_CUSTOMER',
    ListUsers = 'LIST_USERS',
    EditUser = 'EDIT_USER',
    ListAccounts = 'LIST_ACCOUNTS',
    ManageOtcInstrument = 'MANAGE_OTC_INSTRUMENT',
    ManageOtcUser = 'MANAGE_OTC_USER',
    ViewRisk = 'VIEW_RISK',
    ViewScenarios = 'VIEW_SCENARIOS',
    ExportTransactions = 'EXPORT_TRANSACTIONS',
    AssignWorkgroup = 'ASSIGN_WORKGROUP',
    ViewPortfolio = 'VIEW_PORTFOLIO',
    ManageAccount = 'MANAGE_ACCOUNT',
    AddBrokerConnection = 'ADD_BROKER_CONNECTION',
    AddWhatif = 'ADD_WHATIF',
    SaveWhatifComparison = 'SAVE_WHATIF_COMPARISON'
}
/**
    * @export
    * @enum {string}
    */
export enum AccountUserCapabilitiesDTOAllowedViewsEnum {
    Enterprise = 'enterprise',
    Client = 'client',
    D2c = 'd2c',
    Crypto = 'crypto',
    ListWorkgroups = 'listWorkgroups'
}


