/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface WorkgroupCreateDto
 */
export interface WorkgroupCreateDto {
    /**
     * 
     * @type {string}
     * @memberof WorkgroupCreateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkgroupCreateDto
     */
    'distribution': WorkgroupCreateDtoDistributionEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkgroupCreateDto
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkgroupCreateDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkgroupCreateDto
     */
    'subheading'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkgroupCreateDto
     */
    'category'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkgroupCreateDto
     */
    'published'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkgroupCreateDto
     */
    'imageUrl'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkgroupCreateDtoDistributionEnum {
    Client = 'CLIENT',
    Internal = 'INTERNAL',
    Global = 'GLOBAL',
    Model = 'MODEL'
}


