import { AccountManagementApi } from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";

export const useAccountCapabilities = (externalAccountId: string) => {
  const api = new AccountManagementApi(undefined, `${API_BASE}/api`);
  return useQuery(
    [`account-capabilities`, { externalAccountId }],
    () => api.accountUserCapabilities(externalAccountId),
    { enabled: !!externalAccountId }
  );
};
