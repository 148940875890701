import { useQuery } from "react-query";
import { PortfolioReportsApi } from "@iliotech/generated-api-v3";
import {API_BASE} from "../../constants/constants";

export const useIncome = (portfolioId: string) => {
  const api = new PortfolioReportsApi(undefined, `${API_BASE}/api`);
  return useQuery([`portfolio-income`, {portfolioId}], () =>
    api.getPortfolioIncome1(portfolioId)
  );
};
