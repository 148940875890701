import { createContext, useContext } from "react";
import { WorkgroupListStore } from "./WorkgroupListStore";

export const workgroupListStore = WorkgroupListStore();
export const WorkgroupStoreContext = createContext(workgroupListStore);
export const WorkgroupStoreContextProvider = WorkgroupStoreContext.Provider;

export const useWorkgroupList = () => {
  return useContext(WorkgroupStoreContext) as typeof workgroupListStore;
};

export default workgroupListStore;
