import { IColumn } from "../../../IllioTable/types";
import {
  EnumDisplay,
  PortfolioPositionsFixedIncomeResponse,
} from "@iliotech/generated-api-v3";
import { IllioTable } from "../../../IllioTable/IllioTable";
import React from "react";

export const FUNDAMENTALS_COLUMNS: (
  Country: Record<string, EnumDisplay> | undefined
) => IColumn<PortfolioPositionsFixedIncomeResponse["fundamentals"][number]>[] =
  (Country) => [
    {
      field: "name",
      isDefault: true,
      locked: true,
      order: 1,
      renderer: IllioTable.TextCell({ checkIsTotal: (row) => row.isTotal }),
      mandatory: true,
      group: "",
    },
    {
      field: "quantity",
      isDefault: true,
      order: 10,
      renderer: IllioTable.DecimalCell({
        checkIsTotal: (row) => row.isTotal,
        maxDecimals: 0,
        forceDecimals: true,
      }),
      group: "Trade Info",
    },
    {
      field: "code",
      order: 20,
      title: "Ticker",
      renderer: IllioTable.TextCell({ checkIsTotal: (row) => row.isTotal }),
      group: "Descriptive",
    },
    {
      field: "currency",
      isDefault: true,
      renderer: IllioTable.TextCell({ checkIsTotal: (row) => row.isTotal }),
      order: 70,
      group: "Descriptive",
    },
    {
      field: "lastPrice",
      title: "Current Price %",
      renderer: IllioTable.DecimalCell({
        checkIsTotal: (row) => row.isTotal,
        maxDecimals: 2,
        forceDecimals: true,
      }),
      order: 160,
      isDefault: true,
      group: "Value",
    },

    {
      field: "couponRate",
      title: "Coupon Rate",
      renderer: IllioTable.DecimalCell({
        checkIsTotal: (row) => row.isTotal,
        maxDecimals: 3,
        forceDecimals: true,
      }),
      order: 500,
      isDefault: true,
      group: "Bond",
    },

    // Removing issue date per ticket https://illio.atlassian.net/browse/ILLDV001-7038 as at 2023-02-08
    // {
    //   field: "issueDate",
    //   title: "Issue Date",
    //   renderer: IllioTable.DateCell(),
    //   order: 520,
    //   group: "Bond",
    // },
    {
      field: "maturityDate",
      title: "Maturity Date",
      renderer: IllioTable.DateCell(),
      order: 510,
      isDefault: true,
      group: "Bond",
    },
    {
      field: "issueDate",
      title: "Issue Date",
      renderer: IllioTable.DateCell(),
      order: 520,
      isDefault: false,
      group: "Bond",
    },
    {
      field: "nextEventDate",
      title: "First Event",
      renderer: IllioTable.DateCell(),
      order: 600,
      isDefault: true,
      group: "Bond",
    },

    {
      field: "nextEventPrice",
      title: "First Event Price",
      renderer: IllioTable.NumberCell({
        greenForPositive: true,
        maxDecimals: 2,
        minDecimals: 2,
      }),
      order: 610,
      isDefault: true,
      group: "Bond",
    },

    {
      field: "nextCouponDate",
      title: "Next Coupon",
      renderer: IllioTable.DateCell(),
      order: 580,
      isDefault: true,
      group: "Bond",
    },

    {
      field: "nextCouponAmountLocal",
      title: "Next Coupon Amount",
      renderer: IllioTable.NumberCell({ greenForPositive: true }),
      order: 590,
      isDefault: true,
      group: "Bond",
    },

    {
      field: "durationInYears",
      title: "Duration (yrs)",
      renderer: IllioTable.DecimalCell({ maxDecimals: 2, forceDecimals: true }),
      order: 550,
      group: "Bond",
      isDefault: true,
    },
    {
      field: "yieldToFirst",
      title: "Yield to First",
      renderer: IllioTable.PercentCell({
        greenForPositive: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      order: 540,
      group: "Bond",
      isDefault: false,
    },
    {
      field: "yieldToWorse",
      title: "Yield to Worst",
      renderer: IllioTable.PercentCell({
        greenForPositive: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      order: 540,
      group: "Bond",
      isDefault: true,
    },
    {
      field: "yieldToMaturity",
      title: "Yield to Maturity",
      renderer: IllioTable.PercentCell({
        greenForPositive: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      order: 530,
      group: "Bond",
      isDefault: false,
    },

    // {
    //   field: "nextCallDate",
    //   title: "Next Coupon Date",
    //   renderer: IllioTable.DateCell(),
    //   order: 30,
    // },
    //
    // {
    //   field: "nextCallPrice",
    //   title: "Next Coupon Amount",
    //   renderer: IllioTable.DecimalCell(),
    //   order: 32,
    // },
    {
      field: "redemption",
      title: "Redemption %",
      renderer: IllioTable.PercentCell({
        greenForPositive: false,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      order: 560,
      isDefault: false,
      group: "Bond",
    },

    {
      field: "countryOfRisk",
      title: "Country of Risk",
      order: 80,
      group: "Descriptive",
      renderer: ({ dataItem }) => {
        // console.log({val: Country?.[cou], Country})
        return (
          <td>
            <div>
              {Country?.[dataItem.countryOfRisk]?.label ||
                dataItem.countryOfRisk}
            </div>
          </td>
        );
      },
    },

    // {
    //   field: "industry",
    //   order: 40,
    // },
    {
      field: "couponFrequency",
      title: "Coupon Frequency",
      order: 570,
      isDefault: true,
      group: "Bond",
    },
    // {
    //   field: "liability",
    //
    // }
  ];
