import React from 'react';
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useAuth, usePortfolioList} from "@iliotech/data-wire";
import {PortfolioGrid, SearchablePortfolioList} from "../../PortfolioList";
import {Modal} from "../../Modal";
import {useTranslation} from "react-i18next";

interface IOwnProps {

}

export const IBPreparingPortfolio = (props: IOwnProps) => {
  const {user} = useAuthenticator(({user}) => [user]);
  const email = user?.attributes?.email;
  const {illioSelectedAccount} = useAuth();
  const [showPopulationWarning, setShowPopulationWarning] = React.useState(true);
  const {t} = useTranslation();
  
  const portfolios = usePortfolioList(illioSelectedAccount?.externalAccountId!);
  const samplePortfolios = usePortfolioList(
    illioSelectedAccount?.externalAccountId!,
    "GLOBAL"
  );
  
  const {refetch} = portfolios;
  
  React.useEffect(() => {
    void refetch().catch();
  }, [refetch])
  
  const emailAddress = !!email ? ` at ${email}` : "";
  
  return (
    <div className={"card"}>
      <SearchablePortfolioList />
      <PortfolioGrid portfolios={samplePortfolios.data?.data} loading={samplePortfolios.isLoading}/>
        <Modal
          show={showPopulationWarning}
          onClose={() => setShowPopulationWarning(false)}
          title={"Your portfolio is being populated"}
          footer={(
            <button onClick={() => setShowPopulationWarning(false)}>OK</button>
          )}
        >
          <p>
            We're fetching your data from Interactive Brokers. This process can take up to 1 hour if within business hours
            (if outside of business hours this will be ready within 1 hour of the start of the next business hours). <br/><br/>You
            will receive an email{emailAddress} from us as soon as your portfolio is ready. While you wait, you can
            either manually add any other investments you have to your portfolio or you can familiarise yourself with illio
            using one of our example portfolios.
          </p>
        </Modal>
      {/*<pre style={{fontSize: 12}}>{JSON.stringify({user}, null, 2)}</pre>*/}
    </div>
  );
};
