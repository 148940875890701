import { PositionLight, SourceId } from "@iliotech/generated-api-v3";
import { IWhatIfPositionTableRow } from "./WhatIfTableStore";
import moment from "moment";
import { REQUEST_DATE_FORMAT, getPrice } from "../../main";
const CHUNK_LENGTH = 20;
export const getPositionLightName = (position: PositionLight) => {
  return `${position.name} [${position.displayId}]`;
};

export const getPortfolioPercentage = (
  positionValue: number,
  total: number
) => {
  return (positionValue / total) * 100;
};

const getWhatIfPositionsAsyncData = async (
  positions: PositionLight[],
  yearAgoDate: string,
  getFxRate: (currency: string) => Promise<number>
) => {
  return Promise.all(
    positions.map(async (position, index) => {
      let toRet: IWhatIfPositionTableRow = {
        id: index,
        quantity: position?.quantity,
        oldQuantity: position?.quantity,
        name: getPositionLightName(position),
        custodianCode: position.custodianCode,
        sourceId: position?.sourceId,
        multiplier: position?.multiplier || 1,
      };

      try {
        let lastYearPrice;
        if (position?.sourceId?.sourceId.endsWith("CASH")) {
          lastYearPrice = 1;
        } else {
          const lastYearPriceData = await getPrice(
            position?.sourceId?.sourceId,
            position?.sourceId?.sourceData,
            yearAgoDate
          );
          lastYearPrice = lastYearPriceData?.data;
        }

        const fxRate = await getFxRate(position?.currencyCode);

        toRet = {
          ...toRet,
          fxRate,
          totalValue:
            lastYearPrice * fxRate * position?.quantity * position?.multiplier,
          price: lastYearPrice,
        };
      } catch (e) {
        console.log({ e });
      }

      return toRet;
    })
  );
};

function chunkArray(array: any[], chunkSize: number) {
  return array.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!result[chunkIndex]) {
      result[chunkIndex] = [];
    }
    result[chunkIndex].push(item);
    return result;
  }, []);
}

export const parseWhatIfPositions = async (
  positions: PositionLight[],
  getFxRate: (currency: string) => Promise<number>,
  yearAgoDate: string
) => {
  const chunks = chunkArray(positions, CHUNK_LENGTH);
  let results: IWhatIfPositionTableRow[] = [];

  for (let i = 0; i < chunks.length; i++) {
    const chunk = chunks[i];
    const chunkResult = await getWhatIfPositionsAsyncData(
      chunk,
      yearAgoDate,
      getFxRate
    );
    results = results.concat(chunkResult);
  }

  const totalAbsolute = [...results].reduce(
    (a, b) => a + Math.abs(b?.totalValue || 0),
    0
  );

  const resultsWithPercentage: IWhatIfPositionTableRow[] = results.map(
    (item) => ({
      ...item,
      portfolioPercentage: getPortfolioPercentage(
        Math.abs(item.totalValue || 0),
        totalAbsolute
      ),
    })
  );

  return resultsWithPercentage
    .sort((a, b) => (b.portfolioPercentage || 0) - (a.portfolioPercentage || 0))
    .map((item, index) => ({ ...item, id: index }));
};

// support for cash
export const parseSourceIdToUpload = (sourceId: SourceId) => {
  return {
    sourceId: sourceId?.sourceId.replaceAll("CASH", "SUBS")!,
    sourceData: sourceId?.sourceData!,
  };
};

const getPricesAndFxRates = async (
  getFxRate: (currency: string) => Promise<number>,
  sourceIds: (SourceId & { currency: string })[],
  date: string
) => {
  const map = new Map<string, { fxRate: number; price: number }>();

  for (let i = 0; i < sourceIds.length; i += CHUNK_LENGTH) {
    const chunk = sourceIds.slice(i, i + CHUNK_LENGTH);

    const promises = chunk.map(async (sourceId) => {
      const [fxRate, priceData] = await Promise.all([
        getFxRate(sourceId?.currency),
        getPrice(sourceId?.sourceId, sourceId?.sourceData, date),
      ]);

      return {
        fxRate,
        price: priceData.data,
      };
    });

    const results = await Promise.all(promises);

    results.forEach((result, index) => {
      const sourceId = chunk[index]?.sourceId;
      map.set(sourceId, result);
    });
  }

  return map;
};

const mergePositionLightArrays = (
  base: PositionLight[],
  whatIf: PositionLight[]
) => {
  const uniqueIdsSet = new Set<string>();
  const mergedArray: PositionLight[] = [];

  // Add elements from array1
  for (const item of base) {
    if (!uniqueIdsSet.has(item.sourceId?.sourceId)) {
      uniqueIdsSet.add(item.sourceId?.sourceId);
      mergedArray.push(item);
    }
  }

  for (const item of whatIf) {
    if (!uniqueIdsSet.has(item.sourceId?.sourceId)) {
      uniqueIdsSet.add(item.sourceId?.sourceId);
      mergedArray.push(item);
    }
  }
  return mergedArray;
};

export const mergeWhatIfPositions = async (
  base: PositionLight[],
  whatIf: PositionLight[],
  date: string,
  getFxRate: (currency: string) => Promise<number>
) => {
  const sourceIds = [...base, ...whatIf].map((item) => ({
    sourceId: item.sourceId?.sourceId,
    sourceData: item?.sourceId?.sourceData,
    currency: item?.currencyCode,
  }));

  const pricesAndFxRatesMap = await getPricesAndFxRates(
    getFxRate,
    sourceIds,
    date
  );

  const getItemQuantity = (item: PositionLight, isBase?: boolean) => {
    const found = (isBase ? whatIf : base).find(
      (itemToFind) => itemToFind.sourceId?.sourceId === item.sourceId?.sourceId
    );
    if (found) {
      return found.quantity;
    } else return 0;
  };

  const mergedArray = mergePositionLightArrays(base, whatIf);

  const results: IWhatIfPositionTableRow[] = mergedArray.map((item, index) => {
    const price = pricesAndFxRatesMap.get(item.sourceId?.sourceId)?.price!;
    const fxRate = pricesAndFxRatesMap.get(item.sourceId?.sourceId)?.fxRate!;
    const quantity = getItemQuantity(item, true);
    return {
      id: index,
      price,
      fxRate,
      oldQuantity: getItemQuantity(item),
      quantity: getItemQuantity(item, true),
      totalValue: price * fxRate * quantity * item?.multiplier,
      sourceId: item.sourceId,
      name: getPositionLightName(item),
      custodianCode: item.custodianCode,
    };
  });

  const totalAbsolute = [...results].reduce(
    (a, b) => a + Math.abs(b?.totalValue || 0),
    0
  );

  const resultsWithPercentage: IWhatIfPositionTableRow[] = results.map(
    (item) => ({
      ...item,
      portfolioPercentage: getPortfolioPercentage(
        Math.abs(item.totalValue || 0),
        totalAbsolute
      ),
    })
  );

  return resultsWithPercentage
    .sort((a, b) => (b.portfolioPercentage || 0) - (a.portfolioPercentage || 0))
    .map((item, index) => ({ ...item, id: index }));
};
