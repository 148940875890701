import React, { useEffect, useRef } from "react";
import { PortfolioAllocationResponseData } from "@iliotech/generated-api";
import cn from "classnames";
import s from "./StructureHeader.module.scss";
import {
  BundlesEnum,
  STRUCTURE_OPTIONS,
  sendTracking,
  useAuth,
  useStructureGrouping,
} from "@iliotech/data-wire";
import { Typography } from "@progress/kendo-react-common";
import ScrollContainer from "react-indiana-drag-scroll";
import { BsChevronLeft, BsChevronRight, BsChevronDown } from "react-icons/bs";
import { useMeasure, useWindowSize } from "react-use";

import { PortfolioDTOWithDetailsV3RiskAssetViewEnum } from "../../../../generated-api-v3";
import { IconButton } from "../IconButton/IconButton";
import { BREAKPOINTS } from "@iliotech/data-wire";
import { StructureType } from "@iliotech/data-wire/src/reactHooks/context/StructureGrouping/types";
import { WithBundle } from "../__wrappers";
import { set } from "lodash";

function convertRemToPixels(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
// size in rem
const ALLOCATION_CARD_SIZE = 14;

interface IProps {
  riskAssetView: PortfolioDTOWithDetailsV3RiskAssetViewEnum | undefined;
  lockedGroupPassed?: StructureType;
}

export const StructureHeader = ({
  allocations,
  riskAssetView,
  lockedGroupPassed,
}: Partial<PortfolioAllocationResponseData> & IProps) => {
  const {
    grouping,
    setGrouping,
    selectedGroup,
    setSelectedGroup,
    enableSelection,
    setRiskAssetView,
    structureOptions,
    setLockedGroup,
    lockedGroup,
  } = useStructureGrouping();
  // const showIcon = typeof grouping.iconRenderer === "function";
  const scroll = useRef<any>();

  const [arrowRight, setArrowRight] = React.useState(true);
  const [arrowLeft, setArrowLeft] = React.useState(false);
  const [allocationsDropdownMobile, setAllocationsDropdownMobile] =
    React.useState(false);
  const { width } = useWindowSize();
  const { hasBundle } = useAuth();
  const [containerRef, containerSize] = useMeasure();
  const contentWidth =
    convertRemToPixels(ALLOCATION_CARD_SIZE) *
    ((allocations?.[grouping.key]?.length || 0) + 1 || 0);
  const overflow = React.useMemo(
    () => contentWidth - containerSize.width,
    [containerSize, contentWidth]
  );

  useEffect(() => {
    setLockedGroup?.(lockedGroupPassed);
  }, [lockedGroupPassed]);

  const onArrowRightPress = () => {
    scroll.current?.container?.current?.lastChild?.lastChild.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
    setArrowRight(false);
    setArrowLeft(true);
  };

  const onArrowLeftPress = () => {
    scroll.current?.container?.current?.lastChild?.firstChild.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
    setArrowLeft(false);
    setArrowRight(true);
  };

  React.useEffect(() => {
    if (riskAssetView) {
      setRiskAssetView?.(riskAssetView);
    }
  }, [riskAssetView]);

  React.useEffect(() => {
    console.log({ containerSize: containerSize.width, contentWidth });
    if (containerSize.width > contentWidth) {
      setArrowRight(false);
    } else {
      setArrowRight(true);
    }
  }, [containerSize, contentWidth]);

  const onEndScroll = (e: any) => {
    // if scroll is caused by arrow click, we do nothing here as we manage after click
    if (e.external) {
      return;
    }
    if (
      scroll.current?.scrollLeft >
      overflow - convertRemToPixels(ALLOCATION_CARD_SIZE)
    ) {
      setArrowRight(false);
    } else {
      setArrowRight(true);
    }
    if (scroll.current?.scrollLeft > convertRemToPixels(ALLOCATION_CARD_SIZE)) {
      setArrowLeft(true);
    } else {
      setArrowLeft(false);
    }
  };

  const renderAllocations = React.useMemo(() => {
    const isMobile = width < BREAKPOINTS.SMALL;
    const windowAllocations =
      isMobile && !allocationsDropdownMobile
        ? allocations?.[grouping.key].slice(0, 2)
        : allocations?.[grouping.key];
    return windowAllocations
      ?.sort((a, b) => (b.percentage ?? 0) - (a.percentage ?? 0))
      ?.map((allocation) => {
        const isSelected = allocation.id === selectedGroup?.code;

        return (
          <Allocation
            enableSelection={enableSelection}
            onClick={
              enableSelection
                ? () =>
                    setSelectedGroup?.({
                      code: allocation.id,
                      label: allocation.name,
                    })
                : undefined
            }
            key={allocation.id}
            selected={isSelected}
            color={grouping.getColor?.(allocation.id)}
            icon={grouping?.iconRenderer?.(allocation.id) as any}
            name={allocation.name}
            percentage={allocation.percentage}
          />
        );
      });
  }, [allocations, width, allocationsDropdownMobile, grouping, selectedGroup]);

  const renderAllocationsDropdown = React.useMemo(() => {
    if (width < BREAKPOINTS.SMALL && !allocationsDropdownMobile) {
      return (
        <div
          onClick={() => setAllocationsDropdownMobile(true)}
          className={s.allocationsDropdownMobile}
        >
          <Typography.p>
            All assets <BsChevronDown />
          </Typography.p>
        </div>
      );
    }
  }, [width, allocationsDropdownMobile]);

  return (
    <article
      className={cn(
        s.surround,
        hasBundle(BundlesEnum.Manager_Functions) && s.grid
      )}
    >
      {/* @ts-ignore */}
      <div className={cn(s.shadow, s.left, !arrowLeft && s.hide)} />
      <div
        className={cn(s.iconContainer, s.left, !arrowLeft && s.hide)}
        onClick={onArrowLeftPress}
      >
        <BsChevronLeft size={"1.2rem"} />
      </div>
      {/* @ts-ignore */}
      <ScrollContainer
        innerRef={containerRef as any}
        onEndScroll={onEndScroll}
        ref={scroll}
      >
        <div className={cn(s.allocationsSurround)}>
          <Allocation
            enableSelection={enableSelection}
            onClick={
              enableSelection ? () => setSelectedGroup?.(undefined) : undefined
            }
            selected={!selectedGroup?.code}
            color={"var(--selected)"}
            name={"All assets"}
            percentage={100}
          />
          {renderAllocations}
          {renderAllocationsDropdown}
        </div>
      </ScrollContainer>
      <div className={s.row}>
        <div className={cn(s.shadow, !arrowRight && s.hide)} />
        <div
          className={cn(s.iconContainer, !arrowRight && s.hide)}
          onClick={onArrowRightPress}
        >
          <BsChevronRight size={"1.2rem"} />
        </div>
        <WithBundle bundle={BundlesEnum.Manager_Functions}>
          {!lockedGroup && (
            <>
              <b>Show by</b>
              <select
                value={grouping.key}
                data-cy={"show-by-dropdown"}
                className={s.dropdown}
                onChange={(event) => {
                  sendTracking(`show_by_changed_to_${event.target.value}`);
                  setGrouping(event.target.value as any);
                }}
              >
                {structureOptions.map((option) => (
                  <option key={option.key} value={option.key}>
                    {option.label}
                  </option>
                ))}
              </select>
            </>
          )}
        </WithBundle>
      </div>
      {/*{grouping.key}*/}
    </article>
  );
};

interface IAllocationProps {
  onClick?(): void;
  name: string;
  percentage?: number;
  color?: string;
  enableSelection?: boolean;
  selected?: boolean;
  icon?: string;
  iconColor?: string;
}

const Allocation = React.memo(
  ({
    onClick,
    color,
    enableSelection,
    selected,
    icon,
    name,
    percentage,
  }: IAllocationProps) => {
    return (
      <IconButton
        {...{ name, color, onClick, enableSelection, selected, icon }}
        subtitle={`${percentage}%`}
      />
    );
  }
);
