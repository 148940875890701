import React, { FC } from "react";
import { IDecimalCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

const DECIMAL_POINT_SIZE = 8;

type Options = {
  greenForPositive?: boolean;
  percentage?: boolean;
  maxDecimals?: number;
  checkIsTotal?(rowData: any): void;
  forceDecimals?: boolean;
};

const DEFAULT_OPTIONS: Options = {
  greenForPositive: false,
  percentage: false,
  maxDecimals: 2,
  checkIsTotal: (rowData: any) => rowData?.isTotal,
  forceDecimals: false,
};

export const DecimalCell = (optionsIn?: Options): FC<IDecimalCellProps> => {
  const options = {
    ...DEFAULT_OPTIONS,
    ...optionsIn,
  };
  const { greenForPositive } = options;

  return React.memo(({ width = 100, ...props }) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const isCrypto =
      props.dataItem?.assetClassCode === "CryptoCurrencies" &&
      (props.field === "quantity" || props.field === "price");
    const decimals = isCrypto ? 7 : options?.maxDecimals;
    const field = props.field || "";
    const value = props.dataItem[field];

    const sign = value >= 0 ? 1 : -1;

    const whole = React.useMemo(() => {
      if (decimals === 0) {
        return Math.round(value);
      } else return value;
    }, [value, decimals]);

    const [wholeAmount, decimalAmount] = (
      optionsIn?.forceDecimals
        ? String(Math.abs(whole).toFixed(decimals))
        : String(Math.abs(whole))
    ).split(".");

    // const availableWidth = width - DECIMAL_POINT_SIZE;

    return (
      <td
        style={{
          // TODO: replace colors with appropriate css-vars
          color: !greenForPositive
            ? undefined
            : parseFloat(value) > 0
            ? "green"
            : parseFloat(value) === 0
            ? undefined
            : "red",
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            fontFamily: `"Azeret Mono", "DM Sans", Nunito, sans-serif`,
            fontWeight: options.checkIsTotal?.(props.dataItem)
              ? 600
              : undefined,
          }}
        >
          {isNaN(value) || `${value}` === "NaN" ? (
            <div />
          ) : (
            <>
              <div
                style={{
                  // width: (availableWidth * 2) / 3,
                  flex: 1,
                  textAlign: "right",
                  fontFamily: `"Azeret Mono", "DM Sans", Nunito, sans-serif`,
                }}
              >
                {(sign * parseInt(wholeAmount))?.toLocaleString()}
              </div>
              <div style={{ width: DECIMAL_POINT_SIZE, textAlign: "center" }}>
                {!!decimalAmount && !!decimals && "."}
              </div>
              {!!decimals ? (
                <div
                  style={{
                    // width: (availableWidth * 1) / 3,
                    flex: 1,
                    textAlign: "left",
                    opacity: 0.9,
                    fontFamily: `"Azeret Mono", "DM Sans", Nunito, sans-serif`,
                  }}
                >
                  {decimalAmount?.slice(0, decimals)}
                  {options?.percentage ? "%" : ""}
                </div>
              ) : (
                <div style={{ flex: 1 }}></div>
              )}
            </>
          )}
        </div>
      </td>
    );
  });
};
