"use client";
import axios from "axios";

import React, { FC, PropsWithChildren, useRef, version } from "react";

import {
  DisplayItem,
  SortedItemGetter,
  MarketInsightsSortType,
  WatchlistBreakdown,
  InstrumentSearch,
  InstrumentSearchResult,
} from "../types";
import { debounce } from "lodash";
import { useToken } from "./AuthContext";
import { useAppLoadingContext } from "./AppLoadingContext";
import { useSortContext } from "./SortContext";
import { getApiBase } from "@iliotech/watchlist-api/src/utils/watchlist-api";
import { useSortCategory } from "../hooks/useSortCategory";

interface IOwnProps {
  getSortedItems?: SortedItemGetter;
  searchInstruments?: InstrumentSearch;
  onInsightsButtonClick?: () => void;
  onHomeClick?: () => void;
  onInstrumentClick(displayItem: DisplayItem, useWatchlist?: boolean): void;
  onTableButtonClick?: () => void;
}

const MarketContext =
  React.createContext<
    | (ReturnType<typeof useMarketContextValue> &
        Pick<
          IOwnProps,
          | "onInsightsButtonClick"
          | "onInstrumentClick"
          | "onHomeClick"
          | "onTableButtonClick"
        >)
    | null
  >(null);

const DEFAULT_SORTED_ITEMS_GETTER: SortedItemGetter = async (
  sort: MarketInsightsSortType,
  watchlistId: string,
  environment: string = "dev",
  period: number = 0
) => {
  const actualPeriod = period;
  const { categoryId, subCategoryId } = sort;
  const INSIGHTS_API_BASE = getApiBase(environment);
  const url = `${INSIGHTS_API_BASE}/market/category/${categoryId}/watchlistId/${watchlistId}`;
  const result = await axios.get<{ insight: WatchlistBreakdown[] }>(url);

  const subCategory = result?.data?.insight?.find?.(
    (i: any) => i?.id === subCategoryId
  );

  const parseRowInstruments = (row: { instruments: DisplayItem[] }) =>
    row.instruments.map((instrument) => ({
      ...instrument,
      id: instrument.instrumentId,
    }));

  const rows: {
    label: string;
    instruments: DisplayItem[] & { id?: string };
  }[] =
    (subCategory?.chart?.[actualPeriod]?.rows || []).map((row) => ({
      ...row,
      instruments: parseRowInstruments(row),
    })) || [];

  const title =
    subCategory?.title +
    (!!subCategory?.chart?.[actualPeriod]?.subTitle
      ? " - " + subCategory?.chart?.[actualPeriod]?.subTitle
      : "");
  const description = subCategory?.chart?.[actualPeriod]?.whyImportant ?? "";

  return {
    title,
    description,
    rows,
  };
};

const DEFAULT_SEARCH_INSTRUMENTS: InstrumentSearch = async (
  search: string,
  environment: string = "dev",
  token: string
): Promise<InstrumentSearchResult[]> => {
  const INSIGHTS_API_BASE = getApiBase(environment);

  const result = await axios.get<InstrumentSearchResult[]>(
    `${INSIGHTS_API_BASE}/instruments?text=${search}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const INITIAL_SORT = {
  categoryId: "RISK",
  subCategoryId: "UPSIDE_IMPACT",
} as const;

export const MarketContextProvider: FC<PropsWithChildren<IOwnProps>> = ({
  children,
  getSortedItems = DEFAULT_SORTED_ITEMS_GETTER,
  searchInstruments = DEFAULT_SEARCH_INSTRUMENTS,
  onInsightsButtonClick,
  onInstrumentClick,
  onHomeClick,
  onTableButtonClick,
}) => {
  const value = useMarketContextValue(getSortedItems, searchInstruments);
  return (
    <MarketContext.Provider
      value={{
        ...value,
        onInsightsButtonClick,
        onInstrumentClick,
        onHomeClick,
        onTableButtonClick,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};

const useMarketContextValue = (
  getSortedItems: SortedItemGetter,
  searchInstruments: InstrumentSearch
) => {
  const { token, environment } = useToken();
  const { optimisticSort, setSort, sort, watchlistId } = useSortContext();
  const { data: sortCategories } = useSortCategory(watchlistId, environment);
  const { setLoading, loading } = useAppLoadingContext();
  const [sortTitle, setSortTitle] = React.useState("");

  const [sortDescription, setSortDescription] = React.useState<string>();

  const [rows, setRows] = React.useState<
    {
      instruments: DisplayItem[];
      label: string;
    }[]
  >([]);
  const currentSearch = React.useRef("");
  const [searchResults, setSearchResults] = React.useState<
    InstrumentSearchResult[]
  >([]);
  const [searchInProgress, setSearchInProgress] = React.useState(false);
  const watchlistIdRef = useRef(watchlistId);
  React.useEffect(() => {
    watchlistIdRef.current = watchlistId;
  }, [watchlistId]);

  const sortInstruments = React.useCallback(
    async (nextSort: MarketInsightsSortType, overrideWatchlist?: string) => {
      optimisticSort.setOptimisticSort(nextSort);
      setLoading(true);
      const nextItems = await DEFAULT_SORTED_ITEMS_GETTER(
        nextSort,
        overrideWatchlist || watchlistIdRef.current,
        environment,
        nextSort.period
      );

      setRows(nextItems.rows);

      setSortTitle(nextItems.title);
      setSortDescription(nextItems.description);
      setLoading(false);
      setSort(nextSort);
    },
    [watchlistIdRef]
  );

  React.useEffect(() => {
    if (!!sortTitle) {
      return;
    }
    if (sortCategories?.length === 0 || !sortCategories) {
      return;
    }
    if (!!sort.categoryId) {
      sortInstruments({
        categoryId: sort.categoryId,
        subCategoryId: sort.subCategoryId,
        subCategoryLabel: sort.subCategoryLabel,
        period: sort.period,
      });
      return;
    }
  }, [sortCategories, sort]);

  const search = debounce(async (s: string) => {
    if (s === currentSearch.current) {
      return;
    }
    setSearchInProgress(true);
    currentSearch.current = s;
    const instruments = await searchInstruments(s, environment, token);
    setSearchInProgress(false);
    setSearchResults(instruments);
  }, 200);

  const toggleSort = () => {
    const nextSort = {
      ...sort,
    } as const;
    return sortInstruments(nextSort);
  };

  return {
    sort,
    loading: optimisticSort?.isLoading || loading,
    setLoading,
    toggleSort,
    sortInstruments,
    sortTitle,
    sortDescription,
    sortCategories,
    searchInProgress,
    search,
    searchResults,
    rows,
    ...optimisticSort,
  };
};

export const useMarketContext = () => {
  const value = React.useContext(MarketContext);
  if (!value) {
    throw new Error("MarketContext is not initialized");
  }
  return value;
};
