import { useWhatIfPositions } from "@iliotech/data-wire/src/stores";
import { WhatIfPositionsTableCellProps } from "../WhatIfPositionsTable";
import { observer } from "mobx-react-lite";

const TotalValueCell = ({ index }: WhatIfPositionsTableCellProps) => {
  const store = useWhatIfPositions();
  const totalValue = store.getField<number>("totalValue", index);

  const formattedTotalValue = totalValue
    ? totalValue.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      })
    : "";

  return <div>{formattedTotalValue}</div>;
};

export default observer(TotalValueCell);
