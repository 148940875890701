import { GridColumnProps } from "@progress/kendo-react-grid";
import { CellWrapper } from "./cells/CellWrapper";
import StatusCell from "./cells/StatusCell";
import TickerCell from "./cells/TickerCell";
import NameCell from "./cells/NameCell";
import QuantityCell from "./cells/QuantityCell";
import PriceCell from "./cells/PriceCell";
import CustodianCell from "./cells/CustodianCell";
import * as React from "react";
import TotalLocalCell from "./cells/TotalLocalCell";
import MarketPriceCell from "./cells/MarketPriceCell";
import DeleteCell from "./cells/DeleteCell";

export const columns: (baseCurrency: string) => GridColumnProps[] = (
  baseCurrency: string
) => [
  {
    field: "status",
    title: "STATUS",
    width: 50,
    cell: (props) => <CellWrapper gridCellProps={props} cell={StatusCell} />,
  },
  {
    field: "ticker",
    title: "TICKER",
    minResizableWidth: 60,
    cell: (props) => <CellWrapper gridCellProps={props} cell={TickerCell} />,
  },
  {
    field: "name",
    title: "INSTRUMENT NAME",
    minResizableWidth: 150,
    cell: (props) => (
      <CellWrapper
        gridCellProps={props}
        cell={NameCell}
        baseCurrency={baseCurrency}
      />
    ),
  },
  {
    field: "custodian",
    title: "ACCOUNT",
    minResizableWidth: 100,
    cell: (props) => <CellWrapper gridCellProps={props} cell={CustodianCell} />,
  },
  {
    field: "quantity",
    title: "QUANTITY",
    minResizableWidth: 100,
    cell: (props) => <CellWrapper gridCellProps={props} cell={QuantityCell} />,
  },
  {
    field: "price",
    title: "LOCAL PRICE",
    minResizableWidth: 150,
    cell: (props) => <CellWrapper gridCellProps={props} cell={PriceCell} />,
  },
  {
    field: "historicPrice",
    title: "MARKET PRICE",
    minResizableWidth: 150,
    cell: (props) => (
      <CellWrapper gridCellProps={props} cell={MarketPriceCell} />
    ),
  },
  {
    field: "total",
    title: "TRANSACTION VALUE LOCAL",
    minResizableWidth: 100,
    cell: (props) => (
      <CellWrapper gridCellProps={props} cell={TotalLocalCell} />
    ),
  },
  {
    field: "delete",
    title: "ACTION",
    minResizableWidth: 100,
    cell: (props) => <CellWrapper gridCellProps={props} cell={DeleteCell} />,
  },
];
