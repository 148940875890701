import React, { FC } from "react";

import cn from "classnames";
import s from "./Modal.module.css";
import { IModalProps } from "./types";
import { createPortal } from "react-dom";

export const Modal: FC<IModalProps> = ({
  title,
  show,
  children,
  onClose,
  footer,
  dataCy,
}) => {
  if (!show) {
    return null;
  }

  return createPortal(
    <div className={cn(s.background)} onClick={onClose}>
      <section data-cy={dataCy} onClick={() => {}} className={cn(s.surround)}>
        <div className={cn(s.main)}>
          <h6>{title}</h6>
          {children}
        </div>
        {!!footer && <div className={cn(s.footer)}>{footer}</div>}
      </section>
    </div>,
    document.getElementById("app")!
  );
};
