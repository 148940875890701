import { PortfolioSnapshotDtoV3 } from "../../../generated-api-v3";

export type AggregatePosition = Omit<
  PortfolioSnapshotDtoV3,
  "costPrice" | "priceGain" | "priceMovePercentage"
>;

export type PositionOrAggregate = PortfolioSnapshotDtoV3 | AggregatePosition;

export const addPositions = <
  T extends PositionOrAggregate = PortfolioSnapshotDtoV3
>(
  acc: T,
  curr: T,
  sumQuantities = true
) => {
  const fields: (keyof T)[] = [
    "incomeLocal",
    "notionalBase",
    "marketValueLocal",
    "marketValueBase",
    "pnlBase",
    "pnlFxBase",
    "totalCostLocal",
    "totalCostBase",
    "percentOfGrossPortfolio",
    "pnlLocal",
    "realisedBase",
    "exposureBase",
    "incomeHistoricBase",
    "percentOfPortfolio",
    ...(sumQuantities ? (["quantity"] as (keyof T)[]) : []),
  ];
  const next = { ...acc };
  fields.forEach((field) => {
    //@ts-ignore
    next[field] = (next[field] || 0) + (curr[field] || 0);
  });

  if (curr.custodianName !== next.custodianName) {
    next.custodianName = "Multiple";
  }

  return next;
};
