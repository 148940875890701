import React, { FC } from "react";
import { StatusHandler } from "./StatusHandler";
import { sendRequest } from "../../hooks/useApi";
import { AUTH_MODE } from "./constants";
import type {
  AccountCapabilityKeyEnum,
  AccountSummaryDTO,
  EBundleNameDto,
} from "@iliotech/generated-api-v3";
import {
  AuthContext,
  BundlesEnum,
  LOCAL_STORAGE_KEYS,
  useAccountCapabilities,
} from "@iliotech/data-wire";
import { useLogout } from "../../hooks/useLogout";
import { useDVCClient } from "@devcycle/devcycle-react-sdk";
import { Auth } from "aws-amplify";
import { useUser } from "@iliotech/data-wire/src/reactHooks/hooks/useUser";

export const AuthContextProvider: FC<{
  signOut: (data?: Record<string | number | symbol, any> | undefined) => void;
  user: any;
}> = ({ children, signOut, user }) => {
  const logOut = useLogout();
  const [mode, setMode] = React.useState<AUTH_MODE>(AUTH_MODE.LOADING_ACCOUNTS);
  const [illioSelectedAccount, setIllioSelectedAccount] =
    React.useState<AccountSummaryDTO>();
  const [availableAccounts, setAvailableAccounts] = React.useState<
    AccountSummaryDTO[]
  >([]);

  const devcycleClient = useDVCClient();
  const capabilities = useAccountCapabilities(
    illioSelectedAccount?.externalAccountId!
  );
  const bundles: Set<EBundleNameDto> =
    capabilities.data?.data.userPermissionsAssigned!;
  const bundlesArray: BundlesEnum[] =
    Array.from(bundles || {}).map((item) => item.bundleName as BundlesEnum) ||
    [];
  const hasBundle = (bundle: BundlesEnum) => {
    return (bundlesArray || []).includes(bundle);
  };

  const isCustomer =
    illioSelectedAccount?.role === "CUSTOMER" ||
    illioSelectedAccount?.role === "RO_CUSTOMER";
  const isOwner = illioSelectedAccount?.role === "OWNER";
  const isManager = illioSelectedAccount?.role === "MANAGER";
  const [, setInfo] = React.useState<
    { action: string; details?: string | number; timeStamp: Date }[]
  >([]);

  const log = (action: string, details?: string | number) => {
    setInfo((prev) => [...prev, { action, details, timeStamp: new Date() }]);
  };

  const identifyWithDevCycle = (
    email: string,
    externalAccountId: string,
    accountRole: string
  ) => {
    devcycleClient.identifyUser({
      email,
      customData: {
        role: accountRole,
        externalAccountId,
      },
    });
  };

  React.useEffect(() => {
    if (!illioSelectedAccount) {
      log("No current account selection");
      return;
    }
    log(
      "Current account selection",
      `${illioSelectedAccount?.externalAccountId} - ${illioSelectedAccount.status}`
    );

    setMode(AUTH_MODE.ACCOUNT_SELECTED);
  }, [illioSelectedAccount]);

  const getAccounts = React.useCallback(
    function getAccountsFn() {
      return (async () => {
        try {
          await sendRequest("/api/v3/auth/check").catch();
        } catch (err) {
          console.log({ err });
        }
        sendRequest<AccountSummaryDTO[]>("/api/v3/accounts").then(
          (response) => {
            log("Loaded account summaries", `${response?.length} found`);
            const foundAccount = response.find(
              (item) =>
                item.externalAccountId ===
                localStorage.getItem(LOCAL_STORAGE_KEYS.ILLIO_SELECTED_ACCOUNT)
            );
            if (response?.length === 1) {
              const nextSelectedAccount = response[0];
              log(
                "Auto-selected Illio Account",
                nextSelectedAccount.externalAccountId
              );

              selectIllioAccount(nextSelectedAccount);
            } else if (foundAccount) {
              selectIllioAccount(foundAccount);
              setAvailableAccounts(response);
            } else {
              setAvailableAccounts(response);
              setMode(AUTH_MODE.SELECT_ACCOUNT);
            }
          }
        );
      })();
    },
    [setAvailableAccounts]
  );

  React.useEffect(() => {
    getAccounts();
  }, [user?.username, getAccounts]);

  const selectIllioAccount = (account: AccountSummaryDTO | null) => {
    // account selection
    if (!account) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ILLIO_SELECTED_ACCOUNT);
      setIllioSelectedAccount(account ?? undefined);
      setMode(AUTH_MODE.SELECT_ACCOUNT);
      // TODO: Switch themes
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.ILLIO_SELECTED_ACCOUNT,
        String(account.externalAccountId)
      );
      Auth.currentAuthenticatedUser().then((user) => {
        identifyWithDevCycle(
          user.attributes?.email,
          account.externalAccountId,
          account?.role
        );
      });

      setIllioSelectedAccount(account ?? undefined);
      // TODO: Switch themes
    }
  };

  return (
    <AuthContext.Provider
      value={{
        selectIllioAccount,
        illioSelectedAccount,
        availableAccounts,
        logOut,
        isCustomer,
        isManager,
        isOwner,
        capabilities: capabilities?.data?.data,
        userLimits: capabilities.data?.data?.userLimits,
        hasBundle,
      }}
    >
      <div style={{ position: "relative" }}>
        <StatusHandler
          {...{ children, signOut, user, illioSelectedAccount, mode }}
        />
      </div>
    </AuthContext.Provider>
  );
};
export const useAuth = () => React.useContext(AuthContext);
