import { PortfolioApi } from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../constants/constants";

export const usePortfolioAllocation = (
  portfolioId: string,
  showClosedPositions?: boolean
) => {
  const api = new PortfolioApi(undefined, `${API_BASE}/api`);
  return useQuery(
    [`portfolio-allocation`, { portfolioId, showClosedPositions }],
    () =>
      api.getPortfolioStructure1(
        portfolioId,
        "MarketValueBase",
        undefined,
        undefined,
        showClosedPositions
      )
  );
};
