import React, {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import moment, { MomentInput } from "moment";
import { ITimePeriod } from "./types";
import { CHART_DATE_FORMAT, CONSUMER_EPOCH } from "../../constants/constants";
import { Period } from "../../../../generated-api-v3";
import { PERIOD_IDS } from "@iliotech/component-library";

interface IGlobalPeriodContext {
  period: ITimePeriod | undefined;
  periodForRequest: Period | undefined;
  setPeriod(newPeriod: ITimePeriod | undefined): void;
  periodString: string;
  inceptionDate?: MomentInput;
  setInceptionDate?: (d: MomentInput) => void;
  epochDate: Date;
  periodDays: number;
  setEpochDate: (d: Date) => void;
}

type Props = Pick<IGlobalPeriodContext, "inceptionDate">;

const noop = () => {
  console.log("GlobalPeriodContext not yet initialised");
};

const GlobalPeriodContext = React.createContext<IGlobalPeriodContext>({
  period: undefined,
  periodForRequest: undefined,
  periodString: "",
  setPeriod: noop,
  epochDate: CONSUMER_EPOCH,
  setEpochDate: noop,
  periodDays: 0,
});

// Should also bring in inceptionDate from Portfolio Context, and use inceptionDateFromProps if provided or fall back to inceptionDate from portfolioContext
export const GlobalPeriodProvider = ({
  children,
  inceptionDate: inceptionDateFromProps,
}: PropsWithChildren<Props>) => {
  const today = moment().startOf("day").set({ hours: 3 }).toDate();
  const [epochDate, setEpochDate] = useState(CONSUMER_EPOCH);
  const [inceptionDate, setInceptionDate] = useState(inceptionDateFromProps);
  const defaultPeriod = {
    from: undefined,
    to: today,
    label: "All",
  };

  const [period, setPeriod] =
    React.useState<ITimePeriod | undefined>(defaultPeriod);

  const periodDays = React.useMemo(() => {
    if (!period) {
      return 0;
    }
    return moment(period.to).diff(moment(period.from), "days");
  }, [period]);

  const periodForRequest = React.useMemo(
    () =>
      !period
        ? undefined
        : {
            from: moment(period.from).format("YYYY-MM-DD"),
            to: moment(period.to).format("YYYY-MM-DD"),
          },
    [period]
  );

  const periodString = React.useMemo(
    () =>
      !period
        ? ""
        : moment(period.from).format(CHART_DATE_FORMAT) +
          " - " +
          moment(period.to).format(CHART_DATE_FORMAT),
    [period]
  );

  useEffect(() => {
    const portfolioLifeTimeInDays = moment(today).diff(
      moment(inceptionDate),
      "days"
    );
    if (portfolioLifeTimeInDays > 365) {
      setPeriod({
        from: moment(today).subtract(1, "year").add(1, "day").toDate(),
        to: moment(today).toDate(),
        label: "1 Year",
      });
    } else {
      setPeriod({
        from:
          typeof inceptionDate === "undefined"
            ? undefined
            : moment(inceptionDate).toDate(),
        to: moment().startOf("day").set({ hours: 3 }).toDate(),
        label: "All",
      });
    }
  }, [inceptionDate]);

  return (
    <GlobalPeriodContext.Provider
      value={{
        period,
        periodString,
        setPeriod,
        inceptionDate,
        setInceptionDate,
        periodForRequest,
        setEpochDate,
        epochDate,
        periodDays,
      }}
    >
      {children}
    </GlobalPeriodContext.Provider>
  );
};

export const useGlobalPeriod = () => React.useContext(GlobalPeriodContext);
export const createDateObject = (date: string) =>
  moment(date, "YYYY-MM-DD").toDate();
export const makeComputableDateString = (date: Date) =>
  moment(date).format("YYYY-MM-DD");
export const makeHumanReadableDateString = (date: Date) =>
  moment(date).format("DD MMM` YY");
