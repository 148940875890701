import React, { FC } from "react";
import { IDecimalCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { BsCircleFill } from "react-icons/bs";
import { Typography } from "@progress/kendo-react-common";

export const EsgCell = (): FC<IDecimalCellProps> => {
  return React.memo(({ width = 100, ...props }) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const field = props.field || "";
    const value: "R" | "A" | "G" = props.dataItem[field + "Rank"];

    const { color, stringValue } = React.useMemo(() => {
      switch (value) {
        case "R": {
          return { color: "var(--red)", stringValue: "Weak" };
        }
        case "A": {
          return { color: "var(--amber)", stringValue: "Fair" };
        }
        default: {
          return { color: "var(--green)", stringValue: "Strong" };
        }
      }
    }, [value]);

    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div style={{ width }}>
          <span style={{ color, fontSize: "0.7rem" }}>
            <BsCircleFill
              color={color}
              size={9}
              style={{ marginRight: "0.5rem" }}
            />
            {stringValue}
          </span>
        </div>
      </td>
    );
  });
};
