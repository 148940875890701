import moment from "moment";
import React, { useEffect } from "react";
import { REQUEST_DATE_FORMAT } from "../../../constants/constants";
import { useTransactionUploadV2 } from "../../../stores";
import { ITableRow } from "@iliotech/data-wire";
import { useFxRateCached } from "../../api/useFxRate/useFxRateCached";

interface useFetchFxRateProps {
  dataItem: ITableRow;
  baseCurrency: string;
}

export const deriveFxRate = (
  type: "BUY" | "SELL",
  totalBase: number,
  commission: number,
  tradeCosts: number,
  quantity: number,
  price: number
) => {
  const commissionToUse = type === "BUY" ? commission : -commission;
  const tradeCostsToUse = type === "BUY" ? tradeCosts : -tradeCosts;
  const totalLocal =
    quantity * (price || 1) + (commissionToUse || 0) + (tradeCostsToUse || 0);

  return totalBase / totalLocal;
};

export const useFetchFxRateLite = ({
  dataItem,
  baseCurrency,
}: useFetchFxRateProps) => {
  const store = useTransactionUploadV2();
  const currency = dataItem?.currency;

  const date = dataItem?.date;
  const price = dataItem?.price;
  const overrideFxRate = dataItem?.overrideFxRate;
  const dateChanged = dataItem?.dateChanged;
  const selectedInstrument = dataItem?.selectedInstrument;
  const setFxRate = (data: number) =>
    store.equities.updateTableRowByIndex("fxRate", data, dataItem.id);

  const { refetch } = useFxRateCached(
    moment(date).format(REQUEST_DATE_FORMAT),
    currency!,
    baseCurrency!,
    (data) => {
      setFxRate(data.data.fromToFxRate);
    }
  );

  const fetchFxRate = async (overrideFxRateLocal: boolean) => {
    // if total base was passed, we derive the fx rate based on the truth of the total base value
    if (overrideFxRateLocal === true) {
      const derived = deriveFxRate(
        dataItem?.type as any,
        dataItem?.totalBase || 0,
        dataItem?.commission || 0,
        dataItem?.tradeCosts || 0,
        dataItem?.quantity || 1,
        dataItem?.price || 1
      );
      setFxRate(derived);
      return;
    }

    if (!dateChanged) {
      setFxRate(selectedInstrument?.fxRate || 1);
      return;
    }
    if (currency === baseCurrency) {
      setFxRate(1);
      return;
    }

    if (overrideFxRateLocal === false) {
      refetch();
    }
  };

  useEffect(() => {
    if (
      !!date &&
      !!currency &&
      !!baseCurrency &&
      !!dataItem &&
      !!selectedInstrument
    ) {
      fetchFxRate(overrideFxRate || false);
    }
  }, [date, currency, baseCurrency, overrideFxRate, price]);

  return {};
};
