import styled from "@emotion/styled";

const breakpoints = [576, 768, 992, 1200];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export const StyledBigButtonBackground = styled.div`
  position: relative;
  filter: drop-shadow(5px 5px 6px #ccc);
  color: black;
  background-color: white;
  font-weight: 300;
  border-radius: 0.5rem;
  padding: 1rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  ${mq[0]} {
    height: 100%;
    &:hover {
      z-index: 1;
      transform: scale(1.2);
    }
  }
  ${mq[1]} {
    min-height: 10rem;
    width: 12rem;
  }
  z-index: 0;

  transition: transform 400ms, color 400ms, background-color 400ms;

  &:hover {
    color: var(--primary);
  }

  text-decoration-line: none;
`;

export const StyledBigButtonTitle = styled.div`
  font-size: 1rem;
`;

export const StyledBigButtonDescription = styled.div`
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;
