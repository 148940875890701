import * as React from "react";
import RoundButton from "../RoundButton/RoundButton";
import IllioDialogStructured from "../IllioDialogStructured/IllioDialogStructured";

interface IProps {
  username: string;
  logout: () => void;
  path: string;
  visible: boolean;
}

export const TrialEndedDialog = ({
  visible,
  path,
  logout,
  username,
}: IProps) => {
  return visible ? (
    <IllioDialogStructured
      header={<p className={"element-title"}>End of trial</p>}
      footer={
        <>
          <RoundButton
            fillMode={"outline"}
            style={{ maxWidth: 140 }}
            onClick={logout}
          >
            Log off
          </RoundButton>
          <a href={path}>
            <RoundButton style={{ maxWidth: 160 }}>
              Reactivate account
            </RoundButton>
          </a>
        </>
      }
      width={600}
    >
      <p>Hello {username},</p>
      <p>
        Your trial has ended. In order to regain access to your account please
        resubscribe via Interactive Brokers. Or click on the “Reactivate my
        account” button below to see our step-by-step guide.
      </p>{" "}
      <p>
        If you have any questions about this please feel free to get in touch
        at: <a href={"mailto:support@illio.com"}>support@illio.com</a>
      </p>
    </IllioDialogStructured>
  ) : null;
};

export default TrialEndedDialog;
