/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AccountIssue
 */
export interface AccountIssue {
    /**
     * 
     * @type {string}
     * @memberof AccountIssue
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof AccountIssue
     */
    'actionCode': AccountIssueActionCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountIssueActionCodeEnum {
    MapBrokerIb = 'MAP_BROKER_IB',
    UpdateSubscription = 'UPDATE_SUBSCRIPTION',
    AddSubscription = 'ADD_SUBSCRIPTION',
    ContactSupport = 'CONTACT_SUPPORT',
    AddUsers = 'ADD_USERS',
    AddManagers = 'ADD_MANAGERS',
    AddCustomer = 'ADD_CUSTOMER',
    AddTrades = 'ADD_TRADES',
    AddPortfolios = 'ADD_PORTFOLIOS',
    AddBrokerConnections = 'ADD_BROKER_CONNECTIONS',
    PaymentFailed = 'PAYMENT_FAILED',
    SaveWhatifComparison = 'SAVE_WHATIF_COMPARISON',
    WhatifComparisonLimit = 'WHATIF_COMPARISON_LIMIT'
}


