import {
  assignToWorkgroup,
  unAssignToWorkgroup,
  useAuth,
  useWorkgroupInfoV3,
  useWorkgroupList,
} from "@iliotech/data-wire";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useUsers } from "@iliotech/data-wire/src/reactHooks/api/useUsers";
import ManagerShareModal from "@iliotech/component-library/src/components/__pageComponents/WorkgroupListPage/components/ManagerShareModal/ManagerShareModal";
import { AssignDtoAccountRoleEnum } from "@iliotech/generated-api-v3";

export const ManagerShareModalWrapper = observer(
  ({ onComplete }: { onComplete: () => void }) => {
    const { illioSelectedAccount } = useAuth();
    const store = useWorkgroupList();
    const visible = store.modalState.type === "managerShare";

    const managers = useUsers(
      illioSelectedAccount?.externalAccountId!,
      "MANAGER",
      undefined,
      illioSelectedAccount?.role !== "CUSTOMER"
    );

    const workgroup = useWorkgroupInfoV3(
      illioSelectedAccount?.externalAccountId!,
      store.modalState.name
    );

    const onSuccess = () => {
      workgroup.refetch();
      onComplete();
    };

    const managersList = managers.data?.data?.map((manager) => ({
      value: manager.identifier,
      label: manager.fullName,
    }));

    const currentManagers = workgroup.data?.data?.managers?.map((manager) => ({
      value: manager.identifier,
      label: manager.name,
    }));

    const assignItem = (id: string) => {
      return new Promise((resolve) =>
        assignToWorkgroup(
          illioSelectedAccount?.externalAccountId!,
          {
            userIdentifier: id,
            accountRole: AssignDtoAccountRoleEnum.Manager,
          },
          store?.modalState?.name
        ).then(resolve)
      );
    };

    const unAssignItem = (id: string) => {
      return new Promise((resolve) =>
        unAssignToWorkgroup(
          illioSelectedAccount?.externalAccountId!,
          {
            userIdentifier: id,
            accountRole: AssignDtoAccountRoleEnum.Manager,
          },
          store?.modalState?.name
        ).then(resolve)
      );
    };

    const onAssignManagers = async (items: string[]) => {
      // all items that are in items and are not contained in currentItems
      const itemsToAssign = items.filter(
        (item) =>
          !currentManagers?.find((currentItem) => currentItem.value === item)
            ?.value
      );
      // all items that are in currentItems and are not contained in items
      const itemsToUnassign = currentManagers
        ?.filter(
          (currentItem) => !items.find((item) => currentItem.value === item)
        )
        ?.map((item) => item.value)!;

      const promises = [
        ...itemsToAssign.map(assignItem),
        ...itemsToUnassign.map(unAssignItem),
      ];

      try {
        store.setModalLoading(true);
        await Promise.all(promises);
        onSuccess();
        store.resetModalState();
      } catch (e) {
        console.log(e);
        store.resetModalState();
      }
    };

    return (
      <ManagerShareModal
        fetching={managers.isLoading || workgroup.isLoading}
        loading={store.modalState.loading}
        visible={visible}
        handleClose={() => store.resetModalState()}
        onSave={onAssignManagers}
        name={store.modalState.name}
        initialManagers={currentManagers || []}
        managerList={managersList || []}
      />
    );
  }
);
