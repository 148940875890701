import {
  OTCInstrumentsApi,
  PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";

const api = new OTCInstrumentsApi(undefined, `${API_BASE}/api`);

export const createPrivateEquityInstrument = (
  instrument: PricedInstrumentCreateRequestPrivateEquitySystemInstrumentRequest,
  externalAccountId: string
) => {
  return api.addPrivateEquityInstrument(instrument, externalAccountId);
};
