import { API_BASE } from "../constants/constants";
import { PortfolioAndResourceGroupsWorkgroupsApi } from "@iliotech/generated-api-v3";

export const searchWorkgroups = (
  searchText: string,

  externalAccountId: string,
  includeEmpty?: boolean
) => {
  if (!externalAccountId) {
    throw new Error("externalAccountId is required");
  }

  const api = new PortfolioAndResourceGroupsWorkgroupsApi(
    undefined,
    `${API_BASE}/api`
  );

  return api.findWorkgroup(externalAccountId, searchText, includeEmpty);
};
