import {
  usePortfolio,
  useReferenceData,
  useSelectedAccountDetails,
  useSelectedAccountStatus,
} from "@iliotech/data-wire";
import { generatePath } from "react-router-dom";
import { useNavigate, useParams, useLocation } from "react-router";
import {
  BasicHeader,
  Blur,
  CreatePortfolioForm,
  PortfolioUsageExceeded,
  RoundButton,
} from "@iliotech/component-library";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import IllioDialogStructured from "@iliotech/component-library/src/components/IllioDialogStructured/IllioDialogStructured";
import * as React from "react";

export const CreatePortfolio = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [visible, setVisible] = React.useState(false);
  const { portfolioId = "" } = useParams();
  const portfolioInfo = usePortfolio(portfolioId!).data?.data;
  const { CurrencyBase } = useReferenceData();
  const accountStatus = useSelectedAccountStatus();
  const accountDetails = useSelectedAccountDetails();
  const usage = accountDetails.data?.data?.usage;

  const navigate = useNavigate();

  useEffect(() => {
    accountStatus.refetch();
    accountDetails.refetch();
  }, []);

  const onAfterSubmit = (response: any) => {
    console.log("HERE", response);
    if (response?.id) {
      console.log("INSIDE");

      accountDetails.refetch();
      const workgroupName = response.workgroupName;
      console.log(response);
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            (query.queryKey[0] === "workgroup-detail" &&
              // @ts-ignore
              query.queryKey?.[1]?.name === workgroupName) ||
            query.queryKey[0] === "workgroups"
          );
        },
      });
      // @ts-ignore
      navigate(
        generatePath(`/portfolio/:portfolioId/add-investments/onboarding`, {
          portfolioId: response?.id,
        })
      );
    }
  };

  const backGoesToSameDomain = location.key !== "default";

  const onBackPress = () => navigate(backGoesToSameDomain ? -1 : ("/" as any));

  const onBack = () => {
    if (portfolioId) {
      navigate("/workgroup/" + portfolioInfo?.workgroup, { replace: true });
    } else {
      setVisible(true);
    }
  };

  const loading =
    !accountStatus.data || accountStatus.isLoading || accountDetails.isLoading;

  const usageExceeded =
    usage && usage.activePortfolios >= usage.maxPortfolios && !portfolioId;

  return (
    <Blur>
      {visible && (
        <IllioDialogStructured
          contentStyle={{ maxWidth: 500 }}
          header={<p className={"element-title"}>Close window</p>}
          footer={
            <>
              <RoundButton
                fillMode={"outline"}
                style={{ maxWidth: 140 }}
                onClick={() => setVisible(false)}
              >
                No, don't close
              </RoundButton>
              <RoundButton onClick={onBackPress} style={{ maxWidth: 140 }}>
                Yes, close window
              </RoundButton>
            </>
          }
        >
          <p>
            By closing this window you will return to the previous page. Your
            portfolio will still not be saved. Are you sure?
          </p>
        </IllioDialogStructured>
      )}
      <BasicHeader
        hideLogo={false}
        title={"Create Portfolio"}
        hideProfile={true}
        hideLogOff={true}
      />

      {/*<div>*/}
      {/*  {usage?.activePortfolios} / {usage?.maxPortfolios}*/}
      {/*</div>*/}
      <CreatePortfolioForm
        t={t}
        loading={loading}
        allPositions={CurrencyBase!}
        onBackPress={onBack}
        onAfterSubmit={onAfterSubmit}
        allCurrencies={
          Object.entries(CurrencyBase ?? {}).map(([code, data]) => code) ?? []
        }
      />
      {usageExceeded && <PortfolioUsageExceeded />}
    </Blur>
  );
};
