import * as React from "react";
import { AccountSummaryDTO } from "@iliotech/generated-api-v3";
import AccountCard from "../AccountCard/AccountCard";
import s from "./AccountList.module.scss";
interface IProps {
  accountList: AccountSummaryDTO[];
  onClick: (account: AccountSummaryDTO) => void;
}

export const AccountList = ({ accountList, onClick }: IProps) => {
  return (
    <div className={s.surround}>
      {accountList.map((item) => (
        <AccountCard
          onClick={() => onClick(item)}
          account={item}
          key={item.externalAccountId}
        />
      ))}
    </div>
  );
};

export default AccountList;
