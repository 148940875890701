/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { PriceResponse } from '../../aggregator';
/**
 * PriceControllerApi - axios parameter creator
 * @export
 */
export const PriceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} instrumentId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceByInstrumentIdForDate: async (instrumentId: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instrumentId' is not null or undefined
            assertParamExists('getPriceByInstrumentIdForDate', 'instrumentId', instrumentId)
            const localVarPath = `/v1/price/instrument/{instrumentId}`
                .replace(`{${"instrumentId"}}`, encodeURIComponent(String(instrumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {string} date 
         * @param {boolean} [splitAdjusted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceForDate: async (sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', date: string, splitAdjusted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('getPriceForDate', 'sourceId', sourceId)
            // verify required parameter 'sourceData' is not null or undefined
            assertParamExists('getPriceForDate', 'sourceData', sourceData)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getPriceForDate', 'date', date)
            const localVarPath = `/v1/price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sourceId !== undefined) {
                localVarQueryParameter['sourceId'] = sourceId;
            }

            if (sourceData !== undefined) {
                localVarQueryParameter['sourceData'] = sourceData;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (splitAdjusted !== undefined) {
                localVarQueryParameter['splitAdjusted'] = splitAdjusted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {string} from 
         * @param {string} to 
         * @param {boolean} [splitAdjusted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricesForDateRange: async (sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', from: string, to: string, splitAdjusted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('getPricesForDateRange', 'sourceId', sourceId)
            // verify required parameter 'sourceData' is not null or undefined
            assertParamExists('getPricesForDateRange', 'sourceData', sourceData)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getPricesForDateRange', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getPricesForDateRange', 'to', to)
            const localVarPath = `/v1/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sourceId !== undefined) {
                localVarQueryParameter['sourceId'] = sourceId;
            }

            if (sourceData !== undefined) {
                localVarQueryParameter['sourceData'] = sourceData;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (splitAdjusted !== undefined) {
                localVarQueryParameter['splitAdjusted'] = splitAdjusted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceControllerApi - functional programming interface
 * @export
 */
export const PriceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} instrumentId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceByInstrumentIdForDate(instrumentId: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPriceByInstrumentIdForDate(instrumentId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {string} date 
         * @param {boolean} [splitAdjusted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceForDate(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', date: string, splitAdjusted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPriceForDate(sourceId, sourceData, date, splitAdjusted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {string} from 
         * @param {string} to 
         * @param {boolean} [splitAdjusted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPricesForDateRange(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', from: string, to: string, splitAdjusted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPricesForDateRange(sourceId, sourceData, from, to, splitAdjusted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriceControllerApi - factory interface
 * @export
 */
export const PriceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} instrumentId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceByInstrumentIdForDate(instrumentId: string, date?: string, options?: any): AxiosPromise<PriceResponse> {
            return localVarFp.getPriceByInstrumentIdForDate(instrumentId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {string} date 
         * @param {boolean} [splitAdjusted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceForDate(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', date: string, splitAdjusted?: boolean, options?: any): AxiosPromise<number> {
            return localVarFp.getPriceForDate(sourceId, sourceData, date, splitAdjusted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {string} from 
         * @param {string} to 
         * @param {boolean} [splitAdjusted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricesForDateRange(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', from: string, to: string, splitAdjusted?: boolean, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getPricesForDateRange(sourceId, sourceData, from, to, splitAdjusted, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceControllerApi - object-oriented interface
 * @export
 * @class PriceControllerApi
 * @extends {BaseAPI}
 */
export class PriceControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} instrumentId 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceControllerApi
     */
    public getPriceByInstrumentIdForDate(instrumentId: string, date?: string, options?: AxiosRequestConfig) {
        return PriceControllerApiFp(this.configuration).getPriceByInstrumentIdForDate(instrumentId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceId 
     * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
     * @param {string} date 
     * @param {boolean} [splitAdjusted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceControllerApi
     */
    public getPriceForDate(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', date: string, splitAdjusted?: boolean, options?: AxiosRequestConfig) {
        return PriceControllerApiFp(this.configuration).getPriceForDate(sourceId, sourceData, date, splitAdjusted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceId 
     * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
     * @param {string} from 
     * @param {string} to 
     * @param {boolean} [splitAdjusted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceControllerApi
     */
    public getPricesForDateRange(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', from: string, to: string, splitAdjusted?: boolean, options?: AxiosRequestConfig) {
        return PriceControllerApiFp(this.configuration).getPricesForDateRange(sourceId, sourceData, from, to, splitAdjusted, options).then((request) => request(this.axios, this.basePath));
    }
}
