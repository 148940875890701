import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import {
  API_ERROR_STRING,
  getCellWarning,
} from "@iliotech/data-wire/src/processing/transactionUpload/getWarningText";
import StatusIconRenderer from "../../../PositionEntryCellRenderer/StatusIconRenderer/StatusIconRenderer";
import { Tooltip } from "@progress/kendo-react-tooltip";
import s from "./Cells.module.scss";
import { ITableRow } from "@iliotech/data-wire";
import { observer } from "mobx-react-lite";

const StatusCellWrapper = ({ index }: { index: number }) => {
  const store = useTransactionUploadV2();
  const dataItem = store.equities.tableData[index];

  // we destructure the object as we need to access almost all of his fields in this cell.
  // we don't really benefit from the observer pattern
  return <StatusCell dataItem={{ ...dataItem }} />;
};

const StatusCell = ({ dataItem }: { dataItem: ITableRow }) => {
  const store = useTransactionUploadV2();

  const status = React.useMemo(() => getCellWarning(dataItem), [dataItem]);

  const revalidateRow = () => {
    store.equities.updateTableRow({
      ...dataItem,
      apiError: false,
      apiErrorString: "",
    });
  };

  if (status.text.length > 0) {
    return (
      <div
        onClick={
          status.text.includes(API_ERROR_STRING) ? revalidateRow : undefined
        }
        className={s.warningWrapper}
      >
        <Tooltip openDelay={100} position="right" anchorElement={"target"}>
          <StatusIconRenderer title={status.text} status={status.icon} />
        </Tooltip>
      </div>
    );
  }
  return <StatusIconRenderer title={""} status={status.icon} />;
};

export default observer(StatusCellWrapper);
