import * as React from "react";
import s from "./RadioGroup.module.scss";
import {
  RadioGroup as KendoRadioGroup,
  RadioGroupProps as KendoRadioGroupProps,
} from "@progress/kendo-react-inputs";
import InputWrapper from "../InputWrapper/InputWrapper";

export interface RadioGroupProps extends KendoRadioGroupProps {
  error?: string;
  label?: string;
  name: string;
}

export const RadioGroup = ({
  label,
  name,
  data,
  // value,
  ...restProps
}: RadioGroupProps) => {
  return (
    <div className={"illio-input"}>
      <InputWrapper label={label} name={name}>
        <KendoRadioGroup
          className={s.radio}
          // value={value || defaultValue}
          data={data?.map((item) => ({
            ...item,
            "data-cy": "round-toggle-" + item?.label?.toLowerCase(),
          }))}
          {...restProps}
        />
      </InputWrapper>
    </div>
  );
};

export default RadioGroup;
