import { PortfolioDataV3Dto } from "@iliotech/generated-api-v3";
import React from "react";
import s from "./PortfolioCard.module.scss";
import Settings from "./assets/settings.svg";
import { classNames } from "@progress/kendo-react-common";
import { useNavigate } from "react-router";
import Delete from "./assets/delete.svg";

interface IProps extends PortfolioDataV3Dto {
  valueFormatter: (v: number) => string;
  onDeletePortfolio: (id: string) => void;
  openSettings: (id: string) => void;
}

export const PortfolioCard = (props: IProps) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/portfolio/" + props.id);
  };
  const profitPositive = props.profitAndLoss > 0;
  const valuePositive = props.totalWealth > 0;

  return (
    <article onClick={onClick} className={s.container}>
      <div className={s.row}>
        <span className={s.title}>{props.name}</span>
        <div className={s.icons}>
          <img
            src={Settings}
            onClick={(e) => {
              e.stopPropagation();
              props.openSettings(props.id);
            }}
          />
          <img
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              props.onDeletePortfolio(props.id);
            }}
          />
        </div>
      </div>
      <div style={{ height: 8 }} />
      <div className={s.row}>
        <div className={s.rowEnd}>
          <div className={classNames(s.column, profitPositive && s.positive)}>
            <div className={s.label}>Profit & Loss</div>
            <div className={s.value}>
              {props.valueFormatter(props.profitAndLoss)}
            </div>
          </div>
          <div className={classNames(s.column, valuePositive && s.positive)}>
            <div className={s.label}>Value</div>
            <div className={s.value}>
              {props.valueFormatter(props.totalWealth)}
            </div>
          </div>
        </div>
        <div className={s.assets}>
          {props.allocation?.["AssetClass"].map((asset) => (
            <div className={s.allocation}>
              <span>
                <span className={s.percentage}>{asset.percentage}% </span>
                {asset.name}
              </span>
            </div>
          ))}
        </div>
        <div style={{ height: 24 }} />
      </div>
    </article>
  );
};
