"use client";
import React, { createContext, PropsWithChildren } from "react";

type AuthValues = {
  token: string;
  environment: "dev" | "stage" | "test" | "prod" | "mock";
};

const TokenContext = createContext<AuthValues | null>(null);

export const AuthContext = ({
  children,
  token,
  environment,
}: PropsWithChildren<AuthValues>) => {
  return (
    <TokenContext.Provider value={{ token, environment }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => {
  const context = React.useContext(TokenContext);
  if (context === null) {
    throw new Error("useToken must be used within a AuthContext");
  }

  return context;
};
