import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { feeTypeValidator } from "@iliotech/data-wire";
import * as React from "react";
import { Autocomplete, AutocompleteProps } from "../Autocomplete/Autocomplete";
interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  feesList?: { data: { data: any[] } };
  currencyCode: string;
}

export const FeeTypeSelectField = ({
  errors,
  name = "feeType",
  label = "Fee Type",
  currencyCode,
  feesList,
  ...rest
}: IOwnProps) => {
  const memoData = React.useMemo(
    () =>
      (feesList?.data?.data ?? []).filter(
        (item) => item.currencyCode === currencyCode
      ),
    [feesList, currencyCode]
  );

  // console.log(feesList?.data?.data);
  // console.log(memoData)

  if (!memoData) return null;
  return (
    <Field
      component={Autocomplete}
      label={label}
      name={name}
      dataItemKey={"label"}
      textField={"label"}
      error={errors?.["label"]}
      placeholder={
        !currencyCode ? "Please select a currency first" : "Enter Fee type"
      }
      filterData={(filter?: string) => {
        return memoData
          ?.filter((item: any) =>
            (item?.label ?? "")
              ?.toLowerCase()
              .startsWith(filter?.toLowerCase() ?? "")
          )
          ?.map((feeElement: any) => ({
            ...feeElement,
            feeType: feeElement?.label,
          }));
      }}
      validator={feeTypeValidator}
      data={memoData}
      {...rest}
    />
  );
};
