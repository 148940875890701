import {
  useGlobalCurrency,
  useNetWorthBreakdown,
  useSnapshot,
} from "@iliotech/data-wire";
import { useParams } from "react-router";
import { ComparatorWrapper } from "../ComparatorWrapper";
import { NetWorthPie } from "src/components/PieTable/NetWorthPie";
import s from "../shared.module.scss";
import { PieTable } from "src/main";
import { PieTableEntry } from "@iliotech/types";
interface IProps {
  valueFormatter: (v: number) => string;
  virtualId: string;
  basePortfolioId: string;
}
export const NetWorthComparator = ({
  valueFormatter,
  basePortfolioId,
  virtualId,
}: IProps) => {
  const { data: baseSnapshot, isLoading: baseLoading } = useSnapshot(
    basePortfolioId!
  );
  const { data: virtualSnapshot, isLoading: virtualLoading } = useSnapshot(
    virtualId!
  );

  const isSamePie = (base?: PieTableEntry[], virtual?: PieTableEntry[]) => {
    if (base?.length !== virtual?.length) return false;
    return base?.every((baseItem) => {
      const virtualItem = virtual?.find(
        (virtualItem) => virtualItem.label === baseItem.label
      );
      if (!virtualItem) return false;
      return virtualItem.value === baseItem.value;
    });
  };

  const { pieTableData: basePie } = useNetWorthBreakdown(
    baseSnapshot?.data,
    true
  );
  const { pieTableData: virtualPie } = useNetWorthBreakdown(
    virtualSnapshot?.data,
    true
  );

  return (
    <ComparatorWrapper
      loading={baseLoading || virtualLoading}
      noData={!baseSnapshot?.data || !baseSnapshot?.data}
    >
      <h3>Net Worth</h3>
      <article className={s.card}>
        <div className={s.half}>
          <div className={s.titleWrap}>
            <b className={s.title}>Your Portfolio</b>
          </div>

          <PieTable vertical data={basePie} valueFormatter={valueFormatter} />
        </div>
        <div className={s.half}>
          <div className={s.titleWrap}>
            <b className={s.title}>What if Scenario</b>
          </div>

          <PieTable
            vertical
            data={virtualPie}
            valueFormatter={valueFormatter}
          />
        </div>
      </article>
    </ComparatorWrapper>
  );
};
