import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { EnhancedInstrumentSearchResult } from "@iliotech/generated-api-v3";
import cn from "classnames";
import s from "./Cells.module.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { observer } from "mobx-react-lite";

const NameCell = ({ index }: { index: number }) => {
  const store = useTransactionUploadV2();

  const searchResults = (store.equities.getField("searchResults", index + "") ||
    []) as EnhancedInstrumentSearchResult[];
  const selectedInstrument = store.equities.getField(
    "selectedInstrument",
    index + ""
  ) as EnhancedInstrumentSearchResult;

  const settlementCurrency = store.equities.getField(
    "settlementCurrency",
    index + ""
  );
  const defaultValue = {
    name: searchResults.length + " found",
    code: "",
    currencyCode: "",
  };
  const trimText = (text: string) =>
    text.length > 20 ? text.substring(0, 20) + "..." : text;

  const selectedInstrumentName = (instrument: EnhancedInstrumentSearchResult) =>
    instrument?.name
      ? `${instrument?.name} - ${instrument?.code} (${instrument?.currency})`
      : "null";

  const parsedSearchResults = searchResults.map((item) => ({
    ...item,
    name: selectedInstrumentName(item),
  }));

  const onChangeSelectedInstrument = (event: DropDownListChangeEvent) => {
    store.equities.updateTableRowByIndex(
      "selectedInstrument",
      event.value,
      index + ""
    );

    store.equities.updateTableRowByIndex(
      "currency",
      event.value?.currency,
      index + ""
    );

    store.equities.updateTableRowByIndex(
      "status",
      {
        status: event.value?.instrumentStatus,
        sourceId: event.value?.sourceId?.sourceId,
      },
      index + ""
    );

    if (!settlementCurrency) {
      store.equities.updateTableRowByIndex(
        "settlementCurrency",
        event.value?.currency,
        index + ""
      );
    }
  };

  if (searchResults.length > 1) {
    return (
      <DropDownList
        popupSettings={{ popupClass: "illio-combo" }}
        className={cn(!selectedInstrument && s.dropdownWarning)}
        value={selectedInstrument || defaultValue}
        onChange={onChangeSelectedInstrument}
        textField={"name"}
        data={parsedSearchResults}
      />
    );
  }

  return (
    <Tooltip>
      <div title={selectedInstrumentName(selectedInstrument!)}>
        {trimText(selectedInstrumentName(selectedInstrument!))}
      </div>
    </Tooltip>
  );
};

export default observer(NameCell);
