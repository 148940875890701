import * as React from "react";
import cn from "classnames";
import s from "./AddNewButton.module.scss";
import { IoMdAdd } from "react-icons/io";
import { useEffect } from "react";

interface IProps {
  setOpen: (s: boolean) => void;
  openModal: () => void;
  text: string;
}

export const AddNewButton = ({ setOpen, openModal, text }: IProps) => {
  return (
    <li
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
        openModal();
      }}
      className={cn("k-list-item", s.addNewButton)}
    >
      <IoMdAdd size={"1.2rem"} style={{ marginRight: 5 }} />
      <span>{text || "Add new"}</span>
    </li>
  );
};
