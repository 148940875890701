import * as React from "react";
import s from "./RoundButton.module.scss";
import { ButtonProps, Button } from "@progress/kendo-react-buttons";
import cn from "classnames";
import { Loader } from "@progress/kendo-react-indicators";
import { debounce } from "lodash";

interface IProps extends ButtonProps {
  loading?: boolean;
}

export const RoundButton = ({ className, loading, ...restProps }: IProps) => {
  const debouncedClick = debounce((ev) => restProps?.onClick?.(ev), 100);
  return (
    <Button
      {...restProps}
      onClick={debouncedClick}
      className={cn(s.roundbutton, restProps.disabled && s.disabled, className)}
    >
      {loading ? (
        <Loader
          className={restProps.fillMode === "outline" ? "" : "inverse"}
          size={"small"}
          type={"infinite-spinner"}
        />
      ) : (
        restProps.children
      )}
    </Button>
  );
};

export default RoundButton;
