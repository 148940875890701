import { PieTableEntry } from "@iliotech/types";
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";

interface IProps {
  withAnimations?: boolean;
  data: PieTableEntry[];
}
export const NetWorthPie = ({ withAnimations, data }: IProps) => {
  return (
    //@ts-ignore
    <Chart
      style={{ maxHeight: 300, maxWidth: 220, margin: "auto" }}
      onSeriesClick={({ dataItem }) => dataItem?.onClick?.()}
      transitions={withAnimations}
    >
      <ChartArea background={"none"} margin={0} />
      <ChartTooltip
        render={(context: any) => {
          const { category, series, value } = context?.point || context;
          return (
            <div>
              <div>{category}</div>
              <div>
                {(value * 100).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
                })}
                %
              </div>
              {/*<pre style={{fontSize: 12}}>{JSON.stringify({category, series, value}, null, 2)}</pre>*/}
            </div>
          );
        }}
      />
      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={data.filter((item) => !item.isTotal)}
          categoryField="label"
          field="percentShare"
          holeSize={60}
          padding={0}
          labels={{ position: "below" }}
        ></ChartSeriesItem>
      </ChartSeries>
      <ChartLegend visible={false} />
    </Chart>
  );
};
