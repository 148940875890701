import * as React from "react";
import { Skeleton } from "@progress/kendo-react-indicators";

interface IProps {}

const TableLoading = ({}: IProps) => {
  return (
    <div>
      {[...new Array(10)].map((t, i) => (
        <Skeleton
          key={i}
          shape={"text"}
          style={{
            width: "100%",
            height: 25,
            marginTop: 5,
          }}
        />
      ))}
    </div>
  );
};

export default TableLoading;
