import React, { useState } from "react";

export const ConsumerInsightContext =
  React.createContext<
    ReturnType<typeof useConsumerInsightContextHook> | undefined
  >(undefined);

const useConsumerInsightContextHook = () => {
  const [toggled, setToggled] = useState(false);

  return { toggled, setToggled };
};

export const ConsumerInsightContextProvider = ({
  children,
}: React.PropsWithChildren<any>) => {
  const { toggled, setToggled } = useConsumerInsightContextHook();
  return (
    <ConsumerInsightContext.Provider value={{ toggled, setToggled }}>
      {children}
    </ConsumerInsightContext.Provider>
  );
};

export const useConsumerInsightContext = () => {
  const context = React.useContext(ConsumerInsightContext);
  if (typeof context === "undefined") {
    throw new Error(
      "Consumer insight context can only be accessed from within it's provider"
    );
  }
  return context;
};
