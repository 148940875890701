import * as React from "react";
import s from "./Autocomplete.module.scss";
import cn from "classnames";

import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
} from "@progress/kendo-react-dropdowns";
import InputWrapper from "../../InputWrapper/InputWrapper";
import { useEffect } from "react";
// import Timeout = NodeJS.Timeout;

export interface AutocompleteProps extends ComboBoxProps {
  name: string;
  filterData?: (filter: string) => any[] | Promise<any[]>;
  isAsync?: boolean;
  label?: string;
  error?: string;
  visited?: boolean;
  illioInputProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}

export const Autocomplete = ({
  name,
  data,
  filterData,
  isAsync,
  label,
  error,
  visited,
  illioInputProps,
  ...restProps
}: AutocompleteProps) => {
  const isError = error && visited;
  const [localData, setLocalData] = React.useState(data);
  const [insertMore, setInsertMore] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const pendingRequest = React.useRef<any>();
  const input = React.useRef<any>();

  React.useEffect(() => {
    if (data?.length) {
      setLocalData(data.slice());
    }
  }, [JSON.stringify(data)]);

  const debouncedFetch = async (search: string) => {
    const fetchData = filterData as (filter: string) => Promise<any[]>;
    setLoading(true);
    const data = await fetchData(search);
    setLocalData(data);
    setLoading(false);
  };

  const filterChange = async (event: ComboBoxFilterChangeEvent) => {
    const search = event.filter.value;
    if (search.length < 1 && isAsync) {
      setLocalData([]);
      setInsertMore(true);
      return;
    }
    if (isAsync) {
      if (pendingRequest.current) {
        clearTimeout(pendingRequest.current);
      }
      pendingRequest.current = setTimeout(() => {
        debouncedFetch(search);
        setInsertMore(false);
      }, 500);
    } else {
      const filter = filterData as (filter: string) => any[];
      if (search !== "") {
        setLocalData(filter?.(search));
        setInsertMore(false);
      } else {
        setLocalData(data?.slice());
        setInsertMore(false);
      }
    }
  };

  useEffect(() => {
    if (restProps?.opened) {
      input.current?.focus?.();
    }
  }, [restProps?.opened]);

  return (
    <div className={"illio-input"} key={name} {...illioInputProps}>
      <InputWrapper error={isError ? error : ""} label={label} name={name}>
        <ComboBox
          ref={input}
          className={cn(s.autocomplete)}
          popupSettings={{
            appendTo: document.getElementById("app"),
            popupClass: "illio-combo",
          }}
          id={name}
          listNoDataRender={() => (
            <span className={s.noData}>
              {loading
                ? "Searching..."
                : insertMore
                ? "Please enter a character to search."
                : "Your query did not produce any results."}
            </span>
          )}
          valid={!isError}
          size={"medium"}
          rounded={"large"}
          data={localData}
          loading={loading}
          onFilterChange={filterChange}
          filterable={true}
          {...restProps}
        />
      </InputWrapper>
    </div>
  );
};

export default Autocomplete;
