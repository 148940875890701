import { useState } from "react";
import s from "./SeamlessToggle.module.scss";
import classNames from "classnames";
interface IProps {
  activeIndex?: number;
  values: string[];
  disabled?: boolean;
  onChange: (value: string) => void;
}
export const SeamlessToggle = ({
  activeIndex,
  values,
  onChange,
  disabled,
}: IProps) => {
  const [active, setActive] = useState(activeIndex || 0);

  const onClick = (value: string, index: number) => {
    if (disabled) return;
    setActive(index);
    onChange(value);
  };
  return (
    <div className={classNames(s.container, disabled && s.disabled)}>
      {values.map((value, index) => (
        <div
          key={index}
          className={classNames(active === index && s.active, s.itemText)}
          onClick={() => onClick(value, index)}
        >
          <p>{value}</p>
        </div>
      ))}
    </div>
  );
};
