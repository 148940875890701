import React, { useEffect } from "react";
import cn from "classnames";
import s from "./SubscriptionChooser.module.css";

import {
  SubscriptionOption,
  Loader,
  BasicHeader,
  Blur,
} from "@iliotech/component-library";
import { useAuth } from "./AuthContext";
import { BillingOption, sendTracking, useBilling } from "@iliotech/data-wire";
import ReactGA from "react-ga4";
import { useLogout } from "../../hooks/useLogout";

export const SubscriptionChooser = () => {
  const { illioSelectedAccount, availableAccounts, selectIllioAccount } =
    useAuth();
  const logOut = useLogout();
  const [currency, setCurrency] = React.useState("USD");
  const [interval, setInterval] = React.useState<"month" | "year">("month");
  const hasMoreAccounts = availableAccounts?.length! > 1;
  const { createSubscriptionCheckoutSession, availableOptions, loading } =
    useBilling(currency, interval);
  const [working, setWorking] = React.useState(false);
  const accountId = illioSelectedAccount?.externalAccountId;
  const goToStripe = (option: BillingOption) => {
    sendTracking("user-redirected-to-stripe", "sign-up-flow");
    createSubscriptionCheckoutSession(option, accountId!)?.catch(() => {
      setWorking(false);
      sendTracking("user-redirected-to-stripe-error", "sign-up-flow");
    });
  };

  const affiliateReferrer = window.Rewardful?.affiliate?.name;

  useEffect(() => {
    console.log("subscription chooser", "sign-up-flow");
    ReactGA.send({
      hitType: "pageview",
      title: "Illio - Subscription chooser",
    });
  }, []);

  const showLoading = working || loading;
  const onSelectAccounts = () => {
    selectIllioAccount(null);
  };

  return (
    <Blur>
      <BasicHeader
        title={"Start your subscription"}
        hideProfile
        hideLogo={!!hasMoreAccounts}
        logOut={logOut}
        onSelectAccount={hasMoreAccounts ? onSelectAccounts : undefined}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/*<h3>Start your subscription</h3>*/}
        <article data-cy={"package"} style={{ maxWidth: 500 }}>
          <p>
            Start, risk free, with a one month trial.
            <br />
            <br />
            You won't be charged until the end of your trial. You can cancel at
            any time before your billing date.
          </p>

          {showLoading && <Loader fullScreen={false} />}

          {!!affiliateReferrer && (
            <strong>Referred to illio by {affiliateReferrer}</strong>
          )}

          {!showLoading && (
            <div className={cn(s.productList)}>
              {availableOptions.map((option) => {
                return (
                  <SubscriptionOption
                    key={option.name}
                    billingOption={option}
                    onSelect={() => {
                      setWorking(true);
                      goToStripe(option);
                    }}
                  />
                );
              })}
            </div>
          )}
        </article>
      </div>
    </Blur>
  );
};
