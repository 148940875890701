export const notEmptyValidator = (value: string | number | any) => {
  return (!!value || value === 0) && ((value + "")?.length ?? 0) > 0
    ? ""
    : "Please enter a valid value";
};

export const yearValidator = (value: string | number | any) => {
  const regex = /^(19[5-9]\d|20[0-4]\d|2050)$/;
  return regex.test(value) ? "" : "Please enter a valid value";
};

export const positiveOrEmptyValidator = (value: string | number | any) => {
  if (!value || value?.length === 0 || value > 0) {
    return "";
  }
  return "Please enter a positive value";
};

export const positiveValidator = (
  value: string | number | any,
  optional?: boolean
) => {
  if (optional && !value) return "";
  return (!!value || value === 0) &&
    ((value + "")?.length ?? 0) > 0 &&
    value >= 0
    ? ""
    : "Please enter a valid value";
};

export const bondsPriceValidator = (value: string | number | any) => {
  return (!!value || value === 0) &&
    ((value + "")?.length ?? 0) > 0 &&
    value >= 0 &&
    value <= 1000
    ? ""
    : "Enter price as a %age";
};

export const notEmptyDropDown = (value: string | number | any) => {
  return (!!value || value === 0) && ((value + "")?.length ?? 0) > 0
    ? ""
    : "Select from the list below";
};

export const expiryYearValidator = (value: any, validDates: string[]) => {
  return validDates.includes(value) ? "" : "Please enter a valid expiry year";
};

export const feeTypeValidator = (value: any) => {
  return value?.instrumentSourceId?.sourceId?.length > 0
    ? ""
    : "Please enter a valid fee type";
};

export const countryValidator = (value: any) => {
  return value?.code?.length > 0 ? "" : "Please enter a valid country";
};

export const optionValidator = (value: any) => {
  return value?.code?.length > 0 ? "" : "Please enter a valid stock ticker";
};

export const positionValidator = (value: any) => {
  return value?.code?.length > 0 ? "" : "Please enter a valid position";
};

export const otcOptionValidator = (value: any) => {
  return value?.name?.length > 0 ? "" : "Please enter a valid position";
};

export const custodianValidator = (value: any) => {
  return value?.code?.length > 0 ? "" : "Please select an account";
};

export const currencyCodeValidator = (value: any) => {
  // console.log(value);
  return value?.length > 0 ? "" : "Please enter a valid currency";
};
export const enumDisplayValidator = (value: any) => {
  return (value?.code || "").length > 0 ? "" : "Please enter a valid entry";
};

export const workgroupEnumValidator = (value: {
  code: number;
  label: string;
}) => {
  const s = value?.label;
  // if(!s) return;
  if ((s?.substr(0, 1) || "") === " ") return "Cannot start with a space";
  if ((s?.replaceAll(" ", "").length ?? 0) < 0) return "Must not be empty";
  if ((s?.length ?? 0) > 50) return "Must be less than 50 characters";
  // alphanumeric, spaces, underscores, and dash characters are allowed
  const reg = /^([\w \d\s\-])+$/g;
  if (!s?.match(reg)) {
    return "Group name may only contain letters, numbers, underscores, dashes and spaces";
  }
};

export const optionPositionValidator = (
  value: any,
  priceErrorString?: string
) => {
  return priceErrorString
    ? priceErrorString
    : value?.name?.length > 0
    ? ""
    : "Select option";
};

export const dateValidator = (
  value: Date,
  minDate: Date,
  optional?: boolean
) => {
  if (optional && !value) return "";
  return typeof value?.getMonth === "function" &&
    value.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) &&
    value >= minDate
    ? ""
    : "Please enter a valid date";
};

export const futureDateValidator = (
  value: Date,
  minDate: Date,
  optional?: boolean
) => {
  if (optional && !value) return "";
  return typeof value?.getMonth === "function" && value >= minDate
    ? ""
    : "Please enter a valid date";
};

export const emailValidator = (value: string) => {
  return value
    ?.toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    ? ""
    : "Please enter a valid email";
};

export const workgroupNameValidator = (s: string) => {
  // if(!s) return;
  if ((s?.substr(0, 1) || "") === " ") return "Cannot start with a space";
  if ((s?.replaceAll(" ", "").length ?? 0) < 3)
    return "Must be greater than 3 characters";
  if ((s?.length ?? 0) > 50) return "Must be less than 50 characters";
  // alphanumeric, spaces, underscores, and dash characters are allowed
  const reg = /^([\w \d\s\-])+$/g;
  if (!s?.match(reg)) {
    return "Group name may only contain letters, numbers, underscores, dashes and spaces";
  }
};
export const portfolioNameValidator = (s: string) => {
  // if(!s) return;
  if ((s?.substr(0, 1) || "") === " ") return "Cannot start with a space";
  if ((s?.length ?? 0) < 0) return "Must not be empty";
  if ((s?.length ?? 0) > 50) return "Must be less than 50 characters";
  // alphanumeric, spaces, underscores, and dash characters are allowed
  const reg = /^([\w \d\s\-])+$/g;
  if (!s?.match(reg)) {
    return "Portfolio name may only contain letters, numbers, underscores, dashes and spaces";
  }
};

export const passwordValidator = (s: string) => {
  const reg =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,64}$/g;
  return s?.match(reg)
    ? ""
    : "Your password must contain at least one lowercase letter, one uppercase letter, a number and a special character";
};

export const matchValidator = (a: string) => (b: string) =>
  a === b ? "" : "Your password's don't match";

export const textValidatorWrapper =
  ({
    minLength = -1,
    maxLength = 50,
  }: {
    minLength?: number;
    maxLength?: number;
  }) =>
  (value: string) => {
    let result = "";

    if ((value?.length ?? 0) < minLength) {
      result = `Please enter more than ${minLength} characters`;
    }

    if ((value?.length ?? 0) > maxLength) {
      result = `Please enter less than ${maxLength} characters`;
    }

    if ((value?.length ?? 0) < minLength && !value?.match(/[a-z0-9\s_]+/i)) {
      result = "Only alphanumeric characters, space and underscore are allowed";
    }

    return result;
  };
