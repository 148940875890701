import * as React from "react";
import { EnhancedInstrumentSearchResult } from "@iliotech/generated-api-v3";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { Typography } from "@progress/kendo-react-common";
import cn from "classnames";
import s from "./Cells.module.scss";
import { observer } from "mobx-react-lite";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { usePositionUploadV2 } from "@iliotech/data-wire/src/stores/PositionsUpload";

const TickerCell = ({ index }: { index: number }) => {
  const store = usePositionUploadV2();
  const selectedInstrument = store.position.getField(
    "selectedInstrument",
    index + ""
  ) as EnhancedInstrumentSearchResult;
  const ticker = store.position.getField("ticker", index + "");
  const isError = !selectedInstrument?.code;
  return (
    <Tooltip position={"right"}>
      <Typography.p
        title={
          !selectedInstrument?.code
            ? "No instrument found. Make sure you selected the right asset class"
            : undefined
        }
        className={cn(s.link, isError && s.error)}
        onClick={() =>
          store.position.setInstrumentModal({
            visible: true,
            id: index + "",
          })
        }
      >
        {selectedInstrument?.code || `Resolve instrument [${ticker}]`}
      </Typography.p>
    </Tooltip>
  );
};

export default observer(TickerCell);
