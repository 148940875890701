import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { textValidatorWrapper } from "@iliotech/data-wire";
import * as React from "react";
import { NumericInput, NumericInputProps } from "../NumericInput/NumericInput";

interface IOwnProps extends Omit<NumericInputProps, "name" | "label"> {
  name?: string;
  label?: string | null;
  validator?: (value: string) => string;
}

const validator = textValidatorWrapper({
  maxLength: 200,
});

export const CommissionField = ({
  name = "commission",
  label = "Commission",
  ...rest
}: IOwnProps) => {
  return (
    <Field
      name={name}
      component={NumericInput}
      label={label}
      placeholder={"Optional"}
      validator={validator}
      {...rest}
    />
  );
};
