/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { ImportResponseTradeImportWithSearchResult } from '../../aggregator';
// @ts-ignore
import { TemplateRows } from '../../aggregator';
/**
 * PositionUploadApi - axios parameter creator
 * @export
 */
export const PositionUploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TemplateRows} templateRows 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        uploadPosition: async (templateRows: TemplateRows, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateRows' is not null or undefined
            assertParamExists('uploadPosition', 'templateRows', templateRows)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('uploadPosition', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/positionUpload`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateRows, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PositionUploadApi - functional programming interface
 * @export
 */
export const PositionUploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PositionUploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TemplateRows} templateRows 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async uploadPosition(templateRows: TemplateRows, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportResponseTradeImportWithSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPosition(templateRows, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PositionUploadApi - factory interface
 * @export
 */
export const PositionUploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PositionUploadApiFp(configuration)
    return {
        /**
         * 
         * @param {TemplateRows} templateRows 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        uploadPosition(templateRows: TemplateRows, portfolioId: string, options?: any): AxiosPromise<ImportResponseTradeImportWithSearchResult> {
            return localVarFp.uploadPosition(templateRows, portfolioId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PositionUploadApi - object-oriented interface
 * @export
 * @class PositionUploadApi
 * @extends {BaseAPI}
 */
export class PositionUploadApi extends BaseAPI {
    /**
     * 
     * @param {TemplateRows} templateRows 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PositionUploadApi
     */
    public uploadPosition(templateRows: TemplateRows, portfolioId: string, options?: AxiosRequestConfig) {
        return PositionUploadApiFp(this.configuration).uploadPosition(templateRows, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }
}
