import { Field } from "@progress/kendo-react-form";
import { Input, InputProps } from "../Input/Input";
import { textValidatorWrapper } from "@iliotech/data-wire";
import * as React from "react";

interface IOwnProps extends Omit<InputProps, "name" | "label"> {
  name?: string;
  label?: string | null;
}

const validator = textValidatorWrapper({
  maxLength: 200
})

export const NotesField = ({
  name = "notes",
  label = "Notes",
  ...rest
}: IOwnProps) => {
  return (
    <Field
      name={name}
      component={Input}
      label={label}
      placeholder={"Optional"}
      validator={validator}
      {...rest}
    />
  );
};
