import React from "react";
import { useAuth } from "@iliotech/data-wire";
import { LandingPage } from "../LandingPage/LandingPage";
import { LandingPortfoliosPage } from "../LandingPage/LandingPortfoliosPage";
import { Navigate } from "react-router";

export const Home = () => {
  const { illioSelectedAccount } = useAuth();

  const accountType = illioSelectedAccount?.role;
  console.log({ accountType });
  if (accountType === "CUSTOMER") {
    return <Navigate to={"/portfolios/general"} />;
  }

  return <LandingPage />;
};
