import { useState } from "react";
import { Select } from "src/components/Select";
import s from "./ResultsRowWrapper.module.scss";
import classNames from "classnames";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DropDown } from "src/main";
import { useWhatIfResultsRowContext } from "@iliotech/data-wire";

interface IProps {
  title: string;
}
export const ResultsRowWrapper = ({
  title,
  children,
}: React.PropsWithChildren<IProps>) => {
  const { direction, setDirection } = useWhatIfResultsRowContext();

  return (
    <div>
      <div className={s.topRow}>
        <h4>{title}</h4>
        <div className={s.dropdownWrapper}>
          <b>View</b>
          <DropDown
            data={["Columns", "Rows"]}
            value={direction}
            onChange={(v) => setDirection(v.value)}
          />
        </div>
      </div>
      {children}
    </div>
  );
};
