export function filterPortfolios(search: string, portfolios?: any[]) {
  if (!portfolios?.length) {
    return [];
  }

  const mappedPortfolios = portfolios.map((portfolio: any) => ({
    ...portfolio,
    portfolioName: portfolio?.name || portfolio?.portfolioName || "TETST",
  }));

  if (!search) {
    return mappedPortfolios;
  }

  return mappedPortfolios.filter((portfolio: any) => {
    const tokens = search.split(" ");

    return tokens.every((token) => {
      const name = portfolio?.portfolioName;
      return name.toUpperCase().includes(token.toUpperCase());
    });
  });
}
