import { AiOutlineClose } from "react-icons/ai";
import s from "../../../PositionEntryCellRenderer/PositionEntryCellRenderer.module.scss";
import { Typography } from "@progress/kendo-react-common";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { TransactionTableCellProps } from "./DateCell";
import { observer } from "mobx-react-lite";
import { parseLocaleNumber } from "@iliotech/data-wire";

interface Props extends TransactionTableCellProps {}

const TotalLocalCell = ({ index }: Props) => {
  const store = useTransactionUploadV2();

  const currency = store.equities.getField("currency", index + "") as string;
  const quantity = store.equities.getField("quantity", index + "") as number;
  const price = store.equities.getField("price", index + "") as number;
  const type = store.equities.getField("tradeType", index + "") as
    | "BUY"
    | "SELL";
  const commission = parseLocaleNumber(
    store.equities.getField("commission", index + "") + "" || "0",
    "en-US"
  );

  const tradeCosts = parseLocaleNumber(
    store.equities.getField("tradeCosts", index + "") + "" || "0",
    "en-US"
  );

  const commissionToUse = type === "BUY" ? commission : -commission;
  const tradeCostsToUse = type === "BUY" ? tradeCosts : -tradeCosts;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {quantity && (
        <Typography.p>
          {(currency || "") + " "}
          {!currency
            ? ""
            : (
                quantity * (price || 1) +
                (commissionToUse || 0) +
                (tradeCostsToUse || 0)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
        </Typography.p>
      )}
    </div>
  );
};

export default observer(TotalLocalCell);
