import { WorkgroupWithDataV3Dto } from "@iliotech/generated-api-v3";
import React from "react";
import s from "./WorkgroupCard.module.scss";
import { useAuth, useWorkgroupList } from "@iliotech/data-wire";
import { DropDownMenu } from "@iliotech/component-library/src/components/DropDownMenu/DropDownMenu";
import { GoKebabHorizontal } from "react-icons/go";
import Pen from "./assets/pen.svg";
import Share from "./assets/share.svg";
import Suitcase from "./assets/suitcase.svg";
import { IActionColumn } from "@iliotech/component-library";
import { classNames } from "@progress/kendo-react-common";
import { useNavigate } from "react-router";

interface IProps extends WorkgroupWithDataV3Dto {
  valueFormatter: (v: number) => string;
}

export const WorkgroupCard = (props: IProps) => {
  const store = useWorkgroupList();
  const { isCustomer, isOwner } = useAuth();
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/portfolios/" + props.name);
  };
  const profitPositive = props.profitAndLoss > 0;
  const valuePositive = props.totalWealth > 0;
  const actionColumns: IActionColumn[] = isCustomer
    ? []
    : [
        {
          text: "Assign to client",
          action: (dataItem: WorkgroupWithDataV3Dto) => {
            store.setModalState({
              visible: true,
              id: dataItem.id,
              type: "assign",
              name: dataItem.name,
              loading: false,
            });
          },
          disabled: (dataItem: WorkgroupWithDataV3Dto) => {
            return false;
          },
        },
        {
          text: "Delete",
          action: (dataItem: WorkgroupWithDataV3Dto) => {
            store.setModalState({
              visible: true,
              id: dataItem.id,
              name: dataItem.name,
              type: "delete",
              loading: false,
            });
          },
          disabled: (dataItem: WorkgroupWithDataV3Dto) => {
            return dataItem.portfolios > 0;
          },
        },
      ];

  return (
    <article onClick={onClick} className={s.container}>
      <div className={s.row}>
        <span className={s.title}>{props.name}</span>
        <div className={s.icons}>
          <img
            onClick={(e) => {
              e.stopPropagation();
              store.setModalState({
                visible: true,
                id: props.id,
                name: props.name,
                type: "rename",
                loading: false,
              });
            }}
            src={Pen}
            alt="share"
          />
          {isOwner && (
            <img
              onClick={(e) => {
                e.stopPropagation();
                store.setModalState({
                  visible: true,
                  id: props.id,
                  name: props.name,
                  type: "managerShare",
                  loading: false,
                });
              }}
              color={"var(--muted-color)"}
              src={Share}
              alt="pen"
            />
          )}
          <DropDownMenu
            data={actionColumns.filter((x) => !x.disabled?.(props))}
            textField={"text"}
            dataItemKey={"text"}
            onSelect={(e) => {
              e.value?.action?.(props);
            }}
            extraClass={s.menuItem}
            button={
              <div className={s.buttonSurround}>
                <GoKebabHorizontal color={"var(--muted-color)"} />
              </div>
            }
          />
        </div>
      </div>
      <div style={{ height: 8 }} />
      <div className={s.rowStart}>
        <img src={Suitcase} alt="suitcase" />
        <span>{props.portfolios} Portfolios</span>
      </div>
      <div style={{ height: 24 }} />
      <div className={s.rowEnd}>
        <div className={classNames(s.column, profitPositive && s.positive)}>
          <div className={s.label}>Profit & Loss</div>
          <div className={s.value}>
            {props.valueFormatter(props.profitAndLoss)}
          </div>
        </div>
        <div className={classNames(s.column, valuePositive && s.positive)}>
          <div className={s.label}>Value</div>
          <div className={s.value}>
            {props.valueFormatter(props.totalWealth)}
          </div>
        </div>
      </div>
    </article>
  );
};
