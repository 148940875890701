import { PortfolioDataV3Dto } from "@iliotech/generated-api-v3";
import s from "./PortfolioPageList.module.scss";
import React from "react";
import { PortfolioCard } from "./components/PortfolioCard/PortfolioCard";
import {
  FadeLoading,
  ListPaginator,
  Loader,
} from "@iliotech/component-library";
import { PaginatorReturnType } from "@iliotech/data-wire/src/reactHooks/hooks/usePaginator";

type IProps = PaginatorReturnType<PortfolioDataV3Dto> & {
  valueFormatter: (v: number) => string;
  onDeletePortfolio: (id: string) => void;
  openSettings: (id: string) => void;
};

export const PortfolioPageList = ({
  data,
  valueFormatter,
  openSettings,
  onDeletePortfolio,
  ...props
}: IProps) => {
  if (props.isLoading) {
    return <Loader />;
  }
  if (!data?.content?.length) {
    return (
      <div className={s.empty}>
        <p>You don't have any portfolios in this client group.</p>
        <p>Use the "Create portfolio" button above to get started.</p>
      </div>
    );
  }
  return (
    <div className={s.container}>
      <FadeLoading loading={props.isFetching}>
        <div className={s.listContainer}>
          {data?.content?.map((item) => (
            <PortfolioCard
              openSettings={openSettings}
              onDeletePortfolio={onDeletePortfolio}
              valueFormatter={valueFormatter}
              key={item.id}
              {...item}
            />
          ))}
        </div>
      </FadeLoading>
      <ListPaginator {...props} />
    </div>
  );
};
