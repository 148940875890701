import { AxiosResponse } from "axios";
import { useState, useCallback } from "react";
import { QueryObserverOptions, QueryOptions, useQuery } from "react-query";

export interface PaginatableData<T> {
  content: T[];
  page: number;
  size: number;
  order: {
    direction: string;
    name: string;
  };
  totalElements: number;
}

type AxiosPaginatedResponse<T> = Promise<AxiosResponse<PaginatableData<T>>>;

type UsePaginatorOptions<T> = {
  queryKey: string[];
  fetch: (page: number, size: number) => AxiosPaginatedResponse<T>;
  size: number;
};

export type PaginatorReturnType<T> = ReturnType<typeof usePaginator<T>>;

export const usePaginator = <T = any>(
  p: UsePaginatorOptions<T>,
  q: QueryObserverOptions<AxiosResponse<PaginatableData<T>>> = {}
) => {
  const [page, setPage] = useState(0);
  const { data, ...queryInfo } = useQuery<AxiosResponse<PaginatableData<T>>>(
    [...p.queryKey, page],
    () => p.fetch(page, p.size),
    {
      ...q,
      keepPreviousData: true,
    }
  );

  const count = data?.data?.totalElements || 0;
  const pages = data?.data?.totalElements
    ? Math.ceil(data.data.totalElements / p.size) - 1
    : 0;

  const nextPage = useCallback(() => {
    setPage((current) => current + (current >= pages ? 0 : 1));
  }, [pages, setPage]);

  const prevPage = useCallback(() => {
    setPage((current) => current - (current < 1 ? 0 : 1));
  }, [setPage]);

  const resetPage = useCallback(() => {
    setPage(0);
  }, [setPage]);

  const hasNextPage = page < pages;
  const hasPrevPage = page >= 1;

  const currentMinElement = page * p.size + 1;
  const currentMaxElement = Math.min((page + 1) * p.size, count);
  const infoString = `${currentMinElement}-${currentMaxElement} of ${count}`;
  
  return {
    data: data?.data, // This provides direct access to the PaginatableData<T>
    count,
    pages,
    nextPage,
    prevPage,
    page,
    pageToDisplay: page + 1,
    hasNextPage,
    hasPrevPage,resetPage,infoString,
    ...queryInfo,
  };
};
