/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { AccountSubscription } from '../../aggregator';
// @ts-ignore
import { CheckoutPrices } from '../../aggregator';
// @ts-ignore
import { CheckoutProduct } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { PortalDto } from '../../aggregator';
/**
 * SubscriptionControllerApi - axios parameter creator
 * @export
 */
export const SubscriptionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackCheckoutCancel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/subscription/checkout/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackCheckoutSuccess: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('callbackCheckoutSuccess', 'sessionId', sessionId)
            const localVarPath = `/v3/subscription/checkout/success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckoutProduct} checkoutProduct 
         * @param {string} externalAccountId 
         * @param {string} [referralId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession: async (checkoutProduct: CheckoutProduct, externalAccountId: string, referralId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkoutProduct' is not null or undefined
            assertParamExists('createCheckoutSession', 'checkoutProduct', checkoutProduct)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('createCheckoutSession', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/subscription/account/{externalAccountId}/createCheckoutSession`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referralId !== undefined) {
                localVarQueryParameter['referralId'] = referralId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkoutProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/subscription/stripeProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccTierPriceInformation: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getAccTierPriceInformation', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/subscription/account/{externalAccountId}/prices`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckoutPrices} checkoutPrices 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpgrades: async (checkoutPrices: CheckoutPrices, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkoutPrices' is not null or undefined
            assertParamExists('getUpgrades', 'checkoutPrices', checkoutPrices)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getUpgrades', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/subscription/account/{externalAccountId}/upgrade`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkoutPrices, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {'maxUsers' | 'maxPortfolios' | 'maxTrades' | 'maxPositions' | 'maxBrokerConnections' | 'maxManagers' | 'maxMonthsHistory'} [usageLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpgrades1: async (externalAccountId: string, usageLimit?: 'maxUsers' | 'maxPortfolios' | 'maxTrades' | 'maxPositions' | 'maxBrokerConnections' | 'maxManagers' | 'maxMonthsHistory', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getUpgrades1', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/subscription/account/{externalAccountId}/upgrades`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (usageLimit !== undefined) {
                localVarQueryParameter['usageLimit'] = usageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatIfTierPriceInformation: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getWhatIfTierPriceInformation', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/subscription/account/{externalAccountId}/whatIfProducts`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openBillingPortal: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('openBillingPortal', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/subscription/account/{externalAccountId}/billingPortal`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordMeteredUsage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/subscription/meteredUsage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionControllerApi - functional programming interface
 * @export
 */
export const SubscriptionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callbackCheckoutCancel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callbackCheckoutCancel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callbackCheckoutSuccess(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callbackCheckoutSuccess(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckoutProduct} checkoutProduct 
         * @param {string} externalAccountId 
         * @param {string} [referralId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCheckoutSession(checkoutProduct: CheckoutProduct, externalAccountId: string, referralId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCheckoutSession(checkoutProduct, externalAccountId, referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStripeProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStripeProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccTierPriceInformation(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Array<PriceSummaryDto>; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccTierPriceInformation(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckoutPrices} checkoutPrices 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpgrades(checkoutPrices: CheckoutPrices, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpgrades(checkoutPrices, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {'maxUsers' | 'maxPortfolios' | 'maxTrades' | 'maxPositions' | 'maxBrokerConnections' | 'maxManagers' | 'maxMonthsHistory'} [usageLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpgrades1(externalAccountId: string, usageLimit?: 'maxUsers' | 'maxPortfolios' | 'maxTrades' | 'maxPositions' | 'maxBrokerConnections' | 'maxManagers' | 'maxMonthsHistory', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpgrades1(externalAccountId, usageLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatIfTierPriceInformation(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Array<PriceSummaryDto>; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatIfTierPriceInformation(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openBillingPortal(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openBillingPortal(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordMeteredUsage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordMeteredUsage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionControllerApi - factory interface
 * @export
 */
export const SubscriptionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackCheckoutCancel(options?: any): AxiosPromise<object> {
            return localVarFp.callbackCheckoutCancel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackCheckoutSuccess(sessionId: string, options?: any): AxiosPromise<object> {
            return localVarFp.callbackCheckoutSuccess(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckoutProduct} checkoutProduct 
         * @param {string} externalAccountId 
         * @param {string} [referralId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession(checkoutProduct: CheckoutProduct, externalAccountId: string, referralId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.createCheckoutSession(checkoutProduct, externalAccountId, referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeProducts(options?: any): AxiosPromise<void> {
            return localVarFp.createStripeProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccTierPriceInformation(externalAccountId: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: Array<PriceSummaryDto>; }; }> {
            return localVarFp.getAccTierPriceInformation(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckoutPrices} checkoutPrices 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpgrades(checkoutPrices: CheckoutPrices, externalAccountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUpgrades(checkoutPrices, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {'maxUsers' | 'maxPortfolios' | 'maxTrades' | 'maxPositions' | 'maxBrokerConnections' | 'maxManagers' | 'maxMonthsHistory'} [usageLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpgrades1(externalAccountId: string, usageLimit?: 'maxUsers' | 'maxPortfolios' | 'maxTrades' | 'maxPositions' | 'maxBrokerConnections' | 'maxManagers' | 'maxMonthsHistory', options?: any): AxiosPromise<AccountSubscription> {
            return localVarFp.getUpgrades1(externalAccountId, usageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatIfTierPriceInformation(externalAccountId: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: Array<PriceSummaryDto>; }; }> {
            return localVarFp.getWhatIfTierPriceInformation(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openBillingPortal(externalAccountId: string, options?: any): AxiosPromise<PortalDto> {
            return localVarFp.openBillingPortal(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordMeteredUsage(options?: any): AxiosPromise<void> {
            return localVarFp.recordMeteredUsage(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionControllerApi - object-oriented interface
 * @export
 * @class SubscriptionControllerApi
 * @extends {BaseAPI}
 */
export class SubscriptionControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public callbackCheckoutCancel(options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).callbackCheckoutCancel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public callbackCheckoutSuccess(sessionId: string, options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).callbackCheckoutSuccess(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckoutProduct} checkoutProduct 
     * @param {string} externalAccountId 
     * @param {string} [referralId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public createCheckoutSession(checkoutProduct: CheckoutProduct, externalAccountId: string, referralId?: string, options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).createCheckoutSession(checkoutProduct, externalAccountId, referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public createStripeProducts(options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).createStripeProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public getAccTierPriceInformation(externalAccountId: string, options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).getAccTierPriceInformation(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckoutPrices} checkoutPrices 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public getUpgrades(checkoutPrices: CheckoutPrices, externalAccountId: string, options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).getUpgrades(checkoutPrices, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {'maxUsers' | 'maxPortfolios' | 'maxTrades' | 'maxPositions' | 'maxBrokerConnections' | 'maxManagers' | 'maxMonthsHistory'} [usageLimit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public getUpgrades1(externalAccountId: string, usageLimit?: 'maxUsers' | 'maxPortfolios' | 'maxTrades' | 'maxPositions' | 'maxBrokerConnections' | 'maxManagers' | 'maxMonthsHistory', options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).getUpgrades1(externalAccountId, usageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public getWhatIfTierPriceInformation(externalAccountId: string, options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).getWhatIfTierPriceInformation(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public openBillingPortal(externalAccountId: string, options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).openBillingPortal(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public recordMeteredUsage(options?: AxiosRequestConfig) {
        return SubscriptionControllerApiFp(this.configuration).recordMeteredUsage(options).then((request) => request(this.axios, this.basePath));
    }
}
