import React from "react";
import Button from "../../Button/Button";
import { PortfolioCustodian } from "@iliotech/generated-api-v3";
import Checkbox from "../../CheckBox/Checkbox";
import { IPortfolioSettingsCustodiansProps } from "../types";
import AddCustodianModal from "../../AddCustodianModal/AddCustodianModal";
import { createCustodian } from "@iliotech/data-wire";
import s from "../PortfolioSettings.module.scss";
export const PortfolioSettingsCustodians = ({
  portfolioInfo,
  loading,
  setDefaultCustodian,
  refetch,
}: IPortfolioSettingsCustodiansProps) => {
  const [working, setWorking] = React.useState(false);
  const [showAddCustodians, setShowAddCustodians] = React.useState(false);
  const changeCustodian = (nextCustodian: PortfolioCustodian) => {
    setWorking(true);
    setDefaultCustodian(nextCustodian).finally(() => setWorking(false));
  };

  const handleCreateCustodian = (
    selectedCustodian: PortfolioCustodian | null
  ) => {
    createCustodian(portfolioInfo?.id!, selectedCustodian).then(() => {
      refetch();
      setShowAddCustodians(false);
    });
  };

  return (
    <div id={"portfolio-settings-portal"}>
      <AddCustodianModal
        portfolioCustodians={portfolioInfo?.custodians || []}
        handleClose={() => void setShowAddCustodians(false)}
        visible={showAddCustodians}
        wrapperId={"root"}
        onSelectCustodian={handleCreateCustodian}
      />
      <Button
        className={s.custodianButton}
        disabled={!portfolioInfo?.id}
        onClick={() => void setShowAddCustodians(true)}
      >
        Add new account
      </Button>
      <table>
        <thead>
          <tr>
            <th>Account</th>
            <th>Default Account</th>
            <th>Corporate Actions</th>
          </tr>
        </thead>
        <tbody>
          {portfolioInfo?.custodians?.map((custodian) => {
            return (
              <tr>
                <td>{custodian.name}</td>
                <td>
                  <Checkbox
                    disabled={working}
                    checked={custodian.default}
                    onClick={() => changeCustodian(custodian)}
                  />{" "}
                </td>
                <td>{custodian.onlyManualDividends ? "Manual" : "Auto"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
