import {
  AccountManagementApi,
  CustodianControllerApi,
} from "@iliotech/generated-api-v3";
import { useQuery } from "react-query";
import { API_BASE } from "../../../constants/constants";

export const usePortfoliosSummary = (externalAccountId: string) => {
  const api = new AccountManagementApi(undefined, `${API_BASE}/api`);
  return useQuery([`portfolios-summary`, { externalAccountId }], () =>
    api.getCurrentUserPortfoliosSummary(externalAccountId)
  );
};
