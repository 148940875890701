import {
  CreatePositionRequest,
  CreatePositionRequestBuySellEnum,
  PortfolioTradesApi,
  SourceId,
  PortfolioComparisonResponse,
  CreateComparisonScenario,
} from "@iliotech/generated-api-v3";
import { getCachedFxRate, saveWhatIfScenario } from "@iliotech/data-wire";
import { makeAutoObservable } from "mobx";
import {
  VirtualPortfolioResponse,
  InstrumentSearchResultV3,
} from "@iliotech/generated-api-v3";
import {
  getPortfolioPercentage,
  mergeWhatIfPositions,
  parseSourceIdToUpload,
  parseWhatIfPositions,
} from "./utils";
import { getPrice } from "../../main";

import { sendFinalPositionsAsync } from "../../requests/sendFinalPositionsAsync";

export type ValidSearchTypes =
  | "Crypto"
  | "Equity"
  | "Fund"
  | "Fx"
  | "Subscription"
  | "Index"
  | "FixedIncome"
  | "Commodity"
  | "Option"
  | "OtcOption"
  | "Future"
  | "StructuredProduct";

export const EQUITIES_SEARCH_TYPES: ValidSearchTypes[] = ["Equity", "Fund"];
export const CRYPTO_SEARCH_TYPES: ValidSearchTypes[] = ["Crypto"];
export const FIXED_INCOME_SEARCH_TYPES: ValidSearchTypes[] = ["FixedIncome"];

export interface IWhatIfPositionTableRow {
  id: number;
  name?: string;
  quantity?: number;
  oldQuantity?: number;
  portfolioPercentage?: number;
  price?: number;
  totalValue?: number;
  sourceId?: SourceId;
  custodianCode?: string;
  fxRate?: number;
  valid?: boolean;
  multiplier?: number;
  searchTypes?: ValidSearchTypes[];
}

export const WhatIfTableStore = () =>
  makeAutoObservable({
    tableData: {} as { [id: number]: IWhatIfPositionTableRow },
    originalTableData: {} as { [id: number]: IWhatIfPositionTableRow },
    showAlert: false,
    skipSave: false,
    custodianCode: "",
    eraseModal: false,
    saveModal: false,
    anchorDate: "2022-10-10",
    loading: false,
    blockingLoading: false,
    transitionLoading: false,
    submitModal: false,
    basePortfolioTotal: 0,
    whatIfPortfolioTotal: 0,
    portfolioDifference: 0,
    currencySymbol: "",
    currencyCode: "",
    updateTableRowByIndex(
      key: keyof IWhatIfPositionTableRow,
      value: any,
      id: number
    ) {
      // @ts-ignore
      this.tableData[id][key] = value;
    },
    getField<T>(key: keyof IWhatIfPositionTableRow, id: number) {
      return (this.tableData[id]?.[key] as T) || undefined;
    },
    hideTableRow(id: number) {
      this.tableData[id].quantity = 0;
      this.tableData[id].portfolioPercentage = 0;
      this.tableData[id].totalValue = 0;
      this.getTotals();
    },
    removeTableRow(id: number) {
      delete this.tableData[id];
      this.getTotals();
      this.recalculatePortfolioPercentages();
    },
    isValidRow(id: number) {
      const row = this.tableData[id];
      return !!row.custodianCode && !!row.price && !!row.sourceId && !!row.name;
    },
    updateTableRow(row: IWhatIfPositionTableRow) {
      this.tableData[row.id] = row;
    },
    tableDataArray() {
      return Object.values(this.tableData || {});
    },
    recalculatePortfolioPercentages() {
      const tableData = this.tableDataArray();
      this.getTotals();
      const absoluteTotal = tableData.reduce(
        (a, b) => a + Math.abs(b?.totalValue || 0),
        0
      );
      tableData.forEach((item) => {
        item.portfolioPercentage = getPortfolioPercentage(
          item.totalValue || 0,
          absoluteTotal
        );
      });
    },
    onRowQuantityChange(id: number, value: number) {
      const currentRow = this.tableData[id];
      currentRow.quantity = value;
      currentRow.totalValue =
        (value || 0) *
        (currentRow.price || 0) *
        (currentRow.fxRate || 1) *
        (currentRow.multiplier || 1);
      currentRow.valid = this.isValidRow(id);
      this.recalculatePortfolioPercentages();
    },
    onAddNewRow(searchTypes: ValidSearchTypes[]) {
      const id =
        this.tableDataArray()
          .map((item) => item.id)
          .sort((a, b) => b - a)[0] + 1 || 0;
      this.tableData[id] = {
        id,
        name: undefined,
        quantity: 0,
        oldQuantity: 0,
        portfolioPercentage: 0,
        price: 0,
        totalValue: 0,
        custodianCode: this.custodianCode,
        valid: false,
        searchTypes,
      };
    },
    async onSelectNewInstrument(id: number, value: InstrumentSearchResultV3) {
      const getFxRate = (currency: string) =>
        getCachedFxRate(this.anchorDate, currency, this.currencyCode);
      const currentRow = this.tableData[id];
      currentRow.name = value.label;
      currentRow.sourceId = value.instrumentSourceId;

      currentRow.multiplier = value.pointValue || 1;
      const isCash = value.instrumentSourceId?.sourceId.endsWith("SUBS");
      // if instrument is cash, we want to set its price to 1, no need to fetch it
      if (isCash) {
        currentRow.price = 1;
        return;
      }
      getFxRate(value.currencyCode!).then((fxRate) => {
        currentRow.fxRate = fxRate;
      });
      getPrice(
        value.instrumentSourceId?.sourceId,
        value.instrumentSourceId?.sourceData,
        this.anchorDate
      ).then((price) => {
        currentRow.price = price?.data;
      });
    },
    getTotals() {
      const tableData = this.tableDataArray();
      this.whatIfPortfolioTotal = tableData.reduce(
        (a, b) =>
          a +
          (b?.price || 0) *
            (b?.quantity || 0) *
            (b?.fxRate || 1) *
            (b?.multiplier || 1),
        0
      );
      this.portfolioDifference =
        this.whatIfPortfolioTotal - this.basePortfolioTotal;
    },
    resetTableData() {
      this.tableData = {};
    },
    tableLength() {
      return Object.entries(this.tableData).length;
    },
    async processEditInitialData(
      basePositions: VirtualPortfolioResponse,
      whatIfPositions: VirtualPortfolioResponse,
      anchorDate: string
    ) {
      this.anchorDate = anchorDate;
      const basePositionsLight = basePositions?.basePositions;
      const whatIfPositionsLight = whatIfPositions?.basePositions;

      const getFxRate = (currency: string) =>
        getCachedFxRate(
          this.anchorDate,
          currency,
          basePositions.portfolioCurrency
        );
      this.blockingLoading = true;
      this.currencySymbol = basePositions?.portfolioCurrencySymbol;
      this.currencyCode = basePositions?.portfolioCurrency;
      this.custodianCode = basePositions?.basePositions?.[0]?.custodianCode;
      mergeWhatIfPositions(
        basePositionsLight,
        whatIfPositionsLight,
        this.anchorDate,
        getFxRate
      ).then((newData) => {
        const newTableData = newData.reduce((obj: any, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        this.tableData = newTableData;
        this.basePortfolioTotal = this.tableDataArray().reduce(
          (a: any, b: any) =>
            a +
            (b?.price || 0) *
              (b?.oldQuantity || 0) *
              (b?.fxRate || 1) *
              (b?.multiplier || 1),
          0
        );
        this.getTotals();

        this.blockingLoading = false;
      });
    },
    async processInitialData(data: VirtualPortfolioResponse) {
      this.anchorDate = data?.whatIfAnchorDate;
      const getFxRate = (currency: string) =>
        getCachedFxRate(this.anchorDate, currency, data?.portfolioCurrency);
      this.blockingLoading = true;
      this.currencySymbol = data?.portfolioCurrencySymbol;
      this.currencyCode = data?.portfolioCurrency;
      this.custodianCode = data?.basePositions?.[0]?.custodianCode;
      this.showAlert = data?.excludedPositionCount > 0;
      parseWhatIfPositions(
        data?.basePositions,
        getFxRate,
        this.anchorDate
      ).then((newData) => {
        const newTableData = newData.reduce((obj: any, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        this.tableData = newTableData;

        this.onSaveInitialPositions(data?.basePortfolioId!, newData);
        this.basePortfolioTotal = this.tableDataArray().reduce(
          (a: any, b: any) =>
            a +
            (b?.price || 0) *
              (b?.quantity || 0) *
              (b?.fxRate || 1) *
              (b?.multiplier || 1),
          0
        );
        this.getTotals();

        this.blockingLoading = false;
      });
    },
    async onSaveInitialPositions(
      basePortfolioId: string,
      positions: IWhatIfPositionTableRow[]
    ) {
      const tradeTime = this.anchorDate;
      const originalPositionsPayload: CreatePositionRequest[] = positions.map(
        (item) => {
          return {
            sourceId: parseSourceIdToUpload(item.sourceId!),
            tradeTime: tradeTime,
            buySell:
              item.quantity! > 0
                ? CreatePositionRequestBuySellEnum.Buy
                : CreatePositionRequestBuySellEnum.Sell,
            price: Math.abs(Number(item.price)),
            quantity: Math.abs(Number(item.quantity)),
            custodianCode: item.custodianCode!,
          };
        }
      );

      await sendFinalPositionsAsync(
        originalPositionsPayload,
        basePortfolioId,
        "WHAT_IF_POSITIONS"
      );
    },
    async onSaveScenario(
      externalAccountId: string,
      comparisonResponse: PortfolioComparisonResponse,
      name: string,
      callBack: () => void
    ) {
      this.loading = true;
      const payload: CreateComparisonScenario = {
        basePortfolioId: comparisonResponse.basePortfolioId,
        comparisonPortfolioId: comparisonResponse.comparisonPortfolioId,
        name,
        dateFrom: this.anchorDate,
        metrics: comparisonResponse.results,
      };
      await saveWhatIfScenario(payload, externalAccountId);
      this.loading = false;
      callBack();
    },
    async onEditPositions(whatIfPortfolioId: string) {
      this.submitModal = false;
      this.transitionLoading = true;
      this.loading = true;
      const validWhatIfPositions = this.tableDataArray().filter((item) =>
        this.isValidRow(item.id)
      );

      if (!validWhatIfPositions.length) return;
      // const originalPositions = Object.values(this.originalTableData || {});

      const tradeTime = this.anchorDate;

      const whatIfPositionsPayload: CreatePositionRequest[] =
        validWhatIfPositions.map((item) => {
          return {
            sourceId: parseSourceIdToUpload(item.sourceId!),
            tradeTime,
            buySell:
              item.quantity! > 0
                ? CreatePositionRequestBuySellEnum.Buy
                : CreatePositionRequestBuySellEnum.Sell,
            price: Math.abs(Number(item.price)),
            quantity: Math.abs(Number(item.quantity)),
            custodianCode: item.custodianCode!,
          };
        });

      try {
        sendFinalPositionsAsync(
          whatIfPositionsPayload,
          whatIfPortfolioId,
          "WHAT_IF_POSITIONS",
          true
        );
      } catch (e) {
        return false;
      }
      this.loading = false;
    },
    async onSavePositions(whatIfPortfolioId: string) {
      this.submitModal = false;
      this.transitionLoading = true;
      this.loading = true;
      const validWhatIfPositions = this.tableDataArray().filter((item) =>
        this.isValidRow(item.id)
      );

      if (!validWhatIfPositions.length) return;
      // const originalPositions = Object.values(this.originalTableData || {});

      const tradeTime = this.anchorDate;

      const whatIfPositionsPayload: CreatePositionRequest[] =
        validWhatIfPositions.map((item) => {
          return {
            sourceId: parseSourceIdToUpload(item.sourceId!),
            tradeTime,
            buySell:
              item.quantity! > 0
                ? CreatePositionRequestBuySellEnum.Buy
                : CreatePositionRequestBuySellEnum.Sell,
            price: Math.abs(Number(item.price)),
            quantity: Math.abs(Number(item.quantity)),
            custodianCode: item.custodianCode!,
          };
        });

      try {
        sendFinalPositionsAsync(
          whatIfPositionsPayload,
          whatIfPortfolioId,
          "WHAT_IF_POSITIONS"
        );
      } catch (e) {
        return false;
      }
      this.loading = false;
    },
  });

export default WhatIfTableStore;
