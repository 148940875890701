/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SourceId } from './source-id';

/**
 * 
 * @export
 * @interface CreatePositionRequest
 */
export interface CreatePositionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePositionRequest
     */
    'instrumentId'?: string;
    /**
     * 
     * @type {SourceId}
     * @memberof CreatePositionRequest
     */
    'sourceId': SourceId;
    /**
     * 
     * @type {SourceId}
     * @memberof CreatePositionRequest
     */
    'underlyingInstrumentSourceId'?: SourceId;
    /**
     * 
     * @type {string}
     * @memberof CreatePositionRequest
     */
    'tradeTime': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreatePositionRequest
     */
    'custodianCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePositionRequest
     */
    'strategyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePositionRequest
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePositionRequest
     */
    'buySell'?: CreatePositionRequestBuySellEnum;
    /**
     * 
     * @type {number}
     * @memberof CreatePositionRequest
     */
    'fxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePositionRequest
     */
    'historicPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePositionRequest
     */
    'notes'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreatePositionRequestBuySellEnum {
    Sell = 'SELL',
    Buy = 'BUY',
    Add = 'ADD',
    Withdraw = 'WITHDRAW',
    Rebate = 'REBATE',
    Deduction = 'DEDUCTION',
    DividendReceived = 'DIVIDEND_RECEIVED',
    SwapL = 'SWAP_L',
    SwapS = 'SWAP_S',
    Reserve = 'RESERVE',
    Unreserve = 'UNRESERVE',
    Earn = 'EARN',
    Coupon = 'COUPON',
    Accrued = 'ACCRUED'
}


