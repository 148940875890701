import * as React from "react";
import s from "./BigButtonWithIcon.module.scss";
import { Typography } from "@progress/kendo-react-common";
import cn from "classnames";
import { Link, LinkProps } from "react-router-dom";
import { DropDownMenu, DropDownMenuProps } from "../DropDownMenu/DropDownMenu";
import classNames from "classnames";

export interface BigButtonWithIconProps extends Partial<LinkProps> {
  icon?: string;
  title: string;
  titleIcon?: any;
  text: string;
  extraClass?: string;
  href?: string;
  onClick?: () => void;
  premium?: boolean;
  dropdowwnProps?: DropDownMenuProps<any>;
  noInvert?: boolean;
  beta?: boolean;
}

export const BigButtonWithIcon = ({
  icon,
  title,
  text,
  extraClass,
  href,
  onClick,
  premium,
  titleIcon,
  dropdowwnProps,
  noInvert,
  beta,
  ...rest
}: BigButtonWithIconProps) => {
  const content = (
    <>
      {icon && (
        <img
          src={icon}
          className={classNames(s.icon, noInvert && "no-invert")}
          alt={icon}
        />
      )}
      <div className={s.text}>
        {premium && (
          <div className={s.premiumLabel}>
            <Typography.p>PREMIUM</Typography.p>
          </div>
        )}

        <div className={s.row}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography.h6 className={classNames(beta && s.withPremiumLabel)}>
              {title}
            </Typography.h6>
            {beta && (
              <div className={s.premiumLabel}>
                <Typography.p>BETA</Typography.p>
              </div>
            )}
          </div>
          {titleIcon}
        </div>
        <Typography.p>{text}</Typography.p>

        {dropdowwnProps && (
          <div className={s.button}>
            <DropDownMenu {...dropdowwnProps} />
          </div>
        )}
      </div>
    </>
  );

  const className = cn(
    s.container,
    extraClass,
    premium && s.premium,
    dropdowwnProps && s.withDropdown
  );

  if (premium) {
    return <div className={className}>{content}</div>;
  }

  if (!href) {
    return (
      <div onClick={onClick} className={className} {...(rest as any)}>
        {content}
      </div>
    );
  }
  return (
    <Link onClick={onClick} to={href || ""} className={className} {...rest}>
      {content}
    </Link>
  );
};

export default BigButtonWithIcon;
