import React, {PropsWithChildren, useContext, useState} from "react";

import {InstrumentPageContext} from "./InstrumentPageContext";


export const useInstrumentPage = () => {
  const context = useContext(InstrumentPageContext);
  if (context === null) {
    throw new Error("useInstrumentPage hook called outside of InstrumentPageContext");
  }
  
  return context;
}
