import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import {
  currencyCodeValidator,
} from "@iliotech/data-wire";
import * as React from "react";
import { Autocomplete, AutocompleteProps } from "../Autocomplete/Autocomplete";
interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  positions?: any;
}

export const UnderlyingField = ({
  errors,
  name = "underlying",
  label = "Underlying",
  positions,
  ...rest
}: IOwnProps) => {
  // console.log({ positions })
  return (
    <Field
      component={Autocomplete}
      label={label}
      name={name}
      dataItemKey={"underlying"}
      textField={"underlying"}
      placeholder={"Enter name or ticker"}
      error={errors?.[name]}
      filterData={(filter: string) => {
        // console.log({positions})
        return positions
          ?.filter(
            (item: any) =>
              item.currencyCode
                .toLowerCase()
                .startsWith(filter.toLowerCase()) ||
              item.label.toLowerCase().startsWith(filter.toLowerCase())
          )
          ?.map((pos: any) => ({
            ...pos,
            name: `${pos.label} [${pos.currencyCode} - ${pos.currencySymbol}]`,
          }));
      }}
      validator={currencyCodeValidator}
      data={positions}
      allowCustom
    />
  );
};
