import React from "react";
import s from "../BondsPage.module.scss";
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartValueAxisLabels,
  SharedTooltipContext,
  TooltipContext,
} from "@progress/kendo-react-charts";
import { IncomePositionDetailsV3 } from "@iliotech/generated-api-v3";
import cn from "classnames";
import {
  RepaymentTooltipContents,
  useBondsPageContext,
} from "../context/BondsPageContext";
import { uniqBy } from "lodash";
import { Typography } from "@progress/kendo-react-common";
import { useTranslation } from "react-i18next";
import Toggle from "../../../Toggle/Toggle";

export const PrincipalRepaymentChart = () => {
  const {
    repaymentTooltipContents,
    principalRepayments,
    currencySymbol,
    toggleRepaymentsKey,
  } = useBondsPageContext();
  const { t } = useTranslation();

  return (
    <div className={"card"}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography.h6>Principal & Call Repayments</Typography.h6>
        <Toggle
          left={t("First Event")}
          right={t("Maturity")}
          onClick={toggleRepaymentsKey}
        />
      </div>
      <Chart className={s.chartContainer} transitions={true}>
        {/*<ChartLegend visible={false}/>*/}

        <ChartTooltip
          opacity={0.9}
          background={"var(--dark)"}
          shared={true}
          render={repaymentsTooltipRenderer(repaymentTooltipContents)}
        />
        <ChartArea background={"transparent"} />
        <ChartValueAxis>
          <ChartValueAxisItem
            // axisCrossingValue={minValue}
            background={"transparent"}
            majorGridLines={{
              color: "var(--muted-border-color)",
              width: 1,
              skip: 1,
            }}
            minorGridLines={{ visible: false }}
          >
            <ChartValueAxisLabels
              color={"var(--color)"}
              content={(e) =>
                `${currencySymbol} ${
                  e.value?.toLocaleString?.(undefined, {
                    maximumFractionDigits: 0,
                  }) || ""
                }`
              }
            />
          </ChartValueAxisItem>
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            majorGridLines={{
              color: "var(--muted-border-color)",
              width: 1,
            }}
            labels={{
              padding: 10,
              rotation: -45,
              color: "var(--color)",
              position: "start",
              content: (e) =>
                e.value?.length > 22 ? e.value.substr(0, 22) + "..." : e.value,
            }}
            // max={longestGroup < barLimits ? longestGroup : barLimits}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem
            border={{
              width: 0.1,
            }}
            negativeValues={{ visible: true, color: "magenta" }}
            stack={true}
            width={0.1}
            data={principalRepayments}
            categoryField="category"
          />
        </ChartSeries>
      </Chart>
      <div className={cn(s.legend)}>
        {uniqBy(principalRepayments, (r) => r.name).map((repayment) => (
          <div key={repayment.name} className={cn(s.legendEntry)}>
            <div
              className={cn(s.color)}
              style={{ backgroundColor: repayment.color }}
            />
            {repayment.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const repaymentsTooltipRenderer =
  (repaymentTooltipContents: RepaymentTooltipContents) =>
  (contextIn: TooltipContext | SharedTooltipContext) => {
    const context = contextIn as SharedTooltipContext;

    if (
      !context.categoryText ||
      !repaymentTooltipContents[context.categoryText]
    ) {
      return <div>Unable to process data</div>;
    }
    const data = repaymentTooltipContents[context.categoryText];
    const { assets, liabilities, assetsTotal, liabilitiesTotal, total } = data;

    return (
      <div className={cn(s.repaymentEntriesSurround)}>
        <strong>
          Total: {total.toLocaleString(undefined, { maximumFractionDigits: 0 })}
        </strong>
        <Entries data={assets} total={assetsTotal} label={"Assets"} />
        <Entries
          data={liabilities}
          total={liabilitiesTotal}
          label={"Liabilities"}
        />
      </div>
    );
  };

const Entries = React.memo(
  ({
    data,
    total,
    label,
  }: {
    data: {
      details: IncomePositionDetailsV3[];
      category: string;
      color: string;
    }[];
    total: number;
    label: string;
  }) => {
    if (!data.length) return null;
    return (
      <div>
        <label>
          {label} Total:{" "}
          {total.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </label>
        <ul>
          {data.map((entry, i) => (
            <div key={`${entry.category}-${i}`}>
              {entry.details.map((item, j) => (
                <li key={`${item.instrumentName}-${j}`}>
                  <div className={cn(s.horizontal)}>
                    <div
                      className={cn(s.repaymentSquare)}
                      style={{ backgroundColor: entry.color }}
                    />
                    <div className={cn(s.repaymentDetails)}>
                      {entry.category} - {item.instrumentName}:{" "}
                      {item.valueLocal.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}
                      {item.currency}
                    </div>
                  </div>
                </li>
              ))}
            </div>
          ))}
        </ul>
      </div>
    );
  }
);
