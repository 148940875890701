import { GridColumnProps } from "@progress/kendo-react-grid";
import { CellWrapper } from "../__common/CellWrapper";
import { MetricComparison } from "@iliotech/generated-api-v3";
import SegmentsCell from "./cells/SegmentsCell";
import YourPortfolioCell from "./cells/YourPortfolioCell";
import WhatIfScenarioCell from "./cells/WhatIfScenarioCell";
import ChangeCell from "./cells/ChangeCell";

export interface WhatIfResultsCellProps {
  baseCurrency?: string;
  dataItem?: MetricComparison;
}

export const whatIfResultsColumns: (
  baseCurrencySymbol: string
) => GridColumnProps[] = (baseCurrencySymbol: string) => [
  {
    field: "name",
    title: "Segments",
    removeBorderLeft: true,
    cell: (props) => (
      <CellWrapper
        gridCellProps={props}
        cell={SegmentsCell}
        dataItem={props.dataItem}
      />
    ),
  },
  {
    field: "baseValue",
    title: "Your Portfolio",
    rightAligned: true,
    cell: (props) => (
      <CellWrapper
        gridCellProps={props}
        cell={YourPortfolioCell}
        dataItem={props.dataItem}
        rightAligned
      />
    ),
  },
  {
    field: "comparisonValue",
    title: "What if Portfolio",
    rightAligned: true,
    cell: (props) => (
      <CellWrapper
        gridCellProps={props}
        cell={WhatIfScenarioCell}
        dataItem={props.dataItem}
        rightAligned
      />
    ),
  },
  {
    field: "change",
    title: "Change",
    rightAligned: true,
    cell: (props) => (
      <CellWrapper
        extraStyles={{ marginRight: 15 }}
        gridCellProps={props}
        cell={ChangeCell}
        dataItem={props.dataItem}
        rightAligned
      />
    ),
  },
];
