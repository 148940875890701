import * as React from "react";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { Autocomplete } from "../../../FormInputs";
import { TransactionTableCellProps } from "./DateCell";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import cn from "classnames";
import s from "../../../FormInputs/Autocomplete/Autocomplete.module.scss";
import { observer } from "mobx-react-lite";

const INSTRUMENT_TYPES = [
  "Commodity",
  "Crypto",
  "Shares",
  "Fund",
  "ETF",
  "Real Assets",
  "Real Estate",
];

const InstrumentTypeCell = ({ index }: TransactionTableCellProps) => {
  const store = useTransactionUploadV2();

  const instrumentType = store.equities.getField("instrumentType", index + "");
  const onChange = (event: ComboBoxChangeEvent) => {
    store.equities.updateTableRowByIndex(
      "instrumentType",
      event.value,
      index + ""
    );
  };

  return (
    <ComboBox
      popupSettings={{ popupClass: "illio-combo" }}
      size={"medium"}
      rounded={"large"}
      clearButton={false}
      onChange={onChange}
      data={INSTRUMENT_TYPES}
      defaultValue={instrumentType}
      filterable={true}
    />
  );
};

export default observer(InstrumentTypeCell);
