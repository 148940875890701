import * as React from "react";
import FormModal from "../../../../FormModal/FormModal";
import RoundButton from "../../../../RoundButton/RoundButton";
import { FormInputs } from "../../../../FormInputs";

import s from "./RenameModal.module.scss";
import { Form, FormElement } from "@progress/kendo-react-form";
import { workgroupNameValidator } from "@iliotech/data-wire";
interface IProps {
  visible: boolean;
  handleClose: () => void;
  onSave: (data: any) => void;
  name: string;
  loading?: boolean;
}

const RenameModal = ({
  visible,
  handleClose,
  onSave,
  name,
  loading,
}: IProps) => {
  const onSubmit = (data: any) => {
    onSave(data);
  };

  return (
    <Form
      key={"rename-form" + name}
      initialValues={{
        name: name,
      }}
      onSubmit={onSubmit}
      render={(formRenderProps) => (
        <FormModal
          title={"Rename"}
          visible={visible}
          handleClose={handleClose}
          footer={
            <FormModal.Footer className={s.footer}>
              <RoundButton fillMode={"outline"} onClick={handleClose}>
                Close
              </RoundButton>
              <RoundButton
                loading={loading}
                onClick={formRenderProps.onSubmit}
                disabled={formRenderProps.valueGetter("name") === name}
                type={"submit"}
              >
                Save
              </RoundButton>
            </FormModal.Footer>
          }
        >
          <FormElement>
            <FormInputs.Common.NameField
              validator={workgroupNameValidator}
              name={"name"}
              label={"Name"}
              errors={formRenderProps.errors}
            />
          </FormElement>
        </FormModal>
      )}
    />
  );
};

export default RenameModal;
