import { useNotification } from "@iliotech/component-library";

export const useWithTryAndCatch = () => {
  const { error } = useNotification();

  const withTryAndCatch: (fn: any) => Promise<[any, boolean]> = async (
    fn: any
  ) => {
    try {
      const result = await fn();
      return [result, false];
    } catch (e: any) {
      error({
        title: "There was an error saving",
        description: e?.message || e,
        duration: 15000,
      });
      return [false, true];
    }
  };

  return { withTryAndCatch };
};

export default useWithTryAndCatch;
