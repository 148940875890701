/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { DatedValueDto } from '../../aggregator';
// @ts-ignore
import { DatedValueUpdateRequest } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { InstrumentSearchResultV3 } from '../../aggregator';
// @ts-ignore
import { RealAssetDetailResponse } from '../../aggregator';
// @ts-ignore
import { RealAssetInstrumentCreateRequest } from '../../aggregator';
// @ts-ignore
import { RealAssetUpdateRequest } from '../../aggregator';
/**
 * RealAssetsApi - axios parameter creator
 * @export
 */
export const RealAssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new real asset
         * @param {RealAssetInstrumentCreateRequest} realAssetInstrumentCreateRequest 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRealAsset: async (realAssetInstrumentCreateRequest: RealAssetInstrumentCreateRequest, externalAccountId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realAssetInstrumentCreateRequest' is not null or undefined
            assertParamExists('addRealAsset', 'realAssetInstrumentCreateRequest', realAssetInstrumentCreateRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('addRealAsset', 'externalAccountId', externalAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addRealAsset', 'name', name)
            const localVarPath = `/v3/account/{externalAccountId}/realAsset/workgroup/{name}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(realAssetInstrumentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncome: async (externalAccountId: string, instrumentSourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getIncome', 'externalAccountId', externalAccountId)
            // verify required parameter 'instrumentSourceId' is not null or undefined
            assertParamExists('getIncome', 'instrumentSourceId', instrumentSourceId)
            const localVarPath = `/v3/account/{externalAccountId}/realAsset/{instrumentSourceId}/income`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"instrumentSourceId"}}`, encodeURIComponent(String(instrumentSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrument: async (externalAccountId: string, instrumentSourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getInstrument', 'externalAccountId', externalAccountId)
            // verify required parameter 'instrumentSourceId' is not null or undefined
            assertParamExists('getInstrument', 'instrumentSourceId', instrumentSourceId)
            const localVarPath = `/v3/account/{externalAccountId}/realAsset/{instrumentSourceId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"instrumentSourceId"}}`, encodeURIComponent(String(instrumentSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrices: async (externalAccountId: string, instrumentSourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getPrices', 'externalAccountId', externalAccountId)
            // verify required parameter 'instrumentSourceId' is not null or undefined
            assertParamExists('getPrices', 'instrumentSourceId', instrumentSourceId)
            const localVarPath = `/v3/account/{externalAccountId}/realAsset/{instrumentSourceId}/prices`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"instrumentSourceId"}}`, encodeURIComponent(String(instrumentSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {'CashAndEquivalents' | 'Equities' | 'FixedIncome' | 'RealEstate' | 'Alternatives' | 'Commodities' | 'RealAssets' | 'CryptoCurrencies' | 'SubscriptionWithdrawal' | 'Funds' | 'Adjustments' | 'CFD' | 'Fx'} [assetClass] 
         * @param {'DRAFT' | 'ACTIVE' | 'INACTIVE' | 'DELISTED' | 'SUSPENDED' | 'DEPRECATED' | 'ERROR'} [status] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRealAssetInstruments: async (externalAccountId: string, name: string, assetClass?: 'CashAndEquivalents' | 'Equities' | 'FixedIncome' | 'RealEstate' | 'Alternatives' | 'Commodities' | 'RealAssets' | 'CryptoCurrencies' | 'SubscriptionWithdrawal' | 'Funds' | 'Adjustments' | 'CFD' | 'Fx', status?: 'DRAFT' | 'ACTIVE' | 'INACTIVE' | 'DELISTED' | 'SUSPENDED' | 'DEPRECATED' | 'ERROR', searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('searchRealAssetInstruments', 'externalAccountId', externalAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('searchRealAssetInstruments', 'name', name)
            const localVarPath = `/v3/account/{externalAccountId}/realAsset/workgroup/{name}/search`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetClass !== undefined) {
                localVarQueryParameter['assetClass'] = assetClass;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncome: async (datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, instrumentSourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datedValueUpdateRequest' is not null or undefined
            assertParamExists('updateIncome', 'datedValueUpdateRequest', datedValueUpdateRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('updateIncome', 'externalAccountId', externalAccountId)
            // verify required parameter 'instrumentSourceId' is not null or undefined
            assertParamExists('updateIncome', 'instrumentSourceId', instrumentSourceId)
            const localVarPath = `/v3/account/{externalAccountId}/realAsset/{instrumentSourceId}/income`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"instrumentSourceId"}}`, encodeURIComponent(String(instrumentSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datedValueUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrices: async (datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, instrumentSourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datedValueUpdateRequest' is not null or undefined
            assertParamExists('updatePrices', 'datedValueUpdateRequest', datedValueUpdateRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('updatePrices', 'externalAccountId', externalAccountId)
            // verify required parameter 'instrumentSourceId' is not null or undefined
            assertParamExists('updatePrices', 'instrumentSourceId', instrumentSourceId)
            const localVarPath = `/v3/account/{externalAccountId}/realAsset/{instrumentSourceId}/prices`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"instrumentSourceId"}}`, encodeURIComponent(String(instrumentSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datedValueUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RealAssetUpdateRequest} realAssetUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRealAsset: async (realAssetUpdateRequest: RealAssetUpdateRequest, externalAccountId: string, instrumentSourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realAssetUpdateRequest' is not null or undefined
            assertParamExists('updateRealAsset', 'realAssetUpdateRequest', realAssetUpdateRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('updateRealAsset', 'externalAccountId', externalAccountId)
            // verify required parameter 'instrumentSourceId' is not null or undefined
            assertParamExists('updateRealAsset', 'instrumentSourceId', instrumentSourceId)
            const localVarPath = `/v3/account/{externalAccountId}/realAsset/{instrumentSourceId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"instrumentSourceId"}}`, encodeURIComponent(String(instrumentSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(realAssetUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealAssetsApi - functional programming interface
 * @export
 */
export const RealAssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealAssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new real asset
         * @param {RealAssetInstrumentCreateRequest} realAssetInstrumentCreateRequest 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRealAsset(realAssetInstrumentCreateRequest: RealAssetInstrumentCreateRequest, externalAccountId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentSearchResultV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRealAsset(realAssetInstrumentCreateRequest, externalAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIncome(externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatedValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIncome(externalAccountId, instrumentSourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstrument(externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealAssetDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrument(externalAccountId, instrumentSourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrices(externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatedValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrices(externalAccountId, instrumentSourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {'CashAndEquivalents' | 'Equities' | 'FixedIncome' | 'RealEstate' | 'Alternatives' | 'Commodities' | 'RealAssets' | 'CryptoCurrencies' | 'SubscriptionWithdrawal' | 'Funds' | 'Adjustments' | 'CFD' | 'Fx'} [assetClass] 
         * @param {'DRAFT' | 'ACTIVE' | 'INACTIVE' | 'DELISTED' | 'SUSPENDED' | 'DEPRECATED' | 'ERROR'} [status] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRealAssetInstruments(externalAccountId: string, name: string, assetClass?: 'CashAndEquivalents' | 'Equities' | 'FixedIncome' | 'RealEstate' | 'Alternatives' | 'Commodities' | 'RealAssets' | 'CryptoCurrencies' | 'SubscriptionWithdrawal' | 'Funds' | 'Adjustments' | 'CFD' | 'Fx', status?: 'DRAFT' | 'ACTIVE' | 'INACTIVE' | 'DELISTED' | 'SUSPENDED' | 'DEPRECATED' | 'ERROR', searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstrumentSearchResultV3>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRealAssetInstruments(externalAccountId, name, assetClass, status, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIncome(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatedValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIncome(datedValueUpdateRequest, externalAccountId, instrumentSourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrices(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatedValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrices(datedValueUpdateRequest, externalAccountId, instrumentSourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RealAssetUpdateRequest} realAssetUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRealAsset(realAssetUpdateRequest: RealAssetUpdateRequest, externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRealAsset(realAssetUpdateRequest, externalAccountId, instrumentSourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealAssetsApi - factory interface
 * @export
 */
export const RealAssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealAssetsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new real asset
         * @param {RealAssetInstrumentCreateRequest} realAssetInstrumentCreateRequest 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRealAsset(realAssetInstrumentCreateRequest: RealAssetInstrumentCreateRequest, externalAccountId: string, name: string, options?: any): AxiosPromise<InstrumentSearchResultV3> {
            return localVarFp.addRealAsset(realAssetInstrumentCreateRequest, externalAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncome(externalAccountId: string, instrumentSourceId: string, options?: any): AxiosPromise<Array<DatedValueDto>> {
            return localVarFp.getIncome(externalAccountId, instrumentSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrument(externalAccountId: string, instrumentSourceId: string, options?: any): AxiosPromise<RealAssetDetailResponse> {
            return localVarFp.getInstrument(externalAccountId, instrumentSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrices(externalAccountId: string, instrumentSourceId: string, options?: any): AxiosPromise<Array<DatedValueDto>> {
            return localVarFp.getPrices(externalAccountId, instrumentSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {string} name 
         * @param {'CashAndEquivalents' | 'Equities' | 'FixedIncome' | 'RealEstate' | 'Alternatives' | 'Commodities' | 'RealAssets' | 'CryptoCurrencies' | 'SubscriptionWithdrawal' | 'Funds' | 'Adjustments' | 'CFD' | 'Fx'} [assetClass] 
         * @param {'DRAFT' | 'ACTIVE' | 'INACTIVE' | 'DELISTED' | 'SUSPENDED' | 'DEPRECATED' | 'ERROR'} [status] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRealAssetInstruments(externalAccountId: string, name: string, assetClass?: 'CashAndEquivalents' | 'Equities' | 'FixedIncome' | 'RealEstate' | 'Alternatives' | 'Commodities' | 'RealAssets' | 'CryptoCurrencies' | 'SubscriptionWithdrawal' | 'Funds' | 'Adjustments' | 'CFD' | 'Fx', status?: 'DRAFT' | 'ACTIVE' | 'INACTIVE' | 'DELISTED' | 'SUSPENDED' | 'DEPRECATED' | 'ERROR', searchTerm?: string, options?: any): AxiosPromise<Array<InstrumentSearchResultV3>> {
            return localVarFp.searchRealAssetInstruments(externalAccountId, name, assetClass, status, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncome(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, instrumentSourceId: string, options?: any): AxiosPromise<Array<DatedValueDto>> {
            return localVarFp.updateIncome(datedValueUpdateRequest, externalAccountId, instrumentSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrices(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, instrumentSourceId: string, options?: any): AxiosPromise<Array<DatedValueDto>> {
            return localVarFp.updatePrices(datedValueUpdateRequest, externalAccountId, instrumentSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RealAssetUpdateRequest} realAssetUpdateRequest 
         * @param {string} externalAccountId 
         * @param {string} instrumentSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRealAsset(realAssetUpdateRequest: RealAssetUpdateRequest, externalAccountId: string, instrumentSourceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateRealAsset(realAssetUpdateRequest, externalAccountId, instrumentSourceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealAssetsApi - object-oriented interface
 * @export
 * @class RealAssetsApi
 * @extends {BaseAPI}
 */
export class RealAssetsApi extends BaseAPI {
    /**
     * 
     * @summary Add a new real asset
     * @param {RealAssetInstrumentCreateRequest} realAssetInstrumentCreateRequest 
     * @param {string} externalAccountId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealAssetsApi
     */
    public addRealAsset(realAssetInstrumentCreateRequest: RealAssetInstrumentCreateRequest, externalAccountId: string, name: string, options?: AxiosRequestConfig) {
        return RealAssetsApiFp(this.configuration).addRealAsset(realAssetInstrumentCreateRequest, externalAccountId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} instrumentSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealAssetsApi
     */
    public getIncome(externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig) {
        return RealAssetsApiFp(this.configuration).getIncome(externalAccountId, instrumentSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} instrumentSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealAssetsApi
     */
    public getInstrument(externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig) {
        return RealAssetsApiFp(this.configuration).getInstrument(externalAccountId, instrumentSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} instrumentSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealAssetsApi
     */
    public getPrices(externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig) {
        return RealAssetsApiFp(this.configuration).getPrices(externalAccountId, instrumentSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {string} name 
     * @param {'CashAndEquivalents' | 'Equities' | 'FixedIncome' | 'RealEstate' | 'Alternatives' | 'Commodities' | 'RealAssets' | 'CryptoCurrencies' | 'SubscriptionWithdrawal' | 'Funds' | 'Adjustments' | 'CFD' | 'Fx'} [assetClass] 
     * @param {'DRAFT' | 'ACTIVE' | 'INACTIVE' | 'DELISTED' | 'SUSPENDED' | 'DEPRECATED' | 'ERROR'} [status] 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealAssetsApi
     */
    public searchRealAssetInstruments(externalAccountId: string, name: string, assetClass?: 'CashAndEquivalents' | 'Equities' | 'FixedIncome' | 'RealEstate' | 'Alternatives' | 'Commodities' | 'RealAssets' | 'CryptoCurrencies' | 'SubscriptionWithdrawal' | 'Funds' | 'Adjustments' | 'CFD' | 'Fx', status?: 'DRAFT' | 'ACTIVE' | 'INACTIVE' | 'DELISTED' | 'SUSPENDED' | 'DEPRECATED' | 'ERROR', searchTerm?: string, options?: AxiosRequestConfig) {
        return RealAssetsApiFp(this.configuration).searchRealAssetInstruments(externalAccountId, name, assetClass, status, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
     * @param {string} externalAccountId 
     * @param {string} instrumentSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealAssetsApi
     */
    public updateIncome(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig) {
        return RealAssetsApiFp(this.configuration).updateIncome(datedValueUpdateRequest, externalAccountId, instrumentSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DatedValueUpdateRequest} datedValueUpdateRequest 
     * @param {string} externalAccountId 
     * @param {string} instrumentSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealAssetsApi
     */
    public updatePrices(datedValueUpdateRequest: DatedValueUpdateRequest, externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig) {
        return RealAssetsApiFp(this.configuration).updatePrices(datedValueUpdateRequest, externalAccountId, instrumentSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RealAssetUpdateRequest} realAssetUpdateRequest 
     * @param {string} externalAccountId 
     * @param {string} instrumentSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealAssetsApi
     */
    public updateRealAsset(realAssetUpdateRequest: RealAssetUpdateRequest, externalAccountId: string, instrumentSourceId: string, options?: AxiosRequestConfig) {
        return RealAssetsApiFp(this.configuration).updateRealAsset(realAssetUpdateRequest, externalAccountId, instrumentSourceId, options).then((request) => request(this.axios, this.basePath));
    }
}
