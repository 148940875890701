import React from "react";
import { PortfolioResponse } from "../../../../generated-api-v3";
import Loader from "../Loader/Loader";
import BigButtonWithIcon from "../BigButtonWithIcon/BigButtonWithIcon";
import s from "./PortfolioGrid.module.scss";
import clockIcon from "./assets/clock.svg";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { usePortfolioListGridSharedLogic } from "@iliotech/data-wire";
interface IOwnProps {
  portfolios?: PortfolioResponse[];
  loading?: boolean;
}

export const PortfolioGrid = ({ portfolios = [], loading }: IOwnProps) => {
  const { visiblePortfolios, showExpand, setExpanded, description } =
    usePortfolioListGridSharedLogic({ portfolios });

  if (loading) {
    return <Loader />;
  }
  if (!portfolios.length) {
    return (
      <div className={s.empty}>
        <p>You still have not created any portfolios.</p>
        <p>Use the "Create portfolio" button above to get started.</p>
      </div>
    );
  }
  return (
    <div>
      <div className={s.portfolioList}>
        {visiblePortfolios?.map((p) => (
          <BigButtonWithIcon
            key={p.id}
            href={portfolioLink(p.id)}
            extraClass={s.button}
            title={p.portfolioName}
            titleIcon={
              description(p).awaitingIntegrations ? (
                <Tooltip openDelay={100} anchorElement={"target"}>
                  <img
                    src={clockIcon}
                    title={"Your imported trades will be ready soon"}
                  />
                </Tooltip>
              ) : null
            }
            text={description(p).description || p.description || ""}
          />
        ))}
      </div>
      {showExpand && (
        <button
          style={{ width: 120, marginTop: "1rem" }}
          onClick={() => setExpanded(true)}
        >
          Show all
        </button>
      )}
    </div>
  );
};

const portfolioLink = (id: string) => `/portfolio/${id}`;
