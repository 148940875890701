import React from "react";
import cn from "classnames";
import s from "./SearchBox.module.scss";

import { FaSearch, FaTimes } from "react-icons/fa";

interface IOwnProps {
  value: string;
  onChange(nextValue: string): void;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  style?: React.CSSProperties;
  dataCy?: string;
}

export const SearchBox = ({
  value,
  onChange,
  placeholder = "Search Instruments",
  className,
  inputClassName,
  dataCy,
  style,
}: IOwnProps) => {
  const searchInput = React.useRef<HTMLInputElement>(null);

  return (
    <div
      className={cn(s.searchSurround, className)}
      onClick={() => searchInput.current?.focus()}
      style={style}
    >
      <FaSearch className={cn(s.searchIcon)} />
      <input
        data-cy={dataCy}
        className={cn(s.search, inputClassName)}
        ref={searchInput}
        placeholder={placeholder}
        type={"text"}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      {!!value && (
        <FaTimes
          onClick={() => {
            onChange("");
            searchInput.current?.focus();
          }}
          className={cn(s.clearSearch)}
        />
      )}
    </div>
  );
};
// SearchBox.defaultProps = {
//   placeholder: "Search Instruments"
// }
