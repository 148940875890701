import React from "react";
import s from "./AutocompleteAsync.module.scss";
import cn from "classnames";
import {
  ComboBox,
  ComboBoxProps,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";

interface IOwnProps extends ComboBoxProps {
  filterData: (filter: string) => Promise<any[]>;
  emptyMessage?: string;
}

export function AutocompleteAsync({
  filterData,
  data,
  emptyMessage = "Your query did not produce any results.",
  ...rest
}: IOwnProps) {
  const [localData, setLocalData] = React.useState(data);
  const [insertMore, setInsertMore] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const pendingRequest = React.useRef<any>();
  const input = React.useRef<any>();

  React.useEffect(() => {
    if (data?.length) {
      setLocalData(data.slice());
    }
  }, [JSON.stringify(data)]);

  const debouncedFetch = async (search: string) => {
    const fetchData = filterData;
    setLoading(true);
    const data = await fetchData(search);
    setLocalData(data);
    setLoading(false);
  };

  const filterChange = async (event: ComboBoxFilterChangeEvent) => {
    const search = event.filter.value;
    if (search.length < 1) {
      setLocalData([]);
      setInsertMore(true);
      return;
    }
    if (pendingRequest.current) {
      clearTimeout(pendingRequest.current);
    }
    pendingRequest.current = setTimeout(() => {
      debouncedFetch(search);
      setInsertMore(false);
    }, 500);
  };

  React.useEffect(() => {
    if (rest?.opened) {
      input.current?.focus?.();
    }
  }, [rest?.opened]);

  return (
    <ComboBox
      ref={input}
      className={cn(s.autocomplete)}
      popupSettings={{ popupClass: "illio-combo" }}
      listNoDataRender={() => (
        <span className={s.noData}>
          {loading
            ? "Searching..."
            : insertMore
            ? "Please enter a character to search."
            : emptyMessage}
        </span>
      )}
      size={"medium"}
      rounded={"large"}
      data={localData}
      loading={loading}
      onFilterChange={filterChange}
      filterable={true}
      {...rest}
    />
  );
}
