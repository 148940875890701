import * as React from "react";
import s from "./ConnectDetailsScreen.module.scss";
import { Typography } from "@progress/kendo-react-common";
import RoundButton from "../../../RoundButton/RoundButton";
import { BsShieldFillCheck } from "react-icons/all";
import { BrokerProvider } from "../../../../../../generated-api-v3";
import { useEffect, useState } from "react";
import { getIconUrl } from "../../../../../../data-wire";
import plaid from "./Plaid.png";
interface IProps {
  broker: BrokerProvider;
  onConnect: (broker: BrokerProvider) => Promise<void>;
  goBack: () => void;
  createPortfolioManually?: () => Promise<void>;
  overrideContent?: JSX.Element;
}

export const ConnectDetailsScreen = ({
  broker,
  goBack,
  onConnect,
  createPortfolioManually,
  overrideContent,
}: IProps) => {
  useEffect(() => {
    (window as any).goBack = goBack;
  }, []);
  const [loading, setLoading] = useState("");
  const [modal, setModal] = useState(false);
  const createManually = async () => {
    setLoading("Cancel");
    await createPortfolioManually?.();
    setLoading("");
  };

  const brokerContent = React.useMemo(() => {
    return (
      <>
        <Typography.h6
          style={{ fontSize: "1.2rem" }}
          className={"element-title"}
        >
          Account info
        </Typography.h6>
        <Typography.p style={{ marginTop: 10 }}>
          Please note that connected broker accounts vary because not every
          broker exposes your complete transaction history. Certain transactions
          like margin trades, conversions, wire transfers may not be reported.
        </Typography.p>
        <Typography.h6
          style={{ fontSize: "1.2rem", marginTop: 25 }}
          className={"element-title"}
        >
          Supported
        </Typography.h6>
        <ul>
          <li>Private (illio will not have access to your log in details)</li>
          <li>Secure connection (end-to-end encrypted)</li>
          <li>Current balances</li>
          <li>Historical transactions</li>
          <li>New transactions</li>
        </ul>
      </>
    );
  }, []);

  const connectBroker = async () => {
    setLoading("Connect");
    await onConnect(broker);
    setLoading("");
  };

  return (
    <div>
      <Typography.h6 className={"element-title"}>
        Connect to {broker.name}{" "}
        <span style={{ color: "#AAAAAA" }} className={s.provider}>
          Via {broker.brokerName}
        </span>
      </Typography.h6>
      <Typography.p>Start a secure connection to {broker.name}</Typography.p>
      <div className={s.split}>
        <div
          style={{
            backgroundImage: broker.icon
              ? `url(${getIconUrl(broker.icon!)})`
              : `url(${plaid})`,
          }}
          className={s.image}
        />
        <div className={s.content}>{overrideContent || brokerContent}</div>
      </div>
      <div className={s.buttons}>
        <RoundButton
          loading={loading === "Cancel"}
          onClick={createPortfolioManually ? createManually : goBack}
          fillMode={"outline"}
        >
          Cancel
        </RoundButton>
        <RoundButton
          loading={loading === "Connect"}
          onClick={connectBroker}
          style={{ marginLeft: 8 }}
        >
          <BsShieldFillCheck
            style={{ marginRight: 5, marginTop: -3 }}
            size={18}
            color={"white"}
          />
          Start secure connection
        </RoundButton>
      </div>
    </div>
  );
};

export default ConnectDetailsScreen;
