"use client";

import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

const useAppLoadingLogic = () => {
  const [loading, setLoading] = useState(false);

  return {
    loading,
    setLoading,
  };
};

const AppLoadingContext =
  createContext<ReturnType<typeof useAppLoadingLogic> | null>(null);

export const AppLoadingContextProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const { loading, setLoading } = useAppLoadingLogic();
  return (
    <AppLoadingContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </AppLoadingContext.Provider>
  );
};

export const useAppLoadingContext = () => {
  const value = useContext(AppLoadingContext);
  if (!value) {
    throw new Error("AppLoadingContext is not initialized");
  }
  return value;
};
