import { useWhatIfPositions } from "@iliotech/data-wire/src/stores";
import { WhatIfPositionsTableCellProps } from "../WhatIfPositionsTable";
import { observer } from "mobx-react-lite";

const OriginalQuantityCell = ({ index }: WhatIfPositionsTableCellProps) => {
  const store = useWhatIfPositions();
  const currentPrice = store.getField<number>("oldQuantity", index);

  const formattedValue = currentPrice
    ? currentPrice.toLocaleString(undefined, {
        maximumFractionDigits: 6,
      })
    : "";

  return <div>{formattedValue || 0}</div>;
};

export default observer(OriginalQuantityCell);
