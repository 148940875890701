import * as React from "react";
import s from "./CssSpinner.module.scss";
import classNames from "classnames";
export interface CssSpinnerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string;
}

export const CssSpinner = ({ className, ...props }: CssSpinnerProps) => {
  return <div className={classNames(s.spinner, className)} {...props}></div>;
};

export default CssSpinner;
