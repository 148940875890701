import React from 'react';
import {ResetPasswordComponent} from "@iliotech/component-library";
import {Auth} from "aws-amplify";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router";
// import {SetupOTP} from "./SetupOTP";


export const Security = () => {
  const {user} = useAuthenticator(context => [context.user]);
  const navigate = useNavigate();
  
  const onSubmit = async (currentPassword: string, newPassword: string, onSuccess?: () => void) => {
    return Auth.changePassword(user, currentPassword, newPassword)
      .then(() => {
        onSuccess?.()
        navigate("/");
      })
  }
  
  return (
    <>
      <ResetPasswordComponent onSubmit={onSubmit}/>
      {/*<SetupOTP />*/}
    </>
  );
};
