import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import cn from "classnames";
import s from "./PlainTooltip.module.scss";

type TooltipProps = {
  content: React.JSX.Element | string | ReactElement | ReactNode | null;
  style?: React.CSSProperties;
};

export const PlainTooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  content,
  children,
  style,
}) => {
  const [tooltipStyle, setTooltipStyle] = useState({});
  const ref = React.useRef<HTMLDivElement>(null);

  const handleMouseOver = (e: React.MouseEvent) => {
    const bounds = e.currentTarget.getBoundingClientRect();

    setTooltipStyle({
      left: e.clientX - bounds.left + 15 + "px",
      top: e.clientY - bounds.top + "px",
      // top: (ref.current?.getBoundingClientRect()?.top || 0) + 'px'
      // top: e.pageY - 15 - (ref.current?.getBoundingClientRect()?.top || 0) + 'px'
      // top: e.pageY + 15 - () + 'px'
    });
  };

  const handleMouseOut = () => {
    setTooltipStyle({});
  };

  if (!content) return <>{children}</>;

  return (
    <div
      className={cn(s.tooltipContainer)}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      style={style}
      ref={ref}
    >
      {children}
      {!!content && (
        <div className={cn(s.tooltipContent)} style={tooltipStyle}>
          {content}
        </div>
      )}
    </div>
  );
};
