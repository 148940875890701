import React from 'react';
import {IColumn, IllioTable} from '..';
import {AssetVolatilityData, IVolatilityProps} from "./types";
import {BANDS} from "./VolatilityBreakdownSettings";


export const VolatilityBreakdownTable = (props: IVolatilityProps) => {
  
  const dataIn = props.data;
  
  if(!dataIn?.portfolio){
    return null;
  }
  
  const data = [
    ...dataIn.portfolio?.assets
  ]
  
  return (
    <div style={{width: props.width}}>
      <IllioTable data={data} columns={COLUMNS} useColumnChooser={true} />
    </div>
  );
};


const COLUMNS: IColumn<AssetVolatilityData>[] = [
  {
    field: "name",
    title: "Item",
    isDefault: true,
  },
  {
    field: "weight",
    title: "Portfolio Weight",
    isDefault: true,
  },
  {
    field: "riskBand",
    title: "Risk Band",
    isDefault: true,
    renderer: ({dataItem, style}) => {
      return <td style={{...style, backgroundColor: BANDS[dataItem.riskBand - 1]?.color}}>{dataItem.riskBand}</td>
    }
  }
]
