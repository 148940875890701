import * as React from "react";
import { DatePicker } from "../../DatePicker/DatePicker";
import { DatePickerProps } from "@progress/kendo-react-dateinputs/dist/es/datepicker/DatePicker";
import InputWrapper from "../../InputWrapper/InputWrapper";
import s from "./IllioDatePicker.module.scss";
export interface IllioDatePickerProps extends DatePickerProps {
  error?: string;
  label?: string;
  visited?: boolean;
  name: string;
  dataCy?: string;
  errorOverride?: boolean;
}

export const IllioDatePicker = ({
  error,
  label,
  name,
  visited,
  dataCy,
  errorOverride,
  ...restProps
}: IllioDatePickerProps) => {
  const isError = (visited && error) || errorOverride;
  return (
    <div className="illio-input" data-cy={dataCy ?? "custom-date-field"}>
      <InputWrapper error={isError ? error : ""} label={label} name={name}>
        <DatePicker
          className={s.datePicker}
          {...restProps}
          size={"medium"}
          rounded={"large"}
          valid={!isError}
        />
      </InputWrapper>
    </div>
  );
};

export default IllioDatePicker;
