import * as React from "react";

import { observer } from "mobx-react-lite";
import { usePositionUploadV2 } from "@iliotech/data-wire/src/stores/PositionsUpload";
import { Typography } from "@progress/kendo-react-common";

export interface TransactionTableCellProps {
  index: number;
}

const formatNumber = (value: number) =>
  value?.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

const MarketPriceCell = ({ index }: TransactionTableCellProps) => {
  const store = usePositionUploadV2();

  const price = store.position.getField("historicPrice", index + "") as number;

  return <Typography.p>{formatNumber(price)}</Typography.p>;
};

export default observer(MarketPriceCell);
