import * as React from "react";
import SimpleTable from "../../SimpleTable/SimpleTable";
import { ITableRow } from "@iliotech/data-wire";
import { observer } from "mobx-react-lite";
import { useTransactionUploadV2 } from "@iliotech/data-wire/src/stores";
import { useAuth } from "@iliotech/data-wire";
import { columns } from "./TransactionUploadColumns";

import { TransactionUploadEmpty } from "./TransactionUploadEmpty";
import { FadeLoading } from "../../FadeLoading/FadeLoading";
import { useGetOrCreateRoutine, useFetchFxRateLite } from "@iliotech/data-wire";
import { useFetchPriceLite } from "@iliotech/data-wire/src/reactHooks/hooks/__transactionsUpload/useFetchPriceLite";

interface IProps {
  tableData: ITableRow[];
  baseCurrency?: string;
  workgroup?: any;
  externalAccountId?: any;
}

// find out if row has been deleted, without making the whole table an observer
const RowRenderer = observer(({ row, baseCurrency }: any) => {
  const store = useTransactionUploadV2();
  const dataItem = store.equities.tableData[row.props["data-grid-row-index"]];
  useGetOrCreateRoutine({
    dataItem,
    updateTableRow: (dataItem) => store.equities.updateTableRow(dataItem),
  });
  useFetchFxRateLite({ dataItem, baseCurrency });

  useFetchPriceLite({
    dataItem,
    updateTableRowByIndex: (key: any, value: any, id: string) =>
      store.equities.updateTableRowByIndex(key, value, id),
  });

  if (dataItem?.ticker) {
    return row;
  }
  return <></>;
});

export const TransactionsUploadTable = observer(
  ({ tableData, baseCurrency }: IProps) => {
    const finalColumns = columns(baseCurrency!);
    const { illioSelectedAccount } = useAuth();
    const store = useTransactionUploadV2();
    const loading = store.equities.loading;

    return (
      <div>
        <FadeLoading loading={loading}>
          <SimpleTable
            emptyGridRenderer={() => <TransactionUploadEmpty />}
            rowRender={(row, props) => (
              <RowRenderer
                row={row}
                girProps={props}
                externalAccountId={illioSelectedAccount?.externalAccountId}
                baseCurrency={baseCurrency}
              />
            )}
            columns={finalColumns}
            data={tableData}
          />
        </FadeLoading>
      </div>
    );
  }
);

export default TransactionsUploadTable;
