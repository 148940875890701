import {
  InstrumentPerformanceDetails,
  PortfolioAndBenchmarkPerfDataDtoV3,
} from "@iliotech/generated-api-v3";
import { IChartDataItemV2 } from "@iliotech/types";
import { PORTFOLIO_BENCHMARK_COLOR } from "@iliotech/data-wire";
import { IBenchmarkItem } from "../../../ToggableButton";

export const mapPerformanceV3: (
  data: PortfolioAndBenchmarkPerfDataDtoV3 | undefined
) => IChartDataItemV2[] = (data) => {
  return [
    ...(data?.portfolioData || []).map((item) => ({
      value: item.twr,
      date: item.date,
      name: data?.name!,
      category: data?.name!,
      id: data?.name!,
      color: PORTFOLIO_BENCHMARK_COLOR,
    })),
  ];
};

export const mapBenchmarkItems: (
  data: InstrumentPerformanceDetails[],
  benchmarks: IBenchmarkItem[]
) => IChartDataItemV2[] = (data = [], benchmarks) => {
  const toRet: IChartDataItemV2[] = [];
  (data || []).forEach((benchmarkItem, index) => {
    const foundBenchmark = benchmarks.find(
      (b) => b.value === benchmarkItem.code
    );
    const dataToAdd = ((benchmarkItem as any)?.data || []).map((item: any) => ({
      value: item[1],
      date: item[0],
      name: foundBenchmark?.label,
      category: foundBenchmark?.label,
      id: benchmarkItem.code!,
      color: foundBenchmark?.color,
    }));
    toRet.push(...dataToAdd);
  });
  return toRet;
};
