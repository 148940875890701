import * as React from "react";
import { Dialog, DialogProps } from "@progress/kendo-react-dialogs";

interface IProps extends DialogProps {
  children: React.ReactNode | React.ReactNode[];
}

const IllioDialog = ({ children, ...rest }: IProps) => {
  return (
    <Dialog
      appendTo={document.getElementById("app")}
      className={"illio-dialog"}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export default IllioDialog;
