import { useEffect, useState } from "react";
import _ from "lodash";
import { IChartDataItemV2 } from "@iliotech/types";

interface IProps {
  valueFormatter: (value: number) => string;
  chartData: IChartDataItemV2[];
  toggableTotal?: boolean;
  categoryTotalOverride?: (
    data: IChartDataItemV2[],
    category: string
  ) => number;
}

function inject({
  chartData,
  toggledData,
  setToggledData,
  isItemToggled,
  hideTotal,
  setHideTotal,
  valueFormatter,
  toggableTotal,
}: {
  toggledData: IChartDataItemV2[];
  setToggledData: (data: IChartDataItemV2[]) => void;
  setHideTotal: (isHidden: boolean) => void;
  valueFormatter: (value: number) => string;
  chartData: IChartDataItemV2[];
  hideTotal: boolean;
  isItemToggled: (val: string) => boolean;
  toggableTotal?: boolean;
  categoryTotalOverride?:
    | ((data: IChartDataItemV2[], category: string) => number)
    | undefined;
}) {
  function updateToggleData(newData: IChartDataItemV2[]) {
    setToggledData(
      newData.sort((a, b) => Math.abs(b.value) - Math.abs(a.value))
    );
  }

  function removeItem(key: string) {
    updateToggleData([...toggledData.filter((item) => item.category !== key)]);
  }

  function addItem(key: string) {
    const itemsToAdd = chartData.filter(
      (item: IChartDataItemV2) => item.category === key
    );

    if (itemsToAdd) {
      updateToggleData([...toggledData, ...itemsToAdd]);
    }
  }

  function toggleTotal() {
    setHideTotal(!hideTotal);
  }

  function onLegendItemClick(key: string) {
    if (key === "Total" && toggableTotal) {
      toggleTotal();
      return;
    }
    const hasItem = isItemToggled(key);

    if (hasItem) {
      removeItem(key);
    } else {
      addItem(key);
    }
  }

  return {
    addItem,
    removeItem,
    toggleTotal,
    onLegendItemClick,
    valueFormatter,
  };
}

function getLegend(
  toggledData: IChartDataItemV2[],
  chartData: IChartDataItemV2[],
  hideTotal: boolean,
  valueFormatter: (val: number) => string,
  isItemToggled: (val: string) => boolean,
  categoryTotalOverride?:
    | ((data: IChartDataItemV2[], category: string) => number)
    | undefined
) {
  // get categories in chartData
  const categoriesWithDuplicates: { color: string; category: string }[] = (
    chartData || []
  )?.map((item) => ({
    category: item?.category,
    color: item?.color,
  }));

  // remove uplicates

  const uniqueArray = (a: any[]) =>
    // @ts-ignore
    [...new Set(a.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));
  const categories = uniqueArray(categoriesWithDuplicates || []);

  let categoryTotal = (data: IChartDataItemV2[], category: string) =>
    (data || [])
      .filter((item) => item?.category === category)
      .reduce((acc, curr) => acc + curr?.value, 0);

  const total = (toggledData || []).reduce(
    (acc, curr) => acc + curr?.value ?? 0,
    0
  );

  const assembledLegend = [
    {
      label: "Total",
      value: valueFormatter(total),
      color: hideTotal ? "white" : "#a9b2d1",
    },
  ];

  categories.forEach((item) => {
    assembledLegend.push({
      label: item?.category,
      color: isItemToggled(item?.category) ? item?.color : "transparent",
      value: valueFormatter(
        categoryTotalOverride
          ? categoryTotalOverride(chartData, item?.category)
          : categoryTotal(chartData, item?.category)
      ),
    });
  });

  return assembledLegend;
}

export const useToggableLegendV2: (props: IProps) => {
  toggledData: IChartDataItemV2[];
  legend: {
    label: string;
    value: string;
    color: string;
  }[];
  onLegendItemClick: (item: string) => void;
} = ({
  chartData,
  valueFormatter,
  toggableTotal,
  categoryTotalOverride,
}: IProps) => {
  const [toggledData, setToggledData] = useState<IChartDataItemV2[]>(chartData);
  const [hideTotal, setHideTotal] = useState<boolean>(false);
  const [legend, setLegend] = useState<
    { color: string; label: string; value: string }[]
  >([]);
  // These values will refresh if the values above refresh
  const { onLegendItemClick } = inject({
    chartData,
    toggledData,
    setToggledData,
    isItemToggled: (key: string) =>
      toggledData.some((item) => item?.category === key),
    hideTotal,
    setHideTotal,
    valueFormatter,
    toggableTotal,
  });

  useEffect(() => {
    setToggledData(chartData);
  }, [chartData]);

  useEffect(() => {
    const isItemToggled = (key: string) =>
      toggledData.some((item) => item?.category === key);
    const currentLegend = getLegend(
      toggledData,
      chartData,
      hideTotal,
      valueFormatter,
      isItemToggled,
      categoryTotalOverride
    );
    const sameLegend = _.isEqual(currentLegend, legend);

    if (!sameLegend) {
      setLegend(currentLegend);
    }
  }, [toggledData, chartData, hideTotal, valueFormatter, legend]);

  return { toggledData, legend, onLegendItemClick };
};
