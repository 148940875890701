import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { notEmptyDropDown, notEmptyValidator } from "@iliotech/data-wire";
import * as React from "react";
import { AutocompleteProps } from "../Autocomplete/Autocomplete";
import DropDownForForm from "../../DropDownForForm/DropDownForForm";
interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  allOptions?: Set<string> | undefined | { expiryDate: string }[];
  onChange?: any;
}

export const OptionsDateSearchField = ({
  errors,
  name = "expiryDate",
  label = "Expiry Date",
  defaultValue,
  allOptions,
  onChange,
  ...rest
}: IOwnProps) => {
  const memoData = React.useMemo(
    () => [...((allOptions ?? []) as { expiryDate: string }[])],
    [allOptions]
  );
  if (!allOptions) return null;

  return (
    <Field
      component={DropDownForForm}
      label={label}
      name={name}
      error={errors?.[name]}
      onChange={onChange}
      defaultValue={defaultValue}
      dataItemKey={"expiryDate"}
      textField={"expiryDate"}
      placeholder={"Enter Expiry Date"}
      validator={notEmptyDropDown}
      data={memoData}
    />
  );
};
