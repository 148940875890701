import React, { useEffect } from "react";
import cn from "classnames";
import s from "./ModalLayout.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import ModalBackButton from "../ModalBackButton/ModalBackButton";

interface IOwnProps {
  backToPortfolio?: boolean;
  hideBack?: boolean;
}

interface WindowWithGoBack extends Window {
  goBack?: undefined | Function;
}

const BUTTON_BLACKLIST: string[] = [
  "profile",
  "/add-investments",
  "/add-investments/onboarding",
  "/what-if/positions",
  "/what-if/results",
];

// show back button instead of close in the add-investmetns pages
const BACK_BUTTON_PAGES: string[] = [
  "/add-investments/bonds",
  "/add-investments/crypto",
  "/add-investments/equity",
  "/add-investments/cash-transactions",
  "/add-investments/fx-spot",
  "/add-investments/cash-adjustments",
  "/add-investments/cash-transfers",
  "/add-investments/options",
  "/add-investments/real-estate",
  "/add-investments/real-asset",
  "/add-investments/mortgage",
  "/add-investments/unlisted-equity",
  "/add-investments/unlisted-options",
  "/add-investments/connectBroker",
  "/add-investments/positions/uploadFile",
  "/add-investments/positions/positionUpload",
];

export const ModalLayout: React.FC<IOwnProps> = ({
  children,
  backToPortfolio,
  hideBack,
}) => {
  var windowWithGoBack: WindowWithGoBack = window;
  const navigate = useNavigate();
  const location = useLocation();

  const { portfolioId } = useParams<{ portfolioId: string }>();

  useEffect(() => {
    // resetting goBack override on every page change
    (window as any).goBack = undefined;
  }, [location.pathname]);

  const showButton =
    !hideBack &&
    !BUTTON_BLACKLIST.some((path) => window.location.pathname.endsWith(path));

  // list of pages where we show a back button instead of a close button
  const showBack = BACK_BUTTON_PAGES.some((path) =>
    window.location.pathname.endsWith(path)
  );

  // if we have overridden the function to go back, we use that instead
  const goBack = () => {
    if (backToPortfolio && !!portfolioId && !showBack) {
      navigate(`/portfolio/${portfolioId}`);
      return;
    }
    if (windowWithGoBack?.goBack) {
      windowWithGoBack.goBack();
      windowWithGoBack.goBack = undefined;
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={cn(s.surround)}>
      <div style={{ flex: 1 }}>{children}</div>
      {showButton &&
        (showBack ? (
          <ModalBackButton
            extraClass={s.close}
            onPress={goBack}
            text={"Back"}
          />
        ) : (
          <ModalCloseButton
            extraClass={s.close}
            onPress={goBack}
            text={"Close"}
          />
        ))}
    </div>
  );
};
