import React from "react";
import { Input } from "../../FormInputs/Input/Input";
import cn from "classnames";
import s from "../styles/PortfolioSettings.module.scss";
import { PortfolioDTOWithDetailsV3 } from "../../../../../generated-api-v3";
import Button from "../../Button/Button";

interface IOwnProps {
  portfolioInfo?: PortfolioDTOWithDetailsV3;
  loading?: boolean;
  saveName(nextName: string): Promise<any>;
}

export const PortfolioSettingsDetails = ({
  portfolioInfo,
  saveName,
}: IOwnProps) => {
  const [portfolioName, setPortfolioName] = React.useState(portfolioInfo?.name);
  const [dirty, setDirty] = React.useState(false);
  const [working, setWorking] = React.useState(false);
  React.useEffect(() => {
    if (portfolioInfo?.name) {
      setPortfolioName(portfolioInfo?.name);
    }
  }, [portfolioInfo?.name]);

  const showSaveNameButton =
    dirty && !!portfolioName && portfolioName !== portfolioInfo?.name;

  const handleSaveName = () => {
    setWorking(true);
    saveName(portfolioName!)
      .then((result) => {
        setDirty(false);
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        window.location.reload();
        setWorking(false);
      });
  };

  return (
    <div>
      <label>Portfolio Name</label>
      <Input
        name={""}
        value={portfolioName}
        onChange={(event) => {
          setDirty(true);
          setPortfolioName(String(event.target.value));
        }}
      />
      {showSaveNameButton && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0.5rem",
          }}
        >
          <Button
            style={{
              backgroundColor: "var(--primary)",
              color: "var(--primary-inverse)",
            }}
            disabled={working}
            onClick={handleSaveName}
          >
            Save portfolio name
          </Button>
        </div>
      )}
      <div className={cn(s.nameErrors)}></div>
      <label>Portfolio currency</label>
      <input disabled value={portfolioInfo?.currencyCode} />
    </div>
  );
};
