export const LOCAL_STORAGE_KEYS: Record<string, string> = {
  ILLIO_SELECTED_ACCOUNT: "illio-selected-account",
};

export const DEFAULT_LOGO_SOURCE =
  "https://uploads-ssl.webflow.com/60b7a1112331947d8a44a36d/60b7a472b76004ab6b185470_illio-logo.svg";

export const PUSHER_ID: any =
  import.meta.env.VITE_PUSHER_ID || window.environmentSettings.pusherId;
export const PUSHER_CLUSTER: any =
  import.meta.env.VITE_PUSHER_CLUSTER ||
  window.environmentSettings.pusherCluster ||
  "eu";

export const SYSADMIN_BASE_URL =
  (import.meta.env.illioApiUrl || window.environmentSettings.illioApiUrl) ===
  "${VITE_ILLIO_API_URL}"
    ? "https://dev.illio.com/admin"
    : (import.meta.env.illioApiUrl || window.environmentSettings.illioApiUrl) +
      "/admin";

export const BLUE_BASE_URL =
  (import.meta.env.illioApiUrl || window.environmentSettings.illioApiUrl) ===
  "${VITE_ILLIO_API_URL}"
    ? "https://dev.illio.com"
    : import.meta.env.illioApiUrl || window.environmentSettings.illioApiUrl;
