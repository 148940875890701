import React, { useState } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { usePortfolio, usePortfolioPrivateEquities } from "../api";
import { BundlesEnum, useAuth } from "./AuthContext";
import { useAccountMenuItems } from "../hooks";

export const NavigationContext =
  React.createContext<ReturnType<typeof useNavigationContextHook> | undefined>(
    undefined
  );

const useNavigationContextHook = () => {
  const portfolioId = useParams<{ portfolioId: string }>().portfolioId;
  const makeHref = (href: string) => generatePath(href, { portfolioId });
  const portfolio = usePortfolio(portfolioId!);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const privateEquities = usePortfolioPrivateEquities(portfolioId!);
  const { pathname } = useLocation();
  const { userLimits, hasBundle } = useAuth();

  const showWhatIf =
    (userLimits?.maxWhatIfComparisons || 0) > 0 &&
    hasBundle(BundlesEnum.Manager_Functions);

  const hasPrivateEquities =
    (privateEquities?.data?.data?.positions.length || 0) > 0;
  const isActive = (url: string) => pathname.endsWith(url.toLocaleLowerCase());

  const isActiveWithTabs = (
    path: string,
    tabs: string[] = ["summary", "insight"]
  ) => isActive(path) || tabs.some((tab) => isActive(`${path}/${tab}`));

  const isCurrentPath = (url: string) =>
    generatePath(url, { portfolioId }) === pathname;

  const isParentActive = (url: string) => pathname.includes(url);
  const items = useAccountMenuItems();
  const navigate = useNavigate();

  const portfolioItems = [
    {
      label: "Dashboard",
      onClick: () => navigate(makeHref("/portfolio/:portfolioId")),
      dataCy: "dashboard",
      active: isCurrentPath("/portfolio/:portfolioId"),
    },
    {
      label: "Analytics",
      onClick: () =>
        navigate(makeHref(`/portfolio/:portfolioId/analytics/net-worth`)),
      active: isParentActive("analytics"),
      hidden: !hasBundle(BundlesEnum.Basic_View),
      subItems: [
        {
          label: "Net Worth",
          onClick: () =>
            navigate(makeHref(`/portfolio/:portfolioId/analytics/net-worth`)),
          active: isActiveWithTabs("net-worth"),
        },
        {
          label: "P&L",
          onClick: () =>
            navigate(makeHref(`/portfolio/:portfolioId/analytics/pnl`)),
          active: isActiveWithTabs("pnl", ["summary", "insight", "monthly"]),
        },
        {
          label: "Income",
          onClick: () =>
            navigate(makeHref(`/portfolio/:portfolioId/analytics/income`)),
          active: isActiveWithTabs("income"),
        },
        {
          label: "Risk",
          onClick: () =>
            navigate(makeHref(`/portfolio/:portfolioId/analytics/risk`)),
          active: isActiveWithTabs("risk"),
          hidden: !hasBundle(BundlesEnum.Advanced_View),
        },
        {
          label: "Performance",
          onClick: () =>
            navigate(makeHref(`/portfolio/:portfolioId/analytics/performance`)),
          active: isActiveWithTabs("performance", [
            "summary",
            "insight",
            "monthly",
          ]),
        },
        {
          label: "ESG",
          onClick: () =>
            navigate(makeHref(`/portfolio/:portfolioId/analytics/esg`)),
          active: isActiveWithTabs("esg"),
          hidden: !hasBundle(BundlesEnum.Advanced_View),
        },
        {
          label: "Private Equities",
          onClick: () =>
            navigate(
              makeHref(`/portfolio/:portfolioId/analytics/private-equities`)
            ),
          active: isActive("private-equities"),
          hidden: !hasPrivateEquities,
        },

        {
          label: "Options",
          onClick: () =>
            navigate(makeHref("/portfolio/:portfolioId/analytics/options")),
          active: isActive("options"),
          hidden:
            !portfolio.data?.data.hasOptions ||
            !hasBundle(BundlesEnum.Advanced_View_b),
        },

        {
          label: "Bonds",
          onClick: () =>
            navigate(makeHref("/portfolio/:portfolioId/analytics/bonds")),
          active: isActive("bonds"),
          hidden:
            !portfolio.data?.data.hasBonds ||
            !hasBundle(BundlesEnum.Advanced_View_b),
        },
      ],
    },
    {
      label: "Insights",
      onClick: () => navigate(makeHref("/portfolio/:portfolioId/insights")),
      dataCy: "insights",
      active: isCurrentPath("/portfolio/:portfolioId/insights"),
      hidden: !hasBundle(BundlesEnum.Insights_all),
    },
    {
      label: "Positions & Transactions",
      onClick: () =>
        navigate(makeHref("/portfolio/:portfolioId/snapshot/position")),
      dataCy: "positions",
      active: isParentActive("snapshot"),
      subItems: [
        {
          label: "Positions",
          onClick: () =>
            navigate(makeHref("/portfolio/:portfolioId/snapshot/position")),
          dataCy: "positions",
          active: isActive("position"),
        },
        {
          label: "Transactions",
          onClick: () =>
            navigate(makeHref("/portfolio/:portfolioId/snapshot/transaction")),
          dataCy: "transactions",
          active: isActive("transaction"),
        },
      ],
    },
    {
      label: "What-if",
      onClick: () => navigate(makeHref("/portfolio/:portfolioId/what-if")),
      dataCy: "insights",
      active: isCurrentPath("/portfolio/:portfolioId/what-if"),
      hidden: !showWhatIf,
    },
    {
      label: "Portfolio Settings",
      onClick: () => setSettingsOpen(true),
      dataCy: "settings",
    },
  ];

  const accountItems = items.map((item) => ({
    ...item,
    onClick: () =>
      item.href ? navigate(makeHref(item.href || "")) : item.onClick(),
    active: isActive(
      item.href?.split("/")?.[item.href?.split("/").length - 1] || "logout"
    ),
  }));

  const menuSections = [
    {
      items: portfolioItems,
      section: "PORTFOLIO",
    },
    {
      items: accountItems,
      section: "ACCOUNT",
    },
  ];

  const menuItems = [...portfolioItems, ...accountItems];

  const { pageTitle, subItems } = React.useMemo(() => {
    const item = menuItems.find((item) => item.active);

    return { pageTitle: item?.label, subItems: item?.subItems || [] };
  }, [pathname, menuItems]);

  return {
    menuSections,
    settingsOpen,
    setSettingsOpen,
    pageTitle,
    menuItems,
    subItems: subItems.filter((item: any) => !item.hidden),
  };
};

export const NavigationContextProvider = ({
  children,
}: React.PropsWithChildren<any>) => {
  const { ...stuff } = useNavigationContextHook();
  return (
    <NavigationContext.Provider value={{ ...stuff }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  const context = React.useContext(NavigationContext);
  if (typeof context === "undefined") {
    throw new Error(
      "Navigation Context row context can only be accessed from within it's provider"
    );
  }
  return context;
};
