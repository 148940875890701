import {
  CreateOptionTrade,
  EditTradeRequest,
  PortfolioTradesApi,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../../constants/constants";

const api = new PortfolioTradesApi(undefined, `${API_BASE}/api`);

export const addOptionTrade = (
  portfolioId: string,
  payload: CreateOptionTrade
) => {
  return api.addPortfolioOptionTrade(payload, portfolioId);
};
