import React, { FC } from "react";
import cn from "classnames";
import s from "../ReportCreator.module.scss";
import { Button } from "rsuite";
import { Checkbox } from "@progress/kendo-react-inputs";

interface IOwnProps {
  settings: Record<string, any>;
  updateSettings(newSettings: Record<string, any>): void;
}

const RiskSettings: FC<IOwnProps> = ({ settings, updateSettings }) => {
  const isAllSelected =
    !!settings.returnVolatilityDaily &&
    !!settings.returnVolatilityMonthly &&
    !!settings.returnsHistogramMonthly &&
    !!settings.returnsHistogramDaily;

  const toggleAll = () => {
    updateSettings({
      returnVolatilityDaily: !isAllSelected,
      returnVolatilityMonthly: !isAllSelected,
      returnsHistogramMonthly: !isAllSelected,
      returnsHistogramDaily: !isAllSelected,
    });
  };

  const RiskToggle = ({ field }: { field: string }) => {
    return (
      <Checkbox
        checked={settings[field]}
        onChange={({ value }) => updateSettings({ [field]: value })}
      />
    );
  };

  return (
    <div className={cn(s.advancedSettingsSection)}>
      <span className={s.sectionTitle}>P&L Risk</span>
      <br />
      <div className={cn(s.settingsRow)}>
        <Checkbox
          checked={isAllSelected}
          onChange={({ value }) => toggleAll()}
        />
        <span>Select All</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <RiskToggle field={"returnVolatilityDaily"} />
        <span>Daily Return vs Volatility Scatter</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <RiskToggle field={"returnVolatilityMonthly"} />
        <span>Monthly Return vs Volatility Scatter</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <RiskToggle field={"returnsHistogramMonthly"} />
        <span>Monthly Returns Histogram & Key Perf/Vol Stats</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <RiskToggle field={"returnsHistogramDaily"} />
        <span>Daily Returns Histogram & Key Perf/Vol Stats</span>
      </div>
    </div>
  );
};

export default RiskSettings;
