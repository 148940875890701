import { observer } from "mobx-react-lite";

import { Typography } from "@progress/kendo-react-common";
import FormModal from "@iliotech/component-library/src/components/FormModal/FormModal";
import s from "@iliotech/component-library/src/components/__modals/UploadIssueModal/UploadIssueModal.module.scss";
import RoundButton from "@iliotech/component-library/src/components/RoundButton/RoundButton";

interface IProps {
  loading: boolean;
  onSave: () => void;
  visible: boolean;
  handleClose: () => void;
  invalidTrades: any[];
  validTrades: any[];
  footerText?: string;
}

export const SubmitModal = observer(
  ({
    visible,
    handleClose,
    invalidTrades,
    validTrades,
    loading,
    onSave,
    footerText,
  }: IProps) => {
    const summary = invalidTrades?.length ? (
      <Typography.p>
        We will submit <b>{validTrades.length} valid trades</b> and ignore the{" "}
        <b>{invalidTrades.length}</b> trades with errors.
      </Typography.p>
    ) : (
      <Typography.p>
        We will submit <b>{validTrades.length} trades</b>
      </Typography.p>
    );

    return (
      <FormModal
        hideClose={true}
        visible={visible}
        handleClose={() => {}}
        footer={
          <div className={s.footer}>
            <RoundButton fillMode={"outline"} onClick={handleClose}>
              Cancel
            </RoundButton>
            <RoundButton
              disabled={loading}
              loading={loading}
              className={s.save}
              onClick={onSave}
            >
              Continue
            </RoundButton>
          </div>
        }
      >
        <div className={s.container}>
          <Typography.h3 fontWeight={"bold"} style={{ marginBottom: 25 }}>
            Submit your trades
          </Typography.h3>
          {summary}
          <b>{footerText}</b>
        </div>
      </FormModal>
    );
  }
);
