import React, { FC } from "react";
import cn from "classnames";
import s from "../ReportCreator.module.scss";
import { DropDown } from "src/main";
import { Checkbox } from "@progress/kendo-react-inputs";
import { InputNumber } from "rsuite";
import { ScenariosCoefficientsResponse } from "@iliotech/generated-api-v3";
import _ from "lodash";
import NumericInput from "src/components/FormInputs/NumericInput/NumericInput";
import { SENSITIVITY_GROUPING_OPTIONS } from "../constants";
interface IOwnProps {
  settings: Record<string, any>;
  updateSettings(newSettings: Record<string, any>): void;
  data: ScenariosCoefficientsResponse;
}

const DEFAULT_SENSITIVITY_OPTIONS = {
  equityPercent: -10,
  fxPercent: -2,
  yieldPercent: 0,
  volatilityPercent: 0,
};

const DEFAULT_GROUPING = SENSITIVITY_GROUPING_OPTIONS[0];

const SensitivitySettings: FC<IOwnProps> = ({
  settings,
  updateSettings,
  data,
}) => {
  const setDefault = () => {
    updateSettings({
      sensitivity: false,
      sensitivityGrouping: DEFAULT_GROUPING,
      sensitivityOptions: DEFAULT_SENSITIVITY_OPTIONS,
    });
  };

  const SensitivityToggle = ({ field }: { field: string }) => {
    return (
      <Checkbox
        title={field}
        checked={settings[field]}
        onChange={({ value }) => updateSettings({ [field]: value })}
      />
    );
  };

  const updateSensitivitySettings = ({
    field,
    value,
  }: {
    field: string;
    value: string;
  }) => {
    if (!value && value !== "0") {
      return;
    }
    if (value[value.length - 1] === ".") {
      return;
    }

    let newValue = value;
    if (value.includes(".")) {
      if (isNaN(parseInt(value, 10))) {
        return;
      }
      newValue = `${parseInt(value, 10)}`;
    }

    updateSettings({
      sensitivityOptions: {
        ...settings.sensitivityOptions,
        [field]: newValue,
      },
    });
  };

  return (
    <div className={cn(s.advancedSettingsSection)}>
      <span className={s.sectionTitle}>Sensitivity Analysis</span>
      <br />
      <div className={cn(s.settingsRow)}>
        <b
          style={{ cursor: "pointer", color: "var(--primary)" }}
          onClick={setDefault}
        >
          Set default
        </b>
      </div>
      <div className={cn(s.settingsRow)}>
        <SensitivityToggle field={"sensitivity"} />
        <span>Sensitivity page</span>
      </div>
      <div className={cn(s.settingsRow)}>
        <span className={s.fixedWidth}>Group by</span>
        <DropDown
          disabled={!settings.sensitivity}
          data={SENSITIVITY_GROUPING_OPTIONS}
          textField={"name"}
          dataItemKey="value"
          value={settings.sensitivityGrouping}
          onChange={({ value }) => {
            updateSettings({ sensitivityGrouping: value });
          }}
        />
      </div>
      <div className={cn(s.settingsRow)}>
        <span style={{ fontWeight: "bold", marginTop: 10 }}>
          Scenario options
        </span>
      </div>
      <div className={cn(s.settingsRow)}>
        <span className={s.fixedWidth}>Benchmark Move (%)</span>
        <NumericInput
          name="benchmarkMove"
          onChange={(v) =>
            updateSensitivitySettings({
              field: "equityPercent",
              value: v.value?.toString()!,
            })
          }
          className={s.inputNumber}
          value={settings.sensitivityOptions.equityPercent}
          defaultValue={-10}
          disabled={!settings.sensitivity}
          // onBlur={sanitize('equityPercent')}
          max={20}
          min={-20}
        />
      </div>
      <div className={cn(s.settingsRow)}>
        <span className={s.fixedWidth}>Volatility (%)</span>
        <NumericInput
          name="volatilityPercent"
          onChange={(v) =>
            updateSensitivitySettings({
              field: "volatilityPercent",
              value: v.value?.toString()!,
            })
          }
          className={s.inputNumber}
          value={settings.sensitivityOptions.volatilityPercent}
          defaultValue={25}
          disabled={!data?.affectedByVolatilityChange || !settings.sensitivity}
          max={50}
          min={-50}
        />
      </div>
      <div className={cn(s.settingsRow)}>
        <span className={s.fixedWidth}>Yield (%)</span>
        <NumericInput
          name="yieldPercent"
          onChange={(v) =>
            updateSensitivitySettings({
              field: "yieldPercent",
              value: v.value?.toString()!,
            })
          }
          className={s.inputNumber}
          value={settings.sensitivityOptions.yieldPercent}
          defaultValue={25}
          disabled={!data?.affectedByYieldChange || !settings.sensitivity}
          max={50}
          min={-50}
        />
      </div>
      <div className={cn(s.settingsRow)}>
        <span className={s.fixedWidth}>Fx vs {data?.baseCurrencyName} (%)</span>
        <NumericInput
          name="fxPercent"
          onChange={(v) =>
            updateSensitivitySettings({
              field: "fxPercent",
              value: v.value?.toString()!,
            })
          }
          className={s.inputNumber}
          value={settings.sensitivityOptions.fxPercent}
          defaultValue={-2}
          disabled={!settings.sensitivity}
          max={10}
          min={-10}
        />
      </div>
    </div>
  );
};

export default SensitivitySettings;
