import React, { PropsWithChildren } from "react";
import cn from "classnames";
import { Typography } from "@progress/kendo-react-common";

interface IOwnProps {
  title: string;
  withMarginRight?: boolean;
}

const { h6: H6 } = Typography;
export const Section = ({
  children,
  title,
  withMarginRight,
}: PropsWithChildren<IOwnProps>) => {
  return (
    <div className={"card"}>
      <H6
        className={cn(
          "element-title",
          "with-margin",
          withMarginRight && "with-margin-right"
        )}
      >
        {title}
      </H6>
      {children}
    </div>
  );
};
