import {
  PortfolioAndResourceGroupsWorkgroupsApi,
  UpdateWorkgroup,
  WorkgroupControllerApi,
  WorkgroupUpdateDto,
} from "@iliotech/generated-api-v3";
import { API_BASE } from "../constants/constants";
const api = new PortfolioAndResourceGroupsWorkgroupsApi(
  undefined,
  `${API_BASE}/api`
);

export const updateWorkgroup = (
  workgroup: UpdateWorkgroup,
  externalAccountId: string,
  workgroupId: number
) => {
  return api.updateWorkgroup(workgroup, externalAccountId, workgroupId);
};
