import { SimpleTableProps } from "src/components/SimpleTable/SimpleTable";
import { SimpleTable } from "src/main";
import { whatIfResultsColumns } from "./WhatIfResultsColumn";
import { MetricComparison } from "@iliotech/generated-api-v3";
interface IProps extends Omit<SimpleTableProps, "columns"> {
  baseCurrencySymbol: string;
  data: MetricComparison[];
}
export const WhatIfResultsTable = (props: IProps) => {
  return (
    <SimpleTable
      {...props}
      columns={whatIfResultsColumns(props?.baseCurrencySymbol!)}
    />
  );
};
