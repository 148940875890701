import { createContext, useContext } from "react";
import { PositionsTableStore } from "./PositionsTableStore";

export const positionsUploadStore = {
  position: PositionsTableStore(),
};

export const PositionsUploadStoreContext = createContext(positionsUploadStore);
export const PositionsUploadStoreContextProvider =
  PositionsUploadStoreContext.Provider;

export const usePositionUploadV2 = () => {
  return useContext(PositionsUploadStoreContext) as typeof positionsUploadStore;
};

export default positionsUploadStore;

export * from "./PositionsTableStore";
