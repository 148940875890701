/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AssetClassSpecificInstrumentMetadata } from './asset-class-specific-instrument-metadata';
import { InstrumentMetadata } from './instrument-metadata';

/**
 * 
 * @export
 * @interface Instrument
 */
export interface Instrument {
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'sourceData': InstrumentSourceDataEnum;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'sourceId': string;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'assetClass': InstrumentAssetClassEnum;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'assetSubClass': InstrumentAssetSubClassEnum;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'currency': InstrumentCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof Instrument
     */
    'pointValue': number;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'displayId'?: string;
    /**
     * 
     * @type {InstrumentMetadata}
     * @memberof Instrument
     */
    'metadata': InstrumentMetadata;
    /**
     * 
     * @type {AssetClassSpecificInstrumentMetadata}
     * @memberof Instrument
     */
    'specificMetadata'?: AssetClassSpecificInstrumentMetadata;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'status': InstrumentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'lastUpdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'riskAssetClass'?: InstrumentRiskAssetClassEnum;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'investmentVehicle'?: InstrumentInvestmentVehicleEnum;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'expiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Instrument
     */
    'createdAt': string;
}

/**
    * @export
    * @enum {string}
    */
export enum InstrumentSourceDataEnum {
    MarketEodService = 'MarketEodService',
    MarketRefinitivService = 'MarketRefinitivService',
    MarketBondService = 'MarketBondService',
    RealAssetService = 'RealAssetService',
    UserInstrumentService = 'UserInstrumentService',
    SystemInstrumentService = 'SystemInstrumentService',
    OtcService = 'OTCService',
    OptionsService = 'OptionsService',
    CustomBenchmark = 'CustomBenchmark'
}
/**
    * @export
    * @enum {string}
    */
export enum InstrumentAssetClassEnum {
    CashAndEquivalents = 'CashAndEquivalents',
    Equities = 'Equities',
    FixedIncome = 'FixedIncome',
    RealEstate = 'RealEstate',
    Alternatives = 'Alternatives',
    Commodities = 'Commodities',
    RealAssets = 'RealAssets',
    CryptoCurrencies = 'CryptoCurrencies',
    SubscriptionWithdrawal = 'SubscriptionWithdrawal',
    Funds = 'Funds',
    Adjustments = 'Adjustments',
    Cfd = 'CFD',
    Fx = 'Fx'
}
/**
    * @export
    * @enum {string}
    */
export enum InstrumentAssetSubClassEnum {
    SubCash = 'SubCash',
    CshCash = 'CshCash',
    CshTermDeposit = 'CshTermDeposit',
    CshMoneyMarket = 'CshMoneyMarket',
    CshCommercialPaper = 'CshCommercialPaper',
    CshCashFunds = 'CshCashFunds',
    AdjFees = 'AdjFees',
    AdjFxSpot = 'AdjFXSpot',
    AdjIncome = 'AdjIncome',
    AdjPnL = 'AdjPnL',
    AdjInterest = 'AdjInterest',
    EqShares = 'EqShares',
    EqEtfs = 'EqETFs',
    EqWarrants = 'EqWarrants',
    EqOptions = 'EqOptions',
    EqIndex = 'EqIndex',
    EqPreferenceShares = 'EqPreferenceShares',
    EqUnlisted = 'EqUnlisted',
    EqCfd = 'EqCfd',
    EqStruct = 'EqStruct',
    EqFutures = 'EqFutures',
    FutOptions = 'FutOptions',
    FutIndex = 'FutIndex',
    FiBonds = 'FiBonds',
    FiEtf = 'FiETF',
    FiLoans = 'FiLoans',
    FiStructuredProducts = 'FiStructuredProducts',
    FiOptions = 'FiOptions',
    FiCds = 'FiCDS',
    FiDistressedDebt = 'FiDistressedDebt',
    FiIndex = 'FiIndex',
    FiConvertibles = 'FiConvertibles',
    FiCoCosAt1sEtc = 'FiCoCosAT1sEtc',
    FiMortgage = 'FiMortgage',
    FiMoneyMarket = 'FiMoneyMarket',
    FiTermDep = 'FiTermDep',
    FiFcn = 'FiFcn',
    FiFutures = 'FiFutures',
    RePhysical = 'RePhysical',
    ReReit = 'ReREIT',
    ReEquity = 'ReEquity',
    ReLoan = 'ReLoan',
    ReIndex = 'ReIndex',
    ReEtf = 'ReETF',
    AltDirectInvestments = 'AltDirectInvestments',
    AltIndex = 'AltIndex',
    ComPhysical = 'ComPhysical',
    ComNonPhysical = 'ComNonPhysical',
    ComIndices = 'ComIndices',
    ComEtfs = 'ComETFs',
    ComOptions = 'ComOptions',
    ComCfd = 'ComCfd',
    ComStruct = 'ComStruct',
    ComFutures = 'ComFutures',
    RaArt = 'RaArt',
    RaWineAndSpirits = 'RaWineAndSpirits',
    RaMotorVehicle = 'RaMotorVehicle',
    RaYacht = 'RaYacht',
    RaJewellery = 'RaJewellery',
    RaAircraft = 'RaAircraft',
    RaOther = 'RaOther',
    RaDigital = 'RaDigital',
    RaLivestock = 'RaLivestock',
    RaHorses = 'RaHorses',
    CryptoCurrencies = 'CryptoCurrencies',
    CryptoIndex = 'CryptoIndex',
    CryptoTokens = 'CryptoTokens',
    CryptoStablecoins = 'CryptoStablecoins',
    CryptoOptions = 'CryptoOptions',
    CryptoStruct = 'CryptoStruct',
    CryptoFutures = 'CryptoFutures',
    FunEquity = 'FunEquity',
    FunEtf = 'FunETF',
    FunFixedIncome = 'FunFixedIncome',
    FunMixed = 'FunMixed',
    FunSpeciality = 'FunSpeciality',
    FunRealEstate = 'FunRealEstate',
    FunCommodity = 'FunCommodity',
    FunMoneyMarket = 'FunMoneyMarket',
    FunAlternatives = 'FunAlternatives',
    FunAltHedgeFunds = 'FunAltHedgeFunds',
    FunAltPrivateEquity = 'FunAltPrivateEquity',
    PrivateEquityVc = 'PrivateEquityVC',
    FunAltVentureCapital = 'FunAltVentureCapital',
    FunAltFundOfFunds = 'FunAltFundOfFunds',
    FunOther = 'FunOther',
    FundCfd = 'FundCfd',
    FundStruct = 'FundStruct',
    CfdOther = 'CFDOther',
    FxOptions = 'FxOptions',
    FxFwd = 'FxFwd',
    FxTarf = 'FxTarf',
    FxStruct = 'FxStruct',
    FxFutures = 'FxFutures'
}
/**
    * @export
    * @enum {string}
    */
export enum InstrumentCurrencyEnum {
    Eur = 'EUR',
    Gbp = 'GBP',
    Aud = 'AUD',
    Nzd = 'NZD',
    Usd = 'USD',
    Kwd = 'KWD',
    Jod = 'JOD',
    Chf = 'CHF',
    Cad = 'CAD',
    Sgd = 'SGD',
    Ils = 'ILS',
    Qar = 'QAR',
    Aed = 'AED',
    Sar = 'SAR',
    Pln = 'PLN',
    Myr = 'MYR',
    Brl = 'BRL',
    Ron = 'RON',
    Try = 'TRY',
    Cny = 'CNY',
    Cnh = 'CNH',
    Hkd = 'HKD',
    Nok = 'NOK',
    Sek = 'SEK',
    Zar = 'ZAR',
    Egp = 'EGP',
    Mxn = 'MXN',
    Czk = 'CZK',
    Twd = 'TWD',
    Thb = 'THB',
    Uyu = 'UYU',
    Php = 'PHP',
    Ars = 'ARS',
    Rub = 'RUB',
    Inr = 'INR',
    Jpy = 'JPY',
    Isk = 'ISK',
    Pkr = 'PKR',
    Huf = 'HUF',
    Ngn = 'NGN',
    Krw = 'KRW',
    Idr = 'IDR',
    Dkk = 'DKK',
    Vnd = 'VND',
    Clp = 'CLP',
    Gbx = 'GBX',
    Ila = 'ILA',
    Zac = 'ZAC'
}
/**
    * @export
    * @enum {string}
    */
export enum InstrumentStatusEnum {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Delisted = 'DELISTED',
    Suspended = 'SUSPENDED',
    Deprecated = 'DEPRECATED',
    Error = 'ERROR'
}
/**
    * @export
    * @enum {string}
    */
export enum InstrumentRiskAssetClassEnum {
    CashAndEquivalents = 'CashAndEquivalents',
    Equities = 'Equities',
    FixedIncome = 'FixedIncome',
    RealEstate = 'RealEstate',
    Alternatives = 'Alternatives',
    Commodities = 'Commodities',
    RealAssets = 'RealAssets',
    CryptoCurrencies = 'CryptoCurrencies',
    Futures = 'Futures',
    Adjustments = 'Adjustments',
    MixedAllocation = 'MixedAllocation',
    Fx = 'Fx'
}
/**
    * @export
    * @enum {string}
    */
export enum InstrumentInvestmentVehicleEnum {
    Etf = 'ETF',
    Fund = 'Fund',
    Physical = 'Physical',
    Option = 'Option',
    Warrant = 'Warrant',
    Loan = 'Loan',
    Bonds = 'Bonds',
    MoneyMarket = 'MoneyMarket',
    Mortgage = 'Mortgage',
    Shares = 'Shares',
    PrefShares = 'PrefShares',
    HedgeFunds = 'HedgeFunds',
    PrivateEquity = 'PrivateEquity',
    VentureCapital = 'VentureCapital',
    SpecialityFunds = 'SpecialityFunds',
    FundOfFunds = 'FundOfFunds',
    CryptoCurrencies = 'CryptoCurrencies',
    Adjustment = 'Adjustment',
    Fees = 'Fees',
    Fx = 'FX',
    Subscription = 'Subscription',
    Cash = 'Cash',
    Future = 'Future',
    RealAssets = 'RealAssets',
    TermDeposit = 'TermDeposit',
    Fcn = 'Fcn',
    StructuredProduct = 'StructuredProduct',
    Cfd = 'CFD',
    FxFwd = 'FXFwd',
    Tarf = 'Tarf'
}


