import React from "react";
import cn from "classnames";
import s from "./TabMenu.module.scss";

interface IOwnProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  tabs: {id: string, label: string}[];
}

// const tabs = [
//   { id: "details", label: "Portfolio details" },
//   { id: "custodians", label: "Custodians" },
//   { id: "benchmark", label: "Benchmark" },
// ];



export const TabMenuNav = ({
                                        setSelectedTab,
                                        selectedTab,
  tabs
                                      }: IOwnProps) => {
  return (
    <ul className={cn(s.tabs)}>
      {tabs.map((tab) => (
        <li
          className={cn(s.tab, tab.id === selectedTab && s.selected)}
          key={tab.id}
          onClick={() => setSelectedTab(tab.id)}
        >
          {tab.label}
        </li>
      ))}
    </ul>
  );
};
