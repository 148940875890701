import React from "react";
import { FormInputs } from "../../FormInputs";
import { Form, FormSubmitClickEvent } from "@progress/kendo-react-form";
import { Step } from "../Step";
import { portfolioNameValidator } from "@iliotech/data-wire";

interface IOwnProps {
  submit(portfolioName: string, currencyCode: string): void;
  portfolioName: string;
  portfolioCurrency?: any;
  onBack(): void;
  currencies: string[];
}

export const IBPreparePortfolio = ({
  submit,
  currencies,
  onBack,
  portfolioName,
  portfolioCurrency,
}: IOwnProps) => {
  const handleSubmission = ({ values, isValid }: FormSubmitClickEvent) => {
    if (isValid) {
      submit(values.name, values.currency);
    }
  };

  return (
    <Form
      onSubmitClick={handleSubmission}
      render={({ onSubmit, errors }) => {
        return (
          <Step
            currentStep={2}
            onBack={onBack}
            onNext={() => onSubmit(undefined as any)}
          >
            <p>
              In order to start aggregating your wealth, we need to setup your
              first portfolio.
            </p>
            <FormInputs.Common.NameField
              validator={portfolioNameValidator}
              defaultValue={portfolioName}
              errors={errors}
              label={"Portfolio name"}
            />
            <FormInputs.Common.CurrencySearchField
              defaultValue={portfolioCurrency}
              errors={errors}
              label={"Portfolio currency"}
              allCurrencies={currencies}
            />
          </Step>
        );
      }}
    />
  );
};
