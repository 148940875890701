/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { AccountIntegrationMapping } from '../../aggregator';
// @ts-ignore
import { AccountIntegrationMappings } from '../../aggregator';
// @ts-ignore
import { AccountIntegrations } from '../../aggregator';
// @ts-ignore
import { AccountMappingRequest } from '../../aggregator';
// @ts-ignore
import { BrokerInstituteDto } from '../../aggregator';
// @ts-ignore
import { BrokerSendEmailRequest } from '../../aggregator';
// @ts-ignore
import { CreateIntegrationPortfolioRequest } from '../../aggregator';
// @ts-ignore
import { CreateIntegrationRequest } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { IntegrationResponse } from '../../aggregator';
// @ts-ignore
import { IntegrationToken } from '../../aggregator';
// @ts-ignore
import { ReInitiateIntegrationResponse } from '../../aggregator';
/**
 * AccountBrokerManagementApi - axios parameter creator
 * @export
 */
export const AccountBrokerManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllIntegrationMapping: async (externalAccountId: string, integrationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('deleteAllIntegrationMapping', 'externalAccountId', externalAccountId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('deleteAllIntegrationMapping', 'integrationId', integrationId)
            const localVarPath = `/v3/account/{externalAccountId}/integration/{integrationId}/mapping`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegration: async (externalAccountId: string, integrationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('deleteIntegration', 'externalAccountId', externalAccountId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('deleteIntegration', 'integrationId', integrationId)
            const localVarPath = `/v3/account/{externalAccountId}/integration/{integrationId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {number} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleIntegrationMapping: async (externalAccountId: string, integrationId: number, mappingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('deleteSingleIntegrationMapping', 'externalAccountId', externalAccountId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('deleteSingleIntegrationMapping', 'integrationId', integrationId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('deleteSingleIntegrationMapping', 'mappingId', mappingId)
            const localVarPath = `/v3/account/{externalAccountId}/integration/{integrationId}/mapping/{mappingId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)))
                .replace(`{${"mappingId"}}`, encodeURIComponent(String(mappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIntegrations: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getAllIntegrations', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/integration`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerProviders: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getBrokerProviders', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/broker`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration: async (externalAccountId: string, integrationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getIntegration', 'externalAccountId', externalAccountId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getIntegration', 'integrationId', integrationId)
            const localVarPath = `/v3/account/{externalAccountId}/integration/{integrationId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {'IB' | 'PLAID' | 'VEZGO' | 'Q9' | 'ILLIO'} brokerName 
         * @param {string} [brokerProviderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationToken: async (externalAccountId: string, brokerName: 'IB' | 'PLAID' | 'VEZGO' | 'Q9' | 'ILLIO', brokerProviderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getIntegrationToken', 'externalAccountId', externalAccountId)
            // verify required parameter 'brokerName' is not null or undefined
            assertParamExists('getIntegrationToken', 'brokerName', brokerName)
            const localVarPath = `/v3/account/{externalAccountId}/broker/{brokerName}/token`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"brokerName"}}`, encodeURIComponent(String(brokerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (brokerProviderId !== undefined) {
                localVarQueryParameter['brokerProviderId'] = brokerProviderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevalidationIntegration: async (externalAccountId: string, integrationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getRevalidationIntegration', 'externalAccountId', externalAccountId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getRevalidationIntegration', 'integrationId', integrationId)
            const localVarPath = `/v3/account/{externalAccountId}/integration/{integrationId}/revalidate`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {number} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleIntegrationMapping: async (externalAccountId: string, integrationId: number, mappingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getSingleIntegrationMapping', 'externalAccountId', externalAccountId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getSingleIntegrationMapping', 'integrationId', integrationId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('getSingleIntegrationMapping', 'mappingId', mappingId)
            const localVarPath = `/v3/account/{externalAccountId}/integration/{integrationId}/mapping/{mappingId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)))
                .replace(`{${"mappingId"}}`, encodeURIComponent(String(mappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateIntegrationRequest} createIntegrationRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateIntegration: async (createIntegrationRequest: CreateIntegrationRequest, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createIntegrationRequest' is not null or undefined
            assertParamExists('initiateIntegration', 'createIntegrationRequest', createIntegrationRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('initiateIntegration', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/integration`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIntegrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateIntegrationPortfolioRequest} createIntegrationPortfolioRequest 
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePortfolioIntegration: async (createIntegrationPortfolioRequest: CreateIntegrationPortfolioRequest, externalAccountId: string, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createIntegrationPortfolioRequest' is not null or undefined
            assertParamExists('initiatePortfolioIntegration', 'createIntegrationPortfolioRequest', createIntegrationPortfolioRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('initiatePortfolioIntegration', 'externalAccountId', externalAccountId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('initiatePortfolioIntegration', 'portfolioId', portfolioId)
            const localVarPath = `/v3/account/{externalAccountId}/portfolio/{portfolioId}/integration`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIntegrationPortfolioRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountMappingRequest} accountMappingRequest 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIntegrationMappings: async (accountMappingRequest: AccountMappingRequest, externalAccountId: string, integrationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountMappingRequest' is not null or undefined
            assertParamExists('saveIntegrationMappings', 'accountMappingRequest', accountMappingRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('saveIntegrationMappings', 'externalAccountId', externalAccountId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('saveIntegrationMappings', 'integrationId', integrationId)
            const localVarPath = `/v3/account/{externalAccountId}/integration/{integrationId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountMappingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrokerSendEmailRequest} brokerSendEmailRequest 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailForIntegration: async (brokerSendEmailRequest: BrokerSendEmailRequest, externalAccountId: string, integrationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerSendEmailRequest' is not null or undefined
            assertParamExists('sendEmailForIntegration', 'brokerSendEmailRequest', brokerSendEmailRequest)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('sendEmailForIntegration', 'externalAccountId', externalAccountId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('sendEmailForIntegration', 'integrationId', integrationId)
            const localVarPath = `/v3/account/{externalAccountId}/integration/{integrationId}/sendEmail`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brokerSendEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountBrokerManagementApi - functional programming interface
 * @export
 */
export const AccountBrokerManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountBrokerManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllIntegrationMapping(externalAccountId: string, integrationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllIntegrationMapping(externalAccountId, integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIntegration(externalAccountId: string, integrationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIntegration(externalAccountId, integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {number} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSingleIntegrationMapping(externalAccountId: string, integrationId: number, mappingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSingleIntegrationMapping(externalAccountId, integrationId, mappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllIntegrations(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIntegrations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllIntegrations(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrokerProviders(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrokerInstituteDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrokerProviders(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegration(externalAccountId: string, integrationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIntegrationMappings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegration(externalAccountId, integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {'IB' | 'PLAID' | 'VEZGO' | 'Q9' | 'ILLIO'} brokerName 
         * @param {string} [brokerProviderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrationToken(externalAccountId: string, brokerName: 'IB' | 'PLAID' | 'VEZGO' | 'Q9' | 'ILLIO', brokerProviderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrationToken(externalAccountId, brokerName, brokerProviderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevalidationIntegration(externalAccountId: string, integrationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReInitiateIntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevalidationIntegration(externalAccountId, integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {number} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleIntegrationMapping(externalAccountId: string, integrationId: number, mappingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIntegrationMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleIntegrationMapping(externalAccountId, integrationId, mappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateIntegrationRequest} createIntegrationRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateIntegration(createIntegrationRequest: CreateIntegrationRequest, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateIntegration(createIntegrationRequest, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateIntegrationPortfolioRequest} createIntegrationPortfolioRequest 
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiatePortfolioIntegration(createIntegrationPortfolioRequest: CreateIntegrationPortfolioRequest, externalAccountId: string, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiatePortfolioIntegration(createIntegrationPortfolioRequest, externalAccountId, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccountMappingRequest} accountMappingRequest 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveIntegrationMappings(accountMappingRequest: AccountMappingRequest, externalAccountId: string, integrationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIntegrationMappings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveIntegrationMappings(accountMappingRequest, externalAccountId, integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrokerSendEmailRequest} brokerSendEmailRequest 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailForIntegration(brokerSendEmailRequest: BrokerSendEmailRequest, externalAccountId: string, integrationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailForIntegration(brokerSendEmailRequest, externalAccountId, integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountBrokerManagementApi - factory interface
 * @export
 */
export const AccountBrokerManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountBrokerManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllIntegrationMapping(externalAccountId: string, integrationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAllIntegrationMapping(externalAccountId, integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegration(externalAccountId: string, integrationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteIntegration(externalAccountId, integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {number} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSingleIntegrationMapping(externalAccountId: string, integrationId: number, mappingId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteSingleIntegrationMapping(externalAccountId, integrationId, mappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIntegrations(externalAccountId: string, options?: any): AxiosPromise<AccountIntegrations> {
            return localVarFp.getAllIntegrations(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerProviders(externalAccountId: string, options?: any): AxiosPromise<Array<BrokerInstituteDto>> {
            return localVarFp.getBrokerProviders(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(externalAccountId: string, integrationId: number, options?: any): AxiosPromise<AccountIntegrationMappings> {
            return localVarFp.getIntegration(externalAccountId, integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {'IB' | 'PLAID' | 'VEZGO' | 'Q9' | 'ILLIO'} brokerName 
         * @param {string} [brokerProviderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationToken(externalAccountId: string, brokerName: 'IB' | 'PLAID' | 'VEZGO' | 'Q9' | 'ILLIO', brokerProviderId?: string, options?: any): AxiosPromise<IntegrationToken> {
            return localVarFp.getIntegrationToken(externalAccountId, brokerName, brokerProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevalidationIntegration(externalAccountId: string, integrationId: number, options?: any): AxiosPromise<ReInitiateIntegrationResponse> {
            return localVarFp.getRevalidationIntegration(externalAccountId, integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {number} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleIntegrationMapping(externalAccountId: string, integrationId: number, mappingId: number, options?: any): AxiosPromise<AccountIntegrationMapping> {
            return localVarFp.getSingleIntegrationMapping(externalAccountId, integrationId, mappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateIntegrationRequest} createIntegrationRequest 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateIntegration(createIntegrationRequest: CreateIntegrationRequest, externalAccountId: string, options?: any): AxiosPromise<IntegrationResponse> {
            return localVarFp.initiateIntegration(createIntegrationRequest, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateIntegrationPortfolioRequest} createIntegrationPortfolioRequest 
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePortfolioIntegration(createIntegrationPortfolioRequest: CreateIntegrationPortfolioRequest, externalAccountId: string, portfolioId: string, options?: any): AxiosPromise<IntegrationResponse> {
            return localVarFp.initiatePortfolioIntegration(createIntegrationPortfolioRequest, externalAccountId, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountMappingRequest} accountMappingRequest 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIntegrationMappings(accountMappingRequest: AccountMappingRequest, externalAccountId: string, integrationId: number, options?: any): AxiosPromise<AccountIntegrationMappings> {
            return localVarFp.saveIntegrationMappings(accountMappingRequest, externalAccountId, integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrokerSendEmailRequest} brokerSendEmailRequest 
         * @param {string} externalAccountId 
         * @param {number} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailForIntegration(brokerSendEmailRequest: BrokerSendEmailRequest, externalAccountId: string, integrationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailForIntegration(brokerSendEmailRequest, externalAccountId, integrationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountBrokerManagementApi - object-oriented interface
 * @export
 * @class AccountBrokerManagementApi
 * @extends {BaseAPI}
 */
export class AccountBrokerManagementApi extends BaseAPI {
    /**
     * 
     * @param {string} externalAccountId 
     * @param {number} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public deleteAllIntegrationMapping(externalAccountId: string, integrationId: number, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).deleteAllIntegrationMapping(externalAccountId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {number} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public deleteIntegration(externalAccountId: string, integrationId: number, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).deleteIntegration(externalAccountId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {number} integrationId 
     * @param {number} mappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public deleteSingleIntegrationMapping(externalAccountId: string, integrationId: number, mappingId: number, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).deleteSingleIntegrationMapping(externalAccountId, integrationId, mappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public getAllIntegrations(externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).getAllIntegrations(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public getBrokerProviders(externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).getBrokerProviders(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {number} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public getIntegration(externalAccountId: string, integrationId: number, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).getIntegration(externalAccountId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {'IB' | 'PLAID' | 'VEZGO' | 'Q9' | 'ILLIO'} brokerName 
     * @param {string} [brokerProviderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public getIntegrationToken(externalAccountId: string, brokerName: 'IB' | 'PLAID' | 'VEZGO' | 'Q9' | 'ILLIO', brokerProviderId?: string, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).getIntegrationToken(externalAccountId, brokerName, brokerProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {number} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public getRevalidationIntegration(externalAccountId: string, integrationId: number, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).getRevalidationIntegration(externalAccountId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {number} integrationId 
     * @param {number} mappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public getSingleIntegrationMapping(externalAccountId: string, integrationId: number, mappingId: number, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).getSingleIntegrationMapping(externalAccountId, integrationId, mappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateIntegrationRequest} createIntegrationRequest 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public initiateIntegration(createIntegrationRequest: CreateIntegrationRequest, externalAccountId: string, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).initiateIntegration(createIntegrationRequest, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateIntegrationPortfolioRequest} createIntegrationPortfolioRequest 
     * @param {string} externalAccountId 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public initiatePortfolioIntegration(createIntegrationPortfolioRequest: CreateIntegrationPortfolioRequest, externalAccountId: string, portfolioId: string, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).initiatePortfolioIntegration(createIntegrationPortfolioRequest, externalAccountId, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountMappingRequest} accountMappingRequest 
     * @param {string} externalAccountId 
     * @param {number} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public saveIntegrationMappings(accountMappingRequest: AccountMappingRequest, externalAccountId: string, integrationId: number, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).saveIntegrationMappings(accountMappingRequest, externalAccountId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrokerSendEmailRequest} brokerSendEmailRequest 
     * @param {string} externalAccountId 
     * @param {number} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountBrokerManagementApi
     */
    public sendEmailForIntegration(brokerSendEmailRequest: BrokerSendEmailRequest, externalAccountId: string, integrationId: number, options?: AxiosRequestConfig) {
        return AccountBrokerManagementApiFp(this.configuration).sendEmailForIntegration(brokerSendEmailRequest, externalAccountId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }
}
