import { useWhatIfPositions } from "@iliotech/data-wire/src/stores";
import { WhatIfPositionsTableCellProps } from "../WhatIfPositionsTable";
import { observer } from "mobx-react-lite";

const PortfolioPercentageCell = ({ index }: WhatIfPositionsTableCellProps) => {
  const store = useWhatIfPositions();
  const portfolioPercentage = store.getField<number>(
    "portfolioPercentage",
    index
  );

  const formattedPercentage = portfolioPercentage
    ? Math.abs(portfolioPercentage).toLocaleString(undefined, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }) + "%"
    : "";

  return <div>{formattedPercentage}</div>;
};

export default observer(PortfolioPercentageCell);
