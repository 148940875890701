const getValueColor = (v: string) => {
  switch (v) {
    case "High":
      return "var(--red)";
      break;
    case "Weak":
      return "var(--red)";
      break;
    case "Fair":
      return "var(--amber)";
      break;
    case "Medium":
      return "var(--amber)";
      break;
    case "Strong":
      return "var(--green)";
      break;
    case "Low":
      return "var(--green)";
      break;
  }
};

export const getComparisonValue = (v?: string) => {
  if (
    v === "Strong" ||
    v === "High" ||
    v === "Medium" ||
    v === "Fair" ||
    v === "Low" ||
    v === "Weak"
  ) {
    return <b style={{ color: getValueColor(v) }}>{v}</b>;
  }
  return <div style={{ fontWeight: 400 }}>{v}</div>;
};
