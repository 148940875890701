import React, {PropsWithChildren} from "react";
import {FactSquareProps} from "../types";
import {StyledFactSquare} from "../styled";

export const FactSquareTemplate = React.memo(
  ({
     children,
     title,
     primaryBackground,
     secondaryBackground,
     tertiaryBackground,
     primaryBright
   }: PropsWithChildren<Partial<FactSquareProps>>) => {
    return (
      <StyledFactSquare {...{primaryBackground, secondaryBackground, tertiaryBackground, primaryBright}}>
        {title}
        {children}
      </StyledFactSquare>
    );
  });
