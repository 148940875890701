import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

interface IUser {
  email: string;
  email_verified: boolean;
  family_name: string;
  name: string;
  sub: string;
}

export const useUser = () => {
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((res) => {
      setUser(res.attributes);
    });
  }, []);

  return { user };
};
