import React, { useEffect, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import moment from "moment";
import {
  useGlobalPeriod,
  usePortfolio,
  usePageTracking,
  useStructureGrouping,
} from "@iliotech/data-wire";
import { useTranslation } from "react-i18next";
import { Blur, Loader } from "@iliotech/component-library";
import { useTradesLight } from "@iliotech/data-wire";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAuth } from "../Auth/AuthContext";
import { useLogout } from "../../hooks/useLogout";
import { TrialEndedDialog } from "@iliotech/component-library";

const PageWrapper = (props: any) => {
  usePageTracking();

  const { user } = useAuthenticator(({ user }) => [user]);
  const { illioSelectedAccount } = useAuth();
  const logout = useLogout();
  const location = useLocation();
  const { portfolioId } = useParams();
  const { setInceptionDate, setEpochDate } = useGlobalPeriod();

  const { setCustodians } = useStructureGrouping();
  const portfolio = usePortfolio(portfolioId!);
  const custodians = portfolio?.data?.data.custodians;
  const inceptionDate = portfolio.data?.data.inceptionDate;
  const epochDate = portfolio.data?.data.tradeEpochDate;
  const { t } = useTranslation();
  const trades = useTradesLight(portfolioId!, { enabled: !!portfolioId });

  const navigate = useNavigate();
  const isPageProtected =
    !window.location.pathname.includes("add-investments") &&
    !window.location.pathname.includes("update-portfolio");

  useEffect(() => {
    if (trades?.data?.data.totalTransactions === 0 && isPageProtected) {
      navigate(`/portfolio/${portfolioId}/add-investments/onboarding`);
    }
  }, [trades]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (custodians) {
      setCustodians?.(custodians);
    }
  }, [custodians]);

  useEffect(() => {
    setEpochDate(moment(epochDate).toDate());
  }, [epochDate]);

  useEffect(() => {
    setInceptionDate?.(inceptionDate);
  }, [inceptionDate]);

  if (trades.isLoading && isPageProtected) {
    return <Loader fullScreen />;
  }
  return (
    <div style={{ height: "100vh", width: "100vw", position: "relative" }}>
      <Blur>
        <TrialEndedDialog
          username={user?.attributes?.name || ""}
          logout={logout}
          path={"https://support.illio.com/kb-tickets/new"}
          visible={illioSelectedAccount?.status === "SUSPENDED"}
        />
        <main style={{ height: "100%" }}>{props.children}</main>
      </Blur>
    </div>
  );
};

export default PageWrapper;
