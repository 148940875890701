import * as React from "react";
import s from "./ModalCloseButton.module.scss";
import { Typography } from "@progress/kendo-react-common";
import { IoIosArrowBack, IoIosClose, IoIosCloseCircle } from "react-icons/io";
import cn from "classnames";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  onPress: () => void;
  text?: string;
  extraClass?: string;
}

export const ModalCloseButton = ({
  onPress,
  text = "Back",
  extraClass,
  ...rest
}: IProps) => {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    onPress();
  };
  return (
    <div
      {...rest}
      data-cy={"modal-close"}
      onClick={(e) => onClick(e)}
      className={cn(s.container, extraClass)}
    >
      <Typography.p>{text}</Typography.p>
      <div data-cy={"modal-close-icon"} className={s.buttonContainer}>
        <IoIosClose size={"1.8rem"} />
      </div>
    </div>
  );
};

export default ModalCloseButton;
