import React from "react";
import { Step } from "../Step";
import { Typography } from "@progress/kendo-react-common";
import Loader from "../../Loader/Loader";
import { BsWindow } from "react-icons/bs";

interface IOwnProps {
  // onSuccess(): void;
  // onFailure(): void;
  onCancel(): void;
}

export const IBConnectAccount = ({ onCancel }: IOwnProps) => {
  return (
    <Step currentStep={4} onBack={onCancel}>
      {/**<Typography.h6>
            Connecting account
        </Typography.h6>

            <span>Please connect to your account in the new tab that just opened</span>
            <div style={{display: "flex", justifyContent: "center", marginTop: "3rem"}}>
            <BsWindow size={120} />
            </div>**/}
      <Loader fullScreen />
    </Step>
  );
};
