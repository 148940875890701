import * as React from "react";
import s from "./ModalBackButton.module.scss";
import { Typography } from "@progress/kendo-react-common";
import { IoIosArrowBack } from "react-icons/io";

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  onPress: () => void;
  text?: string;
  extraClass?: string;
}

export const ModalBackButton = ({
  onPress,
  text = "Back",
  extraClass,
  ...rest
}: IProps) => {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    onPress();
  };
  return (
    <div
      {...rest}
      onClick={(e) => onClick(e)}
      className={s.container}
      data-cy={"modal-back"}
    >
      <div data-cy={"modal-back-icon"} className={s.buttonContainer}>
        <IoIosArrowBack />
      </div>
      <Typography.p className={s.hideMobile}>{text}</Typography.p>
    </div>
  );
};

export default ModalBackButton;
