import React from "react";
import Button from "../Button/Button";
import { sendTracking } from "@iliotech/data-wire";
import ReactGA from "react-ga4";

interface IOwnProps {
  checkCode(code: string): Promise<boolean | string>;
  codeFromParams?: string;
  errorMessage?: string;
}

export const ReferralCodeChecker = ({
  checkCode,
  codeFromParams = "",
  errorMessage,
}: IOwnProps) => {
  const [code, setCode] = React.useState(codeFromParams);
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    setCode(codeFromParams);
  }, [codeFromParams]);

  const check = () => {
    checkCode(code);
  };
  return (
    <article>
      <h5>You've been referred to illio 👌</h5>
      <span>Reasons to join illio:</span>
      <ul>
        {Array.from({ length: 6 }).map((_, i) => (
          <li style={{ lineHeight: "1rem" }} key={i}>
            Reason {i + 1}
          </li>
        ))}
      </ul>
      <label>
        <b>Referral code*:</b>
      </label>
      <input
        type="text"
        value={code}
        onChange={(event) => {
          setCode(event.target.value);
          setDirty(true);
        }}
      />
      {errorMessage && !dirty && (
        <div style={{ color: "var(--bad" }}>{errorMessage}</div>
      )}
      <Button style={{ float: "right", marginTop: "1rem" }} onClick={check}>
        Continue
      </Button>
    </article>
  );
};
