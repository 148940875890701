import React, { PropsWithChildren } from "react";
import { Auth } from "aws-amplify";

import { useForm } from "react-hook-form";
import {
  BasicHeader,
  Blur,
  CenteredBlurPage,
  ReferralCodeChecker,
} from "@iliotech/component-library";
import { useQueryClient } from "react-query";
import { UserDetailsForm, KendoLoader } from "@iliotech/component-library";
import { CognitoUser, SignUpParams } from "@aws-amplify/auth";
import { VerifyUser } from "@iliotech/component-library";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { API_BASE, sendTracking, usePageTracking } from "@iliotech/data-wire";
import { useSearchParams } from "react-router-dom";
// import {EmailSent} from "../../components-to-move/lottie/MailSent/EmailSent";

type STATE =
  | "LOADING"
  | "CHECKING_CODE"
  | "INVALID_CODE"
  | "ENTER_CODE"
  | "ENTER_DETAILS"
  | "VERIFY_EMAIL"
  | "ERROR_SIGNING_IN";
export const SignUp = ({
  initialStatus = "LOADING",
}: {
  initialStatus?: STATE;
}) => {
  const client = useQueryClient();
  const methods = useForm();

  const [user, setUser] = React.useState<CognitoUser>();
  const [acceptedCode, setAcceptedCode] = React.useState<string>();
  const [status, setStatus] = React.useState<STATE>(initialStatus);
  const [codeFromParams, setCodeFromParams] = React.useState("");

  const [params] = useSearchParams();

  React.useEffect(() => {
    if (initialStatus !== "LOADING") return;
    const initialCode = params.get("code");
    if (!initialCode) {
      // console.log("NO CODE FOUND");
      setStatus("ENTER_CODE");
      return;
    }

    setCodeFromParams(initialCode);
    setStatus("CHECKING_CODE");

    checkCode(initialCode).finally(() => {});
    // console.log("CODE FOUND", initialCode);
    return;
  }, [params, initialStatus]);

  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    client.clear();
  }, [client]);

  React.useEffect(() => {
    Auth.signOut();
    // console.log("user visits sign up page");
    sendTracking(`user_visits_register_page`, "register_flow");
  }, []);

  const onSubmit = async (params: SignUpParams) => {
    // console.log("user clicks submit");
    sendTracking("user_clicks_submit", "register_flow");
    const { password: nextPassword } = params;
    return Auth.signUp({
      ...params,
      attributes: {
        ...params.attributes,
        "custom:referer_code": acceptedCode,
      },
    }).then((r) => {
      setPassword(nextPassword);
      setUser(r.user);
      setStatus("VERIFY_EMAIL");
      console.group("Sign up result");
      console.log(r);
      console.groupEnd();
    });
  };

  const onSubmitDetailsSuccess = () => {
    setStatus("LOADING");
    Auth.signIn({ password, username: user!.getUsername() })
      .then((result) => {
        // console.group("Sign in result");
        // console.log(result);
        // console.groupEnd();
        navigate("/get-started");
      })
      .catch((error) => {
        setStatus("ERROR_SIGNING_IN");
      });
  };

  const checkCode = (code: string) => {
    setStatus("CHECKING_CODE");
    return axios
      .get(`${API_BASE}/api/internal/promotion/${code}`)
      .then((response) => {
        // console.log({ response });
        if (response?.data?.promotionStatus === "OK") {
          setAcceptedCode(code);
          setStatus("ENTER_DETAILS");
        } else {
          setStatus("INVALID_CODE");
        }

        return response?.data?.promotionStatus === "OK";
      })
      .catch(() => {
        setStatus("INVALID_CODE");
        return "Code not valid";
      });
  };

  if (["INVALID_CODE", "ENTER_CODE"].includes(status)) {
    return (
      <CenteredBlurPage>
        <ReferralCodeChecker
          checkCode={checkCode}
          codeFromParams={codeFromParams}
          errorMessage={
            status === "INVALID_CODE" ? "That code is invalid" : undefined
          }
        />
      </CenteredBlurPage>
    );
  }

  if (status === "VERIFY_EMAIL") {
    return (
      <VerifyUser
        email={user!.getUsername()}
        onCodeVerified={onSubmitDetailsSuccess}
      />
    );
  }

  if (status === "LOADING") {
    return <LoadingPage title={"Loading"} />;
  }

  if (status === "CHECKING_CODE") {
    return <LoadingPage title={"Checking your referral code"} />;
  }

  if (status === "ERROR_SIGNING_IN") {
    return (
      <Layout title={"Error signing in"}>
        <article>
          <p>
            We're sorry, we weren't able to sign you in. Please{" "}
            <a href={"/"}>click here</a> to log in again
          </p>
        </article>
      </Layout>
    );
  }

  // * status === "USER_DETAILS"
  return (
    <Layout title={"Create your illio account"}>
      <UserDetailsForm onSubmit={onSubmit} methods={methods} />
    </Layout>
  );
};

const LoadingPage = ({ title }: { title: string }) => {
  return (
    <Layout title={title}>
      <article style={{ width: 400, maxWidth: "100vw" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <KendoLoader />
        </div>
      </article>
    </Layout>
  );
};

const Layout = ({ children, title }: PropsWithChildren<{ title: string }>) => {
  return (
    <Blur>
      <BasicHeader title={title} hideLogOff hideProfile />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: 10,
          minHeight: 700,
        }}
      >
        {children}
      </div>
    </Blur>
  );
};
