import {
  EQUITIES_SEARCH_TYPES,
  ValidSearchTypes,
  useWhatIfPositions,
} from "@iliotech/data-wire/src/stores";
import s from "./Cells.module.scss";
import { WhatIfPositionsTableCellProps } from "../WhatIfPositionsTable";
import { observer } from "mobx-react-lite";
import { ResolveInstrumentAutoComplete } from "src/main";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { InstrumentSearchResultV3 } from "@iliotech/generated-api-v3";
import classNames from "classnames";

const NameCell = ({ index }: WhatIfPositionsTableCellProps) => {
  const store = useWhatIfPositions();
  const name = store.getField<string>("name", index);
  const quantity = store.getField<string>("quantity", index);
  const searchTypes = store.getField<ValidSearchTypes[]>("searchTypes", index);

  const disabled = !quantity;

  const getTypeLabel = () => {
    switch (searchTypes?.[0]) {
      case "Equity":
        return "Shares, ETFs and Funds";
      case "Crypto":
        return "Crypto";
      case "FixedIncome":
        return "Bonds";
      default:
        return "Instrument";
    }
  };
  const getPlaceholder = () => {
    return `Search for ${getTypeLabel()}`;
  };

  const getEmptyMessage = () => {
    return `We couldn't find any ${getTypeLabel()} matching your query`;
  };

  const onChange = (event: ComboBoxChangeEvent) => {
    const value: InstrumentSearchResultV3 = event.value;
    store.onSelectNewInstrument(index, value);
  };

  if (!name) {
    return (
      <ResolveInstrumentAutoComplete
        types={searchTypes || []}
        onChange={onChange}
        placeholder={getPlaceholder()}
        emptyMessage={getEmptyMessage()}
      />
    );
  }
  return <div className={classNames(disabled && s.disabled)}>{name}</div>;
};

export default observer(NameCell);
