import React from "react";
import cn from "classnames";
import s from "../styles/PortfolioSettings.module.scss";

interface IOwnProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const tabs = [
  { id: "details", label: "Portfolio details" },
  { id: "custodians", label: "Accounts" },
  { id: "benchmark", label: "Benchmark" },
];

export const PortfolioSettingsMenu = ({
  setSelectedTab,
  selectedTab,
}: IOwnProps) => {
  return (
    <ul className={cn(s.tabs)}>
      {tabs.map((tab) => (
        <li
          className={cn(s.tab, tab.id === selectedTab && s.selected)}
          key={tab.id}
          onClick={() => setSelectedTab(tab.id)}
        >
          {tab.label}
        </li>
      ))}
    </ul>
  );
};
