import * as React from "react";
import s from "./StatusIconRenderer.module.scss";
import tick from "../assets/tick.svg";
import cn from "classnames";
import { Typography } from "@progress/kendo-react-common";
import { Loader } from "@progress/kendo-react-indicators";

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  status: "OK" | "WARNING" | "ERROR" | "SPINNER";
  title: string;
}

const StatusIconRenderer = ({ status, title, ...rest }: IProps) => {
  if (status === "OK") {
    return (
      <div {...rest} className={cn(s.container, s.ok)}>
        <img src={tick} alt={"ok"} />
      </div>
    );
  }

  if (status === "WARNING") {
    return (
      <div {...rest} title={title} className={cn(s.container, s.warning)}>
        <Typography.p title={title} className={s.text}>
          !
        </Typography.p>
      </div>
    );
  }

  if (status === "SPINNER") {
    return (
      <div title={title} className={s.spinnerWrapper} {...rest}>
        <Loader size={"small"} />
      </div>
    );
  }

  return (
    <div {...rest} title={title} className={cn(s.container, s.error)}>
      <Typography.p title={title} className={s.text}>
        !
      </Typography.p>
    </div>
  );
};

export default StatusIconRenderer;
