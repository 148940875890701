import { WorkgroupWithDataV3Dto } from "@iliotech/generated-api-v3";
import s from "./PageList.module.scss";
import React from "react";
import { WorkgroupCard } from "./components/WorkgroupCard/WorkgroupCard";
import {
  FadeLoading,
  ListPaginator,
  Loader,
} from "@iliotech/component-library";
import { PaginatorReturnType } from "@iliotech/data-wire/src/reactHooks/hooks/usePaginator";

type IProps = PaginatorReturnType<WorkgroupWithDataV3Dto> & {
  valueFormatter: (v: number) => string;
};

export const PageList = ({ data, valueFormatter, ...props }: IProps) => {
  if (props.isLoading) {
    return <Loader />;
  }
  if (!data?.content?.length) {
    return (
      <div className={s.empty}>
        <p>0 workgroups found</p>
      </div>
    );
  }
  return (
    <div className={s.container}>
      <FadeLoading loading={props.isFetching}>
        <div className={s.listContainer}>
          {data?.content?.map((item) => (
            <WorkgroupCard
              valueFormatter={valueFormatter}
              key={item.id}
              {...item}
            />
          ))}
        </div>
      </FadeLoading>
      <ListPaginator {...props} />
    </div>
  );
};
