import React from "react";
import {
  useAccountStatus,
  useAuth,
  useSelectedAccountStatus,
} from "@iliotech/data-wire";
import { Loader } from "@iliotech/component-library";
import { StripeAccountLink } from "./StripeAccountLink";
import { PlatformContactInfo } from "./PlatformContactInfo";

interface IOwnProps {}

export const Billing = (props: IOwnProps) => {
  const accountStatus = useSelectedAccountStatus();

  const loading = accountStatus.isLoading;
  const accountSource = accountStatus.data?.data?.accountSource;

  if (loading) return <Loader fullScreen={false} />;

  switch (accountSource) {
    case "Enterprise":
      return <div>Please contact your enterprise account manager</div>;
    case "Direct":
      return <StripeAccountLink />;

    case "Platform":
      return (
        <PlatformContactInfo
          sourceName={accountStatus.data?.data?.sourceName}
        />
      );
  }

  return null;
};
