import * as React from "react";
import { Loader, LoaderProps } from "@progress/kendo-react-indicators";

interface IProps extends LoaderProps {}

export const KendoLoader = ({ ...rest }: IProps) => {
  return <Loader {...rest} />;
};

export default Loader;
