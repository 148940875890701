/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { IncomeDataResponseV3 } from '../../aggregator';
// @ts-ignore
import { PortfolioReportCustomRequest } from '../../aggregator';
// @ts-ignore
import { PortfolioReportSaveSettings } from '../../aggregator';
// @ts-ignore
import { PortfolioReportStandardRequest } from '../../aggregator';
/**
 * PortfolioReportsApi - axios parameter creator
 * @export
 */
export const PortfolioReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate Standard Portfolio Report
         * @param {PortfolioReportStandardRequest} portfolioReportStandardRequest 
         * @param {string} portfolioId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioConsumerReport: async (portfolioReportStandardRequest: PortfolioReportStandardRequest, portfolioId: string, authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioReportStandardRequest' is not null or undefined
            assertParamExists('getPortfolioConsumerReport', 'portfolioReportStandardRequest', portfolioReportStandardRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioConsumerReport', 'portfolioId', portfolioId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getPortfolioConsumerReport', 'authorization', authorization)
            const localVarPath = `/v3/reports/portfolio/{portfolioId}/consumer`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioReportStandardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Custom Portfolio Report
         * @param {PortfolioReportCustomRequest} portfolioReportCustomRequest 
         * @param {string} portfolioId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCustomReport: async (portfolioReportCustomRequest: PortfolioReportCustomRequest, portfolioId: string, authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioReportCustomRequest' is not null or undefined
            assertParamExists('getPortfolioCustomReport', 'portfolioReportCustomRequest', portfolioReportCustomRequest)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioCustomReport', 'portfolioId', portfolioId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getPortfolioCustomReport', 'authorization', authorization)
            const localVarPath = `/v3/reports/portfolio/{portfolioId}/custom`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioReportCustomRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get portfolio Income Report
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioIncome1: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioIncome1', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/income`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Saved Portfolio Report Settings
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioReportSettings: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioReportSettings', 'portfolioId', portfolioId)
            const localVarPath = `/v3/reports/portfolio/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save Portfolio Report Settings
         * @param {PortfolioReportSaveSettings} portfolioReportSaveSettings 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortfolioReportSettings: async (portfolioReportSaveSettings: PortfolioReportSaveSettings, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioReportSaveSettings' is not null or undefined
            assertParamExists('savePortfolioReportSettings', 'portfolioReportSaveSettings', portfolioReportSaveSettings)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('savePortfolioReportSettings', 'portfolioId', portfolioId)
            const localVarPath = `/v3/reports/portfolio/{portfolioId}/save`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioReportSaveSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioReportsApi - functional programming interface
 * @export
 */
export const PortfolioReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate Standard Portfolio Report
         * @param {PortfolioReportStandardRequest} portfolioReportStandardRequest 
         * @param {string} portfolioId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioConsumerReport(portfolioReportStandardRequest: PortfolioReportStandardRequest, portfolioId: string, authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioConsumerReport(portfolioReportStandardRequest, portfolioId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate Custom Portfolio Report
         * @param {PortfolioReportCustomRequest} portfolioReportCustomRequest 
         * @param {string} portfolioId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioCustomReport(portfolioReportCustomRequest: PortfolioReportCustomRequest, portfolioId: string, authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioCustomReport(portfolioReportCustomRequest, portfolioId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get portfolio Income Report
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioIncome1(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeDataResponseV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioIncome1(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Saved Portfolio Report Settings
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioReportSettings(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortfolioReportSaveSettings>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioReportSettings(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save Portfolio Report Settings
         * @param {PortfolioReportSaveSettings} portfolioReportSaveSettings 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePortfolioReportSettings(portfolioReportSaveSettings: PortfolioReportSaveSettings, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePortfolioReportSettings(portfolioReportSaveSettings, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioReportsApi - factory interface
 * @export
 */
export const PortfolioReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate Standard Portfolio Report
         * @param {PortfolioReportStandardRequest} portfolioReportStandardRequest 
         * @param {string} portfolioId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioConsumerReport(portfolioReportStandardRequest: PortfolioReportStandardRequest, portfolioId: string, authorization: string, options?: any): AxiosPromise<any> {
            return localVarFp.getPortfolioConsumerReport(portfolioReportStandardRequest, portfolioId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Custom Portfolio Report
         * @param {PortfolioReportCustomRequest} portfolioReportCustomRequest 
         * @param {string} portfolioId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioCustomReport(portfolioReportCustomRequest: PortfolioReportCustomRequest, portfolioId: string, authorization: string, options?: any): AxiosPromise<any> {
            return localVarFp.getPortfolioCustomReport(portfolioReportCustomRequest, portfolioId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get portfolio Income Report
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioIncome1(portfolioId: string, options?: any): AxiosPromise<IncomeDataResponseV3> {
            return localVarFp.getPortfolioIncome1(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Saved Portfolio Report Settings
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioReportSettings(portfolioId: string, options?: any): AxiosPromise<Array<PortfolioReportSaveSettings>> {
            return localVarFp.getPortfolioReportSettings(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save Portfolio Report Settings
         * @param {PortfolioReportSaveSettings} portfolioReportSaveSettings 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePortfolioReportSettings(portfolioReportSaveSettings: PortfolioReportSaveSettings, portfolioId: string, options?: any): AxiosPromise<void> {
            return localVarFp.savePortfolioReportSettings(portfolioReportSaveSettings, portfolioId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioReportsApi - object-oriented interface
 * @export
 * @class PortfolioReportsApi
 * @extends {BaseAPI}
 */
export class PortfolioReportsApi extends BaseAPI {
    /**
     * 
     * @summary Generate Standard Portfolio Report
     * @param {PortfolioReportStandardRequest} portfolioReportStandardRequest 
     * @param {string} portfolioId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioReportsApi
     */
    public getPortfolioConsumerReport(portfolioReportStandardRequest: PortfolioReportStandardRequest, portfolioId: string, authorization: string, options?: AxiosRequestConfig) {
        return PortfolioReportsApiFp(this.configuration).getPortfolioConsumerReport(portfolioReportStandardRequest, portfolioId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Custom Portfolio Report
     * @param {PortfolioReportCustomRequest} portfolioReportCustomRequest 
     * @param {string} portfolioId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioReportsApi
     */
    public getPortfolioCustomReport(portfolioReportCustomRequest: PortfolioReportCustomRequest, portfolioId: string, authorization: string, options?: AxiosRequestConfig) {
        return PortfolioReportsApiFp(this.configuration).getPortfolioCustomReport(portfolioReportCustomRequest, portfolioId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get portfolio Income Report
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioReportsApi
     */
    public getPortfolioIncome1(portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioReportsApiFp(this.configuration).getPortfolioIncome1(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Saved Portfolio Report Settings
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioReportsApi
     */
    public getPortfolioReportSettings(portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioReportsApiFp(this.configuration).getPortfolioReportSettings(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save Portfolio Report Settings
     * @param {PortfolioReportSaveSettings} portfolioReportSaveSettings 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioReportsApi
     */
    public savePortfolioReportSettings(portfolioReportSaveSettings: PortfolioReportSaveSettings, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioReportsApiFp(this.configuration).savePortfolioReportSettings(portfolioReportSaveSettings, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }
}
