import React, {createContext} from 'react';
import {PortfolioDTOWithDetailsV3, TradeDtoV3} from "@iliotech/generated-api-v3";
import {useReferenceData} from "@iliotech/data-wire";
import {useTransactionsContextHook} from "./TransactionsContextProvider";

export const TransactionsContext = createContext<ReturnType<typeof useTransactionsContextHook> | null>(null);

export interface TransactionsContextProps {
  referenceData?: ReturnType<typeof useReferenceData>;
  portfolioInfo?: PortfolioDTOWithDetailsV3;
  portfolioId: string;
  transactions?: TradeDtoV3[];
  loading?: boolean;
  refetchTrades?(): void;
  onDeleteTrade?(tradeId: number): Promise<any>;
  onEditTrade?(tradeId: number, path?: string): void;
  onClickTrade?(trade: TradeDtoV3): void;
  
}


