import * as React from "react";
import { CssSpinner } from "../../CssSpinner/CssSpinner";
import { FormModal } from "../../FormModal/FormModal";
import s from "./StatusModal.module.scss";
import { Typography } from "@progress/kendo-react-common";

interface IProps {
  loading: boolean;
  extraContent?: React.ReactNode;
  text?: string;
}

export const StatusModal = ({
  loading,
  text = "Your file is being uploaded",
  extraContent,
}: IProps) => {
  return (
    <FormModal hideClose={true} visible={loading} handleClose={() => {}}>
      <div className={s.loadingContainer}>
        <Typography.h3
          fontWeight={"bold"}
          textAlign={"center"}
          style={{ marginBottom: 25 }}
        >
          {text}
        </Typography.h3>
        <CssSpinner />
        {extraContent}
      </div>
    </FormModal>
  );
};

export default StatusModal;
