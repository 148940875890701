import React from "react";
import {
  DatePicker as KendoDatePicker,
  DatePickerProps,
} from "@progress/kendo-react-dateinputs";
import s from "./DatePicker.module.scss";
import cn from "classnames";

export const DatePicker = ({
  hideWeekends = true,
  className,
  ...props
}: IDatePickerProps) => {
  // todo: use customInput everywhere (have problems with onBlur in period component)
  return (
    <KendoDatePicker
      {...props}
      format={"dd-MMM-yyyy"}
      popupSettings={{
        popupClass: cn(hideWeekends && s.hideWeekends, s.removeHighlight),
        appendTo: document.getElementById("app"),
      }}
      className={cn(s.datePicker, hideWeekends && s.hideWeekends, className)}
    />
  );
};

export interface IDatePickerProps extends DatePickerProps {
  hideWeekends?: boolean;
  className?: any;
}

export default React.memo(DatePicker);
