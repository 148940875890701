import * as CSS from "csstype";
// import { TRANSACTION_TYPES } from './transactionTypes';
import { ICustodian } from "@iliotech/types";
import { ASSETS } from "./assets";

export const SUPPORT_EMAIL_ADDRESS = "support@illio.com";

export interface IOption<V> {
  id: string | number;
  name: string;
  code?: string;
  value: V;
  sourceData?: string;
}

export interface IOptionType<T = any> {
  label: string;
  value: T;
}

export const INSIGHTS_IDS = {
  INCOME: "HDR_INC",
  STRUCTURE: "HDR_STR",
  DASHBOARD: "HDR_POS",
  PNL: "HDR_PNL",
  RISK: "HDR_RSK",
  PERFORMANCE: "HDR_PER",
  ESG: "HDR_ESG",
};
// eslint-disable-next-line no-template-curly-in-string

export const API_BASE =
  window.environmentSettings?.illioApiUrl === "${VITE_ILLIO_API_URL}"
    ? import.meta?.env?.VITE_ILLIO_API_URL ?? "https://dev.illio.com"
    : (window.environmentSettings?.illioApiUrl as string);

export const DASHBOARD_TITLES: Record<string, string> = {
  pnl: "Profit and Loss",
  income: "Income",
  structure: "Structure",
  pnlBreakdown: "P&L Breakdown",
  options: "Options",
  fundamentals: "Fundamentals",
  summary: "Summary",
};

export const ASSET_LABEL_NAMES: Record<string, string> = {
  [ASSETS.alternatives]: "Alternatives",
  [ASSETS.adjustments]: "Cash Adjustments",
  [ASSETS.cash]: "Cash And Equivalents",
  [ASSETS.commodities]: "Commodities",
  [ASSETS.fixedIncome]: "Fixed Income",
  [ASSETS.equity]: "Equities",
  [ASSETS.fund]: "Funds",
  [ASSETS.realEstate]: "Real Estate",
  [ASSETS.realAssets]: "Real Assets",
  [ASSETS.cryptoCurrencies]: "Cryptocurrencies",
  [ASSETS.subscriptionWithdrawal]: "Subscription Withdrawal",
  [ASSETS.futures]: "Futures",
  [ASSETS.mixed]: "Mixed Allocation",
  [ASSETS.fx]: "FX trades",
};

export const SUB_ASSETS: Record<string, string> = {
  options: "EqOptions",
  privateEquity: "AltPrivateEquity",
  directInvestments: "AltDirectInvestments",
  hedgeFund: "AltHedgeFunds",
};

export enum UserRole {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  CUSTOMER = "CUSTOMER",
}

export enum UserStatus {
  UNCONFIRMED = "UNCONFIRMED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum PortfolioType {
  BUSINESS = "CLIENT",
  PERSONAL = "PERSONAL",
  MODEL = "MODEL",
}

export enum PortfolioAccessType {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  HIDDEN = "HIDDEN",
}

export enum SORT_VIEW {
  NAME = "name",
  PNL = "totalProfitAndLoss",
  PERFORMANCE = "performance",
  WEALTH = "totalWealth",
}

export enum FILTER_VIEW {
  ALL,
  CURRENCY,
  BUSINESS,
  ASSET,
}

export enum PROFIT_VIEW {
  ASSET,
  ATTRIBUTION,
}

export enum PERIOD_TYPE {
  MTD,
  YTD,
  INCEPTION,
  YEAR,
  THREE_YEARS,
  MANUAL,
  THREE_MONTHS,
  SIX_MONTHS,
  LAST_YEAR,
  CALENDAR_YEAR,
  CALENDAR_QUARTER,
}

export enum TOP_LIST_ORDER {
  ISSUER = "company.name",
  SECURITY = "name",
  SUBCLASS = "subclass",
  TABLE_COLUMN = "pnlBase",
}

export enum TOPLIST_TYPE {
  MONEY = "MONEY",
  PERCENTAGE = "PERCENTAGE",
}

export enum SETTINGS_VIEW_TYPE {
  ROW,
  COLUMN,
}

export enum ALLOCATION_TYPES {
  AssetClass = "AssetClass",
  RiskAssetClass = "RiskAssetClass",
  AssetSubClass = "AssetSubClass",
  Region = "Region",
  RegionOfIssue = "RegionOfIssue",
  MSCIRegion = "MSCIRegion",
  MSCIClassification = "MSCIClassification",
  Sectors = "IndustrySector",
  Country = "Country",
  Currency = "Currency",
  CreditRating = "CreditRating",
  Custodian = "Custodian",
  ETFSubClass = "ETFSubClass",
  Industry = "Industry",
  PropertySector = "PropertySector",
  FundStrategy = "FundStrategy",
  RealEstate = "RealEstate",
}

export enum GEOGRAPHY_ALLOCATION_TYPES {
  Region = "Region",
  RegionOfIssue = "RegionOfIssue",
  MSCIRegion = "MSCIRegion",
  MSCIClassification = "MSCIClassification",
}

export const GEOGRAPHY_TYPES = [
  { value: GEOGRAPHY_ALLOCATION_TYPES.Region, label: "Region of Risk" },
  { value: GEOGRAPHY_ALLOCATION_TYPES.RegionOfIssue, label: "Region of Issue" },
  {
    value: GEOGRAPHY_ALLOCATION_TYPES.MSCIRegion,
    label: "Alt. Region of Risk",
  },
  {
    value: GEOGRAPHY_ALLOCATION_TYPES.MSCIClassification,
    label: "Classification",
  },
];

export enum EQUITY_EXPOSURE_TYPES {
  MarketValueBase = "MarketValueBase",
  NotionalBase = "NotionalBase",
  ExposureBase = "ExposureBase",
  MarketValueBaseGross = "MarketValueBaseGross",
  NotionalBaseGross = "NotionalBaseGross",
  ExposureBaseGross = "ExposureBaseGross",
}

export const EXPOSURE_TYPES = [
  { value: EQUITY_EXPOSURE_TYPES.MarketValueBase, label: "Market Value" },
  { value: EQUITY_EXPOSURE_TYPES.NotionalBase, label: "Notional" },
  { value: EQUITY_EXPOSURE_TYPES.ExposureBase, label: "Exposure" },
];

export const EXPOSURE_TYPES_WITH_SHORTS = [
  { value: EQUITY_EXPOSURE_TYPES.MarketValueBase, label: "Market Value" },
  { value: EQUITY_EXPOSURE_TYPES.NotionalBase, label: "Notional" },
  { value: EQUITY_EXPOSURE_TYPES.ExposureBase, label: "Exposure" },
  {
    value: EQUITY_EXPOSURE_TYPES.MarketValueBaseGross,
    label: "Market Value (Gross)",
  },
  { value: EQUITY_EXPOSURE_TYPES.NotionalBaseGross, label: "Notional (Gross)" },
  { value: EQUITY_EXPOSURE_TYPES.ExposureBaseGross, label: "Exposure (Gross)" },
];

export enum DetalizationType {
  Monthly = "Monthly",
  Daily = "Daily",
}

export enum PNL_CHART_TYPE {
  PnlByAttribution = "PnlByAttribution",
  PnlByAssetClass = "PnlByAssetClass",
}

export enum SCENARIO_DISPLAY_OPTIONS {
  sectors = "sectors",
  assets = "assets",
  countries = "countries",
  currencies = "currencies",
}

export const RISK_DAILY_PERIOD_OPTIONS = [
  { value: "ytd", label: "Year To Date" },
  { value: "oneYear", label: "Previous 1yr" },
  { value: "sinceInception", label: "Since Inception" },
  { value: "custom", label: "Custom" },
];

export const RISK_MONTHLY_PERIOD_OPTIONS = [
  { value: "previous3m", label: "Previous 3m" },
  { value: "previous6m", label: "Previous 6m" },
  { value: "oneYear", label: "Previous 1yr" },
  { value: "calendar", label: "Calendar" },
  { value: "sinceInception", label: "Since Inception" },
  { value: "custom", label: "Custom" },
];

export const DEFAULT_CUSTODIANS: Record<string, string> = {
  [ASSETS.realAssets]: "013cabfb-ce73-4dba-812e-f330664b193a",
};

export const PERSONAL_USE_CUSTODIAN: IOption<ICustodian> = {
  code: "Personal Use",
  id: "013cabfb-ce73-4dba-812e-f330664b193a",
  name: "Personal Use",
  // @ts-ignore
  default: true,
  value: {
    code: "Personal Use",
    default: true,
    id: "013cabfb-ce73-4dba-812e-f330664b193a",
    name: "Personal Use",
  },
};

type TColorType = Pick<CSS.Properties, "color">;

export const ALLOCATION_BARS_OPTIONS: Record<
  string,
  { color: TColorType | string; title: string }
> = {
  [ALLOCATION_TYPES.RiskAssetClass]: {
    color: "#6677cc",
    title: "Risk Asset Class",
  },
  [ALLOCATION_TYPES.AssetClass]: { color: "#6677cc", title: "Asset Class" },
  [ALLOCATION_TYPES.AssetSubClass]: { color: "#6677cc", title: "Subclass" },
  [ALLOCATION_TYPES.Region]: { color: "#c2b261", title: "Geography" },
  [ALLOCATION_TYPES.RegionOfIssue]: { color: "#c2b261", title: "Geography" },
  [ALLOCATION_TYPES.MSCIRegion]: { color: "#c2b261", title: "Geography" },
  [ALLOCATION_TYPES.MSCIClassification]: {
    color: "#c2b261",
    title: "Geography",
  },
  [ALLOCATION_TYPES.Currency]: { color: "#59abb3", title: "Currency" },
  [ALLOCATION_TYPES.Sectors]: { color: "#cc6f66", title: "Sector" },
  [ALLOCATION_TYPES.Industry]: { color: "#56b877", title: "Industry" },
  [ALLOCATION_TYPES.PropertySector]: {
    color: "#cc6f66",
    title: "PropertySector",
  },
  [ALLOCATION_TYPES.CreditRating]: { color: "#56b877", title: "Credit Rating" },
  [ALLOCATION_TYPES.Custodian]: { color: "#b87f24", title: "Custodian" },
  [ALLOCATION_TYPES.FundStrategy]: { color: "#6677cc", title: "Strategy" },
  [ALLOCATION_TYPES.ETFSubClass]: { color: "#56b877", title: "ETF Strategy" },
};

export const TABLE_PAGE_SIZE = 5000; // ILLDV001-6251 TODO: Add Pagination

export const SCATTER_CHART_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.INCEPTION, label: "Since inception" },
  { value: PERIOD_TYPE.LAST_YEAR, label: "Last year" },
  { value: PERIOD_TYPE.MANUAL, label: "Date range" },
];

export const CHARTS_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.MTD, label: "MTD" },
  { value: PERIOD_TYPE.YTD, label: "YTD" },
  { value: PERIOD_TYPE.YEAR, label: "1 Year" },
  { value: PERIOD_TYPE.CALENDAR_YEAR, label: "Calendar year" },
  { value: PERIOD_TYPE.CALENDAR_QUARTER, label: "Previous Quarter" },
  { value: PERIOD_TYPE.INCEPTION, label: "Since inception" },
  { value: PERIOD_TYPE.MANUAL, label: "Date range" },
];

export const DAILY_RISK_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.YTD, label: "YTD" },
  { value: PERIOD_TYPE.YEAR, label: "1 Year" },
  { value: PERIOD_TYPE.INCEPTION, label: "Since inception" },
  { value: PERIOD_TYPE.MANUAL, label: "Date range" },
];

export const MONTHLY_RISK_PERIODS: Array<IOptionType<PERIOD_TYPE>> = [
  { value: PERIOD_TYPE.THREE_MONTHS, label: "Previous 3m" },
  { value: PERIOD_TYPE.SIX_MONTHS, label: "Previous 6m" },
  { value: PERIOD_TYPE.YEAR, label: "1 Year" },
  { value: PERIOD_TYPE.INCEPTION, label: "Since inception" },
  { value: PERIOD_TYPE.MANUAL, label: "Date range" },
];

export const SYSTEM_EPOCH = new Date("2018-01-01");
export const CONSUMER_EPOCH = new Date("2021-01-01");

export const DATE_FORMAT = "DD-MMM-YYYY";
export const CHART_DATE_FORMAT = "DD MMM YYYY";
export const REQUEST_DATE_FORMAT = "YYYY-MM-DD";

export const PNL_CALCULATION_EVENT = "pnl_calculation";
export const TRANSIT_TRADE_EVENT = "transit_trade";

export const LOCAL_STORAGE_KEYS: Record<string, string> = {
  ILLIO_SELECTED_ACCOUNT: "illio-selected-account",
};

export const UPLOAD_COLUMNS = [
  { label: "Transaction Date", value: "date", required: true },
  { label: "Price", value: "price", required: true },
  { label: "ISIN", value: "isin", required: true },
  { label: "Ticker", value: "ticker", required: true },
  // { label: 'Transaction Action', value: 'action', required: true },
  { label: "Buy / Sell", value: "buySell", required: true },
  { label: "Credit", value: "credit", required: true },
  { label: "Debit", value: "debit", required: true },
  { label: "Quantity", value: "quantity", required: true },
  { label: "Currency", value: "currency", required: false },
];

export const BREAKPOINTS: Record<string, number> = {
  XS: 500,
  SMALL: 769,
  MEDIUM: 1025,
  LARGE: 1200,
  XL: 1400,
  XXL: 1600,
};

export const REAL_ESTATE_TYPES = [
  { value: "RePhysical", label: "Physical" },
  { value: "ReLoan", label: "Loan" },
];

export const UNLISTED_EQUITIES_SECTORS = [
  {
    code: "Commodities",
    label: "Commodities",
  },
  {
    code: "CommunicationServices",
    label: "Communication Services",
  },
  {
    code: "ConsumerDiscretionary",
    label: "Consumer Discretionary",
  },
  {
    code: "ConsumerStaples",
    label: "Consumer Staples",
  },
  {
    code: "Cryptocurrencies",
    label: "Crypto Currencies",
  },
  {
    code: "Energy",
    label: "Energy",
  },
  {
    code: "Financials",
    label: "Financials",
  },
  {
    code: "HealthCare",
    label: "Health Care",
  },
  {
    code: "Industrials",
    label: "Industrials",
  },
  {
    code: "InformationTechnology",
    label: "Information Technology",
  },
  {
    code: "Materials",
    label: "Materials",
  },
  {
    code: "RealAssets",
    label: "Real Assets",
  },
  {
    code: "RealEstate",
    label: "Real Estate",
  },
  {
    code: "Utilities",
    label: "Utilities",
  },

  {
    code: "Other",
    label: "Other",
  },
];

export const REAL_ESTATE_SECTORS = [
  { label: "Agricultural", value: "Agricultural" },
  { label: "Commercial Office", value: "CommercialOffice" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Hotel", value: "Hotel" },
  { label: "Industrial", value: "Industrial" },
  { label: "Other", value: "Other" },
  { label: "Residential", value: "Residential" },
  { label: "Retail", value: "Retail" },
];

export const MONTH_DAYS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
export const REAL_ASSETS_TYPES = [
  {
    value: "RaAircraft",
    label: "Aircraft",
  },
  {
    value: "RaArt",
    label: "Art",
  },
  {
    value: "RaDigital",
    label: "Digital Assets",
  },
  {
    value: "RaHorses",
    label: "Horses",
  },
  {
    value: "RaJewellery",
    label: "Jewellery",
  },
  {
    value: "RaLivestock",
    label: "Livestock",
  },
  {
    value: "RaMotorVehicle",
    label: "Motor vehicle",
  },
  {
    value: "RaOther",
    label: "Other",
  },
  {
    value: "RaWineAndSpirits",
    label: "Wine and spirits",
  },
  {
    value: "RaYacht",
    label: "Yacht",
  },
];

export const allFundStrategies = [
  { assetSubClassId: "FunEquity", strategyName: "Value" },
  { assetSubClassId: "FunEquity", strategyName: "Growth" },
  { assetSubClassId: "FunEquity", strategyName: "Blend" },
  { assetSubClassId: "FunEquity", strategyName: "Bear Market" },
  { assetSubClassId: "FunEquity", strategyName: "Long/Short" },
  { assetSubClassId: "FunEquity", strategyName: "Market Neutral" },
  { assetSubClassId: "FunEquity", strategyName: "Small Cap" },
  { assetSubClassId: "FunEquity", strategyName: "Mid Cap" },
  { assetSubClassId: "FunEquity", strategyName: "Large Cap" },
  { assetSubClassId: "FunEquity", strategyName: "Broad" },
  { assetSubClassId: "FunETF", strategyName: "Commodities" },
  { assetSubClassId: "FunETF", strategyName: "Communication Services" },
  { assetSubClassId: "FunETF", strategyName: "Consumer Discretionary" },
  { assetSubClassId: "FunETF", strategyName: "Consumer Staples" },
  { assetSubClassId: "FunETF", strategyName: "Convertible Bonds" },
  { assetSubClassId: "FunETF", strategyName: "Corporate Bonds" },
  { assetSubClassId: "FunETF", strategyName: "Cryptocurrencies" },
  { assetSubClassId: "FunETF", strategyName: "Developed Mkt Bonds" },
  { assetSubClassId: "FunETF", strategyName: "Emerging Mkt Bonds" },
  { assetSubClassId: "FunETF", strategyName: "Energy" },
  { assetSubClassId: "FunETF", strategyName: "Equity Geographic Focus" },
  { assetSubClassId: "FunETF", strategyName: "Equity Sector Focus" },
  { assetSubClassId: "FunETF", strategyName: "Equity Theme Focus" },
  { assetSubClassId: "FunETF", strategyName: "Financials" },
  { assetSubClassId: "FunETF", strategyName: "Global Bonds" },
  { assetSubClassId: "FunETF", strategyName: "Government Bonds" },
  { assetSubClassId: "FunETF", strategyName: "Health Care" },
  { assetSubClassId: "FunETF", strategyName: "Hedge Fund Strategies" },
  { assetSubClassId: "FunETF", strategyName: "High Yield Bonds" },
  { assetSubClassId: "FunETF", strategyName: "Industrials" },
  { assetSubClassId: "FunETF", strategyName: "Information Technology" },
  { assetSubClassId: "FunETF", strategyName: "Liquid Alternatives" },
  { assetSubClassId: "FunETF", strategyName: "Market Cap Equity" },
  { assetSubClassId: "FunETF", strategyName: "Materials" },
  { assetSubClassId: "FunETF", strategyName: "Money Market" },
  { assetSubClassId: "FunETF", strategyName: "Mixed Asset" },
  { assetSubClassId: "FunETF", strategyName: "Real Assets" },
  { assetSubClassId: "FunETF", strategyName: "Real Estate" },
  { assetSubClassId: "FunETF", strategyName: "Utilities" },
  { assetSubClassId: "FunETF", strategyName: "Other" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Corporate" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Government" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Aggregate" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Mortgage Backed" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Municipals" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Asset Backed" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Bank Loans" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Convertible" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Inflation Protected" },
  { assetSubClassId: "FunFixedIncome", strategyName: "Preferred" },
  { assetSubClassId: "FunMixed", strategyName: "Aggressive" },
  { assetSubClassId: "FunMixed", strategyName: "Moderate" },
  { assetSubClassId: "FunMixed", strategyName: "Conservative" },
  { assetSubClassId: "FunMixed", strategyName: "Dynamic" },
  { assetSubClassId: "FunMixed", strategyName: "Global" },
  { assetSubClassId: "FunMixed", strategyName: "Target up to 2015" },
  { assetSubClassId: "FunMixed", strategyName: "Target 2016-2020" },
  { assetSubClassId: "FunMixed", strategyName: "Target 2021-2025" },
  { assetSubClassId: "FunMixed", strategyName: "Target 2026...2050" },
  { assetSubClassId: "FunMixed", strategyName: "Target 2051-2055" },
  { assetSubClassId: "FunMixed", strategyName: "Target 2055" },
  { assetSubClassId: "FunSpeciality", strategyName: "Life Policy" },
  { assetSubClassId: "FunSpeciality", strategyName: "Currency" },
  { assetSubClassId: "FunSpeciality", strategyName: "Derivative" },
  { assetSubClassId: "FunSpeciality", strategyName: "Specialty(ETF)" },
  { assetSubClassId: "FunRealEstate", strategyName: "Physical Securities" },
  {
    assetSubClassId: "FunRealEstate",
    strategyName: "Physical Assets and Securities",
  },
  { assetSubClassId: "FunMoneyMarket", strategyName: "Currency Focused" },
  { assetSubClassId: "FunMoneyMarket", strategyName: "General MMKT" },
  { assetSubClassId: "FunMoneyMarket", strategyName: "Enhanced MMKT" },
  { assetSubClassId: "FunMoneyMarket", strategyName: "First Tier" },
  { assetSubClassId: "FunMoneyMarket", strategyName: "Global" },
  { assetSubClassId: "FunMoneyMarket", strategyName: "Govt & Agency" },
  { assetSubClassId: "FunMoneyMarket", strategyName: "Second Tier" },
  { assetSubClassId: "FunMoneyMarket", strategyName: "Treasury & Repo" },
  { assetSubClassId: "FunMoneyMarket", strategyName: "Treasury" },
  { assetSubClassId: "FunCommodity", strategyName: "Agriculture" },
  { assetSubClassId: "FunCommodity", strategyName: "Energy" },
  { assetSubClassId: "FunCommodity", strategyName: "Precious Metals" },
  { assetSubClassId: "FunCommodity", strategyName: "Industrial Metals" },
  { assetSubClassId: "FunCommodity", strategyName: "Broad Based" },
  { assetSubClassId: "FunCommodity", strategyName: "Livestock" },
  {
    assetSubClassId: "FunAltHedgeFunds",
    strategyName: "Convertible Arbitrage",
  },
  {
    assetSubClassId: "FunAltHedgeFunds",
    strategyName: "Credit or Private Markets",
  },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Crypto" },
  {
    assetSubClassId: "FunAltHedgeFunds",
    strategyName: "CTA / Managed Futures",
  },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Equity Long / Short" },
  {
    assetSubClassId: "FunAltHedgeFunds",
    strategyName: "Equity Multi Strategy",
  },
  {
    assetSubClassId: "FunAltHedgeFunds",
    strategyName: "Fixed-Income Arbitrage",
  },
  // {assetSubClassId: "FunAltHedgeFunds", strategyName:"Fund of Funds"},
  // {assetSubClassId: "FunAltHedgeFunds", strategyName:"Fund of PE Funds"},
  // {assetSubClassId: "FunAltHedgeFunds", strategyName:"Fund of VC Funds"},
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Macro" },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Market Neutral" },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Merger Arbitrage" },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Multi Strategy" },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Quantitative" },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Venture Capital" },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Volatility Arbitrage" },
  { assetSubClassId: "FunAlternatives", strategyName: "Fund of Funds" },
  { assetSubClassId: "FunAlternatives", strategyName: "Fund of PE Funds" },
  { assetSubClassId: "FunAlternatives", strategyName: "Fund of VC Funds" },
  { assetSubClassId: "FunAlternatives", strategyName: "Mixed Funds" },
  { assetSubClassId: "FunOther", strategyName: "Other" },
  { assetSubClassId: "FunSpeciality", strategyName: "Capital Protection Fund" },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Event Driven" },
  { assetSubClassId: "FunAltHedgeFunds", strategyName: "Relative Value" },
  {
    assetSubClassId: "FunFixedIncome",
    strategyName: "Private debt/Private markets",
  },
  { assetSubClassId: "FunAltVentureCapital", strategyName: "Early Stage" },
  { assetSubClassId: "FunAltVentureCapital", strategyName: "Growth" },
  { assetSubClassId: "FunAltVentureCapital", strategyName: "Late Stage" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Distressed" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Fixed Income" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Global Growth" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Global Buyout" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Impact" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Infrastructure" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Opportunistic" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Private Equity" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Real Estate" },
  { assetSubClassId: "FunAltPrivateEquity", strategyName: "Secondaries" },
];
