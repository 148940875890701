import * as React from "react";
import s from "./DropDown.module.scss";
import {
  DropDownList,
  DropDownListProps,
} from "@progress/kendo-react-dropdowns";
import cn from "classnames";

interface IProps extends DropDownListProps {
  illioInputProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}

export const DropDown = ({ className, illioInputProps, ...rest }: IProps) => {
  return (
    <div className={"illio-input"} {...illioInputProps}>
      <DropDownList
        popupSettings={{ popupClass: "illio-combo" }}
        className={cn(s.dropDown, className)}
        {...rest}
      />
    </div>
  );
};

export default DropDown;
