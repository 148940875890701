import * as React from "react";
import SelectAccountPage from "@iliotech/component-library/src/components/__pageComponents/SelectAccountPage/SelectAccountPage";
import { useAuth } from "@iliotech/data-wire";

const mockedAccounts = [
  {
    externalAccountId: "59cc5e3e-81d1-44fe-8567-32396a7d50ed",
    name: "illio",
    status: "ENABLED",
    level: "ENTERPRISE",
  },
];

const SelectAccount = () => {
  const { selectIllioAccount, availableAccounts } = useAuth();
  console.log({ availableAccounts });
  return (
    <SelectAccountPage
      accountList={availableAccounts}
      onSelectAccount={selectIllioAccount}
    />
  );
};

export default SelectAccount;
