export const BANDS = [
  {
    num: 1,
    startPercentage: 0,
    endPercentage: 2,
    color: '#41d9a0',
    textColor: "black",
  },
  {
    num: 2,
    startPercentage: 2,
    endPercentage: 5,
    color: "#00b26f",
    textColor: "black",
  },
  {
    num: 3,
    startPercentage: 5,
    endPercentage: 10,
    color: "#d9bf41",
    textColor: "black",
  },
  {
    num: 4,
    startPercentage: 10,
    endPercentage: 15,
    color: "#fb9900",
    textColor: "black",
  },
  {
    num: 5,
    startPercentage: 15,
    endPercentage: 25,
    color: "#e17300",
    textColor: "black",
  },
  {
    num: 6,
    startPercentage: 25,
    endPercentage: 50,
    color: "#e13800",
    textColor: "white",
  },
  {
    num: 7,
    startPercentage: 50,
    endPercentage: 100,
    color: "#a50009",
    textColor: "white",
  },
]

type Coords = { x: number, y: number };
export const makeLinePath = (start: Coords, finish: Coords) => {
  const midX = (start.x + finish.x) / 2;
  const midY = (start.y + finish.y) / 2;
  return `M${start.x} ${start.y} Q${start.x},${start.y + (midY - start.y) / 2} ${midX},${midY} T${finish.x},${finish.y} `;
}


export const END_ARROW_SIZE = 6;
export const BAND_START_Y = 80;
export const BAND_LABEL_HEIGHT = 25;
export const NUM_LINES = 5;
export const GAP_AT_TOP = 30;
export const GAP_AT_END = 30;
export const BAND_AREA_HEIGHT = 200;
export const MIN_COLUMN_HEIGHT = 30;
export const LINES_HEIGHT = 50;
export const MARKER_SIZE = 20;
