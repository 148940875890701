import React, { PropsWithChildren } from "react";
import { createContainer } from "./createContainer";
import cn from "classnames";
import { createPortal } from "react-dom";
import s from "./Notification.module.scss";
import { clearTimeout } from "timers";
import { FaTimes } from "react-icons/fa";

interface IOwnProps {
  type: "success" | "error" | "warn";
  onClose(): void;
  duration?: number; // if set to 0, the notification won't dismiss by itself
  title?: string;
  description?: string;
}

const container = createContainer();
const timeToDelete = 300;
const DEFAULT_DURATION = 10_000;
export const Notification = ({
  type,
  onClose,
  title,
  description,
  duration = DEFAULT_DURATION,
}: PropsWithChildren<IOwnProps>) => {
  const [isClosing, setIsClosing] = React.useState(false);

  React.useEffect(() => {
    if (duration) {
      const timeoutId = setTimeout(() => setIsClosing(true), DEFAULT_DURATION);
      return () => {
        try {
          clearTimeout(timeoutId);
        } catch (e: any) {
          console.log("Error clearing timeout");
        }
      };
    }
  }, [duration]);

  React.useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onClose, timeToDelete);
      return () => {
        try {
          clearTimeout(timeoutId);
        } catch (e: any) {
          console.log("Error clearing timeout");
        }
      };
    }
  }, [isClosing, onClose]);

  return createPortal(
    <div className={cn([s.container, { [s.shrink]: isClosing }])}>
      <div
        className={cn(s.notification, {
          [s.slideIn]: !isClosing,
          [s.slideOut]: isClosing,
        })}
      >
        <div className={cn(s.iconArea)}>
          <div className={cn(s.iconSurround, s[type])}></div>
        </div>
        <div className={cn(s.content)}>
          {!!title && <div className={cn(s.title)}>{title}</div>}
          {!!description && (
            <div className={cn(s.description)}>{description}</div>
          )}
        </div>
        <div className={s.closeButton} onClick={() => setIsClosing(true)}>
          <FaTimes />
        </div>
      </div>
    </div>,
    container
  );
};
