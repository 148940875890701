import { useQuery } from "react-query";
import { AccountManagementApi } from "@iliotech/generated-api-v3";
import { API_BASE } from "../../constants/constants";

export const usePortfolioList = (
  externalAccountId: string,
  workgroupType?: "CLIENT" | "INTERNAL" | "GLOBAL" | "MODEL",
  disabled?: boolean
) => {
  const api = new AccountManagementApi(undefined, `${API_BASE}/api`);
  return useQuery(
    [`portfolio-list`, { externalAccountId, workgroupType }],
    () =>
      api.getCurrentUserPortfolios1(
        externalAccountId,
        undefined,
        undefined,
        workgroupType
      ),
    { enabled: !!externalAccountId && !disabled }
  );
};
