/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { AssetClassDto } from '../../aggregator';
// @ts-ignore
import { AssetSubClassDto } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { GeoRegion } from '../../aggregator';
// @ts-ignore
import { InstrumentDto } from '../../aggregator';
// @ts-ignore
import { InstrumentFindRequest } from '../../aggregator';
// @ts-ignore
import { InstrumentRequest } from '../../aggregator';
// @ts-ignore
import { IntrumentData } from '../../aggregator';
/**
 * InstrumentControllerApi - axios parameter creator
 * @export
 */
export const InstrumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetLightList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/asset.light`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/asset.all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetSubClassList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/asset.subclass.all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InstrumentRequest} instrumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentById: async (instrumentRequest: InstrumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instrumentRequest' is not null or undefined
            assertParamExists('getInstrumentById', 'instrumentRequest', instrumentRequest)
            const localVarPath = `/v1/instrument.get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InstrumentFindRequest} instrumentFindRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentByName: async (instrumentFindRequest: InstrumentFindRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instrumentFindRequest' is not null or undefined
            assertParamExists('getInstrumentByName', 'instrumentFindRequest', instrumentFindRequest)
            const localVarPath = `/v1/instrument.find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instrumentFindRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentsInError: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/instruments/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateInstrumentBySourceId1: async (sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('getOrCreateInstrumentBySourceId1', 'sourceId', sourceId)
            // verify required parameter 'sourceData' is not null or undefined
            assertParamExists('getOrCreateInstrumentBySourceId1', 'sourceData', sourceData)
            const localVarPath = `/v1/instrument/sourceId/{sourceId}/sourceData/{sourceData}`
                .replace(`{${"sourceId"}}`, encodeURIComponent(String(sourceId)))
                .replace(`{${"sourceData"}}`, encodeURIComponent(String(sourceData)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/regions.all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshInstruments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/instrument/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshMarketData: async (ticker: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticker' is not null or undefined
            assertParamExists('refreshMarketData', 'ticker', ticker)
            const localVarPath = `/v1/instrument/{ticker}/repair`
                .replace(`{${"ticker"}}`, encodeURIComponent(String(ticker)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticker 
         * @param {string} fromDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshMarketData1: async (ticker: string, fromDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticker' is not null or undefined
            assertParamExists('refreshMarketData1', 'ticker', ticker)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('refreshMarketData1', 'fromDate', fromDate)
            const localVarPath = `/v1/instrument/{ticker}/repairFromDate`
                .replace(`{${"ticker"}}`, encodeURIComponent(String(ticker)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstrumentControllerApi - functional programming interface
 * @export
 */
export const InstrumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstrumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetLightList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetClassDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetLightList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetClassDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetSubClassList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetSubClassDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetSubClassList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InstrumentRequest} instrumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstrumentById(instrumentRequest: InstrumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstrumentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrumentById(instrumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InstrumentFindRequest} instrumentFindRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstrumentByName(instrumentFindRequest: InstrumentFindRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstrumentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrumentByName(instrumentFindRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstrumentsInError(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntrumentData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrumentsInError(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrCreateInstrumentBySourceId1(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrCreateInstrumentBySourceId1(sourceId, sourceData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeoRegion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshInstruments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshInstruments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshMarketData(ticker: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshMarketData(ticker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticker 
         * @param {string} fromDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshMarketData1(ticker: string, fromDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshMarketData1(ticker, fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstrumentControllerApi - factory interface
 * @export
 */
export const InstrumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstrumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetLightList(options?: any): AxiosPromise<Array<AssetClassDto>> {
            return localVarFp.getAssetLightList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetList(options?: any): AxiosPromise<Array<AssetClassDto>> {
            return localVarFp.getAssetList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetSubClassList(options?: any): AxiosPromise<Array<AssetSubClassDto>> {
            return localVarFp.getAssetSubClassList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InstrumentRequest} instrumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentById(instrumentRequest: InstrumentRequest, options?: any): AxiosPromise<Array<InstrumentDto>> {
            return localVarFp.getInstrumentById(instrumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InstrumentFindRequest} instrumentFindRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentByName(instrumentFindRequest: InstrumentFindRequest, options?: any): AxiosPromise<Array<InstrumentDto>> {
            return localVarFp.getInstrumentByName(instrumentFindRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrumentsInError(options?: any): AxiosPromise<Array<IntrumentData>> {
            return localVarFp.getInstrumentsInError(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateInstrumentBySourceId1(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', options?: any): AxiosPromise<InstrumentDto> {
            return localVarFp.getOrCreateInstrumentBySourceId1(sourceId, sourceData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsList(options?: any): AxiosPromise<Array<GeoRegion>> {
            return localVarFp.getRegionsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshInstruments(options?: any): AxiosPromise<void> {
            return localVarFp.refreshInstruments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshMarketData(ticker: string, options?: any): AxiosPromise<string> {
            return localVarFp.refreshMarketData(ticker, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticker 
         * @param {string} fromDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshMarketData1(ticker: string, fromDate: string, options?: any): AxiosPromise<string> {
            return localVarFp.refreshMarketData1(ticker, fromDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstrumentControllerApi - object-oriented interface
 * @export
 * @class InstrumentControllerApi
 * @extends {BaseAPI}
 */
export class InstrumentControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public getAssetLightList(options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).getAssetLightList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public getAssetList(options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).getAssetList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public getAssetSubClassList(options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).getAssetSubClassList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InstrumentRequest} instrumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public getInstrumentById(instrumentRequest: InstrumentRequest, options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).getInstrumentById(instrumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InstrumentFindRequest} instrumentFindRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public getInstrumentByName(instrumentFindRequest: InstrumentFindRequest, options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).getInstrumentByName(instrumentFindRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public getInstrumentsInError(options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).getInstrumentsInError(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceId 
     * @param {'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark'} sourceData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public getOrCreateInstrumentBySourceId1(sourceId: string, sourceData: 'MarketEodService' | 'MarketRefinitivService' | 'MarketBondService' | 'RealAssetService' | 'UserInstrumentService' | 'SystemInstrumentService' | 'OTCService' | 'OptionsService' | 'CustomBenchmark', options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).getOrCreateInstrumentBySourceId1(sourceId, sourceData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public getRegionsList(options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).getRegionsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public refreshInstruments(options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).refreshInstruments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public refreshMarketData(ticker: string, options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).refreshMarketData(ticker, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticker 
     * @param {string} fromDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentControllerApi
     */
    public refreshMarketData1(ticker: string, fromDate: string, options?: AxiosRequestConfig) {
        return InstrumentControllerApiFp(this.configuration).refreshMarketData1(ticker, fromDate, options).then((request) => request(this.axios, this.basePath));
    }
}
