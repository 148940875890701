import { Field } from "@progress/kendo-react-form";
import type { KeyValue } from "@progress/kendo-react-form";
import { textValidatorWrapper } from "@iliotech/data-wire";
import Input from "../Input/Input";
import { AutocompleteProps } from "../Autocomplete/Autocomplete";

interface IOwnProps extends Omit<AutocompleteProps, "name" | "label"> {
  errors: KeyValue<string>;
  name?: string;
  label?: string | null;
  placeholder?: string;
  validator?(s: string): string | undefined;
}
const defaultValidator = textValidatorWrapper({
  minLength: 3,
  maxLength: 50,
});

export const NameField = ({
  errors,
  name = "name",
  label = "Name",
  placeholder,
  validator = defaultValidator,
  ...rest
}: IOwnProps) => {
  return (
    <Field
      name={name}
      error={errors?.[name]}
      type={"text"}
      component={Input}
      placeholder={placeholder}
      label={label ?? undefined}
      validator={validator}
      {...rest}
    />
  );
};
