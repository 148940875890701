import React, { FC } from "react";
import { IDecimalCellProps, IDynamicCellProps } from "../types";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { HighlightedNumberOrName } from "../../Menu/Menu";
import { Skeleton, SkeletonAnimation } from "@progress/kendo-react-indicators";
export const DynamicCell = ({ loading }: any): FC<IDynamicCellProps> => {
  const getColorFromType = colorChooser();

  return React.memo(({ width = "100%", ...props }) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const field = props.field || "";
    const {
      value,
      displayValue,
      type = "NeutralNumber",
      suffix = "%",
    } = props.dataItem[field];

    return (
      <td
        style={{
          color: getColorFromType(type, displayValue ?? String(value)),
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div style={{ width, textAlign: "right" }}>
          {loading ? (
            <Skeleton
              shape={"text"}
              style={{ width: 70, marginLeft: "auto" }}
            />
          ) : (
            <span data-cy="cellValue">{`${
              displayValue ?? value ?? ""
            }${suffix}`}</span>
          )}
        </div>
      </td>
    );
  });
};

function colorChooser() {
  return (type: HighlightedNumberOrName, value?: string) => {
    switch (type) {
      case "NeutralNumber": {
        return undefined; //parseFloat(value ?? "") > 0 ? "var(--good)" : "var(--bad)";
      }
      case "ImportantNumber": {
        return parseFloat(value ?? "") > 0
          ? "var(--good)"
          : parseFloat(value ?? "") < 0
          ? "var(--bad)"
          : "var(--dark)";
      }
      case "GreenWhenPositive": {
        return parseFloat(value ?? "") > 0
          ? "var(--good)"
          : parseFloat(value ?? "") < 0
          ? "var(--bad)"
          : "var(--dark)";
      }
      case "RedWhenNegative": {
        return parseFloat(value ?? "") > 0
          ? "var(--good)"
          : parseFloat(value ?? "") < 0
          ? "var(--bad)"
          : "var(--dark)";
      }
      default:
        return "var(--neutral)";
    }
  };
}
