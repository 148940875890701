import {
  GRID_COL_INDEX_ATTRIBUTE,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { CSSProperties, FunctionComponent } from "react";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import s from "./Cells.module.scss";
import classNames from "classnames";
export const CellWrapper = ({
  gridCellProps,
  cell,
  baseCurrency,
  rightAligned,
  removeBorderLeft,
  dataItem,
  extraStyles,
}: {
  gridCellProps: GridCellProps;
  cell: FunctionComponent<any>;
  baseCurrency?: string;
  rightAligned?: boolean;
  removeBorderLeft?: boolean;
  dataItem?: any;
  extraStyles?: CSSProperties;
}) => {
  const Cell = cell;
  const index = gridCellProps.dataItem.id;
  const navigationAttributes = useTableKeyboardNavigation(gridCellProps.id);

  return (
    <td
      style={{
        // TODO: replace colors with appropriate css-vars
        color: "var(--color)",
        backgroundColor: "transparent",
        ...extraStyles,
      }}
      colSpan={gridCellProps.colSpan}
      role={"gridcell"}
      className={classNames(
        s.tableCell,
        rightAligned && s.rightAligned,
        removeBorderLeft && s.removeBorderLeft
      )}
      aria-colindex={gridCellProps.ariaColumnIndex}
      aria-selected={gridCellProps.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: gridCellProps.columnIndex,
      }}
      {...navigationAttributes}
    >
      <Cell index={index} baseCurrency={baseCurrency} dataItem={dataItem} />
    </td>
  );
};
