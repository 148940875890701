import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  updateWorkgroup,
  useAuth,
  useWorkgroupList,
} from "@iliotech/data-wire";
import RenameModal from "@iliotech/component-library/src/components/__pageComponents/WorkgroupListPage/components/RenameModal/RenameModal";

export const RenameModalWrapper = observer(
  ({ onComplete }: { onComplete: () => void }) => {
    const { illioSelectedAccount } = useAuth();
    const store = useWorkgroupList();
    const visible = store.modalState.type === "rename";

    const onSave = async (data: any) => {
      const name = data?.name;
      store.setModalLoading(true);
      try {
        const result = await updateWorkgroup(
          { name },
          illioSelectedAccount?.externalAccountId!,
          store.modalState.id
        );
        store.resetModalState();
        onComplete();
      } catch (e) {
        console.log(e);
        store.resetModalState();
      }
    };

    return (
      <RenameModal
        loading={store.modalState.loading}
        visible={visible}
        handleClose={() => store.resetModalState()}
        onSave={onSave}
        name={store.modalState.name}
      />
    );
  }
);

export default RenameModalWrapper;
