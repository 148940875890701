import validator from "validator";
import { FormProvider, useFormContext, UseFormReturn } from "react-hook-form";
import React, { FC, useEffect, useState } from "react";
import cn from "classnames";
import s from "./SignUp.module.css";
import { SignUpParams } from "@aws-amplify/auth";
import { Link } from "react-router-dom";
import PasswordEyeButton from "../PasswordEyeButton/PasswordEyeButton";
import { PasswordValidator } from "./PasswordValidator/PasswordValidator";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useResidentialCountries } from "@iliotech/data-wire/src/reactHooks/api/useResidentialCountries";
import { getUserCountry } from "./utils";
import { COUNTRIES_DEFAULT_LIST } from "./countries_hardcoded";

export type SubmissionHandler = (options: {
  password: string;
  email: string;
  firstName: string;
  lastName: string;
}) => Promise<any>;

const DEFAULT_PASSWORD_VALIDATOR = (val: string) =>
  validator.isStrongPassword(val, {
    minLength: 8,
    minUppercase: 1,
    minLowercase: 1,
    minNumbers: 1,
  })
    ? true
    : "Must be 8+ characters and contain uppercase, lowercase letters and numbers";
const seed = Math.floor(Math.random() * 10000000);

interface IUserDetailFormProps {
  onSubmit(params: SignUpParams): Promise<any>;
  methods: UseFormReturn;
}

export const UserDetailsForm = ({
  onSubmit,
  methods,
}: IUserDetailFormProps) => {
  const countriesRequest = useResidentialCountries();
  useEffect(() => {
    setTimeout(() => countriesRequest.refetch(), 1000);
  }, []);

  const countriesData = countriesRequest.data?.data;

  const countries = React.useMemo(() => {
    return Object.entries(countriesData || {}).map(([key, value]) => ({
      label: value as string,
      value: key,
    }));
  }, [JSON.stringify(countriesData)]);

  const countriesList = countries.length ? countries : COUNTRIES_DEFAULT_LIST;

  useEffect(() => {
    if (!countriesList.length) return;
    const country = getUserCountry();
    if (country) {
      setUserCountry(countriesList.find((c) => c.value === country));
    }
  }, [JSON.stringify(countriesList)]);

  const [userCountry, setUserCountry] =
    useState<{ label: string; value: string }>();

  let handleSubmit: SubmissionHandler = async ({
    password,
    email,
    firstName,
    lastName,
    country,
  }: {
    password: string;
    email: string;
    firstName: string;
    lastName: string;
    country: { label: string; value: string };
  }) => {
    onSubmit({
      password,
      username: email!,
      attributes: {
        family_name: lastName,
        name: firstName,
        ["custom:country_residence"]: country?.value,
      },
    }).catch((e) => {
      if (e.code === "UsernameExistsException") {
        methods.setError("email", {
          type: "validate",
          message: "Email already in use",
        });
      }
      return null;
    });
  };

  return (
    <FormProvider {...methods}>
      <SignupForm
        onSubmit={handleSubmit}
        countries={countries}
        userCountry={userCountry}
      />
    </FormProvider>
  );
};

const SignupForm = ({
  onSubmit,
  validatePassword = DEFAULT_PASSWORD_VALIDATOR,
  countries = [],
  userCountry,
}: {
  onSubmit: SubmissionHandler;
  validatePassword?: (val: string) => boolean | string;
  countries: { label: string; value: string }[];
  userCountry?: { label: string; value: string };
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const [passwordInputType, setPasswordInputType] = React.useState("password");
  const [countryData, setCountryData] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedCountry, setSelectedCountry] =
    useState<{ label: string; value: string }>();

  useEffect(() => {
    if (countries.length) setCountryData(countries);
  }, [JSON.stringify(countries)]);

  useEffect(() => {
    if (userCountry) setSelectedCountry(userCountry);
  }, [JSON.stringify(userCountry)]);

  const onEyeClick = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit as any)}>
      <article className={cn(s.formSurround)}>
        <div className="grid" style={{ marginTop: "2rem" }}>
          <Wrapper dataKey={"firstName"} label={"First name"} errors={errors}>
            <input
              {...register("firstName", { minLength: 2 })}
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First name"
              // defaultValue={`Leo-${seed}`}
              required
            />
          </Wrapper>

          <Wrapper dataKey={"lastName"} label={"Last name"} errors={errors}>
            <input
              {...register("lastName", { minLength: 2 })}
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Last name"
              // defaultValue={`Ijebor-${seed}`}
              required
            />
          </Wrapper>
        </div>
        <div className={"illio-input"}>
          <Wrapper dataKey={"country"} label={"Country"} errors={errors}>
            <ComboBox
              {...register("country", {
                minLength: 2,
                validate: (val) => !!val?.value || !!selectedCountry?.value,
              })}
              className={cn(s.combo, "override-border-top")}
              id="country"
              popupSettings={{ popupClass: "illio-combo" }}
              name="country"
              textField={"label"}
              dataItemKey={"value"}
              value={selectedCountry}
              data={countryData}
              onChange={(e) => setSelectedCountry(e.target.value)}
              filterable={true}
              onFilterChange={(e) => {
                setCountryData(
                  countries.filter((c) =>
                    c.label.toLowerCase().includes(e.filter.value.toLowerCase())
                  )
                );
              }}
              placeholder="e.g. Denmark"
            />
          </Wrapper>
        </div>
        <Wrapper dataKey={"email"} label={"Email address"} errors={errors}>
          <input
            {...register("email", {
              validate: (val: string) => validator.isEmail(val),
            })}
            type="email"
            id="email"
            name="email"
            placeholder="name@email.com"
            // defaultValue={`leo.ijebor+${seed}@googlemail.com`}
            required
          />
        </Wrapper>

        <Wrapper dataKey={"password"} label={"Password"} errors={errors}>
          <div className={s.passwordRequirements}>
            <input
              {...register("password", { validate: validatePassword })}
              type={passwordInputType}
              id="password"
              name="password"
              placeholder={"8+ characters, inc numbers and special characters"}
              // defaultValue={"$Admin$123"}
              required
            />
            <PasswordEyeButton
              visible={passwordInputType === "password"}
              onClick={onEyeClick}
            />
          </div>
        </Wrapper>
        <PasswordValidator />

        <br />
        <br />
        <small>
          By pressing submit, you agree to illio's{" "}
          <a
            href={"https://illio.com/terms-of-use"}
            target={"_bank"}
            rel={"noreferrer"}
          >
            Terms and conditions
          </a>{" "}
          and{" "}
          <a
            href={"https://illio.com/privacy-policy"}
            target={"_bank"}
            rel={"noreferrer"}
          >
            Privacy Policy
          </a>
        </small>
        <br />
        <br />

        <small style={{ display: "block" }}>
          Already have an account? <Link to={"/home"}>Login</Link>
        </small>
        <button
          type="submit"
          data-cy={"submit-button"}
          className={"amplify-button"}
          style={{
            marginTop: "2rem",
            width: "100%",
            borderRadius: 10,
          }}
        >
          Submit
        </button>
      </article>
    </form>
  );
};

const Wrapper: FC<{ dataKey: string; label: string; errors: any }> = ({
  dataKey,
  label,
  children,
  errors,
}) => {
  const error = errors[dataKey];
  // console.log({ error });
  return (
    <div className={cn(s.inputWrapper, errors[dataKey] && s.error)}>
      <label htmlFor={dataKey}>{label}</label>
      {children}
      {!!error?.message && (
        <div className={cn(s.errorMessage)}>{error.message}</div>
      )}
    </div>
  );
};
