import s from "./ToggableItem.module.scss";
import React from "react";

export interface ILegendItem {
  label: string;
  value: string;
  color: string;
}

interface IProps {
  item: ILegendItem;
  onPress: (key: string) => void;
}

const labelMapper = new Map();

labelMapper.set("CashAndEquivalents", "Cash And Equivalents");
labelMapper.set("FixedIncome", "Fixed Income");
labelMapper.set("CryptoCurrencies", "Crypto Currencies");

const ToggableItem = ({ item, onPress }: IProps) => {
  return (
    <div onClick={() => onPress(item.label)} className={s.itemContainer}>
      <div
        className={s.legendColor}
        style={{
          backgroundColor: item.color === "white" ? "transparent" : item.color,
        }}
      />
      <div>
        <span className={s.legendItemName}>
          {labelMapper.get(item.label)
            ? labelMapper.get(item.label)
            : item.label}
        </span>
        <span
          className={s.legendItemValue}
          data-cy={"chart-" + item?.label.replaceAll(" ", "-").toLowerCase()}
        >
          {item.value}
        </span>
      </div>
    </div>
  );
};

export default ToggableItem;
