/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ComparisonScenarioDto } from '../../aggregator';
// @ts-ignore
import { CreateComparisonScenario } from '../../aggregator';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { PortfolioComparisonResponse } from '../../aggregator';
// @ts-ignore
import { UpdateComparisonScenario } from '../../aggregator';
// @ts-ignore
import { VirtualPortfolioResponse } from '../../aggregator';
/**
 * WhatIfComparisonApi - axios parameter creator
 * @export
 */
export const WhatIfComparisonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Created a virtual copy to hold the new portfolio and a list of positions to be used as a base for the new portfolio
         * @summary Create virtual copy portfolio
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVirtualCopy: async (externalAccountId: string, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('createVirtualCopy', 'externalAccountId', externalAccountId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('createVirtualCopy', 'portfolioId', portfolioId)
            const localVarPath = `/v3/account/{externalAccountId}/whatIf/{portfolioId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComparisonScenario: async (externalAccountId: string, scenarioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('deleteComparisonScenario', 'externalAccountId', externalAccountId)
            // verify required parameter 'scenarioId' is not null or undefined
            assertParamExists('deleteComparisonScenario', 'scenarioId', scenarioId)
            const localVarPath = `/v3/account/{externalAccountId}/whatIf/scenario/{scenarioId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllComparisonScenarios: async (externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getAllComparisonScenarios', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/whatIf/scenarios`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparisonScenario: async (externalAccountId: string, scenarioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getComparisonScenario', 'externalAccountId', externalAccountId)
            // verify required parameter 'scenarioId' is not null or undefined
            assertParamExists('getComparisonScenario', 'scenarioId', scenarioId)
            const localVarPath = `/v3/account/{externalAccountId}/whatIf/scenario/{scenarioId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Compare two portfolios side by side, date from is optional and defaults to 1 year ago
         * @summary Get portfolio comparison stats
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {string} comparisonPortfolioId 
         * @param {string} [dateFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioComparison: async (externalAccountId: string, portfolioId: string, comparisonPortfolioId: string, dateFrom?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getPortfolioComparison', 'externalAccountId', externalAccountId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioComparison', 'portfolioId', portfolioId)
            // verify required parameter 'comparisonPortfolioId' is not null or undefined
            assertParamExists('getPortfolioComparison', 'comparisonPortfolioId', comparisonPortfolioId)
            const localVarPath = `/v3/account/{externalAccountId}/whatIf/{portfolioId}/compare/{comparisonPortfolioId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)))
                .replace(`{${"comparisonPortfolioId"}}`, encodeURIComponent(String(comparisonPortfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of positions to be used as a base for the new what if portfolio
         * @summary Get portfolio positions as what if ones
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositions: async (externalAccountId: string, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('getPositions', 'externalAccountId', externalAccountId)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPositions', 'portfolioId', portfolioId)
            const localVarPath = `/v3/account/{externalAccountId}/whatIf/{portfolioId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateComparisonScenario} createComparisonScenario 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveComparisonScenario: async (createComparisonScenario: CreateComparisonScenario, externalAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createComparisonScenario' is not null or undefined
            assertParamExists('saveComparisonScenario', 'createComparisonScenario', createComparisonScenario)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('saveComparisonScenario', 'externalAccountId', externalAccountId)
            const localVarPath = `/v3/account/{externalAccountId}/whatIf`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createComparisonScenario, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateComparisonScenario} updateComparisonScenario 
         * @param {string} externalAccountId 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComparisonScenario: async (updateComparisonScenario: UpdateComparisonScenario, externalAccountId: string, scenarioId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateComparisonScenario' is not null or undefined
            assertParamExists('updateComparisonScenario', 'updateComparisonScenario', updateComparisonScenario)
            // verify required parameter 'externalAccountId' is not null or undefined
            assertParamExists('updateComparisonScenario', 'externalAccountId', externalAccountId)
            // verify required parameter 'scenarioId' is not null or undefined
            assertParamExists('updateComparisonScenario', 'scenarioId', scenarioId)
            const localVarPath = `/v3/account/{externalAccountId}/whatIf/scenario/{scenarioId}`
                .replace(`{${"externalAccountId"}}`, encodeURIComponent(String(externalAccountId)))
                .replace(`{${"scenarioId"}}`, encodeURIComponent(String(scenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateComparisonScenario, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WhatIfComparisonApi - functional programming interface
 * @export
 */
export const WhatIfComparisonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WhatIfComparisonApiAxiosParamCreator(configuration)
    return {
        /**
         * Created a virtual copy to hold the new portfolio and a list of positions to be used as a base for the new portfolio
         * @summary Create virtual copy portfolio
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVirtualCopy(externalAccountId: string, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VirtualPortfolioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVirtualCopy(externalAccountId, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComparisonScenario(externalAccountId: string, scenarioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComparisonScenario(externalAccountId, scenarioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllComparisonScenarios(externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparisonScenarioDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllComparisonScenarios(externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComparisonScenario(externalAccountId: string, scenarioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonScenarioDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComparisonScenario(externalAccountId, scenarioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Compare two portfolios side by side, date from is optional and defaults to 1 year ago
         * @summary Get portfolio comparison stats
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {string} comparisonPortfolioId 
         * @param {string} [dateFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioComparison(externalAccountId: string, portfolioId: string, comparisonPortfolioId: string, dateFrom?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioComparisonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioComparison(externalAccountId, portfolioId, comparisonPortfolioId, dateFrom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of positions to be used as a base for the new what if portfolio
         * @summary Get portfolio positions as what if ones
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositions(externalAccountId: string, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VirtualPortfolioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositions(externalAccountId, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateComparisonScenario} createComparisonScenario 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveComparisonScenario(createComparisonScenario: CreateComparisonScenario, externalAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonScenarioDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveComparisonScenario(createComparisonScenario, externalAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateComparisonScenario} updateComparisonScenario 
         * @param {string} externalAccountId 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComparisonScenario(updateComparisonScenario: UpdateComparisonScenario, externalAccountId: string, scenarioId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonScenarioDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComparisonScenario(updateComparisonScenario, externalAccountId, scenarioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WhatIfComparisonApi - factory interface
 * @export
 */
export const WhatIfComparisonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WhatIfComparisonApiFp(configuration)
    return {
        /**
         * Created a virtual copy to hold the new portfolio and a list of positions to be used as a base for the new portfolio
         * @summary Create virtual copy portfolio
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVirtualCopy(externalAccountId: string, portfolioId: string, options?: any): AxiosPromise<VirtualPortfolioResponse> {
            return localVarFp.createVirtualCopy(externalAccountId, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComparisonScenario(externalAccountId: string, scenarioId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteComparisonScenario(externalAccountId, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllComparisonScenarios(externalAccountId: string, options?: any): AxiosPromise<Array<ComparisonScenarioDto>> {
            return localVarFp.getAllComparisonScenarios(externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} externalAccountId 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComparisonScenario(externalAccountId: string, scenarioId: number, options?: any): AxiosPromise<ComparisonScenarioDto> {
            return localVarFp.getComparisonScenario(externalAccountId, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * Compare two portfolios side by side, date from is optional and defaults to 1 year ago
         * @summary Get portfolio comparison stats
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {string} comparisonPortfolioId 
         * @param {string} [dateFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioComparison(externalAccountId: string, portfolioId: string, comparisonPortfolioId: string, dateFrom?: string, options?: any): AxiosPromise<PortfolioComparisonResponse> {
            return localVarFp.getPortfolioComparison(externalAccountId, portfolioId, comparisonPortfolioId, dateFrom, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of positions to be used as a base for the new what if portfolio
         * @summary Get portfolio positions as what if ones
         * @param {string} externalAccountId 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositions(externalAccountId: string, portfolioId: string, options?: any): AxiosPromise<VirtualPortfolioResponse> {
            return localVarFp.getPositions(externalAccountId, portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateComparisonScenario} createComparisonScenario 
         * @param {string} externalAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveComparisonScenario(createComparisonScenario: CreateComparisonScenario, externalAccountId: string, options?: any): AxiosPromise<ComparisonScenarioDto> {
            return localVarFp.saveComparisonScenario(createComparisonScenario, externalAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateComparisonScenario} updateComparisonScenario 
         * @param {string} externalAccountId 
         * @param {number} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComparisonScenario(updateComparisonScenario: UpdateComparisonScenario, externalAccountId: string, scenarioId: number, options?: any): AxiosPromise<ComparisonScenarioDto> {
            return localVarFp.updateComparisonScenario(updateComparisonScenario, externalAccountId, scenarioId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WhatIfComparisonApi - object-oriented interface
 * @export
 * @class WhatIfComparisonApi
 * @extends {BaseAPI}
 */
export class WhatIfComparisonApi extends BaseAPI {
    /**
     * Created a virtual copy to hold the new portfolio and a list of positions to be used as a base for the new portfolio
     * @summary Create virtual copy portfolio
     * @param {string} externalAccountId 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatIfComparisonApi
     */
    public createVirtualCopy(externalAccountId: string, portfolioId: string, options?: AxiosRequestConfig) {
        return WhatIfComparisonApiFp(this.configuration).createVirtualCopy(externalAccountId, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {number} scenarioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatIfComparisonApi
     */
    public deleteComparisonScenario(externalAccountId: string, scenarioId: number, options?: AxiosRequestConfig) {
        return WhatIfComparisonApiFp(this.configuration).deleteComparisonScenario(externalAccountId, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatIfComparisonApi
     */
    public getAllComparisonScenarios(externalAccountId: string, options?: AxiosRequestConfig) {
        return WhatIfComparisonApiFp(this.configuration).getAllComparisonScenarios(externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} externalAccountId 
     * @param {number} scenarioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatIfComparisonApi
     */
    public getComparisonScenario(externalAccountId: string, scenarioId: number, options?: AxiosRequestConfig) {
        return WhatIfComparisonApiFp(this.configuration).getComparisonScenario(externalAccountId, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Compare two portfolios side by side, date from is optional and defaults to 1 year ago
     * @summary Get portfolio comparison stats
     * @param {string} externalAccountId 
     * @param {string} portfolioId 
     * @param {string} comparisonPortfolioId 
     * @param {string} [dateFrom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatIfComparisonApi
     */
    public getPortfolioComparison(externalAccountId: string, portfolioId: string, comparisonPortfolioId: string, dateFrom?: string, options?: AxiosRequestConfig) {
        return WhatIfComparisonApiFp(this.configuration).getPortfolioComparison(externalAccountId, portfolioId, comparisonPortfolioId, dateFrom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of positions to be used as a base for the new what if portfolio
     * @summary Get portfolio positions as what if ones
     * @param {string} externalAccountId 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatIfComparisonApi
     */
    public getPositions(externalAccountId: string, portfolioId: string, options?: AxiosRequestConfig) {
        return WhatIfComparisonApiFp(this.configuration).getPositions(externalAccountId, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateComparisonScenario} createComparisonScenario 
     * @param {string} externalAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatIfComparisonApi
     */
    public saveComparisonScenario(createComparisonScenario: CreateComparisonScenario, externalAccountId: string, options?: AxiosRequestConfig) {
        return WhatIfComparisonApiFp(this.configuration).saveComparisonScenario(createComparisonScenario, externalAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateComparisonScenario} updateComparisonScenario 
     * @param {string} externalAccountId 
     * @param {number} scenarioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatIfComparisonApi
     */
    public updateComparisonScenario(updateComparisonScenario: UpdateComparisonScenario, externalAccountId: string, scenarioId: number, options?: AxiosRequestConfig) {
        return WhatIfComparisonApiFp(this.configuration).updateComparisonScenario(updateComparisonScenario, externalAccountId, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }
}
