import React, { PropsWithChildren } from "react";
import cn from "classnames";
import s from "./FadeLoading.module.scss";

interface IOwnProps {
  loading?: boolean;
}

export const FadeLoading = ({
  children,
  loading,
}: PropsWithChildren<IOwnProps>) => {
  return (
    <div
      className={cn(s.surround, loading && s.fade)}
      style={{ position: "relative", height: "100%" }}
    >
      {children}
    </div>
  );
};
