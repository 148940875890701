import * as React from "react";
import { DateRangePicker } from "../../DatePicker/DateRangePicker";

import InputWrapper from "../../InputWrapper/InputWrapper";
import s from "./IllioDateRangePicker.module.scss";
import { DateRangePickerProps } from "@progress/kendo-react-dateinputs";

export interface IllioDatePickerProps extends DateRangePickerProps {
  error?: string;
  label?: string;
  visited?: boolean;
  name: string;
  dataCy?: string;
  errorOverride?: boolean;
}

export const IllioDateRangePicker = ({
  error,
  label,
  name,
  visited,
  dataCy,
  errorOverride,
  ...restProps
}: IllioDatePickerProps) => {
  const isError = (visited && error) || errorOverride;
  return (
    <div className="illio-input" data-cy={dataCy ?? "custom-date-field"}>
      <InputWrapper error={isError ? error : ""} label={label} name={name}>
        <DateRangePicker
          className={s.datePicker}
          {...restProps}
          hideWeekends={false}
          valid={!isError}
        />
      </InputWrapper>
    </div>
  );
};

export default IllioDateRangePicker;
