import { IColumn } from "../types";
import { DecimalCell } from "../Cells/DecimalCell";
import { PercentCell } from "../Cells/PercentCell";
import { TextCell } from "../Cells/TextCell";

type OptionsGroup = "Value" | "Mandatory" | "P&L" | "Option" | "Descriptive";
type OptionsColumn = IColumn & {
  group?: OptionsGroup;
};

const checkIsTotal = (rowData: any) => rowData.isTotal;
export const Options: OptionsColumn[] = [
  {
    field: "name",
    isDefault: true,
    order: -1,
    // title,
    locked: true,
    width: 150,
    title: "Instrument Name",
    renderer: TextCell({ checkIsTotal }),
    mandatory: true,
    group: "Mandatory",
  },
  {
    field: "openQuantity",
    order: 1,
    renderer: DecimalCell(),
    headerClassName: "decimal",
    rightAligned: true,
    width: 120,
    title: "Open Qty",
    group: "Mandatory",
    isDefault: true,
  },
  { field: "currency", order: 2, isDefault: true, group: "Descriptive" },
  {
    field: "underlyingSpotPrice",
    renderer: DecimalCell({ forceDecimals: true }),
    headerClassName: "decimal",
    rightAligned: true,
    title: "Underlying Price",
    width: 120,
    order: 3,
    group: "Option",
    isDefault: true,
  },
  {
    field: "initialPremium",
    renderer: DecimalCell({ maxDecimals: 0 }),
    headerClassName: "decimal",
    rightAligned: true,
    title: "Initial Premium (local)",
    width: 150,
    order: 3.2,
    group: "Option",
    isDefault: true,
  },
  {
    field: "initialPremiumBase",
    renderer: DecimalCell({ maxDecimals: 0 }),
    width: 220,
    title: "Initial Premium (£)",
    order: 3.4,
    headerClassName: "decimal",
    rightAligned: true,
    group: "Option",
  },
  {
    field: "currentValue",
    renderer: DecimalCell({ maxDecimals: 0 }),
    headerClassName: "decimal",
    title: "Current Premium (local)",
    width: 200,
    order: 4,
    rightAligned: true,
    group: "Option",
  },
  {
    field: "currentValueBase",
    renderer: DecimalCell({ maxDecimals: 0 }),
    headerClassName: "decimal",
    title: "Current Premium (£)",
    width: 200,
    order: 4,
    rightAligned: true,
    isDefault: true,
    group: "Option",
  },
  {
    field: "costPrice",
    renderer: DecimalCell({ forceDecimals: true }),
    headerClassName: "decimal",
    rightAligned: true,
    width: 150,
    order: 5,
    title: "Cost Price",
    isDefault: true,
    group: "Option",
  },
  {
    field: "currentPrice",
    renderer: DecimalCell(),
    headerClassName: "decimal",
    rightAligned: true,
    isDefault: true,
    order: 6,
    width: 150,
    title: "Current Price",
  },
  {
    field: "notional",
    renderer: DecimalCell({ maxDecimals: 0 }),
    title: "Notional (local)",
    headerClassName: "decimal",
    width: 200,
    order: 8,
    rightAligned: true,
    group: "Option",
  },

  {
    field: "notionalBase",
    renderer: DecimalCell({ maxDecimals: 0 }),

    width: 200,
    title: "Notional (£)",
    headerClassName: "decimal",
    order: 9,
    isDefault: true,
    rightAligned: true,
  },
  {
    field: "deltaExposure",
    renderer: DecimalCell({ maxDecimals: 0 }),
    title: "Delta Exposure (local)",
    width: 200,
    order: 10,
    headerClassName: "decimal",
    rightAligned: true,
    group: "Option",
  },
  {
    field: "deltaExposureBase",
    renderer: DecimalCell({ maxDecimals: 0 }),
    width: 200,
    title: "Exposure (£)",
    order: 11,
    headerClassName: "decimal",
    rightAligned: true,
    isDefault: true,
    group: "Option",
  },
  {
    field: "deltaPercent",
    renderer: PercentCell(),
    title: "Delta %",
    headerClassName: "decimal",
    width: 120,
    order: 12,
    rightAligned: true,
    isDefault: true,
    group: "Option",
  },
  {
    field: "impliedVolPercent",
    renderer: PercentCell(),
    title: "Implied Vol",
    width: 120,
    order: 13,
    headerClassName: "decimal",
    rightAligned: true,
    group: "Option",
    isDefault: true,
  },
  {
    field: "expiryDate",
    title: "Expiry date",
    width: 120,
    order: 13.5,
    isDefault: true,
    group: "Option",
  },
  {
    field: "maturity",
    title: "Expiry (yrs)",
    renderer: DecimalCell(),
    headerClassName: "decimal",
    rightAligned: true,
    order: 14,
    isDefault: true,
    group: "Option",
  },

  {
    field: "moneynessPercent",
    title: "Moneyness %",
    renderer: PercentCell(),
    order: 15,
    headerClassName: "decimal",
    width: 150,
    rightAligned: true,
    group: "Option",
    isDefault: true,
  },
  {
    field: "annualYield",
    title: "Annual Yield",
    renderer: DecimalCell(),
    headerClassName: "decimal",
    width: 150,
    order: 16,
    isDefault: true,
    group: "Option",
    rightAligned: true,
  },
  {
    field: "returnPercent",
    renderer: PercentCell(),
    headerClassName: "decimal",
    rightAligned: true,
    width: 120,
    title: "Return %",
    order: 17,
    group: "Option",
    isDefault: true,
  },
  {
    field: "strike",
    renderer: DecimalCell(),
    headerClassName: "decimal",
    rightAligned: true,
    title: "Strike Price",
    order: 17,
    group: "Option",
    isDefault: true,
  },
  {
    field: "pnlLocal",
    renderer: DecimalCell({ maxDecimals: 0 }),
    headerClassName: "decimal",
    rightAligned: true,
    title: "P&L",
    width: 150,
    order: 19,
    group: "P&L",
  },
  {
    field: "pnlBase",
    renderer: DecimalCell({ maxDecimals: 0 }),
    headerClassName: "decimal",
    rightAligned: true,
    title: "P&L (£)",
    width: 150,
    order: 20,
    isDefault: true,
    group: "P&L",
  },
];
