/* tslint:disable */
/* eslint-disable */
/**
 * Illio Wealth API
 * [Environment: dev], Manage your account, add trades, view portfolio performance and pnl
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@illio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { ErrorResponseBody } from '../../aggregator';
// @ts-ignore
import { PortfolioAllocationResponseData } from '../../aggregator';
// @ts-ignore
import { PortfolioDTOWithDetailsV3 } from '../../aggregator';
// @ts-ignore
import { PortfolioEventsResponseV3 } from '../../aggregator';
// @ts-ignore
import { PortfolioOverviewV3 } from '../../aggregator';
// @ts-ignore
import { PortfolioSnapshotDtoV3 } from '../../aggregator';
// @ts-ignore
import { PortfolioUpdateDtoV3 } from '../../aggregator';
// @ts-ignore
import { PortfolioV3DTO } from '../../aggregator';
// @ts-ignore
import { PortfolioWealthAllocation } from '../../aggregator';
// @ts-ignore
import { PrivateCommitmentsDetail } from '../../aggregator';
/**
 * PortfolioApi - axios parameter creator
 * @export
 */
export const PortfolioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Marks the portfolio as ready to be deleted
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('_delete', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get portfolio by Id
         * @param {string} portfolioId 
         * @param {Array<string>} [custodians] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get2: async (portfolioId: string, custodians?: Array<string>, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('get2', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (custodians) {
                localVarQueryParameter['custodians'] = custodians;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get portfolio events
         * @param {string} portfolioId 
         * @param {Set<string>} [custodians] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: async (portfolioId: string, custodians?: Set<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getEvents', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/events`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (custodians) {
                localVarQueryParameter['custodians'] = Array.from(custodians);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get portfolio allocation history
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioAllocationHistory: async (portfolioId: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioAllocationHistory', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/allocationHistory`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get portfolio structure by various allocation types
         * @param {string} portfolioId 
         * @param {'MarketValueBase' | 'NotionalBase' | 'ExposureBase' | 'MarketValueBaseGross' | 'NotionalBaseGross' | 'ExposureBaseGross'} [valueBasis] 
         * @param {Set<string>} [custodians] 
         * @param {string} [date] 
         * @param {boolean} [includeClosedPositions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioStructure1: async (portfolioId: string, valueBasis?: 'MarketValueBase' | 'NotionalBase' | 'ExposureBase' | 'MarketValueBaseGross' | 'NotionalBaseGross' | 'ExposureBaseGross', custodians?: Set<string>, date?: string, includeClosedPositions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolioStructure1', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/allocation`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (valueBasis !== undefined) {
                localVarQueryParameter['valueBasis'] = valueBasis;
            }

            if (custodians) {
                localVarQueryParameter['custodians'] = Array.from(custodians);
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (includeClosedPositions !== undefined) {
                localVarQueryParameter['includeClosedPositions'] = includeClosedPositions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get portfolio private equities commitments
         * @param {string} portfolioId 
         * @param {string} [positionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivateEquitiesCommitments: async (portfolioId: string, positionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPrivateEquitiesCommitments', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/privateEquitiesCommitments`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (positionId !== undefined) {
                localVarQueryParameter['positionId'] = positionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get portfolio snapshot
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshot: async (portfolioId: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getSnapshot', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/snapshot`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overview: async (portfolioId: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('overview', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}/overview`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update portfolio
         * @param {PortfolioUpdateDtoV3} portfolioUpdateDtoV3 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolio: async (portfolioUpdateDtoV3: PortfolioUpdateDtoV3, portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioUpdateDtoV3' is not null or undefined
            assertParamExists('updatePortfolio', 'portfolioUpdateDtoV3', portfolioUpdateDtoV3)
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('updatePortfolio', 'portfolioId', portfolioId)
            const localVarPath = `/v3/portfolio/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioUpdateDtoV3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioApi - functional programming interface
 * @export
 */
export const PortfolioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Marks the portfolio as ready to be deleted
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get portfolio by Id
         * @param {string} portfolioId 
         * @param {Array<string>} [custodians] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get2(portfolioId: string, custodians?: Array<string>, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioDTOWithDetailsV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get2(portfolioId, custodians, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get portfolio events
         * @param {string} portfolioId 
         * @param {Set<string>} [custodians] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvents(portfolioId: string, custodians?: Set<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioEventsResponseV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(portfolioId, custodians, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get portfolio allocation history
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioAllocationHistory(portfolioId: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortfolioWealthAllocation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioAllocationHistory(portfolioId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get portfolio structure by various allocation types
         * @param {string} portfolioId 
         * @param {'MarketValueBase' | 'NotionalBase' | 'ExposureBase' | 'MarketValueBaseGross' | 'NotionalBaseGross' | 'ExposureBaseGross'} [valueBasis] 
         * @param {Set<string>} [custodians] 
         * @param {string} [date] 
         * @param {boolean} [includeClosedPositions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioStructure1(portfolioId: string, valueBasis?: 'MarketValueBase' | 'NotionalBase' | 'ExposureBase' | 'MarketValueBaseGross' | 'NotionalBaseGross' | 'ExposureBaseGross', custodians?: Set<string>, date?: string, includeClosedPositions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioAllocationResponseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioStructure1(portfolioId, valueBasis, custodians, date, includeClosedPositions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get portfolio private equities commitments
         * @param {string} portfolioId 
         * @param {string} [positionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivateEquitiesCommitments(portfolioId: string, positionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrivateCommitmentsDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrivateEquitiesCommitments(portfolioId, positionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get portfolio snapshot
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshot(portfolioId: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortfolioSnapshotDtoV3>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshot(portfolioId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async overview(portfolioId: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioOverviewV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.overview(portfolioId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update portfolio
         * @param {PortfolioUpdateDtoV3} portfolioUpdateDtoV3 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePortfolio(portfolioUpdateDtoV3: PortfolioUpdateDtoV3, portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioV3DTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePortfolio(portfolioUpdateDtoV3, portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioApi - factory interface
 * @export
 */
export const PortfolioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioApiFp(configuration)
    return {
        /**
         * 
         * @summary Marks the portfolio as ready to be deleted
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(portfolioId: string, options?: any): AxiosPromise<object> {
            return localVarFp._delete(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get portfolio by Id
         * @param {string} portfolioId 
         * @param {Array<string>} [custodians] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get2(portfolioId: string, custodians?: Array<string>, from?: string, to?: string, options?: any): AxiosPromise<PortfolioDTOWithDetailsV3> {
            return localVarFp.get2(portfolioId, custodians, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get portfolio events
         * @param {string} portfolioId 
         * @param {Set<string>} [custodians] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(portfolioId: string, custodians?: Set<string>, options?: any): AxiosPromise<PortfolioEventsResponseV3> {
            return localVarFp.getEvents(portfolioId, custodians, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get portfolio allocation history
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioAllocationHistory(portfolioId: string, from?: string, to?: string, options?: any): AxiosPromise<Array<PortfolioWealthAllocation>> {
            return localVarFp.getPortfolioAllocationHistory(portfolioId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get portfolio structure by various allocation types
         * @param {string} portfolioId 
         * @param {'MarketValueBase' | 'NotionalBase' | 'ExposureBase' | 'MarketValueBaseGross' | 'NotionalBaseGross' | 'ExposureBaseGross'} [valueBasis] 
         * @param {Set<string>} [custodians] 
         * @param {string} [date] 
         * @param {boolean} [includeClosedPositions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioStructure1(portfolioId: string, valueBasis?: 'MarketValueBase' | 'NotionalBase' | 'ExposureBase' | 'MarketValueBaseGross' | 'NotionalBaseGross' | 'ExposureBaseGross', custodians?: Set<string>, date?: string, includeClosedPositions?: boolean, options?: any): AxiosPromise<PortfolioAllocationResponseData> {
            return localVarFp.getPortfolioStructure1(portfolioId, valueBasis, custodians, date, includeClosedPositions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get portfolio private equities commitments
         * @param {string} portfolioId 
         * @param {string} [positionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivateEquitiesCommitments(portfolioId: string, positionId?: string, options?: any): AxiosPromise<Array<PrivateCommitmentsDetail>> {
            return localVarFp.getPrivateEquitiesCommitments(portfolioId, positionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get portfolio snapshot
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshot(portfolioId: string, from?: string, to?: string, options?: any): AxiosPromise<Array<PortfolioSnapshotDtoV3>> {
            return localVarFp.getSnapshot(portfolioId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overview(portfolioId: string, from?: string, to?: string, options?: any): AxiosPromise<PortfolioOverviewV3> {
            return localVarFp.overview(portfolioId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update portfolio
         * @param {PortfolioUpdateDtoV3} portfolioUpdateDtoV3 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolio(portfolioUpdateDtoV3: PortfolioUpdateDtoV3, portfolioId: string, options?: any): AxiosPromise<PortfolioV3DTO> {
            return localVarFp.updatePortfolio(portfolioUpdateDtoV3, portfolioId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioApi - object-oriented interface
 * @export
 * @class PortfolioApi
 * @extends {BaseAPI}
 */
export class PortfolioApi extends BaseAPI {
    /**
     * 
     * @summary Marks the portfolio as ready to be deleted
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public _delete(portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration)._delete(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get portfolio by Id
     * @param {string} portfolioId 
     * @param {Array<string>} [custodians] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public get2(portfolioId: string, custodians?: Array<string>, from?: string, to?: string, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).get2(portfolioId, custodians, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get portfolio events
     * @param {string} portfolioId 
     * @param {Set<string>} [custodians] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getEvents(portfolioId: string, custodians?: Set<string>, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).getEvents(portfolioId, custodians, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get portfolio allocation history
     * @param {string} portfolioId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getPortfolioAllocationHistory(portfolioId: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).getPortfolioAllocationHistory(portfolioId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get portfolio structure by various allocation types
     * @param {string} portfolioId 
     * @param {'MarketValueBase' | 'NotionalBase' | 'ExposureBase' | 'MarketValueBaseGross' | 'NotionalBaseGross' | 'ExposureBaseGross'} [valueBasis] 
     * @param {Set<string>} [custodians] 
     * @param {string} [date] 
     * @param {boolean} [includeClosedPositions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getPortfolioStructure1(portfolioId: string, valueBasis?: 'MarketValueBase' | 'NotionalBase' | 'ExposureBase' | 'MarketValueBaseGross' | 'NotionalBaseGross' | 'ExposureBaseGross', custodians?: Set<string>, date?: string, includeClosedPositions?: boolean, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).getPortfolioStructure1(portfolioId, valueBasis, custodians, date, includeClosedPositions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get portfolio private equities commitments
     * @param {string} portfolioId 
     * @param {string} [positionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getPrivateEquitiesCommitments(portfolioId: string, positionId?: string, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).getPrivateEquitiesCommitments(portfolioId, positionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get portfolio snapshot
     * @param {string} portfolioId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getSnapshot(portfolioId: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).getSnapshot(portfolioId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public overview(portfolioId: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).overview(portfolioId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update portfolio
     * @param {PortfolioUpdateDtoV3} portfolioUpdateDtoV3 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public updatePortfolio(portfolioUpdateDtoV3: PortfolioUpdateDtoV3, portfolioId: string, options?: AxiosRequestConfig) {
        return PortfolioApiFp(this.configuration).updatePortfolio(portfolioUpdateDtoV3, portfolioId, options).then((request) => request(this.axios, this.basePath));
    }
}
